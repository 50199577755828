import React from "react";
import { eachDayOfInterval, startOfWeek, endOfWeek, addDays } from "date-fns";
import { formatWithOptions } from "date-fns/fp";
import * as Locales from "date-fns/locale";
import PropTypes from "prop-types";
import { Datepicker } from "../Datepicker";

export const DatepickerWithLocale = ({
  calendarHeight,
  calendarWidth,
  date,
  setDate,
  dateFormat,
  inline,
  label,
  minDate,
  maxDate,
  placeholder,
  startDate,
  className,
  showIcon,
  locale,
  showDayOfWeekOnInputText,
  dateFormatForLocale,
  error
}) => {
  const showDayOfWeek = showDayOfWeekOnInputText
    ? showDayOfWeekOnInputText
    : false;

  const customFormatForLocale = localeCode => {
    const localeCodes =
      localeCode === "en" || localeCode === "enUS"
        ? ["en", "enUS"]
        : [localeCode];

    const customFormat = dateFormatForLocale
      ? dateFormatForLocale.find(custom =>
          localeCodes.some(code => code === custom.locale)
        )
      : null;

    return customFormat ? customFormat.format : null;
  };

  const localeCode = locale === "en" ? "enUS" : !locale ? "enGB" : locale;

  const localeObj = Locales[localeCode] ? Locales[localeCode] : Locales.enGB;

  const dateFormatter = (date, dateFormat) => {
    const customFormat = customFormatForLocale(localeCode);

    const dateFormatPattern = dateFormat
      ? dateFormat
      : customFormat
      ? customFormat
      : showDayOfWeek
      ? localeObj.formatLong.date()
      : localeObj.formatLong.date().replace(/EEEE[,]*/g, "");

    const formatter = formatWithOptions(
      { locale: localeObj },
      dateFormatPattern
    );

    return formatter(date);
  };

  const daysOfTheWeek = () => {
    // get list of days of the week starting on monday
    const start = addDays(startOfWeek(new Date()), 1);
    const end = addDays(endOfWeek(new Date()), 1);

    return eachDayOfInterval({ start, end }).map(day => {
      return {
        longName: dateFormatter(day, "EEEE"),
        abbreviation: dateFormatter(day, "eeeeee")
      };
    });
  };

  return (
    <Datepicker
      calendarHeight={calendarHeight}
      calendarWidth={calendarWidth}
      date={date}
      setDate={setDate}
      dateFormat={dateFormat}
      inline={inline}
      label={label}
      minDate={minDate}
      maxDate={maxDate}
      placeholder={placeholder}
      startDate={startDate}
      className={className}
      showIcon={showIcon}
      dateFormatter={dateFormatter}
      daysOfTheWeek={daysOfTheWeek()}
      error={error}
    />
  );
};

DatepickerWithLocale.propTypes = {
  calendarHeight: PropTypes.string,
  calendarWidth: PropTypes.string,
  date: PropTypes.instanceOf(Date),
  setDate: PropTypes.func.isRequired,
  dateFormat: PropTypes.string,
  inline: PropTypes.bool,
  label: PropTypes.string,
  minDate: PropTypes.instanceOf(Date),
  maxDate: PropTypes.instanceOf(Date),
  placeholder: PropTypes.string,
  startDate: PropTypes.instanceOf(Date),
  className: PropTypes.string,
  showIcon: PropTypes.bool,
  locale: PropTypes.string,
  showDayOfWeekOnInputText: PropTypes.bool,
  dateFormatForLocale: PropTypes.func,
  error: PropTypes.bool
};
