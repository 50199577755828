import * as React from "react";

function SvgCn(props) {
  return (
    <svg viewBox="0 0 640 480" {...props}>
      <defs>
        <path
          id="cn_svg__a"
          fill="#ffde00"
          d="M-.588.81L0-1 .588.81-.952-.31H.952z"
        />
      </defs>
      <path fill="#de2910" d="M0 0h640v480H0z" />
      <use
        width={30}
        height={20}
        transform="matrix(71.9991 0 0 72 119.999 120)"
        xlinkHref="#cn_svg__a"
      />
      <use
        width={30}
        height={20}
        transform="matrix(-12.33562 -20.5871 20.58684 -12.33577 240.291 47.996)"
        xlinkHref="#cn_svg__a"
      />
      <use
        width={30}
        height={20}
        transform="matrix(-3.38573 -23.75998 23.75968 -3.38578 287.95 95.796)"
        xlinkHref="#cn_svg__a"
      />
      <use
        width={30}
        height={20}
        transform="matrix(6.5991 -23.0749 23.0746 6.59919 287.959 168.012)"
        xlinkHref="#cn_svg__a"
      />
      <use
        width={30}
        height={20}
        transform="matrix(14.9991 -18.73557 18.73533 14.99929 239.933 216.054)"
        xlinkHref="#cn_svg__a"
      />
    </svg>
  );
}

export default SvgCn;
