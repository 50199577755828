import React from "react";

import config from "current-theme";

import PropTypes from "prop-types";

export const getThemeColors = () => config.theme.extend.colors;

export default function ColorPalette(props) {
  return (
    <div className="aui-m-2">
      <div
        className={
          `aui-bg-${props.name}` +
          " aui-p-6 aui-max-w-sm aui-rounded aui-overflow-hidden aui-shadow-lg aui-m-2"
        }
      >
        <div className="aui-px-6 aui-py-4" />
      </div>
      <div className="aui-ml-3 aui-text-xs aui-font-semibold aui-text-gray-700">
        <p>
          <code className="aui-font-mono">{props.color}</code>
          <br />
          <code className="aui-font-mono">{props.name}</code>
        </p>
      </div>
    </div>
  );
}
ColorPalette.propTypes = {
  name: PropTypes.string,
  color: PropTypes.string
};
