import * as React from "react";

function SvgLc(props) {
  return (
    <svg viewBox="0 0 640 480" {...props}>
      <g fillRule="evenodd">
        <path fill="#65cfff" d="M0 0h640v480H0z" />
        <path
          fill="#fff"
          d="M318.9 41.991l162.66 395.3-322.6.91L318.9 41.991z"
        />
        <path d="M319.09 96.516l140.67 339.99-278.99.78 138.32-340.77z" />
        <path
          fill="#ffce00"
          d="M318.9 240.1l162.66 197.64-322.6.46L318.9 240.1z"
        />
      </g>
    </svg>
  );
}

export default SvgLc;
