/* eslint-disable @typescript-eslint/no-explicit-any */
import { Events } from "types/events";
import { Message } from "types/types";

export const messages = {
    resize(payload: { height: number }): Message {
        return {
            type: Events.Resize,
            payload,
        };
    },
    moduleLoaded(): Message {
        return {
            type: Events.ModuleLoaded,
        };
    },
    setContext(payload: any): Message {
        return {
            type: Events.SetContext,
            payload: JSON.stringify(payload),
        };
    },
    requestContext(): Message {
        return {
            type: Events.RequestContext,
        };
    },
};
