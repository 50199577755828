import { useEffect } from "react";
import { Message } from "@alphasights/realtime-import";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const useListenToWindowMessage = (callback: (message: any) => void) => {
    useEffect(() => {
        const listener = (event: MessageEvent) => {
            try {
                if (event.data) {
                    const eventData = typeof event.data === "string" ? JSON.parse(event.data) : event.data;
                    callback(eventData);
                }
            } catch (e) {
                callback(event.data);
            }
        };

        window.addEventListener("message", listener);

        return () => {
            window.removeEventListener("message", listener);
        };
    }, [callback]);
};

export const useListenToWindowMessageEvent = (eventName: string, callback: (message: Message) => void) => {
    useListenToWindowMessage((message) => {
        if (!message || typeof message !== "object" || !message.type) return;
        if (message.type === eventName) {
            callback(message);
        }
    });
};
