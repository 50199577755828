/**
 * Module for validating input elements.
 *
 * Rules must in the form of a list of validation objects:
 * const rules = [{ value: value, setErrorState: callback, criteria: [{type: 'minLength', value: 3}] }];
 * Where rule types are:
 * minLength
 * maxLength
 * required
 * mustMatch
 * gt
 * gte
 * lt
 * lte
 * phoneNumber
 */

const PHONE_NUMBER_REGEX = /^\+?[0-9 \(\)-]+$/;
function validatePhoneNumber(number) {
  return PHONE_NUMBER_REGEX.test(number);
}

const RULES = {
  phoneNumber: validatePhoneNumber,
  minLength: (value, minLength) =>
    String(value).length >= minLength ||
    `Input must be at least ${minLength} characters.`,
  maxLength: (value, maxLength) =>
    String(value).length <= maxLength ||
    `Input must be at most ${maxLength} characters.`,
  required: value => !isEmpty(value) || "This input is required.",
  isNumeric: value =>
    value === undefined || !isNaN(value) || "You must provide a valid number.",
  mustMatch: (value, regexp) =>
    !!String(value).match(regexp) || "Invalid input.",
  gt: (value, threshold) =>
    Number(value) > threshold || `Input must be greater than ${threshold}.`,
  gte: (value, threshold) =>
    Number(value) >= threshold ||
    `Input must be greater than or equal to ${threshold}.`,
  lt: (value, threshold) =>
    Number(value) < threshold || `Input must be less than ${threshold}.`,
  lte: (value, threshold) =>
    Number(value) <= threshold ||
    `Input must be less than or equal to ${threshold}.`,
  contains: (value, targetList) =>
    targetList.includes(value) ||
    `Input must be contained in ${targetList.join(",")}.`,
  notContains: (value, targetList) =>
    !targetList.includes(value) ||
    `Input is already contained in ${targetList.join(",")}.`
};

function validate(rules) {
  let validationPassed = true;
  for (let rule of rules) {
    rule.setErrorState(null);
    for (let criteria of rule.criteria) {
      const errorMsg = RULES[criteria.type](rule.value, criteria.value);
      if (errorMsg !== true) {
        rule.setErrorState(errorMsg);
        validationPassed = false;
        break;
      }
    }
  }
  return validationPassed;
}

function isEmpty(value) {
  if (value === null || value === undefined) return true;
  if (typeof value === "object") {
    return Object.entries(value).length === 0;
  }
  return String(value).trim() === "";
}

/// Used to mark deprecated PropType validators
function deprecated(validator) {
  return function deprecated(props, propName, ...rest) {
    if (props[propName] != null) {
      // eslint-disable-next-line no-console
      console.warn(`${propName} is deprecated`);
    }
    return validator(props, propName, ...rest);
  };
}

export { validate, validatePhoneNumber, deprecated };
