import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";

import { Icon } from "../../../../";

export const MINIMIZED_COL_SIZE = 85;

const DefaultColumnHeader = ({
  displayName,
  columnApi,
  column,
  showColumnMenu,
  setSort,
  api,
  hidePin,
  hideDrag,
  hideMinimize,
  displayIcon,
  customItem
}) => {
  const { colDef } = column;
  const DEFAULT_SIZE = 150;
  const MIN_SIZE = colDef.minWidth || 35;
  const filterIconRef = useRef();
  const minimizedColumnSize = colDef.minWidth || MINIMIZED_COL_SIZE;
  const [minimized, setMinimized] = useState(
    column.actualWidth <= minimizedColumnSize
  );

  const [sortState, setSortState] = useState(column.sort || "inactive");
  const [pinned, setPinned] = useState(column.pinned);
  const [columnWidth, setColumnWidth] = useState(
    colDef.maxColWidth || column.actualWidth
  );

  if (!colDef.maxColWidth) {
    colDef.maxColWidth = column.actualWidth;
  }

  /**
   * This useEffect dynamically adjust the columnWidth state variable based on settings prescribed in the colDef
   * so that toggleMinimize function works as expected since it depends on the columnWidth state variable for its calculations
   */
  useEffect(() => {
    /**
     * Logic in this conditional is to ensure the eye-icon toggle to expand a minimized column works in
     * the case that a width is set that is too small and would otherwise prevent expansion of the column
     */
    if (columnWidth <= minimizedColumnSize) {
      const newWidth =
        colDef.width && colDef.width > minimizedColumnSize
          ? colDef.width
          : minimizedColumnSize + 1;
      setColumnWidth(newWidth);
      // eslint-disable-next-line no-console
      console.warn(
        `DefaultColumnHeader: The column for field "${colDef.field}" colDef.width should be greater than minimizedColumnSize (${minimizedColumnSize}) for eye-icon expanding to work
         - Code will automatically set columnWidth state variable equal to (${newWidth}) so toggling eye-icon works
        `
      );
    }
  }, [columnWidth]);

  function renderSortControls() {
    switch (sortState) {
      case "inactive":
        return (
          <Icon
            onClick={onSortRequested.bind(this, "asc")}
            icon="table-sort"
            className={`aui-ml-2 aui-float-right hover:aui-text-dark-1 aui-cursor-pointer`}
          />
        );
      case "asc":
        return (
          <Icon
            onClick={onSortRequested.bind(this, "desc")}
            icon="caret-up"
            className={`aui-cursor-pointer aui-ml-2 aui-mt-1 aui-mr-1 aui-float-right hover:aui-text-dark-1 aui-text-tiny`}
          />
        );
      case "desc":
        return (
          <Icon
            onClick={onSortRequested.bind(this, "inactive")}
            icon="caret-down"
            className={`aui-cursor-pointer aui-ml-2 aui-mt-1 aui-mr-1 aui-float-right hover:aui-text-dark-1 aui-text-tiny`}
          />
        );
    }
  }

  function onSortRequested(order, event) {
    setSort(order, event.shiftKey);
    setSortState(order);
  }

  function toggleMinimize() {
    const originalSize = columnWidth <= MIN_SIZE ? DEFAULT_SIZE : columnWidth;
    const newWidth = !minimized ? MIN_SIZE : originalSize;
    columnApi.setColumnWidth(column.colId, newWidth);
    setMinimized(!minimized);
  }

  function togglePinned() {
    columnApi.setColumnPinned(column.colId, !pinned);
    setPinned(!pinned);
  }

  function toggleColumnMenu() {
    showColumnMenu(filterIconRef.current);
  }

  function copyAll() {
    const visibleRowData = [];
    for (let i = 0; i < api.getDisplayedRowCount(); i++) {
      visibleRowData.push(api.getDisplayedRowAtIndex(i).data);
    }
    colDef.onCopyAll(visibleRowData);
  }

  return (
    <div className="aui-text-grey-4 aui-uppercase aui-group aui-h-full aui-min-h-xl aui-flex aui-items-center">
      {!minimized || hideMinimize ? (
        <div className="aui-w-full aui-group-hover aui-truncate">
          {!hidePin && (
            <Icon
              icon="thumbtack-alt"
              onClick={togglePinned}
              className={`${
                !pinned ? "aui-hidden" : "aui-text-primary-2"
              } hover:aui-text-primary-1 group-hover:aui-inline-block aui-cursor-pointer aui-mr-4`}
            />
          )}
          {!!colDef.onCopyAll && (
            <Icon
              icon="copy"
              onClick={copyAll}
              className="aui-hidden group-hover:aui-block hover:aui-text-primary-1 aui-mr-4 group-hover:aui-inline-block aui-cursor-pointer"
            />
          )}
          {colDef.sortable && renderSortControls()}
          {displayIcon && (
            <Icon
              icon={displayIcon}
              className="group-hover:aui-hidden aui-mr-2"
            />
          )}
          <span className="group-hover:aui-hidden">{displayName}</span>
          <div className="aui-inline-block aui-w- aui-float-right">
            <div className="aui-flex aui-justify-between">
              {!hideDrag && (
                <Icon
                  icon="move"
                  className="aui-cursor-pointer aui-hidden group-hover:aui-block hover:aui-text-primary-1"
                />
              )}
              {!hideMinimize && (
                <Icon
                  icon="eye-slash"
                  onClick={toggleMinimize}
                  className="aui-cursor-pointer aui-hidden hover:aui-text-primary-1 group-hover:aui-block aui-ml-4"
                />
              )}
              {colDef.filter && (
                <div ref={filterIconRef} className="aui-inline-block">
                  <Icon
                    icon="filters"
                    className="aui-cursor-pointer hover:aui-text-primary-1 aui-ml-4"
                    onClick={toggleColumnMenu}
                  />
                </div>
              )}
              {customItem && customItem()}
            </div>
          </div>
        </div>
      ) : (
        <Icon
          icon="eye"
          onClick={toggleMinimize}
          className="aui-text-primary-1 group-hover:aui-block aui-cursor-pointer"
        />
      )}
    </div>
  );
};

DefaultColumnHeader.propTypes = {
  displayName: PropTypes.string,
  column: PropTypes.object,
  columnApi: PropTypes.object,
  agGridReact: PropTypes.object,
  api: PropTypes.object,
  setSort: PropTypes.func,
  showColumnMenu: PropTypes.func,
  hidePin: PropTypes.bool,
  hideDrag: PropTypes.bool,
  hideMinimize: PropTypes.bool,
  displayIcon: PropTypes.string,
  customItem: PropTypes.func
};

DefaultColumnHeader.defaultProps = {
  hidePin: false,
  hideDrag: false,
  hideMinimize: false
};

export { DefaultColumnHeader };
