import * as React from "react";

function SvgGd(props) {
  return (
    <svg viewBox="0 0 640 480" {...props}>
      <defs>
        <g id="gd_svg__c">
          <g id="gd_svg__b">
            <path
              id="gd_svg__a"
              fill="#fcd116"
              d="M0-1v1h.5"
              transform="rotate(18 0 -1)"
            />
            <use transform="scale(-1 1)" xlinkHref="#gd_svg__a" />
          </g>
          <use transform="rotate(72)" xlinkHref="#gd_svg__b" />
          <use transform="rotate(144)" xlinkHref="#gd_svg__b" />
          <use transform="rotate(216)" xlinkHref="#gd_svg__b" />
          <use transform="rotate(288)" xlinkHref="#gd_svg__b" />
        </g>
      </defs>
      <path fill="#ce1126" d="M0 .05h640v480H0z" />
      <path fill="#007a5e" d="M67.2 67.2h505.6v345.6H67.2z" />
      <path fill="#fcd116" d="M67.2 67.25h505.6L67.2 412.85h505.6z" />
      <circle cx={319.917} cy={240.05} r={57.6} fill="#ce1126" />
      <use
        width="100%"
        height="100%"
        transform="matrix(52.8 0 0 52.8 319.917 240.05)"
        xlinkHref="#gd_svg__c"
      />
      <use
        width="100%"
        height="100%"
        x={-100}
        transform="translate(-30.327)"
        xlinkHref="#gd_svg__d"
      />
      <use
        id="gd_svg__d"
        width="100%"
        height="100%"
        transform="matrix(31.2 0 0 31.2 320 33.65)"
        xlinkHref="#gd_svg__c"
      />
      <use
        width="100%"
        height="100%"
        x={100}
        transform="translate(30.327)"
        xlinkHref="#gd_svg__d"
      />
      <path
        fill="#ce1126"
        d="M102.315 240.706c7.206 13.424 21.224 28.032 33.466 33.168.196-13.632-4.64-31.104-11.255-45.024l-22.21 11.856z"
      />
      <path
        fill="#fcd116"
        d="M90.096 194.658c10.446 21.776-27.093 73.728 35.45 85.904-7.716-10.528-12.69-27.904-10.809-41.856 13.122 4.992 26.928 19.6 32.508 30.768 16.368-59.44-41.984-55.824-57.15-74.816z"
      />
      <use
        width="100%"
        height="100%"
        x={-100}
        transform="translate(-30.327 414.55)"
        xlinkHref="#gd_svg__d"
      />
      <use
        width="100%"
        height="100%"
        transform="matrix(31.2 0 0 31.2 320 448.2)"
        xlinkHref="#gd_svg__c"
      />
      <use
        width="100%"
        height="100%"
        x={100}
        transform="translate(30.327 414.55)"
        xlinkHref="#gd_svg__d"
      />
    </svg>
  );
}

export default SvgGd;
