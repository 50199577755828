import React from "react";
import PropTypes from "prop-types";
import { TextBody, Icon } from "../../..";

export default function CheckboxInput(props) {
  const [value, setValue] = props.state;
  const containerClassName = `checkbox-container ${props.containerClassName ||
    "aui-mt-1"}`;

  let classNames = "";
  if (!props.disabled) {
    classNames += " aui-cursor-pointer";
  } else {
    classNames += " aui-cursor-not-allowed";
  }
  classNames += " aui-text-xl";
  classNames += ` ${props.className ? props.className : ""}`;

  return (
    <div className={containerClassName}>
      <label
        className={"aui-flex" + classNames}
        role="option"
        aria-selected={value}
        aria-label={props.labelName && props.labelName + "-checkbox"}
      >
        <input
          className={
            props.inputClassName ? props.inputClassName : "aui-m-1 aui-hidden"
          }
          type="checkbox"
          disabled={props.disabled}
          checked={Boolean(value)}
          onChange={e => setValue(e.target.checked)}
        />
        <Icon
          className={
            props.iconClassName
              ? props.iconClassName
              : "aui-mt-1" + (props.disabled ? " aui-text-grey-2" : "")
          }
          icon={value ? "checkbox-filled" : "checkbox"}
        />
        <div
          className={
            props.textBodyContainerClassName
              ? props.textBodyContainerClassName
              : "aui-ml-2 aui-flex-grow aui-mt-2xs"
          }
        >
          <TextBody textBodyClassName="aui-text-body aui-text-dark-1">
            {props.children}
          </TextBody>
        </div>
      </label>
    </div>
  );
}

CheckboxInput.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string,
  containerClassName: PropTypes.string,
  disabled: PropTypes.bool,
  iconClassName: PropTypes.string,
  inputClassName: PropTypes.string,
  labelName: PropTypes.string,
  state: PropTypes.array,
  textBodyContainerClassName: PropTypes.string
};
