import React from "react";
import PropTypes from "prop-types";

import "./index.pcss";

import useToggle from "../../../../hooks/useToggle";

const Toggle = ({ active, disabled, newToggle, onChange, size }) => {
  const toggleValue = useToggle(active);

  const handleOnChange = () => {
    toggleValue.toggle();
    return onChange(toggleValue.value);
  };

  const getWidthClass = () => {
    if (size === "small") return "aui-w-8 aui-h-4";
    if (size === "large") return "aui-w-16 aui-h-8";
    return "aui-w-12 aui-h-6";
  };

  const getSizeClass = () => {
    if (size === "small") return "aui-w-4 aui-h-4";
    if (size === "large") return "aui-w-8 aui-h-8";
    return "aui-w-6 aui-h-6";
  };

  return (
    <label
      className={`aui-toggle-container aui-flex aui-items-center ${
        disabled ? "" : "aui-cursor-pointer"
      }`}
    >
      <div className="aui-relative">
        <input
          type="checkbox"
          className="aui-hidden"
          checked={toggleValue.value}
          disabled={disabled}
          onChange={() => {
            handleOnChange();
          }}
        />
        <div
          className={`aui-toggle-bar ${getWidthClass()} aui-bg-grey-2 aui-border-2 aui-border-solid ${newToggle &&
            `aui-new-bar`}`}
        />
        <div
          className={`aui-toggle-handle aui-absolute ${getSizeClass()} aui-bg-white aui-rounded-full aui-border-2 aui-border-solid aui-inset-y-0 ${newToggle &&
            `aui-new-handle`}`}
        />
      </div>
    </label>
  );
};

Toggle.propTypes = {
  active: PropTypes.bool,
  disabled: PropTypes.bool,
  newToggle: PropTypes.bool,
  onChange: PropTypes.func,
  size: PropTypes.string
};

Toggle.defaultProps = {
  active: false,
  disabled: false,
  newToggle: false,
  onChange: () => {},
  size: "medium"
};

export default Toggle;
