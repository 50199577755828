import React from "react";
import PropTypes from "prop-types";

import { InlineLoadingMessage } from "../../../../";

const LoadingOverlay = ({ message }) => {
  return (
    <div className="aui-opacity-100 aui-text-dark-1">
      <InlineLoadingMessage spinnerColor="dark-1">
        {message || "Loading data...Please wait."}
      </InlineLoadingMessage>
    </div>
  );
};

LoadingOverlay.propTypes = { message: PropTypes.string };

export { LoadingOverlay };
