import { useEffect } from "react";

export function useOutsideClick(ref, callback, useCapture = false) {
  const handleClick = e => {
    if (ref.current && !isDescendant(ref.current, e)) {
      callback(e);
    }
  };

  function isDescendant(targetParent, evt) {
    if (!evt.path) {
      return true;
    }
    return !!evt.path.find(element => element === targetParent);
  }

  useEffect(() => {
    document.addEventListener("click", handleClick, useCapture);
    return () => {
      document.removeEventListener("click", handleClick, useCapture);
    };
  }, [ref.current]);
}
