import React from "react";
import PropTypes from "prop-types";
import { Icon } from "../..";

const WarningPopup = ({
  body,
  textColor = "grey-5",
  bgColor = "warning-1",
  icon = "alert-triangle",
  iconColor = "warning-2",
  children,
  wrapperClassName = "",
  className = ""
}) => {
  const classNames = [
    "aui-hidden",
    "aui-absolute",
    "aui-z-10",
    "aui-zoom-in",
    "aui-items-center",
    "aui-text-left",
    `aui-text-${textColor}`,
    "aui-py-4",
    "aui-px-5",
    "aui-border-radius-sm",
    `aui-bg-${bgColor}`,
    className
  ].join(" ");

  return (
    <span className={`aui-group aui-relative ${wrapperClassName}`}>
      {children}
      <span className={`group-hover:aui-flex ${classNames}`} role="popover">
        <Icon
          icon={icon}
          className={`aui-text-2xl aui-pr-5 aui-text-${iconColor}`}
        />
        <div>{body}</div>
      </span>
    </span>
  );
};

WarningPopup.propTypes = {
  body: PropTypes.any,
  textColor: PropTypes.string,
  bgColor: PropTypes.string,
  icon: PropTypes.string,
  iconColor: PropTypes.string,
  children: PropTypes.any,
  wrapperClassName: PropTypes.string,
  className: PropTypes.string
};

export { WarningPopup };
