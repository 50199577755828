import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import { FieldError } from "../FieldError";

const InputField = React.forwardRef(
  (
    {
      autoFocus,
      bgColor,
      borderColor,
      className,
      disabled,
      softDisabled,
      error,
      onBlur = () => {},
      onChange = () => {},
      onClick = () => {},
      onFocus = () => {},
      onKeyDown = () => {},
      onKeyPress = () => {},
      placeholder,
      value,
      type = "text",
      readOnly,
      id,
      ariaLabelledby
    },
    ref
  ) => {
    const [currentValue, setCurrentValue] = useState([value]);

    useEffect(() => {
      setCurrentValue(value || "");
    }, [value]);

    function handleChange(e) {
      onChange(e.target.value);
      setCurrentValue(e.target.value);
    }

    let borderStyles = [
      "aui-border",
      borderColor ||
        (error ? "focus:aui-border-error-2" : "focus:aui-border-grey-2"),
      borderColor || (error ? "aui-border-error-2" : "aui-border-grey-2"),
      "aui-border-solid"
    ];

    let classes = [
      "aui-rounded",
      "aui-p-2",
      "focus:aui-outline-none",
      "aui-appearance-none",
      "aui-text-dark-1",
      bgColor || "aui-bg-grey-1",
      "focus:aui-bg-white",
      ...borderStyles,
      className || ""
    ].join(" ");

    if (disabled) {
      classes += `${
        softDisabled ? "" : " aui-cursor-not-allowed"
      } aui-opacity-50`;
    } else {
      classes += " aui-shadow-inner";
    }

    return (
      <React.Fragment>
        <input
          ref={ref}
          type={type}
          placeholder={placeholder || ""}
          value={currentValue}
          onChange={handleChange}
          onBlur={onBlur}
          onFocus={onFocus}
          autoFocus={autoFocus}
          onKeyPress={onKeyPress}
          onKeyDown={onKeyDown}
          className={classes}
          disabled={disabled}
          onClick={onClick}
          readOnly={readOnly}
          id={id}
          aria-labelledby={ariaLabelledby}
        />
        {error && <FieldError>{error}</FieldError>}
      </React.Fragment>
    );
  }
);
InputField.displayName = "InputField";
InputField.propTypes = {
  placeholder: PropTypes.string,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  onClick: PropTypes.func,
  onFocus: PropTypes.func,
  onKeyPress: PropTypes.func,
  onKeyDown: PropTypes.func,
  className: PropTypes.string,
  value: PropTypes.any,
  autoFocus: PropTypes.bool,
  borderColor: PropTypes.string,
  bgColor: PropTypes.string,
  error: PropTypes.string,
  disabled: PropTypes.bool,
  softDisabled: PropTypes.bool,
  type: PropTypes.string,
  readOnly: PropTypes.bool,
  id: PropTypes.string,
  ariaLabelledby: PropTypes.string
};

export { InputField };
