import React, { useEffect, useImperativeHandle, useRef } from "react";
import PropTypes from "prop-types";

import { Select } from "../../../../";
import { useOutsideClick } from "../../../../../hooks";

let selectedItem = null;
const SelectCellEditor = React.forwardRef((props, ref) => {
  const { api, value, selectProps, items, noDropdownToggle } = props;
  const inputRef = useRef();
  useOutsideClick(inputRef, () => api.stopEditing(true));

  selectedItem = value;

  useEffect(() => {
    const gridContainerEl = document.getElementsByClassName("aui-ag-grid")[0];
    gridContainerEl.classList.add("aui-popup-flat");

    return () => {
      gridContainerEl.classList.remove("aui-popup-flat");
    };
  }, []);

  useImperativeHandle(ref, () => {
    return {
      getValue: () => {
        return selectedItem;
      },
      isPopup: () => true
    };
  });
  return (
    <div ref={inputRef}>
      <Select
        noDropdownToggle={noDropdownToggle}
        isDropdownVisible
        items={items}
        selected={selectedItem}
        onSelect={item => {
          selectedItem = item;
          api.stopEditing(false);
        }}
        {...selectProps}
      />
    </div>
  );
});

SelectCellEditor.displayName = "SelectCellEditor";
SelectCellEditor.propTypes = {
  items: PropTypes.array.isRequired,
  api: PropTypes.object.isRequired,
  value: PropTypes.any,
  selectProps: PropTypes.object,
  noDropdownToggle: PropTypes.bool
};

export { SelectCellEditor };
