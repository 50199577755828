import React from "react";
import PropTypes from "prop-types";
import { Icon } from "../../../..";

const CheckboxHeader = props => {
  const onSelectAll = event => {
    event.preventDefault();
    const numSelectedNodes = props.api.getSelectedNodes().length;
    numSelectedNodes > 0
      ? props.api.deselectAllFiltered()
      : props.api.selectAllFiltered();
  };

  const getNumberOfRows = () => {
    let numberOfRows = 0;
    props.api.forEachNode(node => numberOfRows++);
    return numberOfRows;
  };

  const numberOfRows = getNumberOfRows();

  const numSelectedNodes = props.api.getSelectedNodes().length;
  const getIcon = () => {
    if (numSelectedNodes > 0 && numSelectedNodes < numberOfRows) {
      return "checkbox-selected";
    } else if (numSelectedNodes === numberOfRows && numberOfRows) {
      return "checkbox-filled";
    }
    return "checkbox";
  };

  return (
    <div
      className="aui-cursor-pointer"
      onTouchEnd={onSelectAll}
      onClick={onSelectAll}
    >
      <Icon
        className="aui-text-2xl aui-inline-block aui-text-dark-1"
        icon={getIcon()}
      />
    </div>
  );
};

export { CheckboxHeader };

CheckboxHeader.propTypes = {
  api: PropTypes.object
};
