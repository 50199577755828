import React from "react";
import PropTypes from "prop-types";
import { TextSubHeader } from "../../../";

export function FieldLabel(props) {
  return <TextSubHeader>{props.children}</TextSubHeader>;
}
FieldLabel.propTypes = {
  children: PropTypes.any
};
