import React from "react";

const SVGSpinner = props => (
  <svg widt="1em" height="1em" {...props} viewBox="0 0 169 171">
    <defs>
      <linearGradient
        x1="76.3339953%"
        y1="78.5348427%"
        x2="27.9948846%"
        y2="-6.34361662%"
        id="gradient"
      >
        <stop stopColor="#FC8300" offset="0%"></stop>
        <stop stopColor="#FD3001" offset="50%"></stop>
        <stop stopColor="#E8236A" offset="100%"></stop>
      </linearGradient>
    </defs>
    <g fillRule="nonzero" style={{ transformOrigin: "50%" }}>
      <animateTransform
        id="rotate"
        attributeName="transform"
        attributeType="XML"
        type="rotate"
        from="0"
        to="-2160"
        dur="2.5s"
        values="0;-2160"
        calcMode="spline"
        keySplines="0.5 0 0.5 1"
        begin="1.2s;rotate.end+2s"
      />

      <path
        d="M156.87,66.67 C156.87,43.9508709 154.28,11 99.41,11 C64.9,11 57.9429865,27.0015075 57.86,39.42 C57.6779021,66.67 55.2,77.86 80.06,77.86 L98.86,77.86 C114.13,78.32 123.212955,88.4007153 123.86,107.92 C124.861763,138.14 120.59,147.48 119.73,148.85 C128.433432,145.549615 134.723432,141.979615 138.6,138.14 C140.756968,136.303382 142.87147,134.463197 144.6,132.14 C153.442917,120.254852 156.87,104.795441 156.87,66.67 Z"
        fill="url(#gradient)"
        fillRule="nonzero"
      >
        <animate
          begin="1s;rotate.end+1.8s"
          dur="0.7s"
          attributeName="d"
          values="M156.87,66.67 C156.87,43.9508709 154.28,11 99.41,11 C64.9,11 57.9429865,27.0015075 57.86,39.42 C57.6779021,66.67 55.2,77.86 80.06,77.86 L98.86,77.86 C114.13,78.32 123.212955,88.4007153 123.86,107.92 C124.861763,138.14 120.59,147.48 119.73,148.85 C128.433432,145.549615 134.723432,141.979615 138.6,138.14 C140.756968,136.303382 142.87147,134.463197 144.6,132.14 C153.442917,120.254852 156.87,104.795441 156.87,66.67 Z;M130.589061,46.2656555 C131.15882,39.040989 127.628835,28.2735007 116.16308,23.6284387 C102.74065,18.190679 92.0309671,26.2176387 87.5958603,33.1714369 C84.9113314,37.3805062 82.4896417,47.3556867 87.3705381,56.2601929 L88.9439765,58.6785976 C94.1412509,65.2579564 101.079854,67.6205117 105.023407,67.9985657 C109.532709,68.4308559 114.171616,67.4172015 115.917823,66.4676514 C118.014944,65.5211763 119.633162,64.6600487 120.772476,63.8842686 C123.379127,61.9466422 124.074871,61.0299272 125.391887,59.566428 C128.792217,55.7879028 130.266975,50.3497925 130.589061,46.2656555 Z"
          fill="freeze"
          calcMode="spline"
          keySplines="0.5 0 1 0.5;"
        />
      </path>
      <path
        d="M156.87,66.67 C156.87,43.9508709 154.28,11 99.41,11 C64.9,11 57.9429865,27.0015075 57.86,39.42 C57.6779021,66.67 55.2,77.86 80.06,77.86 L98.86,77.86 C114.13,78.32 123.212955,88.4007153 123.86,107.92 C124.861763,138.14 120.59,147.48 119.73,148.85 C128.433432,145.549615 134.723432,141.979615 138.6,138.14 C140.756968,136.303382 142.87147,134.463197 144.6,132.14 C153.442917,120.254852 156.87,104.795441 156.87,66.67 Z"
        fill="#001d3a"
        fillRule="nonzero"
        transform="rotate(-180.000000)"
        style={{ transformOrigin: "50%" }}
      >
        <animate
          begin="1s;rotate.end+1.8s"
          dur="0.7s"
          attributeName="d"
          values="M156.87,66.67 C156.87,43.9508709 154.28,11 99.41,11 C64.9,11 57.9429865,27.0015075 57.86,39.42 C57.6779021,66.67 55.2,77.86 80.06,77.86 L98.86,77.86 C114.13,78.32 123.212955,88.4007153 123.86,107.92 C124.861763,138.14 120.59,147.48 119.73,148.85 C128.433432,145.549615 134.723432,141.979615 138.6,138.14 C140.756968,136.303382 142.87147,134.463197 144.6,132.14 C153.442917,120.254852 156.87,104.795441 156.87,66.67 Z;M130.589061,46.2656555 C131.15882,39.040989 127.628835,28.2735007 116.16308,23.6284387 C102.74065,18.190679 92.0309671,26.2176387 87.5958603,33.1714369 C84.9113314,37.3805062 82.4896417,47.3556867 87.3705381,56.2601929 L88.9439765,58.6785976 C94.1412509,65.2579564 101.079854,67.6205117 105.023407,67.9985657 C109.532709,68.4308559 114.171616,67.4172015 115.917823,66.4676514 C118.014944,65.5211763 119.633162,64.6600487 120.772476,63.8842686 C123.379127,61.9466422 124.074871,61.0299272 125.391887,59.566428 C128.792217,55.7879028 130.266975,50.3497925 130.589061,46.2656555 Z"
          fill="freeze"
          calcMode="spline"
          keySplines="0.5 0 1 0.5;"
        />
      </path>

      <path fill="url(#gradient)" fillRule="nonzero">
        <animate
          begin="rotate.end-0.8s"
          dur="0.7s"
          attributeName="d"
          values="M130.589061,46.2656555 C131.15882,39.040989 127.628835,28.2735007 116.16308,23.6284387 C102.74065,18.190679 92.0309671,26.2176387 87.5958603,33.1714369 C84.9113314,37.3805062 82.4896417,47.3556867 87.3705381,56.2601929 L88.9439765,58.6785976 C94.1412509,65.2579564 101.079854,67.6205117 105.023407,67.9985657 C109.532709,68.4308559 114.171616,67.4172015 115.917823,66.4676514 C118.014944,65.5211763 119.633162,64.6600487 120.772476,63.8842686 C123.379127,61.9466422 124.074871,61.0299272 125.391887,59.566428 C128.792217,55.7879028 130.266975,50.3497925 130.589061,46.2656555 Z;M156.87,66.67 C156.87,43.9508709 154.28,11 99.41,11 C64.9,11 57.9429865,27.0015075 57.86,39.42 C57.6779021,66.67 55.2,77.86 80.06,77.86 L98.86,77.86 C114.13,78.32 123.212955,88.4007153 123.86,107.92 C124.861763,138.14 120.59,147.48 119.73,148.85 C128.433432,145.549615 134.723432,141.979615 138.6,138.14 C140.756968,136.303382 142.87147,134.463197 144.6,132.14 C153.442917,120.254852 156.87,104.795441 156.87,66.67 Z"
          fill="freeze"
          calcMode="spline"
          keySplines="0.5 0 1 0.5;"
        />
        <set
          attributeName="visibility"
          attributeType="CSS"
          to="visible"
          begin="rotate.end-0.5"
          fill="freeze"
        />
        <set
          attributeName="visibility"
          attributeType="CSS"
          to="hidden"
          begin="rotate.end+2s"
          fill="freeze"
        />
      </path>
      <path
        fill="#001d3a"
        fillRule="nonzero"
        transform="rotate(-180.000000) translate(0 0)"
        style={{ transformOrigin: "50%" }}
      >
        <animate
          begin="rotate.end-0.8s"
          dur="0.7s"
          attributeName="d"
          values="M130.589061,46.2656555 C131.15882,39.040989 127.628835,28.2735007 116.16308,23.6284387 C102.74065,18.190679 92.0309671,26.2176387 87.5958603,33.1714369 C84.9113314,37.3805062 82.4896417,47.3556867 87.3705381,56.2601929 L88.9439765,58.6785976 C94.1412509,65.2579564 101.079854,67.6205117 105.023407,67.9985657 C109.532709,68.4308559 114.171616,67.4172015 115.917823,66.4676514 C118.014944,65.5211763 119.633162,64.6600487 120.772476,63.8842686 C123.379127,61.9466422 124.074871,61.0299272 125.391887,59.566428 C128.792217,55.7879028 130.266975,50.3497925 130.589061,46.2656555 Z;M156.87,66.67 C156.87,43.9508709 154.28,11 99.41,11 C64.9,11 57.9429865,27.0015075 57.86,39.42 C57.6779021,66.67 55.2,77.86 80.06,77.86 L98.86,77.86 C114.13,78.32 123.212955,88.4007153 123.86,107.92 C124.861763,138.14 120.59,147.48 119.73,148.85 C128.433432,145.549615 134.723432,141.979615 138.6,138.14 C140.756968,136.303382 142.87147,134.463197 144.6,132.14 C153.442917,120.254852 156.87,104.795441 156.87,66.67 Z"
          fill="freeze"
          calcMode="spline"
          keySplines="0.5 0 1 0.5;"
        />
        <set
          attributeName="visibility"
          attributeType="CSS"
          to="visible"
          begin="rotate.end-0.5"
          fill="freeze"
        />
        <set
          attributeName="visibility"
          attributeType="CSS"
          to="hidden"
          begin="rotate.end+2s"
          fill="freeze"
        />
      </path>
    </g>
  </svg>
);

export default SVGSpinner;
