import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";

import { DropdownRowItem, FieldError } from "../";
import { Icon, InputField } from "../../../";
import { useDebounce, useOutsideClick } from "../../../../hooks";

const SearchableSelect = props => {
  const getValue =
    props.getValue ||
    function(item) {
      return item;
    };

  const placeholder = props.placeholder || "Type to search...";

  const [isDropdownVisible, setDropdownVisible] = useState(false);
  const [currentValue, setCurrentValue] = useState("");
  const [items, setItems] = useState(props.items);
  const ref = useRef();
  const inputRef = useRef();
  const debouncedCurrentValue = useDebounce(currentValue, 500);
  useOutsideClick(ref, () => setDropdownVisible(false));

  useEffect(() => {
    if (isDropdownVisible && inputRef.current) {
      inputRef.current.focus();
    }
  }, [isDropdownVisible]);

  useEffect(() => setItems(props.items), [props.items]);

  useEffect(() => {
    const filteredItems = props.items.filter(item =>
      getValue(item)
        .toLowerCase()
        .includes(debouncedCurrentValue.toLowerCase())
    );
    setItems(filteredItems);
  }, [debouncedCurrentValue]);

  const borderStyles = [
    "aui-border",
    props.error && !isDropdownVisible
      ? "aui-border-error-2"
      : "aui-border-grey-2",
    "aui-border-solid"
  ];

  const classes = [
    "aui-rounded",
    "focus:aui-outline-none",
    "aui-appearance-none",
    "aui-bg-white",
    "focus:aui-bg-white",
    "aui-cursor-pointer",
    "aui-text-left",
    "aui-text-dark-1",
    "aui-h-standard-input",
    props.className || "",
    ...borderStyles
  ].join(" ");

  function handleSelect(item) {
    if (props.onSelect) props.onSelect(item);
    setDropdownVisible(false);
    setCurrentValue("");
  }

  function handleInputChange(value) {
    setCurrentValue(value);
    setDropdownVisible(true);
  }

  return (
    <React.Fragment>
      <div className={classes} ref={ref}>
        <InputField
          placeholder={placeholder}
          className="aui-w-full aui-border-box aui-h-full aui-pl-md aui-pr-xl"
          value={currentValue}
          onChange={handleInputChange}
          onFocus={() => setDropdownVisible(true)}
        />
        <Icon
          icon="add"
          className="aui-text-grey-4 aui--ml-xl aui-align-middle aui-cursor-default"
        />
        {isDropdownVisible && (
          <React.Fragment>
            <div className="aui-relative">
              <div className="aui-dropdown-content aui-bg-white aui-z-50 aui-shadow aui-absolute aui-w-full aui-overflow-y-scroll aui-max-h-64">
                {items.map((item, idx) => {
                  return (
                    <DropdownRowItem
                      key={idx}
                      onClick={() => handleSelect(item)}
                    >
                      {getValue(item)}
                    </DropdownRowItem>
                  );
                })}
                {items.length === 0 && !!debouncedCurrentValue && (
                  <DropdownRowItem className="aui-pointer-events-none">
                    Sorry, nothing found.
                  </DropdownRowItem>
                )}
              </div>
            </div>
          </React.Fragment>
        )}
      </div>
      {props.error && !isDropdownVisible && (
        <FieldError>{props.error}</FieldError>
      )}
    </React.Fragment>
  );
};

SearchableSelect.propTypes = {
  items: PropTypes.array.isRequired,
  onSelect: PropTypes.func,
  getValue: PropTypes.func,
  className: PropTypes.string,
  error: PropTypes.string,
  placeholder: PropTypes.string
};

export { SearchableSelect };
