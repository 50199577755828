import * as React from "react";

function SvgGbSct(props) {
  return (
    <svg viewBox="0 0 640 480" {...props}>
      <path fill="#0065bd" d="M0 0h640v480H0z" />
      <path
        stroke="#fff"
        strokeWidth={0.6}
        d="M0 0l5 3M0 3l5-3"
        transform="scale(128 160)"
      />
    </svg>
  );
}

export default SvgGbSct;
