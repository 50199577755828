import * as React from "react";

function SvgMa(props) {
  return (
    <svg viewBox="0 0 640 480" {...props}>
      <path fill="#c1272d" d="M640 0H0v480h640z" />
      <path
        fill="none"
        stroke="#006233"
        strokeWidth={11.704}
        d="M320 179.452l-35.574 109.496 93.12-67.647H262.453l93.122 67.648z"
      />
    </svg>
  );
}

export default SvgMa;
