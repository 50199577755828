import { useState, useEffect } from "react";

export function useValidity(ref) {
  const [validity, setValidity] = useState({});

  useEffect(() => {
    const onUpdate = ({ target }) => {
      setTimeout(() => {
        setValidity({
          badInput: target.validity.badInput,
          customError: target.validity.customError,
          patternMismatch: target.validity.patternMismatch,
          rangeOverflow: target.validity.rangeOverflow,
          rangeUnderflow: target.validity.rangeUnderflow,
          stepMismatch: target.validity.stepMismatch,
          tooLong: target.validity.tooLong,
          tooShort: target.validity.tooShort,
          typeMismatch: target.validity.typeMismatch,
          valid: target.validity.valid,
          valueMissing: target.validity.valueMissing,
          invalid: target.validity.valid === false
        });
      });
    };
    ref.current.addEventListener("invalid", onUpdate, true);
    ref.current.addEventListener("input", onUpdate);

    return () => {
      ref.current.removeEventListener("input", onUpdate, true);
      ref.current.removeEventListener("invalid", onUpdate);
    };
  }, [validity]);

  return validity;
}
