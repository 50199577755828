import React, { useEffect } from "react";
import PropTypes from "prop-types";
import "./index.pcss";

import { Icon, Tooltip } from "../../";
import { FieldError } from "../Forms/FieldError";

import { appendTooltipToBody } from "../../../utils/appendTooltipToBody";

const disabledClassNames = "aui-bg-grey-3 aui-cursor-not-allowed";
const TagContainer = props => {
  const isTooltipVisible =
    props.isAddDisabled && props.disabledStateTooltipMessage.trim() != "";
  return (
    <div
      className={`anchor-tags aui-flex aui-flex-wrap aui-border aui-border-solid aui-border-grey-2 ${
        props.widthClass
      } ${props.backgroundClass} ${
        props.error ? "aui-border aui-border-primary-2" : ""
      }`}
    >
      <div
        className={`aui-flex aui-text-grey-4 aui-font-bold aui-items-center`}
      >
        <span className="aui-pl-6 aui-text-table-cell aui-whitespace-no-wrap aui--mr-5">
          <span className={`${props.labelIcons && "aui-pl-sm"}`}>
            {props.labelIcons &&
              props.labelIcons.map((labelIcon, idx) => {
                return <Icon key={idx} icon={labelIcon} />;
              })}
            <span className="label-text aui-pl-sm aui-pr-sm aui-uppercase">
              {props.label}:
            </span>
            {props.error && (
              <FieldError>
                <div className="aui-p-2">{props.error}</div>
              </FieldError>
            )}
          </span>
        </span>
      </div>

      <div
        className={`as-tags-container aui-flex aui-relative aui-flex-grow aui-pr-sm aui-items-center`}
      >
        <div className="tags-wrapper aui-overflow-hidden aui-pt-3 aui-h-20 aui-top-0 aui-overflow-x-auto aui-absolute aui-whitespace-no-wrap">
          {props.tags}
        </div>
        {!isTooltipVisible ? (
          generateAddButton(props.isAddDisabled, props.onToggle)
        ) : (
          <React.Fragment>
            <div className="aui-relative aui-w-full"></div>
            <Tooltip
              className="aui-ml-8"
              position="bottom"
              message={props.disabledStateTooltipMessage}
              wide
              onMouseEnter={evt =>
                appendTooltipToBody(
                  evt,
                  props.tooltipOffsetLeft,
                  props.tooltipOffsetTop,
                  true
                )
              }
              enableHidden
            >
              {generateAddButton(props.isAddDisabled, props.onToggle)}
            </Tooltip>
          </React.Fragment>
        )}
      </div>
    </div>
  );
};

function generateAddButton(isAddDisabled, onToggle) {
  return (
    <div
      onClick={isAddDisabled ? null : onToggle}
      className={`as-btn-add aui-rounded-full aui-cursor-pointer aui-h-8 aui-w-8 aui-flex aui-items-center aui-justify-center aui-shadow ${
        isAddDisabled ? disabledClassNames : "aui-bg-grey-5"
      } aui-mr-6 aui-m-auto hover:aui-bg-grey-3`}
    >
      <Icon
        className="aui-text-white aui-font-hairline aui-text-modal-header"
        icon="plus"
      />
    </div>
  );
}

TagContainer.propTypes = {
  error: PropTypes.string,
  tags: PropTypes.any,
  labelIcons: PropTypes.array,
  label: PropTypes.string,
  onToggle: PropTypes.func,
  widthClass: PropTypes.string,
  backgroundClass: PropTypes.string,
  isAddDisabled: PropTypes.bool,
  disabledStateTooltipMessage: PropTypes.string,
  tooltipOffsetLeft: PropTypes.number,
  tooltipOffsetTop: PropTypes.number
};

TagContainer.defaultProps = {
  error: null,
  widthClass: "aui-w-full",
  backgroundClass: "aui-bg-highlight-1-alt",
  isAddDisabled: false,
  disabledStateTooltipMessage: "",
  tooltipOffsetLeft: 0,
  tooltipOffsetTop: 0
};

export { TagContainer };
