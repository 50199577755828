import React, { useState } from "react";
import PropTypes from "prop-types";

import { Icon } from "../../";

export function Tag(props) {
  const [isLoading, setIsLoading] = useState(false);

  const className = props.className || "";

  const defaultClassName = [
    "aui-tag",
    "aui-select-none",
    "aui-px-3",
    "aui-py-1",
    "aui-rounded",
    "aui-overflow-hidden",
    "aui-bg-white",
    "aui-border",
    "aui-border-solid",
    "aui-inline-block",
    props.sideIcon && props.sideIconColor
      ? `aui-border-l-0 aui-rounded-l-none aui-text-grey-5 aui-border-${props.sideIconColor}`
      : props.disabled
      ? "aui-border-grey-3 aui-text-grey-5"
      : "aui-border-primary-1 aui-text-primary-1"
  ].join(" ");

  const activeClassName =
    (props.active &&
      [
        "tag-active",
        "aui-bg-success-1",
        "aui-text-success-2",
        "aui-border-none"
      ].join(" ")) ||
    "";

  const inactiveClassName =
    (props.inactive &&
      [
        "tag-inactive",
        "aui-bg-error-1",
        "aui-text-error-2",
        "aui-border-none"
      ].join(" ")) ||
    "";

  const classes = `${defaultClassName} ${activeClassName} ${inactiveClassName} ${className}`.trim();

  const iconClasses = [
    "aui-cursor-pointer",
    "aui-ml-2",
    "aui-text-extra-small",
    "aui-inline-block",
    "aui-pb-2xs",
    "aui-align-middle",
    "hover:aui-text-brand-1",
    "aui-text-primary-2",
    "aui-mt-2xs"
  ].join(" ");

  const sideIconClasses = [
    "aui-inline-block",
    "aui-pb-2xs",
    "aui-align-middle",
    "aui-mt-2xs",
    "aui-mr-2"
  ].join(" ");

  const sideIconColorClasses = [
    `aui-bg-${props.sideIconColor}`,
    `aui-border-${props.sideIconColor}`,
    "aui-flex",
    "aui-items-center",
    "aui-justify-center",
    "aui-border",
    "aui-border-solid",
    "aui-rounded",
    "aui-rounded-r-none",
    "aui-border-r-0",
    "aui-px-3",
    "aui-py-1",
    "aui-text-white"
  ].join(" ");

  const spinnerClasses = [
    "aui-ml-2",
    "aui-inline-block",
    "aui-mb-2xs",
    "aui-align-middle"
  ].join(" ");

  const handleRemove = () => {
    if (props.onRemove) {
      const ret = props.onRemove();
      if (ret && ret.then) {
        setIsLoading(true);
        ret.finally(() => setIsLoading(false));
      }
    }
  };

  return (
    <span
      className={`${
        props.sideIcon ? "aui-flex aui-flex-row aui-items-stretch" : ""
      }`}
    >
      {props.sideIcon && props.sideIconColor && (
        <span className={sideIconColorClasses}>
          <Icon
            icon={props.sideIcon}
            className="aui-font-hairline aui-text-modal-header"
          />
        </span>
      )}
      <span className={classes}>
        <div className="aui-flex aui-flex-row aui-items-center aui-flex-grow aui-h-full">
          {props.sideIcon && !props.sideIconColor && (
            <Icon icon={props.sideIcon} className={sideIconClasses} />
          )}
          <div className="aui-w-full">{props.children}</div>
          {!props.hideIcon && !isLoading && (
            <Icon icon="close" onClick={handleRemove} className={iconClasses} />
          )}
          {isLoading && (
            <i
              className={`fa fa-spinner fa-spin aui-bg-transparent ${spinnerClasses}`}
            />
          )}
        </div>
      </span>
    </span>
  );
}

Tag.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  onRemove: PropTypes.func,
  hideIcon: PropTypes.bool,
  active: PropTypes.bool,
  inactive: PropTypes.bool,
  sideIcon: PropTypes.string,
  sideIconColor: PropTypes.string
};
