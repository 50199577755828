import React, { useState, useRef } from "react";
import {
  getDaysInMonth,
  getDay,
  subDays,
  endOfMonth,
  startOfMonth,
  setDate,
  getDate,
  isEqual
} from "date-fns";
import { chunk } from "lodash";
import PropTypes from "prop-types";

import { useOutsideClick } from "../../../../hooks";

import { WEEKDAYS } from "./constants";
import CalendarNavigation from "./CalendarNavigation";
import Headers from "./CalendarHeaders";

export const Calendar = ({
  date,
  handleSelectDate,
  height,
  width,
  minDate,
  maxDate,
  onClose,
  startDate,
  dateFormatter,
  daysOfTheWeek
}) => {
  // open calendar on the month of the selected date / if null then the start date / if null then today's date
  const [currentMonth, setCurrentMonth] = useState(
    date || startDate || new Date()
  );
  // animate in and out
  const [animation, setAnimation] = useState("aui-zoom-in");
  const ref = useRef();
  // close when user clicks outside component
  useOutsideClick(ref, () => setAnimation("aui-zoom-out"));

  const handleSetMonth = newMonth => {
    if (endOfMonth(newMonth) < minDate) {
      // new month is earlier than minDate, set month to minDate
      newMonth = minDate;
    } else if (startOfMonth(newMonth) > maxDate) {
      // new month is later than maxDate, set month to maxDate
      newMonth = maxDate;
    }
    setCurrentMonth(startOfMonth(newMonth));
  };

  const generateMonth = () => {
    const daysInMonth = getDaysInMonth(currentMonth);
    /* getDay() returns the index of the weekday where Sunday = 0
     * we subtract 1 as we want calendar weeks to start on Monday */
    const startWeekday = getDay(subDays(startOfMonth(currentMonth), 1));
    const endWeekday = getDay(subDays(endOfMonth(currentMonth), 1));
    /* creates an array containing all days in the month with `null`s
     * in place of weekdays in previous and next months
     * this array is split into chunks of 7 for each week in the month */
    const gridDays = chunk(
      [
        ...Array.from({ length: startWeekday }).fill(null),
        ...Array.from({ length: daysInMonth }, (_, i) =>
          setDate(currentMonth, i + 1)
        ),
        ...Array.from({ length: 6 - endWeekday }).fill(null)
      ],
      7
    );
    return gridDays;
  };

  const handleZoomOutOnAnimationEnd = () => {
    if (animation === "aui-zoom-out") {
      onClose();
    }
  };

  return (
    <div
      className={`aui-absolute aui-mt-2 aui-z-40 ${animation}`}
      onAnimationEnd={handleZoomOutOnAnimationEnd}
      ref={ref}
    >
      <div
        className="calendar aui-flex aui-flex-col aui-p-1 aui-rounded aui-bg-white aui-text-grey-5 aui-text-sm aui-font-normal aui-shadow"
        style={{ height: height || "205px", width: width || "220px" }}
      >
        <CalendarNavigation
          currentMonth={currentMonth}
          handleSetMonth={handleSetMonth}
          minDate={minDate}
          maxDate={maxDate}
          dateFormatter={dateFormatter}
        />
        <table
          className="aui-text-s"
          tabIndex="0"
          role="grid"
          aria-label="calendar"
        >
          <Headers headers={daysOfTheWeek ? daysOfTheWeek : WEEKDAYS} />
          <tbody>
            {generateMonth().map((week, i) => (
              <tr className="week" key={`week-${i}`} role="row">
                {week.map((day, i) => {
                  const disabled = day < minDate || day > maxDate;
                  return day ? (
                    <td
                      className={`${
                        disabled
                          ? "aui-text-grey-2"
                          : "hover:aui-bg-grey-1 aui-cursor-pointer"
                      } aui-py-1 aui-rounded aui-text-center ${
                        isEqual(date, day) ? "aui-bg-grey-2" : ""
                      }`}
                      key={`day-cell-${i}`}
                      onClick={
                        disabled ? () => {} : () => handleSelectDate(day)
                      }
                      role="gridcell"
                      aria-selected={isEqual(date, day)}
                    >
                      {getDate(day)}
                    </td>
                  ) : (
                    <td className="aui-cursor-default" key={`day-cell-${i}`}>
                      &nbsp;
                    </td>
                  );
                })}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

Calendar.propTypes = {
  date: PropTypes.instanceOf(Date),
  handleSelectDate: PropTypes.func.isRequired,
  height: PropTypes.string,
  minDate: PropTypes.instanceOf(Date),
  maxDate: PropTypes.instanceOf(Date),
  onClose: PropTypes.func.isRequired,
  startDate: PropTypes.instanceOf(Date),
  width: PropTypes.string,
  dateFormatter: PropTypes.func,
  daysOfTheWeek: PropTypes.object
};
