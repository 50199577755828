import * as React from "react";

function SvgMg(props) {
  return (
    <svg viewBox="0 0 640 480" {...props}>
      <g fillRule="evenodd" strokeWidth="1pt">
        <path fill="#ff3319" d="M213.33 0H640v240H213.33z" />
        <path fill="#00cc28" d="M213.33 240H640v240H213.33z" />
        <path fill="#fff" d="M0 0h213.33v480H0z" />
      </g>
    </svg>
  );
}

export default SvgMg;
