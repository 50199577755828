import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

const DualView = ({
  className,
  height,
  flipped,
  primaryView,
  secondaryView
}) => {
  const viewClasses = [
    "aui-flex",
    "aui-absolute",
    "aui-inset-0",
    "aui-transition-all",
    "aui-ease-in-out",
    "aui-duration-1000",
    "aui-transform",
    "aui-slide",
    className
  ].join(" ");

  const [primaryViewPosition, setPrimaryViewPosition] = useState(
    "aui-translate-x-0"
  );
  const [secondaryViewPosition, setSecondaryViewPosition] = useState(
    "aui-translate-x-full"
  );

  useEffect(() => {
    if (!secondaryView) return;
    setPrimaryViewPosition(
      flipped ? "aui--translate-x-full" : "aui-translate-x-0"
    );
    setSecondaryViewPosition(
      flipped ? "aui-translate-x-0" : "aui-translate-x-full"
    );
  }, [flipped]);

  return (
    <div className={`${height} aui-relative aui-overflow-hidden`}>
      <div className={`${viewClasses} ${primaryViewPosition}`}>
        {primaryView}
      </div>

      <div className={`${viewClasses} ${secondaryViewPosition}`}>
        {secondaryView}
      </div>
    </div>
  );
};

DualView.propTypes = {
  className: PropTypes.string,
  height: PropTypes.string,
  flipped: PropTypes.bool,
  primaryView: PropTypes.any.isRequired,
  secondaryView: PropTypes.any
};

DualView.defaultProps = {
  className: "",
  height: "aui-h-label-editor"
};

export { DualView };
