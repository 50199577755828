import "whatwg-fetch";
import { uuid } from "../utils";

const httpRequest = (url, options = {}, onError) => {
  if (onError) {
    // eslint-disable-next-line no-console
    console.warn(
      "RESTService: `onError` argument is deprecated, use .catch() instead"
    );
  }
  options.headers = options.headers || {};
  options.headers["X-Request-ID"] = uuid();
  return fetch(url, options).then(response => {
    if (!response.ok) {
      if (onError) {
        onError(response);
      }
      return Promise.reject(response);
    }
    return response.text();
  });
};

const httpRequestJson = (url, options, onError) => {
  return httpRequest(url, options, onError).then(text => JSON.parse(text));
};

const httpJson = method => (url, payload, headers = {}, onError) => {
  return httpRequestJson(
    url,
    {
      headers: {
        ...headers,
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      method,
      body: JSON.stringify(payload)
    },
    onError
  );
};

const httpGetJson = httpJson("GET");
const httpPatchJson = httpJson("PATCH");
const httpPostJson = httpJson("POST");
const httpPutJson = httpJson("PUT");
const httpDeleteJson = httpJson("DELETE");

export default {
  httpRequest,
  httpRequestJson,
  httpGetJson,
  httpPatchJson,
  httpPostJson,
  httpPutJson,
  httpDeleteJson
};
