import * as React from "react";

function SvgLb(props) {
  return (
    <svg viewBox="0 0 640 480" {...props}>
      <defs>
        <clipPath id="lb_svg__a">
          <path fillOpacity={0.67} d="M-85.333 0h682.67v512h-682.67z" />
        </clipPath>
      </defs>
      <g clipPath="url(#lb_svg__a)" transform="translate(80) scale(.9375)">
        <g fillRule="evenodd" strokeWidth="1pt">
          <path
            fill="red"
            d="M-128 383.993h767.975v128H-128zM-128 0h767.975v128.001H-128z"
          />
          <path fill="#fff" d="M-128 128.001h767.975v255.992H-128z" />
        </g>
        <path
          fill="#007900"
          d="M252.1 129.95c-7.81 15.593-13.018 15.593-26.034 25.99-5.206 5.198-13.016 7.797-2.603 12.996-10.413 5.197-15.62 7.797-20.827 18.192l2.603 2.599s9.895-4.85 10.414-2.599c1.73 2.078-13.024 10.051-14.929 11.263-1.904 1.212-11.106 6.93-11.106 6.93-13.016 10.397-20.827 7.796-28.637 23.39l26.034-2.597c5.207 18.192-13.017 20.79-26.034 28.588l-20.827 12.996c5.208 18.192 20.827 7.796 33.844 2.598l2.604 2.6v5.197l-26.034 12.996s-30.733 17.584-31.24 18.192c-.21.942 0 5.198 0 5.198 10.413 2.6 26.034 5.199 36.448 0 13.016-5.198 15.619-10.396 31.24-10.396-18.224 12.994-31.24 18.193-52.068 20.791v10.397c15.62 0 26.033 0 39.051-2.599l33.844-10.396c7.81 0 15.62 7.797 13.017 15.593-7.81 28.588-39.052 23.391-49.466 46.781l41.656-15.593c10.413-5.198 20.826-10.396 33.843-7.796 15.62 5.196 15.62 15.594 36.448 20.79l-5.206-12.993c5.206 2.598 10.413 2.598 15.619 5.197 13.018 5.2 15.621 10.396 31.24 7.797-13.016-15.594-15.619-12.994-26.033-23.39-10.413-15.594-15.62-38.984 0-41.584l18.224 5.199c18.223 2.598 18.223-2.599 44.257 7.797 15.621 5.197 20.828 12.994 39.052 7.796-7.81-18.192-36.448-31.188-54.671-36.386 20.826-12.996 15.619 5.198 44.257-2.6v-5.196c-20.826-15.594-28.637-28.59-57.275-28.59l44.259-5.198v-5.198s-43.649-11.451-44.664-11.858c.304-1.32 1.372-3.366 4.27-4.497 8.289 5.366 33.357 4.74 34.78 4.64-.732-6.396-12.61-11.676-23.023-16.873 0 0-44.59-27.483-44.811-29.916.884-6.965 18.314 1.107 37 6.524-5.206-10.396-15.62-15.593-26.033-18.192l15.62-2.598c-10.413-23.391-36.448-20.792-52.067-31.188-10.415-7.797-10.415-12.996-26.034-20.792z"
        />
        <path
          fill="#fff"
          fillRule="evenodd"
          stroke="#fff"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={3.219}
          d="M223.96 303.07c1.932-6.194 4.476-11.674-7.117-16.954-11.593-5.278 5.795 21.114 7.117 16.954zm13.73-12.39c-2.337.304-3.56 8.835 1.117 11.169 5.188.81.917-11.066-1.117-11.169zm13.53-.91c-2.442.712-2.543 12.691 6 10.56 8.543-2.13-.103-11.573-6-10.56zm15.76-30.76c1.83-2.945-.101-15.025-7.425-9.95-7.321 5.077 5.085 10.762 7.425 9.95zm-15.97-10.05c2.239-.812 2.442-8.223-3.964-6.294-6.405 1.93 2.238 7.817 3.964 6.294zm-14.43 2.94s-4.475-6.193-7.933-4.874c-4.373 4.163 8.238 4.975 7.933 4.874zm-49.59 19.79c1.905.173 16.027-2.329 20.908-7.81 4.88-5.483-25.127 2.346-25.127 2.447 0 .101 2.835 4.844 4.22 5.363zm141.11-34.96c.728-1.295-7.517-7.172-12.416-4.856-1.261 4.339 12.375 5.748 12.416 4.856zm-27.76-13.97c1.526-2.233-3.558-11.37-13.727-6.294-10.17 5.076 10.676 9.848 13.727 6.294zm-32.14-5.38s2.541-8.223 8.644-6.6c6.916 5.281-8.34 6.905-8.644 6.6zm-6-6.19c-.917-2.335-7.323-.913-14.644 3.858-7.324 4.772 16.88 1.422 14.644-3.858zm18.71-22.13s6.523-2.92 8.44 0c2.747 4.366-8.541.102-8.44 0zm-5.47-2.86c-1.322-2.64-8.54-2.89-8.355.925-1.21 2.989 9.38 2.432 8.355-.925zm-17.2.01c-.711-1.523-10.98.029-14.032 6.193 4.899 2.382 16.271-2.335 14.032-6.193zm-21.97 6.3s-13.51 8.26-14.339 14.315c.41 5.229 16.778-9.442 16.778-9.442s1.424-5.787-2.439-4.873zM221.32 185c.378-1.68 6.675-5.572 7.22-5.28.51 1.694-5.143 6.28-7.22 5.28zm4.27 31.57c.304-2.437-16.068-2.235-9.864 5.278 5.166 6.301 10.984-4.161 9.864-5.278zm-14.9 10.78c-.854-1.647-2.082-6.038-4.324-6.442-1.827-.103-11.672 1.928-12.425 3.594-.406 1.32 4.075 9.435 5.602 9.638 1.729.71 10.842-5.978 11.147-6.79zm88.33 55.11c.508-1.725 17.239-7.507 23.015-1.976 6.812 9.34-23.421 4.922-23.015 1.976zM345 293.39c3.666-6.204-11.257-13.559-17.592-6.47 2.165 8.517 14.628 11.6 17.592 6.47z"
        />
      </g>
    </svg>
  );
}

export default SvgLb;
