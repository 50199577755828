import PropTypes from "prop-types";
import React from "react";

const SidePanel = props => {
  return (
    <div
      className={`aui-w-1/4 aui-max-w-sm aui-border-0 aui-border-l aui-border-r aui-border-solid aui-border-grey-2 aui-overflow-auto aui-max-h-full aui-h-full aui-relative ${props.className ||
        ""}`}
    >
      <div className="aui-absolute aui-w-full">
        <div className="aui-p-4 aui-flex aui-flex-col">{props.children}</div>
      </div>
    </div>
  );
};
SidePanel.propTypes = {
  className: PropTypes.string,
  children: PropTypes.any
};

export { SidePanel };
