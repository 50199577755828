import React, { useState } from "react";
import PropTypes from "prop-types";
import CheckboxInput from "./CheckboxInput";

export function CheckboxButton(props) {
  const [value, setValue] = props.state;
  let classNames = value
    ? "aui-bg-white aui-border-primary-1 aui-text-primary-1"
    : "aui-bg-grey-1 aui-border-grey-3 aui-text-grey-4";
  if (!props.disabled) {
    classNames += " aui-cursor-pointer";
    classNames += value
      ? " hover:aui-bg-highlight-1 hover:aui-text-primary-1"
      : " hover:aui-bg-grey-2 hover:aui-text-black";
  } else {
    classNames += " aui-cursor-not-allowed";
    classNames += " aui-opacity-50";
  }
  return (
    <button
      className={
        classNames +
        " aui-border aui-border-solid aui-py-2 aui-px-4 aui-mr-1 aui-mb-1 aui-rounded aui-outline-none"
      }
      {...props}
      onClick={() => setValue(!value)}
    >
      <input
        className="aui-hidden"
        type="checkbox"
        disabled={props.disabled}
        defaultChecked={value}
      />
      {props.children}
    </button>
  );
}
CheckboxButton.propTypes = {
  children: PropTypes.any,
  state: PropTypes.array,
  disabled: PropTypes.bool
};

const CheckboxGroupFactory = CheckboxComponentClass => {
  const component = props => {
    const [value, setValue] = props.state;
    return (
      <div>
        {Object.keys(props.options).map(key => {
          const state = [
            value.includes(key),
            isChecked => {
              if (isChecked) {
                setValue([...value, key]);
              } else {
                setValue(value.filter(k => k !== key));
              }
            }
          ];
          const attributes = typeof definition === "object" ? definition : {};
          return (
            <CheckboxComponentClass
              key={key}
              {...attributes}
              state={state}
              disabled={props.disabled}
            >
              {props.options[key]}
            </CheckboxComponentClass>
          );
        })}
      </div>
    );
  };
  component.propTypes = {
    options: PropTypes.object,
    state: PropTypes.array,
    disabled: PropTypes.bool
  };
  return component;
};

const CheckboxButtonGroup = CheckboxGroupFactory(CheckboxButton);
const CheckboxInputGroup = CheckboxGroupFactory(CheckboxInput);

export { CheckboxButtonGroup, CheckboxInputGroup, CheckboxInput };
