import * as React from "react";

function SvgNl(props) {
  return (
    <svg viewBox="0 0 640 480" {...props}>
      <g fillRule="evenodd" strokeWidth="1pt" transform="scale(1.25 .9375)">
        <rect width={512} height={509.76} fill="#fff" rx={0} ry={0} />
        <rect
          width={512}
          height={169.92}
          y={342.08}
          fill="#21468b"
          rx={0}
          ry={0}
        />
        <path fill="#ae1c28" d="M0 0h512v169.92H0z" />
      </g>
    </svg>
  );
}

export default SvgNl;
