import React, { useState } from "react";
import PropTypes from "prop-types";
import { Icon, SimpleSpinner } from "../../";
import { useShortcut } from "../../../hooks/useShortcut";

const disabledBorderClassName = "aui-border-grey-2 aui-border-solid";
const disabledTextClassName = "aui-text-grey-3 aui-cursor-not-allowed";

const disabledClassNames = [disabledBorderClassName, disabledTextClassName];
const buttonTypes = [];

function ButtonFactory(colorsClassNames, sizeClassNames, disabledClassNames) {
  const component = function({ loadingOnClick = true, ...props }) {
    const [isLoading, setIsLoading] = useState(false);

    const isDisabled = props.disabled || isLoading;

    const colors = isDisabled ? disabledClassNames : colorsClassNames;
    const spinnerColors = colors.filter(color => color.indexOf("text") >= 0);

    const allClassNames = [
      "aui-border",
      ...colors,
      ...(isDisabled ? [] : ["aui-cursor-pointer"]),
      ...sizeClassNames,
      ...(props.block ? ["aui-block aui-w-full"] : []),
      "aui-outline-none",
      "aui-font-semibold",
      ...(props.className ? [props.className] : [])
    ];

    const handleClick = evt => {
      if (props.onClick && !isDisabled) {
        const ret = props.onClick(evt);
        if (loadingOnClick && ret && ret.then) {
          setIsLoading(true);
          ret.catch(() => {}).finally(() => setIsLoading(false));
        }
      }
    };

    if (props.shortcut) {
      useShortcut(
        {
          [props.shortcut]: handleClick
        },
        false,
        [props.onClick]
      );
    }

    return (
      <button
        {...props}
        onClick={handleClick}
        className={allClassNames.join(" ")}
      >
        {props.icon && (
          <Icon
            icon={props.icon}
            className={
              isLoading ? "aui-hidden" : props.children ? "aui-mr-2" : ""
            }
          />
        )}

        <SimpleSpinner
          wrapperClassName={
            isLoading ? "aui-mr-2 aui-inline-block" : "aui-hidden"
          }
          transparent
        />
        {props.children}
      </button>
    );
  };
  component.propTypes = {
    children: PropTypes.any,
    onClick: PropTypes.func,
    icon: PropTypes.string,
    small: PropTypes.bool,
    className: PropTypes.string,
    disabled: PropTypes.bool,
    block: PropTypes.bool,
    shortcut: PropTypes.number,
    loadingOnClick: PropTypes.bool
  };
  buttonTypes.push(component);
  return component;
}

const ButtonGroupLayout = ({ children, className, block }) => {
  return (
    <div
      className={`${
        block ? "aui-block aui-w-full" : ""
      } aui--mb-2 aui--mr-2 ${className || ""}`}
    >
      {React.Children.map(children, (child, idx) =>
        child && buttonTypes.includes(child.type) ? (
          <span
            key={idx}
            className={`aui-mb-2 aui-mr-2 ${
              block ? "aui-block aui-w-full" : "aui-inline-block"
            }`}
          >
            {child}
          </span>
        ) : (
          <React.Fragment key={idx}>{child}</React.Fragment>
        )
      )}
    </div>
  );
};
ButtonGroupLayout.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string,
  block: PropTypes.bool
};

const NoMarginButtonWrapper = props => {
  const [warningIssued, setWarningIssued] = useState(false);
  if (!warningIssued) {
    // eslint-disable-next-line no-console
    console.warn(
      "<NoMarginButtonWrapper> component is deprecated, please use <ButtonGroupLayout/> instead"
    );
    setWarningIssued(true);
  }
  return <ButtonGroupLayout>{props.children}</ButtonGroupLayout>;
};
NoMarginButtonWrapper.propTypes = {
  children: PropTypes.any
};

const colors = {
  primary: [
    "aui-bg-grey-5",
    "aui-border-grey-5",
    "aui-border-solid",
    "aui-text-white",
    "hover:aui-border-primary-2",
    "hover:aui-bg-primary-2"
  ],
  secondary: [
    "aui-bg-white",
    "aui-border-dark-1",
    "aui-border-solid",
    "aui-text-dark-1",
    "hover:aui-border-primary-2",
    "hover:aui-text-primary-2"
  ],
  danger: [
    "aui-bg-white",
    "aui-border-primary-2",
    "aui-border-solid",
    "aui-text-primary-2",
    "hover:aui-bg-primary-2",
    "hover:aui-text-white"
  ],
  ternary: [
    "aui-bg-transparent",
    "aui-border-transparent",
    "aui-text-dark-1",
    "hover:aui-text-primary-2"
  ],
  iconOnly: ["aui-bg-white", "hover:aui-bg-grey-1"]
};
const sizes = {
  regular: ["aui-py-3", "aui-px-4", "aui-rounded", "aui-text-base"],
  small: ["aui-py-2", "aui-px-4", "aui-rounded"],
  ternaryRegular: ["aui-py-3", "aui-px-4"],
  ternarySmall: ["aui-py-2", "aui-px-4"],
  iconOnly: [
    "aui-w-8",
    "aui-h-8",
    "aui-p-2",
    "aui-text-sm",
    "aui-cursor-pointer"
  ]
};
const PrimaryButton = ButtonFactory(
  colors.primary,
  sizes.regular,
  disabledClassNames
);
const PrimaryButtonSmall = ButtonFactory(
  colors.primary,
  sizes.small,
  disabledClassNames
);
const SecondaryButton = ButtonFactory(
  colors.secondary,
  sizes.regular,
  disabledClassNames
);
const SecondaryButtonSmall = ButtonFactory(
  colors.secondary,
  sizes.small,
  disabledClassNames
);
const TernaryButton = ButtonFactory(colors.ternary, sizes.ternaryRegular, [
  "aui-border-transparent",
  disabledTextClassName
]);
const IconOnlyButton = ButtonFactory(colors.iconOnly, sizes.iconOnly, [
  "aui-border-transparent",
  disabledTextClassName
]);
const TernaryButtonSmall = ButtonFactory(colors.ternary, sizes.ternarySmall, [
  "aui-border-transparent",
  disabledTextClassName
]);
const DangerButton = ButtonFactory(
  colors.danger,
  sizes.regular,
  disabledClassNames
);
const DangerButtonSmall = ButtonFactory(
  colors.danger,
  sizes.small,
  disabledClassNames
);

export {
  PrimaryButton,
  SecondaryButton,
  TernaryButton,
  DangerButton,
  PrimaryButtonSmall,
  SecondaryButtonSmall,
  TernaryButtonSmall,
  DangerButtonSmall,
  ButtonGroupLayout,
  NoMarginButtonWrapper,
  IconOnlyButton
};
