import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import { Icon } from "../../../../";

const TextEditorButton = ({ onClick, icon, isToggled }) => {
  const [toggled, setIsToggled] = useState(isToggled);
  const textColor = toggled ? "aui-text-primary-2" : "aui-text-grey-4";

  useEffect(() => setIsToggled(isToggled), [isToggled]);

  function handleClick(e) {
    onClick(e);
  }

  return (
    <div
      className={`aui-cursor-pointer hover:aui-text-primary-2 ${textColor}`}
      onMouseDown={handleClick}
    >
      <Icon icon={icon} className="aui-text-xs" />
    </div>
  );
};

TextEditorButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  icon: PropTypes.string.isRequired,
  isToggled: PropTypes.bool
};

export { TextEditorButton };
