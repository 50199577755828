import * as React from "react";

function SvgWs(props) {
  return (
    <svg viewBox="0 0 640 480" {...props}>
      <g fillRule="evenodd" strokeWidth="1pt">
        <path fill="red" d="M0 0h640v480H0z" />
        <path fill="#00006b" d="M0 0h314.407v157.21H0z" />
        <path
          fill="#fff"
          d="M162.77 144.4l-12.468-8.415-11.95 8.555 3.795-15.007-11.471-9.25 14.817-.858 4.862-14.274 5.357 14.477 14.477.427-11.504 9.81zm-2.136-99.826l-9.975-6.41-9.795 6.362 2.72-11.953-8.781-7.817 11.66-.977 4.357-11.192 4.49 11.349 11.48.9-8.888 7.99zm-44.083 35.922l-9.708-6.66-9.922 6.658 3.089-11.673-9.147-7.768 11.607-.554 4.273-11.46 4.091 11.33 11.781.687-9.08 7.556zm88.383-8.026l-9.315-6.01-9.064 6.083 2.608-11.083-8.35-7.096 10.926-.841 3.899-10.468 4.143 10.564 10.763.625-8.362 7.37zm-26.052 26.247l-6.21-3.868-6.188 3.907 1.613-7.347-5.482-4.924 7.208-.673 2.804-6.95 2.841 6.93 7.213.63-5.453 4.956z"
        />
      </g>
    </svg>
  );
}

export default SvgWs;
