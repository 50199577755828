import * as React from "react";

function SvgTj(props) {
  return (
    <svg viewBox="0 0 640 480" {...props}>
      <path fill="#060" d="M0 0h640v480H0z" />
      <path fill="#fff" d="M0 0h640v342.857H0z" />
      <path fill="#c00" d="M0 0h640v137.143H0z" />
      <path
        fill="#f8c300"
        d="M300.8 233.623a8.571 8.571 0 0115.977 4.046v34.285h6.446V237.67a8.571 8.571 0 0115.977-4.046 20.229 20.229 0 10-38.4 0"
      />
      <path
        fill="#fff"
        d="M305.394 224.674A13.714 13.714 0 01320 231.223a13.714 13.714 0 0114.606-6.549 14.743 14.743 0 00-29.212 0"
      />
      <path
        id="tj_svg__a"
        fill="#f8c300"
        d="M316.777 258.259a26.057 26.057 0 01-43.78 16.673 27.086 27.086 0 01-40.96 11.978c2.503 24.978 39.944 19.878 42.734-4.409 11.763 20.65 37.627 14.736 45.195-10.56z"
      />
      <use
        width="100%"
        height="100%"
        fill="#f8c300"
        transform="matrix(-1 0 0 1 640 0)"
        xlinkHref="#tj_svg__a"
      />
      <path
        id="tj_svg__b"
        fill="#f8c300"
        d="M291.776 302.613c-5.224 11.277-15.665 13.214-24.798 4.11 0 0 3.671-2.593 7.602-3.309-.737-3.136.774-7.475 2.904-9.822 2.234 1.527 5.351 4.587 6.144 8.142 5.505-.712 8.148.88 8.148.88z"
      />
      <use
        width="100%"
        height="100%"
        fill="#f8c300"
        transform="rotate(9.37 320 551.314)"
        xlinkHref="#tj_svg__b"
      />
      <use
        width="100%"
        height="100%"
        fill="#f8c300"
        transform="rotate(18.74 320 551.314)"
        xlinkHref="#tj_svg__b"
      />
      <path
        fill="none"
        stroke="#f8c300"
        strokeWidth={10.971}
        d="M253.486 327.771a233.143 233.143 0 01133.028 0"
      />
      <g fill="#f8c300" transform="translate(320 164.571) scale(.68571)">
        <path
          id="tj_svg__c"
          d="M301930 415571l-790463-574305h977066l-790463 574305L0-513674z"
          transform="scale(.00005)"
        />
      </g>
      <g
        id="tj_svg__d"
        fill="#f8c300"
        transform="translate(320 260.571) scale(.68571)"
      >
        <use
          width="100%"
          height="100%"
          transform="translate(-70 -121.244)"
          xlinkHref="#tj_svg__c"
        />
        <use
          width="100%"
          height="100%"
          transform="translate(-121.244 -70)"
          xlinkHref="#tj_svg__c"
        />
        <use
          width="100%"
          height="100%"
          transform="translate(-140)"
          xlinkHref="#tj_svg__c"
        />
      </g>
      <use
        width="100%"
        height="100%"
        fill="#f8c300"
        transform="matrix(-1 0 0 1 640 0)"
        xlinkHref="#tj_svg__d"
      />
    </svg>
  );
}

export default SvgTj;
