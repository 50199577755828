import React, { useRef, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { includes } from "lodash";

import { DropdownRowItem } from "../";
import {
  Icon,
  PrimaryButtonSmall,
  SimpleSpinner,
  PrimaryButton
} from "../../../";
import { CheckboxInput, InputField } from "../";
import { FieldError } from "../FieldError";
import { useDebounce, useOutsideClick } from "../../../../hooks";

const MultiCheckboxSelect = ({
  buttonLabel,
  checkboxes,
  className,
  disabled,
  error,
  getLabel,
  grouped,
  isDropdownVisible,
  items,
  onClick,
  onSearch,
  onSelect,
  pills,
  placeholder,
  searchThreshold,
  selected,
  selectAllItems,
  selectAllTickBoxLabel,
  selectedLabels,
  smallLabels,
  showButton,
  showSelectAllTickBox,
  treeCheckBoxGroup
}) => {
  const searchFilterEnabled = grouped
    ? getLength(items) >= searchThreshold
    : items.length >= searchThreshold;

  const [isVisible, setVisible] = useState(isDropdownVisible);
  const [filteredItems, setFilteredItems] = useState(items);
  const [searchFilter, setSearchFilter] = useState("");
  const [selectedItems, setSelectedItems] = useState(selected || []);
  const [selectedParents, setSelectedParents] = useState(selectedLabels || []);
  const [selectAll, setSelectAll] = useState(selectAllItems);
  const [isSearching, setIsSearching] = useState();

  const debouncedSearchFilter = useDebounce(searchFilter, onSearch ? 500 : 0);

  const triggerRef = useRef();

  useEffect(() => {
    setSelectedItems(selected);
  }, [selected]);

  useEffect(() => {
    // When on a treeCheckBoxGroup, if all parents have been selected, select `ALL` checkbox too
    if (
      treeCheckBoxGroup &&
      selectedParents &&
      selectedParents.length === items.length
    ) {
      setSelectAll(true);
    }
  }, [selectedParents]);

  useEffect(() => {
    if (treeCheckBoxGroup && selectAllItems) {
      const children = items.map(item => item.options).flat();
      const parents = items.map(item => item.id);
      setSelectedItems(children);
      setSelectedParents(parents);
    }
  }, [selectAllItems]);

  useEffect(() => {
    let selectedChildren = [];
    if (selectedLabels && selectedLabels.length > 0) {
      setSelectedParents(selectedLabels);
      selectedLabels.map(parent => {
        const children = items
          .filter(item => item.id === parent)[0]
          .options.flat();
        selectedChildren.push(...children);
      });
      setSelectedItems(selectedItems.concat(selectedChildren));
    }
  }, [selectedLabels]);

  useEffect(() => {
    !showButton && onSelect && onSelect(selectedItems);
  }, [selectedItems]);

  useEffect(() => {
    if (!debouncedSearchFilter) {
      setFilteredItems(items);
      return;
    }

    if (onSearch) {
      setIsSearching(true);
      onSearch(debouncedSearchFilter).then(result => {
        setIsSearching(false);
        setFilteredItems(result.filter(item => item !== selectedItems));
      });
    } else {
      let filteredItems = [];
      if (grouped) {
        filteredItems = items
          .map(object => {
            return {
              label: object.label,
              options: object.options.filter(
                item =>
                  item !== selectedItems &&
                  getLabel(item)
                    .toLowerCase()
                    .includes(debouncedSearchFilter.toLowerCase())
              )
            };
          })
          .filter(object => object.options.length > 0);
      } else {
        filteredItems = items.filter(
          item =>
            item !== selectedItems &&
            getLabel(item)
              .toLowerCase()
              .includes(debouncedSearchFilter)
        );
      }
      setFilteredItems(filteredItems);
    }
  }, [debouncedSearchFilter, items]);

  useOutsideClick(triggerRef, () => setVisible(false));

  const borderStyles = [
    "aui-rounded",
    "aui-border",
    error && !isVisible ? "aui-border-error-2" : "aui-border-grey-2",
    "aui-border-solid"
  ].join(" ");

  const disabledClasses = [
    "aui-pointer-events-none",
    "aui-border-grey-2",
    "aui-bg-grey-1"
  ];
  const activeClasses = ["aui-cursor-pointer"];

  const stateStyles = disabled || isSearching ? disabledClasses : activeClasses;

  const classes = [
    "aui-font-sans",
    "focus:aui-outline-none",
    "aui-appearance-none",
    "aui-bg-white",
    "focus:aui-bg-white",
    "aui-text-left",
    "aui-text-dark-1",
    className || "",
    ...stateStyles
  ].join(" ");

  function handleToggleClick(e) {
    if (disabled) return;

    if (onClick) onClick(e);
    setSearchFilter("");
    setVisible(!isVisible);
  }

  function handleKeyDown(evt) {
    evt.nativeEvent.stopImmediatePropagation();
    if (evt.key === "Enter" && !isSearching) {
      if (grouped) {
        return handleSelect(filteredItems[0].options[0]);
      } else {
        return handleSelect(filteredItems[0]);
      }
    }
    if (evt.key === "Escape") {
      setVisible(false);
    }
  }

  function handleTabIndex(evt) {
    evt.nativeEvent.stopImmediatePropagation();
    if (evt.key === "Enter") {
      return handleToggleClick();
    }
  }

  function handleSelect(item) {
    if (!item) return;

    if (includes(selectedItems, item)) {
      const newSelected = item.id
        ? selectedItems.filter(el => el.id !== item.id)
        : selectedItems.filter(el => el !== item);

      setSelectedItems(newSelected);
      // When deselecting a child, the parent needs to be deselected too
      treeCheckBoxGroup && uncheckParent(item.parent);
    } else {
      const updatedItems = selectedItems.concat([item]);
      setSelectedItems(updatedItems);
      // If all children are selected, their parent needs to be selected too
      treeCheckBoxGroup && checkParent(item.parent, updatedItems);
    }
  }

  function handleSelectAll(checkAll) {
    let children = [];
    let parents = [];
    if (checkAll) {
      children = items.map(item => item.options).flat();
      parents = items.map(item => item.id);
    }
    setSelectedItems(children);
    setSelectedParents(parents);
  }

  function checkParent(parent, updatedItems) {
    const childrenCount = items.filter(child => child.id === parent)[0].options
      .length;
    const selectedChildrenCount = updatedItems.filter(
      child => child.parent === parent
    ).length;
    if (childrenCount === selectedChildrenCount) {
      setSelectedParents(selectedParents.concat(parent));
    }
  }

  function uncheckParent(parent) {
    // Remove parent from selectedParents
    const selectedLabels = selectedParents.filter(label => label !== parent);
    setSelectedParents(selectedLabels);
    setSelectAll(false);
  }

  function handleParentChecked(id) {
    // Handles logic for checking/unchecking parents + their children
    if (!id) return;

    let selectedChildren = [];
    let selectedLabels = [];

    if (includes(selectedParents, id)) {
      selectedChildren = selectedItems.filter(item => item.parent !== id);
      selectedLabels = selectedParents.filter(item => item !== id);
      setSelectAll(false);
    } else {
      const selected = items.find(item => item.id === id).options;
      selectedChildren = selectedItems.concat(selected);
      selectedLabels = selectedParents.concat(id);
    }
    setSelectedItems(selectedChildren);
    setSelectedParents(selectedLabels);
  }

  function getLength(items) {
    return items.reduce((agg, item) => agg + item.options.length, 0);
  }

  function renderGroupedList(items) {
    return items
      .filter(object => object.options.length > 0)
      .map((groupedItem, key) => {
        return (
          <React.Fragment key={key}>
            <div
              className={`${
                checkboxes && !treeCheckBoxGroup ? "aui-mt-4" : ""
              } aui-border-0 aui-border-b aui-border-l aui-border-r aui-border-grey-2 aui-border-solid`}
            ></div>
            <div
              className={`${checkboxes && "aui-pb-0"} ${smallLabels &&
                "aui-text-sm aui-text-grey-3"} ${treeCheckBoxGroup &&
                "aui-font-normal aui-p-6"}
              ${!treeCheckBoxGroup && !smallLabels && "aui-font-extrabold"}
              ${!treeCheckBoxGroup && "aui-p-3"} aui-py-4`}
            >
              {(treeCheckBoxGroup && renderCheckboxGroupParent(groupedItem)) ||
                groupedItem.label}
            </div>
            {renderList(groupedItem.options)}
          </React.Fragment>
        );
      });
  }

  function renderCheckboxGroupParent(item) {
    const isChecked = includes(selectedParents, item.id);
    const setChecked = () => handleParentChecked(item.id);
    return (
      <CheckboxInput state={[isChecked, setChecked]}>
        {item.label}
      </CheckboxInput>
    );
  }

  function renderSelectAll() {
    const isChecked = selectAll;
    const setChecked = () => {
      handleSelectAll(!isChecked);
      setSelectAll(!isChecked);
    };
    return (
      <>
        <div
          className={`aui-border-0 aui-border-b aui-border-l aui-border-r aui-border-grey-2 aui-border-solid`}
        ></div>
        <div
          className={`${checkboxes &&
            "aui-pb-0"} aui-font-normal aui-p-6 aui-py-4`}
        >
          <CheckboxInput state={[isChecked, setChecked]}>
            {selectAllTickBoxLabel}
          </CheckboxInput>
        </div>
      </>
    );
  }

  function renderList(items) {
    return items.map((item, idx) => {
      if (checkboxes) {
        const isChecked = includes(selectedItems, item);
        const setChecked = () => handleSelect(item);
        return (
          !item.hide && (
            <div
              className={`${treeCheckBoxGroup &&
                "aui-pl-12"} aui-dropdown-item aui-px-3 aui-whitespace-no-wrap`}
              key={idx}
              role="option"
            >
              <CheckboxInput state={[isChecked, setChecked]}>
                {getLabel(item)}
                {item.description && (
                  <span className="aui-text-grey-3"> ({item.description})</span>
                )}
              </CheckboxInput>
            </div>
          )
        );
      } else {
        return (
          <DropdownRowItem
            key={idx}
            className={`
              aui-whitespace-no-wrap
              ${stateStyles.join(" ")}
              ${selectedItems &&
                includes(selectedItems, item) &&
                "aui-bg-highlight-1"}
            `}
            onClick={() => handleSelect(item)}
          >
            {getLabel(item)}
          </DropdownRowItem>
        );
      }
    });
  }

  const renderSelectedCount = () => {
    if (pills) {
      return selectedItems.map(item => {
        return (
          <PrimaryButtonSmall
            className="aui-mr-2 aui-mb-1"
            key={`tag-${item.id}`}
            onClick={e => {
              e.stopPropagation();
              handleSelect(item);
            }}
          >
            <div className="aui-flex aui-items-center">
              {getLabel(item)}
              <Icon className="aui-ml-3 aui-text-xs" icon="close" />
            </div>
          </PrimaryButtonSmall>
        );
      });
    } else {
      return selectedItems.map(getLabel).join(", ");
    }
  };

  const renderButton = onClick => (
    <div className="aui-flex">
      <PrimaryButton
        className="aui-mt-2 aui-w-full aui-mx-6"
        onClick={() => onClick()}
      >
        {buttonLabel || "Submit"}
      </PrimaryButton>
    </div>
  );

  return (
    <React.Fragment>
      <div className={classes} ref={triggerRef} role="select">
        <div
          onClick={handleToggleClick}
          onKeyDown={handleTabIndex}
          style={{ gridTemplateColumns: "1fr 0fr" }}
          className={
            `aui-p-3 ${
              pills ? "aui-pb-2" : "aui-whitespace-no-wrap"
            } aui-dropdown-toggle aui-grid aui-grid-flow-col aui-items-center ` +
            borderStyles
          }
          tabIndex={0}
        >
          <span
            className={`${!pills && "aui-truncate"} aui-flex-grow aui-pr-1`}
          >
            {(!treeCheckBoxGroup &&
              selectedItems &&
              selectedItems.length &&
              renderSelectedCount()) || (
              <span className="aui-text-grey-5">
                {placeholder || "Select item(s)"}
              </span>
            )}
          </span>
          {!disabled && (
            <React.Fragment>
              {!isSearching ? (
                <Icon
                  icon={isVisible ? "caret-up" : "caret-down"}
                  className={`${
                    pills ? "aui-self-end aui-pb-2" : ""
                  } aui-text-sm aui-text-grey-5 aui-float-right`}
                />
              ) : (
                <SimpleSpinner
                  transparent
                  className="aui-float-right aui-text-grey-4"
                />
              )}
            </React.Fragment>
          )}
        </div>
        {isVisible && (
          <div className="aui-relative">
            <div
              className={
                "aui-dropdown-content aui-box-border aui-bg-white aui-z-50 aui-shadow-sm aui-absolute aui-w-full " +
                borderStyles
              }
            >
              {searchFilterEnabled && (
                <div className="aui-flex aui-flex-col aui-p-1 aui-bg-grey-1">
                  <InputField
                    autoFocus
                    placeholder="Type to search..."
                    type="text"
                    borderColor="aui-border-transparent aui-shadow-none focus:aui-bg-grey-1"
                    onKeyDown={handleKeyDown}
                    onChange={value => setSearchFilter(value.toLowerCase())}
                  />
                </div>
              )}
              <div
                className={`aui-min-h-20 ${checkboxes &&
                  "aui-pb-4"} ${!treeCheckBoxGroup &&
                  "aui-overflow-y-auto aui-max-h-64"}`}
              >
                {filteredItems.length === 0 && (
                  <DropdownRowItem disabled>No items in list.</DropdownRowItem>
                )}
                {grouped
                  ? renderGroupedList(filteredItems)
                  : renderList(filteredItems)}
                {showSelectAllTickBox && renderSelectAll()}
                {showButton &&
                  renderButton(() => {
                    onSelect(selectedItems);
                    setVisible(false);
                  })}
              </div>
            </div>
          </div>
        )}
      </div>
      {error && !isVisible && <FieldError>{error}</FieldError>}
    </React.Fragment>
  );
};

MultiCheckboxSelect.propTypes = {
  buttonLabel: PropTypes.string,
  checkboxes: PropTypes.bool,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  error: PropTypes.string,
  getLabel: PropTypes.func,
  grouped: PropTypes.bool,
  isDropdownVisible: PropTypes.bool,
  items: PropTypes.array,
  onClick: PropTypes.func,
  onSearch: PropTypes.func,
  onSelect: PropTypes.func,
  pills: PropTypes.bool,
  placeholder: PropTypes.string,
  searchThreshold: PropTypes.number,
  selected: PropTypes.array,
  selectAllItems: PropTypes.bool,
  selectAllTickBoxLabel: PropTypes.string,
  selectedLabels: PropTypes.arrayOf(PropTypes.string),
  showButton: PropTypes.bool,
  showSelectAllTickBox: PropTypes.bool,
  smallLabels: PropTypes.bool,
  treeCheckBoxGroup: PropTypes.bool
};

MultiCheckboxSelect.defaultProps = {
  items: [],
  getLabel: item => {
    return item;
  },
  isDropdownVisible: false,
  searchThreshold: 10,
  treeCheckBoxGroup: false
};

export { MultiCheckboxSelect };
