import React, { useState } from "react";
import PropTypes from "prop-types";
import { FieldError } from "../FieldError";
import { Icon, TextBody, InfoPopup } from "../../../";

import "./index.pcss";

export function RadioButton(props) {
  const value = props.selected;

  const errorBorder = "aui-border-error-2";
  const regularBorder = !props.disabled
    ? "aui-border-grey-5"
    : "aui-border-grey-7";

  let borderStyles = `aui-border aui-border-solid ${
    props.error ? errorBorder : regularBorder
  }`;

  let classNames = value
    ? `${
        props.backgroundClassName ? props.backgroundClassName : "aui-bg-dark-1"
      } ${borderStyles} aui-text-white`
    : `aui-bg-white ${borderStyles} aui-text-dark-1`;

  if (!props.disabled) {
    classNames += " aui-cursor-pointer";
    classNames += value
      ? " hover:aui-bg-primary-2 hover:aui-text-white"
      : " hover:aui-text-primary-2";
  } else {
    classNames += " aui-cursor-not-allowed";
    classNames += value ? " aui-bg-grey-3" : " aui-text-grey-7";
  }
  if (props.isFirst) {
    classNames += " aui-rounded-l aui-border-r-0";
  }
  if (props.isLast) {
    classNames += " aui-rounded-r aui-border-l-0";
  }

  if (props.className) {
    classNames += " " + props.className;
  }

  if (props.shouldCapitalize) {
    classNames += " aui-text-sm aui-uppercase";
  } else {
    classNames += " aui-text-md";
  }

  return (
    <button
      className={
        classNames +
        " aui-py-3 aui-px-4 aui-mb-1 aui-outline-none aui-font-semibold"
      }
      disabled={props.disabled}
      onClick={props.onChange}
    >
      <input
        className="aui-hidden"
        type="radio"
        name={props.name}
        disabled={props.disabled}
        checked={value}
        onChange={props.onChange}
      />
      {props.icon && <Icon icon={props.icon} className="aui-mr-sm aui-pl-1" />}
      {props.children}
      {props.tooltip && (
        <InfoPopup
          infoBody={props.tooltip}
          className="aui-normal-case aui-text-grey-5 aui-w-56 aui-leading-5"
          wrapperClassName="aui-top-0.5"
        >
          <Icon icon={"more-info"} className="aui-ml-2 aui-top-0.5" />
        </InfoPopup>
      )}
    </button>
  );
}
RadioButton.propTypes = {
  onChange: PropTypes.func,
  children: PropTypes.any,
  disabled: PropTypes.bool,
  name: PropTypes.string,
  error: PropTypes.string,
  value: PropTypes.any,
  selected: PropTypes.bool,
  isFirst: PropTypes.bool,
  isLast: PropTypes.bool,
  icon: PropTypes.string,
  tooltip: PropTypes.string,
  className: PropTypes.string,
  backgroundClassName: PropTypes.string,
  shouldCapitalize: PropTypes.bool
};

RadioButton.defaultProps = {
  shouldCapitalize: true
};

export function RadioInput(props) {
  let classNames = "";
  if (!props.disabled) {
    classNames += " aui-cursor-pointer";
  } else {
    classNames += " aui-cursor-not-allowed";
  }

  classNames += props.className ? ` ${props.className}` : "";
  return (
    <label className={"aui-flex" + classNames}>
      <input
        type="radio"
        className="aui-hidden"
        name={props.name}
        error={props.error}
        value={props.value}
        checked={props.selected}
        disabled={props.disabled}
        onChange={props.onChange}
      />
      <div
        className={
          "aui-radio-icon aui-text-xl aui-mt-1" +
          (props.error ? " error" : "") +
          (props.disabled ? " disabled" : "") +
          (props.selected ? " selected" : "")
        }
      />
      <div className="aui-ml-2 aui-flex-grow">
        <TextBody>{props.children}</TextBody>
      </div>
    </label>
  );
}
RadioInput.propTypes = {
  onChange: PropTypes.func,
  children: PropTypes.any,
  disabled: PropTypes.bool,
  name: PropTypes.string,
  className: PropTypes.string,
  error: PropTypes.string,
  value: PropTypes.any,
  selected: PropTypes.bool
};

let radioId = 1;
const RadioGroupFactory = (
  RadioComponentClass,
  { wrapper = children => children, optionWrapper = children => children } = {}
) => {
  const component = props => {
    if (
      RadioComponentClass === RadioButton &&
      props.options &&
      Object.keys(props.options).length > 3
    ) {
      // eslint-disable-next-line no-console
      console.warn(
        "RadioButtonGroup component is not recommended to be used for more than 3 options. Consider using RadioInputGroup instead"
      );
    }
    const [value, setValue] = props.state;
    const [groupName] = useState(() => `radio-${radioId++}`);
    const optionsKeys = Object.keys(props.options);

    const getClassName = index => {
      let className = props.className || "";
      if (props.optionClassNames && props.optionClassNames[index]) {
        className += " " + props.optionClassNames[index];
      }
      return className;
    };

    const getBackgroundColorClassName = index => {
      let className = "";
      if (
        props.optionBackgroundClassNames &&
        props.optionBackgroundClassNames[index]
      ) {
        className = props.optionBackgroundClassNames[index];
      }
      return className;
    };

    return wrapper(
      <React.Fragment>
        {optionsKeys.map((key, idx) =>
          optionWrapper(
            <RadioComponentClass
              key={key}
              icon={props.icons ? props.icons[idx] : null}
              tooltip={props.tooltips ? props.tooltips[idx] : null}
              shouldCapitalize={props.shouldCapitalize}
              name={groupName}
              disabled={props.disableds ? props.disableds[idx] : props.disabled}
              isFirst={idx === 0}
              isLast={idx === optionsKeys.length - 1}
              value={key}
              className={getClassName(idx)}
              backgroundClassName={getBackgroundColorClassName(idx)}
              selected={value === key}
              error={props.error}
              onChange={() => {
                props.deselectable && value === key
                  ? setValue()
                  : setValue(key);
              }}
            >
              {props.options[key]}
            </RadioComponentClass>
          )
        )}
        {props.error && <FieldError>{props.error}</FieldError>}
      </React.Fragment>
    );
  };
  component.propTypes = {
    error: PropTypes.any,
    disabled: PropTypes.bool,
    disableds: PropTypes.array,
    deselectable: PropTypes.bool,
    options: PropTypes.object,
    state: PropTypes.array,
    icons: PropTypes.array,
    tooltips: PropTypes.array,
    optionClassNames: PropTypes.array,
    optionBackgroundClassNames: PropTypes.array,
    className: PropTypes.string,
    shouldCapitalize: PropTypes.bool
  };

  component.defaultProps = {
    shouldCapitalize: true
  };
  return component;
};

const RadioButtonGroup = RadioGroupFactory(RadioButton);
const RadioInputGroup = RadioGroupFactory(RadioInput);
const RadioHorizontalInputGroup = RadioGroupFactory(RadioInput, {
  wrapper: function flexWrapper(children) {
    return <div className="aui-flex aui--mx-4">{children}</div>;
  },
  optionWrapper: function spacedWrapper(children) {
    return <div className="aui-px-4">{children}</div>;
  }
});

export { RadioButtonGroup, RadioInputGroup, RadioHorizontalInputGroup };
