import React from "react";
import PropTypes from "prop-types";

import "./index.css";

const SIZES_MAP = {
  small: "aui-text-2xl",
  medium: "aui-text-4xl",
  large: "aui-text-6xl"
};

export function SimpleSpinner(props) {
  const {
    className = "",
    wrapperClassName = "",
    size,
    transparent = false
  } = props;
  const sizeClass = SIZES_MAP[size] || "";
  const spinnerBars = Array.from(Array(12).keys());
  const bgClass = transparent ? "" : "aui-bg-white";
  return (
    <div className={`aui-z-50 ${bgClass} aui-opacity-50 ${wrapperClassName}`}>
      <div
        className={`aui-spinner aui-block aui-inset-0 aui-m-auto ${className} ${sizeClass}`}
      >
        {spinnerBars.map(bar => (
          <i key={bar} />
        ))}
      </div>
    </div>
  );
}

SimpleSpinner.propTypes = {
  wrapperClassName: PropTypes.string,
  className: PropTypes.string,
  size: PropTypes.oneOf(["small", "medium", "large"]),
  transparent: PropTypes.bool
};
