import * as React from "react";

function SvgMy(props) {
  return (
    <svg viewBox="0 0 640 480" {...props}>
      <path fill="#cc0001" d="M0-.05h640v480.1H0z" />
      <path id="my_svg__a" fill="#fff" d="M0 445.757h640v34.293H0z" />
      <use
        width="100%"
        height="100%"
        transform="translate(0 -68.586)"
        xlinkHref="#my_svg__a"
      />
      <use
        width="100%"
        height="100%"
        transform="translate(0 -137.17)"
        xlinkHref="#my_svg__a"
      />
      <use
        width="100%"
        height="100%"
        transform="translate(0 -205.757)"
        xlinkHref="#my_svg__a"
      />
      <use
        width="100%"
        height="100%"
        transform="translate(0 -274.343)"
        xlinkHref="#my_svg__a"
      />
      <use
        width="100%"
        height="100%"
        transform="translate(0 -342.93)"
        xlinkHref="#my_svg__a"
      />
      <use
        width="100%"
        height="100%"
        transform="translate(0 -411.514)"
        xlinkHref="#my_svg__a"
      />
      <path fill="#010066" d="M0-.05h372.655v274.343H0z" />
      <g fill="#fc0">
        <path d="M149.71 48.531c-49.063 0-88.886 39.69-88.886 88.59 0 48.9 39.823 88.591 88.886 88.591 17.726 0 34.242-5.187 48.113-14.11a78.856 78.856 0 01-25.772 4.312c-43.506 0-78.815-35.192-78.815-78.554 0-43.36 35.31-78.553 78.815-78.553 9.615 0 18.825 1.717 27.346 4.863-14.187-9.555-31.288-15.139-49.687-15.139z" />
        <path d="M297.115 183.24l-37.441-19.535 11.07 39.98-25.043-33.444-7.813 40.706-7.684-40.729-25.148 33.369 11.197-39.947-37.503 19.422 27.86-31.253-42.43 1.63 39.004-16.37-38.952-16.486 42.424 1.757-27.76-31.337 37.44 19.535-11.07-39.98 25.043 33.444 7.813-40.706 7.684 40.729 25.148-33.369-11.196 39.947 37.503-19.422-27.86 31.253 42.43-1.63-39.005 16.37 38.953 16.486-42.425-1.757z" />
      </g>
    </svg>
  );
}

export default SvgMy;
