import React, { useRef, useState, useEffect } from "react";
import PropTypes from "prop-types";

import { DropdownRowItem } from "../";
import { Icon, SimpleSpinner } from "../../../";
import { InputField } from "../";
import { FieldError } from "../FieldError";
import { useDebounce, useOutsideClick } from "../../../../hooks";
import { deprecated } from "../../../../utils";

const Select = props => {
  const { items = [], placeholder, grouped } = props;

  const getLabel =
    props.getLabel ||
    props.getValue ||
    function(item) {
      return item;
    };

  const searchFilterEnabled = grouped
    ? getLength(items) >= props.searchThreshold
    : items.length >= props.searchThreshold;

  const [isDropdownVisible, setDropdownVisible] = useState(
    props.isDropdownVisible
  );
  const [filteredItems, setFilteredItems] = useState(items);
  const [searchFilter, setSearchFilter] = useState("");
  const [selected, setSelected] = useState(props.selected);
  const [isSearching, setIsSearching] = useState();

  const debouncedSearchFilter = useDebounce(
    searchFilter,
    props.onSearch ? props.debouncedSearchFilterTimeout : 0
  );

  const triggerRef = useRef();

  useEffect(() => setSelected(props.selected), [props.selected]);

  useEffect(() => {
    if (!debouncedSearchFilter) {
      setFilteredItems(items);
      return;
    }

    if (props.onSearch) {
      setIsSearching(true);
      props.onSearch(debouncedSearchFilter).then(result => {
        setIsSearching(false);
        setFilteredItems(result.filter(item => item !== selected));
      });
    } else {
      let filteredItems = [];
      if (grouped) {
        filteredItems = items
          .map(object => {
            return {
              label: object.label,
              options: object.options.filter(
                item =>
                  item !== selected &&
                  getLabel(item)
                    .toLowerCase()
                    .includes(debouncedSearchFilter.toLowerCase())
              )
            };
          })
          .filter(object => object.options.length > 0);
      } else {
        filteredItems = items.filter(
          item =>
            item !== selected &&
            getLabel(item)
              .toLowerCase()
              .includes(debouncedSearchFilter)
        );
      }
      setFilteredItems(filteredItems);
    }
  }, [debouncedSearchFilter, items]);

  useOutsideClick(triggerRef, () => setDropdownVisible(false));

  const borderStyles = [
    "aui-rounded",
    "aui-border",
    props.error && !isDropdownVisible
      ? "aui-border-error-2"
      : "aui-border-grey-2",
    "aui-border-solid"
  ].join(" ");

  const disabledClasses = [
    "aui-pointer-events-none",
    "aui-border-grey-2",
    "aui-bg-grey-1"
  ];
  const activeClasses = ["aui-cursor-pointer"];

  const stateStyles =
    props.disabled || isSearching ? disabledClasses : activeClasses;

  const classes = [
    "aui-font-sans",
    "focus:aui-outline-none",
    "aui-appearance-none",
    "aui-bg-white",
    "focus:aui-bg-white",
    "aui-text-left",
    "aui-text-dark-1",
    props.className || "",
    ...stateStyles
  ].join(" ");

  function handleToggleClick(e) {
    if (props.disabled) return;

    if (props.onClick) props.onClick(e);
    setSearchFilter("");
    setDropdownVisible(!isDropdownVisible);
  }

  function handleKeyDown(evt) {
    evt.nativeEvent.stopImmediatePropagation();
    if (evt.key === "Enter" && !isSearching) {
      if (grouped) {
        return handleSelect(filteredItems[0].options[0]);
      } else {
        return handleSelect(filteredItems[0]);
      }
    }
  }

  function handleTabIndex(evt) {
    evt.nativeEvent.stopImmediatePropagation();
    if (evt.key === "Enter") {
      return handleToggleClick();
    }
  }

  function handleSelect(item) {
    if (props.noDropdownToggle && item && !items.includes(item))
      item = selected;
    if (!item) return;

    if (props.onSelect) props.onSelect(item);
    setSelected(item);
    setDropdownVisible(false);
  }

  function getLength(items) {
    return items.reduce((agg, item) => agg + item.options.length, 0);
  }

  function renderGroupedList(items) {
    return items
      .filter(object => object.options.length > 0)
      .map((groupedItem, key) => {
        return (
          <React.Fragment key={key}>
            <div className="aui-border-0 aui-border-b aui-border-l aui-border-r aui-border-grey-2 aui-border-solid"></div>
            <div className="aui-font-extrabold aui-p-3 aui-py-4">
              {groupedItem.label}
            </div>
            {renderList(groupedItem.options)}
          </React.Fragment>
        );
      });
  }

  function renderList(items) {
    return items.map((item, idx) => {
      return (
        item !== selected && (
          <DropdownRowItem
            key={idx}
            className={`aui-whitespace-no-wrap ${stateStyles.join(" ")}`}
            onClick={() => handleSelect(item)}
          >
            {getLabel(item)}
          </DropdownRowItem>
        )
      );
    });
  }

  return (
    <React.Fragment>
      <div className={classes} ref={triggerRef} role="select">
        <div
          onClick={props.noDropdownToggle ? handleSelect : handleToggleClick}
          onKeyDown={handleTabIndex}
          style={{ gridTemplateColumns: "1fr 0fr" }}
          className={
            "aui-p-3 aui-whitespace-no-wrap aui-dropdown-toggle aui-grid aui-grid-flow-col aui-items-center " +
            borderStyles
          }
          tabIndex={0}
        >
          <span className="aui-truncate aui-flex-grow aui-pr-1">
            {(selected && getLabel(selected)) || (
              <span className="aui-text-dark-1">
                {placeholder || "Select item"}
              </span>
            )}
          </span>
          {!props.disabled && (
            <React.Fragment>
              {!isSearching ? (
                <Icon
                  icon={isDropdownVisible ? "caret-up" : "caret-down"}
                  className="aui-text-sm aui-text-dark-1 aui-float-right"
                />
              ) : (
                <SimpleSpinner
                  transparent
                  className="aui-float-right aui-text-dark-1"
                />
              )}
            </React.Fragment>
          )}
        </div>
        {isDropdownVisible && (
          <div className="aui-relative">
            <div
              className={
                "aui-dropdown-content aui-box-border aui-bg-white aui-z-50 aui-shadow-sm aui-absolute aui-w-full " +
                borderStyles
              }
            >
              {searchFilterEnabled && (
                <div className="aui-flex aui-flex-col aui-p-1 aui-bg-grey-1">
                  <InputField
                    autoFocus
                    placeholder="Type to search..."
                    type="text"
                    borderColor="aui-border-transparent aui-shadow-none focus:aui-bg-grey-1"
                    onKeyDown={handleKeyDown}
                    onChange={value => setSearchFilter(value.toLowerCase())}
                  />
                </div>
              )}
              <div className="aui-overflow-y-auto aui-min-h-20 aui-max-h-64">
                {filteredItems.length === 0 && (
                  <DropdownRowItem disabled>No items in list.</DropdownRowItem>
                )}
                {grouped
                  ? renderGroupedList(filteredItems)
                  : renderList(filteredItems)}
              </div>
            </div>
          </div>
        )}
      </div>
      {props.error && !isDropdownVisible && (
        <FieldError>{props.error}</FieldError>
      )}
    </React.Fragment>
  );
};

Select.propTypes = {
  selected: PropTypes.any,
  items: PropTypes.array,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  placeholder: PropTypes.string,
  getValue: deprecated(PropTypes.func),
  getLabel: PropTypes.func,
  error: PropTypes.string,
  onClick: PropTypes.func,
  onSelect: PropTypes.func,
  grouped: PropTypes.bool,
  searchThreshold: PropTypes.number,
  onSearch: PropTypes.func,
  isDropdownVisible: PropTypes.bool,
  noDropdownToggle: PropTypes.bool,
  debouncedSearchFilterTimeout: PropTypes.number
};

Select.defaultProps = {
  searchThreshold: 10,
  isDropdownVisible: false,
  debouncedSearchFilterTimeout: 500
};

export { Select };
