import { useState, useEffect } from "react";

/**
 * Custom hook to help manage promise cancellation for both consecutive pending requests
 * and component destruction.
 *
 * Usage:
 *

const [loadDataPromise, triggerLoadData] = usePromiseEffect(() => {
  setLoading(true);
  return service.fetchData();
}, [pageNumber]);

loadDataPromise
  .then(results => {
    setData(true);
    setLoading(false);
  })
  .catch(error => {
    setError(error);
  });

// ...

<button onClick={triggerLoadData}>Refresh the data</button>

 *
 * @param promiseCallback - function that returns a promise or falsy as a result.
 * @param dependencies - array of dependencies to be used to determine when the callback should be re-triggered.
 * @param triggerImmediately - determines whether the callback should be triggered when the component is first
 *                             created. Default to true. By setting it to false, the callback will only ever be
 *                             called when the trigger method is called.
 * @returns {[Promise, function]} - Returns an array containing two elements:
 *  * a promise that will be resolved when the original promise resolves, assuming the
 *    component is still mounted and that no subsequent trigger of the promiseCallback has happened.
 *  * a function that can be used to forcibly trigger the loading of the data.
 */
export function usePromiseEffect(
  promiseCallback,
  dependencies = [],
  triggerImmediately = true
) {
  const [triggerState, setTriggerState] = useState(0);
  const [promiseMethods, setPromiseMethods] = useState(null);

  const promise = new Promise((resolve, reject) => {
    useEffect(() => {
      let isSubscribed = true;

      if (triggerImmediately || triggerState > 0) {
        const promise = promiseCallback();

        if (promise) {
          promise
            .then(value => {
              if (isSubscribed) {
                resolve(value);
                if (promiseMethods) {
                  promiseMethods.resolve(value);
                }
              }
            })
            .catch(error => {
              if (isSubscribed) {
                reject(error);
                if (promiseMethods) {
                  promiseMethods.reject(error);
                }
              }
            });
        }
      }

      return () => (isSubscribed = false);
    }, [triggerState, ...dependencies]);
  });

  const trigger = () => {
    const promise = new Promise((resolve, reject) =>
      setPromiseMethods({
        resolve,
        reject
      })
    );
    setTriggerState(triggerState + 1);
    return promise;
  };

  return [promise, trigger];
}
