import * as React from "react";

function SvgGg(props) {
  return (
    <svg viewBox="0 0 640 480" {...props}>
      <path fill="#fff" d="M0 0h640v480H0z" />
      <path fill="#e8112d" d="M256 0h128v480H256z" />
      <path fill="#e8112d" d="M0 176h640v128H0z" />
      <path
        id="gg_svg__a"
        fill="#f9dd16"
        d="M109.991 286.667l23.342-23.343h210.01v-46.666h-210.01l-23.342-23.325z"
      />
      <use
        width={36}
        height={24}
        transform="rotate(90 320 240)"
        xlinkHref="#gg_svg__a"
      />
      <use
        width={36}
        height={24}
        transform="rotate(-90 320 240)"
        xlinkHref="#gg_svg__a"
      />
      <use
        width={36}
        height={24}
        transform="rotate(180 320 240)"
        xlinkHref="#gg_svg__a"
      />
    </svg>
  );
}

export default SvgGg;
