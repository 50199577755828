import React, { useRef, useState, useEffect } from "react";
import PropTypes from "prop-types";

import { Icon, InputField, InlineLoadingMessage } from "../../../";
import { FieldError } from "../FieldError";
import { useDebounce, useOutsideClick } from "../../../../hooks";
import { ListRowItem } from "../ListRowItem";

const ListSelect = props => {
  const { placeholder, autoFocus } = props;

  const getValue =
    props.getValue ||
    function(item) {
      return item;
    };

  const [currentValue, setCurrentValue] = useState("");
  const debouncedCurrentValue = useDebounce(currentValue, 500);
  const [dataLoadingPromise, setDataLoadingPromise] = useState(null);
  const [items, setItems] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const ref = useRef();
  useOutsideClick(ref, () => {
    setItems([]);
    setCurrentValue("");
  });
  const [isLoading, setIsLoading] = useState(null);

  useEffect(() => {
    if (debouncedCurrentValue) {
      setIsLoading(true);
      const promise = props.loadOptions(debouncedCurrentValue);
      setDataLoadingPromise(promise);
    } else {
      setItems([]);
    }
  }, [debouncedCurrentValue]);

  useEffect(() => {
    if (dataLoadingPromise && dataLoadingPromise.then) {
      dataLoadingPromise.then(setItems);
      dataLoadingPromise.finally(() => setIsLoading(false));
    }
  }, [dataLoadingPromise]);

  let borderStyles = [
    "aui-border",
    props.error ? "aui-border-error-2" : "aui-border-grey-2",
    "aui-border-solid"
  ];

  const disabledClasses = [
    "aui-pointer-events-none",
    "aui-border-grey-2",
    "aui-bg-grey-1"
  ];
  const activeClasses = ["aui-cursor-pointer"];

  const stateStyles = props.disabled ? disabledClasses : activeClasses;

  const classes = [
    "aui-dropdown-toggle",
    "aui-rounded",
    "focus:aui-outline-none",
    "aui-appearance-none",
    "aui-bg-white",
    "focus:aui-bg-white",
    "aui-text-left",
    "aui-text-dark-1",
    props.className || "",
    ...borderStyles,
    ...stateStyles
  ].join(" ");

  function handleSelect(item) {
    let ret = null;
    if (props.onSelect) {
      ret = props.onSelect(item);
    }

    if (ret && ret.then) {
      ret.then(() => {
        setTimeout(() => {
          setSelectedItems(selectedItems.concat(getValue(item)));
        }, 800);
      });
    } else {
      setTimeout(() => {
        setSelectedItems(selectedItems.concat(getValue(item)));
      }, 800);
    }

    if (props.closeOnSelect) {
      setCurrentValue("");
      setItems([]);
    }
    return ret;
  }

  function handleInputChange(value) {
    setCurrentValue(value);
    if (props.onChange) props.onChange(value);
  }

  function isSelected(item) {
    return selectedItems.includes(getValue(item));
  }

  function noResultsFound() {
    return (
      !isLoading &&
      debouncedCurrentValue.length > 0 &&
      currentValue.length > 0 &&
      items &&
      items.length === 0
    );
  }

  return (
    <div className="aui-absolute aui-w-full aui-z-10" ref={ref}>
      <div className={classes}>
        <InputField
          placeholder={placeholder || "Type to search..."}
          className="aui-w-full aui-border-box aui-h-standard-input aui-pl-md aui-pr-xl"
          value={currentValue}
          autoFocus={autoFocus}
          onChange={handleInputChange}
        />
        <Icon
          icon="add"
          className="aui-text-grey-4 aui--ml-xl aui-align-middle aui-cursor-default"
        />
      </div>

      {isLoading && (
        <div className={"aui-pb-64 aui-bg-white"}>
          <ListRowItem disabled={true}>
            <InlineLoadingMessage>Searching...</InlineLoadingMessage>
          </ListRowItem>
        </div>
      )}

      {noResultsFound() && props.onCreate && (
        <ListRowItem onSelect={() => props.onCreate(currentValue)} tabIndex={0}>
          <a className="aui-text-primary-1">
            Create new <strong>{currentValue}</strong>?
          </a>
        </ListRowItem>
      )}

      {noResultsFound() && !props.onCreate && !props.error && (
        <ListRowItem disabled={true}>No results found.</ListRowItem>
      )}
      <div
        className={
          "aui-overflow-auto aui-bg-white " +
          (items.length > 0 && items.length < 10 && "aui-pb-64")
        }
      >
        {items.length > 0 &&
          !isLoading &&
          items.map((item, idx) => {
            return (
              !isSelected(item) && (
                <ListRowItem
                  key={idx}
                  onSelect={() => handleSelect(item)}
                  tabIndex={0}
                >
                  {getValue(item)}
                </ListRowItem>
              )
            );
          })}
      </div>
      {props.error && <FieldError>{props.error}</FieldError>}
    </div>
  );
};

ListSelect.propTypes = {
  loadOptions: PropTypes.func,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  closeOnSelect: PropTypes.bool,
  placeholder: PropTypes.string,
  getValue: PropTypes.func,
  onSelect: PropTypes.func,
  onCreate: PropTypes.func,
  autoFocus: PropTypes.bool,
  error: PropTypes.string
};

export { ListSelect };
