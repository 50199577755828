import * as React from "react";

function SvgKi(props) {
  return (
    <svg viewBox="0 0 640 480" {...props}>
      <defs>
        <clipPath id="ki_svg__a">
          <path fillOpacity={0.67} d="M-86.325 0h682.67v512h-682.67z" />
        </clipPath>
      </defs>
      <g clipPath="url(#ki_svg__a)" transform="translate(80.93) scale(.9375)">
        <path
          fill="#e73e2d"
          fillRule="evenodd"
          d="M-164.28 0h835.79v306.49h-835.79z"
        />
        <path
          fill="#fec74a"
          fillRule="evenodd"
          stroke="#d8aa3f"
          strokeWidth={1.665}
          d="M204.3 282.11c-19.435-15.174-55.633-10.041-61.596-51.286 27.717 21.469 22.686-1.227 64.082 19.229L204.3 282.11z"
        />
        <path
          fill="#fec74a"
          fillRule="evenodd"
          stroke="#d8aa3f"
          strokeWidth={1.665}
          d="M209.47 263.35c-13.852-20.398-49.882-26.602-42.922-67.69 19.807 28.928 21.971 5.782 55.109 37.936L209.47 263.35z"
        />
        <path
          fill="#fec74a"
          fillRule="evenodd"
          stroke="#d8aa3f"
          strokeWidth={1.665}
          d="M215.77 250.01c-5-24.145-35.922-43.653-13.782-78.958 7.242 34.303 18.091 13.743 36.418 56.124l-22.637 22.834z"
        />
        <path
          fill="#fec74a"
          fillRule="evenodd"
          stroke="#d8aa3f"
          strokeWidth={1.665}
          d="M230.32 237.61c2.653-24.514-20.786-52.572 11.123-79.376-3.638 34.87 12.998 18.633 17.431 64.594l-28.553 14.782z"
        />
        <path
          fill="#fec74a"
          fillRule="evenodd"
          stroke="#d8aa3f"
          strokeWidth={1.665}
          d="M243.26 227.67c12.457-21.279 2.56-56.474 42.646-67.864-17.596 30.324 4.23 22.321-10.543 66.068l-32.103 1.796z"
        />
        <path
          fill="#fec74a"
          fillRule="evenodd"
          stroke="#d8aa3f"
          strokeWidth={1.665}
          d="M262.07 230.29c18.559-16.234 20.327-52.751 61.957-50.849-26.3 23.183-3.062 22.511-30.94 59.321l-31.017-8.471z"
        />
        <path
          fill="#fec74a"
          fillRule="evenodd"
          stroke="#d8aa3f"
          strokeWidth={1.665}
          d="M278.57 239.87c22.506-10.072 34.914-44.463 74.152-30.426-31.946 14.444-9.534 20.621-46.987 47.628L278.57 239.87z"
        />
        <path
          fill="#fec74a"
          fillRule="evenodd"
          stroke="#d8aa3f"
          strokeWidth={1.665}
          d="M292.49 254.93c24.431-3.33 46.018-32.837 79.718-8.323-34.72 4.867-14.953 17.104-58.494 32.476L292.49 254.93z"
        />
        <path
          fill="#fec74a"
          fillRule="evenodd"
          stroke="#d8aa3f"
          strokeWidth={1.665}
          d="M379.46 259.6l-112.06 2.46 4.705 30.194 107.36-32.654z"
        />
        <path
          fill="#fec74a"
          fillRule="evenodd"
          stroke="#d8aa3f"
          strokeWidth={1.665}
          d="M363 218.41l-103.84 39.859 17.386 33.373 86.458-73.232z"
        />
        <path
          fill="#fec74a"
          fillRule="evenodd"
          stroke="#d8aa3f"
          strokeWidth={1.665}
          d="M280.58 280.67l53.722-97.54-82.407 76.705 28.684 20.835z"
        />
        <path
          fill="#fec74a"
          fillRule="evenodd"
          stroke="#d8aa3f"
          strokeWidth={1.665}
          d="M279.46 272.63l17.268-109.47-53.811 97.285 36.543 12.187z"
        />
        <path
          fill="#fec74a"
          fillRule="evenodd"
          stroke="#d8aa3f"
          strokeWidth={1.665}
          d="M273.01 263.74l-18.035-110.25-19.989 110.73 38.025-.482z"
        />
        <path
          fill="#fec74a"
          fillRule="evenodd"
          stroke="#d8aa3f"
          strokeWidth={1.665}
          d="M263.68 254.29l-52.292-92.072 20.095 111.92 32.197-19.845z"
        />
        <path
          fill="#fec74a"
          fillRule="evenodd"
          stroke="#d8aa3f"
          strokeWidth={1.665}
          d="M255.56 253.6l-81.101-68.479 57.667 98.003 23.435-29.524z"
        />
        <path
          fill="#fec74a"
          fillRule="evenodd"
          stroke="#d8aa3f"
          strokeWidth={1.665}
          d="M145.95 218.89l87.069 71.891 13.387-37.606-100.46-34.285z"
        />
        <path
          fill="#fec74a"
          fillRule="evenodd"
          stroke="#d8aa3f"
          strokeWidth={1.665}
          d="M232.1 260.85l-102.31-1.438 101.88 34.01.429-32.572z"
        />
        <path
          fill="#fec74a"
          fillRule="evenodd"
          stroke="#d8aa3f"
          strokeWidth={1.665}
          d="M315.121 279.224c0 33.896-27.478 61.374-61.374 61.374s-61.373-27.478-61.373-61.374 27.478-61.374 61.373-61.374 61.374 27.478 61.374 61.374z"
        />
        <path
          fill="#005989"
          fillRule="evenodd"
          d="M-165.12 303.4h839.9V512h-839.9z"
        />
        <path
          fill="#fff"
          fillRule="evenodd"
          d="M-165.6 454c15.618 7.228 37.991 25.319 61.922 25.303 40.042-.26 41.108-27.425 81.669-26.133 40.667 1.035 33.311 29.634 88.224 29.491 45.369-.119 60.026-34.665 99.134-30.526 28.963-1.49 40.817 32.714 85.334 33.148 46.172.987 63.126-37.28 92.09-34.112 30.856 0 40.964 30.821 84.253 31.043 55.136.278 64.829-32.078 99.323-30.008 24.55-.518 43.91 23.714 79.887 24.317 28.707.489 52.74-21.214 68.975-28.974l.803-36.827c-17.015 6.327-42.386 27.073-67.756 27.301-36.51 1.336-59.094-23.946-84.464-23.718-30.397.273-42.51 31.284-94.076 31.284-47.11 0-57.918-31.284-88.316-31.284-29.712.228-38.815 34.026-90.253 33.4-41.96-.505-58.31-32.262-88.023-32.034-31.5 0-64.072 30.881-98.951 29.39-48.14-2.034-58.541-29.39-90.041-29.39-23.542 0-48.948 25.635-77.31 26.062-28.205.434-59.891-25.508-62.826-26.062l.402 38.33zm0-72.93c15.618 7.228 37.991 25.319 61.922 25.303 40.042-.26 41.108-27.425 81.669-26.133 40.667 1.035 33.311 29.634 88.224 29.491 45.369-.119 60.026-34.665 99.134-30.526 28.963-1.49 40.817 32.714 85.334 33.148 46.172.987 63.126-37.28 92.09-34.112 30.856 0 40.964 30.821 84.253 31.043 55.136.278 64.829-32.078 99.323-30.008 24.55-.518 43.91 23.714 79.887 24.317 28.707.489 52.74-21.214 68.975-28.974l.803-36.827c-17.015 6.327-42.386 27.073-67.756 27.301-36.51 1.336-59.094-23.946-84.464-23.718-30.397.273-42.51 31.284-94.076 31.284-47.11 0-57.918-31.284-88.316-31.284-29.712.228-38.815 34.026-90.253 33.4-41.96-.505-58.31-32.262-88.023-32.034-31.5 0-64.072 30.881-98.951 29.39-48.14-2.034-58.541-29.39-90.041-29.39-23.542 0-48.948 25.635-77.31 26.062-28.205.434-59.891-25.508-62.826-26.062l.402 38.33z"
        />
        <path
          fill="#fff"
          fillRule="evenodd"
          d="M-165.6 308.92c15.603 7.228 37.955 25.319 61.865 25.303 40.004-.26 41.07-27.425 81.593-26.133 40.629 1.035 33.28 29.634 88.141 29.491 45.327-.119 59.97-34.665 99.042-30.526 28.936-1.49 40.779 32.714 85.254 33.148 46.129.987 63.067-37.28 92.004-34.112 30.827 0 40.926 30.821 84.174 31.043 55.085.278 64.769-32.078 99.23-30.008 24.528-.518 43.869 23.714 79.813 24.317 28.68.489 52.691-21.214 68.911-28.974l.802-36.827c-17 6.327-42.346 27.073-67.693 27.301-36.475 1.336-59.038-23.946-84.385-23.718-30.369.273-42.471 31.284-93.988 31.284-47.065 0-57.864-31.284-88.234-31.284-29.684.228-38.779 34.026-90.169 33.4-41.921-.505-58.255-32.262-87.941-32.034-31.47 0-64.012 30.881-98.859 29.39-48.095-2.034-58.486-29.39-89.957-29.39-23.52 0-48.903 25.635-77.237 26.062-28.15.44-59.81-25.5-62.74-26.06l.401 38.33z"
        />
        <path
          fill="#ffc84b"
          fillRule="evenodd"
          stroke="#d8aa3f"
          strokeWidth={1.776}
          d="M136.81 75.956c61.048-4.18 49.921-9.394 74.493-13.316 30.964 4.257 34.505 23.004 51.758 34.505 0 0-6.485 21.926-27.706 18.822-2.853-8.796 10.273-11.515-27.497-34.54-21.958-1.307-61.25 3.854-71.048-5.472zm189.26 33.934l-43.979 1.81v10.866c29.664.949 35.011-3.018 43.979-12.676z"
        />
        <path
          fill="#ffc84b"
          fillRule="evenodd"
          stroke="#d8aa3f"
          strokeLinejoin="round"
          strokeWidth={1.665}
          d="M174.84 108.07c7.665-3.92 11.477-2.32 18.118-2.579 4.57 8.278 8.882 9.054 18.885 10.09 15.263 21.471 36.735 21.73 39.839 21.73 29.923-.778 39.409-21.73 59.501-24.06h21.214c-3.535-6.036-6.295-9.486-13.97-9.83-15.781-.863-36.217-.431-54.067 3.621l-25.094 6.21c-7.33-3.536-25.266-22.335-36.735-21.99-6.726 1.81-6.726 4.139-10.089 6.726-6.467 2.76-12.936 1.437-17.601 10.08z"
        />
        <path
          fill="#ffc84b"
          fillRule="evenodd"
          stroke="#d8aa3f"
          strokeWidth={1.665}
          d="M205.104 98.914a3.174 3.174 0 11-6.348 0 3.174 3.174 0 016.348 0z"
        />
        <path
          fill="#ffc84b"
          fillRule="evenodd"
          stroke="#d8aa3f"
          strokeWidth={1.776}
          d="M225.56 107.82c44.927-54.154 84.163-47.514 135.3-51.222 1.725 6.295 1.122 15.695-23.282 24.059-33.372 4.915-93.132 40.097-93.39 40.097-11.126-.517-18.886-12.158-18.627-12.935z"
        />
        <path
          fill="none"
          stroke="#d9a43e"
          strokeLinecap="round"
          strokeWidth={1.776}
          d="M317.87 72.536l33.134 1.436m-36.744 3.97l24.152 1.97"
        />
      </g>
    </svg>
  );
}

export default SvgKi;
