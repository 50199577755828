import * as React from "react";

function SvgSd(props) {
  return (
    <svg viewBox="0 0 640 480" {...props}>
      <defs>
        <clipPath id="sd_svg__a">
          <path fillOpacity={0.67} d="M0 0h682.67v512H0z" />
        </clipPath>
      </defs>
      <g
        fillRule="evenodd"
        strokeWidth="1pt"
        clipPath="url(#sd_svg__a)"
        transform="scale(.9375)"
      >
        <path d="M0 341.32h1024V512H0z" />
        <path fill="#fff" d="M0 170.64h1024v170.68H0z" />
        <path fill="red" d="M0 0h1024.8v170.68H0z" />
        <path fill="#009a00" d="M0 0v512l341.32-256L0 0z" />
      </g>
    </svg>
  );
}

export default SvgSd;
