import React, { useEffect, useState } from "react";
import { format } from "date-fns";
import PropTypes from "prop-types";
import CalendarImage from "./CalendarImage";
import { InputField } from "../../Forms";
import { Calendar } from "../Calendar";
import "./index.css";

export const Datepicker = ({
  calendarHeight,
  calendarWidth,
  date,
  setDate,
  dateFormat,
  inline,
  label,
  minDate,
  maxDate,
  placeholder,
  startDate,
  className,
  showIcon,
  dateFormatter,
  daysOfTheWeek,
  error
}) => {
  const [showCalendar, setShowCalendar] = useState(false);

  const formatter = dateFormatter
    ? (date, dateFormat) => (date ? dateFormatter(date, dateFormat) : "")
    : (date, dateFormat) =>
        date ? format(date, dateFormat ? dateFormat : "MMM dd, yyyy") : "";

  const [formattedDate, setFormattedDate] = useState(
    date ? formatter(date, dateFormat) : ""
  );

  useEffect(() => {
    setFormattedDate(date ? formatter(date, dateFormat) : "");
  }, [dateFormatter]);

  const handleSelectDate = selectedDate => {
    // update date and close the calendar component
    setDate(selectedDate);
    setFormattedDate(formatter(selectedDate, dateFormat));
    setShowCalendar(false);
  };

  return (
    <>
      {!showIcon ? (
        <div className="datepicker aui-relative">
          <div className={`${inline ? "aui-flex aui-items-center" : ""}`}>
            {label && (
              <div>
                <label
                  className={`aui-text-xs aui-text-grey-4 ${
                    inline ? "aui-mr-2" : ""
                  }`}
                  onClick={() => setShowCalendar(true)}
                >
                  {label}
                </label>
              </div>
            )}
            <InputField
              bgColor="aui-bg-white"
              error={error}
              disabled={showCalendar}
              softDisabled
              onClick={() => setShowCalendar(true)}
              placeholder={placeholder}
              value={formattedDate}
              className={className}
            />
          </div>
        </div>
      ) : (
        <div className="datepicker aui-relative">
          <div className={`${inline ? "aui-flex aui-items-center" : ""}`}>
            {label && (
              <div>
                <label
                  className={`aui-text-xs aui-text-grey-4 ${
                    inline ? "aui-mr-2" : ""
                  }`}
                  onClick={() => setShowCalendar(true)}
                >
                  {label}
                </label>
              </div>
            )}
            <div
              className={`aui-flex aui-flex-row aui-p-2 aui-items-center aui-rounded ${
                error ? "aui-border-error-2" : "aui-border-grey-2"
              } aui-border aui-border-solid`}
              onClick={() => setShowCalendar(true)}
            >
              <div
                className={`aui-flex-grow ${
                  !formattedDate ? "aui-text-grey-3" : ""
                } ${className} aui-w-5/6`}
              >
                {formattedDate ? formattedDate : placeholder}
              </div>
              <CalendarImage className="aui-min-w-4 aui-ml-2" alt="Calendar" />
            </div>
          </div>
        </div>
      )}
      {showCalendar && (
        <Calendar
          height={calendarHeight}
          width={calendarWidth}
          date={date}
          handleSelectDate={handleSelectDate}
          minDate={minDate}
          maxDate={maxDate}
          onClose={() => setShowCalendar(false)}
          startDate={startDate}
          dateFormatter={dateFormatter}
          daysOfTheWeek={daysOfTheWeek}
        />
      )}
    </>
  );
};

Datepicker.defaultProps = {
  calendarHeight: undefined,
  calendarWidth: undefined,
  placeholder: "Enter date",
  showIcon: false
};

Datepicker.propTypes = {
  calendarHeight: PropTypes.string,
  calendarWidth: PropTypes.string,
  date: PropTypes.instanceOf(Date),
  setDate: PropTypes.func.isRequired,
  dateFormat: PropTypes.string,
  inline: PropTypes.bool,
  label: PropTypes.string,
  minDate: PropTypes.instanceOf(Date),
  maxDate: PropTypes.instanceOf(Date),
  placeholder: PropTypes.string,
  startDate: PropTypes.instanceOf(Date),
  className: PropTypes.string,
  showIcon: PropTypes.bool,
  dateFormatter: PropTypes.func,
  daysOfTheWeek: PropTypes.object,
  error: PropTypes.bool
};
