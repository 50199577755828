import React, {
  useState,
  useEffect,
  useRef,
  forwardRef,
  useImperativeHandle
} from "react";
import PropTypes from "prop-types";
import { ModalHeader } from "../../";
import { useShortcut } from "../../../hooks/useShortcut";
import { useOutsideClick } from "../../../hooks";

const FlyoutExtension = props => {
  const { size, content, className, orientation, closing } = props;
  const isVertical = orientation === "left" || orientation === "right";
  const sizeProperty = isVertical ? "Width" : "Height";
  const styles = {
    [`max${sizeProperty}`]: `calc(${isVertical ? "300" : "100"}% - 30px)`,
    [sizeProperty.toLowerCase()]: `${size}px`,
    [orientation]: "100%"
  };

  const inAnimations = {
    left: "aui-slide-in-left-under-flyout",
    right: "aui-slide-in-right-under-flyout",
    top: "aui-slide-in-top-under-flyout",
    bottom: "aui-slide-in-bottom-under-flyout"
  };

  const outAnimations = {
    left: "aui-slide-out-left-under-flyout",
    right: "aui-slide-out-right-under-flyout",
    top: "aui-slide-out-top-under-flyout",
    bottom: "aui-slide-out-bottom-under-flyout"
  };

  const fillSize = {
    left: "aui-h-full",
    right: "aui-h-full",
    top: "aui-w-full",
    bottom: "aui-w-full"
  };

  const horizontalPosition = {
    left: "",
    right: "",
    top: "aui-left-0",
    bottom: "aui-left-0"
  };

  const verticalPosition = {
    left: "aui-top-0",
    right: "aui-top-0",
    top: "",
    bottom: ""
  };

  const borders = {
    left: "aui-border-l-0",
    right: "aui-border-r-0",
    top: "aui-border-t-0",
    bottom: "aui-border-b-0"
  };

  const classNames = [
    "as-flyout-extension",
    "aui-box-border",
    "aui-absolute",
    "aui-overflow-y-auto",
    inAnimations[orientation],
    closing ? outAnimations[orientation] : "",
    fillSize[orientation],
    verticalPosition[orientation],
    horizontalPosition[orientation],
    "aui-bg-white-smoke",
    "aui-border-solid",
    "aui-border",
    "aui-border-grey-2",
    borders[orientation],
    className
  ].join(" ");

  return (
    <div className={classNames} style={styles}>
      {content}
    </div>
  );
};

FlyoutExtension.propTypes = {
  size: PropTypes.number,
  content: PropTypes.any,
  className: PropTypes.string,
  orientation: PropTypes.string,
  closing: PropTypes.bool
};

// eslint-disable-next-line react/display-name
const Flyout = forwardRef((props, flyoutRef) => {
  const clickOutsideContainerRef = useRef();

  /*
    Extension state is used to store the extension prop
    so its contents are preserved after prop removal.
    This allows the FlyoutExtension to stay rendered
    while animating out before it is removed.
  */
  const [extension, setExtension] = useState(false);
  const [extensionWillClose, setExtensionWillClose] = useState(false);

  useEffect(() => {
    let extensionTimeout;

    if (props.extension) {
      setExtension(props.extension);
      if (extensionWillClose) setExtensionWillClose(false);
    } else {
      setExtensionWillClose(true);
      extensionTimeout = setTimeout(() => {
        setExtension(false);
        setExtensionWillClose(false);
      }, 500);
    }

    return () => {
      clearTimeout(extensionTimeout);
    };
  }, [props.extension]);

  // eslint-disable-next-line no-console
  console.assert(
    !!props.orientation,
    "Flyout orientation must be set. Must be one of top, bottom, left, right."
  );

  const latestDisableOutsideClick = useRef(props.disableOutsideClick);
  useEffect(() => {
    latestDisableOutsideClick.current = props.disableOutsideClick;
  }, [props]);

  useOutsideClick(
    clickOutsideContainerRef,
    () => {
      if (!latestDisableOutsideClick.current) {
        flyoutRef ? flyoutRef.current.handleClose() : handleClose();
      }
    },
    true
  );

  const inAnimations = {
    left: "aui-slide-in-left",
    top: "aui-slide-in-top",
    right: "aui-slide-in-right",
    bottom: "aui-slide-in-bottom"
  };
  const outAnimations = {
    left: "aui-slide-out-left",
    top: "aui-slide-out-top",
    right: "aui-slide-out-right",
    bottom: "aui-slide-out-bottom"
  };

  const dimensions = {
    left: "aui-h-full aui-w-1/4",
    right: "aui-h-full aui-w-1/4",
    top: "aui-h-5/10 aui-w-full",
    bottom: "aui-h-5/10 aui-w-full"
  };

  const offsets = {
    left: "aui-top-0",
    right: "aui-top-0",
    top: "aui-left-0",
    bottom: "aui-left-0"
  };

  const padding = {
    left: "aui-px-6 aui-pt-lg aui-pb-lg",
    right: "aui-px-6 aui-pt-lg aui-pb-lg",
    top: "aui-pt-lg aui-pb-lg aui-px-6",
    bottom: "aui-pt-lg aui-pb-lg aui-px-6"
  };

  const overflow = {
    left: "aui-h-full aui-overflow-y-auto",
    right: "aui-h-full aui-overflow-y-auto",
    top: "aui-max-h-full aui-overflow-y-auto",
    bottom: "aui-max-h-full aui-overflow-y-auto"
  };

  const [animation, setAnimation] = useState(inAnimations[props.orientation]);

  const classNames = [
    "as-flyout",
    dimensions[props.orientation],
    "aui-fixed",
    offsets[props.orientation],
    "aui-z-50",
    animation,
    props.className ? props.className : ""
  ].join(" ");

  const containerClassNames = [
    "as-flyout-container",
    "aui-relative",
    "aui-box-border",
    "aui-z-50",
    "aui-bg-white",
    "aui-module-shadow",
    "aui-h-full",
    props.containerClassName ? props.containerClassName : ""
  ].join(" ");

  const headerClassNames = [
    "as-flyout-header",
    props.headerClassName ? props.headerClassName : ""
  ].join(" ");

  const contentClassNames = [
    "as-flyout-content",
    padding[props.orientation],
    overflow[props.orientation],
    props.contentClassName ? props.contentClassName : ""
  ].join(" ");

  useShortcut({
    27: handleEscapePress
  });

  function handleClose(e, override = false) {
    props.beforeClose && props.beforeClose();
    if (!props.disableClose || override) {
      setAnimation(outAnimations[props.orientation]);
    }
  }

  useImperativeHandle(flyoutRef, () => ({
    forceClose: () => handleClose(null, true),
    handleClose: () => handleClose()
  }));

  function handleEscapePress(evt) {
    evt.stopPropagation();
    flyoutRef ? flyoutRef.current.handleClose() : handleClose();
  }

  function onFlyComplete() {
    if (props.onClose && isClosing()) {
      props.onClose();
    }
  }

  const isClosing = () => animation === outAnimations[props.orientation];

  const handleConfirm = () => {
    if (props.onConfirm) {
      const ret = props.onConfirm();
      if (ret && ret.then) {
        ret.then(handleClose);
      } else {
        handleClose();
      }
      return ret;
    }
  };

  return (
    <div
      className={classNames}
      onAnimationEnd={onFlyComplete}
      ref={clickOutsideContainerRef}
    >
      <div className={containerClassNames}>
        <ModalHeader
          title={props.title}
          onClose={handleClose}
          onConfirm={props.onConfirm ? handleConfirm : null}
          showIcon={props.showCancelIcon}
          className={headerClassNames}
          cancelLabel={props.closeModalTag}
          modalTextClassName={props.modalTextClassName}
          hideControls={props.hideControls}
          noBackgroundColor={props.noHeaderBackground}
        />
        <div className={contentClassNames}>
          <div>{props.children}</div>
          {/* <div> below ensures long content scrolls to the bottom.
          The offset discrepancy may be caused by Flyout's Modal Header. */}
          <div className="aui-h-32" />
        </div>
      </div>
      {extension && (
        <FlyoutExtension
          {...extension}
          orientation={props.orientation}
          closing={extensionWillClose}
        />
      )}
      {props.siblingContent}
    </div>
  );
});

Flyout.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string,
  headerClassName: PropTypes.string,
  modalTextClassName: PropTypes.string,
  containerClassName: PropTypes.string,
  contentClassName: PropTypes.string,
  orientation: PropTypes.string,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  beforeClose: PropTypes.func,
  onClose: PropTypes.func,
  onConfirm: PropTypes.func,
  disableOutsideClick: PropTypes.bool,
  showCancelIcon: PropTypes.bool,
  closeModalTag: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  extension: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  hideControls: PropTypes.bool,
  disableClose: PropTypes.bool,
  siblingContent: PropTypes.node,
  noHeaderBackground: PropTypes.bool
};

Flyout.defaultProps = {
  disableOutsideClick: false,
  siblingContent: <React.Fragment />,
  noHeaderBackground: false
};

export { Flyout, FlyoutExtension };
