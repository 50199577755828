import React from "react";
import PropTypes from "prop-types";

const Box = props => {
  const {
    title,
    children,
    titleLinkText,
    onTitleLinkClick,
    paddedContents = true
  } = props;
  return (
    <div className="aui-border aui-fade-in aui-border-solid aui-border-grey-2 aui-bg-white">
      {title && (
        <div className="aui-border-b aui-border-t-0 aui-border-l-0 aui-border-r-0 aui-p-4 aui-bg-white-smoke aui-border-solid aui-border-grey-2 aui-flex aui-justify-between">
          <div className="aui-text-grey-4 aui-uppercase aui-text-sm aui-font-medium">
            {title}
          </div>
          {onTitleLinkClick && (
            <a
              className="aui-text-xs aui-cursor-pointer"
              onClick={onTitleLinkClick}
            >
              {titleLinkText}
            </a>
          )}
        </div>
      )}
      <div className={paddedContents ? "aui-p-4" : ""}>{children}</div>
    </div>
  );
};

Box.propTypes = {
  title: PropTypes.string,
  children: PropTypes.any,
  titleLinkText: PropTypes.string,
  onTitleLinkClick: PropTypes.func,
  paddedContents: PropTypes.bool
};
export { Box };
