import * as React from "react";

function SvgNz(props) {
  return (
    <svg viewBox="0 0 640 480" {...props}>
      <defs>
        <clipPath id="nz_svg__c">
          <path d="M0 0h600v300H0z" />
        </clipPath>
        <clipPath id="nz_svg__d">
          <path d="M0 0l300 150H0zm300 0h300L300 150zm0 150h300v150zm0 0v150H0z" />
        </clipPath>
        <g id="nz_svg__b">
          <g id="nz_svg__a">
            <path d="M0 0v.5L1 0z" transform="translate(0 -.325)" />
            <path d="M0 0v-.5L1 0z" transform="rotate(-36 .5 -.162)" />
          </g>
          <use transform="scale(-1 1)" xlinkHref="#nz_svg__a" />
          <use transform="rotate(72 0 0)" xlinkHref="#nz_svg__a" />
          <use transform="rotate(-72 0 0)" xlinkHref="#nz_svg__a" />
          <use transform="scale(-1 1) rotate(72)" xlinkHref="#nz_svg__a" />
        </g>
      </defs>
      <path fill="#00247d" fillRule="evenodd" d="M.004 0h640v480h-640z" />
      <g transform="translate(-92.947 36.115) scale(.66825)">
        <use
          width="100%"
          height="100%"
          fill="#fff"
          transform="matrix(45.4 0 0 45.4 900 120)"
          xlinkHref="#nz_svg__b"
        />
        <use
          width="100%"
          height="100%"
          fill="#cc142b"
          transform="matrix(30 0 0 30 900 120)"
          xlinkHref="#nz_svg__b"
        />
      </g>
      <g transform="rotate(82 534.179 124.977) scale(.66825)">
        <use
          width="100%"
          height="100%"
          fill="#fff"
          transform="rotate(-82 519.022 -457.666) scale(40.4)"
          xlinkHref="#nz_svg__b"
        />
        <use
          width="100%"
          height="100%"
          fill="#cc142b"
          transform="rotate(-82 519.022 -457.666) scale(25)"
          xlinkHref="#nz_svg__b"
        />
      </g>
      <g transform="rotate(82 534.179 124.977) scale(.66825)">
        <use
          width="100%"
          height="100%"
          fill="#fff"
          transform="rotate(-82 668.57 -327.666) scale(45.4)"
          xlinkHref="#nz_svg__b"
        />
        <use
          width="100%"
          height="100%"
          fill="#cc142b"
          transform="rotate(-82 668.57 -327.666) scale(30)"
          xlinkHref="#nz_svg__b"
        />
      </g>
      <g transform="translate(-92.947 36.115) scale(.66825)">
        <use
          width="100%"
          height="100%"
          fill="#fff"
          transform="matrix(50.4 0 0 50.4 900 480)"
          xlinkHref="#nz_svg__b"
        />
        <use
          width="100%"
          height="100%"
          fill="#cc142b"
          transform="matrix(35 0 0 35 900 480)"
          xlinkHref="#nz_svg__b"
        />
      </g>
      <path
        stroke="#fff"
        strokeWidth={60}
        d="M0 0l600 300M0 300L600 0"
        clipPath="url(#nz_svg__c)"
        transform="matrix(.60681 0 0 .73139 .004 0)"
      />
      <path
        stroke="#cc142b"
        strokeWidth={40}
        d="M0 0l600 300M0 300L600 0"
        clipPath="url(#nz_svg__d)"
        transform="matrix(.60681 0 0 .73139 .004 0)"
      />
      <path
        fill="#fff"
        d="M151.707-.002v79.37H.004v60.68h151.703v79.37h60.682v-79.37h151.703v-60.68H212.389V-.003z"
        clipPath="url(#nz_svg__c)"
        color="#000"
        fontFamily="sans-serif"
        fontWeight={400}
        overflow="visible"
        style={{
          lineHeight: "normal",
          textIndent: 0,
          textAlign: "start",
          textDecorationLine: "none",
          textDecorationStyle: "solid",
          textDecorationColor: "#000",
          textTransform: "none",
          blockProgression: "tb",
          isolation: "auto",
          mixBlendMode: "normal"
        }}
      />
      <path
        fill="#cc142b"
        d="M163.844.002v91.502H.004v36.408h163.84v91.502h36.408v-91.502h163.84V91.504h-163.84V.002z"
        color="#000"
        fontFamily="sans-serif"
        fontWeight={400}
        overflow="visible"
        style={{
          lineHeight: "normal",
          textIndent: 0,
          textAlign: "start",
          textDecorationLine: "none",
          textDecorationStyle: "solid",
          textDecorationColor: "#000",
          textTransform: "none",
          blockProgression: "tb",
          isolation: "auto",
          mixBlendMode: "normal"
        }}
      />
    </svg>
  );
}

export default SvgNz;
