import React from "react";
import PropTypes from "prop-types";

export const Fieldset = ({ legend, children, className }) => (
  <fieldset
    className={`aui-border-primary-2 aui-border-solid ${className || ""}`}
  >
    <legend className="aui-text-primary-2 aui-font-bold aui-text-center">
      <div className="aui-mx-8 aui-font-semibold aui-text-xl">{legend}</div>
    </legend>
    {children}
  </fieldset>
);

Fieldset.propTypes = {
  legend: PropTypes.string,
  children: PropTypes.any,
  className: PropTypes.string
};
