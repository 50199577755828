import React from "react";
import PropTypes from "prop-types";
import { Icon } from "../../../index";

const ListViewItem = props => {
  return (
    <React.Fragment>
      <div className="aui-flex aui-justify-between aui-items-center aui-border-0 aui-border-b aui-border-solid aui-border-grey-2">
        <div className="aui-mt-sm aui-mb-sm aui-ml-2">
          <div className="aui-text-dark-1">{props.title}</div>
          <div className="aui-text-grey-3 aui-text-sm">{props.subTitle}</div>
        </div>
        {props.isEditing && props.onRemove && (
          <Icon
            icon="close"
            onClick={props.onRemove}
            className="aui-cursor-pointer aui-text-primary-2 aui-mx-2 aui-text-extra-small aui-inline-block aui-pb-1 aui-align-middle hover:aui-text-brand-1"
          />
        )}
      </div>
    </React.Fragment>
  );
};

ListViewItem.propTypes = {
  isEditing: PropTypes.bool,
  onRemove: PropTypes.func,
  title: PropTypes.string.isRequired,
  subTitle: PropTypes.string
};

export { ListViewItem };
