import * as React from "react";

function SvgCalendar(props) {
  return (
    <svg
      width="14"
      height="20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12.6 1.45455H11.9V0.727273C11.9 0.327273 11.585 0 11.2 0C10.815 0 10.5 0.327273 10.5 0.727273V1.45455H3.5V0.727273C3.5 0.327273 3.185 0 2.8 0C2.415 0 2.1 0.327273 2.1 0.727273V1.45455H1.4C0.63 1.45455 0 2.10909 0 2.90909V14.5455C0 15.3455 0.63 16 1.4 16H12.6C13.37 16 14 15.3455 14 14.5455V2.90909C14 2.10909 13.37 1.45455 12.6 1.45455ZM11.9 14.5455H2.1C1.715 14.5455 1.4 14.2182 1.4 13.8182V5.09091H12.6V13.8182C12.6 14.2182 12.285 14.5455 11.9 14.5455Z"
        fill="#717C80"
      />
    </svg>
  );
}

export default SvgCalendar;
