import React, { useRef, useState, useEffect } from "react";
import { uniq } from "lodash";
import PropTypes from "prop-types";

import { DropdownRowItem } from "../";
import { Icon, Tag, InlineLoadingMessage, InputField } from "../../../";
import { FieldError } from "../FieldError";
import { useDebounce, useOutsideClick } from "../../../../hooks";

const Multiselect = props => {
  const getValue =
    props.getValue ||
    function(item) {
      return item;
    };

  const alwaysShowSuggestions = props.alwaysShowSuggestions || false;

  const className = props.className || "";

  const [isDropdownVisible, setDropdownVisible] = useState(false);
  const [selected, setSelected] = useState(props.selected || []);
  const [currentValue, setCurrentValue] = useState("");
  const [dataLoadingPromise, setDataLoadingPromise] = useState(null);
  const [items, setItems] = useState([]);
  const ref = useRef();
  const inputRef = useRef();
  const debouncedCurrentValue = useDebounce(currentValue, 500);
  const [isLoading, setIsLoading] = useState(null);
  useOutsideClick(ref, () => setDropdownVisible(false));

  useEffect(() => {
    if (isDropdownVisible && inputRef.current) {
      inputRef.current.focus();
    }
  }, [isDropdownVisible]);

  useEffect(() => setSelected(props.selected), [props.selected]);

  useEffect(() => {
    const shouldLoadOptions = debouncedCurrentValue || alwaysShowSuggestions;

    if (shouldLoadOptions) {
      setIsLoading(true);
      const promise = props.loadOptions(debouncedCurrentValue);
      setDataLoadingPromise(promise);
    } else {
      setItems([]);
    }
  }, [debouncedCurrentValue]);

  useEffect(() => {
    if (dataLoadingPromise && dataLoadingPromise.then) {
      dataLoadingPromise.then(setItems);
      dataLoadingPromise.finally(() => setIsLoading(false));
    }
  }, [dataLoadingPromise]);

  function getBorderStyles() {
    return props.borderStyles
      ? [props.borderStyles]
      : [
          "aui-border",
          props.error && !isDropdownVisible
            ? "aui-border-error-2"
            : "aui-border-grey-2",
          "aui-border-solid"
        ];
  }

  const borderStyles = getBorderStyles();

  const classes = [
    "aui-rounded",
    "focus:aui-outline-none",
    "aui-appearance-none",
    "aui-bg-white",
    "focus:aui-bg-white",
    props.disabled ? "aui-cursor-not-allowed" : "aui-cursor-pointer",
    "aui-text-left",
    "aui-text-dark-1",
    "aui-h-standard-input",
    className,
    ...borderStyles
  ].join(" ");

  function validItems(items) {
    return items.filter(
      object =>
        object.options.filter(option => !selected.includes(option)).length > 0
    );
  }

  function handleSelect(item) {
    const uniqueSelected = uniq([item, ...selected]);
    if (props.onSelect) props.onSelect(uniqueSelected);
    if (props.onItemSelected) props.onItemSelected(item);
    setSelected(uniqueSelected);
    setDropdownVisible(false);
    setCurrentValue("");
  }

  function handleInputFocus() {
    if (alwaysShowSuggestions) {
      setDropdownVisible(true);
    }
  }

  function handleInputChange(value) {
    setCurrentValue(value);
    setDropdownVisible(true);

    if (!value && alwaysShowSuggestions) {
      setDropdownVisible(true);
    }
  }

  function handleItemRemove(item) {
    const filteredItems = selected.filter(
      selectedItem => getValue(selectedItem) !== getValue(item)
    );
    if (props.onItemRemoved) props.onItemRemoved(item);
    if (props.onSelect) props.onSelect(filteredItems);
    setSelected(filteredItems);
  }

  function handleCreate() {
    const newItem = props.onCreate(currentValue);
    handleSelect(newItem);
  }

  function renderGroupedList(items) {
    return items
      .filter(
        object =>
          object.options.filter(option => !selected.includes(option)).length > 0
      )
      .map((groupedItem, key) => {
        return (
          <React.Fragment key={key}>
            <div className="aui-border-0 aui-border-b aui-border-l aui-border-r aui-border-grey-2 aui-border-solid"></div>
            <div className="aui-font-extrabold aui-p-3 aui-py-4">
              {groupedItem.label}
            </div>
            {renderList(groupedItem.options)}
          </React.Fragment>
        );
      });
  }

  function renderList(items) {
    return items.map((item, idx) => {
      return (
        !selected.includes(item) && (
          <DropdownRowItem key={idx} onClick={() => handleSelect(item)}>
            {getValue(item)}
          </DropdownRowItem>
        )
      );
    });
  }

  return (
    <React.Fragment>
      <div className={classes} ref={ref}>
        <InputField
          placeholder={props.placeholder}
          bgColor={props.bgColor}
          className="aui-w-full aui-border-box aui-h-full aui-pl-md aui-pr-xl"
          value={currentValue}
          onChange={handleInputChange}
          onFocus={handleInputFocus}
          disabled={props.disabled}
        />
        <Icon
          icon="add"
          className="aui-text-dark-1 aui--ml-xl aui-align-middle aui-cursor-default"
        />
        {isDropdownVisible && (
          <React.Fragment>
            <div className="aui-relative">
              <div className="aui-dropdown-content aui-bg-white aui-z-50 aui-shadow aui-absolute aui-w-full aui-overflow-y-scroll aui-max-h-64">
                {isLoading && (
                  <DropdownRowItem className="aui-pointer-events-none">
                    <InlineLoadingMessage>Searching...</InlineLoadingMessage>
                  </DropdownRowItem>
                )}
                {!isLoading &&
                  (props.grouped
                    ? renderGroupedList(items)
                    : renderList(items))}
                {isLoading === false &&
                  (!items ||
                    (props.grouped
                      ? validItems(items).length === 0
                      : items.length === 0)) &&
                  !!debouncedCurrentValue && (
                    <DropdownRowItem className="aui-pointer-events-none">
                      Sorry, nothing found.
                    </DropdownRowItem>
                  )}
                {isLoading === false &&
                  props.onCreate &&
                  currentValue &&
                  !!currentValue.trim() && (
                    <DropdownRowItem onClick={handleCreate}>
                      <a className="aui-text-primary-1">
                        &nbsp; Create new {currentValue}?
                      </a>
                    </DropdownRowItem>
                  )}
              </div>
            </div>
          </React.Fragment>
        )}
      </div>
      {!props.hideTags && (
        <div className="aui-pt-2 aui-flex aui-flex-wrap">
          {selected.map((item, idx) => (
            <Tag
              key={idx}
              className="aui-mr-2 aui-mt-2"
              onRemove={() => handleItemRemove(item)}
            >
              {getValue(item)}
            </Tag>
          ))}
        </div>
      )}
      {props.error && !isDropdownVisible && (
        <FieldError>{props.error}</FieldError>
      )}
    </React.Fragment>
  );
};

Multiselect.propTypes = {
  alwaysShowSuggestions: PropTypes.bool,
  bgColor: PropTypes.string,
  borderStyles: PropTypes.string,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  error: PropTypes.string,
  getValue: PropTypes.func,
  grouped: PropTypes.bool,
  hideTags: PropTypes.bool,
  loadOptions: PropTypes.func,
  onCreate: PropTypes.func,
  onItemRemoved: PropTypes.func,
  onItemSelected: PropTypes.func,
  onSelect: PropTypes.func,
  placeholder: PropTypes.string,
  selected: PropTypes.array
};

Multiselect.defaultProps = {
  grouped: false,
  placeholder: "Type to search...",
  hideTags: false
};

export { Multiselect };
