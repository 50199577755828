import React from "react";
import PropTypes from "prop-types";
import { ListViewItem } from "./ListViewItem";

const ListView = props => {
  const noop = () => {};
  const getItemSubTitle = props.getItemSubTitle || noop;
  const getItemTitle =
    props.getItemTitle ||
    function(item) {
      return item;
    };

  return (
    <React.Fragment>
      {props.items.map((item, idx) => {
        return (
          <ListViewItem
            key={idx}
            title={getItemTitle(item)}
            subTitle={getItemSubTitle(item)}
            onRemove={
              props.onItemRemoved ? () => props.onItemRemoved(item) : null
            }
            isEditing={props.isEditing}
          />
        );
      })}
    </React.Fragment>
  );
};

ListView.propTypes = {
  items: PropTypes.array.isRequired,
  getItemTitle: PropTypes.func,
  getItemSubTitle: PropTypes.func,
  onItemRemoved: PropTypes.func,
  isEditing: PropTypes.bool
};

export { ListView };
