import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import eachMinuteOfInterval from "date-fns/eachMinuteOfInterval";
import endOfDay from "date-fns/endOfDay";
import startOfDay from "date-fns/startOfDay";
import format from "date-fns/format";
import roundToNearestMinutes from "date-fns/roundToNearestMinutes";
import { Select } from "../..";

export const THIRTY_MINS_IN_SECONDS = 1800;
export const SIXTY_MINS_IN_SECONDS = 3600;
export const DEFAULT_TIME_FORMAT = "H:mmaa";

export const TimePicker = ({
  selectedTime,
  onSelectTime,
  getDisplayValue,
  className,
  timeBetweenValuesInMinutes = 15,
  timeSlotsDate,
  placeholder = "Select Time"
}) => {
  const [timeSlots, setTimeSlots] = useState([]);

  useEffect(() => {
    setTimeSlots(generateTimeSlots(timeSlotsDate || new Date()));
  }, [timeSlotsDate]);

  const generateTimeSlots = selectedDate => {
    return eachMinuteOfInterval(
      {
        start: roundToNearestMinutes(startOfDay(selectedDate), {
          nearestTo: timeBetweenValuesInMinutes
        }),
        end: endOfDay(selectedDate)
      },
      { step: timeBetweenValuesInMinutes }
    );
  };

  const getLabel = time =>
    getDisplayValue ? getDisplayValue(time) : formatTime(time);

  const formatTime = time => format(time, DEFAULT_TIME_FORMAT);

  /*
   * The default search function in the Select component uses the prop getLabel method and expects a string.
   * If getLabel returns a dom node the default search function will throw an exception (Example: AlphaViewScheduling component in delivery)
   * and so we pass down our own search function.
   */
  const onSearch = searchValue => {
    return Promise.resolve(
      timeSlots.filter(timeSlot => formatTime(timeSlot).includes(searchValue))
    );
  };

  return (
    <Select
      className={className}
      placeholder={placeholder}
      selected={selectedTime}
      onSelect={onSelectTime}
      items={timeSlots}
      getLabel={getLabel}
      onSearch={onSearch}
      debouncedSearchFilterTimeout={0}
    />
  );
};

TimePicker.propTypes = {
  selectedTime: PropTypes.instanceOf(Date),
  onSelectTime: PropTypes.func.isRequired,
  getDisplayValue: PropTypes.func,
  timeBetweenValuesInMinutes: PropTypes.number,
  placeholder: PropTypes.string,
  className: PropTypes.string,
  timeSlotsDate: PropTypes.Date
};

TimePicker.defaultProps = {
  timeBetweenValuesInMinutes: 15,
  placeholder: "Select Time"
};
