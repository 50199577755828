import React from "react";
import PropTypes from "prop-types";
import "./index.pcss";

const Badge = props => {
  const className = props.className ? props.className : "aui-w-1/12";
  const circular = props.circular ? props.circular : false;
  const border = props.borderColor
    ? `aui-border aui-border-solid aui-border-${props.borderColor}`
    : "";

  return circular ? (
    <div
      className={`${border} aui-bg-${props.bgColor} aui-p-0 aui-text-${props.textColor} aui-w-8 aui-h-8 aui-rounded-full aui-border-2 aui-border-solid aui-border-white aui-text-center aui-text-white aui-font-normal aui-cursor-default ${className}`}
    >
      <span className="aui-leading-none aui-text-xs aui-font-semibold">
        {props.children}
      </span>
    </div>
  ) : (
    <div
      className={`${border} aui-bg-${props.bgColor} aui-text-${props.textColor} aui-text-shadow-md aui-text-shadow-sm aui-rounded-lg extra-rounded aui-py-2 aui-rounded aui-leading-none aui-text-center ${className}`}
    >
      <span className="aui-text-tiny aui-uppercase aui-font-semibold aui-flex aui-justify-center">
        {props.children}
      </span>
    </div>
  );
};

export { Badge };

Badge.propTypes = {
  className: PropTypes.any,
  children: PropTypes.any,
  bgColor: PropTypes.string,
  borderColor: PropTypes.string,
  textColor: PropTypes.string,
  circular: PropTypes.bool
};
