import * as React from "react";

function SvgJp(props) {
  return (
    <svg viewBox="0 0 640 480" {...props}>
      <defs>
        <clipPath id="jp_svg__a">
          <path fillOpacity={0.67} d="M-88.001 32h640v480h-640z" />
        </clipPath>
      </defs>
      <g
        fillRule="evenodd"
        strokeWidth="1pt"
        clipPath="url(#jp_svg__a)"
        transform="translate(88.001 -32)"
      >
        <path fill="#fff" d="M-128 32h720v480h-720z" />
        <circle
          cx={523.08}
          cy={344.05}
          fill="#d30000"
          transform="translate(-168.44 8.618) scale(.76554)"
          r={194.93}
        />
      </g>
    </svg>
  );
}

export default SvgJp;
