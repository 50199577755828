import React from "react";
import PropTypes from "prop-types";

import { Icon } from "../../";

const GridCard = props => {
  const className = props.className || "";
  return (
    <div
      className={
        "aui-zoom-in aui-bg-white aui-block aui-max-w-sm aui-overflow-hidden aui-grid-card-shadow aui-p-4 " +
        className
      }
    >
      <div className="aui-flex aui-border-0 aui-border-b aui-border-b-2 aui-border-solid aui-border-grey-2 aui-pt-1">
        <div className="aui-w-3/4 aui-mb-3">
          <div className="aui-font-bold aui-text-left">
            <a
              className="aui-text-dark-1 hover:aui-text-dark-1"
              href={`https://www.google.com/search?q=${props.title}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {props.title}
            </a>
          </div>
        </div>
        {props.showControls && (
          <div className="aui-w-1/4">
            <Icon
              icon="move"
              onClick={props.onMove}
              className="aui-move-handle aui-text-grey-3 aui-pl-sm aui-float-right aui-border-0 aui-border-l aui-border-l-2 aui-border-solid aui-border-grey-2 aui-cursor-pointer aui-pb-1 aui-pt-1"
            />
            <Icon
              icon="delete"
              onClick={props.onDelete}
              className="aui-text-grey-3 aui-float-right aui-pr-sm aui-cursor-pointer hover:aui-text-error-2 aui-pt-1 aui-text-base"
            />
          </div>
        )}
      </div>
      {props.children}
    </div>
  );
};
export { GridCard };

GridCard.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  onMove: PropTypes.func,
  onDelete: PropTypes.func,
  showControls: PropTypes.bool,
  children: PropTypes.any
};
