import * as React from "react";

function SvgMn(props) {
  return (
    <svg viewBox="0 0 640 480" {...props}>
      <path fill="#c4272f" d="M-.05 0h640.1v480H-.05z" />
      <path fill="#015197" d="M213.317 0h213.367v480H213.317z" />
      <circle cx={107} cy={189.056} r={35} fill="#f9cf02" />
      <circle cx={107} cy={173.147} r={38.182} fill="#c4272f" />
      <path
        fill="#f9cf02"
        fillRule="evenodd"
        d="M91.113 131.805c0 8.774 7.113 15.887 15.887 15.887 8.774 0 15.887-7.113 15.887-15.887 0-6.355-3.972-7.15-3.972-9.532 0-2.383 2.383-5.56-2.383-9.533 2.383 3.972-1.588 4.767-1.588 8.738 0 3.972 1.588 3.972 1.588 7.15a3.177 3.177 0 01-6.355 0c0-3.178 3.178-6.355 3.178-11.121 0-4.767-.795-6.355-3.178-10.327-2.383-3.972-6.354-7.15-3.177-10.327-4.766 1.59-2.383 7.944-2.383 11.916 0 3.971-3.177 6.355-3.177 11.12 0 4.767 2.383 5.561 2.383 8.739a3.177 3.177 0 01-6.355 0c0-3.178 1.588-3.178 1.588-7.15 0-3.971-3.971-4.766-1.588-8.738-4.766 3.972-2.383 7.15-2.383 9.533 0 2.383-3.972 3.177-3.972 9.532z"
      />
      <circle cx={107} cy={179.51} r={25.455} fill="#f9cf02" />
      <path
        fill="#f9cf02"
        d="M37 230.42v152.727h31.818V230.419zm108.182 0v152.727H177V230.419zm-70 25.454V268.6h63.636v-12.727zm0 89.09v12.728h63.636v-12.727zm0-114.545h63.636L107 249.51zm0 133.637h63.636L107 383.146z"
      />
      <circle
        cx={107}
        cy={306.783}
        r={33.727}
        fill="#f9cf02"
        stroke="#c4272f"
        strokeWidth={3.818}
      />
      <path
        fill="none"
        stroke="#c4272f"
        strokeWidth={3.818}
        d="M107 273.056a16.864 16.864 0 010 33.727 16.864 16.864 0 100 33.727"
      />
      <circle cx={107} cy={289.919} r={6.364} fill="#c4272f" />
      <circle cx={107} cy={323.647} r={6.364} fill="#c4272f" />
    </svg>
  );
}

export default SvgMn;
