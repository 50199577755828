import React from "react";
import PropTypes from "prop-types";

const Headers = ({ headers }) => {
  return (
    <thead>
      <tr role="row">
        {headers.map(({ longName, abbreviation }) => {
          return (
            <th role="columnheader" aria-label={longName} key={longName}>
              <abbr title={longName}>{abbreviation}</abbr>
            </th>
          );
        })}
      </tr>
    </thead>
  );
};

Headers.propTypes = {
  headers: PropTypes.arrayOf(
    PropTypes.shape({
      abbreviation: PropTypes.string.isRequired,
      longName: PropTypes.string.isRequired
    })
  ).isRequired
};

export default Headers;
