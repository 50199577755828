import * as React from "react";

function SvgIr(props) {
  return (
    <svg viewBox="0 0 640 480" {...props}>
      <defs>
        <clipPath id="ir_svg__a">
          <path fillOpacity={0.67} d="M-85.311 0h682.67v512h-682.67z" />
        </clipPath>
      </defs>
      <g
        fillRule="evenodd"
        clipPath="url(#ir_svg__a)"
        transform="translate(79.98) scale(.9375)"
      >
        <path fill="#fff" d="M-191.96 0h896v512h-896z" />
        <path fill="#da0000" d="M-191.96 343.84h896V512h-896z" />
        <g fill="#fff" strokeWidth="1pt">
          <path d="M-21.628 350.958h49.07v3.377h-49.07zm7.316 16.884h3.377v3.28h-3.377zm41.882-.106v3.377h-9.807v-3.377zm5.274-16.778h3.377v20.16h-3.377z" />
          <path d="M52.379 367.736v3.377H33.794v-3.377zm-34.616-7.887h3.377v11.27h-3.377z" />
          <path d="M49.614 350.958h3.377v20.16h-3.377zm-8.442 0h3.377v20.16h-3.377zm-44.799 8.024v3.377h-17.91v-3.377zm39.3 0v3.377h-17.91v-3.377z" />
          <path d="M17.763 359.849h3.377v11.27h-3.377z" />
          <path d="M17.763 359.849h3.377v11.27h-3.377z" />
          <path d="M17.763 359.849h3.377v11.27h-3.377zm-39.3 0h3.377v11.27h-3.377zm28.807 0h3.376v11.27H7.27zm-14.272 0h3.377v11.27h-3.377z" />
          <path d="M9.639 367.736v3.377h-15.14v-3.377zm1.007-8.754v3.377H1.048v-3.377z" />
        </g>
        <g fill="#fff" strokeWidth="1pt">
          <path d="M-102.228 350.958h49.07v3.377h-49.07zm7.316 16.884h3.377v3.28h-3.377zm41.882-.106v3.377h-9.807v-3.377zm5.274-16.778h3.377v20.16h-3.377z" />
          <path d="M-28.221 367.736v3.377h-18.585v-3.377zm-34.616-7.887h3.377v11.27h-3.377z" />
          <path d="M-30.986 350.958h3.377v20.16h-3.377zm-8.442 0h3.377v20.16h-3.377zm-44.799 8.024v3.377h-17.91v-3.377zm39.3 0v3.377h-17.91v-3.377z" />
          <path d="M-62.837 359.849h3.377v11.27h-3.377z" />
          <path d="M-62.837 359.849h3.377v11.27h-3.377z" />
          <path d="M-62.837 359.849h3.377v11.27h-3.377zm-39.3 0h3.377v11.27h-3.377zm28.807 0h3.376v11.27h-3.377zm-14.272 0h3.377v11.27h-3.377z" />
          <path d="M-70.961 367.736v3.377h-15.14v-3.377zm1.007-8.754v3.377h-9.598v-3.377z" />
        </g>
        <g fill="#fff" strokeWidth="1pt">
          <path d="M58.297 350.958h49.07v3.377h-49.07zm7.316 16.884h3.376v3.28h-3.376zm41.881-.106v3.377h-9.807v-3.377zm5.275-16.778h3.377v20.16h-3.377z" />
          <path d="M132.303 367.736v3.377H113.72v-3.377zm-34.616-7.887h3.377v11.27h-3.377z" />
          <path d="M129.539 350.958h3.377v20.16h-3.377zm-8.442 0h3.377v20.16h-3.377zm-44.799 8.024v3.377h-17.91v-3.377zm39.299 0v3.377h-17.91v-3.377z" />
          <path d="M97.687 359.849h3.377v11.27h-3.377z" />
          <path d="M97.687 359.849h3.377v11.27h-3.377z" />
          <path d="M97.687 359.849h3.377v11.27h-3.377zm-39.299 0h3.377v11.27h-3.377zm28.806 0h3.377v11.27h-3.377zm-14.272 0H76.3v11.27h-3.377z" />
          <path d="M89.563 367.736v3.377h-15.14v-3.377zm1.007-8.754v3.377h-9.598v-3.377z" />
        </g>
        <g fill="#fff" strokeWidth="1pt">
          <path d="M622.682 350.958h49.07v3.377h-49.07zm7.316 16.884h3.377v3.28h-3.377zm41.882-.106v3.377h-9.807v-3.377zm5.274-16.778h3.377v20.16h-3.377z" />
          <path d="M696.689 367.736v3.377h-18.585v-3.377zm-34.616-7.887h3.377v11.27h-3.377z" />
          <path d="M693.924 350.958h3.377v20.16h-3.377zm-8.442 0h3.377v20.16h-3.377zm-44.799 8.024v3.377h-17.91v-3.377zm39.3 0v3.377h-17.91v-3.377z" />
          <path d="M662.073 359.849h3.377v11.27h-3.377z" />
          <path d="M662.073 359.849h3.377v11.27h-3.377z" />
          <path d="M662.073 359.849h3.377v11.27h-3.377zm-39.3 0h3.377v11.27h-3.377zm28.807 0h3.376v11.27h-3.377zm-14.272 0h3.377v11.27h-3.377z" />
          <path d="M653.949 367.736v3.377h-15.14v-3.377zm1.007-8.754v3.377h-9.598v-3.377z" />
        </g>
        <g fill="#fff" strokeWidth="1pt">
          <path d="M138.742 350.958h49.07v3.377h-49.07zm7.316 16.884h3.377v3.28h-3.377zm41.882-.106v3.377h-9.807v-3.377zm5.274-16.778h3.377v20.16h-3.377z" />
          <path d="M212.749 367.736v3.377h-18.585v-3.377zm-34.616-7.887h3.377v11.27h-3.377z" />
          <path d="M209.984 350.958h3.377v20.16h-3.377zm-8.442 0h3.377v20.16h-3.377zm-44.799 8.024v3.377h-17.91v-3.377zm39.3 0v3.377h-17.91v-3.377z" />
          <path d="M178.133 359.849h3.377v11.27h-3.377z" />
          <path d="M178.133 359.849h3.377v11.27h-3.377z" />
          <path d="M178.133 359.849h3.377v11.27h-3.377zm-39.3 0h3.377v11.27h-3.377zm28.807 0h3.376v11.27h-3.377zm-14.272 0h3.377v11.27h-3.377z" />
          <path d="M170.009 367.736v3.377h-15.14v-3.377zm1.007-8.754v3.377h-9.598v-3.377z" />
        </g>
        <g fill="#fff" strokeWidth="1pt">
          <path d="M219.482 350.958h49.07v3.377h-49.07zm7.316 16.884h3.377v3.28h-3.377zm41.882-.106v3.377h-9.807v-3.377zm5.274-16.778h3.377v20.16h-3.377z" />
          <path d="M293.489 367.736v3.377h-18.585v-3.377zm-34.616-7.887h3.377v11.27h-3.377z" />
          <path d="M290.724 350.958h3.377v20.16h-3.377zm-8.442 0h3.377v20.16h-3.377zm-44.799 8.024v3.377h-17.91v-3.377zm39.3 0v3.377h-17.91v-3.377z" />
          <path d="M258.873 359.849h3.377v11.27h-3.377z" />
          <path d="M258.873 359.849h3.377v11.27h-3.377z" />
          <path d="M258.873 359.849h3.377v11.27h-3.377zm-39.3 0h3.377v11.27h-3.377zm28.807 0h3.376v11.27h-3.377zm-14.272 0h3.377v11.27h-3.377z" />
          <path d="M250.749 367.736v3.377h-15.14v-3.377zm1.007-8.754v3.377h-9.598v-3.377z" />
        </g>
        <path fill="#239f40" d="M-191.96 0h896v168.16h-896z" />
        <g fill="#fff" strokeWidth="1pt">
          <path d="M300.692 350.958h49.07v3.377h-49.07zm7.316 16.884h3.377v3.28h-3.377zm41.882-.106v3.377h-9.807v-3.377zm5.274-16.778h3.377v20.16h-3.377z" />
          <path d="M374.699 367.736v3.377h-18.585v-3.377zm-34.616-7.887h3.377v11.27h-3.377z" />
          <path d="M371.934 350.958h3.377v20.16h-3.377zm-8.442 0h3.377v20.16h-3.377zm-44.799 8.024v3.377h-17.91v-3.377zm39.3 0v3.377h-17.91v-3.377z" />
          <path d="M340.083 359.849h3.377v11.27h-3.377z" />
          <path d="M340.083 359.849h3.377v11.27h-3.377z" />
          <path d="M340.083 359.849h3.377v11.27h-3.377zm-39.3 0h3.377v11.27h-3.377zm28.807 0h3.376v11.27h-3.377zm-14.272 0h3.377v11.27h-3.377z" />
          <path d="M331.959 367.736v3.377h-15.14v-3.377zm1.007-8.754v3.377h-9.598v-3.377z" />
        </g>
        <g fill="#fff" strokeWidth="1pt">
          <path d="M381.422 350.958h49.07v3.377h-49.07zm7.316 16.884h3.377v3.28h-3.377zm41.882-.106v3.377h-9.807v-3.377zm5.274-16.778h3.377v20.16h-3.377z" />
          <path d="M455.429 367.736v3.377h-18.585v-3.377zm-34.616-7.887h3.377v11.27h-3.377z" />
          <path d="M452.664 350.958h3.377v20.16h-3.377zm-8.442 0h3.377v20.16h-3.377zm-44.799 8.024v3.377h-17.91v-3.377zm39.3 0v3.377h-17.91v-3.377z" />
          <path d="M420.813 359.849h3.377v11.27h-3.377z" />
          <path d="M420.813 359.849h3.377v11.27h-3.377z" />
          <path d="M420.813 359.849h3.377v11.27h-3.377zm-39.3 0h3.377v11.27h-3.377zm28.807 0h3.376v11.27h-3.377zm-14.272 0h3.377v11.27h-3.377z" />
          <path d="M412.689 367.736v3.377h-15.14v-3.377zm1.007-8.754v3.377h-9.598v-3.377z" />
        </g>
        <g fill="#fff" strokeWidth="1pt">
          <path d="M462.162 350.958h49.07v3.377h-49.07zm7.316 16.884h3.377v3.28h-3.377zm41.882-.106v3.377h-9.807v-3.377zm5.274-16.778h3.377v20.16h-3.377z" />
          <path d="M536.169 367.736v3.377h-18.585v-3.377zm-34.616-7.887h3.377v11.27h-3.377z" />
          <path d="M533.404 350.958h3.377v20.16h-3.377zm-8.442 0h3.377v20.16h-3.377zm-44.799 8.024v3.377h-17.91v-3.377zm39.3 0v3.377h-17.91v-3.377z" />
          <path d="M501.553 359.849h3.377v11.27h-3.377z" />
          <path d="M501.553 359.849h3.377v11.27h-3.377z" />
          <path d="M501.553 359.849h3.377v11.27h-3.377zm-39.3 0h3.377v11.27h-3.377zm28.807 0h3.376v11.27h-3.377zm-14.272 0h3.377v11.27h-3.377z" />
          <path d="M493.429 367.736v3.377h-15.14v-3.377zm1.007-8.754v3.377h-9.598v-3.377z" />
        </g>
        <g fill="#fff" strokeWidth="1pt">
          <path d="M543.372 350.958h49.07v3.377h-49.07zm7.316 16.884h3.377v3.28h-3.377zm41.882-.106v3.377h-9.807v-3.377zm5.274-16.778h3.377v20.16h-3.377z" />
          <path d="M617.379 367.736v3.377h-18.585v-3.377zm-34.616-7.887h3.377v11.27h-3.377z" />
          <path d="M614.614 350.958h3.377v20.16h-3.377zm-8.442 0h3.377v20.16h-3.377zm-44.799 8.024v3.377h-17.91v-3.377zm39.3 0v3.377h-17.91v-3.377z" />
          <path d="M582.763 359.849h3.377v11.27h-3.377z" />
          <path d="M582.763 359.849h3.377v11.27h-3.377z" />
          <path d="M582.763 359.849h3.377v11.27h-3.377zm-39.3 0h3.377v11.27h-3.377zm28.807 0h3.376v11.27h-3.377zm-14.272 0h3.377v11.27h-3.377z" />
          <path d="M574.639 367.736v3.377h-15.14v-3.377zm1.007-8.754v3.377h-9.598v-3.377z" />
        </g>
        <g fill="#fff" strokeWidth="1pt">
          <path d="M-183.788 350.958h49.07v3.377h-49.07zm7.316 16.884h3.377v3.28h-3.377zm41.882-.106v3.377h-9.807v-3.377zm5.274-16.778h3.377v20.16h-3.377z" />
          <path d="M-109.781 367.736v3.377h-18.585v-3.377zm-34.616-7.887h3.377v11.27h-3.377z" />
          <path d="M-112.546 350.958h3.377v20.16h-3.377zm-8.442 0h3.377v20.16h-3.377zm-44.799 8.024v3.377h-17.91v-3.377zm39.3 0v3.377h-17.91v-3.377z" />
          <path d="M-144.397 359.849h3.377v11.27h-3.377z" />
          <path d="M-144.397 359.849h3.377v11.27h-3.377z" />
          <path d="M-144.397 359.849h3.377v11.27h-3.377zm-39.3 0h3.377v11.27h-3.377zm28.807 0h3.376v11.27h-3.377zm-14.272 0h3.377v11.27h-3.377z" />
          <path d="M-152.521 367.736v3.377h-15.14v-3.377zm1.007-8.754v3.377h-9.598v-3.377z" />
        </g>
        <g fill="#fff" strokeWidth="1pt">
          <path d="M-21.628 143.442h49.07v3.377h-49.07zm7.316 16.884h3.377v3.28h-3.377zm41.882-.106v3.377h-9.807v-3.377zm5.274-16.778h3.377v20.16h-3.377z" />
          <path d="M52.379 160.22v3.377H33.794v-3.377zm-34.616-7.887h3.377v11.27h-3.377z" />
          <path d="M49.614 143.442h3.377v20.16h-3.377zm-8.442 0h3.377v20.16h-3.377zm-44.799 8.024v3.377h-17.91v-3.377zm39.3 0v3.377h-17.91v-3.377z" />
          <path d="M17.763 152.333h3.377v11.27h-3.377z" />
          <path d="M17.763 152.333h3.377v11.27h-3.377z" />
          <path d="M17.763 152.333h3.377v11.27h-3.377zm-39.3 0h3.377v11.27h-3.377zm28.807 0h3.376v11.27H7.27zm-14.272 0h3.377v11.27h-3.377z" />
          <path d="M9.639 160.22v3.377h-15.14v-3.377zm1.007-8.754v3.377H1.048v-3.377z" />
        </g>
        <g fill="#fff" strokeWidth="1pt">
          <path d="M-102.228 143.442h49.07v3.377h-49.07zm7.316 16.884h3.377v3.28h-3.377zm41.882-.106v3.377h-9.807v-3.377zm5.274-16.778h3.377v20.16h-3.377z" />
          <path d="M-28.221 160.22v3.377h-18.585v-3.377zm-34.616-7.887h3.377v11.27h-3.377z" />
          <path d="M-30.986 143.442h3.377v20.16h-3.377zm-8.442 0h3.377v20.16h-3.377zm-44.799 8.024v3.377h-17.91v-3.377zm39.3 0v3.377h-17.91v-3.377z" />
          <path d="M-62.837 152.333h3.377v11.27h-3.377z" />
          <path d="M-62.837 152.333h3.377v11.27h-3.377z" />
          <path d="M-62.837 152.333h3.377v11.27h-3.377zm-39.3 0h3.377v11.27h-3.377zm28.807 0h3.376v11.27h-3.377zm-14.272 0h3.377v11.27h-3.377z" />
          <path d="M-70.961 160.22v3.377h-15.14v-3.377zm1.007-8.754v3.377h-9.598v-3.377z" />
        </g>
        <g fill="#fff" strokeWidth="1pt">
          <path d="M58.297 143.442h49.07v3.377h-49.07zm7.316 16.884h3.376v3.28h-3.376zm41.881-.106v3.377h-9.807v-3.377zm5.275-16.778h3.377v20.16h-3.377z" />
          <path d="M132.303 160.22v3.377H113.72v-3.377zm-34.616-7.887h3.377v11.27h-3.377z" />
          <path d="M129.539 143.442h3.377v20.16h-3.377zm-8.442 0h3.377v20.16h-3.377zm-44.799 8.024v3.377h-17.91v-3.377zm39.299 0v3.377h-17.91v-3.377z" />
          <path d="M97.687 152.333h3.377v11.27h-3.377z" />
          <path d="M97.687 152.333h3.377v11.27h-3.377z" />
          <path d="M97.687 152.333h3.377v11.27h-3.377zm-39.299 0h3.377v11.27h-3.377zm28.806 0h3.377v11.27h-3.377zm-14.272 0H76.3v11.27h-3.377z" />
          <path d="M89.563 160.22v3.377h-15.14v-3.377zm1.007-8.754v3.377h-9.598v-3.377z" />
        </g>
        <g fill="#fff" strokeWidth="1pt">
          <path d="M622.682 143.442h49.07v3.377h-49.07zm7.316 16.884h3.377v3.28h-3.377zm41.882-.106v3.377h-9.807v-3.377zm5.274-16.778h3.377v20.16h-3.377z" />
          <path d="M696.689 160.22v3.377h-18.585v-3.377zm-34.616-7.887h3.377v11.27h-3.377z" />
          <path d="M693.924 143.442h3.377v20.16h-3.377zm-8.442 0h3.377v20.16h-3.377zm-44.799 8.024v3.377h-17.91v-3.377zm39.3 0v3.377h-17.91v-3.377z" />
          <path d="M662.073 152.333h3.377v11.27h-3.377z" />
          <path d="M662.073 152.333h3.377v11.27h-3.377z" />
          <path d="M662.073 152.333h3.377v11.27h-3.377zm-39.3 0h3.377v11.27h-3.377zm28.807 0h3.376v11.27h-3.377zm-14.272 0h3.377v11.27h-3.377z" />
          <path d="M653.949 160.22v3.377h-15.14v-3.377zm1.007-8.754v3.377h-9.598v-3.377z" />
        </g>
        <g fill="#fff" strokeWidth="1pt">
          <path d="M138.742 143.442h49.07v3.377h-49.07zm7.316 16.884h3.377v3.28h-3.377zm41.882-.106v3.377h-9.807v-3.377zm5.274-16.778h3.377v20.16h-3.377z" />
          <path d="M212.749 160.22v3.377h-18.585v-3.377zm-34.616-7.887h3.377v11.27h-3.377z" />
          <path d="M209.984 143.442h3.377v20.16h-3.377zm-8.442 0h3.377v20.16h-3.377zm-44.799 8.024v3.377h-17.91v-3.377zm39.3 0v3.377h-17.91v-3.377z" />
          <path d="M178.133 152.333h3.377v11.27h-3.377z" />
          <path d="M178.133 152.333h3.377v11.27h-3.377z" />
          <path d="M178.133 152.333h3.377v11.27h-3.377zm-39.3 0h3.377v11.27h-3.377zm28.807 0h3.376v11.27h-3.377zm-14.272 0h3.377v11.27h-3.377z" />
          <path d="M170.009 160.22v3.377h-15.14v-3.377zm1.007-8.754v3.377h-9.598v-3.377z" />
        </g>
        <g fill="#fff" strokeWidth="1pt">
          <path d="M219.482 143.442h49.07v3.377h-49.07zm7.316 16.884h3.377v3.28h-3.377zm41.882-.106v3.377h-9.807v-3.377zm5.274-16.778h3.377v20.16h-3.377z" />
          <path d="M293.489 160.22v3.377h-18.585v-3.377zm-34.616-7.887h3.377v11.27h-3.377z" />
          <path d="M290.724 143.442h3.377v20.16h-3.377zm-8.442 0h3.377v20.16h-3.377zm-44.799 8.024v3.377h-17.91v-3.377zm39.3 0v3.377h-17.91v-3.377z" />
          <path d="M258.873 152.333h3.377v11.27h-3.377z" />
          <path d="M258.873 152.333h3.377v11.27h-3.377z" />
          <path d="M258.873 152.333h3.377v11.27h-3.377zm-39.3 0h3.377v11.27h-3.377zm28.807 0h3.376v11.27h-3.377zm-14.272 0h3.377v11.27h-3.377z" />
          <path d="M250.749 160.22v3.377h-15.14v-3.377zm1.007-8.754v3.377h-9.598v-3.377z" />
        </g>
        <g fill="#fff" strokeWidth="1pt">
          <path d="M300.692 143.442h49.07v3.377h-49.07zm7.316 16.884h3.377v3.28h-3.377zm41.882-.106v3.377h-9.807v-3.377zm5.274-16.778h3.377v20.16h-3.377z" />
          <path d="M374.699 160.22v3.377h-18.585v-3.377zm-34.616-7.887h3.377v11.27h-3.377z" />
          <path d="M371.934 143.442h3.377v20.16h-3.377zm-8.442 0h3.377v20.16h-3.377zm-44.799 8.024v3.377h-17.91v-3.377zm39.3 0v3.377h-17.91v-3.377z" />
          <path d="M340.083 152.333h3.377v11.27h-3.377z" />
          <path d="M340.083 152.333h3.377v11.27h-3.377z" />
          <path d="M340.083 152.333h3.377v11.27h-3.377zm-39.3 0h3.377v11.27h-3.377zm28.807 0h3.376v11.27h-3.377zm-14.272 0h3.377v11.27h-3.377z" />
          <path d="M331.959 160.22v3.377h-15.14v-3.377zm1.007-8.754v3.377h-9.598v-3.377z" />
        </g>
        <g fill="#fff" strokeWidth="1pt">
          <path d="M381.422 143.442h49.07v3.377h-49.07zm7.316 16.884h3.377v3.28h-3.377zm41.882-.106v3.377h-9.807v-3.377zm5.274-16.778h3.377v20.16h-3.377z" />
          <path d="M455.429 160.22v3.377h-18.585v-3.377zm-34.616-7.887h3.377v11.27h-3.377z" />
          <path d="M452.664 143.442h3.377v20.16h-3.377zm-8.442 0h3.377v20.16h-3.377zm-44.799 8.024v3.377h-17.91v-3.377zm39.3 0v3.377h-17.91v-3.377z" />
          <path d="M420.813 152.333h3.377v11.27h-3.377z" />
          <path d="M420.813 152.333h3.377v11.27h-3.377z" />
          <path d="M420.813 152.333h3.377v11.27h-3.377zm-39.3 0h3.377v11.27h-3.377zm28.807 0h3.376v11.27h-3.377zm-14.272 0h3.377v11.27h-3.377z" />
          <path d="M412.689 160.22v3.377h-15.14v-3.377zm1.007-8.754v3.377h-9.598v-3.377z" />
        </g>
        <g fill="#fff" strokeWidth="1pt">
          <path d="M462.162 143.442h49.07v3.377h-49.07zm7.316 16.884h3.377v3.28h-3.377zm41.882-.106v3.377h-9.807v-3.377zm5.274-16.778h3.377v20.16h-3.377z" />
          <path d="M536.169 160.22v3.377h-18.585v-3.377zm-34.616-7.887h3.377v11.27h-3.377z" />
          <path d="M533.404 143.442h3.377v20.16h-3.377zm-8.442 0h3.377v20.16h-3.377zm-44.799 8.024v3.377h-17.91v-3.377zm39.3 0v3.377h-17.91v-3.377z" />
          <path d="M501.553 152.333h3.377v11.27h-3.377z" />
          <path d="M501.553 152.333h3.377v11.27h-3.377z" />
          <path d="M501.553 152.333h3.377v11.27h-3.377zm-39.3 0h3.377v11.27h-3.377zm28.807 0h3.376v11.27h-3.377zm-14.272 0h3.377v11.27h-3.377z" />
          <path d="M493.429 160.22v3.377h-15.14v-3.377zm1.007-8.754v3.377h-9.598v-3.377z" />
        </g>
        <g fill="#fff" strokeWidth="1pt">
          <path d="M543.372 143.442h49.07v3.377h-49.07zm7.316 16.884h3.377v3.28h-3.377zm41.882-.106v3.377h-9.807v-3.377zm5.274-16.778h3.377v20.16h-3.377z" />
          <path d="M617.379 160.22v3.377h-18.585v-3.377zm-34.616-7.887h3.377v11.27h-3.377z" />
          <path d="M614.614 143.442h3.377v20.16h-3.377zm-8.442 0h3.377v20.16h-3.377zm-44.799 8.024v3.377h-17.91v-3.377zm39.3 0v3.377h-17.91v-3.377z" />
          <path d="M582.763 152.333h3.377v11.27h-3.377z" />
          <path d="M582.763 152.333h3.377v11.27h-3.377z" />
          <path d="M582.763 152.333h3.377v11.27h-3.377zm-39.3 0h3.377v11.27h-3.377zm28.807 0h3.376v11.27h-3.377zm-14.272 0h3.377v11.27h-3.377z" />
          <path d="M574.639 160.22v3.377h-15.14v-3.377zm1.007-8.754v3.377h-9.598v-3.377z" />
        </g>
        <g fill="#fff" strokeWidth="1pt">
          <path d="M-183.788 143.442h49.07v3.377h-49.07zm7.316 16.884h3.377v3.28h-3.377zm41.882-.106v3.377h-9.807v-3.377zm5.274-16.778h3.377v20.16h-3.377z" />
          <path d="M-109.781 160.22v3.377h-18.585v-3.377zm-34.616-7.887h3.377v11.27h-3.377z" />
          <path d="M-112.546 143.442h3.377v20.16h-3.377zm-8.442 0h3.377v20.16h-3.377zm-44.799 8.024v3.377h-17.91v-3.377zm39.3 0v3.377h-17.91v-3.377z" />
          <path d="M-144.397 152.333h3.377v11.27h-3.377z" />
          <path d="M-144.397 152.333h3.377v11.27h-3.377z" />
          <path d="M-144.397 152.333h3.377v11.27h-3.377zm-39.3 0h3.377v11.27h-3.377zm28.807 0h3.376v11.27h-3.377zm-14.272 0h3.377v11.27h-3.377z" />
          <path d="M-152.521 160.22v3.377h-15.14v-3.377zm1.007-8.754v3.377h-9.598v-3.377z" />
        </g>
        <path
          fill="#d90000"
          d="M-68.83 339.507h6.048v10.505h-6.048zm160.532 0h6.048v10.505h-6.048zm-283.705 0h6.048v10.505h-6.048zm81.475 0h6.048v10.505h-6.048zm80.895 0h6.048v10.505h-6.048zm40.024 0h6.049v10.505h-6.05zm40.861 0H57.3v10.505h-6.048zm80.475 0h6.048v10.505h-6.048zm203.056 0h6.048v10.505h-6.048zm-162.197 0h6.048v10.505h-6.048zm40.035 0h6.048v10.505h-6.048zm40.438 0h6.048v10.505h-6.048zm40.448 0h6.048v10.505h-6.048zm323.143 0h6.047v10.505h-6.048zm-242.67 0h6.048v10.505h-6.048zm40.86 0h6.049v10.505h-6.049zm41.284 0h6.049v10.505h-6.049zm38.776 0h6.049v10.505H494.9zm41.274 0h6.048v10.505h-6.048zm40.448 0h6.048v10.505h-6.048zm119.674 0h6.048v10.505h-6.048zm-38.778 0h6.048v10.505h-6.048zm-808.908 0h6.048v10.505h-6.048z"
        />
        <path
          fill="#239e3f"
          d="M-68.83 162.607h6.048v10.505h-6.048zm160.532 0h6.048v10.505h-6.048zm-283.705 0h6.048v10.505h-6.048zm81.475 0h6.048v10.505h-6.048zm80.895 0h6.048v10.505h-6.048zm40.024 0h6.049v10.505h-6.05zm40.861 0H57.3v10.505h-6.048zm80.475 0h6.048v10.505h-6.048zm203.056 0h6.048v10.505h-6.048zm-162.197 0h6.048v10.505h-6.048zm40.035 0h6.048v10.505h-6.048zm40.438 0h6.048v10.505h-6.048zm40.448 0h6.048v10.505h-6.048zm323.143 0h6.047v10.505h-6.048zm-242.67 0h6.048v10.505h-6.048zm40.86 0h6.049v10.505h-6.049zm41.284 0h6.049v10.505h-6.049zm38.776 0h6.049v10.505H494.9zm41.274 0h6.048v10.505h-6.048zm40.448 0h6.048v10.505h-6.048zm119.674 0h6.048v10.505h-6.048zm-38.778 0h6.048v10.505h-6.048zm-808.908 0h6.048v10.505h-6.048z"
        />
        <g fill="#da0000">
          <path d="M279.8 197.489c8.453 10.36 34.511 67.652-15.73 105.18-23.62 17.777-8.994 18.675-8.296 21.663 37.966-20.167 50.366-47.49 50.078-71.966-.29-24.476-13.266-46.102-26.052-54.873z" />
          <path d="M284.826 194.826c18.75 9.6 59.553 57.879 15.635 112.344 27.288-6.056 61.98-86.417-15.635-112.344zm-57.581 0c-18.749 9.6-59.553 57.879-15.635 112.344-27.288-6.056-61.98-86.417 15.635-112.344z" />
          <path d="M232.2 197.489c-8.454 10.36-34.512 67.652 15.73 105.18 23.618 17.777 8.993 18.675 8.294 21.663-37.965-20.167-50.365-47.49-50.077-71.966.289-24.476 13.265-46.102 26.052-54.873z" />
          <path d="M304.198 319.111c-14.86.236-33.593-2.047-47.486-9.267 2.288 4.45 4.189 7.252 6.477 11.701 13.25 1.222 31.535 2.734 41.01-2.434zm-94.973 0c14.86.236 33.593-2.047 47.487-9.267-2.289 4.45-4.19 7.252-6.478 11.701-13.25 1.222-31.535 2.734-41.01-2.434zm27.257-138.714c3.011 8.002 10.91 9.165 19.365 4.454 6.161 3.697 15.69 3.93 18.976-4.067 2.499 19.784-18.305 15.104-19.08 11.231-7.745 7.487-22.165 3.163-19.26-11.618z" />
          <path d="M256.402 331.569l7.813-8.93 1.117-120.178-9.302-8.185-9.3 7.813 1.859 120.92 7.813 8.558z" />
        </g>
      </g>
    </svg>
  );
}

export default SvgIr;
