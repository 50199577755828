module.exports = {
  purge: false,
  prefix: "aui-",
  theme: {
    fontFamily: {
      sans: ['"Proxima Nova"', "Arial", "sans-serif"],
      serif: ["Georgia", "Cambria", '"Times New Roman"', "Times", "serif"],
      mono: [
        "Menlo",
        "Monaco",
        "Consolas",
        '"Liberation Mono"',
        '"Courier New"',
        "monospace"
      ]
    },
    extend: {
      borderRadius: {
        full: "50%"
      },
      fontWeight: {
        thin: 200,
        regular: 400,
        semibold: 500,
        bold: 600
      },
      fontSize: {
        emphasis: "24px",
        header: "21px",
        section: "18px",
        "sub-heading": "14px",
        "sub-text": "12px",
        body: "14px",
        "table-cell": "12px",
        "modal-header": "12px",
        tiny: "8px",
        "extra-small": "6px",
        "icon-tiny": "4px",
        "feedback-header": "80%",
        "7xl": "5rem"
      },
      lineHeight: {
        subtext: "0.92rem"
      },
      letterSpacing: {
        snug: "0.011rem",
        narrowest: "0.016rem",
        narrower: "0.019rem",
        narrow: "0.031"
      },
      spacing: {
        "2xs": "2px",
        xs: "5px",
        sm: "10px",
        md: "15px",
        lg: "20px",
        xl: "30px",
        header: "5rem",
        "2xl": "35px",
        "3xl": "50px",
        "label-editor": "350px",
        "textbox-height": "80px",
        sidebar: "400px",
        "-18": "-4.5rem",
        18: "4.5rem",
        26: "6.5rem",
        34: "8.5rem",
        micro: "4px",
        tiny: "8px",
        "x-small": "12px",
        small: "16px",
        standard: "24px",
        semi: "32px",
        large: "48px",
        "x-large": "64px",
        "xx-large": "128px"
      },
      maxHeight: {
        40: "10rem",
        48: "12rem",
        56: "14rem",
        64: "16rem"
      },
      maxWidth: {
        8: "8rem"
      },
      minWidth: {
        xl: "30px",
        card: "330px",
        compare: "198px"
      },
      minHeight: {
        10: "2.5rem",
        12: "3rem",
        64: "16rem",
        xl: "30px",
        "1/4": "25%",
        "1/2": "50%",
        "3/4": "75%"
      },
      zIndex: {
        max: 9999999
      },
      height: {
        "2/10": "20%",
        "3/10": "30%",
        "4/10": "40%",
        "5/10": "50%",
        "6/10": "60%",
        "7/10": "70%",
        "8/10": "80%",
        "9/10": "90%",
        "standard-input": "38px"
      },
      width: {
        "modal-medium": "900px",
        max: "max-content",
        min: "min-content"
      },
      boxShadow: {
        prominent: "0 0 6px 0 rgba(0,0,0,.03), 0 0 3px 0 rgba(0,0,0,.06)",
        huge: "0 105px 250px -12px rgba(0, 0, 0, 0.65)",
        "blue-1": "0 0 6px 0 rgba(0,0,0,.03), 0 0 3px 0 rgba(0, 129, 255, 1)",
      },
      colors: {
        white: "#ffffff",
        black: "#222222",
        "dark-1": "#001d3a",
        "brand-navy": "#001d3a",
        "white-smoke": "#f8f8f8",
        "warning-1": "#fff9c2",
        "warning-2": "#f2d024",
        "warning-3": "#333333",
        "blue-1": "#0081ff",
        "grey-0": "#eeeeee",
        "grey-1": "#f8f8f8",
        "grey-2": "#e7e8ea",
        "grey-3": "#a2acb0",
        "grey-4": "#717c80",
        "grey-5": "#222222",
        "grey-6": "#717c80",
        "grey-7": "#d3d3d3",
        "primary-1": "#3181ff",
        "highlight-1": "#EAF2FF",
        "highlight-1-alt": "#f0f6ff",
        "primary-2": "#FD3001",
        "highlight-2": "#FC8300",
        "highlight-3": "#4fc3f7",
        "highlight-4": "#cc8d89",
        "highlight-5": "#9ed3e8",
        "highlight-6": "#ff7f7d",
        "success-1": "#e3fcec",
        "success-2": "#0CBC9A",
        "success-3": "#00a89c",
        "success-4": "#0CBC9A",
        "success-5": "#00C19B",
        "error-1": "#FFD6CC",
        "error-2": "#FD3001",
        "brand-1": "#FC8300",
        "disabled-1": "#FFD6CC",
        "secondary-1": "#e6e8ff",
        "light-blue-1": "#EAF2FF",
        "light-blue-2": "#eaf2ff",
        "light-blue-3": "#b592fa",
        "alphadeal-1": "#74237b",
        "alphadeal-2": "#eab925"
      },
      inset: {
        "1/2": "50%",
        "3/2": "150%"
      },
      opacity: {
        "30": ".3"
      }
    }
  },
  inset: {
    0: 0,
    "2xl": "30px"
  },
  variants: {
    borderColor: ["responsive", "group-hover", "hover", "focus"],
    backgroundColor: ["responsive", "group-hover", "hover", "focus"],
    display: ["responsive", "hover", "focus", "group-hover"],
    textColor: ["responsive", "group-hover", "hover", "focus", "visited"]
  },
  plugins: [],
  corePlugins: {
    textOpacity: false,
    backgroundOpacity: false,
    borderOpacity: false,
    placeholderOpacity: false,
    divideOpacity: false
  }
};
