import jss from "jss";
import preset from "jss-preset-default";

jss.setup(preset());

export type RealTimeModuleStylesConfig = {
    width?: number | string;
    height?: number | string;
};

const defaultStylesConfig: RealTimeModuleStylesConfig = {
    width: "100%",
    height: "100%",
};

export const createClasses = (config: Partial<RealTimeModuleStylesConfig> = {}) => {
    const { width, height } = config;

    const styles = {
        moduleContainer: {
            border: "none",
            padding: 0,
            margin: 0,
            width: width ? String(width).replace(/px/gi, "") + "px" : defaultStylesConfig.width,
            height: height ? String(height).replace(/px/gi, "") + "px" : defaultStylesConfig.height,
            maxWidth: "100%",
        },
    };

    return jss.createStyleSheet(styles).attach().classes;
};
