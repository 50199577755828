import React, { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";

import { Icon } from "../../";
import { useOutsideClick } from "../../../hooks";

const Sidebar = props => {
  const ref = useRef();

  if (!props.disableOutsideClick) {
    useOutsideClick(ref, () => {
      handleClose();
    });
  }

  useEffect(() => {
    document.addEventListener("keydown", handleEscapePress, false);

    return () => {
      document.removeEventListener("keydown", handleEscapePress, false);
    };
  }, []);

  const [animation, setAnimation] = useState("aui-slide-in-left");
  const classNames = [
    "as-sidebar",
    "aui-module-shadow",
    "aui-h-full",
    "aui-pr-lg",
    "aui-pb-lg",
    "aui-pt-lg",
    "aui-pl-2xl",
    "aui-absolute",
    "aui-z-50",
    animation,
    props.width ? props.width : "aui-w-1/4",
    props.className ? props.className : ""
  ].join(" ");

  function handleClose() {
    if (props.onClose) props.onClose();
    setAnimation("aui-slide-out-left");
  }

  function handleEscapePress(evt) {
    if (evt.keyCode === 27) {
      handleClose();
    }
  }

  function renderSidebarContents() {
    const contents = (
      <React.Fragment>
        <div className="aui-flex aui-items-center">
          <div className="aui-text-grey-5 aui-text-section aui-font-semibold">
            {props.title}
          </div>
          <Icon
            icon="close"
            className="aui-ml-auto aui-text-xs aui-text-dark-1 aui-cursor-pointer hover:aui-text-grey-4"
            onClick={handleClose}
          />
        </div>
        {props.children}
      </React.Fragment>
    );
    if (props.sticky) {
      return (
        <div
          className="aui-sticky"
          style={{ top: `${props.stickyTopOffsetPx}px` }}
        >
          {contents}
        </div>
      );
    }
    return contents;
  }

  return (
    <div className={classNames} ref={ref}>
      {renderSidebarContents()}
    </div>
  );
};

Sidebar.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string,
  title: PropTypes.string,
  width: PropTypes.string,
  onClose: PropTypes.func,
  sticky: PropTypes.bool,
  disableOutsideClick: PropTypes.bool,
  stickyTopOffsetPx: PropTypes.number
};

Sidebar.defaultProps = {
  sticky: false,
  stickyTopOffsetPx: 0,
  disableOutsideClick: false
};

export { Sidebar };
