import React, { useLayoutEffect, useState } from "react";
import debounce from "lodash/debounce";

const isPartiallyInViewport = (ref, bottomOffset) => {
  if (ref && ref.current) {
    const { top, bottom } = ref.current.getBoundingClientRect();
    const viewportHeight =
      window.innerHeight || document.documentElement.clientHeight;
    return viewportHeight - top > 0 && bottom > bottomOffset;
  }
  return false;
};

export const useIsPartiallyInViewport = (
  ref,
  timeout = 200,
  bottomOffset = 0
) => {
  const [inViewport, setInViewport] = useState(
    isPartiallyInViewport(ref, bottomOffset)
  );

  useLayoutEffect(() => {
    const handleScroll = debounce(
      () => setInViewport(isPartiallyInViewport(ref, bottomOffset)),
      timeout
    );
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);
  return [inViewport];
};
