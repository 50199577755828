import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import CSSSpinner from "./CSSSpinner";
import SVGSpinner from "./SVGSpinner";

const SIZES_MAP = {
  small: "aui-text-2xl",
  medium: "aui-text-4xl",
  large: "aui-text-6xl"
};

export function Spinner(props) {
  const {
    className = "",
    wrapperClassName = "",
    size,
    transparent = false,
    type = "css"
  } = props;
  const sizeClass = SIZES_MAP[size] || "";

  const bgClass = transparent ? "" : "aui-bg-white";

  return (
    <div className={`aui-z-50 ${bgClass} ${wrapperClassName}`}>
      <div
        className={`aui-spinner aui-block aui-inset-0 aui-m-auto ${className} ${sizeClass}`}
      >
        {type === "css" && <CSSSpinner />}
        {type === "svg" && <SVGSpinner />}
      </div>
    </div>
  );
}

Spinner.propTypes = {
  wrapperClassName: PropTypes.string,
  className: PropTypes.string,
  size: PropTypes.oneOf(["small", "medium", "large"]),
  type: PropTypes.oneOf(["css", "svg"]),
  transparent: PropTypes.bool
};
