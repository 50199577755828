import React from "react";
import PropTypes from "prop-types";

const DropdownRowItem = props => {
  const noop = () => {};
  const onClick = props.onClick || noop;
  const disabledStyles = props.disabled
    ? "aui-pointer-events-none aui-text-grey-4"
    : "aui-cursor-pointer";
  return (
    <div
      className={`aui-dropdown-item hover:aui-bg-light-blue-1 ${disabledStyles} ${props.className ||
        ""}`}
      role="option"
      onClick={onClick}
    >
      <div className="aui-border-0 aui-border-b aui-border-l aui-border-r aui-border-grey-2 aui-border-solid"></div>
      <div className="aui-p-3 aui-py-4 aui-select-none">{props.children}</div>
    </div>
  );
};

DropdownRowItem.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  onClick: PropTypes.any
};

export { DropdownRowItem };
