import React from "react";
import PropTypes from "prop-types";
import { Icon } from "../../";
import "./index.pcss";

const Tooltip = props => {
  const wide = props.wide === true;
  const narrow = props.narrow !== false;
  const noMargin = props.noMargin !== false;
  const position = (props.position && props.position.toLowerCase()) || "top";

  const classNames = ["aui-relative", props.className || ""].join(" ");

  const tooltipPosition = position => {
    if (["bottom", "bottom-left", "bottom-right"].includes(position))
      return "as-top-1";
    return "aui-bottom-3/2";
  };

  const borderPosition = position => {
    if (["bottom", "bottom-left", "bottom-right"].includes(position))
      return "aui-border-t";
    return "aui-border-b";
  };
  const tooltipClassNames = [
    "aui-invisible",
    "aui-bg-white",
    "aui-text-grey-5",
    "aui-p-4",
    "aui-my-2",
    "aui-absolute",
    "aui-z-20",
    "aui-box-content",
    wide ? "aui-w-64 aui--ml-34" : "",
    narrow ? "aui-w-32 aui--ml-18" : "",
    noMargin ? "" : "aui-w-48 aui--ml-26",
    "aui-border-0",
    "aui-border-dark-1",
    "aui-border-solid",
    "aui-module-shadow",
    "aui-zoom-in",
    borderPosition(position),
    tooltipPosition(position),
    position === "bottom-right" ? "aui-left-0" : "aui-left-1/2",
    props.customTooltipClassNames || ""
  ]
    .join(" ")
    .trim();

  return (
    <span
      className={`aui-tooltip hover:aui-text-primary-2 ${classNames}`}
      onMouseEnter={props.onMouseEnter}
    >
      {props.children || (
        <Icon
          className={`${
            props.iconSize ? `aui-text-${props.iconSize}` : ""
          } hover:aui-text-primary-2`}
          icon={props.icon || "question-circle"}
        />
      )}
      <span
        className={`aui-tooltip-text ${tooltipClassNames} ${
          props.enableHidden ? `aui-hidden` : ""
        }`}
      >
        {props.isMessageHtml ? (
          <div
            dangerouslySetInnerHTML={{
              __html: props.message
            }}
          />
        ) : (
          props.message
        )}
      </span>
    </span>
  );
};

Tooltip.propTypes = {
  icon: PropTypes.string,
  iconSize: PropTypes.string,
  className: PropTypes.string,
  message: PropTypes.string,
  children: PropTypes.any,
  wide: PropTypes.bool,
  narrow: PropTypes.bool,
  position: PropTypes.string,
  noMargin: PropTypes.bool,
  onMouseEnter: PropTypes.func,
  enableHidden: PropTypes.bool,
  isMessageHtml: PropTypes.bool,
  customTooltipClassNames: PropTypes.string
};

export { Tooltip };
