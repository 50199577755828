import React, { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";

import { Icon } from "../../";
import { useOutsideClick } from "../../../hooks";

// eslint-disable-next-line react/display-name
const Popup = React.forwardRef((props, ref) => {
  ref = ref || useRef();
  const {
    className,
    children,
    onClose,
    onMouseDown,
    onMouseUp,
    closeOnOutsideClick,
    invisible,
    padding,
    margin
  } = props;
  const [animation, setAnimation] = useState("aui-zoom-in");
  const latestCloseOnOutsideClick = useRef(closeOnOutsideClick);
  useOutsideClick(
    ref,
    () => latestCloseOnOutsideClick.current && setAnimation("aui-zoom-out")
  );

  useEffect(() => {
    latestCloseOnOutsideClick.current = closeOnOutsideClick;
  }, [closeOnOutsideClick]);

  const style = {};

  if (props.x && props.y) {
    style.left = props.x + "px";
    style.top = props.y + "px";
    style.position = "absolute";
  }

  function handleZoomOutOnAnimationEnd() {
    if (animation === "aui-zoom-out") {
      onClose();
    }
  }

  return (
    <div
      ref={ref}
      style={style}
      onMouseDown={onMouseDown}
      onMouseUp={onMouseUp}
      // if the popup is invisible, we want it to wrap to a new line (block element) but to only take up the width of its content
      className={`${
        invisible
          ? "aui-table"
          : `aui-module-shadow aui-bg-white ${padding || "aui-p-4"}`
      } ${animation} ${className}`}
      onAnimationEnd={handleZoomOutOnAnimationEnd}
    >
      {onClose && !closeOnOutsideClick && (
        <Icon
          icon="close"
          className="aui-float-right aui-text-xs aui-mt-2 aui-text-dark-1 aui-font-thin hover:aui-text-black aui-cursor-pointer"
          onClick={() => setAnimation("aui-zoom-out")}
        />
      )}
      <div className={`${margin || "aui-mt-2"}`}>{children}</div>
    </div>
  );
});

Popup.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string,
  x: PropTypes.number,
  y: PropTypes.number,
  onClose: PropTypes.func,
  onMouseDown: PropTypes.func,
  onMouseUp: PropTypes.func,
  closeOnOutsideClick: PropTypes.bool,
  invisible: PropTypes.bool,
  padding: PropTypes.string,
  margin: PropTypes.string
};

export { Popup };
