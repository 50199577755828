import React, { useEffect, useState } from "react";

import "./CSSSpinner.css";

const CSSSpinner = () => {
  const [stage, setStage] = useState(0);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setStage((stage + 1) % 4);
    }, 1000);

    return () => clearTimeout(timeoutId);
  }, [stage]);

  return (
    <div className={`aui-spinner-logo aui-spinner-stage-${stage}`}>
      <div className="part-one">
        <div className="logo-part tip" />
        <div className="logo-part slope" />
        <div className="logo-part box" />
        <div className="logo-part inner" />
      </div>
      <div className="part-two">
        <div className="logo-part tip" />
        <div className="logo-part slope" />
        <div className="logo-part box" />
        <div className="logo-part inner" />
      </div>
    </div>
  );
};

export default CSSSpinner;
