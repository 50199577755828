import React from "react";
import PropTypes from "prop-types";

import { TextHeaderFour, TextBodyThree } from "../Styles/Text";

const Container = props => {
  const className = [
    "aui-block",
    "aui-overflow-hidden",
    "aui-box-border",
    "aui-p-standard",
    "aui-bg-white",
    "aui-text-grey-5",
    "aui-rounded-lg",
    "aui-shadow-prominent",
    props.className || ""
  ]
    .join(" ")
    .trim();

  return (
    <div className={className}>
      {props.title && (
        <TextHeaderFour className="aui-inline-block aui-text-grey-5">
          {props.title}
        </TextHeaderFour>
      )}
      {props.subtitle && (
        <TextBodyThree
          className={`aui-text-grey-4 ${
            props.subtitleBelow ? "aui-pt-2" : "aui-inline-block aui-pl-2 "
          }`}
        >
          {props.subtitle}
        </TextBodyThree>
      )}
      <div className="aui-block aui-pt-4">{props.children}</div>
    </div>
  );
};

export { Container };

Container.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.any,
  subtitleBelow: PropTypes.bool
};
