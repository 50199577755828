import React, { useState, useEffect } from "react";

export function useDragAndDrop(ref, dropTarget = null, effectClass = null) {
  const [position, setPosition] = useState({ x: null, y: null });
  const [isInsideDropTarget, setIsInsideDropTarget] = useState(false);
  const [isDragging, setIsDragging] = useState(false);

  useEffect(() => {
    if (isDragging) {
      applyEffect();
    } else {
      removeEffect();
      if (dropTarget) {
        const boundingBox = dropTarget.current.getBoundingClientRect();
        setIsInsideDropTarget(
          position.x >= boundingBox.left &&
            position.y >= boundingBox.top &&
            position.y <= boundingBox.top + boundingBox.height &&
            position.x <= boundingBox.width + boundingBox.left
        );
      }
    }
  }, [isDragging]);

  useEffect(() => {
    if (!isDragging || !ref) return;
    ref.current.style.left = position.x + "px";
    ref.current.style.top = position.y + "px";
  }, [position]);

  useEffect(() => {
    window.addEventListener("mousemove", evt => {
      setPosition({ x: evt.clientX, y: evt.clientY });
    });
  }, []);

  function applyEffect() {
    if (!effectClass) return;
    ref.current.classList.add(effectClass);
  }

  function removeEffect() {
    if (!effectClass) return;
    ref.current.classList.remove(effectClass);
  }

  return [isDragging, setIsDragging, isInsideDropTarget];
}
