import React from "react";
import PropTypes from "prop-types";

import { CheckboxInput } from "../../";
import { deprecated } from "../../../utils";

const Card = props => {
  const className = [
    "aui-bg-white",
    "aui-block",
    "aui-max-w-sm",
    "aui-overflow-hidden",
    "aui-grid-card-shadow",
    "aui-p-lg",
    "aui-border-0",
    "aui-border-t",
    "aui-border-solid",
    "aui-border-dark-1",
    props.className || ""
  ]
    .join(" ")
    .trim();

  const isString = value => typeof value === "string";
  const isFunction = value => value instanceof Function;

  const titleClick = props.onTitleClick || props.titleHref;
  const titleClass =
    "aui-text-black hover:aui-text-primary-1 aui-no-underline" +
    (titleClick ? " aui-cursor-pointer" : "");

  return (
    <div className={className}>
      <div className="aui-font-bold">
        {props.selectedState && (
          <CheckboxInput
            state={props.selectedState}
            className="aui-float-right aui-text-2xl"
          />
        )}
        {titleClick && (
          <a
            className={titleClass}
            onClick={isFunction(titleClick) ? titleClick : null}
            href={isString(titleClick) ? titleClick : null}
            // eslint-disable-next-line react/jsx-no-target-blank
            target={isString(titleClick) ? "_blank" : null}
          >
            {props.title}
            <div>{props.subtitle}</div>
          </a>
        )}
        {!titleClick && props.title}
      </div>
      {!titleClick && <div className="aui-text-dark-1">{props.subtitle}</div>}
      {props.children}
    </div>
  );
};

export { Card };

Card.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  onTitleClick: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  titleHref: deprecated(PropTypes.string),
  className: PropTypes.string,
  children: PropTypes.any,
  selectedState: PropTypes.array
};
