export * from "./format";
export * from "./localStorage";
export * from "./object";
export * from "./scroll";
export * from "./validation";

export function uuid() {
  const simpleUuid = () => {
    // From: https://stackoverflow.com/questions/105034/how-to-create-guid-uuid#2117523 original.  Note the comment regarding the reliance on Math.random() and how that's not a high-quality random number generator.
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function(c) {
      var r = (Math.random() * 16) | 0,
        v = c == "x" ? r : (r & 0x3) | 0x8;
      return v.toString(16);
    });
  };

  const crypto = window.crypto || window.msCrypto;

  if (!crypto) {
    // Use Simple uuid when crypto is not available such as running tests.
    return simpleUuid();
  }

  // From: https://stackoverflow.com/questions/105034/how-to-create-guid-uuid#2117523 update 2017-06-28
  return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
    (
      c ^
      (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
    ).toString(16)
  );
}
