/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect } from "react";
import { v4 as uuid } from "uuid";
import { RealTimeModuleController, messages, MessagePayload } from "@alphasights/realtime-import";

/**
 * React Component to render external module in iframe
 * @date 7/17/2023 - 5:51:47 PM
 *
 * @export
 * @interface RealTimeModuleRendererProps
 * @typedef {RealTimeModuleRendererProps}
 * @template T
 */
export interface RealTimeModuleRendererProps<T extends Record<string, unknown> = Record<string, unknown>> {
    /**
     * The url of the module to be rendered
     * @date 7/17/2023 - 5:51:47 PM
     *
     * @type {string}
     */
    moduleUrl: string;
    /**
     * Callback to be called when a message is received from the module
     * @date 7/17/2023 - 5:51:47 PM
     *
     * @type {?(type: string, payload: MessagePayload) => void}
     */
    onMessage?: (type: string, payload: MessagePayload) => void;
    /**
     * Callback to be called when the module is loaded
     * @date 7/17/2023 - 5:51:47 PM
     *
     * @type {?() => void}
     */
    onModuleLoad?: () => void;
    /**
     * The context to be sent to the module
     * @date 7/17/2023 - 5:51:47 PM
     *
     * @type {T}
     */
    context: T;
    /**
     * The id of the iframe. If not provided, a random id will be generated
     * @date 7/17/2023 - 5:51:47 PM
     *
     * @type {?string}
     */
    id?: string;
    /**
     * The target origin of the iframe. If not provided, "*" will be used
     * @date 7/17/2023 - 5:51:47 PM
     *
     * @type {?string}
     */
    targetOrigin?: string;
}

/**
 * Description placeholder
 * @date 7/17/2023 - 5:51:47 PM
 *
 * @param {RealTimeModuleRendererProps} {
    moduleUrl,
    onMessage,
    onModuleLoad,
    context,
    id = uuid(),
    targetOrigin = "*",
}
 * @returns {*}
 */
export const RealTimeModuleRenderer = ({
    moduleUrl,
    onMessage,
    onModuleLoad,
    context,
    id = uuid(),
    targetOrigin = "*",
}: RealTimeModuleRendererProps) => {
    // Instance of the iframe controller
    const moduleController = React.useMemo(() => new RealTimeModuleController(id, targetOrigin), [id, targetOrigin]);

    // Reference to the context
    const contextRef = React.useRef(null);

    // Context value
    const contextValue = React.useMemo(() => context, [context]);

    // Initialize the iframe controller
    useEffect(() => {
        // Set the callbacks
        moduleController.onModuleLoaded = onModuleLoad;
        moduleController.onRequestContext = sendContextToIframe;
        moduleController.onMessage = onMessage;

        // Initialize the iframe URL
        moduleController.url = moduleUrl;
        moduleController.init();

        return () => {
            // Dispose the iframe controller
            moduleController.dispose();
        };
    }, [moduleUrl]);

    const sendContextToIframe = () => {
        moduleController.sendMessage(messages.setContext(contextRef.current));
    };

    const updateContext = (newContext: any) => {
        if (contextRef.current !== newContext) {
            contextRef.current = newContext;
            sendContextToIframe();
        }
    };

    React.useEffect(() => {
        updateContext(contextValue);
    }, [contextValue, updateContext]);

    return <iframe id={id} />;
};
