import { Message, MessagePayload } from "types/types";
import { createClasses } from "./RealTimeModuleRenderer.styles";
import { Events } from "types/events";

// eslint-disable-next-line @typescript-eslint/no-empty-function
const noop = () => {};

const IGNORED_MESSAGES_PREFIXES = ["webpackHotUpdate"];

/**
 * RealTimeModuleController. Used to communicate with the module inside the iframe
 * @date 7/17/2023 - 5:55:04 PM
 *
 * @export
 * @class RealTimeModuleController
 * @typedef {RealTimeModuleController}
 */
export class RealTimeModuleController {
    private _height: number | undefined = undefined;
    private _igoredPrefixes: string[] = IGNORED_MESSAGES_PREFIXES;
    private bindedHandleWindowMessage: (event: MessageEvent) => void;

    /**
     * Called when the module is loaded
     * @date 7/17/2023 - 5:55:04 PM
     *
     * @public
     * @type {(() => void) | undefined}
     */
    public onModuleLoaded: (() => void) | undefined = noop;
    /**
     * Called when a message is received from the module
     * @date 7/17/2023 - 5:55:04 PM
     *
     * @public
     * @type {((type: string, payload: MessagePayload) => void) | undefined}
     */
    public onMessage: ((type: string, payload: MessagePayload) => void) | undefined = noop;
    /**
     * Called when the module requests the context
     * @date 7/17/2023 - 5:55:04 PM
     *
     * @public
     * @type {(() => void) | undefined}
     */
    public onRequestContext: (() => void) | undefined = noop;

    /**
     * Creates an instance of RealTimeModuleController.
     * @date 7/17/2023 - 5:55:04 PM
     *
     * @constructor
     * @param {string} iframeId
     * @param {string} [targetOrigin="*"]
     */
    constructor(
        private iframeId: string,
        public targetOrigin = "*",
    ) {
        this.bindedHandleWindowMessage = this.handleWindowMessage.bind(this);
        this.init();
    }

    /**
     * Initializes the instance attaching the window events
     * @date 7/17/2023 - 5:55:04 PM
     *
     * @public
     */
    public init() {
        this.attachEvents();
    }

    /**
     * Disposes the instance dettaching the window events
     * @date 7/17/2023 - 5:55:04 PM
     *
     * @public
     */
    public dispose() {
        this.dettachEvents();
    }

    /**
     * Iframe height. It will change the height using css styles
     * @date 7/17/2023 - 5:55:04 PM
     *
     * @type {number}
     */
    set height(height: number | undefined) {
        if (this.iframe && this._height !== height) {
            this._height = height;
            this.attachStyles();
        }
    }

    /**
     * Iframe height. It will change the height using css styles
     * @date 7/17/2023 - 5:55:04 PM
     *
     * @type {(number | undefined)}
     */
    get height(): number | undefined {
        return this._height;
    }

    /**
     * Module url to be rendered inside the iframe
     * @date 7/17/2023 - 5:55:04 PM
     *
     * @public
     * @type {string}
     */
    public set url(value: string | null) {
        this.iframe.setAttribute("src", value ?? "");
    }

    /**
     * Module url to be rendered inside the iframe
     * @date 7/17/2023 - 5:55:04 PM
     *
     * @public
     * @type {string}
     */
    public get url() {
        return this.iframe.getAttribute("src");
    }

    /**
     * Iframe element
     * @date 7/17/2023 - 5:55:04 PM
     *
     * @private
     * @readonly
     * @type {*}
     */
    private get iframe() {
        return document.getElementById(this.iframeId) as HTMLIFrameElement;
    }

    /**
     * Sends a message to the module inside the iframe. It uses postMessage function
     * @date 7/17/2023 - 5:55:04 PM
     *
     * @public
     * @param {(string | Message)} message
     */
    public sendMessage(message: string | Message): void {
        if (!this.iframe) {
            return;
        }
        this.iframe.contentWindow?.postMessage(message, this.targetOrigin);
    }

    public addIgnoredMessagePrefix(prefix: string): void {
        this._igoredPrefixes.push(prefix);
    }

    /**
     * Attaches the css styles to the iframe
     * @date 7/17/2023 - 5:55:04 PM
     *
     * @private
     */
    private attachStyles(): void {
        if (!this.iframe) {
            return;
        }
        const classes = createClasses({ height: this.height });
        this.iframe.setAttribute("class", classes.moduleContainer);
    }

    /**
     * Handles the window message event
     * @date 7/17/2023 - 5:55:04 PM
     *
     * @private
     * @param {MessageEvent} event
     */
    private handleWindowMessage(event: MessageEvent): void {
        if (event.data) {
            try {
                const isEventDataInIgnoredPrefixes =
                    typeof event.data === "string" &&
                    this._igoredPrefixes.some((prefix) => event.data.startsWith(prefix));
                if (!isEventDataInIgnoredPrefixes) {
                    const eventData = typeof event.data === "string" ? JSON.parse(event.data) : event.data;
                    this.handleMessage(eventData);
                }
            } catch {
                // eslint-disable-next-line no-console
                console.error("Invalid message received from iframe", event.data);
            }
        }
    }

    /**
     * Captures the message and calls the corresponding handler
     * @date 7/17/2023 - 5:55:04 PM
     *
     * @private
     * @param {Message} message
     */
    private handleMessage(message: Message): void {
        switch (message.type) {
            case Events.Resize:
                this.height = (message.payload as { height: number })?.height;
                break;
            case Events.ModuleLoaded:
                this.onModuleLoaded?.();
                break;
            case Events.RequestContext:
                this.onRequestContext?.();
                break;
            default:
                this.onMessage?.(message.type, message.payload);
                break;
        }
    }

    /**
     * Attach window events
     * @date 7/17/2023 - 5:55:04 PM
     *
     * @private
     */
    private attachEvents(): void {
        this.dettachEvents();
        window.addEventListener("message", this.bindedHandleWindowMessage);
    }

    /**
     * Dettach window events
     * @date 7/17/2023 - 5:55:04 PM
     *
     * @private
     */
    private dettachEvents(): void {
        window.removeEventListener("message", this.bindedHandleWindowMessage);
    }
}
