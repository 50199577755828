/**
 * Do not edit directly
 * Generated on Mon, 12 Aug 2024 17:22:10 GMT
 */

module.exports = {
  "blur": {
    "tertiary": {
      "value": "blur(2px)",
      "filePath": "src/properties/blur.json",
      "isSource": true,
      "original": {
        "value": "blur(2px)"
      },
      "name": "BlurTertiary",
      "attributes": {
        "category": "blur",
        "type": "tertiary"
      },
      "path": [
        "blur",
        "tertiary"
      ]
    },
    "secondary": {
      "value": "blur(4px)",
      "filePath": "src/properties/blur.json",
      "isSource": true,
      "original": {
        "value": "blur(4px)"
      },
      "name": "BlurSecondary",
      "attributes": {
        "category": "blur",
        "type": "secondary"
      },
      "path": [
        "blur",
        "secondary"
      ]
    },
    "strong": {
      "value": "blur(6px)",
      "filePath": "src/properties/blur.json",
      "isSource": true,
      "original": {
        "value": "blur(6px)"
      },
      "name": "BlurStrong",
      "attributes": {
        "category": "blur",
        "type": "strong"
      },
      "path": [
        "blur",
        "strong"
      ]
    }
  },
  "screen": {
    "breakpoint": {
      "xs": {
        "value": 320,
        "filePath": "src/properties/breakpoint.json",
        "isSource": true,
        "original": {
          "value": 320
        },
        "name": "ScreenBreakpointXs",
        "attributes": {
          "category": "screen",
          "type": "breakpoint",
          "item": "xs"
        },
        "path": [
          "screen",
          "breakpoint",
          "xs"
        ]
      },
      "sm": {
        "value": 600,
        "filePath": "src/properties/breakpoint.json",
        "isSource": true,
        "original": {
          "value": 600
        },
        "name": "ScreenBreakpointSm",
        "attributes": {
          "category": "screen",
          "type": "breakpoint",
          "item": "sm"
        },
        "path": [
          "screen",
          "breakpoint",
          "sm"
        ]
      },
      "md": {
        "value": 1280,
        "filePath": "src/properties/breakpoint.json",
        "isSource": true,
        "original": {
          "value": 1280
        },
        "name": "ScreenBreakpointMd",
        "attributes": {
          "category": "screen",
          "type": "breakpoint",
          "item": "md"
        },
        "path": [
          "screen",
          "breakpoint",
          "md"
        ]
      },
      "lg": {
        "value": 1440,
        "filePath": "src/properties/breakpoint.json",
        "isSource": true,
        "original": {
          "value": 1440
        },
        "name": "ScreenBreakpointLg",
        "attributes": {
          "category": "screen",
          "type": "breakpoint",
          "item": "lg"
        },
        "path": [
          "screen",
          "breakpoint",
          "lg"
        ]
      },
      "xl": {
        "value": 1920,
        "filePath": "src/properties/breakpoint.json",
        "isSource": true,
        "original": {
          "value": 1920
        },
        "name": "ScreenBreakpointXl",
        "attributes": {
          "category": "screen",
          "type": "breakpoint",
          "item": "xl"
        },
        "path": [
          "screen",
          "breakpoint",
          "xl"
        ]
      }
    },
    "content": {
      "md": {
        "value": 1280,
        "filePath": "src/properties/breakpoint.json",
        "isSource": true,
        "original": {
          "value": 1280
        },
        "name": "ScreenContentMd",
        "attributes": {
          "category": "screen",
          "type": "content",
          "item": "md"
        },
        "path": [
          "screen",
          "content",
          "md"
        ]
      },
      "lg": {
        "value": 1440,
        "filePath": "src/properties/breakpoint.json",
        "isSource": true,
        "original": {
          "value": 1440
        },
        "name": "ScreenContentLg",
        "attributes": {
          "category": "screen",
          "type": "content",
          "item": "lg"
        },
        "path": [
          "screen",
          "content",
          "lg"
        ]
      },
      "xl": {
        "value": 1728,
        "filePath": "src/properties/breakpoint.json",
        "isSource": true,
        "original": {
          "value": 1728
        },
        "name": "ScreenContentXl",
        "attributes": {
          "category": "screen",
          "type": "content",
          "item": "xl"
        },
        "path": [
          "screen",
          "content",
          "xl"
        ]
      }
    }
  },
  "color": {
    "base": {
      "none": {
        "value": "rgba(0, 0, 0, 0)",
        "filePath": "src/properties/color/base.json",
        "isSource": true,
        "original": {
          "value": "rgba(0, 0, 0, 0)"
        },
        "name": "ColorBaseNone",
        "attributes": {
          "category": "color",
          "type": "base",
          "item": "none"
        },
        "path": [
          "color",
          "base",
          "none"
        ]
      },
      "alpha": {
        "light": {
          "100": {
            "value": "rgba(26, 46, 96, 0.04)",
            "filePath": "src/properties/color/base.json",
            "isSource": true,
            "original": {
              "value": "#1A2E600A"
            },
            "name": "ColorBaseAlphaLight100",
            "attributes": {
              "category": "color",
              "type": "base",
              "item": "alpha",
              "subitem": "light",
              "state": "100"
            },
            "path": [
              "color",
              "base",
              "alpha",
              "light",
              "100"
            ]
          },
          "200": {
            "value": "rgba(26, 46, 96, 0.06)",
            "filePath": "src/properties/color/base.json",
            "isSource": true,
            "original": {
              "value": "#1A2E600F"
            },
            "name": "ColorBaseAlphaLight200",
            "attributes": {
              "category": "color",
              "type": "base",
              "item": "alpha",
              "subitem": "light",
              "state": "200"
            },
            "path": [
              "color",
              "base",
              "alpha",
              "light",
              "200"
            ]
          }
        },
        "dark": {
          "100": {
            "value": "rgba(223, 230, 246, 0.12)",
            "filePath": "src/properties/color/base.json",
            "isSource": true,
            "original": {
              "value": "#DFE6F61F"
            },
            "name": "ColorBaseAlphaDark100",
            "attributes": {
              "category": "color",
              "type": "base",
              "item": "alpha",
              "subitem": "dark",
              "state": "100"
            },
            "path": [
              "color",
              "base",
              "alpha",
              "dark",
              "100"
            ]
          },
          "200": {
            "value": "rgba(223, 230, 246, 0.24)",
            "filePath": "src/properties/color/base.json",
            "isSource": true,
            "original": {
              "value": "#DFE6F63D"
            },
            "name": "ColorBaseAlphaDark200",
            "attributes": {
              "category": "color",
              "type": "base",
              "item": "alpha",
              "subitem": "dark",
              "state": "200"
            },
            "path": [
              "color",
              "base",
              "alpha",
              "dark",
              "200"
            ]
          }
        }
      },
      "white": {
        "value": "#ffffff",
        "filePath": "src/properties/color/base.json",
        "isSource": true,
        "original": {
          "value": "#FFFFFF"
        },
        "name": "ColorBaseWhite",
        "attributes": {
          "category": "color",
          "type": "base",
          "item": "white"
        },
        "path": [
          "color",
          "base",
          "white"
        ]
      },
      "black": {
        "value": "#000000",
        "filePath": "src/properties/color/base.json",
        "isSource": true,
        "original": {
          "value": "#000000"
        },
        "name": "ColorBaseBlack",
        "attributes": {
          "category": "color",
          "type": "base",
          "item": "black"
        },
        "path": [
          "color",
          "base",
          "black"
        ]
      },
      "grey": {
        "25": {
          "value": "#f9fafb",
          "filePath": "src/properties/color/base.json",
          "isSource": true,
          "original": {
            "value": "#F9FAFB"
          },
          "name": "ColorBaseGrey25",
          "attributes": {
            "category": "color",
            "type": "base",
            "item": "grey",
            "subitem": "25"
          },
          "path": [
            "color",
            "base",
            "grey",
            "25"
          ]
        },
        "50": {
          "value": "#f6f7f9",
          "filePath": "src/properties/color/base.json",
          "isSource": true,
          "original": {
            "value": "#F6F7F9"
          },
          "name": "ColorBaseGrey50",
          "attributes": {
            "category": "color",
            "type": "base",
            "item": "grey",
            "subitem": "50"
          },
          "path": [
            "color",
            "base",
            "grey",
            "50"
          ]
        },
        "100": {
          "value": "#f3f4f6",
          "filePath": "src/properties/color/base.json",
          "isSource": true,
          "original": {
            "value": "#F3F4F6"
          },
          "name": "ColorBaseGrey100",
          "attributes": {
            "category": "color",
            "type": "base",
            "item": "grey",
            "subitem": "100"
          },
          "path": [
            "color",
            "base",
            "grey",
            "100"
          ]
        },
        "200": {
          "value": "#ebecef",
          "filePath": "src/properties/color/base.json",
          "isSource": true,
          "original": {
            "value": "#EBECEF"
          },
          "name": "ColorBaseGrey200",
          "attributes": {
            "category": "color",
            "type": "base",
            "item": "grey",
            "subitem": "200"
          },
          "path": [
            "color",
            "base",
            "grey",
            "200"
          ]
        },
        "300": {
          "value": "#e2e4e9",
          "filePath": "src/properties/color/base.json",
          "isSource": true,
          "original": {
            "value": "#E2E4E9"
          },
          "name": "ColorBaseGrey300",
          "attributes": {
            "category": "color",
            "type": "base",
            "item": "grey",
            "subitem": "300"
          },
          "path": [
            "color",
            "base",
            "grey",
            "300"
          ]
        },
        "400": {
          "value": "#9ca1b0",
          "filePath": "src/properties/color/base.json",
          "isSource": true,
          "original": {
            "value": "#9CA1B0"
          },
          "name": "ColorBaseGrey400",
          "attributes": {
            "category": "color",
            "type": "base",
            "item": "grey",
            "subitem": "400"
          },
          "path": [
            "color",
            "base",
            "grey",
            "400"
          ]
        },
        "500": {
          "value": "#666b7a",
          "filePath": "src/properties/color/base.json",
          "isSource": true,
          "original": {
            "value": "#666B7A"
          },
          "name": "ColorBaseGrey500",
          "attributes": {
            "category": "color",
            "type": "base",
            "item": "grey",
            "subitem": "500"
          },
          "path": [
            "color",
            "base",
            "grey",
            "500"
          ]
        },
        "900": {
          "value": "#1e2129",
          "filePath": "src/properties/color/base.json",
          "isSource": true,
          "original": {
            "value": "#1E2129"
          },
          "name": "ColorBaseGrey900",
          "attributes": {
            "category": "color",
            "type": "base",
            "item": "grey",
            "subitem": "900"
          },
          "path": [
            "color",
            "base",
            "grey",
            "900"
          ]
        },
        "1000": {
          "value": "#16171d",
          "filePath": "src/properties/color/base.json",
          "isSource": true,
          "original": {
            "value": "#16171D"
          },
          "name": "ColorBaseGrey1000",
          "attributes": {
            "category": "color",
            "type": "base",
            "item": "grey",
            "subitem": "1000"
          },
          "path": [
            "color",
            "base",
            "grey",
            "1000"
          ]
        }
      },
      "blue": {
        "25": {
          "value": "#f5f9ff",
          "filePath": "src/properties/color/base.json",
          "isSource": true,
          "original": {
            "value": "#F5F9FF"
          },
          "name": "ColorBaseBlue25",
          "attributes": {
            "category": "color",
            "type": "base",
            "item": "blue",
            "subitem": "25"
          },
          "path": [
            "color",
            "base",
            "blue",
            "25"
          ]
        },
        "50": {
          "value": "#e6f0fe",
          "filePath": "src/properties/color/base.json",
          "isSource": true,
          "original": {
            "value": "#E6F0FE"
          },
          "name": "ColorBaseBlue50",
          "attributes": {
            "category": "color",
            "type": "base",
            "item": "blue",
            "subitem": "50"
          },
          "path": [
            "color",
            "base",
            "blue",
            "50"
          ]
        },
        "100": {
          "value": "#d7e8fe",
          "filePath": "src/properties/color/base.json",
          "isSource": true,
          "original": {
            "value": "#D7E8FE"
          },
          "name": "ColorBaseBlue100",
          "attributes": {
            "category": "color",
            "type": "base",
            "item": "blue",
            "subitem": "100"
          },
          "path": [
            "color",
            "base",
            "blue",
            "100"
          ]
        },
        "200": {
          "value": "#bedbfe",
          "filePath": "src/properties/color/base.json",
          "isSource": true,
          "original": {
            "value": "#BEDBFE"
          },
          "name": "ColorBaseBlue200",
          "attributes": {
            "category": "color",
            "type": "base",
            "item": "blue",
            "subitem": "200"
          },
          "path": [
            "color",
            "base",
            "blue",
            "200"
          ]
        },
        "300": {
          "value": "#91c3fd",
          "filePath": "src/properties/color/base.json",
          "isSource": true,
          "original": {
            "value": "#91C3FD"
          },
          "name": "ColorBaseBlue300",
          "attributes": {
            "category": "color",
            "type": "base",
            "item": "blue",
            "subitem": "300"
          },
          "path": [
            "color",
            "base",
            "blue",
            "300"
          ]
        },
        "400": {
          "value": "#61a6fa",
          "filePath": "src/properties/color/base.json",
          "isSource": true,
          "original": {
            "value": "#61A6FA"
          },
          "name": "ColorBaseBlue400",
          "attributes": {
            "category": "color",
            "type": "base",
            "item": "blue",
            "subitem": "400"
          },
          "path": [
            "color",
            "base",
            "blue",
            "400"
          ]
        },
        "500": {
          "value": "#3c83f6",
          "filePath": "src/properties/color/base.json",
          "isSource": true,
          "original": {
            "value": "#3C83F6"
          },
          "name": "ColorBaseBlue500",
          "attributes": {
            "category": "color",
            "type": "base",
            "item": "blue",
            "subitem": "500"
          },
          "path": [
            "color",
            "base",
            "blue",
            "500"
          ]
        },
        "600": {
          "value": "#2463eb",
          "filePath": "src/properties/color/base.json",
          "isSource": true,
          "original": {
            "value": "#2463EB"
          },
          "name": "ColorBaseBlue600",
          "attributes": {
            "category": "color",
            "type": "base",
            "item": "blue",
            "subitem": "600"
          },
          "path": [
            "color",
            "base",
            "blue",
            "600"
          ]
        },
        "700": {
          "value": "#1d4fd7",
          "filePath": "src/properties/color/base.json",
          "isSource": true,
          "original": {
            "value": "#1D4FD7"
          },
          "name": "ColorBaseBlue700",
          "attributes": {
            "category": "color",
            "type": "base",
            "item": "blue",
            "subitem": "700"
          },
          "path": [
            "color",
            "base",
            "blue",
            "700"
          ]
        },
        "800": {
          "value": "#1e3fae",
          "filePath": "src/properties/color/base.json",
          "isSource": true,
          "original": {
            "value": "#1E3FAE"
          },
          "name": "ColorBaseBlue800",
          "attributes": {
            "category": "color",
            "type": "base",
            "item": "blue",
            "subitem": "800"
          },
          "path": [
            "color",
            "base",
            "blue",
            "800"
          ]
        },
        "1000": {
          "value": "#0d1730",
          "filePath": "src/properties/color/base.json",
          "isSource": true,
          "original": {
            "value": "#0D1730"
          },
          "name": "ColorBaseBlue1000",
          "attributes": {
            "category": "color",
            "type": "base",
            "item": "blue",
            "subitem": "1000"
          },
          "path": [
            "color",
            "base",
            "blue",
            "1000"
          ]
        },
        "600subtle": {
          "value": "rgba(36, 99, 235, 0.08)",
          "filePath": "src/properties/color/base.json",
          "isSource": true,
          "original": {
            "value": "#2463EB14"
          },
          "name": "ColorBaseBlue600subtle",
          "attributes": {
            "category": "color",
            "type": "base",
            "item": "blue",
            "subitem": "600subtle"
          },
          "path": [
            "color",
            "base",
            "blue",
            "600subtle"
          ]
        }
      },
      "purple": {
        "25": {
          "value": "#f7f5ff",
          "filePath": "src/properties/color/base.json",
          "isSource": true,
          "original": {
            "value": "#F7F5FF"
          },
          "name": "ColorBasePurple25",
          "attributes": {
            "category": "color",
            "type": "base",
            "item": "purple",
            "subitem": "25"
          },
          "path": [
            "color",
            "base",
            "purple",
            "25"
          ]
        },
        "50": {
          "value": "#f0ebff",
          "filePath": "src/properties/color/base.json",
          "isSource": true,
          "original": {
            "value": "#F0EBFF"
          },
          "name": "ColorBasePurple50",
          "attributes": {
            "category": "color",
            "type": "base",
            "item": "purple",
            "subitem": "50"
          },
          "path": [
            "color",
            "base",
            "purple",
            "50"
          ]
        },
        "100": {
          "value": "#ece6ff",
          "filePath": "src/properties/color/base.json",
          "isSource": true,
          "original": {
            "value": "#ECE6FF"
          },
          "name": "ColorBasePurple100",
          "attributes": {
            "category": "color",
            "type": "base",
            "item": "purple",
            "subitem": "100"
          },
          "path": [
            "color",
            "base",
            "purple",
            "100"
          ]
        },
        "200": {
          "value": "#e0d6ff",
          "filePath": "src/properties/color/base.json",
          "isSource": true,
          "original": {
            "value": "#E0D6FF"
          },
          "name": "ColorBasePurple200",
          "attributes": {
            "category": "color",
            "type": "base",
            "item": "purple",
            "subitem": "200"
          },
          "path": [
            "color",
            "base",
            "purple",
            "200"
          ]
        },
        "500": {
          "value": "#8055f7",
          "filePath": "src/properties/color/base.json",
          "isSource": true,
          "original": {
            "value": "#8055F7"
          },
          "name": "ColorBasePurple500",
          "attributes": {
            "category": "color",
            "type": "base",
            "item": "purple",
            "subitem": "500"
          },
          "path": [
            "color",
            "base",
            "purple",
            "500"
          ]
        },
        "600": {
          "value": "#6534ea",
          "filePath": "src/properties/color/base.json",
          "isSource": true,
          "original": {
            "value": "#6534EA"
          },
          "name": "ColorBasePurple600",
          "attributes": {
            "category": "color",
            "type": "base",
            "item": "purple",
            "subitem": "600"
          },
          "path": [
            "color",
            "base",
            "purple",
            "600"
          ]
        },
        "600subtle": {
          "value": "rgba(101, 52, 234, 0.03)",
          "filePath": "src/properties/color/base.json",
          "isSource": true,
          "original": {
            "value": "#6534EA08"
          },
          "name": "ColorBasePurple600subtle",
          "attributes": {
            "category": "color",
            "type": "base",
            "item": "purple",
            "subitem": "600subtle"
          },
          "path": [
            "color",
            "base",
            "purple",
            "600subtle"
          ]
        }
      },
      "violet": {
        "25": {
          "value": "#fdf5ff",
          "filePath": "src/properties/color/base.json",
          "isSource": true,
          "original": {
            "value": "#FDF5FF"
          },
          "name": "ColorBaseViolet25",
          "attributes": {
            "category": "color",
            "type": "base",
            "item": "violet",
            "subitem": "25"
          },
          "path": [
            "color",
            "base",
            "violet",
            "25"
          ]
        },
        "50": {
          "value": "#faebff",
          "filePath": "src/properties/color/base.json",
          "isSource": true,
          "original": {
            "value": "#FAEBFF"
          },
          "name": "ColorBaseViolet50",
          "attributes": {
            "category": "color",
            "type": "base",
            "item": "violet",
            "subitem": "50"
          },
          "path": [
            "color",
            "base",
            "violet",
            "50"
          ]
        },
        "100": {
          "value": "#f9e6ff",
          "filePath": "src/properties/color/base.json",
          "isSource": true,
          "original": {
            "value": "#F9E6FF"
          },
          "name": "ColorBaseViolet100",
          "attributes": {
            "category": "color",
            "type": "base",
            "item": "violet",
            "subitem": "100"
          },
          "path": [
            "color",
            "base",
            "violet",
            "100"
          ]
        },
        "200": {
          "value": "#f5d2fe",
          "filePath": "src/properties/color/base.json",
          "isSource": true,
          "original": {
            "value": "#F5D2FE"
          },
          "name": "ColorBaseViolet200",
          "attributes": {
            "category": "color",
            "type": "base",
            "item": "violet",
            "subitem": "200"
          },
          "path": [
            "color",
            "base",
            "violet",
            "200"
          ]
        },
        "500": {
          "value": "#d535ed",
          "filePath": "src/properties/color/base.json",
          "isSource": true,
          "original": {
            "value": "#D535ED"
          },
          "name": "ColorBaseViolet500",
          "attributes": {
            "category": "color",
            "type": "base",
            "item": "violet",
            "subitem": "500"
          },
          "path": [
            "color",
            "base",
            "violet",
            "500"
          ]
        },
        "600": {
          "value": "#bf27d3",
          "filePath": "src/properties/color/base.json",
          "isSource": true,
          "original": {
            "value": "#BF27D3"
          },
          "name": "ColorBaseViolet600",
          "attributes": {
            "category": "color",
            "type": "base",
            "item": "violet",
            "subitem": "600"
          },
          "path": [
            "color",
            "base",
            "violet",
            "600"
          ]
        },
        "600subtle": {
          "value": "rgba(191, 39, 211, 0.08)",
          "filePath": "src/properties/color/base.json",
          "isSource": true,
          "original": {
            "value": "#BF27D314"
          },
          "name": "ColorBaseViolet600subtle",
          "attributes": {
            "category": "color",
            "type": "base",
            "item": "violet",
            "subitem": "600subtle"
          },
          "path": [
            "color",
            "base",
            "violet",
            "600subtle"
          ]
        }
      },
      "fuchsia": {
        "500": {
          "value": "#ec4699",
          "filePath": "src/properties/color/base.json",
          "isSource": true,
          "original": {
            "value": "#EC4699"
          },
          "name": "ColorBaseFuchsia500",
          "attributes": {
            "category": "color",
            "type": "base",
            "item": "fuchsia",
            "subitem": "500"
          },
          "path": [
            "color",
            "base",
            "fuchsia",
            "500"
          ]
        }
      },
      "pink": {
        "500": {
          "value": "#e8236a",
          "filePath": "src/properties/color/base.json",
          "isSource": true,
          "original": {
            "value": "#E8236A"
          },
          "name": "ColorBasePink500",
          "attributes": {
            "category": "color",
            "type": "base",
            "item": "pink",
            "subitem": "500"
          },
          "path": [
            "color",
            "base",
            "pink",
            "500"
          ]
        },
        "faded": {
          "500": {
            "value": "#ef6597",
            "filePath": "src/properties/color/base.json",
            "isSource": true,
            "original": {
              "value": "#EF6597"
            },
            "name": "ColorBasePinkFaded500",
            "attributes": {
              "category": "color",
              "type": "base",
              "item": "pink",
              "subitem": "faded",
              "state": "500"
            },
            "path": [
              "color",
              "base",
              "pink",
              "faded",
              "500"
            ]
          }
        }
      },
      "red": {
        "25": {
          "value": "#fef5f5",
          "filePath": "src/properties/color/base.json",
          "isSource": true,
          "original": {
            "value": "#FEF5F5"
          },
          "name": "ColorBaseRed25",
          "attributes": {
            "category": "color",
            "type": "base",
            "item": "red",
            "subitem": "25"
          },
          "path": [
            "color",
            "base",
            "red",
            "25"
          ]
        },
        "50": {
          "value": "#fee7e7",
          "filePath": "src/properties/color/base.json",
          "isSource": true,
          "original": {
            "value": "#FEE7E7"
          },
          "name": "ColorBaseRed50",
          "attributes": {
            "category": "color",
            "type": "base",
            "item": "red",
            "subitem": "50"
          },
          "path": [
            "color",
            "base",
            "red",
            "50"
          ]
        },
        "100": {
          "value": "#fee1e1",
          "filePath": "src/properties/color/base.json",
          "isSource": true,
          "original": {
            "value": "#FEE1E1"
          },
          "name": "ColorBaseRed100",
          "attributes": {
            "category": "color",
            "type": "base",
            "item": "red",
            "subitem": "100"
          },
          "path": [
            "color",
            "base",
            "red",
            "100"
          ]
        },
        "200": {
          "value": "#fec8c8",
          "filePath": "src/properties/color/base.json",
          "isSource": true,
          "original": {
            "value": "#FEC8C8"
          },
          "name": "ColorBaseRed200",
          "attributes": {
            "category": "color",
            "type": "base",
            "item": "red",
            "subitem": "200"
          },
          "path": [
            "color",
            "base",
            "red",
            "200"
          ]
        },
        "500": {
          "value": "#ef4343",
          "filePath": "src/properties/color/base.json",
          "isSource": true,
          "original": {
            "value": "#EF4343"
          },
          "name": "ColorBaseRed500",
          "attributes": {
            "category": "color",
            "type": "base",
            "item": "red",
            "subitem": "500"
          },
          "path": [
            "color",
            "base",
            "red",
            "500"
          ]
        },
        "600": {
          "value": "#dc2828",
          "filePath": "src/properties/color/base.json",
          "isSource": true,
          "original": {
            "value": "#DC2828"
          },
          "name": "ColorBaseRed600",
          "attributes": {
            "category": "color",
            "type": "base",
            "item": "red",
            "subitem": "600"
          },
          "path": [
            "color",
            "base",
            "red",
            "600"
          ]
        },
        "600subtle": {
          "value": "rgba(220, 40, 40, 0.08)",
          "filePath": "src/properties/color/base.json",
          "isSource": true,
          "original": {
            "value": "#DC282814"
          },
          "name": "ColorBaseRed600subtle",
          "attributes": {
            "category": "color",
            "type": "base",
            "item": "red",
            "subitem": "600subtle"
          },
          "path": [
            "color",
            "base",
            "red",
            "600subtle"
          ]
        }
      },
      "orange": {
        "50": {
          "value": "#fff1e0",
          "filePath": "src/properties/color/base.json",
          "isSource": true,
          "original": {
            "value": "#FFF1E0"
          },
          "name": "ColorBaseOrange50",
          "attributes": {
            "category": "color",
            "type": "base",
            "item": "orange",
            "subitem": "50"
          },
          "path": [
            "color",
            "base",
            "orange",
            "50"
          ]
        },
        "100": {
          "value": "#ffebd1",
          "filePath": "src/properties/color/base.json",
          "isSource": true,
          "original": {
            "value": "#FFEBD1"
          },
          "name": "ColorBaseOrange100",
          "attributes": {
            "category": "color",
            "type": "base",
            "item": "orange",
            "subitem": "100"
          },
          "path": [
            "color",
            "base",
            "orange",
            "100"
          ]
        },
        "500": {
          "value": "#f97415",
          "filePath": "src/properties/color/base.json",
          "isSource": true,
          "original": {
            "value": "#F97415"
          },
          "name": "ColorBaseOrange500",
          "attributes": {
            "category": "color",
            "type": "base",
            "item": "orange",
            "subitem": "500"
          },
          "path": [
            "color",
            "base",
            "orange",
            "500"
          ]
        },
        "600": {
          "value": "#e9590c",
          "filePath": "src/properties/color/base.json",
          "isSource": true,
          "original": {
            "value": "#E9590C"
          },
          "name": "ColorBaseOrange600",
          "attributes": {
            "category": "color",
            "type": "base",
            "item": "orange",
            "subitem": "600"
          },
          "path": [
            "color",
            "base",
            "orange",
            "600"
          ]
        }
      },
      "yellow": {
        "25": {
          "value": "#fffcf0",
          "filePath": "src/properties/color/base.json",
          "isSource": true,
          "original": {
            "value": "#FFFCF0"
          },
          "name": "ColorBaseYellow25",
          "attributes": {
            "category": "color",
            "type": "base",
            "item": "yellow",
            "subitem": "25"
          },
          "path": [
            "color",
            "base",
            "yellow",
            "25"
          ]
        },
        "50": {
          "value": "#fff8dc",
          "filePath": "src/properties/color/base.json",
          "isSource": true,
          "original": {
            "value": "#FFF8DC"
          },
          "name": "ColorBaseYellow50",
          "attributes": {
            "category": "color",
            "type": "base",
            "item": "yellow",
            "subitem": "50"
          },
          "path": [
            "color",
            "base",
            "yellow",
            "50"
          ]
        },
        "100": {
          "value": "#fef1be",
          "filePath": "src/properties/color/base.json",
          "isSource": true,
          "original": {
            "value": "#FEF1BE"
          },
          "name": "ColorBaseYellow100",
          "attributes": {
            "category": "color",
            "type": "base",
            "item": "yellow",
            "subitem": "100"
          },
          "path": [
            "color",
            "base",
            "yellow",
            "100"
          ]
        },
        "200": {
          "value": "#fde68b",
          "filePath": "src/properties/color/base.json",
          "isSource": true,
          "original": {
            "value": "#FDE68B"
          },
          "name": "ColorBaseYellow200",
          "attributes": {
            "category": "color",
            "type": "base",
            "item": "yellow",
            "subitem": "200"
          },
          "path": [
            "color",
            "base",
            "yellow",
            "200"
          ]
        },
        "300": {
          "value": "#fcf6d3",
          "filePath": "src/properties/color/base.json",
          "isSource": true,
          "original": {
            "value": "#FCF6D3"
          },
          "name": "ColorBaseYellow300",
          "attributes": {
            "category": "color",
            "type": "base",
            "item": "yellow",
            "subitem": "300"
          },
          "path": [
            "color",
            "base",
            "yellow",
            "300"
          ]
        },
        "500": {
          "value": "#f59f0a",
          "filePath": "src/properties/color/base.json",
          "isSource": true,
          "original": {
            "value": "#F59F0A"
          },
          "name": "ColorBaseYellow500",
          "attributes": {
            "category": "color",
            "type": "base",
            "item": "yellow",
            "subitem": "500"
          },
          "path": [
            "color",
            "base",
            "yellow",
            "500"
          ]
        },
        "600": {
          "value": "#ca6c02",
          "filePath": "src/properties/color/base.json",
          "isSource": true,
          "original": {
            "value": "#CA6C02"
          },
          "name": "ColorBaseYellow600",
          "attributes": {
            "category": "color",
            "type": "base",
            "item": "yellow",
            "subitem": "600"
          },
          "path": [
            "color",
            "base",
            "yellow",
            "600"
          ]
        },
        "700": {
          "value": "#eab925",
          "filePath": "src/properties/color/base.json",
          "isSource": true,
          "original": {
            "value": "#EAB925"
          },
          "name": "ColorBaseYellow700",
          "attributes": {
            "category": "color",
            "type": "base",
            "item": "yellow",
            "subitem": "700"
          },
          "path": [
            "color",
            "base",
            "yellow",
            "700"
          ]
        },
        "600subtle": {
          "value": "rgba(202, 108, 2, 0.08)",
          "filePath": "src/properties/color/base.json",
          "isSource": true,
          "original": {
            "value": "#CA6C0214"
          },
          "name": "ColorBaseYellow600subtle",
          "attributes": {
            "category": "color",
            "type": "base",
            "item": "yellow",
            "subitem": "600subtle"
          },
          "path": [
            "color",
            "base",
            "yellow",
            "600subtle"
          ]
        }
      },
      "green": {
        "25": {
          "value": "#f1fdf7",
          "filePath": "src/properties/color/base.json",
          "isSource": true,
          "original": {
            "value": "#F1FDF7"
          },
          "name": "ColorBaseGreen25",
          "attributes": {
            "category": "color",
            "type": "base",
            "item": "green",
            "subitem": "25"
          },
          "path": [
            "color",
            "base",
            "green",
            "25"
          ]
        },
        "50": {
          "value": "#dffbed",
          "filePath": "src/properties/color/base.json",
          "isSource": true,
          "original": {
            "value": "#DFFBED"
          },
          "name": "ColorBaseGreen50",
          "attributes": {
            "category": "color",
            "type": "base",
            "item": "green",
            "subitem": "50"
          },
          "path": [
            "color",
            "base",
            "green",
            "50"
          ]
        },
        "100": {
          "value": "#c8f9e0",
          "filePath": "src/properties/color/base.json",
          "isSource": true,
          "original": {
            "value": "#C8F9E0"
          },
          "name": "ColorBaseGreen100",
          "attributes": {
            "category": "color",
            "type": "base",
            "item": "green",
            "subitem": "100"
          },
          "path": [
            "color",
            "base",
            "green",
            "100"
          ]
        },
        "200": {
          "value": "#a5f3cf",
          "filePath": "src/properties/color/base.json",
          "isSource": true,
          "original": {
            "value": "#A5F3CF"
          },
          "name": "ColorBaseGreen200",
          "attributes": {
            "category": "color",
            "type": "base",
            "item": "green",
            "subitem": "200"
          },
          "path": [
            "color",
            "base",
            "green",
            "200"
          ]
        },
        "500": {
          "value": "#10b77f",
          "filePath": "src/properties/color/base.json",
          "isSource": true,
          "original": {
            "value": "#10B77F"
          },
          "name": "ColorBaseGreen500",
          "attributes": {
            "category": "color",
            "type": "base",
            "item": "green",
            "subitem": "500"
          },
          "path": [
            "color",
            "base",
            "green",
            "500"
          ]
        },
        "600": {
          "value": "#08875f",
          "filePath": "src/properties/color/base.json",
          "isSource": true,
          "original": {
            "value": "#08875F"
          },
          "name": "ColorBaseGreen600",
          "attributes": {
            "category": "color",
            "type": "base",
            "item": "green",
            "subitem": "600"
          },
          "path": [
            "color",
            "base",
            "green",
            "600"
          ]
        },
        "700": {
          "value": "#047756",
          "filePath": "src/properties/color/base.json",
          "isSource": true,
          "original": {
            "value": "#047756"
          },
          "name": "ColorBaseGreen700",
          "attributes": {
            "category": "color",
            "type": "base",
            "item": "green",
            "subitem": "700"
          },
          "path": [
            "color",
            "base",
            "green",
            "700"
          ]
        },
        "600subtle": {
          "value": "rgba(8, 135, 95, 0.08)",
          "filePath": "src/properties/color/base.json",
          "isSource": true,
          "original": {
            "value": "#08875F14"
          },
          "name": "ColorBaseGreen600subtle",
          "attributes": {
            "category": "color",
            "type": "base",
            "item": "green",
            "subitem": "600subtle"
          },
          "path": [
            "color",
            "base",
            "green",
            "600subtle"
          ]
        }
      }
    },
    "gradient": {
      "500": {
        "value": "linear-gradient(268.84deg, #e8236a 0%, #fd3001 31.25%, #f97415 100%)",
        "filePath": "src/properties/color/gradient.json",
        "isSource": true,
        "original": {
          "value": "linear-gradient(268.84deg, {color.base.pink.500.value} 0%, #fd3001 31.25%, {color.base.orange.500.value} 100%)"
        },
        "name": "ColorGradient500",
        "attributes": {
          "category": "color",
          "type": "gradient",
          "item": "500"
        },
        "path": [
          "color",
          "gradient",
          "500"
        ]
      },
      "500reverse": {
        "value": "linear-gradient(88.84deg, #e8236a 0%, #fd3001 31.25%, #f97415 100%)",
        "filePath": "src/properties/color/gradient.json",
        "isSource": true,
        "original": {
          "value": "linear-gradient(88.84deg, {color.base.pink.500.value} 0%, #fd3001 31.25%, {color.base.orange.500.value} 100%)"
        },
        "name": "ColorGradient500reverse",
        "attributes": {
          "category": "color",
          "type": "gradient",
          "item": "500reverse"
        },
        "path": [
          "color",
          "gradient",
          "500reverse"
        ]
      }
    },
    "interfaces": {
      "highlights": {
        "value": "#f3f4f6",
        "filePath": "src/properties/color/interfaces.json",
        "isSource": true,
        "original": {
          "value": "{color.base.grey.100.value}"
        },
        "name": "ColorInterfacesHighlights",
        "attributes": {
          "category": "color",
          "type": "interfaces",
          "item": "highlights"
        },
        "path": [
          "color",
          "interfaces",
          "highlights"
        ]
      }
    },
    "background": {
      "ai": {
        "default": {
          "value": "#f0ebff",
          "filePath": "src/properties/color/semantic.json",
          "isSource": true,
          "original": {
            "value": "{color.base.purple.50.value}"
          },
          "name": "ColorBackgroundAiDefault",
          "attributes": {
            "category": "color",
            "type": "background",
            "item": "ai",
            "subitem": "default"
          },
          "path": [
            "color",
            "background",
            "ai",
            "default"
          ]
        },
        "hover": {
          "value": "#ece6ff",
          "filePath": "src/properties/color/semantic.json",
          "isSource": true,
          "original": {
            "value": "{color.base.purple.100.value}"
          },
          "name": "ColorBackgroundAiHover",
          "attributes": {
            "category": "color",
            "type": "background",
            "item": "ai",
            "subitem": "hover"
          },
          "path": [
            "color",
            "background",
            "ai",
            "hover"
          ]
        },
        "pressed": {
          "value": "#e0d6ff",
          "filePath": "src/properties/color/semantic.json",
          "isSource": true,
          "original": {
            "value": "{color.base.purple.200.value}"
          },
          "name": "ColorBackgroundAiPressed",
          "attributes": {
            "category": "color",
            "type": "background",
            "item": "ai",
            "subitem": "pressed"
          },
          "path": [
            "color",
            "background",
            "ai",
            "pressed"
          ]
        },
        "subtle": {
          "value": "#f7f5ff",
          "filePath": "src/properties/color/semantic.json",
          "isSource": true,
          "original": {
            "value": "{color.base.purple.25.value}"
          },
          "name": "ColorBackgroundAiSubtle",
          "attributes": {
            "category": "color",
            "type": "background",
            "item": "ai",
            "subitem": "subtle"
          },
          "path": [
            "color",
            "background",
            "ai",
            "subtle"
          ]
        }
      },
      "chart": {
        "sequential": {
          "base": {
            "value": "#1e3fae",
            "filePath": "src/properties/color/semantic.json",
            "isSource": true,
            "original": {
              "value": "{color.base.blue.800.value}"
            },
            "name": "ColorBackgroundChartSequentialBase",
            "attributes": {
              "category": "color",
              "type": "background",
              "item": "chart",
              "subitem": "sequential",
              "state": "base"
            },
            "path": [
              "color",
              "background",
              "chart",
              "sequential",
              "base"
            ]
          },
          "base02": {
            "value": "#1d4fd7",
            "filePath": "src/properties/color/semantic.json",
            "isSource": true,
            "original": {
              "value": "{color.base.blue.700.value}"
            },
            "name": "ColorBackgroundChartSequentialBase02",
            "attributes": {
              "category": "color",
              "type": "background",
              "item": "chart",
              "subitem": "sequential",
              "state": "base02"
            },
            "path": [
              "color",
              "background",
              "chart",
              "sequential",
              "base02"
            ]
          },
          "base03": {
            "value": "#2463eb",
            "filePath": "src/properties/color/semantic.json",
            "isSource": true,
            "original": {
              "value": "{color.base.blue.600.value}"
            },
            "name": "ColorBackgroundChartSequentialBase03",
            "attributes": {
              "category": "color",
              "type": "background",
              "item": "chart",
              "subitem": "sequential",
              "state": "base03"
            },
            "path": [
              "color",
              "background",
              "chart",
              "sequential",
              "base03"
            ]
          },
          "base04": {
            "value": "#3c83f6",
            "filePath": "src/properties/color/semantic.json",
            "isSource": true,
            "original": {
              "value": "{color.base.blue.500.value}"
            },
            "name": "ColorBackgroundChartSequentialBase04",
            "attributes": {
              "category": "color",
              "type": "background",
              "item": "chart",
              "subitem": "sequential",
              "state": "base04"
            },
            "path": [
              "color",
              "background",
              "chart",
              "sequential",
              "base04"
            ]
          },
          "base05": {
            "value": "#61a6fa",
            "filePath": "src/properties/color/semantic.json",
            "isSource": true,
            "original": {
              "value": "{color.base.blue.400.value}"
            },
            "name": "ColorBackgroundChartSequentialBase05",
            "attributes": {
              "category": "color",
              "type": "background",
              "item": "chart",
              "subitem": "sequential",
              "state": "base05"
            },
            "path": [
              "color",
              "background",
              "chart",
              "sequential",
              "base05"
            ]
          },
          "base06": {
            "value": "#91c3fd",
            "filePath": "src/properties/color/semantic.json",
            "isSource": true,
            "original": {
              "value": "{color.base.blue.300.value}"
            },
            "name": "ColorBackgroundChartSequentialBase06",
            "attributes": {
              "category": "color",
              "type": "background",
              "item": "chart",
              "subitem": "sequential",
              "state": "base06"
            },
            "path": [
              "color",
              "background",
              "chart",
              "sequential",
              "base06"
            ]
          },
          "base07": {
            "value": "#bedbfe",
            "filePath": "src/properties/color/semantic.json",
            "isSource": true,
            "original": {
              "value": "{color.base.blue.200.value}"
            },
            "name": "ColorBackgroundChartSequentialBase07",
            "attributes": {
              "category": "color",
              "type": "background",
              "item": "chart",
              "subitem": "sequential",
              "state": "base07"
            },
            "path": [
              "color",
              "background",
              "chart",
              "sequential",
              "base07"
            ]
          },
          "base08": {
            "value": "#d7e8fe",
            "filePath": "src/properties/color/semantic.json",
            "isSource": true,
            "original": {
              "value": "{color.base.blue.100.value}"
            },
            "name": "ColorBackgroundChartSequentialBase08",
            "attributes": {
              "category": "color",
              "type": "background",
              "item": "chart",
              "subitem": "sequential",
              "state": "base08"
            },
            "path": [
              "color",
              "background",
              "chart",
              "sequential",
              "base08"
            ]
          }
        }
      },
      "disabled": {
        "value": "#ebecef",
        "filePath": "src/properties/color/semantic.json",
        "isSource": true,
        "original": {
          "value": "{color.base.grey.200.value}"
        },
        "name": "ColorBackgroundDisabled",
        "attributes": {
          "category": "color",
          "type": "background",
          "item": "disabled"
        },
        "path": [
          "color",
          "background",
          "disabled"
        ]
      },
      "brand": {
        "_": {
          "value": "linear-gradient(268.84deg, #e8236a 0%, #fd3001 31.25%, #f97415 100%)",
          "filePath": "src/properties/color/semantic.json",
          "isSource": true,
          "original": {
            "value": "{color.gradient.500.value}"
          },
          "name": "ColorBackgroundBrand",
          "attributes": {
            "category": "color",
            "type": "background",
            "item": "brand",
            "subitem": "_"
          },
          "path": [
            "color",
            "background",
            "brand",
            "_"
          ]
        },
        "hover": {
          "value": "linear-gradient(88.84deg, #e8236a 0%, #fd3001 31.25%, #f97415 100%)",
          "filePath": "src/properties/color/semantic.json",
          "isSource": true,
          "original": {
            "value": "{color.gradient.500reverse.value}"
          },
          "name": "ColorBackgroundBrandHover",
          "attributes": {
            "category": "color",
            "type": "background",
            "item": "brand",
            "subitem": "hover"
          },
          "path": [
            "color",
            "background",
            "brand",
            "hover"
          ]
        },
        "gradient": {
          "value": "linear-gradient(268.84deg, #e8236a 0%, #fd3001 31.25%, #f97415 100%)",
          "filePath": "src/properties/color/semantic.json",
          "isSource": true,
          "original": {
            "value": "{color.gradient.500.value}"
          },
          "name": "ColorBackgroundBrandGradient",
          "attributes": {
            "category": "color",
            "type": "background",
            "item": "brand",
            "subitem": "gradient"
          },
          "path": [
            "color",
            "background",
            "brand",
            "gradient"
          ]
        },
        "gradient_hover": {
          "value": "linear-gradient(268.84deg, #e8236a 0%, #fd3001 31.25%, #f97415 100%)",
          "filePath": "src/properties/color/semantic.json",
          "isSource": true,
          "original": {
            "value": "{color.gradient.500.value}"
          },
          "name": "ColorBackgroundBrandGradientHover",
          "attributes": {
            "category": "color",
            "type": "background",
            "item": "brand",
            "subitem": "gradient_hover"
          },
          "path": [
            "color",
            "background",
            "brand",
            "gradient_hover"
          ]
        },
        "gradient_reverse": {
          "value": "linear-gradient(88.84deg, #e8236a 0%, #fd3001 31.25%, #f97415 100%)",
          "filePath": "src/properties/color/semantic.json",
          "isSource": true,
          "original": {
            "value": "{color.gradient.500reverse.value}"
          },
          "name": "ColorBackgroundBrandGradientReverse",
          "attributes": {
            "category": "color",
            "type": "background",
            "item": "brand",
            "subitem": "gradient_reverse"
          },
          "path": [
            "color",
            "background",
            "brand",
            "gradient_reverse"
          ]
        }
      },
      "secondary": {
        "value": "#e2e4e9",
        "filePath": "src/properties/color/semantic.json",
        "isSource": true,
        "original": {
          "value": "{color.base.grey.300.value}"
        },
        "name": "ColorBackgroundSecondary",
        "attributes": {
          "category": "color",
          "type": "background",
          "item": "secondary"
        },
        "path": [
          "color",
          "background",
          "secondary"
        ]
      },
      "inverse": {
        "value": "#ffffff",
        "filePath": "src/properties/color/semantic.json",
        "isSource": true,
        "original": {
          "value": "{color.base.white.value}"
        },
        "name": "ColorBackgroundInverse",
        "attributes": {
          "category": "color",
          "type": "background",
          "item": "inverse"
        },
        "path": [
          "color",
          "background",
          "inverse"
        ]
      },
      "success": {
        "value": "#dffbed",
        "filePath": "src/properties/color/semantic.json",
        "isSource": true,
        "original": {
          "value": "{color.base.green.50.value}"
        },
        "name": "ColorBackgroundSuccess",
        "attributes": {
          "category": "color",
          "type": "background",
          "item": "success"
        },
        "path": [
          "color",
          "background",
          "success"
        ]
      },
      "info": {
        "value": "#e6f0fe",
        "filePath": "src/properties/color/semantic.json",
        "isSource": true,
        "original": {
          "value": "{color.base.blue.50.value}"
        },
        "name": "ColorBackgroundInfo",
        "attributes": {
          "category": "color",
          "type": "background",
          "item": "info"
        },
        "path": [
          "color",
          "background",
          "info"
        ]
      },
      "warning": {
        "value": "#fff8dc",
        "filePath": "src/properties/color/semantic.json",
        "isSource": true,
        "original": {
          "value": "{color.base.yellow.50.value}"
        },
        "name": "ColorBackgroundWarning",
        "attributes": {
          "category": "color",
          "type": "background",
          "item": "warning"
        },
        "path": [
          "color",
          "background",
          "warning"
        ]
      },
      "assistive_hover": {
        "value": "#91c3fd",
        "filePath": "src/properties/color/semantic.json",
        "isSource": true,
        "original": {
          "value": "{color.base.blue.300.value}"
        },
        "name": "ColorBackgroundAssistiveHover",
        "attributes": {
          "category": "color",
          "type": "background",
          "item": "assistive_hover"
        },
        "path": [
          "color",
          "background",
          "assistive_hover"
        ]
      },
      "calendar_pill": {
        "value": "#3c83f6",
        "filePath": "src/properties/color/semantic.json",
        "isSource": true,
        "original": {
          "value": "{color.base.blue.500.value}"
        },
        "name": "ColorBackgroundCalendarPill",
        "attributes": {
          "category": "color",
          "type": "background",
          "item": "calendar_pill"
        },
        "path": [
          "color",
          "background",
          "calendar_pill"
        ]
      },
      "dark": {
        "value": "#1e2129",
        "filePath": "src/properties/color/semantic.json",
        "isSource": true,
        "original": {
          "value": "{color.base.grey.900.value}"
        },
        "name": "ColorBackgroundDark",
        "attributes": {
          "category": "color",
          "type": "background",
          "item": "dark"
        },
        "path": [
          "color",
          "background",
          "dark"
        ]
      },
      "none": {
        "value": "rgba(0, 0, 0, 0)",
        "filePath": "src/properties/color/semantic.json",
        "isSource": true,
        "original": {
          "value": "transparent"
        },
        "name": "ColorBackgroundNone",
        "attributes": {
          "category": "color",
          "type": "background",
          "item": "none"
        },
        "path": [
          "color",
          "background",
          "none"
        ]
      },
      "decorative": {
        "base": {
          "value": "#f0ebff",
          "filePath": "src/properties/color/semantic.json",
          "isSource": true,
          "original": {
            "value": "{color.base.purple.50.value}"
          },
          "name": "ColorBackgroundDecorativeBase",
          "attributes": {
            "category": "color",
            "type": "background",
            "item": "decorative",
            "subitem": "base"
          },
          "path": [
            "color",
            "background",
            "decorative",
            "base"
          ]
        },
        "base02": {
          "value": "#f6f7f9",
          "filePath": "src/properties/color/semantic.json",
          "isSource": true,
          "original": {
            "value": "{color.base.grey.50.value}"
          },
          "name": "ColorBackgroundDecorativeBase02",
          "attributes": {
            "category": "color",
            "type": "background",
            "item": "decorative",
            "subitem": "base02"
          },
          "path": [
            "color",
            "background",
            "decorative",
            "base02"
          ]
        },
        "base03": {
          "value": "#fff1e0",
          "filePath": "src/properties/color/semantic.json",
          "isSource": true,
          "original": {
            "value": "{color.base.orange.50.value}"
          },
          "name": "ColorBackgroundDecorativeBase03",
          "attributes": {
            "category": "color",
            "type": "background",
            "item": "decorative",
            "subitem": "base03"
          },
          "path": [
            "color",
            "background",
            "decorative",
            "base03"
          ]
        },
        "base04": {
          "value": "#faebff",
          "filePath": "src/properties/color/semantic.json",
          "isSource": true,
          "original": {
            "value": "{color.base.violet.50.value}"
          },
          "name": "ColorBackgroundDecorativeBase04",
          "attributes": {
            "category": "color",
            "type": "background",
            "item": "decorative",
            "subitem": "base04"
          },
          "path": [
            "color",
            "background",
            "decorative",
            "base04"
          ]
        },
        "base05": {
          "value": "#dffbed",
          "filePath": "src/properties/color/semantic.json",
          "isSource": true,
          "original": {
            "value": "{color.base.green.50.value}"
          },
          "name": "ColorBackgroundDecorativeBase05",
          "attributes": {
            "category": "color",
            "type": "background",
            "item": "decorative",
            "subitem": "base05"
          },
          "path": [
            "color",
            "background",
            "decorative",
            "base05"
          ]
        },
        "base06": {
          "value": "#e6f0fe",
          "filePath": "src/properties/color/semantic.json",
          "isSource": true,
          "original": {
            "value": "{color.base.blue.50.value}"
          },
          "name": "ColorBackgroundDecorativeBase06",
          "attributes": {
            "category": "color",
            "type": "background",
            "item": "decorative",
            "subitem": "base06"
          },
          "path": [
            "color",
            "background",
            "decorative",
            "base06"
          ]
        }
      },
      "surface": {
        "recessed": {
          "value": "#f9fafb",
          "filePath": "src/properties/color/semantic.json",
          "isSource": true,
          "original": {
            "value": "{color.base.grey.25.value}"
          },
          "name": "ColorBackgroundSurfaceRecessed",
          "attributes": {
            "category": "color",
            "type": "background",
            "item": "surface",
            "subitem": "recessed"
          },
          "path": [
            "color",
            "background",
            "surface",
            "recessed"
          ]
        },
        "page": {
          "default": {
            "value": "#ffffff",
            "filePath": "src/properties/color/semantic.json",
            "isSource": true,
            "original": {
              "value": "{color.base.white.value}"
            },
            "name": "ColorBackgroundSurfacePageDefault",
            "attributes": {
              "category": "color",
              "type": "background",
              "item": "surface",
              "subitem": "page",
              "state": "default"
            },
            "path": [
              "color",
              "background",
              "surface",
              "page",
              "default"
            ]
          },
          "hover": {
            "value": "#f6f7f9",
            "filePath": "src/properties/color/semantic.json",
            "isSource": true,
            "original": {
              "value": "{color.base.grey.50.value}"
            },
            "name": "ColorBackgroundSurfacePageHover",
            "attributes": {
              "category": "color",
              "type": "background",
              "item": "surface",
              "subitem": "page",
              "state": "hover"
            },
            "path": [
              "color",
              "background",
              "surface",
              "page",
              "hover"
            ]
          },
          "pressed": {
            "value": "#f3f4f6",
            "filePath": "src/properties/color/semantic.json",
            "isSource": true,
            "original": {
              "value": "{color.base.grey.100.value}"
            },
            "name": "ColorBackgroundSurfacePagePressed",
            "attributes": {
              "category": "color",
              "type": "background",
              "item": "surface",
              "subitem": "page",
              "state": "pressed"
            },
            "path": [
              "color",
              "background",
              "surface",
              "page",
              "pressed"
            ]
          }
        },
        "raised": {
          "value": "#ffffff",
          "filePath": "src/properties/color/semantic.json",
          "isSource": true,
          "original": {
            "value": "{color.base.white.value}"
          },
          "name": "ColorBackgroundSurfaceRaised",
          "attributes": {
            "category": "color",
            "type": "background",
            "item": "surface",
            "subitem": "raised"
          },
          "path": [
            "color",
            "background",
            "surface",
            "raised"
          ]
        },
        "overlay": {
          "value": "#ffffff",
          "filePath": "src/properties/color/semantic.json",
          "isSource": true,
          "original": {
            "value": "{color.base.white.value}"
          },
          "name": "ColorBackgroundSurfaceOverlay",
          "attributes": {
            "category": "color",
            "type": "background",
            "item": "surface",
            "subitem": "overlay"
          },
          "path": [
            "color",
            "background",
            "surface",
            "overlay"
          ]
        }
      },
      "input": {
        "default": {
          "value": "#ffffff",
          "filePath": "src/properties/color/semantic.json",
          "isSource": true,
          "original": {
            "value": "{color.base.white.value}"
          },
          "name": "ColorBackgroundInputDefault",
          "attributes": {
            "category": "color",
            "type": "background",
            "item": "input",
            "subitem": "default"
          },
          "path": [
            "color",
            "background",
            "input",
            "default"
          ]
        },
        "hover": {
          "value": "#f9fafb",
          "filePath": "src/properties/color/semantic.json",
          "isSource": true,
          "original": {
            "value": "{color.base.grey.25.value}"
          },
          "name": "ColorBackgroundInputHover",
          "attributes": {
            "category": "color",
            "type": "background",
            "item": "input",
            "subitem": "hover"
          },
          "path": [
            "color",
            "background",
            "input",
            "hover"
          ]
        },
        "pressed": {
          "value": "#ffffff",
          "filePath": "src/properties/color/semantic.json",
          "isSource": true,
          "original": {
            "value": "{color.base.white.value}"
          },
          "name": "ColorBackgroundInputPressed",
          "attributes": {
            "category": "color",
            "type": "background",
            "item": "input",
            "subitem": "pressed"
          },
          "path": [
            "color",
            "background",
            "input",
            "pressed"
          ]
        }
      },
      "action": {
        "secondary": {
          "default": {
            "value": "#0d1730",
            "filePath": "src/properties/color/semantic.json",
            "isSource": true,
            "original": {
              "value": "{color.base.blue.1000.value}"
            },
            "name": "ColorBackgroundActionSecondaryDefault",
            "attributes": {
              "category": "color",
              "type": "background",
              "item": "action",
              "subitem": "secondary",
              "state": "default"
            },
            "path": [
              "color",
              "background",
              "action",
              "secondary",
              "default"
            ]
          },
          "hover": {
            "value": "#16171d",
            "filePath": "src/properties/color/semantic.json",
            "isSource": true,
            "original": {
              "value": "{color.base.grey.1000.value}"
            },
            "name": "ColorBackgroundActionSecondaryHover",
            "attributes": {
              "category": "color",
              "type": "background",
              "item": "action",
              "subitem": "secondary",
              "state": "hover"
            },
            "path": [
              "color",
              "background",
              "action",
              "secondary",
              "hover"
            ]
          },
          "pressed": {
            "value": "#16171d",
            "filePath": "src/properties/color/semantic.json",
            "isSource": true,
            "original": {
              "value": "{color.base.grey.1000.value}"
            },
            "name": "ColorBackgroundActionSecondaryPressed",
            "attributes": {
              "category": "color",
              "type": "background",
              "item": "action",
              "subitem": "secondary",
              "state": "pressed"
            },
            "path": [
              "color",
              "background",
              "action",
              "secondary",
              "pressed"
            ]
          }
        },
        "outline": {
          "default": {
            "value": "#ffffff",
            "filePath": "src/properties/color/semantic.json",
            "isSource": true,
            "original": {
              "value": "{color.base.white.value}"
            },
            "name": "ColorBackgroundActionOutlineDefault",
            "attributes": {
              "category": "color",
              "type": "background",
              "item": "action",
              "subitem": "outline",
              "state": "default"
            },
            "path": [
              "color",
              "background",
              "action",
              "outline",
              "default"
            ]
          },
          "hover": {
            "value": "#f6f7f9",
            "filePath": "src/properties/color/semantic.json",
            "isSource": true,
            "original": {
              "value": "{color.base.grey.50.value}"
            },
            "name": "ColorBackgroundActionOutlineHover",
            "attributes": {
              "category": "color",
              "type": "background",
              "item": "action",
              "subitem": "outline",
              "state": "hover"
            },
            "path": [
              "color",
              "background",
              "action",
              "outline",
              "hover"
            ]
          },
          "pressed": {
            "value": "#f3f4f6",
            "filePath": "src/properties/color/semantic.json",
            "isSource": true,
            "original": {
              "value": "{color.base.grey.100.value}"
            },
            "name": "ColorBackgroundActionOutlinePressed",
            "attributes": {
              "category": "color",
              "type": "background",
              "item": "action",
              "subitem": "outline",
              "state": "pressed"
            },
            "path": [
              "color",
              "background",
              "action",
              "outline",
              "pressed"
            ]
          }
        }
      },
      "neutral": {
        "default": {
          "value": "#f6f7f9",
          "filePath": "src/properties/color/semantic.json",
          "isSource": true,
          "original": {
            "value": "{color.base.grey.50.value}"
          },
          "name": "ColorBackgroundNeutralDefault",
          "attributes": {
            "category": "color",
            "type": "background",
            "item": "neutral",
            "subitem": "default"
          },
          "path": [
            "color",
            "background",
            "neutral",
            "default"
          ]
        },
        "subtle": {
          "value": "#f9fafb",
          "filePath": "src/properties/color/semantic.json",
          "isSource": true,
          "original": {
            "value": "{color.base.grey.25.value}"
          },
          "name": "ColorBackgroundNeutralSubtle",
          "attributes": {
            "category": "color",
            "type": "background",
            "item": "neutral",
            "subitem": "subtle"
          },
          "path": [
            "color",
            "background",
            "neutral",
            "subtle"
          ]
        },
        "hover": {
          "value": "#f3f4f6",
          "filePath": "src/properties/color/semantic.json",
          "isSource": true,
          "original": {
            "value": "{color.base.grey.100.value}"
          },
          "name": "ColorBackgroundNeutralHover",
          "attributes": {
            "category": "color",
            "type": "background",
            "item": "neutral",
            "subitem": "hover"
          },
          "path": [
            "color",
            "background",
            "neutral",
            "hover"
          ]
        },
        "pressed": {
          "value": "#ebecef",
          "filePath": "src/properties/color/semantic.json",
          "isSource": true,
          "original": {
            "value": "{color.base.grey.200.value}"
          },
          "name": "ColorBackgroundNeutralPressed",
          "attributes": {
            "category": "color",
            "type": "background",
            "item": "neutral",
            "subitem": "pressed"
          },
          "path": [
            "color",
            "background",
            "neutral",
            "pressed"
          ]
        }
      },
      "success_subtle": {
        "value": "#f1fdf7",
        "filePath": "src/properties/color/semantic.json",
        "isSource": true,
        "original": {
          "value": "{color.base.green.25.value}"
        },
        "name": "ColorBackgroundSuccessSubtle",
        "attributes": {
          "category": "color",
          "type": "background",
          "item": "success_subtle"
        },
        "path": [
          "color",
          "background",
          "success_subtle"
        ]
      },
      "success_hover": {
        "value": "#c8f9e0",
        "filePath": "src/properties/color/semantic.json",
        "isSource": true,
        "original": {
          "value": "{color.base.green.100.value}"
        },
        "name": "ColorBackgroundSuccessHover",
        "attributes": {
          "category": "color",
          "type": "background",
          "item": "success_hover"
        },
        "path": [
          "color",
          "background",
          "success_hover"
        ]
      },
      "success_pressed": {
        "value": "#a5f3cf",
        "filePath": "src/properties/color/semantic.json",
        "isSource": true,
        "original": {
          "value": "{color.base.green.200.value}"
        },
        "name": "ColorBackgroundSuccessPressed",
        "attributes": {
          "category": "color",
          "type": "background",
          "item": "success_pressed"
        },
        "path": [
          "color",
          "background",
          "success_pressed"
        ]
      },
      "success_strong": {
        "default": {
          "value": "#10b77f",
          "filePath": "src/properties/color/semantic.json",
          "isSource": true,
          "original": {
            "value": "{color.base.green.500.value}"
          },
          "name": "ColorBackgroundSuccessStrongDefault",
          "attributes": {
            "category": "color",
            "type": "background",
            "item": "success_strong",
            "subitem": "default"
          },
          "path": [
            "color",
            "background",
            "success_strong",
            "default"
          ]
        },
        "hover": {
          "value": "#08875f",
          "filePath": "src/properties/color/semantic.json",
          "isSource": true,
          "original": {
            "value": "{color.base.green.600.value}"
          },
          "name": "ColorBackgroundSuccessStrongHover",
          "attributes": {
            "category": "color",
            "type": "background",
            "item": "success_strong",
            "subitem": "hover"
          },
          "path": [
            "color",
            "background",
            "success_strong",
            "hover"
          ]
        },
        "pressed": {
          "value": "#047756",
          "filePath": "src/properties/color/semantic.json",
          "isSource": true,
          "original": {
            "value": "{color.base.green.700.value}"
          },
          "name": "ColorBackgroundSuccessStrongPressed",
          "attributes": {
            "category": "color",
            "type": "background",
            "item": "success_strong",
            "subitem": "pressed"
          },
          "path": [
            "color",
            "background",
            "success_strong",
            "pressed"
          ]
        }
      },
      "info_subtle": {
        "value": "#f5f9ff",
        "filePath": "src/properties/color/semantic.json",
        "isSource": true,
        "original": {
          "value": "{color.base.blue.25.value}"
        },
        "name": "ColorBackgroundInfoSubtle",
        "attributes": {
          "category": "color",
          "type": "background",
          "item": "info_subtle"
        },
        "path": [
          "color",
          "background",
          "info_subtle"
        ]
      },
      "info_hover": {
        "value": "#d7e8fe",
        "filePath": "src/properties/color/semantic.json",
        "isSource": true,
        "original": {
          "value": "{color.base.blue.100.value}"
        },
        "name": "ColorBackgroundInfoHover",
        "attributes": {
          "category": "color",
          "type": "background",
          "item": "info_hover"
        },
        "path": [
          "color",
          "background",
          "info_hover"
        ]
      },
      "info_pressed": {
        "value": "#bedbfe",
        "filePath": "src/properties/color/semantic.json",
        "isSource": true,
        "original": {
          "value": "{color.base.blue.200.value}"
        },
        "name": "ColorBackgroundInfoPressed",
        "attributes": {
          "category": "color",
          "type": "background",
          "item": "info_pressed"
        },
        "path": [
          "color",
          "background",
          "info_pressed"
        ]
      },
      "warning_subtle": {
        "value": "#fffcf0",
        "filePath": "src/properties/color/semantic.json",
        "isSource": true,
        "original": {
          "value": "{color.base.yellow.25.value}"
        },
        "name": "ColorBackgroundWarningSubtle",
        "attributes": {
          "category": "color",
          "type": "background",
          "item": "warning_subtle"
        },
        "path": [
          "color",
          "background",
          "warning_subtle"
        ]
      },
      "warning_hover": {
        "value": "#fef1be",
        "filePath": "src/properties/color/semantic.json",
        "isSource": true,
        "original": {
          "value": "{color.base.yellow.100.value}"
        },
        "name": "ColorBackgroundWarningHover",
        "attributes": {
          "category": "color",
          "type": "background",
          "item": "warning_hover"
        },
        "path": [
          "color",
          "background",
          "warning_hover"
        ]
      },
      "warning_pressed": {
        "value": "#fde68b",
        "filePath": "src/properties/color/semantic.json",
        "isSource": true,
        "original": {
          "value": "{color.base.yellow.200.value}"
        },
        "name": "ColorBackgroundWarningPressed",
        "attributes": {
          "category": "color",
          "type": "background",
          "item": "warning_pressed"
        },
        "path": [
          "color",
          "background",
          "warning_pressed"
        ]
      },
      "danger": {
        "default": {
          "value": "#fee7e7",
          "filePath": "src/properties/color/semantic.json",
          "isSource": true,
          "original": {
            "value": "{color.base.red.50.value}"
          },
          "name": "ColorBackgroundDangerDefault",
          "attributes": {
            "category": "color",
            "type": "background",
            "item": "danger",
            "subitem": "default"
          },
          "path": [
            "color",
            "background",
            "danger",
            "default"
          ]
        },
        "subtle": {
          "value": "#fef5f5",
          "filePath": "src/properties/color/semantic.json",
          "isSource": true,
          "original": {
            "value": "{color.base.red.25.value}"
          },
          "name": "ColorBackgroundDangerSubtle",
          "attributes": {
            "category": "color",
            "type": "background",
            "item": "danger",
            "subitem": "subtle"
          },
          "path": [
            "color",
            "background",
            "danger",
            "subtle"
          ]
        },
        "hover": {
          "value": "#fee1e1",
          "filePath": "src/properties/color/semantic.json",
          "isSource": true,
          "original": {
            "value": "{color.base.red.100.value}"
          },
          "name": "ColorBackgroundDangerHover",
          "attributes": {
            "category": "color",
            "type": "background",
            "item": "danger",
            "subitem": "hover"
          },
          "path": [
            "color",
            "background",
            "danger",
            "hover"
          ]
        },
        "pressed": {
          "value": "#fec8c8",
          "filePath": "src/properties/color/semantic.json",
          "isSource": true,
          "original": {
            "value": "{color.base.red.200.value}"
          },
          "name": "ColorBackgroundDangerPressed",
          "attributes": {
            "category": "color",
            "type": "background",
            "item": "danger",
            "subitem": "pressed"
          },
          "path": [
            "color",
            "background",
            "danger",
            "pressed"
          ]
        }
      },
      "processing": {
        "default": {
          "value": "#faebff",
          "filePath": "src/properties/color/semantic.json",
          "isSource": true,
          "original": {
            "value": "{color.base.violet.50.value}"
          },
          "name": "ColorBackgroundProcessingDefault",
          "attributes": {
            "category": "color",
            "type": "background",
            "item": "processing",
            "subitem": "default"
          },
          "path": [
            "color",
            "background",
            "processing",
            "default"
          ]
        },
        "subtle": {
          "value": "#fdf5ff",
          "filePath": "src/properties/color/semantic.json",
          "isSource": true,
          "original": {
            "value": "{color.base.violet.25.value}"
          },
          "name": "ColorBackgroundProcessingSubtle",
          "attributes": {
            "category": "color",
            "type": "background",
            "item": "processing",
            "subitem": "subtle"
          },
          "path": [
            "color",
            "background",
            "processing",
            "subtle"
          ]
        },
        "hover": {
          "value": "#f9e6ff",
          "filePath": "src/properties/color/semantic.json",
          "isSource": true,
          "original": {
            "value": "{color.base.violet.100.value}"
          },
          "name": "ColorBackgroundProcessingHover",
          "attributes": {
            "category": "color",
            "type": "background",
            "item": "processing",
            "subitem": "hover"
          },
          "path": [
            "color",
            "background",
            "processing",
            "hover"
          ]
        },
        "pressed": {
          "value": "#f5d2fe",
          "filePath": "src/properties/color/semantic.json",
          "isSource": true,
          "original": {
            "value": "{color.base.violet.200.value}"
          },
          "name": "ColorBackgroundProcessingPressed",
          "attributes": {
            "category": "color",
            "type": "background",
            "item": "processing",
            "subitem": "pressed"
          },
          "path": [
            "color",
            "background",
            "processing",
            "pressed"
          ]
        }
      },
      "selected": {
        "default": {
          "value": "#e6f0fe",
          "filePath": "src/properties/color/semantic.json",
          "isSource": true,
          "original": {
            "value": "{color.base.blue.50.value}"
          },
          "name": "ColorBackgroundSelectedDefault",
          "attributes": {
            "category": "color",
            "type": "background",
            "item": "selected",
            "subitem": "default"
          },
          "path": [
            "color",
            "background",
            "selected",
            "default"
          ]
        },
        "subtle": {
          "value": "#f5f9ff",
          "filePath": "src/properties/color/semantic.json",
          "isSource": true,
          "original": {
            "value": "{color.base.blue.25.value}"
          },
          "name": "ColorBackgroundSelectedSubtle",
          "attributes": {
            "category": "color",
            "type": "background",
            "item": "selected",
            "subitem": "subtle"
          },
          "path": [
            "color",
            "background",
            "selected",
            "subtle"
          ]
        },
        "hover": {
          "value": "#d7e8fe",
          "filePath": "src/properties/color/semantic.json",
          "isSource": true,
          "original": {
            "value": "{color.base.blue.100.value}"
          },
          "name": "ColorBackgroundSelectedHover",
          "attributes": {
            "category": "color",
            "type": "background",
            "item": "selected",
            "subitem": "hover"
          },
          "path": [
            "color",
            "background",
            "selected",
            "hover"
          ]
        },
        "pressed": {
          "value": "#bedbfe",
          "filePath": "src/properties/color/semantic.json",
          "isSource": true,
          "original": {
            "value": "{color.base.blue.200.value}"
          },
          "name": "ColorBackgroundSelectedPressed",
          "attributes": {
            "category": "color",
            "type": "background",
            "item": "selected",
            "subitem": "pressed"
          },
          "path": [
            "color",
            "background",
            "selected",
            "pressed"
          ]
        },
        "strong": {
          "default": {
            "value": "#3c83f6",
            "filePath": "src/properties/color/semantic.json",
            "isSource": true,
            "original": {
              "value": "{color.base.blue.500.value}"
            },
            "name": "ColorBackgroundSelectedStrongDefault",
            "attributes": {
              "category": "color",
              "type": "background",
              "item": "selected",
              "subitem": "strong",
              "state": "default"
            },
            "path": [
              "color",
              "background",
              "selected",
              "strong",
              "default"
            ]
          },
          "hover": {
            "value": "#2463eb",
            "filePath": "src/properties/color/semantic.json",
            "isSource": true,
            "original": {
              "value": "{color.base.blue.600.value}"
            },
            "name": "ColorBackgroundSelectedStrongHover",
            "attributes": {
              "category": "color",
              "type": "background",
              "item": "selected",
              "subitem": "strong",
              "state": "hover"
            },
            "path": [
              "color",
              "background",
              "selected",
              "strong",
              "hover"
            ]
          },
          "pressed": {
            "value": "#1d4fd7",
            "filePath": "src/properties/color/semantic.json",
            "isSource": true,
            "original": {
              "value": "{color.base.blue.700.value}"
            },
            "name": "ColorBackgroundSelectedStrongPressed",
            "attributes": {
              "category": "color",
              "type": "background",
              "item": "selected",
              "subitem": "strong",
              "state": "pressed"
            },
            "path": [
              "color",
              "background",
              "selected",
              "strong",
              "pressed"
            ]
          }
        }
      },
      "state": {
        "hover": {
          "value": "rgba(26, 46, 96, 0.04)",
          "filePath": "src/properties/color/semantic.json",
          "isSource": true,
          "original": {
            "value": "{color.base.alpha.light.100.value}"
          },
          "name": "ColorBackgroundStateHover",
          "attributes": {
            "category": "color",
            "type": "background",
            "item": "state",
            "subitem": "hover"
          },
          "path": [
            "color",
            "background",
            "state",
            "hover"
          ]
        },
        "pressed": {
          "value": "rgba(26, 46, 96, 0.06)",
          "filePath": "src/properties/color/semantic.json",
          "isSource": true,
          "original": {
            "value": "{color.base.alpha.light.200.value}"
          },
          "name": "ColorBackgroundStatePressed",
          "attributes": {
            "category": "color",
            "type": "background",
            "item": "state",
            "subitem": "pressed"
          },
          "path": [
            "color",
            "background",
            "state",
            "pressed"
          ]
        },
        "inverse": {
          "hover": {
            "value": "rgba(223, 230, 246, 0.12)",
            "filePath": "src/properties/color/semantic.json",
            "isSource": true,
            "original": {
              "value": "{color.base.alpha.dark.100.value}"
            },
            "name": "ColorBackgroundStateInverseHover",
            "attributes": {
              "category": "color",
              "type": "background",
              "item": "state",
              "subitem": "inverse",
              "state": "hover"
            },
            "path": [
              "color",
              "background",
              "state",
              "inverse",
              "hover"
            ]
          },
          "pressed": {
            "value": "rgba(223, 230, 246, 0.24)",
            "filePath": "src/properties/color/semantic.json",
            "isSource": true,
            "original": {
              "value": "{color.base.alpha.dark.200.value}"
            },
            "name": "ColorBackgroundStateInversePressed",
            "attributes": {
              "category": "color",
              "type": "background",
              "item": "state",
              "subitem": "inverse",
              "state": "pressed"
            },
            "path": [
              "color",
              "background",
              "state",
              "inverse",
              "pressed"
            ]
          }
        }
      },
      "highlight_base": {
        "value": "#fef1be",
        "filePath": "src/properties/color/semantic.json",
        "isSource": true,
        "original": {
          "value": "{color.base.yellow.100.value}"
        },
        "name": "ColorBackgroundHighlightBase",
        "attributes": {
          "category": "color",
          "type": "background",
          "item": "highlight_base"
        },
        "path": [
          "color",
          "background",
          "highlight_base"
        ]
      },
      "highlight_base02": {
        "value": "#faebff",
        "filePath": "src/properties/color/semantic.json",
        "isSource": true,
        "original": {
          "value": "{color.base.violet.50.value}"
        },
        "name": "ColorBackgroundHighlightBase02",
        "attributes": {
          "category": "color",
          "type": "background",
          "item": "highlight_base02"
        },
        "path": [
          "color",
          "background",
          "highlight_base02"
        ]
      },
      "highlight_base03": {
        "value": "#e6f0fe",
        "filePath": "src/properties/color/semantic.json",
        "isSource": true,
        "original": {
          "value": "{color.base.blue.50.value}"
        },
        "name": "ColorBackgroundHighlightBase03",
        "attributes": {
          "category": "color",
          "type": "background",
          "item": "highlight_base03"
        },
        "path": [
          "color",
          "background",
          "highlight_base03"
        ]
      }
    },
    "border": {
      "ai": {
        "strong": {
          "value": "#8055f7",
          "filePath": "src/properties/color/semantic.json",
          "isSource": true,
          "original": {
            "value": "{color.base.purple.500.value}"
          },
          "name": "ColorBorderAiStrong",
          "attributes": {
            "category": "color",
            "type": "border",
            "item": "ai",
            "subitem": "strong"
          },
          "path": [
            "color",
            "border",
            "ai",
            "strong"
          ]
        },
        "subtle": {
          "value": "rgba(101, 52, 234, 0.03)",
          "filePath": "src/properties/color/semantic.json",
          "isSource": true,
          "original": {
            "value": "{color.base.purple.600subtle.value}"
          },
          "name": "ColorBorderAiSubtle",
          "attributes": {
            "category": "color",
            "type": "border",
            "item": "ai",
            "subitem": "subtle"
          },
          "path": [
            "color",
            "border",
            "ai",
            "subtle"
          ]
        }
      },
      "brand": {
        "value": "linear-gradient(268.84deg, #e8236a 0%, #fd3001 31.25%, #f97415 100%)",
        "filePath": "src/properties/color/semantic.json",
        "isSource": true,
        "original": {
          "value": "{color.gradient.500.value}"
        },
        "name": "ColorBorderBrand",
        "attributes": {
          "category": "color",
          "type": "border",
          "item": "brand"
        },
        "path": [
          "color",
          "border",
          "brand"
        ]
      },
      "divider": {
        "value": "#e2e4e9",
        "filePath": "src/properties/color/semantic.json",
        "isSource": true,
        "original": {
          "value": "{color.base.grey.300.value}"
        },
        "name": "ColorBorderDivider",
        "attributes": {
          "category": "color",
          "type": "border",
          "item": "divider"
        },
        "path": [
          "color",
          "border",
          "divider"
        ]
      },
      "divider_inverse": {
        "value": "#ffffff",
        "filePath": "src/properties/color/semantic.json",
        "isSource": true,
        "original": {
          "value": "{color.base.white.value}"
        },
        "name": "ColorBorderDividerInverse",
        "attributes": {
          "category": "color",
          "type": "border",
          "item": "divider_inverse"
        },
        "path": [
          "color",
          "border",
          "divider_inverse"
        ]
      },
      "danger": {
        "value": "#ef4343",
        "filePath": "src/properties/color/semantic.json",
        "isSource": true,
        "original": {
          "value": "{color.base.red.500.value}"
        },
        "name": "ColorBorderDanger",
        "attributes": {
          "category": "color",
          "type": "border",
          "item": "danger"
        },
        "path": [
          "color",
          "border",
          "danger"
        ]
      },
      "danger_subtle": {
        "value": "rgba(220, 40, 40, 0.08)",
        "filePath": "src/properties/color/semantic.json",
        "isSource": true,
        "original": {
          "value": "{color.base.red.600subtle.value}"
        },
        "name": "ColorBorderDangerSubtle",
        "attributes": {
          "category": "color",
          "type": "border",
          "item": "danger_subtle"
        },
        "path": [
          "color",
          "border",
          "danger_subtle"
        ]
      },
      "warning": {
        "value": "#f59f0a",
        "filePath": "src/properties/color/semantic.json",
        "isSource": true,
        "original": {
          "value": "{color.base.yellow.500.value}"
        },
        "name": "ColorBorderWarning",
        "attributes": {
          "category": "color",
          "type": "border",
          "item": "warning"
        },
        "path": [
          "color",
          "border",
          "warning"
        ]
      },
      "warning_subtle": {
        "value": "rgba(202, 108, 2, 0.08)",
        "filePath": "src/properties/color/semantic.json",
        "isSource": true,
        "original": {
          "value": "{color.base.yellow.600subtle.value}"
        },
        "name": "ColorBorderWarningSubtle",
        "attributes": {
          "category": "color",
          "type": "border",
          "item": "warning_subtle"
        },
        "path": [
          "color",
          "border",
          "warning_subtle"
        ]
      },
      "success": {
        "value": "#10b77f",
        "filePath": "src/properties/color/semantic.json",
        "isSource": true,
        "original": {
          "value": "{color.base.green.500.value}"
        },
        "name": "ColorBorderSuccess",
        "attributes": {
          "category": "color",
          "type": "border",
          "item": "success"
        },
        "path": [
          "color",
          "border",
          "success"
        ]
      },
      "success_subtle": {
        "value": "rgba(8, 135, 95, 0.08)",
        "filePath": "src/properties/color/semantic.json",
        "isSource": true,
        "original": {
          "value": "{color.base.green.600subtle.value}"
        },
        "name": "ColorBorderSuccessSubtle",
        "attributes": {
          "category": "color",
          "type": "border",
          "item": "success_subtle"
        },
        "path": [
          "color",
          "border",
          "success_subtle"
        ]
      },
      "info": {
        "value": "#3c83f6",
        "filePath": "src/properties/color/semantic.json",
        "isSource": true,
        "original": {
          "value": "{color.base.blue.500.value}"
        },
        "name": "ColorBorderInfo",
        "attributes": {
          "category": "color",
          "type": "border",
          "item": "info"
        },
        "path": [
          "color",
          "border",
          "info"
        ]
      },
      "info_subtle": {
        "value": "rgba(36, 99, 235, 0.08)",
        "filePath": "src/properties/color/semantic.json",
        "isSource": true,
        "original": {
          "value": "{color.base.blue.600subtle.value}"
        },
        "name": "ColorBorderInfoSubtle",
        "attributes": {
          "category": "color",
          "type": "border",
          "item": "info_subtle"
        },
        "path": [
          "color",
          "border",
          "info_subtle"
        ]
      },
      "disabled": {
        "value": "#ebecef",
        "filePath": "src/properties/color/semantic.json",
        "isSource": true,
        "original": {
          "value": "{color.base.grey.200.value}"
        },
        "name": "ColorBorderDisabled",
        "attributes": {
          "category": "color",
          "type": "border",
          "item": "disabled"
        },
        "path": [
          "color",
          "border",
          "disabled"
        ]
      },
      "selected": {
        "value": "#3c83f6",
        "filePath": "src/properties/color/semantic.json",
        "isSource": true,
        "original": {
          "value": "{color.base.blue.500.value}"
        },
        "name": "ColorBorderSelected",
        "attributes": {
          "category": "color",
          "type": "border",
          "item": "selected"
        },
        "path": [
          "color",
          "border",
          "selected"
        ]
      },
      "strong": {
        "_": {
          "value": "#1e2129",
          "filePath": "src/properties/color/semantic.json",
          "isSource": true,
          "original": {
            "value": "{color.base.grey.900.value}"
          },
          "name": "ColorBorderStrong",
          "attributes": {
            "category": "color",
            "type": "border",
            "item": "strong",
            "subitem": "_"
          },
          "path": [
            "color",
            "border",
            "strong",
            "_"
          ]
        },
        "hover": {
          "value": "#ef4343",
          "filePath": "src/properties/color/semantic.json",
          "isSource": true,
          "original": {
            "value": "{color.base.red.500.value}"
          },
          "name": "ColorBorderStrongHover",
          "attributes": {
            "category": "color",
            "type": "border",
            "item": "strong",
            "subitem": "hover"
          },
          "path": [
            "color",
            "border",
            "strong",
            "hover"
          ]
        }
      },
      "neutral": {
        "default": {
          "value": "#e2e4e9",
          "filePath": "src/properties/color/semantic.json",
          "isSource": true,
          "original": {
            "value": "{color.base.grey.300.value}"
          },
          "name": "ColorBorderNeutralDefault",
          "attributes": {
            "category": "color",
            "type": "border",
            "item": "neutral",
            "subitem": "default"
          },
          "path": [
            "color",
            "border",
            "neutral",
            "default"
          ]
        },
        "hover": {
          "value": "#9ca1b0",
          "filePath": "src/properties/color/semantic.json",
          "isSource": true,
          "original": {
            "value": "{color.base.grey.400.value}"
          },
          "name": "ColorBorderNeutralHover",
          "attributes": {
            "category": "color",
            "type": "border",
            "item": "neutral",
            "subitem": "hover"
          },
          "path": [
            "color",
            "border",
            "neutral",
            "hover"
          ]
        }
      },
      "processing": {
        "value": "#d535ed",
        "filePath": "src/properties/color/semantic.json",
        "isSource": true,
        "original": {
          "value": "{color.base.violet.500.value}"
        },
        "name": "ColorBorderProcessing",
        "attributes": {
          "category": "color",
          "type": "border",
          "item": "processing"
        },
        "path": [
          "color",
          "border",
          "processing"
        ]
      },
      "processing_subtle": {
        "value": "rgba(191, 39, 211, 0.08)",
        "filePath": "src/properties/color/semantic.json",
        "isSource": true,
        "original": {
          "value": "{color.base.violet.600subtle.value}"
        },
        "name": "ColorBorderProcessingSubtle",
        "attributes": {
          "category": "color",
          "type": "border",
          "item": "processing_subtle"
        },
        "path": [
          "color",
          "border",
          "processing_subtle"
        ]
      }
    },
    "text": {
      "ai": {
        "value": "#6534ea",
        "filePath": "src/properties/color/semantic.json",
        "isSource": true,
        "original": {
          "value": "{color.base.purple.600.value}"
        },
        "name": "ColorTextAi",
        "attributes": {
          "category": "color",
          "type": "text",
          "item": "ai"
        },
        "path": [
          "color",
          "text",
          "ai"
        ]
      },
      "danger": {
        "value": "#dc2828",
        "filePath": "src/properties/color/semantic.json",
        "isSource": true,
        "original": {
          "value": "{color.base.red.600.value}"
        },
        "name": "ColorTextDanger",
        "attributes": {
          "category": "color",
          "type": "text",
          "item": "danger"
        },
        "path": [
          "color",
          "text",
          "danger"
        ]
      },
      "inverse": {
        "value": "#ffffff",
        "filePath": "src/properties/color/semantic.json",
        "isSource": true,
        "original": {
          "value": "{color.base.white.value}"
        },
        "name": "ColorTextInverse",
        "attributes": {
          "category": "color",
          "type": "text",
          "item": "inverse"
        },
        "path": [
          "color",
          "text",
          "inverse"
        ]
      },
      "disabled": {
        "value": "#9ca1b0",
        "filePath": "src/properties/color/semantic.json",
        "isSource": true,
        "original": {
          "value": "{color.base.grey.400.value}"
        },
        "name": "ColorTextDisabled",
        "attributes": {
          "category": "color",
          "type": "text",
          "item": "disabled"
        },
        "path": [
          "color",
          "text",
          "disabled"
        ]
      },
      "assistive": {
        "value": "#9ca1b0",
        "filePath": "src/properties/color/semantic.json",
        "isSource": true,
        "original": {
          "value": "{color.base.grey.400.value}"
        },
        "name": "ColorTextAssistive",
        "attributes": {
          "category": "color",
          "type": "text",
          "item": "assistive"
        },
        "path": [
          "color",
          "text",
          "assistive"
        ]
      },
      "strong": {
        "_": {
          "value": "#1e2129",
          "filePath": "src/properties/color/semantic.json",
          "isSource": true,
          "original": {
            "value": "{color.base.grey.900.value}"
          },
          "name": "ColorTextStrong",
          "attributes": {
            "category": "color",
            "type": "text",
            "item": "strong",
            "subitem": "_"
          },
          "path": [
            "color",
            "text",
            "strong",
            "_"
          ]
        },
        "hover": {
          "value": "#dc2828",
          "filePath": "src/properties/color/semantic.json",
          "isSource": true,
          "original": {
            "value": "{color.base.red.600.value}"
          },
          "name": "ColorTextStrongHover",
          "attributes": {
            "category": "color",
            "type": "text",
            "item": "strong",
            "subitem": "hover"
          },
          "path": [
            "color",
            "text",
            "strong",
            "hover"
          ]
        }
      },
      "secondary": {
        "value": "#666b7a",
        "filePath": "src/properties/color/semantic.json",
        "isSource": true,
        "original": {
          "value": "{color.base.grey.500.value}"
        },
        "name": "ColorTextSecondary",
        "attributes": {
          "category": "color",
          "type": "text",
          "item": "secondary"
        },
        "path": [
          "color",
          "text",
          "secondary"
        ]
      },
      "info": {
        "value": "#2463eb",
        "filePath": "src/properties/color/semantic.json",
        "isSource": true,
        "original": {
          "value": "{color.base.blue.600.value}"
        },
        "name": "ColorTextInfo",
        "attributes": {
          "category": "color",
          "type": "text",
          "item": "info"
        },
        "path": [
          "color",
          "text",
          "info"
        ]
      },
      "success": {
        "value": "#08875f",
        "filePath": "src/properties/color/semantic.json",
        "isSource": true,
        "original": {
          "value": "{color.base.green.600.value}"
        },
        "name": "ColorTextSuccess",
        "attributes": {
          "category": "color",
          "type": "text",
          "item": "success"
        },
        "path": [
          "color",
          "text",
          "success"
        ]
      },
      "warning": {
        "value": "#ca6c02",
        "filePath": "src/properties/color/semantic.json",
        "isSource": true,
        "original": {
          "value": "{color.base.yellow.600.value}"
        },
        "name": "ColorTextWarning",
        "attributes": {
          "category": "color",
          "type": "text",
          "item": "warning"
        },
        "path": [
          "color",
          "text",
          "warning"
        ]
      },
      "brand_hover": {
        "value": "#dc2828",
        "filePath": "src/properties/color/semantic.json",
        "isSource": true,
        "original": {
          "value": "{color.base.red.600.value}"
        },
        "name": "ColorTextBrandHover",
        "attributes": {
          "category": "color",
          "type": "text",
          "item": "brand_hover"
        },
        "path": [
          "color",
          "text",
          "brand_hover"
        ]
      },
      "link": {
        "_": {
          "value": "#2463eb",
          "filePath": "src/properties/color/semantic.json",
          "isSource": true,
          "original": {
            "value": "{color.base.blue.600.value}"
          },
          "name": "ColorTextLink",
          "attributes": {
            "category": "color",
            "type": "text",
            "item": "link",
            "subitem": "_"
          },
          "path": [
            "color",
            "text",
            "link",
            "_"
          ]
        },
        "hover": {
          "value": "#1d4fd7",
          "filePath": "src/properties/color/semantic.json",
          "isSource": true,
          "original": {
            "value": "{color.base.blue.700.value}"
          },
          "name": "ColorTextLinkHover",
          "attributes": {
            "category": "color",
            "type": "text",
            "item": "link",
            "subitem": "hover"
          },
          "path": [
            "color",
            "text",
            "link",
            "hover"
          ]
        },
        "pressed": {
          "value": "#1e3fae",
          "filePath": "src/properties/color/semantic.json",
          "isSource": true,
          "original": {
            "value": "{color.base.blue.800.value}"
          },
          "name": "ColorTextLinkPressed",
          "attributes": {
            "category": "color",
            "type": "text",
            "item": "link",
            "subitem": "pressed"
          },
          "path": [
            "color",
            "text",
            "link",
            "pressed"
          ]
        }
      },
      "menu": {
        "value": "#1e2129",
        "filePath": "src/properties/color/semantic.json",
        "isSource": true,
        "original": {
          "value": "{color.base.grey.900.value}"
        },
        "name": "ColorTextMenu",
        "attributes": {
          "category": "color",
          "type": "text",
          "item": "menu"
        },
        "path": [
          "color",
          "text",
          "menu"
        ]
      },
      "processing": {
        "value": "#bf27d3",
        "filePath": "src/properties/color/semantic.json",
        "isSource": true,
        "original": {
          "value": "{color.base.violet.600.value}"
        },
        "name": "ColorTextProcessing",
        "attributes": {
          "category": "color",
          "type": "text",
          "item": "processing"
        },
        "path": [
          "color",
          "text",
          "processing"
        ]
      },
      "selected": {
        "value": "#2463eb",
        "filePath": "src/properties/color/semantic.json",
        "isSource": true,
        "original": {
          "value": "{color.base.blue.600.value}"
        },
        "name": "ColorTextSelected",
        "attributes": {
          "category": "color",
          "type": "text",
          "item": "selected"
        },
        "path": [
          "color",
          "text",
          "selected"
        ]
      },
      "decorative": {
        "base": {
          "value": "#6534ea",
          "filePath": "src/properties/color/semantic.json",
          "isSource": true,
          "original": {
            "value": "{color.base.purple.600.value}"
          },
          "name": "ColorTextDecorativeBase",
          "attributes": {
            "category": "color",
            "type": "text",
            "item": "decorative",
            "subitem": "base"
          },
          "path": [
            "color",
            "text",
            "decorative",
            "base"
          ]
        },
        "base02": {
          "value": "#666b7a",
          "filePath": "src/properties/color/semantic.json",
          "isSource": true,
          "original": {
            "value": "{color.base.grey.500.value}"
          },
          "name": "ColorTextDecorativeBase02",
          "attributes": {
            "category": "color",
            "type": "text",
            "item": "decorative",
            "subitem": "base02"
          },
          "path": [
            "color",
            "text",
            "decorative",
            "base02"
          ]
        },
        "base03": {
          "value": "#e9590c",
          "filePath": "src/properties/color/semantic.json",
          "isSource": true,
          "original": {
            "value": "{color.base.orange.600.value}"
          },
          "name": "ColorTextDecorativeBase03",
          "attributes": {
            "category": "color",
            "type": "text",
            "item": "decorative",
            "subitem": "base03"
          },
          "path": [
            "color",
            "text",
            "decorative",
            "base03"
          ]
        },
        "base04": {
          "value": "#bf27d3",
          "filePath": "src/properties/color/semantic.json",
          "isSource": true,
          "original": {
            "value": "{color.base.violet.600.value}"
          },
          "name": "ColorTextDecorativeBase04",
          "attributes": {
            "category": "color",
            "type": "text",
            "item": "decorative",
            "subitem": "base04"
          },
          "path": [
            "color",
            "text",
            "decorative",
            "base04"
          ]
        },
        "base05": {
          "value": "#08875f",
          "filePath": "src/properties/color/semantic.json",
          "isSource": true,
          "original": {
            "value": "{color.base.green.600.value}"
          },
          "name": "ColorTextDecorativeBase05",
          "attributes": {
            "category": "color",
            "type": "text",
            "item": "decorative",
            "subitem": "base05"
          },
          "path": [
            "color",
            "text",
            "decorative",
            "base05"
          ]
        },
        "base06": {
          "value": "#2463eb",
          "filePath": "src/properties/color/semantic.json",
          "isSource": true,
          "original": {
            "value": "{color.base.blue.600.value}"
          },
          "name": "ColorTextDecorativeBase06",
          "attributes": {
            "category": "color",
            "type": "text",
            "item": "decorative",
            "subitem": "base06"
          },
          "path": [
            "color",
            "text",
            "decorative",
            "base06"
          ]
        }
      }
    },
    "icon": {
      "ai": {
        "value": "#6534ea",
        "filePath": "src/properties/color/semantic.json",
        "isSource": true,
        "original": {
          "value": "{color.base.purple.600.value}"
        },
        "name": "ColorIconAi",
        "attributes": {
          "category": "color",
          "type": "icon",
          "item": "ai"
        },
        "path": [
          "color",
          "icon",
          "ai"
        ]
      },
      "disabled": {
        "value": "#9ca1b0",
        "filePath": "src/properties/color/semantic.json",
        "isSource": true,
        "original": {
          "value": "{color.base.grey.400.value}"
        },
        "name": "ColorIconDisabled",
        "attributes": {
          "category": "color",
          "type": "icon",
          "item": "disabled"
        },
        "path": [
          "color",
          "icon",
          "disabled"
        ]
      },
      "brand_strong": {
        "value": "#0d1730",
        "filePath": "src/properties/color/semantic.json",
        "isSource": true,
        "original": {
          "value": "{color.base.blue.1000.value}"
        },
        "name": "ColorIconBrandStrong",
        "attributes": {
          "category": "color",
          "type": "icon",
          "item": "brand_strong"
        },
        "path": [
          "color",
          "icon",
          "brand_strong"
        ]
      },
      "brand_secondary": {
        "value": "#0d1730",
        "filePath": "src/properties/color/semantic.json",
        "isSource": true,
        "original": {
          "value": "{color.base.blue.1000.value}"
        },
        "name": "ColorIconBrandSecondary",
        "attributes": {
          "category": "color",
          "type": "icon",
          "item": "brand_secondary"
        },
        "path": [
          "color",
          "icon",
          "brand_secondary"
        ]
      },
      "strong": {
        "_": {
          "value": "#1e2129",
          "filePath": "src/properties/color/semantic.json",
          "isSource": true,
          "original": {
            "value": "{color.base.grey.900.value}"
          },
          "name": "ColorIconStrong",
          "attributes": {
            "category": "color",
            "type": "icon",
            "item": "strong",
            "subitem": "_"
          },
          "path": [
            "color",
            "icon",
            "strong",
            "_"
          ]
        },
        "hover": {
          "value": "#dc2828",
          "filePath": "src/properties/color/semantic.json",
          "isSource": true,
          "original": {
            "value": "{color.base.red.600.value}"
          },
          "name": "ColorIconStrongHover",
          "attributes": {
            "category": "color",
            "type": "icon",
            "item": "strong",
            "subitem": "hover"
          },
          "path": [
            "color",
            "icon",
            "strong",
            "hover"
          ]
        }
      },
      "secondary": {
        "value": "#666b7a",
        "filePath": "src/properties/color/semantic.json",
        "isSource": true,
        "original": {
          "value": "{color.base.grey.500.value}"
        },
        "name": "ColorIconSecondary",
        "attributes": {
          "category": "color",
          "type": "icon",
          "item": "secondary"
        },
        "path": [
          "color",
          "icon",
          "secondary"
        ]
      },
      "info": {
        "value": "#2463eb",
        "filePath": "src/properties/color/semantic.json",
        "isSource": true,
        "original": {
          "value": "{color.base.blue.600.value}"
        },
        "name": "ColorIconInfo",
        "attributes": {
          "category": "color",
          "type": "icon",
          "item": "info"
        },
        "path": [
          "color",
          "icon",
          "info"
        ]
      },
      "success": {
        "value": "#08875f",
        "filePath": "src/properties/color/semantic.json",
        "isSource": true,
        "original": {
          "value": "{color.base.green.600.value}"
        },
        "name": "ColorIconSuccess",
        "attributes": {
          "category": "color",
          "type": "icon",
          "item": "success"
        },
        "path": [
          "color",
          "icon",
          "success"
        ]
      },
      "warning": {
        "value": "#ca6c02",
        "filePath": "src/properties/color/semantic.json",
        "isSource": true,
        "original": {
          "value": "{color.base.yellow.600.value}"
        },
        "name": "ColorIconWarning",
        "attributes": {
          "category": "color",
          "type": "icon",
          "item": "warning"
        },
        "path": [
          "color",
          "icon",
          "warning"
        ]
      },
      "danger": {
        "value": "#dc2828",
        "filePath": "src/properties/color/semantic.json",
        "isSource": true,
        "original": {
          "value": "{color.base.red.600.value}"
        },
        "name": "ColorIconDanger",
        "attributes": {
          "category": "color",
          "type": "icon",
          "item": "danger"
        },
        "path": [
          "color",
          "icon",
          "danger"
        ]
      },
      "inverse": {
        "value": "#ffffff",
        "filePath": "src/properties/color/semantic.json",
        "isSource": true,
        "original": {
          "value": "{color.base.white.value}"
        },
        "name": "ColorIconInverse",
        "attributes": {
          "category": "color",
          "type": "icon",
          "item": "inverse"
        },
        "path": [
          "color",
          "icon",
          "inverse"
        ]
      },
      "link": {
        "_": {
          "value": "#2463eb",
          "filePath": "src/properties/color/semantic.json",
          "isSource": true,
          "original": {
            "value": "{color.base.blue.600.value}"
          },
          "name": "ColorIconLink",
          "attributes": {
            "category": "color",
            "type": "icon",
            "item": "link",
            "subitem": "_"
          },
          "path": [
            "color",
            "icon",
            "link",
            "_"
          ]
        },
        "hover": {
          "value": "#1d4fd7",
          "filePath": "src/properties/color/semantic.json",
          "isSource": true,
          "original": {
            "value": "{color.base.blue.700.value}"
          },
          "name": "ColorIconLinkHover",
          "attributes": {
            "category": "color",
            "type": "icon",
            "item": "link",
            "subitem": "hover"
          },
          "path": [
            "color",
            "icon",
            "link",
            "hover"
          ]
        },
        "pressed": {
          "value": "#1e3fae",
          "filePath": "src/properties/color/semantic.json",
          "isSource": true,
          "original": {
            "value": "{color.base.blue.800.value}"
          },
          "name": "ColorIconLinkPressed",
          "attributes": {
            "category": "color",
            "type": "icon",
            "item": "link",
            "subitem": "pressed"
          },
          "path": [
            "color",
            "icon",
            "link",
            "pressed"
          ]
        }
      },
      "processing": {
        "value": "#bf27d3",
        "filePath": "src/properties/color/semantic.json",
        "isSource": true,
        "original": {
          "value": "{color.base.violet.600.value}"
        },
        "name": "ColorIconProcessing",
        "attributes": {
          "category": "color",
          "type": "icon",
          "item": "processing"
        },
        "path": [
          "color",
          "icon",
          "processing"
        ]
      },
      "selected": {
        "value": "#2463eb",
        "filePath": "src/properties/color/semantic.json",
        "isSource": true,
        "original": {
          "value": "{color.base.blue.600.value}"
        },
        "name": "ColorIconSelected",
        "attributes": {
          "category": "color",
          "type": "icon",
          "item": "selected"
        },
        "path": [
          "color",
          "icon",
          "selected"
        ]
      },
      "decorative_base": {
        "value": "#6534ea",
        "filePath": "src/properties/color/semantic.json",
        "isSource": true,
        "original": {
          "value": "{color.base.purple.600.value}"
        },
        "name": "ColorIconDecorativeBase",
        "attributes": {
          "category": "color",
          "type": "icon",
          "item": "decorative_base"
        },
        "path": [
          "color",
          "icon",
          "decorative_base"
        ]
      },
      "decorative_base02": {
        "value": "#666b7a",
        "filePath": "src/properties/color/semantic.json",
        "isSource": true,
        "original": {
          "value": "{color.base.grey.500.value}"
        },
        "name": "ColorIconDecorativeBase02",
        "attributes": {
          "category": "color",
          "type": "icon",
          "item": "decorative_base02"
        },
        "path": [
          "color",
          "icon",
          "decorative_base02"
        ]
      },
      "decorative_base03": {
        "value": "#e9590c",
        "filePath": "src/properties/color/semantic.json",
        "isSource": true,
        "original": {
          "value": "{color.base.orange.600.value}"
        },
        "name": "ColorIconDecorativeBase03",
        "attributes": {
          "category": "color",
          "type": "icon",
          "item": "decorative_base03"
        },
        "path": [
          "color",
          "icon",
          "decorative_base03"
        ]
      },
      "decorative_base04": {
        "value": "#bf27d3",
        "filePath": "src/properties/color/semantic.json",
        "isSource": true,
        "original": {
          "value": "{color.base.violet.600.value}"
        },
        "name": "ColorIconDecorativeBase04",
        "attributes": {
          "category": "color",
          "type": "icon",
          "item": "decorative_base04"
        },
        "path": [
          "color",
          "icon",
          "decorative_base04"
        ]
      },
      "decorative_base05": {
        "value": "#08875f",
        "filePath": "src/properties/color/semantic.json",
        "isSource": true,
        "original": {
          "value": "{color.base.green.600.value}"
        },
        "name": "ColorIconDecorativeBase05",
        "attributes": {
          "category": "color",
          "type": "icon",
          "item": "decorative_base05"
        },
        "path": [
          "color",
          "icon",
          "decorative_base05"
        ]
      },
      "decorative_base06": {
        "value": "#2463eb",
        "filePath": "src/properties/color/semantic.json",
        "isSource": true,
        "original": {
          "value": "{color.base.blue.600.value}"
        },
        "name": "ColorIconDecorativeBase06",
        "attributes": {
          "category": "color",
          "type": "icon",
          "item": "decorative_base06"
        },
        "path": [
          "color",
          "icon",
          "decorative_base06"
        ]
      }
    },
    "chart": {
      "categorical": {
        "base": {
          "value": "#3c83f6",
          "filePath": "src/properties/color/semantic.json",
          "isSource": true,
          "original": {
            "value": "{color.base.blue.500.value}"
          },
          "name": "ColorChartCategoricalBase",
          "attributes": {
            "category": "color",
            "type": "chart",
            "item": "categorical",
            "subitem": "base"
          },
          "path": [
            "color",
            "chart",
            "categorical",
            "base"
          ]
        },
        "base02": {
          "value": "#ec4699",
          "filePath": "src/properties/color/semantic.json",
          "isSource": true,
          "original": {
            "value": "{color.base.fuchsia.500.value}"
          },
          "name": "ColorChartCategoricalBase02",
          "attributes": {
            "category": "color",
            "type": "chart",
            "item": "categorical",
            "subitem": "base02"
          },
          "path": [
            "color",
            "chart",
            "categorical",
            "base02"
          ]
        },
        "base03": {
          "value": "#0cc09e",
          "filePath": "src/properties/color/semantic.json",
          "isSource": true,
          "original": {
            "value": "#0CC09E"
          },
          "name": "ColorChartCategoricalBase03",
          "attributes": {
            "category": "color",
            "type": "chart",
            "item": "categorical",
            "subitem": "base03"
          },
          "path": [
            "color",
            "chart",
            "categorical",
            "base03"
          ]
        },
        "base04": {
          "value": "#ef4343",
          "filePath": "src/properties/color/semantic.json",
          "isSource": true,
          "original": {
            "value": "{color.base.red.500.value}"
          },
          "name": "ColorChartCategoricalBase04",
          "attributes": {
            "category": "color",
            "type": "chart",
            "item": "categorical",
            "subitem": "base04"
          },
          "path": [
            "color",
            "chart",
            "categorical",
            "base04"
          ]
        },
        "base05": {
          "value": "#f59f0a",
          "filePath": "src/properties/color/semantic.json",
          "isSource": true,
          "original": {
            "value": "{color.base.yellow.500.value}"
          },
          "name": "ColorChartCategoricalBase05",
          "attributes": {
            "category": "color",
            "type": "chart",
            "item": "categorical",
            "subitem": "base05"
          },
          "path": [
            "color",
            "chart",
            "categorical",
            "base05"
          ]
        },
        "base06": {
          "value": "#d535ed",
          "filePath": "src/properties/color/semantic.json",
          "isSource": true,
          "original": {
            "value": "{color.base.violet.500.value}"
          },
          "name": "ColorChartCategoricalBase06",
          "attributes": {
            "category": "color",
            "type": "chart",
            "item": "categorical",
            "subitem": "base06"
          },
          "path": [
            "color",
            "chart",
            "categorical",
            "base06"
          ]
        },
        "base07": {
          "value": "#f97415",
          "filePath": "src/properties/color/semantic.json",
          "isSource": true,
          "original": {
            "value": "{color.base.orange.500.value}"
          },
          "name": "ColorChartCategoricalBase07",
          "attributes": {
            "category": "color",
            "type": "chart",
            "item": "categorical",
            "subitem": "base07"
          },
          "path": [
            "color",
            "chart",
            "categorical",
            "base07"
          ]
        },
        "base08": {
          "value": "#8055f7",
          "filePath": "src/properties/color/semantic.json",
          "isSource": true,
          "original": {
            "value": "{color.base.purple.500.value}"
          },
          "name": "ColorChartCategoricalBase08",
          "attributes": {
            "category": "color",
            "type": "chart",
            "item": "categorical",
            "subitem": "base08"
          },
          "path": [
            "color",
            "chart",
            "categorical",
            "base08"
          ]
        },
        "base09": {
          "value": "#9ca1b0",
          "filePath": "src/properties/color/semantic.json",
          "isSource": true,
          "original": {
            "value": "{color.base.grey.400.value}"
          },
          "name": "ColorChartCategoricalBase09",
          "attributes": {
            "category": "color",
            "type": "chart",
            "item": "categorical",
            "subitem": "base09"
          },
          "path": [
            "color",
            "chart",
            "categorical",
            "base09"
          ]
        }
      }
    }
  },
  "tint": {
    "100": {
      "value": "rgba(70, 142, 255, 1)",
      "filePath": "src/properties/color/tint-shade.json",
      "isSource": true,
      "original": {
        "value": "rgba(70, 142, 255, 1)"
      },
      "name": "Tint100",
      "attributes": {
        "category": "tint",
        "type": "100"
      },
      "path": [
        "tint",
        "100"
      ]
    }
  },
  "shade": {
    "100": {
      "value": "rgba(44, 116, 230, 1)",
      "filePath": "src/properties/color/tint-shade.json",
      "isSource": true,
      "original": {
        "value": "rgba(44, 116, 230, 1)"
      },
      "name": "Shade100",
      "attributes": {
        "category": "shade",
        "type": "100"
      },
      "path": [
        "shade",
        "100"
      ]
    }
  },
  "flex": {
    "gap": {
      "sm": {
        "value": "4px",
        "filePath": "src/properties/flex/gap.json",
        "isSource": true,
        "original": {
          "value": "{spacing.inner.base.value}"
        },
        "name": "FlexGapSm",
        "attributes": {
          "category": "flex",
          "type": "gap",
          "item": "sm"
        },
        "path": [
          "flex",
          "gap",
          "sm"
        ]
      },
      "md": {
        "value": "8px",
        "filePath": "src/properties/flex/gap.json",
        "isSource": true,
        "original": {
          "value": "{spacing.inner.base02.value}"
        },
        "name": "FlexGapMd",
        "attributes": {
          "category": "flex",
          "type": "gap",
          "item": "md"
        },
        "path": [
          "flex",
          "gap",
          "md"
        ]
      },
      "lg": {
        "value": "12px",
        "filePath": "src/properties/flex/gap.json",
        "isSource": true,
        "original": {
          "value": "{spacing.inner.base03.value}"
        },
        "name": "FlexGapLg",
        "attributes": {
          "category": "flex",
          "type": "gap",
          "item": "lg"
        },
        "path": [
          "flex",
          "gap",
          "lg"
        ]
      },
      "xl": {
        "value": "16px",
        "filePath": "src/properties/flex/gap.json",
        "isSource": true,
        "original": {
          "value": "{spacing.inner.base04.value}"
        },
        "name": "FlexGapXl",
        "attributes": {
          "category": "flex",
          "type": "gap",
          "item": "xl"
        },
        "path": [
          "flex",
          "gap",
          "xl"
        ]
      }
    },
    "grow": {
      "none": {
        "value": "none",
        "filePath": "src/properties/flex/grow.json",
        "isSource": true,
        "original": {
          "value": "none"
        },
        "name": "FlexGrowNone",
        "attributes": {
          "category": "flex",
          "type": "grow",
          "item": "none"
        },
        "path": [
          "flex",
          "grow",
          "none"
        ]
      },
      "sm": {
        "value": 1,
        "filePath": "src/properties/flex/grow.json",
        "isSource": true,
        "original": {
          "value": 1
        },
        "name": "FlexGrowSm",
        "attributes": {
          "category": "flex",
          "type": "grow",
          "item": "sm"
        },
        "path": [
          "flex",
          "grow",
          "sm"
        ]
      },
      "md": {
        "value": 10,
        "filePath": "src/properties/flex/grow.json",
        "isSource": true,
        "original": {
          "value": 10
        },
        "name": "FlexGrowMd",
        "attributes": {
          "category": "flex",
          "type": "grow",
          "item": "md"
        },
        "path": [
          "flex",
          "grow",
          "md"
        ]
      },
      "lg": {
        "value": 100,
        "filePath": "src/properties/flex/grow.json",
        "isSource": true,
        "original": {
          "value": 100
        },
        "name": "FlexGrowLg",
        "attributes": {
          "category": "flex",
          "type": "grow",
          "item": "lg"
        },
        "path": [
          "flex",
          "grow",
          "lg"
        ]
      },
      "xl": {
        "value": 1000,
        "filePath": "src/properties/flex/grow.json",
        "isSource": true,
        "original": {
          "value": 1000
        },
        "name": "FlexGrowXl",
        "attributes": {
          "category": "flex",
          "type": "grow",
          "item": "xl"
        },
        "path": [
          "flex",
          "grow",
          "xl"
        ]
      }
    }
  },
  "icons": {
    "size": {
      "xs": {
        "value": "12px",
        "filePath": "src/properties/icons.json",
        "isSource": true,
        "original": {
          "value": 12
        },
        "name": "IconsSizeXs",
        "attributes": {
          "category": "icons",
          "type": "size",
          "item": "xs"
        },
        "path": [
          "icons",
          "size",
          "xs"
        ]
      },
      "sm": {
        "value": "16px",
        "filePath": "src/properties/icons.json",
        "isSource": true,
        "original": {
          "value": 16
        },
        "name": "IconsSizeSm",
        "attributes": {
          "category": "icons",
          "type": "size",
          "item": "sm"
        },
        "path": [
          "icons",
          "size",
          "sm"
        ]
      },
      "md": {
        "value": "20px",
        "filePath": "src/properties/icons.json",
        "isSource": true,
        "original": {
          "value": 20
        },
        "name": "IconsSizeMd",
        "attributes": {
          "category": "icons",
          "type": "size",
          "item": "md"
        },
        "path": [
          "icons",
          "size",
          "md"
        ]
      },
      "lg": {
        "value": "24px",
        "filePath": "src/properties/icons.json",
        "isSource": true,
        "original": {
          "value": 24
        },
        "name": "IconsSizeLg",
        "attributes": {
          "category": "icons",
          "type": "size",
          "item": "lg"
        },
        "path": [
          "icons",
          "size",
          "lg"
        ]
      },
      "xl": {
        "value": "32px",
        "filePath": "src/properties/icons.json",
        "isSource": true,
        "original": {
          "value": 32
        },
        "name": "IconsSizeXl",
        "attributes": {
          "category": "icons",
          "type": "size",
          "item": "xl"
        },
        "path": [
          "icons",
          "size",
          "xl"
        ]
      }
    }
  },
  "shape": {
    "border": {
      "radius": {
        "none": {
          "value": "0px",
          "filePath": "src/properties/shape/border.json",
          "isSource": true,
          "original": {
            "value": "0px"
          },
          "name": "ShapeBorderRadiusNone",
          "attributes": {
            "category": "shape",
            "type": "border",
            "item": "radius",
            "subitem": "none"
          },
          "path": [
            "shape",
            "border",
            "radius",
            "none"
          ]
        },
        "xsmall": {
          "value": "2px",
          "filePath": "src/properties/shape/border.json",
          "isSource": true,
          "original": {
            "value": "2px"
          },
          "name": "ShapeBorderRadiusXsmall",
          "attributes": {
            "category": "shape",
            "type": "border",
            "item": "radius",
            "subitem": "xsmall"
          },
          "path": [
            "shape",
            "border",
            "radius",
            "xsmall"
          ]
        },
        "small": {
          "value": "4px",
          "filePath": "src/properties/shape/border.json",
          "isSource": true,
          "original": {
            "value": "4px"
          },
          "name": "ShapeBorderRadiusSmall",
          "attributes": {
            "category": "shape",
            "type": "border",
            "item": "radius",
            "subitem": "small"
          },
          "path": [
            "shape",
            "border",
            "radius",
            "small"
          ]
        },
        "medium": {
          "value": "8px",
          "filePath": "src/properties/shape/border.json",
          "isSource": true,
          "original": {
            "value": "8px"
          },
          "name": "ShapeBorderRadiusMedium",
          "attributes": {
            "category": "shape",
            "type": "border",
            "item": "radius",
            "subitem": "medium"
          },
          "path": [
            "shape",
            "border",
            "radius",
            "medium"
          ]
        },
        "medium-large": {
          "value": "12px",
          "filePath": "src/properties/shape/border.json",
          "isSource": true,
          "original": {
            "value": "12px"
          },
          "name": "ShapeBorderRadiusMediumLarge",
          "attributes": {
            "category": "shape",
            "type": "border",
            "item": "radius",
            "subitem": "medium-large"
          },
          "path": [
            "shape",
            "border",
            "radius",
            "medium-large"
          ]
        },
        "large": {
          "value": "50%",
          "filePath": "src/properties/shape/border.json",
          "isSource": true,
          "original": {
            "value": "50%"
          },
          "name": "ShapeBorderRadiusLarge",
          "attributes": {
            "category": "shape",
            "type": "border",
            "item": "radius",
            "subitem": "large"
          },
          "path": [
            "shape",
            "border",
            "radius",
            "large"
          ]
        },
        "xlarge": {
          "value": "9999px",
          "filePath": "src/properties/shape/border.json",
          "isSource": true,
          "original": {
            "value": "9999px"
          },
          "name": "ShapeBorderRadiusXlarge",
          "attributes": {
            "category": "shape",
            "type": "border",
            "item": "radius",
            "subitem": "xlarge"
          },
          "path": [
            "shape",
            "border",
            "radius",
            "xlarge"
          ]
        }
      },
      "width": {
        "none": {
          "value": "0px",
          "filePath": "src/properties/shape/border.json",
          "isSource": true,
          "original": {
            "value": "0px"
          },
          "name": "ShapeBorderWidthNone",
          "attributes": {
            "category": "shape",
            "type": "border",
            "item": "width",
            "subitem": "none"
          },
          "path": [
            "shape",
            "border",
            "width",
            "none"
          ]
        },
        "sm": {
          "value": "1px",
          "filePath": "src/properties/shape/border.json",
          "isSource": true,
          "original": {
            "value": "1px"
          },
          "name": "ShapeBorderWidthSm",
          "attributes": {
            "category": "shape",
            "type": "border",
            "item": "width",
            "subitem": "sm"
          },
          "path": [
            "shape",
            "border",
            "width",
            "sm"
          ]
        },
        "lg": {
          "value": "2px",
          "filePath": "src/properties/shape/border.json",
          "isSource": true,
          "original": {
            "value": "2px"
          },
          "name": "ShapeBorderWidthLg",
          "attributes": {
            "category": "shape",
            "type": "border",
            "item": "width",
            "subitem": "lg"
          },
          "path": [
            "shape",
            "border",
            "width",
            "lg"
          ]
        },
        "xlg": {
          "value": "4px",
          "filePath": "src/properties/shape/border.json",
          "isSource": true,
          "original": {
            "value": "4px"
          },
          "name": "ShapeBorderWidthXlg",
          "attributes": {
            "category": "shape",
            "type": "border",
            "item": "width",
            "subitem": "xlg"
          },
          "path": [
            "shape",
            "border",
            "width",
            "xlg"
          ]
        },
        "small": {
          "value": "1px",
          "filePath": "src/properties/shape/border.json",
          "isSource": true,
          "original": {
            "value": "{shape.border.width.sm.value}"
          },
          "name": "ShapeBorderWidthSmall",
          "attributes": {
            "category": "shape",
            "type": "border",
            "item": "width",
            "subitem": "small"
          },
          "path": [
            "shape",
            "border",
            "width",
            "small"
          ]
        },
        "large": {
          "value": "2px",
          "filePath": "src/properties/shape/border.json",
          "isSource": true,
          "original": {
            "value": "{shape.border.width.lg.value}"
          },
          "name": "ShapeBorderWidthLarge",
          "attributes": {
            "category": "shape",
            "type": "border",
            "item": "width",
            "subitem": "large"
          },
          "path": [
            "shape",
            "border",
            "width",
            "large"
          ]
        },
        "xlarge": {
          "value": "4px",
          "filePath": "src/properties/shape/border.json",
          "isSource": true,
          "original": {
            "value": "{shape.border.width.xlg.value}"
          },
          "name": "ShapeBorderWidthXlarge",
          "attributes": {
            "category": "shape",
            "type": "border",
            "item": "width",
            "subitem": "xlarge"
          },
          "path": [
            "shape",
            "border",
            "width",
            "xlarge"
          ]
        }
      }
    },
    "opacity": {
      "default": {
        "value": "rgba(34, 34, 34, 0.7)",
        "filePath": "src/properties/shape/opacity.json",
        "isSource": true,
        "original": {
          "value": "rgba(34, 34, 34, 0.7)"
        },
        "name": "ShapeOpacityDefault",
        "attributes": {
          "category": "shape",
          "type": "opacity",
          "item": "default"
        },
        "path": [
          "shape",
          "opacity",
          "default"
        ]
      },
      "inverse": {
        "value": "rgba(255, 255, 255, 0.7)",
        "filePath": "src/properties/shape/opacity.json",
        "isSource": true,
        "original": {
          "value": "rgba(255, 255, 255, 0.7)"
        },
        "name": "ShapeOpacityInverse",
        "attributes": {
          "category": "shape",
          "type": "opacity",
          "item": "inverse"
        },
        "path": [
          "shape",
          "opacity",
          "inverse"
        ]
      },
      "overlay": {
        "100": {
          "value": "rgba(255, 255, 255, 0.8)",
          "filePath": "src/properties/shape/opacity.json",
          "isSource": true,
          "original": {
            "value": "rgba(255, 255, 255, 0.8)"
          },
          "name": "ShapeOpacityOverlay100",
          "attributes": {
            "category": "shape",
            "type": "opacity",
            "item": "overlay",
            "subitem": "100"
          },
          "path": [
            "shape",
            "opacity",
            "overlay",
            "100"
          ]
        },
        "900": {
          "value": "rgba(34, 34, 34, 0.8)",
          "filePath": "src/properties/shape/opacity.json",
          "isSource": true,
          "original": {
            "value": "rgba(34, 34, 34, 0.8)"
          },
          "name": "ShapeOpacityOverlay900",
          "attributes": {
            "category": "shape",
            "type": "opacity",
            "item": "overlay",
            "subitem": "900"
          },
          "path": [
            "shape",
            "opacity",
            "overlay",
            "900"
          ]
        }
      }
    },
    "shadow": {
      "default": {
        "value": "0px 1px 5px rgba(34, 34, 34, 0.1)",
        "filePath": "src/properties/shape/shadow.json",
        "isSource": true,
        "original": {
          "value": "0px 1px 5px rgba(34, 34, 34, 0.1)"
        },
        "name": "ShapeShadowDefault",
        "attributes": {
          "category": "shape",
          "type": "shadow",
          "item": "default"
        },
        "path": [
          "shape",
          "shadow",
          "default"
        ]
      },
      "strong": {
        "value": "0px 2px 16px rgba(0, 29, 58, 0.12), 0px 24px 64px rgba(0, 29, 58, 0.12)",
        "filePath": "src/properties/shape/shadow.json",
        "isSource": true,
        "original": {
          "value": "0px 2px 16px rgba(0, 29, 58, 0.12), 0px 24px 64px rgba(0, 29, 58, 0.12)"
        },
        "name": "ShapeShadowStrong",
        "attributes": {
          "category": "shape",
          "type": "shadow",
          "item": "strong"
        },
        "path": [
          "shape",
          "shadow",
          "strong"
        ]
      },
      "secondary": {
        "value": "0px 8px 24px rgba(0, 29, 58, 0.12)",
        "filePath": "src/properties/shape/shadow.json",
        "isSource": true,
        "original": {
          "value": "0px 8px 24px rgba(0, 29, 58, 0.12)"
        },
        "name": "ShapeShadowSecondary",
        "attributes": {
          "category": "shape",
          "type": "shadow",
          "item": "secondary"
        },
        "path": [
          "shape",
          "shadow",
          "secondary"
        ]
      },
      "tertiary": {
        "value": "0px 4px 12px rgba(0, 29, 58, 0.12)",
        "filePath": "src/properties/shape/shadow.json",
        "isSource": true,
        "original": {
          "value": "0px 4px 12px rgba(0, 29, 58, 0.12)"
        },
        "name": "ShapeShadowTertiary",
        "attributes": {
          "category": "shape",
          "type": "shadow",
          "item": "tertiary"
        },
        "path": [
          "shape",
          "shadow",
          "tertiary"
        ]
      },
      "medium": {
        "value": "0px 1px 5px rgba(34, 34, 34, 0.2)",
        "filePath": "src/properties/shape/shadow.json",
        "isSource": true,
        "original": {
          "value": "0px 1px 5px rgba(34, 34, 34, 0.2)"
        },
        "name": "ShapeShadowMedium",
        "attributes": {
          "category": "shape",
          "type": "shadow",
          "item": "medium"
        },
        "path": [
          "shape",
          "shadow",
          "medium"
        ]
      },
      "inverse": {
        "value": "inset 0px 2px 4px rgba(34, 34, 34, 0.06)",
        "filePath": "src/properties/shape/shadow.json",
        "isSource": true,
        "original": {
          "value": "inset 0px 2px 4px rgba(34, 34, 34, 0.06)"
        },
        "name": "ShapeShadowInverse",
        "attributes": {
          "category": "shape",
          "type": "shadow",
          "item": "inverse"
        },
        "path": [
          "shape",
          "shadow",
          "inverse"
        ]
      }
    }
  },
  "spacing": {
    "inner": {
      "base": {
        "value": "4px",
        "filePath": "src/properties/spacing.json",
        "isSource": true,
        "original": {
          "value": 4
        },
        "name": "SpacingInnerBase",
        "attributes": {
          "category": "spacing",
          "type": "inner",
          "item": "base"
        },
        "path": [
          "spacing",
          "inner",
          "base"
        ]
      },
      "base02": {
        "value": "8px",
        "filePath": "src/properties/spacing.json",
        "isSource": true,
        "original": {
          "value": 8
        },
        "name": "SpacingInnerBase02",
        "attributes": {
          "category": "spacing",
          "type": "inner",
          "item": "base02"
        },
        "path": [
          "spacing",
          "inner",
          "base02"
        ]
      },
      "base03": {
        "value": "12px",
        "filePath": "src/properties/spacing.json",
        "isSource": true,
        "original": {
          "value": 12
        },
        "name": "SpacingInnerBase03",
        "attributes": {
          "category": "spacing",
          "type": "inner",
          "item": "base03"
        },
        "path": [
          "spacing",
          "inner",
          "base03"
        ]
      },
      "base04": {
        "value": "16px",
        "filePath": "src/properties/spacing.json",
        "isSource": true,
        "original": {
          "value": 16
        },
        "name": "SpacingInnerBase04",
        "attributes": {
          "category": "spacing",
          "type": "inner",
          "item": "base04"
        },
        "path": [
          "spacing",
          "inner",
          "base04"
        ]
      },
      "base05": {
        "value": "20px",
        "filePath": "src/properties/spacing.json",
        "isSource": true,
        "original": {
          "value": 20
        },
        "name": "SpacingInnerBase05",
        "attributes": {
          "category": "spacing",
          "type": "inner",
          "item": "base05"
        },
        "path": [
          "spacing",
          "inner",
          "base05"
        ]
      },
      "base06": {
        "value": "24px",
        "filePath": "src/properties/spacing.json",
        "isSource": true,
        "original": {
          "value": 24
        },
        "name": "SpacingInnerBase06",
        "attributes": {
          "category": "spacing",
          "type": "inner",
          "item": "base06"
        },
        "path": [
          "spacing",
          "inner",
          "base06"
        ]
      },
      "base08": {
        "value": "32px",
        "filePath": "src/properties/spacing.json",
        "isSource": true,
        "original": {
          "value": 32
        },
        "name": "SpacingInnerBase08",
        "attributes": {
          "category": "spacing",
          "type": "inner",
          "item": "base08"
        },
        "path": [
          "spacing",
          "inner",
          "base08"
        ]
      },
      "base10": {
        "value": "40px",
        "filePath": "src/properties/spacing.json",
        "isSource": true,
        "original": {
          "value": 40
        },
        "name": "SpacingInnerBase10",
        "attributes": {
          "category": "spacing",
          "type": "inner",
          "item": "base10"
        },
        "path": [
          "spacing",
          "inner",
          "base10"
        ]
      },
      "base12": {
        "value": "48px",
        "filePath": "src/properties/spacing.json",
        "isSource": true,
        "original": {
          "value": 48
        },
        "name": "SpacingInnerBase12",
        "attributes": {
          "category": "spacing",
          "type": "inner",
          "item": "base12"
        },
        "path": [
          "spacing",
          "inner",
          "base12"
        ]
      },
      "base14": {
        "value": "56px",
        "filePath": "src/properties/spacing.json",
        "isSource": true,
        "original": {
          "value": 56
        },
        "name": "SpacingInnerBase14",
        "attributes": {
          "category": "spacing",
          "type": "inner",
          "item": "base14"
        },
        "path": [
          "spacing",
          "inner",
          "base14"
        ]
      }
    },
    "layout": {
      "base": {
        "value": "8px",
        "filePath": "src/properties/spacing.json",
        "isSource": true,
        "original": {
          "value": 8
        },
        "name": "SpacingLayoutBase",
        "attributes": {
          "category": "spacing",
          "type": "layout",
          "item": "base"
        },
        "path": [
          "spacing",
          "layout",
          "base"
        ]
      },
      "base02": {
        "value": "16px",
        "filePath": "src/properties/spacing.json",
        "isSource": true,
        "original": {
          "value": 16
        },
        "name": "SpacingLayoutBase02",
        "attributes": {
          "category": "spacing",
          "type": "layout",
          "item": "base02"
        },
        "path": [
          "spacing",
          "layout",
          "base02"
        ]
      },
      "base03": {
        "value": "24px",
        "filePath": "src/properties/spacing.json",
        "isSource": true,
        "original": {
          "value": 24
        },
        "name": "SpacingLayoutBase03",
        "attributes": {
          "category": "spacing",
          "type": "layout",
          "item": "base03"
        },
        "path": [
          "spacing",
          "layout",
          "base03"
        ]
      },
      "base04": {
        "value": "32px",
        "filePath": "src/properties/spacing.json",
        "isSource": true,
        "original": {
          "value": 32
        },
        "name": "SpacingLayoutBase04",
        "attributes": {
          "category": "spacing",
          "type": "layout",
          "item": "base04"
        },
        "path": [
          "spacing",
          "layout",
          "base04"
        ]
      },
      "base05": {
        "value": "40px",
        "filePath": "src/properties/spacing.json",
        "isSource": true,
        "original": {
          "value": 40
        },
        "name": "SpacingLayoutBase05",
        "attributes": {
          "category": "spacing",
          "type": "layout",
          "item": "base05"
        },
        "path": [
          "spacing",
          "layout",
          "base05"
        ]
      },
      "base06": {
        "value": "48px",
        "filePath": "src/properties/spacing.json",
        "isSource": true,
        "original": {
          "value": 48
        },
        "name": "SpacingLayoutBase06",
        "attributes": {
          "category": "spacing",
          "type": "layout",
          "item": "base06"
        },
        "path": [
          "spacing",
          "layout",
          "base06"
        ]
      },
      "base07": {
        "value": "56px",
        "filePath": "src/properties/spacing.json",
        "isSource": true,
        "original": {
          "value": 56
        },
        "name": "SpacingLayoutBase07",
        "attributes": {
          "category": "spacing",
          "type": "layout",
          "item": "base07"
        },
        "path": [
          "spacing",
          "layout",
          "base07"
        ]
      },
      "base08": {
        "value": "64px",
        "filePath": "src/properties/spacing.json",
        "isSource": true,
        "original": {
          "value": 64
        },
        "name": "SpacingLayoutBase08",
        "attributes": {
          "category": "spacing",
          "type": "layout",
          "item": "base08"
        },
        "path": [
          "spacing",
          "layout",
          "base08"
        ]
      },
      "base10": {
        "value": "80px",
        "filePath": "src/properties/spacing.json",
        "isSource": true,
        "original": {
          "value": 80
        },
        "name": "SpacingLayoutBase10",
        "attributes": {
          "category": "spacing",
          "type": "layout",
          "item": "base10"
        },
        "path": [
          "spacing",
          "layout",
          "base10"
        ]
      },
      "base12": {
        "value": "96px",
        "filePath": "src/properties/spacing.json",
        "isSource": true,
        "original": {
          "value": 96
        },
        "name": "SpacingLayoutBase12",
        "attributes": {
          "category": "spacing",
          "type": "layout",
          "item": "base12"
        },
        "path": [
          "spacing",
          "layout",
          "base12"
        ]
      },
      "base15": {
        "value": "120px",
        "filePath": "src/properties/spacing.json",
        "isSource": true,
        "original": {
          "value": 120
        },
        "name": "SpacingLayoutBase15",
        "attributes": {
          "category": "spacing",
          "type": "layout",
          "item": "base15"
        },
        "path": [
          "spacing",
          "layout",
          "base15"
        ]
      }
    }
  },
  "font": {
    "family": {
      "text": {
        "regular": {
          "value": "proxima-nova,Arial,sans-serif",
          "filePath": "src/properties/typography/family.json",
          "isSource": true,
          "original": {
            "value": "proxima-nova,Arial,sans-serif"
          },
          "name": "FontFamilyTextRegular",
          "attributes": {
            "category": "font",
            "type": "family",
            "item": "text",
            "subitem": "regular"
          },
          "path": [
            "font",
            "family",
            "text",
            "regular"
          ]
        },
        "bold": {
          "value": "proxima-nova,Arial,sans-serif",
          "filePath": "src/properties/typography/family.json",
          "isSource": true,
          "original": {
            "value": "proxima-nova,Arial,sans-serif"
          },
          "name": "FontFamilyTextBold",
          "attributes": {
            "category": "font",
            "type": "family",
            "item": "text",
            "subitem": "bold"
          },
          "path": [
            "font",
            "family",
            "text",
            "bold"
          ]
        }
      },
      "display": {
        "value": "proxima-nova,Arial,sans-serif",
        "filePath": "src/properties/typography/family.json",
        "isSource": true,
        "original": {
          "value": "proxima-nova,Arial,sans-serif"
        },
        "name": "FontFamilyDisplay",
        "attributes": {
          "category": "font",
          "type": "family",
          "item": "display"
        },
        "path": [
          "font",
          "family",
          "display"
        ]
      }
    },
    "line-height": {
      "10": {
        "value": "5rem",
        "filePath": "src/properties/typography/line-height.json",
        "isSource": true,
        "original": {
          "value": 80
        },
        "name": "FontLineHeight10",
        "attributes": {
          "category": "font",
          "type": "line-height",
          "item": "10"
        },
        "path": [
          "font",
          "line-height",
          "10"
        ]
      },
      "00": {
        "value": "0.75rem",
        "filePath": "src/properties/typography/line-height.json",
        "isSource": true,
        "original": {
          "value": 12
        },
        "name": "FontLineHeight00",
        "attributes": {
          "category": "font",
          "type": "line-height",
          "item": "00"
        },
        "path": [
          "font",
          "line-height",
          "00"
        ]
      },
      "01": {
        "value": "1rem",
        "filePath": "src/properties/typography/line-height.json",
        "isSource": true,
        "original": {
          "value": 16
        },
        "name": "FontLineHeight01",
        "attributes": {
          "category": "font",
          "type": "line-height",
          "item": "01"
        },
        "path": [
          "font",
          "line-height",
          "01"
        ]
      },
      "02": {
        "value": "1.25rem",
        "filePath": "src/properties/typography/line-height.json",
        "isSource": true,
        "original": {
          "value": 20
        },
        "name": "FontLineHeight02",
        "attributes": {
          "category": "font",
          "type": "line-height",
          "item": "02"
        },
        "path": [
          "font",
          "line-height",
          "02"
        ]
      },
      "03": {
        "value": "1.5rem",
        "filePath": "src/properties/typography/line-height.json",
        "isSource": true,
        "original": {
          "value": 24
        },
        "name": "FontLineHeight03",
        "attributes": {
          "category": "font",
          "type": "line-height",
          "item": "03"
        },
        "path": [
          "font",
          "line-height",
          "03"
        ]
      },
      "04": {
        "value": "1.75rem",
        "filePath": "src/properties/typography/line-height.json",
        "isSource": true,
        "original": {
          "value": 28
        },
        "name": "FontLineHeight04",
        "attributes": {
          "category": "font",
          "type": "line-height",
          "item": "04"
        },
        "path": [
          "font",
          "line-height",
          "04"
        ]
      },
      "05": {
        "value": "2rem",
        "filePath": "src/properties/typography/line-height.json",
        "isSource": true,
        "original": {
          "value": 32
        },
        "name": "FontLineHeight05",
        "attributes": {
          "category": "font",
          "type": "line-height",
          "item": "05"
        },
        "path": [
          "font",
          "line-height",
          "05"
        ]
      },
      "06": {
        "value": "2.5rem",
        "filePath": "src/properties/typography/line-height.json",
        "isSource": true,
        "original": {
          "value": 40
        },
        "name": "FontLineHeight06",
        "attributes": {
          "category": "font",
          "type": "line-height",
          "item": "06"
        },
        "path": [
          "font",
          "line-height",
          "06"
        ]
      },
      "07": {
        "value": "3rem",
        "filePath": "src/properties/typography/line-height.json",
        "isSource": true,
        "original": {
          "value": 48
        },
        "name": "FontLineHeight07",
        "attributes": {
          "category": "font",
          "type": "line-height",
          "item": "07"
        },
        "path": [
          "font",
          "line-height",
          "07"
        ]
      },
      "08": {
        "value": "3.75rem",
        "filePath": "src/properties/typography/line-height.json",
        "isSource": true,
        "original": {
          "value": 60
        },
        "name": "FontLineHeight08",
        "attributes": {
          "category": "font",
          "type": "line-height",
          "item": "08"
        },
        "path": [
          "font",
          "line-height",
          "08"
        ]
      },
      "09": {
        "value": "4.25rem",
        "filePath": "src/properties/typography/line-height.json",
        "isSource": true,
        "original": {
          "value": 68
        },
        "name": "FontLineHeight09",
        "attributes": {
          "category": "font",
          "type": "line-height",
          "item": "09"
        },
        "path": [
          "font",
          "line-height",
          "09"
        ]
      }
    },
    "size": {
      "10": {
        "value": "3rem",
        "filePath": "src/properties/typography/size.json",
        "isSource": true,
        "original": {
          "value": 48
        },
        "name": "FontSize10",
        "attributes": {
          "category": "font",
          "type": "size",
          "item": "10"
        },
        "path": [
          "font",
          "size",
          "10"
        ]
      },
      "11": {
        "value": "3.5rem",
        "filePath": "src/properties/typography/size.json",
        "isSource": true,
        "original": {
          "value": 56
        },
        "name": "FontSize11",
        "attributes": {
          "category": "font",
          "type": "size",
          "item": "11"
        },
        "path": [
          "font",
          "size",
          "11"
        ]
      },
      "12": {
        "value": "4rem",
        "filePath": "src/properties/typography/size.json",
        "isSource": true,
        "original": {
          "value": 64
        },
        "name": "FontSize12",
        "attributes": {
          "category": "font",
          "type": "size",
          "item": "12"
        },
        "path": [
          "font",
          "size",
          "12"
        ]
      },
      "base": {
        "value": "1rem",
        "filePath": "src/properties/typography/size.json",
        "isSource": true,
        "original": {
          "value": 16
        },
        "name": "FontSizeBase",
        "attributes": {
          "category": "font",
          "type": "size",
          "item": "base"
        },
        "path": [
          "font",
          "size",
          "base"
        ]
      },
      "01": {
        "value": "0.625rem",
        "filePath": "src/properties/typography/size.json",
        "isSource": true,
        "original": {
          "value": 10
        },
        "name": "FontSize01",
        "attributes": {
          "category": "font",
          "type": "size",
          "item": "01"
        },
        "path": [
          "font",
          "size",
          "01"
        ]
      },
      "02": {
        "value": "0.8125rem",
        "filePath": "src/properties/typography/size.json",
        "isSource": true,
        "original": {
          "value": 13
        },
        "name": "FontSize02",
        "attributes": {
          "category": "font",
          "type": "size",
          "item": "02"
        },
        "path": [
          "font",
          "size",
          "02"
        ]
      },
      "03": {
        "value": "0.875rem",
        "filePath": "src/properties/typography/size.json",
        "isSource": true,
        "original": {
          "value": 14
        },
        "name": "FontSize03",
        "attributes": {
          "category": "font",
          "type": "size",
          "item": "03"
        },
        "path": [
          "font",
          "size",
          "03"
        ]
      },
      "04": {
        "value": "1rem",
        "filePath": "src/properties/typography/size.json",
        "isSource": true,
        "original": {
          "value": "{font.size.base.value}"
        },
        "name": "FontSize04",
        "attributes": {
          "category": "font",
          "type": "size",
          "item": "04"
        },
        "path": [
          "font",
          "size",
          "04"
        ]
      },
      "05": {
        "value": "1.125rem",
        "filePath": "src/properties/typography/size.json",
        "isSource": true,
        "original": {
          "value": 18
        },
        "name": "FontSize05",
        "attributes": {
          "category": "font",
          "type": "size",
          "item": "05"
        },
        "path": [
          "font",
          "size",
          "05"
        ]
      },
      "06": {
        "value": "1.25rem",
        "filePath": "src/properties/typography/size.json",
        "isSource": true,
        "original": {
          "value": 20
        },
        "name": "FontSize06",
        "attributes": {
          "category": "font",
          "type": "size",
          "item": "06"
        },
        "path": [
          "font",
          "size",
          "06"
        ]
      },
      "07": {
        "value": "1.5rem",
        "filePath": "src/properties/typography/size.json",
        "isSource": true,
        "original": {
          "value": 24
        },
        "name": "FontSize07",
        "attributes": {
          "category": "font",
          "type": "size",
          "item": "07"
        },
        "path": [
          "font",
          "size",
          "07"
        ]
      },
      "08": {
        "value": "2rem",
        "filePath": "src/properties/typography/size.json",
        "isSource": true,
        "original": {
          "value": 32
        },
        "name": "FontSize08",
        "attributes": {
          "category": "font",
          "type": "size",
          "item": "08"
        },
        "path": [
          "font",
          "size",
          "08"
        ]
      },
      "09": {
        "value": "2.5rem",
        "filePath": "src/properties/typography/size.json",
        "isSource": true,
        "original": {
          "value": 40
        },
        "name": "FontSize09",
        "attributes": {
          "category": "font",
          "type": "size",
          "item": "09"
        },
        "path": [
          "font",
          "size",
          "09"
        ]
      }
    },
    "spacing": {
      "01": {
        "value": "0.0625rem",
        "filePath": "src/properties/typography/spacing.json",
        "isSource": true,
        "original": {
          "value": 1
        },
        "name": "FontSpacing01",
        "attributes": {
          "category": "font",
          "type": "spacing",
          "item": "01"
        },
        "path": [
          "font",
          "spacing",
          "01"
        ]
      }
    },
    "weight": {
      "light": {
        "value": 400,
        "filePath": "src/properties/typography/weight.json",
        "isSource": true,
        "original": {
          "value": 400
        },
        "name": "FontWeightLight",
        "attributes": {
          "category": "font",
          "type": "weight",
          "item": "light"
        },
        "path": [
          "font",
          "weight",
          "light"
        ]
      },
      "regular": {
        "value": 400,
        "filePath": "src/properties/typography/weight.json",
        "isSource": true,
        "original": {
          "value": 400
        },
        "name": "FontWeightRegular",
        "attributes": {
          "category": "font",
          "type": "weight",
          "item": "regular"
        },
        "path": [
          "font",
          "weight",
          "regular"
        ]
      },
      "semi-bold": {
        "value": 600,
        "filePath": "src/properties/typography/weight.json",
        "isSource": true,
        "original": {
          "value": 600
        },
        "name": "FontWeightSemiBold",
        "attributes": {
          "category": "font",
          "type": "weight",
          "item": "semi-bold"
        },
        "path": [
          "font",
          "weight",
          "semi-bold"
        ]
      },
      "bold": {
        "value": 600,
        "filePath": "src/properties/typography/weight.json",
        "isSource": true,
        "original": {
          "value": 600
        },
        "name": "FontWeightBold",
        "attributes": {
          "category": "font",
          "type": "weight",
          "item": "bold"
        },
        "path": [
          "font",
          "weight",
          "bold"
        ]
      },
      "xbold": {
        "value": 600,
        "filePath": "src/properties/typography/weight.json",
        "isSource": true,
        "original": {
          "value": 600
        },
        "name": "FontWeightXbold",
        "attributes": {
          "category": "font",
          "type": "weight",
          "item": "xbold"
        },
        "path": [
          "font",
          "weight",
          "xbold"
        ]
      }
    }
  },
  "zIndices": {
    "hide": {
      "value": -1,
      "filePath": "src/properties/zindex.json",
      "isSource": true,
      "original": {
        "value": -1
      },
      "name": "ZIndicesHide",
      "attributes": {
        "category": "zIndices",
        "type": "hide"
      },
      "path": [
        "zIndices",
        "hide"
      ]
    },
    "auto": {
      "value": "auto",
      "filePath": "src/properties/zindex.json",
      "isSource": true,
      "original": {
        "value": "auto"
      },
      "name": "ZIndicesAuto",
      "attributes": {
        "category": "zIndices",
        "type": "auto"
      },
      "path": [
        "zIndices",
        "auto"
      ]
    },
    "base": {
      "value": 0,
      "filePath": "src/properties/zindex.json",
      "isSource": true,
      "original": {
        "value": 0
      },
      "name": "ZIndicesBase",
      "attributes": {
        "category": "zIndices",
        "type": "base"
      },
      "path": [
        "zIndices",
        "base"
      ]
    },
    "docked": {
      "value": 10,
      "filePath": "src/properties/zindex.json",
      "isSource": true,
      "original": {
        "value": 10
      },
      "name": "ZIndicesDocked",
      "attributes": {
        "category": "zIndices",
        "type": "docked"
      },
      "path": [
        "zIndices",
        "docked"
      ]
    },
    "sticky": {
      "value": 1100,
      "filePath": "src/properties/zindex.json",
      "isSource": true,
      "original": {
        "value": 1100
      },
      "name": "ZIndicesSticky",
      "attributes": {
        "category": "zIndices",
        "type": "sticky"
      },
      "path": [
        "zIndices",
        "sticky"
      ]
    },
    "banner": {
      "value": 1200,
      "filePath": "src/properties/zindex.json",
      "isSource": true,
      "original": {
        "value": 1200
      },
      "name": "ZIndicesBanner",
      "attributes": {
        "category": "zIndices",
        "type": "banner"
      },
      "path": [
        "zIndices",
        "banner"
      ]
    },
    "overlay": {
      "value": 1300,
      "filePath": "src/properties/zindex.json",
      "isSource": true,
      "original": {
        "value": 1300
      },
      "name": "ZIndicesOverlay",
      "attributes": {
        "category": "zIndices",
        "type": "overlay"
      },
      "path": [
        "zIndices",
        "overlay"
      ]
    },
    "flyout": {
      "value": 1400,
      "filePath": "src/properties/zindex.json",
      "isSource": true,
      "original": {
        "value": 1400
      },
      "name": "ZIndicesFlyout",
      "attributes": {
        "category": "zIndices",
        "type": "flyout"
      },
      "path": [
        "zIndices",
        "flyout"
      ]
    },
    "skipLink": {
      "value": 1500,
      "filePath": "src/properties/zindex.json",
      "isSource": true,
      "original": {
        "value": 1500
      },
      "name": "ZIndicesSkipLink",
      "attributes": {
        "category": "zIndices",
        "type": "skipLink"
      },
      "path": [
        "zIndices",
        "skipLink"
      ]
    },
    "tooltip": {
      "value": 1600,
      "filePath": "src/properties/zindex.json",
      "isSource": true,
      "original": {
        "value": 1600
      },
      "name": "ZIndicesTooltip",
      "attributes": {
        "category": "zIndices",
        "type": "tooltip"
      },
      "path": [
        "zIndices",
        "tooltip"
      ]
    },
    "popover": {
      "value": 1700,
      "filePath": "src/properties/zindex.json",
      "isSource": true,
      "original": {
        "value": 1700
      },
      "name": "ZIndicesPopover",
      "attributes": {
        "category": "zIndices",
        "type": "popover"
      },
      "path": [
        "zIndices",
        "popover"
      ]
    },
    "dropdown": {
      "value": 1800,
      "filePath": "src/properties/zindex.json",
      "isSource": true,
      "original": {
        "value": 1800
      },
      "name": "ZIndicesDropdown",
      "attributes": {
        "category": "zIndices",
        "type": "dropdown"
      },
      "path": [
        "zIndices",
        "dropdown"
      ]
    },
    "modal": {
      "value": 1900,
      "filePath": "src/properties/zindex.json",
      "isSource": true,
      "original": {
        "value": 1900
      },
      "name": "ZIndicesModal",
      "attributes": {
        "category": "zIndices",
        "type": "modal"
      },
      "path": [
        "zIndices",
        "modal"
      ]
    },
    "toast": {
      "value": 2000,
      "filePath": "src/properties/zindex.json",
      "isSource": true,
      "original": {
        "value": 2000
      },
      "name": "ZIndicesToast",
      "attributes": {
        "category": "zIndices",
        "type": "toast"
      },
      "path": [
        "zIndices",
        "toast"
      ]
    }
  },
  "typography": {
    "body": {
      "regular": {
        "font-family": {
          "value": "proxima-nova,Arial,sans-serif",
          "filePath": "src/classes/typography/body.json",
          "isSource": true,
          "original": {
            "value": "{font.family.text.regular.value}"
          },
          "name": "TypographyBodyRegularFontFamily",
          "attributes": {
            "category": "typography",
            "type": "body",
            "item": "regular",
            "subitem": "font-family"
          },
          "path": [
            "typography",
            "body",
            "regular",
            "font-family"
          ]
        },
        "font-weight": {
          "value": 400,
          "filePath": "src/classes/typography/body.json",
          "isSource": true,
          "original": {
            "value": "{font.weight.regular.value}"
          },
          "name": "TypographyBodyRegularFontWeight",
          "attributes": {
            "category": "typography",
            "type": "body",
            "item": "regular",
            "subitem": "font-weight"
          },
          "path": [
            "typography",
            "body",
            "regular",
            "font-weight"
          ]
        },
        "font-size": {
          "value": "0.875rem",
          "filePath": "src/classes/typography/body.json",
          "isSource": true,
          "original": {
            "value": "{font.size.03.value}"
          },
          "name": "TypographyBodyRegularFontSize",
          "attributes": {
            "category": "typography",
            "type": "body",
            "item": "regular",
            "subitem": "font-size"
          },
          "path": [
            "typography",
            "body",
            "regular",
            "font-size"
          ]
        },
        "line-height": {
          "value": "1.25rem",
          "filePath": "src/classes/typography/body.json",
          "isSource": true,
          "original": {
            "value": "{font.line-height.02.value}"
          },
          "name": "TypographyBodyRegularLineHeight",
          "attributes": {
            "category": "typography",
            "type": "body",
            "item": "regular",
            "subitem": "line-height"
          },
          "path": [
            "typography",
            "body",
            "regular",
            "line-height"
          ]
        }
      },
      "em": {
        "font-family": {
          "value": "proxima-nova,Arial,sans-serif",
          "filePath": "src/classes/typography/body.json",
          "isSource": true,
          "original": {
            "value": "{typography.body.regular.font-family.value}"
          },
          "name": "TypographyBodyEmFontFamily",
          "attributes": {
            "category": "typography",
            "type": "body",
            "item": "em",
            "subitem": "font-family"
          },
          "path": [
            "typography",
            "body",
            "em",
            "font-family"
          ]
        },
        "font-weight": {
          "value": 600,
          "filePath": "src/classes/typography/body.json",
          "isSource": true,
          "original": {
            "value": "{font.weight.semi-bold.value}"
          },
          "name": "TypographyBodyEmFontWeight",
          "attributes": {
            "category": "typography",
            "type": "body",
            "item": "em",
            "subitem": "font-weight"
          },
          "path": [
            "typography",
            "body",
            "em",
            "font-weight"
          ]
        },
        "font-size": {
          "value": "0.875rem",
          "filePath": "src/classes/typography/body.json",
          "isSource": true,
          "original": {
            "value": "{typography.body.regular.font-size.value}"
          },
          "name": "TypographyBodyEmFontSize",
          "attributes": {
            "category": "typography",
            "type": "body",
            "item": "em",
            "subitem": "font-size"
          },
          "path": [
            "typography",
            "body",
            "em",
            "font-size"
          ]
        },
        "line-height": {
          "value": "1.25rem",
          "filePath": "src/classes/typography/body.json",
          "isSource": true,
          "original": {
            "value": "{typography.body.regular.line-height.value}"
          },
          "name": "TypographyBodyEmLineHeight",
          "attributes": {
            "category": "typography",
            "type": "body",
            "item": "em",
            "subitem": "line-height"
          },
          "path": [
            "typography",
            "body",
            "em",
            "line-height"
          ]
        }
      },
      "small": {
        "font-family": {
          "value": "proxima-nova,Arial,sans-serif",
          "filePath": "src/classes/typography/body.json",
          "isSource": true,
          "original": {
            "value": "{font.family.text.regular.value}"
          },
          "name": "TypographyBodySmallFontFamily",
          "attributes": {
            "category": "typography",
            "type": "body",
            "item": "small",
            "subitem": "font-family"
          },
          "path": [
            "typography",
            "body",
            "small",
            "font-family"
          ]
        },
        "font-weight": {
          "value": 400,
          "filePath": "src/classes/typography/body.json",
          "isSource": true,
          "original": {
            "value": "{font.weight.regular.value}"
          },
          "name": "TypographyBodySmallFontWeight",
          "attributes": {
            "category": "typography",
            "type": "body",
            "item": "small",
            "subitem": "font-weight"
          },
          "path": [
            "typography",
            "body",
            "small",
            "font-weight"
          ]
        },
        "font-size": {
          "value": "0.8125rem",
          "filePath": "src/classes/typography/body.json",
          "isSource": true,
          "original": {
            "value": "{font.size.02.value}"
          },
          "name": "TypographyBodySmallFontSize",
          "attributes": {
            "category": "typography",
            "type": "body",
            "item": "small",
            "subitem": "font-size"
          },
          "path": [
            "typography",
            "body",
            "small",
            "font-size"
          ]
        },
        "line-height": {
          "value": "1rem",
          "filePath": "src/classes/typography/body.json",
          "isSource": true,
          "original": {
            "value": "{font.line-height.01.value}"
          },
          "name": "TypographyBodySmallLineHeight",
          "attributes": {
            "category": "typography",
            "type": "body",
            "item": "small",
            "subitem": "line-height"
          },
          "path": [
            "typography",
            "body",
            "small",
            "line-height"
          ]
        }
      },
      "small-em": {
        "font-family": {
          "value": "proxima-nova,Arial,sans-serif",
          "filePath": "src/classes/typography/body.json",
          "isSource": true,
          "original": {
            "value": "{typography.body.small.font-family.value}"
          },
          "name": "TypographyBodySmallEmFontFamily",
          "attributes": {
            "category": "typography",
            "type": "body",
            "item": "small-em",
            "subitem": "font-family"
          },
          "path": [
            "typography",
            "body",
            "small-em",
            "font-family"
          ]
        },
        "font-weight": {
          "value": 600,
          "filePath": "src/classes/typography/body.json",
          "isSource": true,
          "original": {
            "value": "{font.weight.semi-bold.value}"
          },
          "name": "TypographyBodySmallEmFontWeight",
          "attributes": {
            "category": "typography",
            "type": "body",
            "item": "small-em",
            "subitem": "font-weight"
          },
          "path": [
            "typography",
            "body",
            "small-em",
            "font-weight"
          ]
        },
        "font-size": {
          "value": "0.8125rem",
          "filePath": "src/classes/typography/body.json",
          "isSource": true,
          "original": {
            "value": "{typography.body.small.font-size.value}"
          },
          "name": "TypographyBodySmallEmFontSize",
          "attributes": {
            "category": "typography",
            "type": "body",
            "item": "small-em",
            "subitem": "font-size"
          },
          "path": [
            "typography",
            "body",
            "small-em",
            "font-size"
          ]
        },
        "line-height": {
          "value": "1rem",
          "filePath": "src/classes/typography/body.json",
          "isSource": true,
          "original": {
            "value": "{typography.body.small.line-height.value}"
          },
          "name": "TypographyBodySmallEmLineHeight",
          "attributes": {
            "category": "typography",
            "type": "body",
            "item": "small-em",
            "subitem": "line-height"
          },
          "path": [
            "typography",
            "body",
            "small-em",
            "line-height"
          ]
        }
      },
      "small-spaced": {
        "font-family": {
          "value": "proxima-nova,Arial,sans-serif",
          "filePath": "src/classes/typography/body.json",
          "isSource": true,
          "original": {
            "value": "{font.family.text.regular.value}"
          },
          "name": "TypographyBodySmallSpacedFontFamily",
          "attributes": {
            "category": "typography",
            "type": "body",
            "item": "small-spaced",
            "subitem": "font-family"
          },
          "path": [
            "typography",
            "body",
            "small-spaced",
            "font-family"
          ]
        },
        "font-weight": {
          "value": 400,
          "filePath": "src/classes/typography/body.json",
          "isSource": true,
          "original": {
            "value": "{font.weight.regular.value}"
          },
          "name": "TypographyBodySmallSpacedFontWeight",
          "attributes": {
            "category": "typography",
            "type": "body",
            "item": "small-spaced",
            "subitem": "font-weight"
          },
          "path": [
            "typography",
            "body",
            "small-spaced",
            "font-weight"
          ]
        },
        "font-size": {
          "value": "0.8125rem",
          "filePath": "src/classes/typography/body.json",
          "isSource": true,
          "original": {
            "value": "{font.size.02.value}"
          },
          "name": "TypographyBodySmallSpacedFontSize",
          "attributes": {
            "category": "typography",
            "type": "body",
            "item": "small-spaced",
            "subitem": "font-size"
          },
          "path": [
            "typography",
            "body",
            "small-spaced",
            "font-size"
          ]
        },
        "line-height": {
          "value": "1.25rem",
          "filePath": "src/classes/typography/body.json",
          "isSource": true,
          "original": {
            "value": "{font.line-height.02.value}"
          },
          "name": "TypographyBodySmallSpacedLineHeight",
          "attributes": {
            "category": "typography",
            "type": "body",
            "item": "small-spaced",
            "subitem": "line-height"
          },
          "path": [
            "typography",
            "body",
            "small-spaced",
            "line-height"
          ]
        }
      },
      "small-spaced-em": {
        "font-family": {
          "value": "proxima-nova,Arial,sans-serif",
          "filePath": "src/classes/typography/body.json",
          "isSource": true,
          "original": {
            "value": "{font.family.text.regular.value}"
          },
          "name": "TypographyBodySmallSpacedEmFontFamily",
          "attributes": {
            "category": "typography",
            "type": "body",
            "item": "small-spaced-em",
            "subitem": "font-family"
          },
          "path": [
            "typography",
            "body",
            "small-spaced-em",
            "font-family"
          ]
        },
        "font-weight": {
          "value": 600,
          "filePath": "src/classes/typography/body.json",
          "isSource": true,
          "original": {
            "value": "{font.weight.semi-bold.value}"
          },
          "name": "TypographyBodySmallSpacedEmFontWeight",
          "attributes": {
            "category": "typography",
            "type": "body",
            "item": "small-spaced-em",
            "subitem": "font-weight"
          },
          "path": [
            "typography",
            "body",
            "small-spaced-em",
            "font-weight"
          ]
        },
        "font-size": {
          "value": "0.8125rem",
          "filePath": "src/classes/typography/body.json",
          "isSource": true,
          "original": {
            "value": "{font.size.02.value}"
          },
          "name": "TypographyBodySmallSpacedEmFontSize",
          "attributes": {
            "category": "typography",
            "type": "body",
            "item": "small-spaced-em",
            "subitem": "font-size"
          },
          "path": [
            "typography",
            "body",
            "small-spaced-em",
            "font-size"
          ]
        },
        "line-height": {
          "value": "1.25rem",
          "filePath": "src/classes/typography/body.json",
          "isSource": true,
          "original": {
            "value": "{font.line-height.02.value}"
          },
          "name": "TypographyBodySmallSpacedEmLineHeight",
          "attributes": {
            "category": "typography",
            "type": "body",
            "item": "small-spaced-em",
            "subitem": "line-height"
          },
          "path": [
            "typography",
            "body",
            "small-spaced-em",
            "line-height"
          ]
        }
      },
      "large": {
        "font-family": {
          "value": "proxima-nova,Arial,sans-serif",
          "filePath": "src/classes/typography/body.json",
          "isSource": true,
          "original": {
            "value": "{font.family.text.regular.value}"
          },
          "name": "TypographyBodyLargeFontFamily",
          "attributes": {
            "category": "typography",
            "type": "body",
            "item": "large",
            "subitem": "font-family"
          },
          "path": [
            "typography",
            "body",
            "large",
            "font-family"
          ]
        },
        "font-weight": {
          "value": 400,
          "filePath": "src/classes/typography/body.json",
          "isSource": true,
          "original": {
            "value": "{font.weight.regular.value}"
          },
          "name": "TypographyBodyLargeFontWeight",
          "attributes": {
            "category": "typography",
            "type": "body",
            "item": "large",
            "subitem": "font-weight"
          },
          "path": [
            "typography",
            "body",
            "large",
            "font-weight"
          ]
        },
        "font-size": {
          "value": "1rem",
          "filePath": "src/classes/typography/body.json",
          "isSource": true,
          "original": {
            "value": "{font.size.04.value}"
          },
          "name": "TypographyBodyLargeFontSize",
          "attributes": {
            "category": "typography",
            "type": "body",
            "item": "large",
            "subitem": "font-size"
          },
          "path": [
            "typography",
            "body",
            "large",
            "font-size"
          ]
        },
        "line-height": {
          "value": "1.5rem",
          "filePath": "src/classes/typography/body.json",
          "isSource": true,
          "original": {
            "value": "{font.line-height.03.value}"
          },
          "name": "TypographyBodyLargeLineHeight",
          "attributes": {
            "category": "typography",
            "type": "body",
            "item": "large",
            "subitem": "line-height"
          },
          "path": [
            "typography",
            "body",
            "large",
            "line-height"
          ]
        }
      },
      "large-em": {
        "font-family": {
          "value": "proxima-nova,Arial,sans-serif",
          "filePath": "src/classes/typography/body.json",
          "isSource": true,
          "original": {
            "value": "{typography.body.large.font-family.value}"
          },
          "name": "TypographyBodyLargeEmFontFamily",
          "attributes": {
            "category": "typography",
            "type": "body",
            "item": "large-em",
            "subitem": "font-family"
          },
          "path": [
            "typography",
            "body",
            "large-em",
            "font-family"
          ]
        },
        "font-weight": {
          "value": 600,
          "filePath": "src/classes/typography/body.json",
          "isSource": true,
          "original": {
            "value": "{font.weight.semi-bold.value}"
          },
          "name": "TypographyBodyLargeEmFontWeight",
          "attributes": {
            "category": "typography",
            "type": "body",
            "item": "large-em",
            "subitem": "font-weight"
          },
          "path": [
            "typography",
            "body",
            "large-em",
            "font-weight"
          ]
        },
        "font-size": {
          "value": "1rem",
          "filePath": "src/classes/typography/body.json",
          "isSource": true,
          "original": {
            "value": "{typography.body.large.font-size.value}"
          },
          "name": "TypographyBodyLargeEmFontSize",
          "attributes": {
            "category": "typography",
            "type": "body",
            "item": "large-em",
            "subitem": "font-size"
          },
          "path": [
            "typography",
            "body",
            "large-em",
            "font-size"
          ]
        },
        "line-height": {
          "value": "1.5rem",
          "filePath": "src/classes/typography/body.json",
          "isSource": true,
          "original": {
            "value": "{typography.body.large.line-height.value}"
          },
          "name": "TypographyBodyLargeEmLineHeight",
          "attributes": {
            "category": "typography",
            "type": "body",
            "item": "large-em",
            "subitem": "line-height"
          },
          "path": [
            "typography",
            "body",
            "large-em",
            "line-height"
          ]
        }
      }
    },
    "navigation": {
      "font-family": {
        "value": "proxima-nova,Arial,sans-serif",
        "filePath": "src/classes/typography/navigation.json",
        "isSource": true,
        "original": {
          "value": "{font.family.text.bold.value}"
        },
        "name": "TypographyNavigationFontFamily",
        "attributes": {
          "category": "typography",
          "type": "navigation",
          "item": "font-family"
        },
        "path": [
          "typography",
          "navigation",
          "font-family"
        ]
      },
      "font-weight": {
        "value": 600,
        "filePath": "src/classes/typography/navigation.json",
        "isSource": true,
        "original": {
          "value": "{font.weight.semi-bold.value}"
        },
        "name": "TypographyNavigationFontWeight",
        "attributes": {
          "category": "typography",
          "type": "navigation",
          "item": "font-weight"
        },
        "path": [
          "typography",
          "navigation",
          "font-weight"
        ]
      },
      "font-size": {
        "value": "0.875rem",
        "filePath": "src/classes/typography/navigation.json",
        "isSource": true,
        "original": {
          "value": "{font.size.03.value}"
        },
        "name": "TypographyNavigationFontSize",
        "attributes": {
          "category": "typography",
          "type": "navigation",
          "item": "font-size"
        },
        "path": [
          "typography",
          "navigation",
          "font-size"
        ]
      },
      "line-height": {
        "value": "1.25rem",
        "filePath": "src/classes/typography/navigation.json",
        "isSource": true,
        "original": {
          "value": "{font.line-height.02.value}"
        },
        "name": "TypographyNavigationLineHeight",
        "attributes": {
          "category": "typography",
          "type": "navigation",
          "item": "line-height"
        },
        "path": [
          "typography",
          "navigation",
          "line-height"
        ]
      },
      "text-transform": {
        "value": "uppercase",
        "filePath": "src/classes/typography/navigation.json",
        "isSource": true,
        "original": {
          "value": "uppercase"
        },
        "name": "TypographyNavigationTextTransform",
        "attributes": {
          "category": "typography",
          "type": "navigation",
          "item": "text-transform"
        },
        "path": [
          "typography",
          "navigation",
          "text-transform"
        ]
      },
      "letter-spacing": {
        "value": "0.04rem",
        "filePath": "src/classes/typography/navigation.json",
        "isSource": true,
        "original": {
          "value": "0.04rem"
        },
        "name": "TypographyNavigationLetterSpacing",
        "attributes": {
          "category": "typography",
          "type": "navigation",
          "item": "letter-spacing"
        },
        "path": [
          "typography",
          "navigation",
          "letter-spacing"
        ]
      }
    },
    "pre-title": {
      "font-family": {
        "value": "proxima-nova,Arial,sans-serif",
        "filePath": "src/classes/typography/pre-title.json",
        "isSource": true,
        "original": {
          "value": "{font.family.text.bold.value}"
        },
        "name": "TypographyPreTitleFontFamily",
        "attributes": {
          "category": "typography",
          "type": "pre-title",
          "item": "font-family"
        },
        "path": [
          "typography",
          "pre-title",
          "font-family"
        ]
      },
      "font-weight": {
        "value": 600,
        "filePath": "src/classes/typography/pre-title.json",
        "isSource": true,
        "original": {
          "value": "{font.weight.semi-bold.value}"
        },
        "name": "TypographyPreTitleFontWeight",
        "attributes": {
          "category": "typography",
          "type": "pre-title",
          "item": "font-weight"
        },
        "path": [
          "typography",
          "pre-title",
          "font-weight"
        ]
      },
      "font-size": {
        "value": "0.8125rem",
        "filePath": "src/classes/typography/pre-title.json",
        "isSource": true,
        "original": {
          "value": "{font.size.02.value}"
        },
        "name": "TypographyPreTitleFontSize",
        "attributes": {
          "category": "typography",
          "type": "pre-title",
          "item": "font-size"
        },
        "path": [
          "typography",
          "pre-title",
          "font-size"
        ]
      },
      "line-height": {
        "value": "1rem",
        "filePath": "src/classes/typography/pre-title.json",
        "isSource": true,
        "original": {
          "value": "{font.line-height.01.value}"
        },
        "name": "TypographyPreTitleLineHeight",
        "attributes": {
          "category": "typography",
          "type": "pre-title",
          "item": "line-height"
        },
        "path": [
          "typography",
          "pre-title",
          "line-height"
        ]
      },
      "text-transform": {
        "value": "capitalize",
        "filePath": "src/classes/typography/pre-title.json",
        "isSource": true,
        "original": {
          "value": "capitalize"
        },
        "name": "TypographyPreTitleTextTransform",
        "attributes": {
          "category": "typography",
          "type": "pre-title",
          "item": "text-transform"
        },
        "path": [
          "typography",
          "pre-title",
          "text-transform"
        ]
      }
    }
  },
  "container": {
    "xstyled": {
      "mx": {
        "value": "auto",
        "filePath": "src/classes/wrappers/container.json",
        "isSource": true,
        "original": {
          "value": "auto"
        },
        "name": "ContainerXstyledMx",
        "attributes": {
          "category": "container",
          "type": "xstyled",
          "item": "mx"
        },
        "path": [
          "container",
          "xstyled",
          "mx"
        ]
      },
      "container": {
        "value": true,
        "filePath": "src/classes/wrappers/container.json",
        "isSource": true,
        "original": {
          "value": true
        },
        "name": "ContainerXstyledContainer",
        "attributes": {
          "category": "container",
          "type": "xstyled",
          "item": "container"
        },
        "path": [
          "container",
          "xstyled",
          "container"
        ]
      },
      "p": {
        "value": "4px",
        "filePath": "src/classes/wrappers/container.json",
        "isSource": true,
        "original": {
          "value": "{spacing.inner.base.value}"
        },
        "name": "ContainerXstyledP",
        "attributes": {
          "category": "container",
          "type": "xstyled",
          "item": "p"
        },
        "path": [
          "container",
          "xstyled",
          "p"
        ]
      }
    }
  },
  "components": {
    "base": {
      "full-width": {
        "width": {
          "value": "100%",
          "filePath": "src/components/base.json",
          "isSource": true,
          "original": {
            "value": "100%"
          },
          "name": "ComponentsBaseFullWidthWidth",
          "attributes": {
            "category": "components",
            "type": "base",
            "item": "full-width",
            "subitem": "width"
          },
          "path": [
            "components",
            "base",
            "full-width",
            "width"
          ]
        }
      }
    },
    "button": {
      "base-styles": {
        "borderRadius": {
          "_": {
            "value": "4px",
            "filePath": "src/components/button.json",
            "isSource": true,
            "original": {
              "value": "{shape.border.radius.small.value}"
            },
            "name": "ComponentsButtonBaseStylesBorderRadius",
            "attributes": {
              "category": "components",
              "type": "button",
              "item": "base-styles",
              "subitem": "borderRadius",
              "state": "_"
            },
            "path": [
              "components",
              "button",
              "base-styles",
              "borderRadius",
              "_"
            ]
          },
          "hover": {
            "value": "4px",
            "filePath": "src/components/button.json",
            "isSource": true,
            "original": {
              "value": "{shape.border.radius.small.value}"
            },
            "name": "ComponentsButtonBaseStylesBorderRadiusHover",
            "attributes": {
              "category": "components",
              "type": "button",
              "item": "base-styles",
              "subitem": "borderRadius",
              "state": "hover"
            },
            "path": [
              "components",
              "button",
              "base-styles",
              "borderRadius",
              "hover"
            ]
          }
        },
        "cursor": {
          "_": {
            "value": "pointer",
            "filePath": "src/components/button.json",
            "isSource": true,
            "original": {
              "value": "pointer"
            },
            "name": "ComponentsButtonBaseStylesCursor",
            "attributes": {
              "category": "components",
              "type": "button",
              "item": "base-styles",
              "subitem": "cursor",
              "state": "_"
            },
            "path": [
              "components",
              "button",
              "base-styles",
              "cursor",
              "_"
            ]
          },
          "disabled": {
            "value": "not-allowed",
            "filePath": "src/components/button.json",
            "isSource": true,
            "original": {
              "value": "not-allowed"
            },
            "name": "ComponentsButtonBaseStylesCursorDisabled",
            "attributes": {
              "category": "components",
              "type": "button",
              "item": "base-styles",
              "subitem": "cursor",
              "state": "disabled"
            },
            "path": [
              "components",
              "button",
              "base-styles",
              "cursor",
              "disabled"
            ]
          }
        },
        "display": {
          "value": "inline-flex",
          "filePath": "src/components/button.json",
          "isSource": true,
          "original": {
            "value": "inline-flex"
          },
          "name": "ComponentsButtonBaseStylesDisplay",
          "attributes": {
            "category": "components",
            "type": "button",
            "item": "base-styles",
            "subitem": "display"
          },
          "path": [
            "components",
            "button",
            "base-styles",
            "display"
          ]
        },
        "align-items": {
          "value": "center",
          "filePath": "src/components/button.json",
          "isSource": true,
          "original": {
            "value": "center"
          },
          "name": "ComponentsButtonBaseStylesAlignItems",
          "attributes": {
            "category": "components",
            "type": "button",
            "item": "base-styles",
            "subitem": "align-items"
          },
          "path": [
            "components",
            "button",
            "base-styles",
            "align-items"
          ]
        },
        "justify-content": {
          "value": "center",
          "filePath": "src/components/button.json",
          "isSource": true,
          "original": {
            "value": "center"
          },
          "name": "ComponentsButtonBaseStylesJustifyContent",
          "attributes": {
            "category": "components",
            "type": "button",
            "item": "base-styles",
            "subitem": "justify-content"
          },
          "path": [
            "components",
            "button",
            "base-styles",
            "justify-content"
          ]
        },
        "transition": {
          "value": "all 0.2s ease",
          "filePath": "src/components/button.json",
          "isSource": true,
          "original": {
            "value": "all 0.2s ease"
          },
          "name": "ComponentsButtonBaseStylesTransition",
          "attributes": {
            "category": "components",
            "type": "button",
            "item": "base-styles",
            "subitem": "transition"
          },
          "path": [
            "components",
            "button",
            "base-styles",
            "transition"
          ]
        },
        "gap": {
          "value": "8px",
          "filePath": "src/components/button.json",
          "isSource": true,
          "original": {
            "value": "{spacing.inner.base02.value}"
          },
          "name": "ComponentsButtonBaseStylesGap",
          "attributes": {
            "category": "components",
            "type": "button",
            "item": "base-styles",
            "subitem": "gap"
          },
          "path": [
            "components",
            "button",
            "base-styles",
            "gap"
          ]
        },
        "box-sizing": {
          "value": "border-box",
          "filePath": "src/components/button.json",
          "isSource": true,
          "original": {
            "value": "border-box"
          },
          "name": "ComponentsButtonBaseStylesBoxSizing",
          "attributes": {
            "category": "components",
            "type": "button",
            "item": "base-styles",
            "subitem": "box-sizing"
          },
          "path": [
            "components",
            "button",
            "base-styles",
            "box-sizing"
          ]
        },
        "outline": {
          "focus": {
            "value": "none",
            "filePath": "src/components/button.json",
            "isSource": true,
            "original": {
              "value": "none"
            },
            "name": "ComponentsButtonBaseStylesOutlineFocus",
            "attributes": {
              "category": "components",
              "type": "button",
              "item": "base-styles",
              "subitem": "outline",
              "state": "focus"
            },
            "path": [
              "components",
              "button",
              "base-styles",
              "outline",
              "focus"
            ]
          }
        }
      },
      "disabled": {
        "color": {
          "value": "#9ca1b0",
          "filePath": "src/components/button.json",
          "isSource": true,
          "original": {
            "value": "{color.text.disabled}"
          },
          "name": "ComponentsButtonDisabledColor",
          "attributes": {
            "category": "components",
            "type": "button",
            "item": "disabled",
            "subitem": "color"
          },
          "path": [
            "components",
            "button",
            "disabled",
            "color"
          ]
        },
        "outline": {
          "value": "none",
          "filePath": "src/components/button.json",
          "isSource": true,
          "original": {
            "value": "none"
          },
          "name": "ComponentsButtonDisabledOutline",
          "attributes": {
            "category": "components",
            "type": "button",
            "item": "disabled",
            "subitem": "outline"
          },
          "path": [
            "components",
            "button",
            "disabled",
            "outline"
          ]
        },
        "background": {
          "value": "#ebecef",
          "filePath": "src/components/button.json",
          "isSource": true,
          "original": {
            "value": "{color.background.disabled}"
          },
          "name": "ComponentsButtonDisabledBackground",
          "attributes": {
            "category": "components",
            "type": "button",
            "item": "disabled",
            "subitem": "background"
          },
          "path": [
            "components",
            "button",
            "disabled",
            "background"
          ]
        }
      },
      "variant": {
        "primary": {
          "color": {
            "_": {
              "value": "#ffffff",
              "filePath": "src/components/button.json",
              "isSource": true,
              "original": {
                "value": "{color.text.inverse}"
              },
              "name": "ComponentsButtonVariantPrimaryColor",
              "attributes": {
                "category": "components",
                "type": "button",
                "item": "variant",
                "subitem": "primary",
                "state": "color"
              },
              "path": [
                "components",
                "button",
                "variant",
                "primary",
                "color",
                "_"
              ]
            },
            "disabled": {
              "value": "#9ca1b0",
              "filePath": "src/components/button.json",
              "isSource": true,
              "original": {
                "value": "{color.text.disabled}"
              },
              "name": "ComponentsButtonVariantPrimaryColorDisabled",
              "attributes": {
                "category": "components",
                "type": "button",
                "item": "variant",
                "subitem": "primary",
                "state": "color"
              },
              "path": [
                "components",
                "button",
                "variant",
                "primary",
                "color",
                "disabled"
              ]
            }
          },
          "background": {
            "_": {
              "value": "linear-gradient(268.84deg, #e8236a 0%, #fd3001 31.25%, #f97415 100%)",
              "filePath": "src/components/button.json",
              "isSource": true,
              "original": {
                "value": "{color.background.brand.gradient}"
              },
              "name": "ComponentsButtonVariantPrimaryBackground",
              "attributes": {
                "category": "components",
                "type": "button",
                "item": "variant",
                "subitem": "primary",
                "state": "background"
              },
              "path": [
                "components",
                "button",
                "variant",
                "primary",
                "background",
                "_"
              ]
            },
            "hover": {
              "value": "linear-gradient(88.84deg, #e8236a 0%, #fd3001 31.25%, #f97415 100%)",
              "filePath": "src/components/button.json",
              "isSource": true,
              "original": {
                "value": "{color.background.brand.gradient_reverse}"
              },
              "name": "ComponentsButtonVariantPrimaryBackgroundHover",
              "attributes": {
                "category": "components",
                "type": "button",
                "item": "variant",
                "subitem": "primary",
                "state": "background"
              },
              "path": [
                "components",
                "button",
                "variant",
                "primary",
                "background",
                "hover"
              ]
            },
            "disabled": {
              "value": "#ebecef",
              "filePath": "src/components/button.json",
              "isSource": true,
              "original": {
                "value": "{color.background.disabled}"
              },
              "name": "ComponentsButtonVariantPrimaryBackgroundDisabled",
              "attributes": {
                "category": "components",
                "type": "button",
                "item": "variant",
                "subitem": "primary",
                "state": "background"
              },
              "path": [
                "components",
                "button",
                "variant",
                "primary",
                "background",
                "disabled"
              ]
            }
          },
          "outline": {
            "focus": {
              "value": "none",
              "filePath": "src/components/button.json",
              "isSource": true,
              "original": {
                "value": "none"
              },
              "name": "ComponentsButtonVariantPrimaryOutlineFocus",
              "attributes": {
                "category": "components",
                "type": "button",
                "item": "variant",
                "subitem": "primary",
                "state": "outline"
              },
              "path": [
                "components",
                "button",
                "variant",
                "primary",
                "outline",
                "focus"
              ]
            },
            "disabled": {
              "value": "none",
              "filePath": "src/components/button.json",
              "isSource": true,
              "original": {
                "value": "none"
              },
              "name": "ComponentsButtonVariantPrimaryOutlineDisabled",
              "attributes": {
                "category": "components",
                "type": "button",
                "item": "variant",
                "subitem": "primary",
                "state": "outline"
              },
              "path": [
                "components",
                "button",
                "variant",
                "primary",
                "outline",
                "disabled"
              ]
            }
          }
        },
        "secondary": {
          "color": {
            "_": {
              "value": "#ffffff",
              "filePath": "src/components/button.json",
              "isSource": true,
              "original": {
                "value": "{color.text.inverse}"
              },
              "name": "ComponentsButtonVariantSecondaryColor",
              "attributes": {
                "category": "components",
                "type": "button",
                "item": "variant",
                "subitem": "secondary",
                "state": "color"
              },
              "path": [
                "components",
                "button",
                "variant",
                "secondary",
                "color",
                "_"
              ]
            },
            "hover": {
              "value": "#ffffff",
              "filePath": "src/components/button.json",
              "isSource": true,
              "original": {
                "value": "{color.text.inverse}"
              },
              "name": "ComponentsButtonVariantSecondaryColorHover",
              "attributes": {
                "category": "components",
                "type": "button",
                "item": "variant",
                "subitem": "secondary",
                "state": "color"
              },
              "path": [
                "components",
                "button",
                "variant",
                "secondary",
                "color",
                "hover"
              ]
            },
            "disabled": {
              "value": "#9ca1b0",
              "filePath": "src/components/button.json",
              "isSource": true,
              "original": {
                "value": "{color.text.disabled}"
              },
              "name": "ComponentsButtonVariantSecondaryColorDisabled",
              "attributes": {
                "category": "components",
                "type": "button",
                "item": "variant",
                "subitem": "secondary",
                "state": "color"
              },
              "path": [
                "components",
                "button",
                "variant",
                "secondary",
                "color",
                "disabled"
              ]
            }
          },
          "background": {
            "_": {
              "value": "#0d1730",
              "filePath": "src/components/button.json",
              "isSource": true,
              "original": {
                "value": "{color.background.action.secondary.default}"
              },
              "name": "ComponentsButtonVariantSecondaryBackground",
              "attributes": {
                "category": "components",
                "type": "button",
                "item": "variant",
                "subitem": "secondary",
                "state": "background"
              },
              "path": [
                "components",
                "button",
                "variant",
                "secondary",
                "background",
                "_"
              ]
            },
            "hover": {
              "value": "#16171d",
              "filePath": "src/components/button.json",
              "isSource": true,
              "original": {
                "value": "{color.background.action.secondary.hover}"
              },
              "name": "ComponentsButtonVariantSecondaryBackgroundHover",
              "attributes": {
                "category": "components",
                "type": "button",
                "item": "variant",
                "subitem": "secondary",
                "state": "background"
              },
              "path": [
                "components",
                "button",
                "variant",
                "secondary",
                "background",
                "hover"
              ]
            },
            "disabled": {
              "value": "#ebecef",
              "filePath": "src/components/button.json",
              "isSource": true,
              "original": {
                "value": "{color.background.disabled}"
              },
              "name": "ComponentsButtonVariantSecondaryBackgroundDisabled",
              "attributes": {
                "category": "components",
                "type": "button",
                "item": "variant",
                "subitem": "secondary",
                "state": "background"
              },
              "path": [
                "components",
                "button",
                "variant",
                "secondary",
                "background",
                "disabled"
              ]
            }
          },
          "outline": {
            "_": {
              "value": "none",
              "filePath": "src/components/button.json",
              "isSource": true,
              "original": {
                "value": "none"
              },
              "name": "ComponentsButtonVariantSecondaryOutline",
              "attributes": {
                "category": "components",
                "type": "button",
                "item": "variant",
                "subitem": "secondary",
                "state": "outline"
              },
              "path": [
                "components",
                "button",
                "variant",
                "secondary",
                "outline",
                "_"
              ]
            },
            "hover": {
              "value": "none",
              "filePath": "src/components/button.json",
              "isSource": true,
              "original": {
                "value": "none"
              },
              "name": "ComponentsButtonVariantSecondaryOutlineHover",
              "attributes": {
                "category": "components",
                "type": "button",
                "item": "variant",
                "subitem": "secondary",
                "state": "outline"
              },
              "path": [
                "components",
                "button",
                "variant",
                "secondary",
                "outline",
                "hover"
              ]
            },
            "disabled": {
              "value": "none",
              "filePath": "src/components/button.json",
              "isSource": true,
              "original": {
                "value": "none"
              },
              "name": "ComponentsButtonVariantSecondaryOutlineDisabled",
              "attributes": {
                "category": "components",
                "type": "button",
                "item": "variant",
                "subitem": "secondary",
                "state": "outline"
              },
              "path": [
                "components",
                "button",
                "variant",
                "secondary",
                "outline",
                "disabled"
              ]
            },
            "focus": {
              "value": "none",
              "filePath": "src/components/button.json",
              "isSource": true,
              "original": {
                "value": "none"
              },
              "name": "ComponentsButtonVariantSecondaryOutlineFocus",
              "attributes": {
                "category": "components",
                "type": "button",
                "item": "variant",
                "subitem": "secondary",
                "state": "outline"
              },
              "path": [
                "components",
                "button",
                "variant",
                "secondary",
                "outline",
                "focus"
              ]
            }
          }
        },
        "success": {
          "color": {
            "_": {
              "value": "#ffffff",
              "filePath": "src/components/button.json",
              "isSource": true,
              "original": {
                "value": "{color.text.inverse}"
              },
              "name": "ComponentsButtonVariantSuccessColor",
              "attributes": {
                "category": "components",
                "type": "button",
                "item": "variant",
                "subitem": "success",
                "state": "color"
              },
              "path": [
                "components",
                "button",
                "variant",
                "success",
                "color",
                "_"
              ]
            },
            "disabled": {
              "value": "#9ca1b0",
              "filePath": "src/components/button.json",
              "isSource": true,
              "original": {
                "value": "{color.text.disabled}"
              },
              "name": "ComponentsButtonVariantSuccessColorDisabled",
              "attributes": {
                "category": "components",
                "type": "button",
                "item": "variant",
                "subitem": "success",
                "state": "color"
              },
              "path": [
                "components",
                "button",
                "variant",
                "success",
                "color",
                "disabled"
              ]
            }
          },
          "background": {
            "_": {
              "value": "#10b77f",
              "filePath": "src/components/button.json",
              "isSource": true,
              "original": {
                "value": "{color.background.success_strong.default}"
              },
              "name": "ComponentsButtonVariantSuccessBackground",
              "attributes": {
                "category": "components",
                "type": "button",
                "item": "variant",
                "subitem": "success",
                "state": "background"
              },
              "path": [
                "components",
                "button",
                "variant",
                "success",
                "background",
                "_"
              ]
            },
            "hover": {
              "value": "#08875f",
              "filePath": "src/components/button.json",
              "isSource": true,
              "original": {
                "value": "{color.background.success_strong.hover}"
              },
              "name": "ComponentsButtonVariantSuccessBackgroundHover",
              "attributes": {
                "category": "components",
                "type": "button",
                "item": "variant",
                "subitem": "success",
                "state": "background"
              },
              "path": [
                "components",
                "button",
                "variant",
                "success",
                "background",
                "hover"
              ]
            },
            "disabled": {
              "value": "#ebecef",
              "filePath": "src/components/button.json",
              "isSource": true,
              "original": {
                "value": "{color.background.disabled}"
              },
              "name": "ComponentsButtonVariantSuccessBackgroundDisabled",
              "attributes": {
                "category": "components",
                "type": "button",
                "item": "variant",
                "subitem": "success",
                "state": "background"
              },
              "path": [
                "components",
                "button",
                "variant",
                "success",
                "background",
                "disabled"
              ]
            }
          },
          "outline": {
            "disabled": {
              "value": "none",
              "filePath": "src/components/button.json",
              "isSource": true,
              "original": {
                "value": "none"
              },
              "name": "ComponentsButtonVariantSuccessOutlineDisabled",
              "attributes": {
                "category": "components",
                "type": "button",
                "item": "variant",
                "subitem": "success",
                "state": "outline"
              },
              "path": [
                "components",
                "button",
                "variant",
                "success",
                "outline",
                "disabled"
              ]
            },
            "focus": {
              "value": "none",
              "filePath": "src/components/button.json",
              "isSource": true,
              "original": {
                "value": "none"
              },
              "name": "ComponentsButtonVariantSuccessOutlineFocus",
              "attributes": {
                "category": "components",
                "type": "button",
                "item": "variant",
                "subitem": "success",
                "state": "outline"
              },
              "path": [
                "components",
                "button",
                "variant",
                "success",
                "outline",
                "focus"
              ]
            }
          }
        },
        "ghost": {
          "color": {
            "_": {
              "value": "#1e2129",
              "filePath": "src/components/button.json",
              "isSource": true,
              "original": {
                "value": "{color.text.strong._}"
              },
              "name": "ComponentsButtonVariantGhostColor",
              "attributes": {
                "category": "components",
                "type": "button",
                "item": "variant",
                "subitem": "ghost",
                "state": "color"
              },
              "path": [
                "components",
                "button",
                "variant",
                "ghost",
                "color",
                "_"
              ]
            },
            "hover": {
              "value": "#dc2828",
              "filePath": "src/components/button.json",
              "isSource": true,
              "original": {
                "value": "{color.text.strong.hover}"
              },
              "name": "ComponentsButtonVariantGhostColorHover",
              "attributes": {
                "category": "components",
                "type": "button",
                "item": "variant",
                "subitem": "ghost",
                "state": "color"
              },
              "path": [
                "components",
                "button",
                "variant",
                "ghost",
                "color",
                "hover"
              ]
            },
            "focus": {
              "value": "#dc2828",
              "filePath": "src/components/button.json",
              "isSource": true,
              "original": {
                "value": "{color.text.strong.hover}"
              },
              "name": "ComponentsButtonVariantGhostColorFocus",
              "attributes": {
                "category": "components",
                "type": "button",
                "item": "variant",
                "subitem": "ghost",
                "state": "color"
              },
              "path": [
                "components",
                "button",
                "variant",
                "ghost",
                "color",
                "focus"
              ]
            },
            "disabled": {
              "value": "#9ca1b0",
              "filePath": "src/components/button.json",
              "isSource": true,
              "original": {
                "value": "{color.text.disabled}"
              },
              "name": "ComponentsButtonVariantGhostColorDisabled",
              "attributes": {
                "category": "components",
                "type": "button",
                "item": "variant",
                "subitem": "ghost",
                "state": "color"
              },
              "path": [
                "components",
                "button",
                "variant",
                "ghost",
                "color",
                "disabled"
              ]
            }
          },
          "background": {
            "_": {
              "value": "transparent",
              "filePath": "src/components/button.json",
              "isSource": true,
              "original": {
                "value": "transparent"
              },
              "name": "ComponentsButtonVariantGhostBackground",
              "attributes": {
                "category": "components",
                "type": "button",
                "item": "variant",
                "subitem": "ghost",
                "state": "background"
              },
              "path": [
                "components",
                "button",
                "variant",
                "ghost",
                "background",
                "_"
              ]
            },
            "hover": {
              "value": "transparent",
              "filePath": "src/components/button.json",
              "isSource": true,
              "original": {
                "value": "transparent"
              },
              "name": "ComponentsButtonVariantGhostBackgroundHover",
              "attributes": {
                "category": "components",
                "type": "button",
                "item": "variant",
                "subitem": "ghost",
                "state": "background"
              },
              "path": [
                "components",
                "button",
                "variant",
                "ghost",
                "background",
                "hover"
              ]
            },
            "disabled": {
              "value": "transparent",
              "filePath": "src/components/button.json",
              "isSource": true,
              "original": {
                "value": "transparent"
              },
              "name": "ComponentsButtonVariantGhostBackgroundDisabled",
              "attributes": {
                "category": "components",
                "type": "button",
                "item": "variant",
                "subitem": "ghost",
                "state": "background"
              },
              "path": [
                "components",
                "button",
                "variant",
                "ghost",
                "background",
                "disabled"
              ]
            }
          },
          "outline": {
            "disabled": {
              "value": "none",
              "filePath": "src/components/button.json",
              "isSource": true,
              "original": {
                "value": "none"
              },
              "name": "ComponentsButtonVariantGhostOutlineDisabled",
              "attributes": {
                "category": "components",
                "type": "button",
                "item": "variant",
                "subitem": "ghost",
                "state": "outline"
              },
              "path": [
                "components",
                "button",
                "variant",
                "ghost",
                "outline",
                "disabled"
              ]
            },
            "focus": {
              "value": "none",
              "filePath": "src/components/button.json",
              "isSource": true,
              "original": {
                "value": "none"
              },
              "name": "ComponentsButtonVariantGhostOutlineFocus",
              "attributes": {
                "category": "components",
                "type": "button",
                "item": "variant",
                "subitem": "ghost",
                "state": "outline"
              },
              "path": [
                "components",
                "button",
                "variant",
                "ghost",
                "outline",
                "focus"
              ]
            }
          },
          "padding": {
            "value": 0,
            "filePath": "src/components/button.json",
            "isSource": true,
            "original": {
              "value": 0
            },
            "name": "ComponentsButtonVariantGhostPadding",
            "attributes": {
              "category": "components",
              "type": "button",
              "item": "variant",
              "subitem": "ghost",
              "state": "padding"
            },
            "path": [
              "components",
              "button",
              "variant",
              "ghost",
              "padding"
            ]
          }
        },
        "ghost-inverse": {
          "color": {
            "_": {
              "value": "#ffffff",
              "filePath": "src/components/button.json",
              "isSource": true,
              "original": {
                "value": "{color.text.inverse}"
              },
              "name": "ComponentsButtonVariantGhostInverseColor",
              "attributes": {
                "category": "components",
                "type": "button",
                "item": "variant",
                "subitem": "ghost-inverse",
                "state": "color"
              },
              "path": [
                "components",
                "button",
                "variant",
                "ghost-inverse",
                "color",
                "_"
              ]
            },
            "hover": {
              "value": "#dc2828",
              "filePath": "src/components/button.json",
              "isSource": true,
              "original": {
                "value": "{color.text.strong.hover}"
              },
              "name": "ComponentsButtonVariantGhostInverseColorHover",
              "attributes": {
                "category": "components",
                "type": "button",
                "item": "variant",
                "subitem": "ghost-inverse",
                "state": "color"
              },
              "path": [
                "components",
                "button",
                "variant",
                "ghost-inverse",
                "color",
                "hover"
              ]
            },
            "focus": {
              "value": "#dc2828",
              "filePath": "src/components/button.json",
              "isSource": true,
              "original": {
                "value": "{color.text.strong.hover}"
              },
              "name": "ComponentsButtonVariantGhostInverseColorFocus",
              "attributes": {
                "category": "components",
                "type": "button",
                "item": "variant",
                "subitem": "ghost-inverse",
                "state": "color"
              },
              "path": [
                "components",
                "button",
                "variant",
                "ghost-inverse",
                "color",
                "focus"
              ]
            },
            "disabled": {
              "value": "#9ca1b0",
              "filePath": "src/components/button.json",
              "isSource": true,
              "original": {
                "value": "{color.text.disabled}"
              },
              "name": "ComponentsButtonVariantGhostInverseColorDisabled",
              "attributes": {
                "category": "components",
                "type": "button",
                "item": "variant",
                "subitem": "ghost-inverse",
                "state": "color"
              },
              "path": [
                "components",
                "button",
                "variant",
                "ghost-inverse",
                "color",
                "disabled"
              ]
            }
          },
          "background": {
            "_": {
              "value": "transparent",
              "filePath": "src/components/button.json",
              "isSource": true,
              "original": {
                "value": "transparent"
              },
              "name": "ComponentsButtonVariantGhostInverseBackground",
              "attributes": {
                "category": "components",
                "type": "button",
                "item": "variant",
                "subitem": "ghost-inverse",
                "state": "background"
              },
              "path": [
                "components",
                "button",
                "variant",
                "ghost-inverse",
                "background",
                "_"
              ]
            },
            "hover": {
              "value": "transparent",
              "filePath": "src/components/button.json",
              "isSource": true,
              "original": {
                "value": "transparent"
              },
              "name": "ComponentsButtonVariantGhostInverseBackgroundHover",
              "attributes": {
                "category": "components",
                "type": "button",
                "item": "variant",
                "subitem": "ghost-inverse",
                "state": "background"
              },
              "path": [
                "components",
                "button",
                "variant",
                "ghost-inverse",
                "background",
                "hover"
              ]
            },
            "disabled": {
              "value": "transparent",
              "filePath": "src/components/button.json",
              "isSource": true,
              "original": {
                "value": "transparent"
              },
              "name": "ComponentsButtonVariantGhostInverseBackgroundDisabled",
              "attributes": {
                "category": "components",
                "type": "button",
                "item": "variant",
                "subitem": "ghost-inverse",
                "state": "background"
              },
              "path": [
                "components",
                "button",
                "variant",
                "ghost-inverse",
                "background",
                "disabled"
              ]
            }
          },
          "outline": {
            "disabled": {
              "value": "none",
              "filePath": "src/components/button.json",
              "isSource": true,
              "original": {
                "value": "none"
              },
              "name": "ComponentsButtonVariantGhostInverseOutlineDisabled",
              "attributes": {
                "category": "components",
                "type": "button",
                "item": "variant",
                "subitem": "ghost-inverse",
                "state": "outline"
              },
              "path": [
                "components",
                "button",
                "variant",
                "ghost-inverse",
                "outline",
                "disabled"
              ]
            },
            "focus": {
              "value": "none",
              "filePath": "src/components/button.json",
              "isSource": true,
              "original": {
                "value": "none"
              },
              "name": "ComponentsButtonVariantGhostInverseOutlineFocus",
              "attributes": {
                "category": "components",
                "type": "button",
                "item": "variant",
                "subitem": "ghost-inverse",
                "state": "outline"
              },
              "path": [
                "components",
                "button",
                "variant",
                "ghost-inverse",
                "outline",
                "focus"
              ]
            }
          },
          "padding": {
            "value": 0,
            "filePath": "src/components/button.json",
            "isSource": true,
            "original": {
              "value": 0
            },
            "name": "ComponentsButtonVariantGhostInversePadding",
            "attributes": {
              "category": "components",
              "type": "button",
              "item": "variant",
              "subitem": "ghost-inverse",
              "state": "padding"
            },
            "path": [
              "components",
              "button",
              "variant",
              "ghost-inverse",
              "padding"
            ]
          }
        },
        "outline": {
          "color": {
            "_": {
              "value": "#1e2129",
              "filePath": "src/components/button.json",
              "isSource": true,
              "original": {
                "value": "{color.text.strong._}"
              },
              "name": "ComponentsButtonVariantOutlineColor",
              "attributes": {
                "category": "components",
                "type": "button",
                "item": "variant",
                "subitem": "outline",
                "state": "color"
              },
              "path": [
                "components",
                "button",
                "variant",
                "outline",
                "color",
                "_"
              ]
            },
            "focus": {
              "value": "#1e2129",
              "filePath": "src/components/button.json",
              "isSource": true,
              "original": {
                "value": "{color.text.strong._}"
              },
              "name": "ComponentsButtonVariantOutlineColorFocus",
              "attributes": {
                "category": "components",
                "type": "button",
                "item": "variant",
                "subitem": "outline",
                "state": "color"
              },
              "path": [
                "components",
                "button",
                "variant",
                "outline",
                "color",
                "focus"
              ]
            },
            "hover": {
              "value": "#1e2129",
              "filePath": "src/components/button.json",
              "isSource": true,
              "original": {
                "value": "{color.text.strong._}"
              },
              "name": "ComponentsButtonVariantOutlineColorHover",
              "attributes": {
                "category": "components",
                "type": "button",
                "item": "variant",
                "subitem": "outline",
                "state": "color"
              },
              "path": [
                "components",
                "button",
                "variant",
                "outline",
                "color",
                "hover"
              ]
            },
            "disabled": {
              "value": "#9ca1b0",
              "filePath": "src/components/button.json",
              "isSource": true,
              "original": {
                "value": "{color.text.disabled}"
              },
              "name": "ComponentsButtonVariantOutlineColorDisabled",
              "attributes": {
                "category": "components",
                "type": "button",
                "item": "variant",
                "subitem": "outline",
                "state": "color"
              },
              "path": [
                "components",
                "button",
                "variant",
                "outline",
                "color",
                "disabled"
              ]
            }
          },
          "background": {
            "_": {
              "value": "#ffffff",
              "filePath": "src/components/button.json",
              "isSource": true,
              "original": {
                "value": "{color.background.action.outline.default}"
              },
              "name": "ComponentsButtonVariantOutlineBackground",
              "attributes": {
                "category": "components",
                "type": "button",
                "item": "variant",
                "subitem": "outline",
                "state": "background"
              },
              "path": [
                "components",
                "button",
                "variant",
                "outline",
                "background",
                "_"
              ]
            },
            "hover": {
              "value": "#f6f7f9",
              "filePath": "src/components/button.json",
              "isSource": true,
              "original": {
                "value": "{color.background.action.outline.hover}"
              },
              "name": "ComponentsButtonVariantOutlineBackgroundHover",
              "attributes": {
                "category": "components",
                "type": "button",
                "item": "variant",
                "subitem": "outline",
                "state": "background"
              },
              "path": [
                "components",
                "button",
                "variant",
                "outline",
                "background",
                "hover"
              ]
            }
          },
          "outline": {
            "_": {
              "value": "1px solid #e2e4e9",
              "filePath": "src/components/button.json",
              "isSource": true,
              "original": {
                "value": "{shape.border.width.sm.value} solid {color.border.neutral.default}"
              },
              "name": "ComponentsButtonVariantOutlineOutline",
              "attributes": {
                "category": "components",
                "type": "button",
                "item": "variant",
                "subitem": "outline",
                "state": "outline"
              },
              "path": [
                "components",
                "button",
                "variant",
                "outline",
                "outline",
                "_"
              ]
            },
            "hover": {
              "value": "1px solid #e2e4e9",
              "filePath": "src/components/button.json",
              "isSource": true,
              "original": {
                "value": "{shape.border.width.sm.value} solid {color.border.neutral.default}"
              },
              "name": "ComponentsButtonVariantOutlineOutlineHover",
              "attributes": {
                "category": "components",
                "type": "button",
                "item": "variant",
                "subitem": "outline",
                "state": "outline"
              },
              "path": [
                "components",
                "button",
                "variant",
                "outline",
                "outline",
                "hover"
              ]
            },
            "focus": {
              "value": "1px solid #e2e4e9",
              "filePath": "src/components/button.json",
              "isSource": true,
              "original": {
                "value": "{shape.border.width.sm.value} solid {color.border.neutral.default}"
              },
              "name": "ComponentsButtonVariantOutlineOutlineFocus",
              "attributes": {
                "category": "components",
                "type": "button",
                "item": "variant",
                "subitem": "outline",
                "state": "outline"
              },
              "path": [
                "components",
                "button",
                "variant",
                "outline",
                "outline",
                "focus"
              ]
            }
          }
        },
        "icon": {
          "color": {
            "_": {
              "value": "#666b7a",
              "filePath": "src/components/button.json",
              "isSource": true,
              "original": {
                "value": "{color.icon.secondary}"
              },
              "name": "ComponentsButtonVariantIconColor",
              "attributes": {
                "category": "components",
                "type": "button",
                "item": "variant",
                "subitem": "icon",
                "state": "color"
              },
              "path": [
                "components",
                "button",
                "variant",
                "icon",
                "color",
                "_"
              ]
            },
            "hover": {
              "value": "#dc2828",
              "filePath": "src/components/button.json",
              "isSource": true,
              "original": {
                "value": "{color.icon.strong.hover}"
              },
              "name": "ComponentsButtonVariantIconColorHover",
              "attributes": {
                "category": "components",
                "type": "button",
                "item": "variant",
                "subitem": "icon",
                "state": "color"
              },
              "path": [
                "components",
                "button",
                "variant",
                "icon",
                "color",
                "hover"
              ]
            },
            "focus": {
              "value": "#1d4fd7",
              "filePath": "src/components/button.json",
              "isSource": true,
              "original": {
                "value": "{color.base.blue.700.value}"
              },
              "name": "ComponentsButtonVariantIconColorFocus",
              "attributes": {
                "category": "components",
                "type": "button",
                "item": "variant",
                "subitem": "icon",
                "state": "color"
              },
              "path": [
                "components",
                "button",
                "variant",
                "icon",
                "color",
                "focus"
              ]
            },
            "disabled": {
              "value": "#9ca1b0",
              "filePath": "src/components/button.json",
              "isSource": true,
              "original": {
                "value": "{color.text.disabled}"
              },
              "name": "ComponentsButtonVariantIconColorDisabled",
              "attributes": {
                "category": "components",
                "type": "button",
                "item": "variant",
                "subitem": "icon",
                "state": "color"
              },
              "path": [
                "components",
                "button",
                "variant",
                "icon",
                "color",
                "disabled"
              ]
            }
          },
          "background": {
            "_": {
              "value": "transparent",
              "filePath": "src/components/button.json",
              "isSource": true,
              "original": {
                "value": "transparent"
              },
              "name": "ComponentsButtonVariantIconBackground",
              "attributes": {
                "category": "components",
                "type": "button",
                "item": "variant",
                "subitem": "icon",
                "state": "background"
              },
              "path": [
                "components",
                "button",
                "variant",
                "icon",
                "background",
                "_"
              ]
            },
            "disabled": {
              "value": "#ebecef",
              "filePath": "src/components/button.json",
              "isSource": true,
              "original": {
                "value": "{color.background.disabled}"
              },
              "name": "ComponentsButtonVariantIconBackgroundDisabled",
              "attributes": {
                "category": "components",
                "type": "button",
                "item": "variant",
                "subitem": "icon",
                "state": "background"
              },
              "path": [
                "components",
                "button",
                "variant",
                "icon",
                "background",
                "disabled"
              ]
            }
          },
          "outline": {
            "disabled": {
              "value": "none",
              "filePath": "src/components/button.json",
              "isSource": true,
              "original": {
                "value": "none"
              },
              "name": "ComponentsButtonVariantIconOutlineDisabled",
              "attributes": {
                "category": "components",
                "type": "button",
                "item": "variant",
                "subitem": "icon",
                "state": "outline"
              },
              "path": [
                "components",
                "button",
                "variant",
                "icon",
                "outline",
                "disabled"
              ]
            },
            "focus": {
              "value": "none",
              "filePath": "src/components/button.json",
              "isSource": true,
              "original": {
                "value": "none"
              },
              "name": "ComponentsButtonVariantIconOutlineFocus",
              "attributes": {
                "category": "components",
                "type": "button",
                "item": "variant",
                "subitem": "icon",
                "state": "outline"
              },
              "path": [
                "components",
                "button",
                "variant",
                "icon",
                "outline",
                "focus"
              ]
            }
          },
          "padding": {
            "value": 0,
            "filePath": "src/components/button.json",
            "isSource": true,
            "original": {
              "value": 0
            },
            "name": "ComponentsButtonVariantIconPadding",
            "attributes": {
              "category": "components",
              "type": "button",
              "item": "variant",
              "subitem": "icon",
              "state": "padding"
            },
            "path": [
              "components",
              "button",
              "variant",
              "icon",
              "padding"
            ]
          }
        },
        "link": {
          "color": {
            "_": {
              "value": "#666b7a",
              "filePath": "src/components/button.json",
              "isSource": true,
              "original": {
                "value": "{color.text.secondary}"
              },
              "name": "ComponentsButtonVariantLinkColor",
              "attributes": {
                "category": "components",
                "type": "button",
                "item": "variant",
                "subitem": "link",
                "state": "color"
              },
              "path": [
                "components",
                "button",
                "variant",
                "link",
                "color",
                "_"
              ]
            },
            "hover": {
              "value": "#dc2828",
              "filePath": "src/components/button.json",
              "isSource": true,
              "original": {
                "value": "{color.text.strong.hover}"
              },
              "name": "ComponentsButtonVariantLinkColorHover",
              "attributes": {
                "category": "components",
                "type": "button",
                "item": "variant",
                "subitem": "link",
                "state": "color"
              },
              "path": [
                "components",
                "button",
                "variant",
                "link",
                "color",
                "hover"
              ]
            },
            "focus": {
              "value": "#1d4fd7",
              "filePath": "src/components/button.json",
              "isSource": true,
              "original": {
                "value": "{color.base.blue.700.value}"
              },
              "name": "ComponentsButtonVariantLinkColorFocus",
              "attributes": {
                "category": "components",
                "type": "button",
                "item": "variant",
                "subitem": "link",
                "state": "color"
              },
              "path": [
                "components",
                "button",
                "variant",
                "link",
                "color",
                "focus"
              ]
            },
            "disabled": {
              "value": "#9ca1b0",
              "filePath": "src/components/button.json",
              "isSource": true,
              "original": {
                "value": "{color.text.disabled}"
              },
              "name": "ComponentsButtonVariantLinkColorDisabled",
              "attributes": {
                "category": "components",
                "type": "button",
                "item": "variant",
                "subitem": "link",
                "state": "color"
              },
              "path": [
                "components",
                "button",
                "variant",
                "link",
                "color",
                "disabled"
              ]
            }
          },
          "background": {
            "_": {
              "value": "transparent",
              "filePath": "src/components/button.json",
              "isSource": true,
              "original": {
                "value": "transparent"
              },
              "name": "ComponentsButtonVariantLinkBackground",
              "attributes": {
                "category": "components",
                "type": "button",
                "item": "variant",
                "subitem": "link",
                "state": "background"
              },
              "path": [
                "components",
                "button",
                "variant",
                "link",
                "background",
                "_"
              ]
            },
            "disabled": {
              "value": "#ebecef",
              "filePath": "src/components/button.json",
              "isSource": true,
              "original": {
                "value": "{color.background.disabled}"
              },
              "name": "ComponentsButtonVariantLinkBackgroundDisabled",
              "attributes": {
                "category": "components",
                "type": "button",
                "item": "variant",
                "subitem": "link",
                "state": "background"
              },
              "path": [
                "components",
                "button",
                "variant",
                "link",
                "background",
                "disabled"
              ]
            }
          },
          "outline": {
            "disabled": {
              "value": "none",
              "filePath": "src/components/button.json",
              "isSource": true,
              "original": {
                "value": "none"
              },
              "name": "ComponentsButtonVariantLinkOutlineDisabled",
              "attributes": {
                "category": "components",
                "type": "button",
                "item": "variant",
                "subitem": "link",
                "state": "outline"
              },
              "path": [
                "components",
                "button",
                "variant",
                "link",
                "outline",
                "disabled"
              ]
            },
            "focus": {
              "value": "none",
              "filePath": "src/components/button.json",
              "isSource": true,
              "original": {
                "value": "none"
              },
              "name": "ComponentsButtonVariantLinkOutlineFocus",
              "attributes": {
                "category": "components",
                "type": "button",
                "item": "variant",
                "subitem": "link",
                "state": "outline"
              },
              "path": [
                "components",
                "button",
                "variant",
                "link",
                "outline",
                "focus"
              ]
            }
          },
          "padding": {
            "value": 0,
            "filePath": "src/components/button.json",
            "isSource": true,
            "original": {
              "value": 0
            },
            "name": "ComponentsButtonVariantLinkPadding",
            "attributes": {
              "category": "components",
              "type": "button",
              "item": "variant",
              "subitem": "link",
              "state": "padding"
            },
            "path": [
              "components",
              "button",
              "variant",
              "link",
              "padding"
            ]
          }
        }
      },
      "icon": {
        "small": {
          "font-size": {
            "value": "16px",
            "filePath": "src/components/button.json",
            "isSource": true,
            "original": {
              "value": "{icons.size.sm.value}"
            },
            "name": "ComponentsButtonIconSmallFontSize",
            "attributes": {
              "category": "components",
              "type": "button",
              "item": "icon",
              "subitem": "small",
              "state": "font-size"
            },
            "path": [
              "components",
              "button",
              "icon",
              "small",
              "font-size"
            ]
          },
          "w": {
            "value": "16px",
            "filePath": "src/components/button.json",
            "isSource": true,
            "original": {
              "value": "{icons.size.sm.value}"
            },
            "name": "ComponentsButtonIconSmallW",
            "attributes": {
              "category": "components",
              "type": "button",
              "item": "icon",
              "subitem": "small",
              "state": "w"
            },
            "path": [
              "components",
              "button",
              "icon",
              "small",
              "w"
            ]
          }
        },
        "medium": {
          "font-size": {
            "value": "20px",
            "filePath": "src/components/button.json",
            "isSource": true,
            "original": {
              "value": "{icons.size.md.value}"
            },
            "name": "ComponentsButtonIconMediumFontSize",
            "attributes": {
              "category": "components",
              "type": "button",
              "item": "icon",
              "subitem": "medium",
              "state": "font-size"
            },
            "path": [
              "components",
              "button",
              "icon",
              "medium",
              "font-size"
            ]
          },
          "w": {
            "value": "20px",
            "filePath": "src/components/button.json",
            "isSource": true,
            "original": {
              "value": "{icons.size.md.value}"
            },
            "name": "ComponentsButtonIconMediumW",
            "attributes": {
              "category": "components",
              "type": "button",
              "item": "icon",
              "subitem": "medium",
              "state": "w"
            },
            "path": [
              "components",
              "button",
              "icon",
              "medium",
              "w"
            ]
          }
        },
        "large": {
          "font-size": {
            "value": "24px",
            "filePath": "src/components/button.json",
            "isSource": true,
            "original": {
              "value": "{icons.size.lg.value}"
            },
            "name": "ComponentsButtonIconLargeFontSize",
            "attributes": {
              "category": "components",
              "type": "button",
              "item": "icon",
              "subitem": "large",
              "state": "font-size"
            },
            "path": [
              "components",
              "button",
              "icon",
              "large",
              "font-size"
            ]
          },
          "w": {
            "value": "24px",
            "filePath": "src/components/button.json",
            "isSource": true,
            "original": {
              "value": "{icons.size.lg.value}"
            },
            "name": "ComponentsButtonIconLargeW",
            "attributes": {
              "category": "components",
              "type": "button",
              "item": "icon",
              "subitem": "large",
              "state": "w"
            },
            "path": [
              "components",
              "button",
              "icon",
              "large",
              "w"
            ]
          }
        }
      },
      "size": {
        "small": {
          "padding": {
            "value": "8px 12px",
            "filePath": "src/components/button.json",
            "isSource": true,
            "original": {
              "value": "{spacing.inner.base02.value} {spacing.inner.base03.value}"
            },
            "name": "ComponentsButtonSizeSmallPadding",
            "attributes": {
              "category": "components",
              "type": "button",
              "item": "size",
              "subitem": "small",
              "state": "padding"
            },
            "path": [
              "components",
              "button",
              "size",
              "small",
              "padding"
            ]
          },
          "line-height": {
            "value": "1rem",
            "filePath": "src/components/button.json",
            "isSource": true,
            "original": {
              "value": "{font.line-height.01.value}"
            },
            "name": "ComponentsButtonSizeSmallLineHeight",
            "attributes": {
              "category": "components",
              "type": "button",
              "item": "size",
              "subitem": "small",
              "state": "line-height"
            },
            "path": [
              "components",
              "button",
              "size",
              "small",
              "line-height"
            ]
          }
        },
        "medium": {
          "padding": {
            "value": "12px 16px",
            "filePath": "src/components/button.json",
            "isSource": true,
            "original": {
              "value": "{spacing.inner.base03.value} {spacing.inner.base04.value}"
            },
            "name": "ComponentsButtonSizeMediumPadding",
            "attributes": {
              "category": "components",
              "type": "button",
              "item": "size",
              "subitem": "medium",
              "state": "padding"
            },
            "path": [
              "components",
              "button",
              "size",
              "medium",
              "padding"
            ]
          },
          "line-height": {
            "value": "1.25rem",
            "filePath": "src/components/button.json",
            "isSource": true,
            "original": {
              "value": "{font.line-height.02.value}"
            },
            "name": "ComponentsButtonSizeMediumLineHeight",
            "attributes": {
              "category": "components",
              "type": "button",
              "item": "size",
              "subitem": "medium",
              "state": "line-height"
            },
            "path": [
              "components",
              "button",
              "size",
              "medium",
              "line-height"
            ]
          }
        },
        "large": {
          "padding": {
            "value": "12px 24px",
            "filePath": "src/components/button.json",
            "isSource": true,
            "original": {
              "value": "{spacing.inner.base03.value} {spacing.inner.base06.value}"
            },
            "name": "ComponentsButtonSizeLargePadding",
            "attributes": {
              "category": "components",
              "type": "button",
              "item": "size",
              "subitem": "large",
              "state": "padding"
            },
            "path": [
              "components",
              "button",
              "size",
              "large",
              "padding"
            ]
          },
          "line-height": {
            "value": "1.5rem",
            "filePath": "src/components/button.json",
            "isSource": true,
            "original": {
              "value": "{font.line-height.03.value}"
            },
            "name": "ComponentsButtonSizeLargeLineHeight",
            "attributes": {
              "category": "components",
              "type": "button",
              "item": "size",
              "subitem": "large",
              "state": "line-height"
            },
            "path": [
              "components",
              "button",
              "size",
              "large",
              "line-height"
            ]
          }
        }
      }
    },
    "deck": {
      "item": {
        "borderTop": {
          "value": "1px solid #e2e4e9",
          "filePath": "src/components/deck.json",
          "isSource": true,
          "original": {
            "value": "{shape.border.width.sm.value} solid {color.border.neutral.default}"
          },
          "name": "ComponentsDeckItemBorderTop",
          "attributes": {
            "category": "components",
            "type": "deck",
            "item": "item",
            "subitem": "borderTop"
          },
          "path": [
            "components",
            "deck",
            "item",
            "borderTop"
          ]
        },
        "borderBottom": {
          "_": {
            "value": "none",
            "filePath": "src/components/deck.json",
            "isSource": true,
            "original": {
              "value": "none"
            },
            "name": "ComponentsDeckItemBorderBottom",
            "attributes": {
              "category": "components",
              "type": "deck",
              "item": "item",
              "subitem": "borderBottom",
              "state": "_"
            },
            "path": [
              "components",
              "deck",
              "item",
              "borderBottom",
              "_"
            ]
          },
          "last": {
            "value": "1px solid #e2e4e9",
            "filePath": "src/components/deck.json",
            "isSource": true,
            "original": {
              "value": "{shape.border.width.sm.value} solid {color.border.neutral.default}"
            },
            "name": "ComponentsDeckItemBorderBottomLast",
            "attributes": {
              "category": "components",
              "type": "deck",
              "item": "item",
              "subitem": "borderBottom",
              "state": "last"
            },
            "path": [
              "components",
              "deck",
              "item",
              "borderBottom",
              "last"
            ]
          }
        }
      },
      "header": {
        "paddingTop": {
          "value": "12px",
          "filePath": "src/components/deck.json",
          "isSource": true,
          "original": {
            "value": "{spacing.inner.base03.value}"
          },
          "name": "ComponentsDeckHeaderPaddingTop",
          "attributes": {
            "category": "components",
            "type": "deck",
            "item": "header",
            "subitem": "paddingTop"
          },
          "path": [
            "components",
            "deck",
            "header",
            "paddingTop"
          ]
        },
        "paddingBottom": {
          "value": "12px",
          "filePath": "src/components/deck.json",
          "isSource": true,
          "original": {
            "value": "{spacing.inner.base03.value}"
          },
          "name": "ComponentsDeckHeaderPaddingBottom",
          "attributes": {
            "category": "components",
            "type": "deck",
            "item": "header",
            "subitem": "paddingBottom"
          },
          "path": [
            "components",
            "deck",
            "header",
            "paddingBottom"
          ]
        }
      },
      "title": {
        "display": {
          "value": "flex",
          "filePath": "src/components/deck.json",
          "isSource": true,
          "original": {
            "value": "flex"
          },
          "name": "ComponentsDeckTitleDisplay",
          "attributes": {
            "category": "components",
            "type": "deck",
            "item": "title",
            "subitem": "display"
          },
          "path": [
            "components",
            "deck",
            "title",
            "display"
          ]
        },
        "flex-direction": {
          "value": "row",
          "filePath": "src/components/deck.json",
          "isSource": true,
          "original": {
            "value": "row"
          },
          "name": "ComponentsDeckTitleFlexDirection",
          "attributes": {
            "category": "components",
            "type": "deck",
            "item": "title",
            "subitem": "flex-direction"
          },
          "path": [
            "components",
            "deck",
            "title",
            "flex-direction"
          ]
        },
        "justify-content": {
          "value": "space-between",
          "filePath": "src/components/deck.json",
          "isSource": true,
          "original": {
            "value": "space-between"
          },
          "name": "ComponentsDeckTitleJustifyContent",
          "attributes": {
            "category": "components",
            "type": "deck",
            "item": "title",
            "subitem": "justify-content"
          },
          "path": [
            "components",
            "deck",
            "title",
            "justify-content"
          ]
        },
        "paddingTop": {
          "value": "24px",
          "filePath": "src/components/deck.json",
          "isSource": true,
          "original": {
            "value": "{spacing.inner.base06.value}"
          },
          "name": "ComponentsDeckTitlePaddingTop",
          "attributes": {
            "category": "components",
            "type": "deck",
            "item": "title",
            "subitem": "paddingTop"
          },
          "path": [
            "components",
            "deck",
            "title",
            "paddingTop"
          ]
        },
        "paddingBottom": {
          "value": "24px",
          "filePath": "src/components/deck.json",
          "isSource": true,
          "original": {
            "value": "{spacing.inner.base06.value}"
          },
          "name": "ComponentsDeckTitlePaddingBottom",
          "attributes": {
            "category": "components",
            "type": "deck",
            "item": "title",
            "subitem": "paddingBottom"
          },
          "path": [
            "components",
            "deck",
            "title",
            "paddingBottom"
          ]
        },
        "cursor": {
          "value": "pointer",
          "filePath": "src/components/deck.json",
          "isSource": true,
          "original": {
            "value": "pointer"
          },
          "name": "ComponentsDeckTitleCursor",
          "attributes": {
            "category": "components",
            "type": "deck",
            "item": "title",
            "subitem": "cursor"
          },
          "path": [
            "components",
            "deck",
            "title",
            "cursor"
          ]
        }
      },
      "titleText": {
        "outline": {
          "value": "none",
          "filePath": "src/components/deck.json",
          "isSource": true,
          "original": {
            "value": "none"
          },
          "name": "ComponentsDeckTitleTextOutline",
          "attributes": {
            "category": "components",
            "type": "deck",
            "item": "titleText",
            "subitem": "outline"
          },
          "path": [
            "components",
            "deck",
            "titleText",
            "outline"
          ]
        },
        "display": {
          "value": "flex",
          "filePath": "src/components/deck.json",
          "isSource": true,
          "original": {
            "value": "flex"
          },
          "name": "ComponentsDeckTitleTextDisplay",
          "attributes": {
            "category": "components",
            "type": "deck",
            "item": "titleText",
            "subitem": "display"
          },
          "path": [
            "components",
            "deck",
            "titleText",
            "display"
          ]
        },
        "alignItems": {
          "value": "center",
          "filePath": "src/components/deck.json",
          "isSource": true,
          "original": {
            "value": "center"
          },
          "name": "ComponentsDeckTitleTextAlignItems",
          "attributes": {
            "category": "components",
            "type": "deck",
            "item": "titleText",
            "subitem": "alignItems"
          },
          "path": [
            "components",
            "deck",
            "titleText",
            "alignItems"
          ]
        },
        "gap": {
          "value": "8px",
          "filePath": "src/components/deck.json",
          "isSource": true,
          "original": {
            "value": "{spacing.inner.base02.value}"
          },
          "name": "ComponentsDeckTitleTextGap",
          "attributes": {
            "category": "components",
            "type": "deck",
            "item": "titleText",
            "subitem": "gap"
          },
          "path": [
            "components",
            "deck",
            "titleText",
            "gap"
          ]
        },
        "overflow": {
          "value": "hidden",
          "filePath": "src/components/deck.json",
          "isSource": true,
          "original": {
            "value": "hidden"
          },
          "name": "ComponentsDeckTitleTextOverflow",
          "attributes": {
            "category": "components",
            "type": "deck",
            "item": "titleText",
            "subitem": "overflow"
          },
          "path": [
            "components",
            "deck",
            "titleText",
            "overflow"
          ]
        },
        "whiteSpace": {
          "value": "nowrap",
          "filePath": "src/components/deck.json",
          "isSource": true,
          "original": {
            "value": "nowrap"
          },
          "name": "ComponentsDeckTitleTextWhiteSpace",
          "attributes": {
            "category": "components",
            "type": "deck",
            "item": "titleText",
            "subitem": "whiteSpace"
          },
          "path": [
            "components",
            "deck",
            "titleText",
            "whiteSpace"
          ]
        }
      },
      "subTitleText": {
        "color": {
          "value": "#666b7a",
          "filePath": "src/components/deck.json",
          "isSource": true,
          "original": {
            "value": "{color.text.secondary}"
          },
          "name": "ComponentsDeckSubTitleTextColor",
          "attributes": {
            "category": "components",
            "type": "deck",
            "item": "subTitleText",
            "subitem": "color"
          },
          "path": [
            "components",
            "deck",
            "subTitleText",
            "color"
          ]
        },
        "textOverflow": {
          "value": "ellipsis",
          "filePath": "src/components/deck.json",
          "isSource": true,
          "original": {
            "value": "ellipsis"
          },
          "name": "ComponentsDeckSubTitleTextTextOverflow",
          "attributes": {
            "category": "components",
            "type": "deck",
            "item": "subTitleText",
            "subitem": "textOverflow"
          },
          "path": [
            "components",
            "deck",
            "subTitleText",
            "textOverflow"
          ]
        },
        "overflow": {
          "value": "hidden",
          "filePath": "src/components/deck.json",
          "isSource": true,
          "original": {
            "value": "hidden"
          },
          "name": "ComponentsDeckSubTitleTextOverflow",
          "attributes": {
            "category": "components",
            "type": "deck",
            "item": "subTitleText",
            "subitem": "overflow"
          },
          "path": [
            "components",
            "deck",
            "subTitleText",
            "overflow"
          ]
        }
      },
      "backButton": {
        "outline": {
          "value": "none",
          "filePath": "src/components/deck.json",
          "isSource": true,
          "original": {
            "value": "none"
          },
          "name": "ComponentsDeckBackButtonOutline",
          "attributes": {
            "category": "components",
            "type": "deck",
            "item": "backButton",
            "subitem": "outline"
          },
          "path": [
            "components",
            "deck",
            "backButton",
            "outline"
          ]
        },
        "w": {
          "_": {
            "value": "24px",
            "filePath": "src/components/deck.json",
            "isSource": true,
            "original": {
              "value": "24px"
            },
            "name": "ComponentsDeckBackButtonW",
            "attributes": {
              "category": "components",
              "type": "deck",
              "item": "backButton",
              "subitem": "w",
              "state": "_"
            },
            "path": [
              "components",
              "deck",
              "backButton",
              "w",
              "_"
            ]
          },
          "md": {
            "value": "20px",
            "filePath": "src/components/deck.json",
            "isSource": true,
            "original": {
              "value": "20px"
            },
            "name": "ComponentsDeckBackButtonWMd",
            "attributes": {
              "category": "components",
              "type": "deck",
              "item": "backButton",
              "subitem": "w",
              "state": "md"
            },
            "path": [
              "components",
              "deck",
              "backButton",
              "w",
              "md"
            ]
          }
        },
        "h": {
          "_": {
            "value": "24px",
            "filePath": "src/components/deck.json",
            "isSource": true,
            "original": {
              "value": "24px"
            },
            "name": "ComponentsDeckBackButtonH",
            "attributes": {
              "category": "components",
              "type": "deck",
              "item": "backButton",
              "subitem": "h",
              "state": "_"
            },
            "path": [
              "components",
              "deck",
              "backButton",
              "h",
              "_"
            ]
          },
          "md": {
            "value": "20px",
            "filePath": "src/components/deck.json",
            "isSource": true,
            "original": {
              "value": "20px"
            },
            "name": "ComponentsDeckBackButtonHMd",
            "attributes": {
              "category": "components",
              "type": "deck",
              "item": "backButton",
              "subitem": "h",
              "state": "md"
            },
            "path": [
              "components",
              "deck",
              "backButton",
              "h",
              "md"
            ]
          }
        }
      },
      "titleButton": {
        "w": {
          "_": {
            "value": "24px",
            "filePath": "src/components/deck.json",
            "isSource": true,
            "original": {
              "value": "24px"
            },
            "name": "ComponentsDeckTitleButtonW",
            "attributes": {
              "category": "components",
              "type": "deck",
              "item": "titleButton",
              "subitem": "w",
              "state": "_"
            },
            "path": [
              "components",
              "deck",
              "titleButton",
              "w",
              "_"
            ]
          },
          "md": {
            "value": "20px",
            "filePath": "src/components/deck.json",
            "isSource": true,
            "original": {
              "value": "20px"
            },
            "name": "ComponentsDeckTitleButtonWMd",
            "attributes": {
              "category": "components",
              "type": "deck",
              "item": "titleButton",
              "subitem": "w",
              "state": "md"
            },
            "path": [
              "components",
              "deck",
              "titleButton",
              "w",
              "md"
            ]
          }
        },
        "h": {
          "_": {
            "value": "24px",
            "filePath": "src/components/deck.json",
            "isSource": true,
            "original": {
              "value": "24px"
            },
            "name": "ComponentsDeckTitleButtonH",
            "attributes": {
              "category": "components",
              "type": "deck",
              "item": "titleButton",
              "subitem": "h",
              "state": "_"
            },
            "path": [
              "components",
              "deck",
              "titleButton",
              "h",
              "_"
            ]
          },
          "md": {
            "value": "20px",
            "filePath": "src/components/deck.json",
            "isSource": true,
            "original": {
              "value": "20px"
            },
            "name": "ComponentsDeckTitleButtonHMd",
            "attributes": {
              "category": "components",
              "type": "deck",
              "item": "titleButton",
              "subitem": "h",
              "state": "md"
            },
            "path": [
              "components",
              "deck",
              "titleButton",
              "h",
              "md"
            ]
          }
        }
      },
      "buttonIcon": {
        "width": {
          "value": null,
          "filePath": "src/components/deck.json",
          "isSource": true,
          "original": {
            "value": null
          },
          "name": "ComponentsDeckButtonIconWidth",
          "attributes": {
            "category": "components",
            "type": "deck",
            "item": "buttonIcon",
            "subitem": "width"
          },
          "path": [
            "components",
            "deck",
            "buttonIcon",
            "width"
          ]
        },
        "height": {
          "value": null,
          "filePath": "src/components/deck.json",
          "isSource": true,
          "original": {
            "value": null
          },
          "name": "ComponentsDeckButtonIconHeight",
          "attributes": {
            "category": "components",
            "type": "deck",
            "item": "buttonIcon",
            "subitem": "height"
          },
          "path": [
            "components",
            "deck",
            "buttonIcon",
            "height"
          ]
        }
      },
      "contentTitle": {
        "text-transform": {
          "_": {
            "value": "uppercase",
            "filePath": "src/components/deck.json",
            "isSource": true,
            "original": {
              "value": "{typography.navigation.text-transform.value}"
            },
            "name": "ComponentsDeckContentTitleTextTransform",
            "attributes": {
              "category": "components",
              "type": "deck",
              "item": "contentTitle",
              "subitem": "text-transform",
              "state": "_"
            },
            "path": [
              "components",
              "deck",
              "contentTitle",
              "text-transform",
              "_"
            ]
          },
          "md": {
            "value": "none",
            "filePath": "src/components/deck.json",
            "isSource": true,
            "original": {
              "value": "none"
            },
            "name": "ComponentsDeckContentTitleTextTransformMd",
            "attributes": {
              "category": "components",
              "type": "deck",
              "item": "contentTitle",
              "subitem": "text-transform",
              "state": "md"
            },
            "path": [
              "components",
              "deck",
              "contentTitle",
              "text-transform",
              "md"
            ]
          }
        },
        "font-weight": {
          "_": {
            "value": 600,
            "filePath": "src/components/deck.json",
            "isSource": true,
            "original": {
              "value": "{typography.navigation.font-weight.value}"
            },
            "name": "ComponentsDeckContentTitleFontWeight",
            "attributes": {
              "category": "components",
              "type": "deck",
              "item": "contentTitle",
              "subitem": "font-weight",
              "state": "_"
            },
            "path": [
              "components",
              "deck",
              "contentTitle",
              "font-weight",
              "_"
            ]
          },
          "md": {
            "value": 400,
            "filePath": "src/components/deck.json",
            "isSource": true,
            "original": {
              "value": "{typography.body.regular.font-weight.value}"
            },
            "name": "ComponentsDeckContentTitleFontWeightMd",
            "attributes": {
              "category": "components",
              "type": "deck",
              "item": "contentTitle",
              "subitem": "font-weight",
              "state": "md"
            },
            "path": [
              "components",
              "deck",
              "contentTitle",
              "font-weight",
              "md"
            ]
          }
        },
        "color": {
          "_": {
            "value": "#666b7a",
            "filePath": "src/components/deck.json",
            "isSource": true,
            "original": {
              "value": "{color.text.secondary}"
            },
            "name": "ComponentsDeckContentTitleColor",
            "attributes": {
              "category": "components",
              "type": "deck",
              "item": "contentTitle",
              "subitem": "color",
              "state": "_"
            },
            "path": [
              "components",
              "deck",
              "contentTitle",
              "color",
              "_"
            ]
          },
          "md": {
            "value": "initial",
            "filePath": "src/components/deck.json",
            "isSource": true,
            "original": {
              "value": "initial"
            },
            "name": "ComponentsDeckContentTitleColorMd",
            "attributes": {
              "category": "components",
              "type": "deck",
              "item": "contentTitle",
              "subitem": "color",
              "state": "md"
            },
            "path": [
              "components",
              "deck",
              "contentTitle",
              "color",
              "md"
            ]
          }
        },
        "margin-left": {
          "_": {
            "value": "16px",
            "filePath": "src/components/deck.json",
            "isSource": true,
            "original": {
              "value": "{spacing.inner.base04}"
            },
            "name": "ComponentsDeckContentTitleMarginLeft",
            "attributes": {
              "category": "components",
              "type": "deck",
              "item": "contentTitle",
              "subitem": "margin-left",
              "state": "_"
            },
            "path": [
              "components",
              "deck",
              "contentTitle",
              "margin-left",
              "_"
            ]
          },
          "md": {
            "value": "20px",
            "filePath": "src/components/deck.json",
            "isSource": true,
            "original": {
              "value": "{spacing.inner.base05}"
            },
            "name": "ComponentsDeckContentTitleMarginLeftMd",
            "attributes": {
              "category": "components",
              "type": "deck",
              "item": "contentTitle",
              "subitem": "margin-left",
              "state": "md"
            },
            "path": [
              "components",
              "deck",
              "contentTitle",
              "margin-left",
              "md"
            ]
          }
        }
      }
    },
    "divider": {
      "horizontal": {
        "base": {
          "border-top": {
            "value": "1px solid #e2e4e9",
            "filePath": "src/components/divider.json",
            "isSource": true,
            "original": {
              "value": "{shape.border.width.sm} solid {color.border.divider}"
            },
            "name": "ComponentsDividerHorizontalBaseBorderTop",
            "attributes": {
              "category": "components",
              "type": "divider",
              "item": "horizontal",
              "subitem": "base",
              "state": "border-top"
            },
            "path": [
              "components",
              "divider",
              "horizontal",
              "base",
              "border-top"
            ]
          }
        },
        "spacing": {
          "zero": {
            "mt": {
              "value": 0,
              "filePath": "src/components/divider.json",
              "isSource": true,
              "original": {
                "value": 0
              },
              "name": "ComponentsDividerHorizontalSpacingZeroMt",
              "attributes": {
                "category": "components",
                "type": "divider",
                "item": "horizontal",
                "subitem": "spacing",
                "state": "zero"
              },
              "path": [
                "components",
                "divider",
                "horizontal",
                "spacing",
                "zero",
                "mt"
              ]
            },
            "mb": {
              "value": 0,
              "filePath": "src/components/divider.json",
              "isSource": true,
              "original": {
                "value": 0
              },
              "name": "ComponentsDividerHorizontalSpacingZeroMb",
              "attributes": {
                "category": "components",
                "type": "divider",
                "item": "horizontal",
                "subitem": "spacing",
                "state": "zero"
              },
              "path": [
                "components",
                "divider",
                "horizontal",
                "spacing",
                "zero",
                "mb"
              ]
            }
          },
          "sm": {
            "mt": {
              "value": "4px",
              "filePath": "src/components/divider.json",
              "isSource": true,
              "original": {
                "value": "{spacing.inner.base.value}"
              },
              "name": "ComponentsDividerHorizontalSpacingSmMt",
              "attributes": {
                "category": "components",
                "type": "divider",
                "item": "horizontal",
                "subitem": "spacing",
                "state": "sm"
              },
              "path": [
                "components",
                "divider",
                "horizontal",
                "spacing",
                "sm",
                "mt"
              ]
            },
            "mb": {
              "value": "4px",
              "filePath": "src/components/divider.json",
              "isSource": true,
              "original": {
                "value": "{spacing.inner.base.value}"
              },
              "name": "ComponentsDividerHorizontalSpacingSmMb",
              "attributes": {
                "category": "components",
                "type": "divider",
                "item": "horizontal",
                "subitem": "spacing",
                "state": "sm"
              },
              "path": [
                "components",
                "divider",
                "horizontal",
                "spacing",
                "sm",
                "mb"
              ]
            }
          },
          "md": {
            "mt": {
              "value": "8px",
              "filePath": "src/components/divider.json",
              "isSource": true,
              "original": {
                "value": "{spacing.inner.base02.value}"
              },
              "name": "ComponentsDividerHorizontalSpacingMdMt",
              "attributes": {
                "category": "components",
                "type": "divider",
                "item": "horizontal",
                "subitem": "spacing",
                "state": "md"
              },
              "path": [
                "components",
                "divider",
                "horizontal",
                "spacing",
                "md",
                "mt"
              ]
            },
            "mb": {
              "value": "8px",
              "filePath": "src/components/divider.json",
              "isSource": true,
              "original": {
                "value": "{spacing.inner.base02.value}"
              },
              "name": "ComponentsDividerHorizontalSpacingMdMb",
              "attributes": {
                "category": "components",
                "type": "divider",
                "item": "horizontal",
                "subitem": "spacing",
                "state": "md"
              },
              "path": [
                "components",
                "divider",
                "horizontal",
                "spacing",
                "md",
                "mb"
              ]
            }
          },
          "lg": {
            "mt": {
              "value": "16px",
              "filePath": "src/components/divider.json",
              "isSource": true,
              "original": {
                "value": "{spacing.inner.base04.value}"
              },
              "name": "ComponentsDividerHorizontalSpacingLgMt",
              "attributes": {
                "category": "components",
                "type": "divider",
                "item": "horizontal",
                "subitem": "spacing",
                "state": "lg"
              },
              "path": [
                "components",
                "divider",
                "horizontal",
                "spacing",
                "lg",
                "mt"
              ]
            },
            "mb": {
              "value": "16px",
              "filePath": "src/components/divider.json",
              "isSource": true,
              "original": {
                "value": "{spacing.inner.base04.value}"
              },
              "name": "ComponentsDividerHorizontalSpacingLgMb",
              "attributes": {
                "category": "components",
                "type": "divider",
                "item": "horizontal",
                "subitem": "spacing",
                "state": "lg"
              },
              "path": [
                "components",
                "divider",
                "horizontal",
                "spacing",
                "lg",
                "mb"
              ]
            }
          }
        }
      },
      "vertical": {
        "base": {
          "border-left": {
            "value": "1px solid #e2e4e9",
            "filePath": "src/components/divider.json",
            "isSource": true,
            "original": {
              "value": "{shape.border.width.sm} solid {color.border.divider}"
            },
            "name": "ComponentsDividerVerticalBaseBorderLeft",
            "attributes": {
              "category": "components",
              "type": "divider",
              "item": "vertical",
              "subitem": "base",
              "state": "border-left"
            },
            "path": [
              "components",
              "divider",
              "vertical",
              "base",
              "border-left"
            ]
          }
        },
        "spacing": {
          "zero": {
            "ml": {
              "value": 0,
              "filePath": "src/components/divider.json",
              "isSource": true,
              "original": {
                "value": 0
              },
              "name": "ComponentsDividerVerticalSpacingZeroMl",
              "attributes": {
                "category": "components",
                "type": "divider",
                "item": "vertical",
                "subitem": "spacing",
                "state": "zero"
              },
              "path": [
                "components",
                "divider",
                "vertical",
                "spacing",
                "zero",
                "ml"
              ]
            },
            "mr": {
              "value": 0,
              "filePath": "src/components/divider.json",
              "isSource": true,
              "original": {
                "value": 0
              },
              "name": "ComponentsDividerVerticalSpacingZeroMr",
              "attributes": {
                "category": "components",
                "type": "divider",
                "item": "vertical",
                "subitem": "spacing",
                "state": "zero"
              },
              "path": [
                "components",
                "divider",
                "vertical",
                "spacing",
                "zero",
                "mr"
              ]
            }
          },
          "sm": {
            "ml": {
              "value": "4px",
              "filePath": "src/components/divider.json",
              "isSource": true,
              "original": {
                "value": "{spacing.inner.base.value}"
              },
              "name": "ComponentsDividerVerticalSpacingSmMl",
              "attributes": {
                "category": "components",
                "type": "divider",
                "item": "vertical",
                "subitem": "spacing",
                "state": "sm"
              },
              "path": [
                "components",
                "divider",
                "vertical",
                "spacing",
                "sm",
                "ml"
              ]
            },
            "mr": {
              "value": "4px",
              "filePath": "src/components/divider.json",
              "isSource": true,
              "original": {
                "value": "{spacing.inner.base.value}"
              },
              "name": "ComponentsDividerVerticalSpacingSmMr",
              "attributes": {
                "category": "components",
                "type": "divider",
                "item": "vertical",
                "subitem": "spacing",
                "state": "sm"
              },
              "path": [
                "components",
                "divider",
                "vertical",
                "spacing",
                "sm",
                "mr"
              ]
            }
          },
          "md": {
            "ml": {
              "value": "8px",
              "filePath": "src/components/divider.json",
              "isSource": true,
              "original": {
                "value": "{spacing.inner.base02.value}"
              },
              "name": "ComponentsDividerVerticalSpacingMdMl",
              "attributes": {
                "category": "components",
                "type": "divider",
                "item": "vertical",
                "subitem": "spacing",
                "state": "md"
              },
              "path": [
                "components",
                "divider",
                "vertical",
                "spacing",
                "md",
                "ml"
              ]
            },
            "mr": {
              "value": "8px",
              "filePath": "src/components/divider.json",
              "isSource": true,
              "original": {
                "value": "{spacing.inner.base02.value}"
              },
              "name": "ComponentsDividerVerticalSpacingMdMr",
              "attributes": {
                "category": "components",
                "type": "divider",
                "item": "vertical",
                "subitem": "spacing",
                "state": "md"
              },
              "path": [
                "components",
                "divider",
                "vertical",
                "spacing",
                "md",
                "mr"
              ]
            }
          },
          "lg": {
            "ml": {
              "value": "16px",
              "filePath": "src/components/divider.json",
              "isSource": true,
              "original": {
                "value": "{spacing.inner.base04.value}"
              },
              "name": "ComponentsDividerVerticalSpacingLgMl",
              "attributes": {
                "category": "components",
                "type": "divider",
                "item": "vertical",
                "subitem": "spacing",
                "state": "lg"
              },
              "path": [
                "components",
                "divider",
                "vertical",
                "spacing",
                "lg",
                "ml"
              ]
            },
            "mr": {
              "value": "16px",
              "filePath": "src/components/divider.json",
              "isSource": true,
              "original": {
                "value": "{spacing.inner.base04.value}"
              },
              "name": "ComponentsDividerVerticalSpacingLgMr",
              "attributes": {
                "category": "components",
                "type": "divider",
                "item": "vertical",
                "subitem": "spacing",
                "state": "lg"
              },
              "path": [
                "components",
                "divider",
                "vertical",
                "spacing",
                "lg",
                "mr"
              ]
            }
          }
        }
      }
    },
    "icon-only-button": {
      "base-styles": {
        "outline": {
          "focus": {
            "value": "none",
            "filePath": "src/components/icon-only-button.json",
            "isSource": true,
            "original": {
              "value": "none"
            },
            "name": "ComponentsIconOnlyButtonBaseStylesOutlineFocus",
            "attributes": {
              "category": "components",
              "type": "icon-only-button",
              "item": "base-styles",
              "subitem": "outline",
              "state": "focus"
            },
            "path": [
              "components",
              "icon-only-button",
              "base-styles",
              "outline",
              "focus"
            ]
          }
        },
        "position": {
          "value": "relative",
          "filePath": "src/components/icon-only-button.json",
          "isSource": true,
          "original": {
            "value": "relative"
          },
          "name": "ComponentsIconOnlyButtonBaseStylesPosition",
          "attributes": {
            "category": "components",
            "type": "icon-only-button",
            "item": "base-styles",
            "subitem": "position"
          },
          "path": [
            "components",
            "icon-only-button",
            "base-styles",
            "position"
          ]
        },
        "transition": {
          "value": "all 0.2s ease",
          "filePath": "src/components/icon-only-button.json",
          "isSource": true,
          "original": {
            "value": "all 0.2s ease"
          },
          "name": "ComponentsIconOnlyButtonBaseStylesTransition",
          "attributes": {
            "category": "components",
            "type": "icon-only-button",
            "item": "base-styles",
            "subitem": "transition"
          },
          "path": [
            "components",
            "icon-only-button",
            "base-styles",
            "transition"
          ]
        },
        "border-radius": {
          "value": "50%",
          "filePath": "src/components/icon-only-button.json",
          "isSource": true,
          "original": {
            "value": "50%"
          },
          "name": "ComponentsIconOnlyButtonBaseStylesBorderRadius",
          "attributes": {
            "category": "components",
            "type": "icon-only-button",
            "item": "base-styles",
            "subitem": "border-radius"
          },
          "path": [
            "components",
            "icon-only-button",
            "base-styles",
            "border-radius"
          ]
        }
      },
      "base-icon": {
        "position": {
          "value": "absolute",
          "filePath": "src/components/icon-only-button.json",
          "isSource": true,
          "original": {
            "value": "absolute"
          },
          "name": "ComponentsIconOnlyButtonBaseIconPosition",
          "attributes": {
            "category": "components",
            "type": "icon-only-button",
            "item": "base-icon",
            "subitem": "position"
          },
          "path": [
            "components",
            "icon-only-button",
            "base-icon",
            "position"
          ]
        },
        "top": {
          "value": "50%",
          "filePath": "src/components/icon-only-button.json",
          "isSource": true,
          "original": {
            "value": "50%"
          },
          "name": "ComponentsIconOnlyButtonBaseIconTop",
          "attributes": {
            "category": "components",
            "type": "icon-only-button",
            "item": "base-icon",
            "subitem": "top"
          },
          "path": [
            "components",
            "icon-only-button",
            "base-icon",
            "top"
          ]
        },
        "left": {
          "value": "50%",
          "filePath": "src/components/icon-only-button.json",
          "isSource": true,
          "original": {
            "value": "50%"
          },
          "name": "ComponentsIconOnlyButtonBaseIconLeft",
          "attributes": {
            "category": "components",
            "type": "icon-only-button",
            "item": "base-icon",
            "subitem": "left"
          },
          "path": [
            "components",
            "icon-only-button",
            "base-icon",
            "left"
          ]
        },
        "transform": {
          "value": "translate(-50%, -50%)",
          "filePath": "src/components/icon-only-button.json",
          "isSource": true,
          "original": {
            "value": "translate(-50%, -50%)"
          },
          "name": "ComponentsIconOnlyButtonBaseIconTransform",
          "attributes": {
            "category": "components",
            "type": "icon-only-button",
            "item": "base-icon",
            "subitem": "transform"
          },
          "path": [
            "components",
            "icon-only-button",
            "base-icon",
            "transform"
          ]
        }
      },
      "variant": {
        "primary": {
          "color": {
            "_": {
              "value": "#ffffff",
              "filePath": "src/components/icon-only-button.json",
              "isSource": true,
              "original": {
                "value": "{components.button.variant.primary.color._.value}"
              },
              "name": "ComponentsIconOnlyButtonVariantPrimaryColor",
              "attributes": {
                "category": "components",
                "type": "icon-only-button",
                "item": "variant",
                "subitem": "primary",
                "state": "color"
              },
              "path": [
                "components",
                "icon-only-button",
                "variant",
                "primary",
                "color",
                "_"
              ]
            },
            "disabled": {
              "value": "#9ca1b0",
              "filePath": "src/components/icon-only-button.json",
              "isSource": true,
              "original": {
                "value": "{components.button.variant.primary.color.disabled.value}"
              },
              "name": "ComponentsIconOnlyButtonVariantPrimaryColorDisabled",
              "attributes": {
                "category": "components",
                "type": "icon-only-button",
                "item": "variant",
                "subitem": "primary",
                "state": "color"
              },
              "path": [
                "components",
                "icon-only-button",
                "variant",
                "primary",
                "color",
                "disabled"
              ]
            }
          },
          "background": {
            "_": {
              "value": "linear-gradient(268.84deg, #e8236a 0%, #fd3001 31.25%, #f97415 100%)",
              "filePath": "src/components/icon-only-button.json",
              "isSource": true,
              "original": {
                "value": "{components.button.variant.primary.background._.value}"
              },
              "name": "ComponentsIconOnlyButtonVariantPrimaryBackground",
              "attributes": {
                "category": "components",
                "type": "icon-only-button",
                "item": "variant",
                "subitem": "primary",
                "state": "background"
              },
              "path": [
                "components",
                "icon-only-button",
                "variant",
                "primary",
                "background",
                "_"
              ]
            },
            "hover": {
              "value": "linear-gradient(88.84deg, #e8236a 0%, #fd3001 31.25%, #f97415 100%)",
              "filePath": "src/components/icon-only-button.json",
              "isSource": true,
              "original": {
                "value": "{components.button.variant.primary.background.hover.value}"
              },
              "name": "ComponentsIconOnlyButtonVariantPrimaryBackgroundHover",
              "attributes": {
                "category": "components",
                "type": "icon-only-button",
                "item": "variant",
                "subitem": "primary",
                "state": "background"
              },
              "path": [
                "components",
                "icon-only-button",
                "variant",
                "primary",
                "background",
                "hover"
              ]
            },
            "disabled": {
              "value": "#ebecef",
              "filePath": "src/components/icon-only-button.json",
              "isSource": true,
              "original": {
                "value": "{components.button.variant.primary.background.disabled.value}"
              },
              "name": "ComponentsIconOnlyButtonVariantPrimaryBackgroundDisabled",
              "attributes": {
                "category": "components",
                "type": "icon-only-button",
                "item": "variant",
                "subitem": "primary",
                "state": "background"
              },
              "path": [
                "components",
                "icon-only-button",
                "variant",
                "primary",
                "background",
                "disabled"
              ]
            }
          }
        },
        "secondary": {
          "color": {
            "_": {
              "value": "#ffffff",
              "filePath": "src/components/icon-only-button.json",
              "isSource": true,
              "original": {
                "value": "{components.button.variant.secondary.color._.value}"
              },
              "name": "ComponentsIconOnlyButtonVariantSecondaryColor",
              "attributes": {
                "category": "components",
                "type": "icon-only-button",
                "item": "variant",
                "subitem": "secondary",
                "state": "color"
              },
              "path": [
                "components",
                "icon-only-button",
                "variant",
                "secondary",
                "color",
                "_"
              ]
            },
            "disabled": {
              "value": "#9ca1b0",
              "filePath": "src/components/icon-only-button.json",
              "isSource": true,
              "original": {
                "value": "{components.button.variant.secondary.color.disabled.value}"
              },
              "name": "ComponentsIconOnlyButtonVariantSecondaryColorDisabled",
              "attributes": {
                "category": "components",
                "type": "icon-only-button",
                "item": "variant",
                "subitem": "secondary",
                "state": "color"
              },
              "path": [
                "components",
                "icon-only-button",
                "variant",
                "secondary",
                "color",
                "disabled"
              ]
            }
          },
          "background": {
            "_": {
              "value": "#0d1730",
              "filePath": "src/components/icon-only-button.json",
              "isSource": true,
              "original": {
                "value": "{components.button.variant.secondary.background._.value}"
              },
              "name": "ComponentsIconOnlyButtonVariantSecondaryBackground",
              "attributes": {
                "category": "components",
                "type": "icon-only-button",
                "item": "variant",
                "subitem": "secondary",
                "state": "background"
              },
              "path": [
                "components",
                "icon-only-button",
                "variant",
                "secondary",
                "background",
                "_"
              ]
            },
            "hover": {
              "value": "#16171d",
              "filePath": "src/components/icon-only-button.json",
              "isSource": true,
              "original": {
                "value": "{components.button.variant.secondary.background.hover.value}"
              },
              "name": "ComponentsIconOnlyButtonVariantSecondaryBackgroundHover",
              "attributes": {
                "category": "components",
                "type": "icon-only-button",
                "item": "variant",
                "subitem": "secondary",
                "state": "background"
              },
              "path": [
                "components",
                "icon-only-button",
                "variant",
                "secondary",
                "background",
                "hover"
              ]
            },
            "disabled": {
              "value": "#ebecef",
              "filePath": "src/components/icon-only-button.json",
              "isSource": true,
              "original": {
                "value": "{components.button.variant.secondary.background.disabled.value}"
              },
              "name": "ComponentsIconOnlyButtonVariantSecondaryBackgroundDisabled",
              "attributes": {
                "category": "components",
                "type": "icon-only-button",
                "item": "variant",
                "subitem": "secondary",
                "state": "background"
              },
              "path": [
                "components",
                "icon-only-button",
                "variant",
                "secondary",
                "background",
                "disabled"
              ]
            }
          }
        },
        "ghost": {
          "color": {
            "_": {
              "value": "#1e2129",
              "filePath": "src/components/icon-only-button.json",
              "isSource": true,
              "original": {
                "value": "{components.button.variant.ghost.color._.value}"
              },
              "name": "ComponentsIconOnlyButtonVariantGhostColor",
              "attributes": {
                "category": "components",
                "type": "icon-only-button",
                "item": "variant",
                "subitem": "ghost",
                "state": "color"
              },
              "path": [
                "components",
                "icon-only-button",
                "variant",
                "ghost",
                "color",
                "_"
              ]
            },
            "hover": {
              "value": "#dc2828",
              "filePath": "src/components/icon-only-button.json",
              "isSource": true,
              "original": {
                "value": "{components.button.variant.ghost.color.hover.value}"
              },
              "name": "ComponentsIconOnlyButtonVariantGhostColorHover",
              "attributes": {
                "category": "components",
                "type": "icon-only-button",
                "item": "variant",
                "subitem": "ghost",
                "state": "color"
              },
              "path": [
                "components",
                "icon-only-button",
                "variant",
                "ghost",
                "color",
                "hover"
              ]
            },
            "disabled": {
              "value": "#9ca1b0",
              "filePath": "src/components/icon-only-button.json",
              "isSource": true,
              "original": {
                "value": "{components.button.variant.ghost.color.disabled.value}"
              },
              "name": "ComponentsIconOnlyButtonVariantGhostColorDisabled",
              "attributes": {
                "category": "components",
                "type": "icon-only-button",
                "item": "variant",
                "subitem": "ghost",
                "state": "color"
              },
              "path": [
                "components",
                "icon-only-button",
                "variant",
                "ghost",
                "color",
                "disabled"
              ]
            }
          },
          "background": {
            "_": {
              "value": "transparent",
              "filePath": "src/components/icon-only-button.json",
              "isSource": true,
              "original": {
                "value": "{components.button.variant.ghost.background._.value}"
              },
              "name": "ComponentsIconOnlyButtonVariantGhostBackground",
              "attributes": {
                "category": "components",
                "type": "icon-only-button",
                "item": "variant",
                "subitem": "ghost",
                "state": "background"
              },
              "path": [
                "components",
                "icon-only-button",
                "variant",
                "ghost",
                "background",
                "_"
              ]
            },
            "disabled": {
              "value": "transparent",
              "filePath": "src/components/icon-only-button.json",
              "isSource": true,
              "original": {
                "value": "{components.button.variant.ghost.background.disabled.value}"
              },
              "name": "ComponentsIconOnlyButtonVariantGhostBackgroundDisabled",
              "attributes": {
                "category": "components",
                "type": "icon-only-button",
                "item": "variant",
                "subitem": "ghost",
                "state": "background"
              },
              "path": [
                "components",
                "icon-only-button",
                "variant",
                "ghost",
                "background",
                "disabled"
              ]
            }
          }
        },
        "outline": {
          "color": {
            "_": {
              "value": "#1e2129",
              "filePath": "src/components/icon-only-button.json",
              "isSource": true,
              "original": {
                "value": "{components.button.variant.outline.color._.value}"
              },
              "name": "ComponentsIconOnlyButtonVariantOutlineColor",
              "attributes": {
                "category": "components",
                "type": "icon-only-button",
                "item": "variant",
                "subitem": "outline",
                "state": "color"
              },
              "path": [
                "components",
                "icon-only-button",
                "variant",
                "outline",
                "color",
                "_"
              ]
            },
            "hover": {
              "value": "#1e2129",
              "filePath": "src/components/icon-only-button.json",
              "isSource": true,
              "original": {
                "value": "{components.button.variant.outline.color.hover.value}"
              },
              "name": "ComponentsIconOnlyButtonVariantOutlineColorHover",
              "attributes": {
                "category": "components",
                "type": "icon-only-button",
                "item": "variant",
                "subitem": "outline",
                "state": "color"
              },
              "path": [
                "components",
                "icon-only-button",
                "variant",
                "outline",
                "color",
                "hover"
              ]
            },
            "disabled": {
              "value": "#9ca1b0",
              "filePath": "src/components/icon-only-button.json",
              "isSource": true,
              "original": {
                "value": "{components.button.variant.outline.color.disabled.value}"
              },
              "name": "ComponentsIconOnlyButtonVariantOutlineColorDisabled",
              "attributes": {
                "category": "components",
                "type": "icon-only-button",
                "item": "variant",
                "subitem": "outline",
                "state": "color"
              },
              "path": [
                "components",
                "icon-only-button",
                "variant",
                "outline",
                "color",
                "disabled"
              ]
            }
          },
          "background": {
            "_": {
              "value": "#ffffff",
              "filePath": "src/components/icon-only-button.json",
              "isSource": true,
              "original": {
                "value": "{components.button.variant.outline.background._.value}"
              },
              "name": "ComponentsIconOnlyButtonVariantOutlineBackground",
              "attributes": {
                "category": "components",
                "type": "icon-only-button",
                "item": "variant",
                "subitem": "outline",
                "state": "background"
              },
              "path": [
                "components",
                "icon-only-button",
                "variant",
                "outline",
                "background",
                "_"
              ]
            }
          },
          "border": {
            "_": {
              "value": "1px solid #e2e4e9",
              "filePath": "src/components/icon-only-button.json",
              "isSource": true,
              "original": {
                "value": "{components.button.variant.outline.outline._.value}"
              },
              "name": "ComponentsIconOnlyButtonVariantOutlineBorder",
              "attributes": {
                "category": "components",
                "type": "icon-only-button",
                "item": "variant",
                "subitem": "outline",
                "state": "border"
              },
              "path": [
                "components",
                "icon-only-button",
                "variant",
                "outline",
                "border",
                "_"
              ]
            },
            "hover": {
              "value": "1px solid #e2e4e9",
              "filePath": "src/components/icon-only-button.json",
              "isSource": true,
              "original": {
                "value": "{components.button.variant.outline.outline.hover.value}"
              },
              "name": "ComponentsIconOnlyButtonVariantOutlineBorderHover",
              "attributes": {
                "category": "components",
                "type": "icon-only-button",
                "item": "variant",
                "subitem": "outline",
                "state": "border"
              },
              "path": [
                "components",
                "icon-only-button",
                "variant",
                "outline",
                "border",
                "hover"
              ]
            }
          }
        }
      },
      "size": {
        "small": {
          "dimensions": {
            "w": {
              "value": "32px",
              "filePath": "src/components/icon-only-button.json",
              "isSource": true,
              "original": {
                "value": "32px"
              },
              "name": "ComponentsIconOnlyButtonSizeSmallDimensionsW",
              "attributes": {
                "category": "components",
                "type": "icon-only-button",
                "item": "size",
                "subitem": "small",
                "state": "dimensions"
              },
              "path": [
                "components",
                "icon-only-button",
                "size",
                "small",
                "dimensions",
                "w"
              ]
            },
            "minW": {
              "value": "32px",
              "filePath": "src/components/icon-only-button.json",
              "isSource": true,
              "original": {
                "value": "32px"
              },
              "name": "ComponentsIconOnlyButtonSizeSmallDimensionsMinW",
              "attributes": {
                "category": "components",
                "type": "icon-only-button",
                "item": "size",
                "subitem": "small",
                "state": "dimensions"
              },
              "path": [
                "components",
                "icon-only-button",
                "size",
                "small",
                "dimensions",
                "minW"
              ]
            },
            "h": {
              "value": "32px",
              "filePath": "src/components/icon-only-button.json",
              "isSource": true,
              "original": {
                "value": "32px"
              },
              "name": "ComponentsIconOnlyButtonSizeSmallDimensionsH",
              "attributes": {
                "category": "components",
                "type": "icon-only-button",
                "item": "size",
                "subitem": "small",
                "state": "dimensions"
              },
              "path": [
                "components",
                "icon-only-button",
                "size",
                "small",
                "dimensions",
                "h"
              ]
            },
            "minH": {
              "value": "32px",
              "filePath": "src/components/icon-only-button.json",
              "isSource": true,
              "original": {
                "value": "32px"
              },
              "name": "ComponentsIconOnlyButtonSizeSmallDimensionsMinH",
              "attributes": {
                "category": "components",
                "type": "icon-only-button",
                "item": "size",
                "subitem": "small",
                "state": "dimensions"
              },
              "path": [
                "components",
                "icon-only-button",
                "size",
                "small",
                "dimensions",
                "minH"
              ]
            }
          },
          "icon": {
            "w": {
              "value": "16px",
              "filePath": "src/components/icon-only-button.json",
              "isSource": true,
              "original": {
                "value": "16px"
              },
              "name": "ComponentsIconOnlyButtonSizeSmallIconW",
              "attributes": {
                "category": "components",
                "type": "icon-only-button",
                "item": "size",
                "subitem": "small",
                "state": "icon"
              },
              "path": [
                "components",
                "icon-only-button",
                "size",
                "small",
                "icon",
                "w"
              ]
            },
            "h": {
              "value": "16px",
              "filePath": "src/components/icon-only-button.json",
              "isSource": true,
              "original": {
                "value": "16px"
              },
              "name": "ComponentsIconOnlyButtonSizeSmallIconH",
              "attributes": {
                "category": "components",
                "type": "icon-only-button",
                "item": "size",
                "subitem": "small",
                "state": "icon"
              },
              "path": [
                "components",
                "icon-only-button",
                "size",
                "small",
                "icon",
                "h"
              ]
            }
          }
        },
        "medium": {
          "dimensions": {
            "w": {
              "value": "44px",
              "filePath": "src/components/icon-only-button.json",
              "isSource": true,
              "original": {
                "value": "44px"
              },
              "name": "ComponentsIconOnlyButtonSizeMediumDimensionsW",
              "attributes": {
                "category": "components",
                "type": "icon-only-button",
                "item": "size",
                "subitem": "medium",
                "state": "dimensions"
              },
              "path": [
                "components",
                "icon-only-button",
                "size",
                "medium",
                "dimensions",
                "w"
              ]
            },
            "minW": {
              "value": "44px",
              "filePath": "src/components/icon-only-button.json",
              "isSource": true,
              "original": {
                "value": "44px"
              },
              "name": "ComponentsIconOnlyButtonSizeMediumDimensionsMinW",
              "attributes": {
                "category": "components",
                "type": "icon-only-button",
                "item": "size",
                "subitem": "medium",
                "state": "dimensions"
              },
              "path": [
                "components",
                "icon-only-button",
                "size",
                "medium",
                "dimensions",
                "minW"
              ]
            },
            "minH": {
              "value": "44px",
              "filePath": "src/components/icon-only-button.json",
              "isSource": true,
              "original": {
                "value": "44px"
              },
              "name": "ComponentsIconOnlyButtonSizeMediumDimensionsMinH",
              "attributes": {
                "category": "components",
                "type": "icon-only-button",
                "item": "size",
                "subitem": "medium",
                "state": "dimensions"
              },
              "path": [
                "components",
                "icon-only-button",
                "size",
                "medium",
                "dimensions",
                "minH"
              ]
            },
            "h": {
              "value": "44px",
              "filePath": "src/components/icon-only-button.json",
              "isSource": true,
              "original": {
                "value": "44px"
              },
              "name": "ComponentsIconOnlyButtonSizeMediumDimensionsH",
              "attributes": {
                "category": "components",
                "type": "icon-only-button",
                "item": "size",
                "subitem": "medium",
                "state": "dimensions"
              },
              "path": [
                "components",
                "icon-only-button",
                "size",
                "medium",
                "dimensions",
                "h"
              ]
            }
          },
          "icon": {
            "w": {
              "value": "20px",
              "filePath": "src/components/icon-only-button.json",
              "isSource": true,
              "original": {
                "value": "20px"
              },
              "name": "ComponentsIconOnlyButtonSizeMediumIconW",
              "attributes": {
                "category": "components",
                "type": "icon-only-button",
                "item": "size",
                "subitem": "medium",
                "state": "icon"
              },
              "path": [
                "components",
                "icon-only-button",
                "size",
                "medium",
                "icon",
                "w"
              ]
            },
            "h": {
              "value": "20px",
              "filePath": "src/components/icon-only-button.json",
              "isSource": true,
              "original": {
                "value": "20px"
              },
              "name": "ComponentsIconOnlyButtonSizeMediumIconH",
              "attributes": {
                "category": "components",
                "type": "icon-only-button",
                "item": "size",
                "subitem": "medium",
                "state": "icon"
              },
              "path": [
                "components",
                "icon-only-button",
                "size",
                "medium",
                "icon",
                "h"
              ]
            }
          }
        },
        "large": {
          "dimensions": {
            "w": {
              "value": "48px",
              "filePath": "src/components/icon-only-button.json",
              "isSource": true,
              "original": {
                "value": "48px"
              },
              "name": "ComponentsIconOnlyButtonSizeLargeDimensionsW",
              "attributes": {
                "category": "components",
                "type": "icon-only-button",
                "item": "size",
                "subitem": "large",
                "state": "dimensions"
              },
              "path": [
                "components",
                "icon-only-button",
                "size",
                "large",
                "dimensions",
                "w"
              ]
            },
            "minW": {
              "value": "48px",
              "filePath": "src/components/icon-only-button.json",
              "isSource": true,
              "original": {
                "value": "48px"
              },
              "name": "ComponentsIconOnlyButtonSizeLargeDimensionsMinW",
              "attributes": {
                "category": "components",
                "type": "icon-only-button",
                "item": "size",
                "subitem": "large",
                "state": "dimensions"
              },
              "path": [
                "components",
                "icon-only-button",
                "size",
                "large",
                "dimensions",
                "minW"
              ]
            },
            "minH": {
              "value": "48px",
              "filePath": "src/components/icon-only-button.json",
              "isSource": true,
              "original": {
                "value": "48px"
              },
              "name": "ComponentsIconOnlyButtonSizeLargeDimensionsMinH",
              "attributes": {
                "category": "components",
                "type": "icon-only-button",
                "item": "size",
                "subitem": "large",
                "state": "dimensions"
              },
              "path": [
                "components",
                "icon-only-button",
                "size",
                "large",
                "dimensions",
                "minH"
              ]
            },
            "h": {
              "value": "48px",
              "filePath": "src/components/icon-only-button.json",
              "isSource": true,
              "original": {
                "value": "48px"
              },
              "name": "ComponentsIconOnlyButtonSizeLargeDimensionsH",
              "attributes": {
                "category": "components",
                "type": "icon-only-button",
                "item": "size",
                "subitem": "large",
                "state": "dimensions"
              },
              "path": [
                "components",
                "icon-only-button",
                "size",
                "large",
                "dimensions",
                "h"
              ]
            }
          },
          "icon": {
            "w": {
              "value": "24px",
              "filePath": "src/components/icon-only-button.json",
              "isSource": true,
              "original": {
                "value": "24px"
              },
              "name": "ComponentsIconOnlyButtonSizeLargeIconW",
              "attributes": {
                "category": "components",
                "type": "icon-only-button",
                "item": "size",
                "subitem": "large",
                "state": "icon"
              },
              "path": [
                "components",
                "icon-only-button",
                "size",
                "large",
                "icon",
                "w"
              ]
            },
            "h": {
              "value": "24px",
              "filePath": "src/components/icon-only-button.json",
              "isSource": true,
              "original": {
                "value": "24px"
              },
              "name": "ComponentsIconOnlyButtonSizeLargeIconH",
              "attributes": {
                "category": "components",
                "type": "icon-only-button",
                "item": "size",
                "subitem": "large",
                "state": "icon"
              },
              "path": [
                "components",
                "icon-only-button",
                "size",
                "large",
                "icon",
                "h"
              ]
            }
          }
        }
      }
    },
    "icon": {
      "base": {
        "background": {
          "_": {
            "value": "#e6f0fe",
            "filePath": "src/components/icon.json",
            "isSource": true,
            "original": {
              "value": "{color.background.info.value}"
            },
            "name": "ComponentsIconBaseBackground",
            "attributes": {
              "category": "components",
              "type": "icon",
              "item": "base",
              "subitem": "background",
              "state": "_"
            },
            "path": [
              "components",
              "icon",
              "base",
              "background",
              "_"
            ]
          },
          "hover": {
            "value": "#e2e4e9",
            "filePath": "src/components/icon.json",
            "isSource": true,
            "original": {
              "value": "{color.background.secondary.value}"
            },
            "name": "ComponentsIconBaseBackgroundHover",
            "attributes": {
              "category": "components",
              "type": "icon",
              "item": "base",
              "subitem": "background",
              "state": "hover"
            },
            "path": [
              "components",
              "icon",
              "base",
              "background",
              "hover"
            ]
          }
        }
      },
      "active": {
        "background": {
          "value": "#e6f0fe",
          "filePath": "src/components/icon.json",
          "isSource": true,
          "original": {
            "value": "{color.background.info.value}"
          },
          "name": "ComponentsIconActiveBackground",
          "attributes": {
            "category": "components",
            "type": "icon",
            "item": "active",
            "subitem": "background"
          },
          "path": [
            "components",
            "icon",
            "active",
            "background"
          ]
        },
        "color": {
          "value": "#2463eb",
          "filePath": "src/components/icon.json",
          "isSource": true,
          "original": {
            "value": "{color.text.info.value}"
          },
          "name": "ComponentsIconActiveColor",
          "attributes": {
            "category": "components",
            "type": "icon",
            "item": "active",
            "subitem": "color"
          },
          "path": [
            "components",
            "icon",
            "active",
            "color"
          ]
        }
      }
    },
    "list": {
      "margin": {
        "value": 0,
        "filePath": "src/components/list.json",
        "isSource": true,
        "original": {
          "value": 0
        },
        "name": "ComponentsListMargin",
        "attributes": {
          "category": "components",
          "type": "list",
          "item": "margin"
        },
        "path": [
          "components",
          "list",
          "margin"
        ]
      },
      "list-style": {
        "value": "none",
        "filePath": "src/components/list.json",
        "isSource": true,
        "original": {
          "value": "none"
        },
        "name": "ComponentsListListStyle",
        "attributes": {
          "category": "components",
          "type": "list",
          "item": "list-style"
        },
        "path": [
          "components",
          "list",
          "list-style"
        ]
      },
      "padding": {
        "value": 0,
        "filePath": "src/components/list.json",
        "isSource": true,
        "original": {
          "value": 0
        },
        "name": "ComponentsListPadding",
        "attributes": {
          "category": "components",
          "type": "list",
          "item": "padding"
        },
        "path": [
          "components",
          "list",
          "padding"
        ]
      },
      "display": {
        "value": "flex",
        "filePath": "src/components/list.json",
        "isSource": true,
        "original": {
          "value": "flex"
        },
        "name": "ComponentsListDisplay",
        "attributes": {
          "category": "components",
          "type": "list",
          "item": "display"
        },
        "path": [
          "components",
          "list",
          "display"
        ]
      }
    },
    "list-item": {
      "base": {
        "margin": {
          "value": 0,
          "filePath": "src/components/list.json",
          "isSource": true,
          "original": {
            "value": 0
          },
          "name": "ComponentsListItemBaseMargin",
          "attributes": {
            "category": "components",
            "type": "list-item",
            "item": "base",
            "subitem": "margin"
          },
          "path": [
            "components",
            "list-item",
            "base",
            "margin"
          ]
        },
        "display": {
          "value": "flex",
          "filePath": "src/components/list.json",
          "isSource": true,
          "original": {
            "value": "flex"
          },
          "name": "ComponentsListItemBaseDisplay",
          "attributes": {
            "category": "components",
            "type": "list-item",
            "item": "base",
            "subitem": "display"
          },
          "path": [
            "components",
            "list-item",
            "base",
            "display"
          ]
        },
        "align-items": {
          "value": "center",
          "filePath": "src/components/list.json",
          "isSource": true,
          "original": {
            "value": "center"
          },
          "name": "ComponentsListItemBaseAlignItems",
          "attributes": {
            "category": "components",
            "type": "list-item",
            "item": "base",
            "subitem": "align-items"
          },
          "path": [
            "components",
            "list-item",
            "base",
            "align-items"
          ]
        },
        "color": {
          "value": "#1e2129",
          "filePath": "src/components/list.json",
          "isSource": true,
          "original": {
            "value": "{color.text.strong._}"
          },
          "name": "ComponentsListItemBaseColor",
          "attributes": {
            "category": "components",
            "type": "list-item",
            "item": "base",
            "subitem": "color"
          },
          "path": [
            "components",
            "list-item",
            "base",
            "color"
          ]
        },
        "hoverColor": {
          "value": "#dc2828",
          "filePath": "src/components/list.json",
          "isSource": true,
          "original": {
            "value": "{color.text.strong.hover}"
          },
          "name": "ComponentsListItemBaseHoverColor",
          "attributes": {
            "category": "components",
            "type": "list-item",
            "item": "base",
            "subitem": "hoverColor"
          },
          "path": [
            "components",
            "list-item",
            "base",
            "hoverColor"
          ]
        }
      },
      "spacing": {
        "sm": {
          "padding": {
            "value": "4px",
            "filePath": "src/components/list.json",
            "isSource": true,
            "original": {
              "value": "{spacing.inner.base.value}"
            },
            "name": "ComponentsListItemSpacingSmPadding",
            "attributes": {
              "category": "components",
              "type": "list-item",
              "item": "spacing",
              "subitem": "sm",
              "state": "padding"
            },
            "path": [
              "components",
              "list-item",
              "spacing",
              "sm",
              "padding"
            ]
          }
        },
        "md": {
          "padding": {
            "value": "8px",
            "filePath": "src/components/list.json",
            "isSource": true,
            "original": {
              "value": "{spacing.inner.base02.value}"
            },
            "name": "ComponentsListItemSpacingMdPadding",
            "attributes": {
              "category": "components",
              "type": "list-item",
              "item": "spacing",
              "subitem": "md",
              "state": "padding"
            },
            "path": [
              "components",
              "list-item",
              "spacing",
              "md",
              "padding"
            ]
          }
        },
        "lg": {
          "padding": {
            "value": "16px",
            "filePath": "src/components/list.json",
            "isSource": true,
            "original": {
              "value": "{spacing.inner.base04.value}"
            },
            "name": "ComponentsListItemSpacingLgPadding",
            "attributes": {
              "category": "components",
              "type": "list-item",
              "item": "spacing",
              "subitem": "lg",
              "state": "padding"
            },
            "path": [
              "components",
              "list-item",
              "spacing",
              "lg",
              "padding"
            ]
          }
        }
      },
      "variant": {
        "compact": {
          "padding": {
            "value": 0,
            "filePath": "src/components/list.json",
            "isSource": true,
            "original": {
              "value": 0
            },
            "name": "ComponentsListItemVariantCompactPadding",
            "attributes": {
              "category": "components",
              "type": "list-item",
              "item": "variant",
              "subitem": "compact",
              "state": "padding"
            },
            "path": [
              "components",
              "list-item",
              "variant",
              "compact",
              "padding"
            ]
          }
        }
      },
      "icons-right": {
        "display": {
          "value": "flex",
          "filePath": "src/components/list.json",
          "isSource": true,
          "original": {
            "value": "flex"
          },
          "name": "ComponentsListItemIconsRightDisplay",
          "attributes": {
            "category": "components",
            "type": "list-item",
            "item": "icons-right",
            "subitem": "display"
          },
          "path": [
            "components",
            "list-item",
            "icons-right",
            "display"
          ]
        },
        "margin-left": {
          "value": "4px",
          "filePath": "src/components/list.json",
          "isSource": true,
          "original": {
            "value": "{spacing.inner.base.value}"
          },
          "name": "ComponentsListItemIconsRightMarginLeft",
          "attributes": {
            "category": "components",
            "type": "list-item",
            "item": "icons-right",
            "subitem": "margin-left"
          },
          "path": [
            "components",
            "list-item",
            "icons-right",
            "margin-left"
          ]
        }
      },
      "icons-left": {
        "display": {
          "value": "flex",
          "filePath": "src/components/list.json",
          "isSource": true,
          "original": {
            "value": "flex"
          },
          "name": "ComponentsListItemIconsLeftDisplay",
          "attributes": {
            "category": "components",
            "type": "list-item",
            "item": "icons-left",
            "subitem": "display"
          },
          "path": [
            "components",
            "list-item",
            "icons-left",
            "display"
          ]
        },
        "margin-right": {
          "value": "4px",
          "filePath": "src/components/list.json",
          "isSource": true,
          "original": {
            "value": "{spacing.inner.base.value}"
          },
          "name": "ComponentsListItemIconsLeftMarginRight",
          "attributes": {
            "category": "components",
            "type": "list-item",
            "item": "icons-left",
            "subitem": "margin-right"
          },
          "path": [
            "components",
            "list-item",
            "icons-left",
            "margin-right"
          ]
        }
      },
      "icons-span": {
        "flex-grow": {
          "value": 0,
          "filePath": "src/components/list.json",
          "isSource": true,
          "original": {
            "value": 0
          },
          "name": "ComponentsListItemIconsSpanFlexGrow",
          "attributes": {
            "category": "components",
            "type": "list-item",
            "item": "icons-span",
            "subitem": "flex-grow"
          },
          "path": [
            "components",
            "list-item",
            "icons-span",
            "flex-grow"
          ]
        }
      },
      "text-span": {
        "flex-grow": {
          "value": 1,
          "filePath": "src/components/list.json",
          "isSource": true,
          "original": {
            "value": 1
          },
          "name": "ComponentsListItemTextSpanFlexGrow",
          "attributes": {
            "category": "components",
            "type": "list-item",
            "item": "text-span",
            "subitem": "flex-grow"
          },
          "path": [
            "components",
            "list-item",
            "text-span",
            "flex-grow"
          ]
        },
        "display": {
          "value": "flex",
          "filePath": "src/components/list.json",
          "isSource": true,
          "original": {
            "value": "flex"
          },
          "name": "ComponentsListItemTextSpanDisplay",
          "attributes": {
            "category": "components",
            "type": "list-item",
            "item": "text-span",
            "subitem": "display"
          },
          "path": [
            "components",
            "list-item",
            "text-span",
            "display"
          ]
        },
        "flex-direction": {
          "value": "column",
          "filePath": "src/components/list.json",
          "isSource": true,
          "original": {
            "value": "column"
          },
          "name": "ComponentsListItemTextSpanFlexDirection",
          "attributes": {
            "category": "components",
            "type": "list-item",
            "item": "text-span",
            "subitem": "flex-direction"
          },
          "path": [
            "components",
            "list-item",
            "text-span",
            "flex-direction"
          ]
        }
      },
      "primary": {
        "color": {
          "value": "inherit",
          "filePath": "src/components/list.json",
          "isSource": true,
          "original": {
            "value": "inherit"
          },
          "name": "ComponentsListItemPrimaryColor",
          "attributes": {
            "category": "components",
            "type": "list-item",
            "item": "primary",
            "subitem": "color"
          },
          "path": [
            "components",
            "list-item",
            "primary",
            "color"
          ]
        }
      },
      "secondary": {
        "color": {
          "value": "#666b7a",
          "filePath": "src/components/list.json",
          "isSource": true,
          "original": {
            "value": "{color.text.secondary}"
          },
          "name": "ComponentsListItemSecondaryColor",
          "attributes": {
            "category": "components",
            "type": "list-item",
            "item": "secondary",
            "subitem": "color"
          },
          "path": [
            "components",
            "list-item",
            "secondary",
            "color"
          ]
        }
      }
    },
    "loading": {
      "size": {
        "xs": {
          "width": {
            "value": 10,
            "filePath": "src/components/loading.json",
            "isSource": true,
            "original": {
              "value": 10
            },
            "name": "ComponentsLoadingSizeXsWidth",
            "attributes": {
              "category": "components",
              "type": "loading",
              "item": "size",
              "subitem": "xs",
              "state": "width"
            },
            "path": [
              "components",
              "loading",
              "size",
              "xs",
              "width"
            ]
          },
          "height": {
            "value": 10,
            "filePath": "src/components/loading.json",
            "isSource": true,
            "original": {
              "value": 10
            },
            "name": "ComponentsLoadingSizeXsHeight",
            "attributes": {
              "category": "components",
              "type": "loading",
              "item": "size",
              "subitem": "xs",
              "state": "height"
            },
            "path": [
              "components",
              "loading",
              "size",
              "xs",
              "height"
            ]
          }
        },
        "sm": {
          "width": {
            "value": 20,
            "filePath": "src/components/loading.json",
            "isSource": true,
            "original": {
              "value": 20
            },
            "name": "ComponentsLoadingSizeSmWidth",
            "attributes": {
              "category": "components",
              "type": "loading",
              "item": "size",
              "subitem": "sm",
              "state": "width"
            },
            "path": [
              "components",
              "loading",
              "size",
              "sm",
              "width"
            ]
          },
          "height": {
            "value": 20,
            "filePath": "src/components/loading.json",
            "isSource": true,
            "original": {
              "value": 20
            },
            "name": "ComponentsLoadingSizeSmHeight",
            "attributes": {
              "category": "components",
              "type": "loading",
              "item": "size",
              "subitem": "sm",
              "state": "height"
            },
            "path": [
              "components",
              "loading",
              "size",
              "sm",
              "height"
            ]
          }
        },
        "md": {
          "width": {
            "value": 40,
            "filePath": "src/components/loading.json",
            "isSource": true,
            "original": {
              "value": 40
            },
            "name": "ComponentsLoadingSizeMdWidth",
            "attributes": {
              "category": "components",
              "type": "loading",
              "item": "size",
              "subitem": "md",
              "state": "width"
            },
            "path": [
              "components",
              "loading",
              "size",
              "md",
              "width"
            ]
          },
          "height": {
            "value": 40,
            "filePath": "src/components/loading.json",
            "isSource": true,
            "original": {
              "value": 40
            },
            "name": "ComponentsLoadingSizeMdHeight",
            "attributes": {
              "category": "components",
              "type": "loading",
              "item": "size",
              "subitem": "md",
              "state": "height"
            },
            "path": [
              "components",
              "loading",
              "size",
              "md",
              "height"
            ]
          }
        },
        "lg": {
          "width": {
            "value": 80,
            "filePath": "src/components/loading.json",
            "isSource": true,
            "original": {
              "value": 80
            },
            "name": "ComponentsLoadingSizeLgWidth",
            "attributes": {
              "category": "components",
              "type": "loading",
              "item": "size",
              "subitem": "lg",
              "state": "width"
            },
            "path": [
              "components",
              "loading",
              "size",
              "lg",
              "width"
            ]
          },
          "height": {
            "value": 80,
            "filePath": "src/components/loading.json",
            "isSource": true,
            "original": {
              "value": 80
            },
            "name": "ComponentsLoadingSizeLgHeight",
            "attributes": {
              "category": "components",
              "type": "loading",
              "item": "size",
              "subitem": "lg",
              "state": "height"
            },
            "path": [
              "components",
              "loading",
              "size",
              "lg",
              "height"
            ]
          }
        }
      }
    },
    "logo": {
      "size": {
        "xxs": {
          "value": 24,
          "filePath": "src/components/logo.json",
          "isSource": true,
          "original": {
            "value": 24
          },
          "name": "ComponentsLogoSizeXxs",
          "attributes": {
            "category": "components",
            "type": "logo",
            "item": "size",
            "subitem": "xxs"
          },
          "path": [
            "components",
            "logo",
            "size",
            "xxs"
          ]
        },
        "xs": {
          "value": 32,
          "filePath": "src/components/logo.json",
          "isSource": true,
          "original": {
            "value": 32
          },
          "name": "ComponentsLogoSizeXs",
          "attributes": {
            "category": "components",
            "type": "logo",
            "item": "size",
            "subitem": "xs"
          },
          "path": [
            "components",
            "logo",
            "size",
            "xs"
          ]
        },
        "sm": {
          "value": 40,
          "filePath": "src/components/logo.json",
          "isSource": true,
          "original": {
            "value": 40
          },
          "name": "ComponentsLogoSizeSm",
          "attributes": {
            "category": "components",
            "type": "logo",
            "item": "size",
            "subitem": "sm"
          },
          "path": [
            "components",
            "logo",
            "size",
            "sm"
          ]
        },
        "md": {
          "value": 56,
          "filePath": "src/components/logo.json",
          "isSource": true,
          "original": {
            "value": 56
          },
          "name": "ComponentsLogoSizeMd",
          "attributes": {
            "category": "components",
            "type": "logo",
            "item": "size",
            "subitem": "md"
          },
          "path": [
            "components",
            "logo",
            "size",
            "md"
          ]
        },
        "lg": {
          "value": 72,
          "filePath": "src/components/logo.json",
          "isSource": true,
          "original": {
            "value": 72
          },
          "name": "ComponentsLogoSizeLg",
          "attributes": {
            "category": "components",
            "type": "logo",
            "item": "size",
            "subitem": "lg"
          },
          "path": [
            "components",
            "logo",
            "size",
            "lg"
          ]
        }
      },
      "xxs": {
        "height": {
          "value": 24,
          "filePath": "src/components/logo.json",
          "isSource": true,
          "original": {
            "value": "{components.logo.size.xxs.value}"
          },
          "name": "ComponentsLogoXxsHeight",
          "attributes": {
            "category": "components",
            "type": "logo",
            "item": "xxs",
            "subitem": "height"
          },
          "path": [
            "components",
            "logo",
            "xxs",
            "height"
          ]
        }
      },
      "xs": {
        "height": {
          "value": 32,
          "filePath": "src/components/logo.json",
          "isSource": true,
          "original": {
            "value": "{components.logo.size.xs.value}"
          },
          "name": "ComponentsLogoXsHeight",
          "attributes": {
            "category": "components",
            "type": "logo",
            "item": "xs",
            "subitem": "height"
          },
          "path": [
            "components",
            "logo",
            "xs",
            "height"
          ]
        }
      },
      "sm": {
        "height": {
          "value": 40,
          "filePath": "src/components/logo.json",
          "isSource": true,
          "original": {
            "value": "{components.logo.size.sm.value}"
          },
          "name": "ComponentsLogoSmHeight",
          "attributes": {
            "category": "components",
            "type": "logo",
            "item": "sm",
            "subitem": "height"
          },
          "path": [
            "components",
            "logo",
            "sm",
            "height"
          ]
        }
      },
      "md": {
        "height": {
          "value": 56,
          "filePath": "src/components/logo.json",
          "isSource": true,
          "original": {
            "value": "{components.logo.size.md.value}"
          },
          "name": "ComponentsLogoMdHeight",
          "attributes": {
            "category": "components",
            "type": "logo",
            "item": "md",
            "subitem": "height"
          },
          "path": [
            "components",
            "logo",
            "md",
            "height"
          ]
        }
      },
      "lg": {
        "height": {
          "value": 72,
          "filePath": "src/components/logo.json",
          "isSource": true,
          "original": {
            "value": "{components.logo.size.lg.value}"
          },
          "name": "ComponentsLogoLgHeight",
          "attributes": {
            "category": "components",
            "type": "logo",
            "item": "lg",
            "subitem": "height"
          },
          "path": [
            "components",
            "logo",
            "lg",
            "height"
          ]
        }
      }
    },
    "nav-bar": {
      "padding": {
        "value": "12px",
        "filePath": "src/components/nav-bar.json",
        "isSource": true,
        "original": {
          "value": "{spacing.inner.base03.value}"
        },
        "name": "ComponentsNavBarPadding",
        "attributes": {
          "category": "components",
          "type": "nav-bar",
          "item": "padding"
        },
        "path": [
          "components",
          "nav-bar",
          "padding"
        ]
      },
      "display": {
        "value": "flex",
        "filePath": "src/components/nav-bar.json",
        "isSource": true,
        "original": {
          "value": "flex"
        },
        "name": "ComponentsNavBarDisplay",
        "attributes": {
          "category": "components",
          "type": "nav-bar",
          "item": "display"
        },
        "path": [
          "components",
          "nav-bar",
          "display"
        ]
      },
      "justifyContent": {
        "value": "space-between",
        "filePath": "src/components/nav-bar.json",
        "isSource": true,
        "original": {
          "value": "space-between"
        },
        "name": "ComponentsNavBarJustifyContent",
        "attributes": {
          "category": "components",
          "type": "nav-bar",
          "item": "justifyContent"
        },
        "path": [
          "components",
          "nav-bar",
          "justifyContent"
        ]
      },
      "alignItems": {
        "value": "center",
        "filePath": "src/components/nav-bar.json",
        "isSource": true,
        "original": {
          "value": "center"
        },
        "name": "ComponentsNavBarAlignItems",
        "attributes": {
          "category": "components",
          "type": "nav-bar",
          "item": "alignItems"
        },
        "path": [
          "components",
          "nav-bar",
          "alignItems"
        ]
      },
      "border-bottom": {
        "value": "1px solid #e2e4e9",
        "filePath": "src/components/nav-bar.json",
        "isSource": true,
        "original": {
          "value": "1px solid {color.border.neutral.default}"
        },
        "name": "ComponentsNavBarBorderBottom",
        "attributes": {
          "category": "components",
          "type": "nav-bar",
          "item": "border-bottom"
        },
        "path": [
          "components",
          "nav-bar",
          "border-bottom"
        ]
      }
    },
    "overlay": {
      "base": {
        "background": {
          "value": "rgba(34, 34, 34, 0.8)",
          "filePath": "src/components/overlay.json",
          "isSource": true,
          "original": {
            "value": "{shape.opacity.overlay.900}"
          },
          "name": "ComponentsOverlayBaseBackground",
          "attributes": {
            "category": "components",
            "type": "overlay",
            "item": "base",
            "subitem": "background"
          },
          "path": [
            "components",
            "overlay",
            "base",
            "background"
          ]
        },
        "transition": {
          "value": "visibility 0.2s, opacity 0.2s",
          "filePath": "src/components/overlay.json",
          "isSource": true,
          "original": {
            "value": "visibility 0.2s, opacity 0.2s"
          },
          "name": "ComponentsOverlayBaseTransition",
          "attributes": {
            "category": "components",
            "type": "overlay",
            "item": "base",
            "subitem": "transition"
          },
          "path": [
            "components",
            "overlay",
            "base",
            "transition"
          ]
        },
        "visibility": {
          "value": "visible",
          "filePath": "src/components/overlay.json",
          "isSource": true,
          "original": {
            "value": "visible"
          },
          "name": "ComponentsOverlayBaseVisibility",
          "attributes": {
            "category": "components",
            "type": "overlay",
            "item": "base",
            "subitem": "visibility"
          },
          "path": [
            "components",
            "overlay",
            "base",
            "visibility"
          ]
        }
      },
      "closed": {
        "opacity": {
          "value": "0",
          "filePath": "src/components/overlay.json",
          "isSource": true,
          "original": {
            "value": "0"
          },
          "name": "ComponentsOverlayClosedOpacity",
          "attributes": {
            "category": "components",
            "type": "overlay",
            "item": "closed",
            "subitem": "opacity"
          },
          "path": [
            "components",
            "overlay",
            "closed",
            "opacity"
          ]
        },
        "visibility": {
          "value": "hidden",
          "filePath": "src/components/overlay.json",
          "isSource": true,
          "original": {
            "value": "hidden"
          },
          "name": "ComponentsOverlayClosedVisibility",
          "attributes": {
            "category": "components",
            "type": "overlay",
            "item": "closed",
            "subitem": "visibility"
          },
          "path": [
            "components",
            "overlay",
            "closed",
            "visibility"
          ]
        }
      }
    },
    "popover": {
      "header": {
        "padding": {
          "value": "16px 16px 8px 16px",
          "filePath": "src/components/popover.json",
          "isSource": true,
          "original": {
            "value": "{spacing.inner.base04} {spacing.inner.base04} {spacing.inner.base02} {spacing.inner.base04}"
          },
          "name": "ComponentsPopoverHeaderPadding",
          "attributes": {
            "category": "components",
            "type": "popover",
            "item": "header",
            "subitem": "padding"
          },
          "path": [
            "components",
            "popover",
            "header",
            "padding"
          ]
        },
        "text-align": {
          "value": "left",
          "filePath": "src/components/popover.json",
          "isSource": true,
          "original": {
            "value": "left"
          },
          "name": "ComponentsPopoverHeaderTextAlign",
          "attributes": {
            "category": "components",
            "type": "popover",
            "item": "header",
            "subitem": "text-align"
          },
          "path": [
            "components",
            "popover",
            "header",
            "text-align"
          ]
        },
        "border-bottom": {
          "value": "1px solid #e2e4e9",
          "filePath": "src/components/popover.json",
          "isSource": true,
          "original": {
            "value": "{shape.border.width.sm} solid {color.border.divider}"
          },
          "name": "ComponentsPopoverHeaderBorderBottom",
          "attributes": {
            "category": "components",
            "type": "popover",
            "item": "header",
            "subitem": "border-bottom"
          },
          "path": [
            "components",
            "popover",
            "header",
            "border-bottom"
          ]
        }
      },
      "content": {
        "padding": {
          "value": "8px",
          "filePath": "src/components/popover.json",
          "isSource": true,
          "original": {
            "value": "{spacing.inner.base02}"
          },
          "name": "ComponentsPopoverContentPadding",
          "attributes": {
            "category": "components",
            "type": "popover",
            "item": "content",
            "subitem": "padding"
          },
          "path": [
            "components",
            "popover",
            "content",
            "padding"
          ]
        }
      },
      "footer": {
        "border-top": {
          "value": "1px solid #e2e4e9",
          "filePath": "src/components/popover.json",
          "isSource": true,
          "original": {
            "value": "{shape.border.width.sm} solid {color.border.divider}"
          },
          "name": "ComponentsPopoverFooterBorderTop",
          "attributes": {
            "category": "components",
            "type": "popover",
            "item": "footer",
            "subitem": "border-top"
          },
          "path": [
            "components",
            "popover",
            "footer",
            "border-top"
          ]
        },
        "display": {
          "value": "flex",
          "filePath": "src/components/popover.json",
          "isSource": true,
          "original": {
            "value": "flex"
          },
          "name": "ComponentsPopoverFooterDisplay",
          "attributes": {
            "category": "components",
            "type": "popover",
            "item": "footer",
            "subitem": "display"
          },
          "path": [
            "components",
            "popover",
            "footer",
            "display"
          ]
        },
        "justify-content": {
          "value": "space-between",
          "filePath": "src/components/popover.json",
          "isSource": true,
          "original": {
            "value": "space-between"
          },
          "name": "ComponentsPopoverFooterJustifyContent",
          "attributes": {
            "category": "components",
            "type": "popover",
            "item": "footer",
            "subitem": "justify-content"
          },
          "path": [
            "components",
            "popover",
            "footer",
            "justify-content"
          ]
        },
        "align-items": {
          "value": "center",
          "filePath": "src/components/popover.json",
          "isSource": true,
          "original": {
            "value": "center"
          },
          "name": "ComponentsPopoverFooterAlignItems",
          "attributes": {
            "category": "components",
            "type": "popover",
            "item": "footer",
            "subitem": "align-items"
          },
          "path": [
            "components",
            "popover",
            "footer",
            "align-items"
          ]
        },
        "padding": {
          "value": "16px",
          "filePath": "src/components/popover.json",
          "isSource": true,
          "original": {
            "value": "{spacing.inner.base04}"
          },
          "name": "ComponentsPopoverFooterPadding",
          "attributes": {
            "category": "components",
            "type": "popover",
            "item": "footer",
            "subitem": "padding"
          },
          "path": [
            "components",
            "popover",
            "footer",
            "padding"
          ]
        },
        "small": {
          "gap": {
            "value": "8px",
            "filePath": "src/components/popover.json",
            "isSource": true,
            "original": {
              "value": "{spacing.inner.base02}"
            },
            "name": "ComponentsPopoverFooterSmallGap",
            "attributes": {
              "category": "components",
              "type": "popover",
              "item": "footer",
              "subitem": "small",
              "state": "gap"
            },
            "path": [
              "components",
              "popover",
              "footer",
              "small",
              "gap"
            ]
          }
        },
        "medium": {
          "gap": {
            "value": "12px",
            "filePath": "src/components/popover.json",
            "isSource": true,
            "original": {
              "value": "{spacing.inner.base03}"
            },
            "name": "ComponentsPopoverFooterMediumGap",
            "attributes": {
              "category": "components",
              "type": "popover",
              "item": "footer",
              "subitem": "medium",
              "state": "gap"
            },
            "path": [
              "components",
              "popover",
              "footer",
              "medium",
              "gap"
            ]
          }
        },
        "large": {
          "gap": {
            "value": "16px",
            "filePath": "src/components/popover.json",
            "isSource": true,
            "original": {
              "value": "{spacing.inner.base04}"
            },
            "name": "ComponentsPopoverFooterLargeGap",
            "attributes": {
              "category": "components",
              "type": "popover",
              "item": "footer",
              "subitem": "large",
              "state": "gap"
            },
            "path": [
              "components",
              "popover",
              "footer",
              "large",
              "gap"
            ]
          }
        }
      },
      "body": {
        "border": {
          "value": "1px solid #e2e4e9",
          "filePath": "src/components/popover.json",
          "isSource": true,
          "original": {
            "value": "{shape.border.width.sm} solid {color.border.neutral.default}"
          },
          "name": "ComponentsPopoverBodyBorder",
          "attributes": {
            "category": "components",
            "type": "popover",
            "item": "body",
            "subitem": "border"
          },
          "path": [
            "components",
            "popover",
            "body",
            "border"
          ]
        },
        "border-radius": {
          "value": "8px",
          "filePath": "src/components/popover.json",
          "isSource": true,
          "original": {
            "value": "{shape.border.radius.medium.value}"
          },
          "name": "ComponentsPopoverBodyBorderRadius",
          "attributes": {
            "category": "components",
            "type": "popover",
            "item": "body",
            "subitem": "border-radius"
          },
          "path": [
            "components",
            "popover",
            "body",
            "border-radius"
          ]
        },
        "background": {
          "value": "#ffffff",
          "filePath": "src/components/popover.json",
          "isSource": true,
          "original": {
            "value": "{color.background.surface.overlay}"
          },
          "name": "ComponentsPopoverBodyBackground",
          "attributes": {
            "category": "components",
            "type": "popover",
            "item": "body",
            "subitem": "background"
          },
          "path": [
            "components",
            "popover",
            "body",
            "background"
          ]
        },
        "box-shadow": {
          "value": "0px 4px 12px rgba(0, 29, 58, 0.12)",
          "filePath": "src/components/popover.json",
          "isSource": true,
          "original": {
            "value": "{shape.shadow.tertiary.value}"
          },
          "name": "ComponentsPopoverBodyBoxShadow",
          "attributes": {
            "category": "components",
            "type": "popover",
            "item": "body",
            "subitem": "box-shadow"
          },
          "path": [
            "components",
            "popover",
            "body",
            "box-shadow"
          ]
        }
      }
    },
    "radio-button": {
      "errorLabel": {
        "color": {
          "value": "#dc2828",
          "filePath": "src/components/radiobutton.json",
          "isSource": true,
          "original": {
            "value": "{color.text.danger}"
          },
          "name": "ComponentsRadioButtonErrorLabelColor",
          "attributes": {
            "category": "components",
            "type": "radio-button",
            "item": "errorLabel",
            "subitem": "color"
          },
          "path": [
            "components",
            "radio-button",
            "errorLabel",
            "color"
          ]
        }
      },
      "base": {
        "cursor": {
          "value": "pointer",
          "filePath": "src/components/radiobutton.json",
          "isSource": true,
          "original": {
            "value": "pointer"
          },
          "name": "ComponentsRadioButtonBaseCursor",
          "attributes": {
            "category": "components",
            "type": "radio-button",
            "item": "base",
            "subitem": "cursor"
          },
          "path": [
            "components",
            "radio-button",
            "base",
            "cursor"
          ]
        },
        "borderRadius": {
          "value": "50%",
          "filePath": "src/components/radiobutton.json",
          "isSource": true,
          "original": {
            "value": "{shape.border.radius.large}"
          },
          "name": "ComponentsRadioButtonBaseBorderRadius",
          "attributes": {
            "category": "components",
            "type": "radio-button",
            "item": "base",
            "subitem": "borderRadius"
          },
          "path": [
            "components",
            "radio-button",
            "base",
            "borderRadius"
          ]
        },
        "overflow": {
          "value": "hidden",
          "filePath": "src/components/radiobutton.json",
          "isSource": true,
          "original": {
            "value": "hidden"
          },
          "name": "ComponentsRadioButtonBaseOverflow",
          "attributes": {
            "category": "components",
            "type": "radio-button",
            "item": "base",
            "subitem": "overflow"
          },
          "path": [
            "components",
            "radio-button",
            "base",
            "overflow"
          ]
        },
        "background": {
          "value": "transparent",
          "filePath": "src/components/radiobutton.json",
          "isSource": true,
          "original": {
            "value": "transparent"
          },
          "name": "ComponentsRadioButtonBaseBackground",
          "attributes": {
            "category": "components",
            "type": "radio-button",
            "item": "base",
            "subitem": "background"
          },
          "path": [
            "components",
            "radio-button",
            "base",
            "background"
          ]
        },
        "transition": {
          "value": "all 0.2s ease",
          "filePath": "src/components/radiobutton.json",
          "isSource": true,
          "original": {
            "value": "all 0.2s ease"
          },
          "name": "ComponentsRadioButtonBaseTransition",
          "attributes": {
            "category": "components",
            "type": "radio-button",
            "item": "base",
            "subitem": "transition"
          },
          "path": [
            "components",
            "radio-button",
            "base",
            "transition"
          ]
        },
        "outline": {
          "value": "1px solid #e2e4e9",
          "filePath": "src/components/radiobutton.json",
          "isSource": true,
          "original": {
            "value": "{shape.border.width.sm} solid {color.border.neutral.default}"
          },
          "name": "ComponentsRadioButtonBaseOutline",
          "attributes": {
            "category": "components",
            "type": "radio-button",
            "item": "base",
            "subitem": "outline"
          },
          "path": [
            "components",
            "radio-button",
            "base",
            "outline"
          ]
        },
        "color": {
          "value": "#1e2129",
          "filePath": "src/components/radiobutton.json",
          "isSource": true,
          "original": {
            "value": "{color.text.strong._}"
          },
          "name": "ComponentsRadioButtonBaseColor",
          "attributes": {
            "category": "components",
            "type": "radio-button",
            "item": "base",
            "subitem": "color"
          },
          "path": [
            "components",
            "radio-button",
            "base",
            "color"
          ]
        }
      },
      "spacing": {
        "mb": {
          "value": "8px",
          "filePath": "src/components/radiobutton.json",
          "isSource": true,
          "original": {
            "value": "{spacing.layout.base}"
          },
          "name": "ComponentsRadioButtonSpacingMb",
          "attributes": {
            "category": "components",
            "type": "radio-button",
            "item": "spacing",
            "subitem": "mb"
          },
          "path": [
            "components",
            "radio-button",
            "spacing",
            "mb"
          ]
        }
      },
      "size": {
        "small": {
          "w": {
            "value": "12px",
            "filePath": "src/components/radiobutton.json",
            "isSource": true,
            "original": {
              "value": "{spacing.inner.base03}"
            },
            "name": "ComponentsRadioButtonSizeSmallW",
            "attributes": {
              "category": "components",
              "type": "radio-button",
              "item": "size",
              "subitem": "small",
              "state": "w"
            },
            "path": [
              "components",
              "radio-button",
              "size",
              "small",
              "w"
            ]
          },
          "h": {
            "value": "12px",
            "filePath": "src/components/radiobutton.json",
            "isSource": true,
            "original": {
              "value": "{spacing.inner.base03}"
            },
            "name": "ComponentsRadioButtonSizeSmallH",
            "attributes": {
              "category": "components",
              "type": "radio-button",
              "item": "size",
              "subitem": "small",
              "state": "h"
            },
            "path": [
              "components",
              "radio-button",
              "size",
              "small",
              "h"
            ]
          }
        },
        "medium": {
          "w": {
            "value": 15,
            "filePath": "src/components/radiobutton.json",
            "isSource": true,
            "original": {
              "value": 15
            },
            "name": "ComponentsRadioButtonSizeMediumW",
            "attributes": {
              "category": "components",
              "type": "radio-button",
              "item": "size",
              "subitem": "medium",
              "state": "w"
            },
            "path": [
              "components",
              "radio-button",
              "size",
              "medium",
              "w"
            ]
          },
          "h": {
            "value": 15,
            "filePath": "src/components/radiobutton.json",
            "isSource": true,
            "original": {
              "value": 15
            },
            "name": "ComponentsRadioButtonSizeMediumH",
            "attributes": {
              "category": "components",
              "type": "radio-button",
              "item": "size",
              "subitem": "medium",
              "state": "h"
            },
            "path": [
              "components",
              "radio-button",
              "size",
              "medium",
              "h"
            ]
          }
        },
        "large": {
          "w": {
            "value": 18,
            "filePath": "src/components/radiobutton.json",
            "isSource": true,
            "original": {
              "value": 18
            },
            "name": "ComponentsRadioButtonSizeLargeW",
            "attributes": {
              "category": "components",
              "type": "radio-button",
              "item": "size",
              "subitem": "large",
              "state": "w"
            },
            "path": [
              "components",
              "radio-button",
              "size",
              "large",
              "w"
            ]
          },
          "h": {
            "value": 18,
            "filePath": "src/components/radiobutton.json",
            "isSource": true,
            "original": {
              "value": 18
            },
            "name": "ComponentsRadioButtonSizeLargeH",
            "attributes": {
              "category": "components",
              "type": "radio-button",
              "item": "size",
              "subitem": "large",
              "state": "h"
            },
            "path": [
              "components",
              "radio-button",
              "size",
              "large",
              "h"
            ]
          }
        }
      },
      "text": {
        "size": {
          "small": {
            "marginLeft": {
              "value": "12px",
              "filePath": "src/components/radiobutton.json",
              "isSource": true,
              "original": {
                "value": "{spacing.inner.base03}"
              },
              "name": "ComponentsRadioButtonTextSizeSmallMarginLeft",
              "attributes": {
                "category": "components",
                "type": "radio-button",
                "item": "text",
                "subitem": "size",
                "state": "small"
              },
              "path": [
                "components",
                "radio-button",
                "text",
                "size",
                "small",
                "marginLeft"
              ]
            }
          },
          "medium": {
            "marginLeft": {
              "value": "20px",
              "filePath": "src/components/radiobutton.json",
              "isSource": true,
              "original": {
                "value": "{spacing.inner.base05}"
              },
              "name": "ComponentsRadioButtonTextSizeMediumMarginLeft",
              "attributes": {
                "category": "components",
                "type": "radio-button",
                "item": "text",
                "subitem": "size",
                "state": "medium"
              },
              "path": [
                "components",
                "radio-button",
                "text",
                "size",
                "medium",
                "marginLeft"
              ]
            }
          },
          "large": {
            "marginLeft": {
              "value": "calc(18px + 2px)",
              "filePath": "src/components/radiobutton.json",
              "isSource": true,
              "original": {
                "value": "calc({components.radio-button.size.large.w.value}px + 2px)"
              },
              "name": "ComponentsRadioButtonTextSizeLargeMarginLeft",
              "attributes": {
                "category": "components",
                "type": "radio-button",
                "item": "text",
                "subitem": "size",
                "state": "large"
              },
              "path": [
                "components",
                "radio-button",
                "text",
                "size",
                "large",
                "marginLeft"
              ]
            }
          }
        },
        "paddingLeft": {
          "value": "8px",
          "filePath": "src/components/radiobutton.json",
          "isSource": true,
          "original": {
            "value": "{spacing.inner.base02}"
          },
          "name": "ComponentsRadioButtonTextPaddingLeft",
          "attributes": {
            "category": "components",
            "type": "radio-button",
            "item": "text",
            "subitem": "paddingLeft"
          },
          "path": [
            "components",
            "radio-button",
            "text",
            "paddingLeft"
          ]
        },
        "color": {
          "value": "#1e2129",
          "filePath": "src/components/radiobutton.json",
          "isSource": true,
          "original": {
            "value": "{color.text.strong._}"
          },
          "name": "ComponentsRadioButtonTextColor",
          "attributes": {
            "category": "components",
            "type": "radio-button",
            "item": "text",
            "subitem": "color"
          },
          "path": [
            "components",
            "radio-button",
            "text",
            "color"
          ]
        }
      },
      "subtext": {
        "color": {
          "value": "#666b7a",
          "filePath": "src/components/radiobutton.json",
          "isSource": true,
          "original": {
            "value": "{color.text.secondary}"
          },
          "name": "ComponentsRadioButtonSubtextColor",
          "attributes": {
            "category": "components",
            "type": "radio-button",
            "item": "subtext",
            "subitem": "color"
          },
          "path": [
            "components",
            "radio-button",
            "subtext",
            "color"
          ]
        }
      },
      "textContainer": {
        "display": {
          "value": "flex",
          "filePath": "src/components/radiobutton.json",
          "isSource": true,
          "original": {
            "value": "flex"
          },
          "name": "ComponentsRadioButtonTextContainerDisplay",
          "attributes": {
            "category": "components",
            "type": "radio-button",
            "item": "textContainer",
            "subitem": "display"
          },
          "path": [
            "components",
            "radio-button",
            "textContainer",
            "display"
          ]
        },
        "flexDirection": {
          "value": "column",
          "filePath": "src/components/radiobutton.json",
          "isSource": true,
          "original": {
            "value": "column"
          },
          "name": "ComponentsRadioButtonTextContainerFlexDirection",
          "attributes": {
            "category": "components",
            "type": "radio-button",
            "item": "textContainer",
            "subitem": "flexDirection"
          },
          "path": [
            "components",
            "radio-button",
            "textContainer",
            "flexDirection"
          ]
        },
        "pl": {
          "value": "4px",
          "filePath": "src/components/radiobutton.json",
          "isSource": true,
          "original": {
            "value": "{spacing.inner.base}"
          },
          "name": "ComponentsRadioButtonTextContainerPl",
          "attributes": {
            "category": "components",
            "type": "radio-button",
            "item": "textContainer",
            "subitem": "pl"
          },
          "path": [
            "components",
            "radio-button",
            "textContainer",
            "pl"
          ]
        }
      },
      "groupLabel": {
        "color": {
          "value": "#666b7a",
          "filePath": "src/components/radiobutton.json",
          "isSource": true,
          "original": {
            "value": "{color.text.secondary}"
          },
          "name": "ComponentsRadioButtonGroupLabelColor",
          "attributes": {
            "category": "components",
            "type": "radio-button",
            "item": "groupLabel",
            "subitem": "color"
          },
          "path": [
            "components",
            "radio-button",
            "groupLabel",
            "color"
          ]
        },
        "mb": {
          "value": "8px",
          "filePath": "src/components/radiobutton.json",
          "isSource": true,
          "original": {
            "value": "{spacing.layout.base}"
          },
          "name": "ComponentsRadioButtonGroupLabelMb",
          "attributes": {
            "category": "components",
            "type": "radio-button",
            "item": "groupLabel",
            "subitem": "mb"
          },
          "path": [
            "components",
            "radio-button",
            "groupLabel",
            "mb"
          ]
        }
      },
      "boxContainer": {
        "size": {
          "small": {
            "w": {
              "value": "16px",
              "filePath": "src/components/radiobutton.json",
              "isSource": true,
              "original": {
                "value": "{spacing.inner.base04}"
              },
              "name": "ComponentsRadioButtonBoxContainerSizeSmallW",
              "attributes": {
                "category": "components",
                "type": "radio-button",
                "item": "boxContainer",
                "subitem": "size",
                "state": "small"
              },
              "path": [
                "components",
                "radio-button",
                "boxContainer",
                "size",
                "small",
                "w"
              ]
            },
            "h": {
              "value": "16px",
              "filePath": "src/components/radiobutton.json",
              "isSource": true,
              "original": {
                "value": "{spacing.inner.base04}"
              },
              "name": "ComponentsRadioButtonBoxContainerSizeSmallH",
              "attributes": {
                "category": "components",
                "type": "radio-button",
                "item": "boxContainer",
                "subitem": "size",
                "state": "small"
              },
              "path": [
                "components",
                "radio-button",
                "boxContainer",
                "size",
                "small",
                "h"
              ]
            }
          },
          "medium": {
            "w": {
              "value": "20px",
              "filePath": "src/components/radiobutton.json",
              "isSource": true,
              "original": {
                "value": "{spacing.inner.base05}"
              },
              "name": "ComponentsRadioButtonBoxContainerSizeMediumW",
              "attributes": {
                "category": "components",
                "type": "radio-button",
                "item": "boxContainer",
                "subitem": "size",
                "state": "medium"
              },
              "path": [
                "components",
                "radio-button",
                "boxContainer",
                "size",
                "medium",
                "w"
              ]
            },
            "h": {
              "value": "20px",
              "filePath": "src/components/radiobutton.json",
              "isSource": true,
              "original": {
                "value": "{spacing.inner.base05}"
              },
              "name": "ComponentsRadioButtonBoxContainerSizeMediumH",
              "attributes": {
                "category": "components",
                "type": "radio-button",
                "item": "boxContainer",
                "subitem": "size",
                "state": "medium"
              },
              "path": [
                "components",
                "radio-button",
                "boxContainer",
                "size",
                "medium",
                "h"
              ]
            }
          },
          "large": {
            "w": {
              "value": "24px",
              "filePath": "src/components/radiobutton.json",
              "isSource": true,
              "original": {
                "value": "{spacing.inner.base06}"
              },
              "name": "ComponentsRadioButtonBoxContainerSizeLargeW",
              "attributes": {
                "category": "components",
                "type": "radio-button",
                "item": "boxContainer",
                "subitem": "size",
                "state": "large"
              },
              "path": [
                "components",
                "radio-button",
                "boxContainer",
                "size",
                "large",
                "w"
              ]
            },
            "h": {
              "value": "24px",
              "filePath": "src/components/radiobutton.json",
              "isSource": true,
              "original": {
                "value": "{spacing.inner.base06}"
              },
              "name": "ComponentsRadioButtonBoxContainerSizeLargeH",
              "attributes": {
                "category": "components",
                "type": "radio-button",
                "item": "boxContainer",
                "subitem": "size",
                "state": "large"
              },
              "path": [
                "components",
                "radio-button",
                "boxContainer",
                "size",
                "large",
                "h"
              ]
            }
          }
        },
        "background": {
          "value": "transparent",
          "filePath": "src/components/radiobutton.json",
          "isSource": true,
          "original": {
            "value": "transparent"
          },
          "name": "ComponentsRadioButtonBoxContainerBackground",
          "attributes": {
            "category": "components",
            "type": "radio-button",
            "item": "boxContainer",
            "subitem": "background"
          },
          "path": [
            "components",
            "radio-button",
            "boxContainer",
            "background"
          ]
        },
        "borderRadius": {
          "value": "4px",
          "filePath": "src/components/radiobutton.json",
          "isSource": true,
          "original": {
            "value": "{shape.border.radius.small}"
          },
          "name": "ComponentsRadioButtonBoxContainerBorderRadius",
          "attributes": {
            "category": "components",
            "type": "radio-button",
            "item": "boxContainer",
            "subitem": "borderRadius"
          },
          "path": [
            "components",
            "radio-button",
            "boxContainer",
            "borderRadius"
          ]
        }
      },
      "box": {
        "size": {
          "small": {
            "margin": {
              "value": "2px",
              "filePath": "src/components/radiobutton.json",
              "isSource": true,
              "original": {
                "value": "2px"
              },
              "name": "ComponentsRadioButtonBoxSizeSmallMargin",
              "attributes": {
                "category": "components",
                "type": "radio-button",
                "item": "box",
                "subitem": "size",
                "state": "small"
              },
              "path": [
                "components",
                "radio-button",
                "box",
                "size",
                "small",
                "margin"
              ]
            }
          },
          "medium": {
            "margin": {
              "value": "2.5px",
              "filePath": "src/components/radiobutton.json",
              "isSource": true,
              "original": {
                "value": "2.5px"
              },
              "name": "ComponentsRadioButtonBoxSizeMediumMargin",
              "attributes": {
                "category": "components",
                "type": "radio-button",
                "item": "box",
                "subitem": "size",
                "state": "medium"
              },
              "path": [
                "components",
                "radio-button",
                "box",
                "size",
                "medium",
                "margin"
              ]
            }
          },
          "large": {
            "margin": {
              "value": "3px",
              "filePath": "src/components/radiobutton.json",
              "isSource": true,
              "original": {
                "value": "3px"
              },
              "name": "ComponentsRadioButtonBoxSizeLargeMargin",
              "attributes": {
                "category": "components",
                "type": "radio-button",
                "item": "box",
                "subitem": "size",
                "state": "large"
              },
              "path": [
                "components",
                "radio-button",
                "box",
                "size",
                "large",
                "margin"
              ]
            }
          }
        }
      },
      "disabled": {
        "label": {
          "color": {
            "value": "#9ca1b0",
            "filePath": "src/components/radiobutton.json",
            "isSource": true,
            "original": {
              "value": "{color.text.disabled}"
            },
            "name": "ComponentsRadioButtonDisabledLabelColor",
            "attributes": {
              "category": "components",
              "type": "radio-button",
              "item": "disabled",
              "subitem": "label",
              "state": "color"
            },
            "path": [
              "components",
              "radio-button",
              "disabled",
              "label",
              "color"
            ]
          },
          "cursor": {
            "value": "not-allowed",
            "filePath": "src/components/radiobutton.json",
            "isSource": true,
            "original": {
              "value": "not-allowed"
            },
            "name": "ComponentsRadioButtonDisabledLabelCursor",
            "attributes": {
              "category": "components",
              "type": "radio-button",
              "item": "disabled",
              "subitem": "label",
              "state": "cursor"
            },
            "path": [
              "components",
              "radio-button",
              "disabled",
              "label",
              "cursor"
            ]
          }
        },
        "boxContainer": {
          "background": {
            "value": "transparent",
            "filePath": "src/components/radiobutton.json",
            "isSource": true,
            "original": {
              "value": "transparent"
            },
            "name": "ComponentsRadioButtonDisabledBoxContainerBackground",
            "attributes": {
              "category": "components",
              "type": "radio-button",
              "item": "disabled",
              "subitem": "boxContainer",
              "state": "background"
            },
            "path": [
              "components",
              "radio-button",
              "disabled",
              "boxContainer",
              "background"
            ]
          }
        },
        "box": {
          "outline": {
            "value": "1px solid #ebecef",
            "filePath": "src/components/radiobutton.json",
            "isSource": true,
            "original": {
              "value": "{shape.border.width.sm} solid {color.border.disabled}"
            },
            "name": "ComponentsRadioButtonDisabledBoxOutline",
            "attributes": {
              "category": "components",
              "type": "radio-button",
              "item": "disabled",
              "subitem": "box",
              "state": "outline"
            },
            "path": [
              "components",
              "radio-button",
              "disabled",
              "box",
              "outline"
            ]
          },
          "cursor": {
            "value": "not-allowed",
            "filePath": "src/components/radiobutton.json",
            "isSource": true,
            "original": {
              "value": "not-allowed"
            },
            "name": "ComponentsRadioButtonDisabledBoxCursor",
            "attributes": {
              "category": "components",
              "type": "radio-button",
              "item": "disabled",
              "subitem": "box",
              "state": "cursor"
            },
            "path": [
              "components",
              "radio-button",
              "disabled",
              "box",
              "cursor"
            ]
          }
        },
        "checkmark": {
          "outline": {
            "value": "1px solid #9ca1b0",
            "filePath": "src/components/radiobutton.json",
            "isSource": true,
            "original": {
              "value": "{shape.border.width.sm} solid {color.icon.disabled}"
            },
            "name": "ComponentsRadioButtonDisabledCheckmarkOutline",
            "attributes": {
              "category": "components",
              "type": "radio-button",
              "item": "disabled",
              "subitem": "checkmark",
              "state": "outline"
            },
            "path": [
              "components",
              "radio-button",
              "disabled",
              "checkmark",
              "outline"
            ]
          },
          "borderRadius": {
            "value": "50%",
            "filePath": "src/components/radiobutton.json",
            "isSource": true,
            "original": {
              "value": "{shape.border.radius.large}"
            },
            "name": "ComponentsRadioButtonDisabledCheckmarkBorderRadius",
            "attributes": {
              "category": "components",
              "type": "radio-button",
              "item": "disabled",
              "subitem": "checkmark",
              "state": "borderRadius"
            },
            "path": [
              "components",
              "radio-button",
              "disabled",
              "checkmark",
              "borderRadius"
            ]
          },
          "background": {
            "value": "#9ca1b0",
            "filePath": "src/components/radiobutton.json",
            "isSource": true,
            "original": {
              "value": "{color.icon.disabled}"
            },
            "name": "ComponentsRadioButtonDisabledCheckmarkBackground",
            "attributes": {
              "category": "components",
              "type": "radio-button",
              "item": "disabled",
              "subitem": "checkmark",
              "state": "background"
            },
            "path": [
              "components",
              "radio-button",
              "disabled",
              "checkmark",
              "background"
            ]
          }
        }
      },
      "checkmark": {
        "state": {
          "unselected": {
            "outline": {
              "value": "solid #666b7a",
              "filePath": "src/components/radiobutton.json",
              "isSource": true,
              "original": {
                "value": "solid {color.icon.secondary}"
              },
              "name": "ComponentsRadioButtonCheckmarkStateUnselectedOutline",
              "attributes": {
                "category": "components",
                "type": "radio-button",
                "item": "checkmark",
                "subitem": "state",
                "state": "unselected"
              },
              "path": [
                "components",
                "radio-button",
                "checkmark",
                "state",
                "unselected",
                "outline"
              ]
            }
          },
          "selected": {
            "borderRadius": {
              "value": "50%",
              "filePath": "src/components/radiobutton.json",
              "isSource": true,
              "original": {
                "value": "{shape.border.radius.large}"
              },
              "name": "ComponentsRadioButtonCheckmarkStateSelectedBorderRadius",
              "attributes": {
                "category": "components",
                "type": "radio-button",
                "item": "checkmark",
                "subitem": "state",
                "state": "selected"
              },
              "path": [
                "components",
                "radio-button",
                "checkmark",
                "state",
                "selected",
                "borderRadius"
              ]
            },
            "background": {
              "value": "#1e2129",
              "filePath": "src/components/radiobutton.json",
              "isSource": true,
              "original": {
                "value": "{color.icon.strong._}"
              },
              "name": "ComponentsRadioButtonCheckmarkStateSelectedBackground",
              "attributes": {
                "category": "components",
                "type": "radio-button",
                "item": "checkmark",
                "subitem": "state",
                "state": "selected"
              },
              "path": [
                "components",
                "radio-button",
                "checkmark",
                "state",
                "selected",
                "background"
              ]
            }
          },
          "error": {
            "borderRadius": {
              "value": "50%",
              "filePath": "src/components/radiobutton.json",
              "isSource": true,
              "original": {
                "value": "{shape.border.radius.large}"
              },
              "name": "ComponentsRadioButtonCheckmarkStateErrorBorderRadius",
              "attributes": {
                "category": "components",
                "type": "radio-button",
                "item": "checkmark",
                "subitem": "state",
                "state": "error"
              },
              "path": [
                "components",
                "radio-button",
                "checkmark",
                "state",
                "error",
                "borderRadius"
              ]
            },
            "background": {
              "value": "#1e2129",
              "filePath": "src/components/radiobutton.json",
              "isSource": true,
              "original": {
                "value": "{color.icon.strong._}"
              },
              "name": "ComponentsRadioButtonCheckmarkStateErrorBackground",
              "attributes": {
                "category": "components",
                "type": "radio-button",
                "item": "checkmark",
                "subitem": "state",
                "state": "error"
              },
              "path": [
                "components",
                "radio-button",
                "checkmark",
                "state",
                "error",
                "background"
              ]
            }
          },
          "disabled": {
            "cursor": {
              "value": "not-allowed",
              "filePath": "src/components/radiobutton.json",
              "isSource": true,
              "original": {
                "value": "not-allowed"
              },
              "name": "ComponentsRadioButtonCheckmarkStateDisabledCursor",
              "attributes": {
                "category": "components",
                "type": "radio-button",
                "item": "checkmark",
                "subitem": "state",
                "state": "disabled"
              },
              "path": [
                "components",
                "radio-button",
                "checkmark",
                "state",
                "disabled",
                "cursor"
              ]
            }
          }
        },
        "size": {
          "small": {
            "margin": {
              "value": "2px",
              "filePath": "src/components/radiobutton.json",
              "isSource": true,
              "original": {
                "value": "{components.radio-button.box.size.small.margin.value}"
              },
              "name": "ComponentsRadioButtonCheckmarkSizeSmallMargin",
              "attributes": {
                "category": "components",
                "type": "radio-button",
                "item": "checkmark",
                "subitem": "size",
                "state": "small"
              },
              "path": [
                "components",
                "radio-button",
                "checkmark",
                "size",
                "small",
                "margin"
              ]
            }
          },
          "medium": {
            "margin": {
              "value": "2.5px",
              "filePath": "src/components/radiobutton.json",
              "isSource": true,
              "original": {
                "value": "{components.radio-button.box.size.medium.margin.value}"
              },
              "name": "ComponentsRadioButtonCheckmarkSizeMediumMargin",
              "attributes": {
                "category": "components",
                "type": "radio-button",
                "item": "checkmark",
                "subitem": "size",
                "state": "medium"
              },
              "path": [
                "components",
                "radio-button",
                "checkmark",
                "size",
                "medium",
                "margin"
              ]
            }
          },
          "large": {
            "margin": {
              "value": "3px",
              "filePath": "src/components/radiobutton.json",
              "isSource": true,
              "original": {
                "value": "{components.radio-button.box.size.large.margin.value}"
              },
              "name": "ComponentsRadioButtonCheckmarkSizeLargeMargin",
              "attributes": {
                "category": "components",
                "type": "radio-button",
                "item": "checkmark",
                "subitem": "size",
                "state": "large"
              },
              "path": [
                "components",
                "radio-button",
                "checkmark",
                "size",
                "large",
                "margin"
              ]
            }
          }
        }
      },
      "state": {
        "unselected": {
          "box": {
            "background": {
              "value": "transparent",
              "filePath": "src/components/radiobutton.json",
              "isSource": true,
              "original": {
                "value": "transparent"
              },
              "name": "ComponentsRadioButtonStateUnselectedBoxBackground",
              "attributes": {
                "category": "components",
                "type": "radio-button",
                "item": "state",
                "subitem": "unselected",
                "state": "box"
              },
              "path": [
                "components",
                "radio-button",
                "state",
                "unselected",
                "box",
                "background"
              ]
            }
          },
          "boxContainer": {
            "background": {
              "_": {
                "value": "transparent",
                "filePath": "src/components/radiobutton.json",
                "isSource": true,
                "original": {
                  "value": "transparent"
                },
                "name": "ComponentsRadioButtonStateUnselectedBoxContainerBackground",
                "attributes": {
                  "category": "components",
                  "type": "radio-button",
                  "item": "state",
                  "subitem": "unselected",
                  "state": "boxContainer"
                },
                "path": [
                  "components",
                  "radio-button",
                  "state",
                  "unselected",
                  "boxContainer",
                  "background",
                  "_"
                ]
              },
              "hover": {
                "value": "#f6f7f9",
                "filePath": "src/components/radiobutton.json",
                "isSource": true,
                "original": {
                  "value": "{color.background.action.outline.hover}"
                },
                "name": "ComponentsRadioButtonStateUnselectedBoxContainerBackgroundHover",
                "attributes": {
                  "category": "components",
                  "type": "radio-button",
                  "item": "state",
                  "subitem": "unselected",
                  "state": "boxContainer"
                },
                "path": [
                  "components",
                  "radio-button",
                  "state",
                  "unselected",
                  "boxContainer",
                  "background",
                  "hover"
                ]
              }
            }
          }
        },
        "selected": {
          "box": {
            "outline": {
              "value": "1px solid #1e2129",
              "filePath": "src/components/radiobutton.json",
              "isSource": true,
              "original": {
                "value": "{shape.border.width.sm} solid {color.border.strong._}"
              },
              "name": "ComponentsRadioButtonStateSelectedBoxOutline",
              "attributes": {
                "category": "components",
                "type": "radio-button",
                "item": "state",
                "subitem": "selected",
                "state": "box"
              },
              "path": [
                "components",
                "radio-button",
                "state",
                "selected",
                "box",
                "outline"
              ]
            }
          },
          "boxContainer": {
            "background": {
              "_": {
                "value": "transparent",
                "filePath": "src/components/radiobutton.json",
                "isSource": true,
                "original": {
                  "value": "transparent"
                },
                "name": "ComponentsRadioButtonStateSelectedBoxContainerBackground",
                "attributes": {
                  "category": "components",
                  "type": "radio-button",
                  "item": "state",
                  "subitem": "selected",
                  "state": "boxContainer"
                },
                "path": [
                  "components",
                  "radio-button",
                  "state",
                  "selected",
                  "boxContainer",
                  "background",
                  "_"
                ]
              },
              "hover": {
                "hover": {
                  "value": "#f6f7f9",
                  "filePath": "src/components/radiobutton.json",
                  "isSource": true,
                  "original": {
                    "value": "{color.background.action.outline.hover}"
                  },
                  "name": "ComponentsRadioButtonStateSelectedBoxContainerBackgroundHoverHover",
                  "attributes": {
                    "category": "components",
                    "type": "radio-button",
                    "item": "state",
                    "subitem": "selected",
                    "state": "boxContainer"
                  },
                  "path": [
                    "components",
                    "radio-button",
                    "state",
                    "selected",
                    "boxContainer",
                    "background",
                    "hover",
                    "hover"
                  ]
                }
              }
            }
          }
        },
        "error": {
          "box": {
            "background": {
              "value": "transparent",
              "filePath": "src/components/radiobutton.json",
              "isSource": true,
              "original": {
                "value": "transparent"
              },
              "name": "ComponentsRadioButtonStateErrorBoxBackground",
              "attributes": {
                "category": "components",
                "type": "radio-button",
                "item": "state",
                "subitem": "error",
                "state": "box"
              },
              "path": [
                "components",
                "radio-button",
                "state",
                "error",
                "box",
                "background"
              ]
            },
            "outlineColor": {
              "value": "#ef4343",
              "filePath": "src/components/radiobutton.json",
              "isSource": true,
              "original": {
                "value": "{color.border.danger}"
              },
              "name": "ComponentsRadioButtonStateErrorBoxOutlineColor",
              "attributes": {
                "category": "components",
                "type": "radio-button",
                "item": "state",
                "subitem": "error",
                "state": "box"
              },
              "path": [
                "components",
                "radio-button",
                "state",
                "error",
                "box",
                "outlineColor"
              ]
            }
          },
          "boxContainer": {
            "background": {
              "value": "transparent",
              "filePath": "src/components/radiobutton.json",
              "isSource": true,
              "original": {
                "value": "transparent"
              },
              "name": "ComponentsRadioButtonStateErrorBoxContainerBackground",
              "attributes": {
                "category": "components",
                "type": "radio-button",
                "item": "state",
                "subitem": "error",
                "state": "boxContainer"
              },
              "path": [
                "components",
                "radio-button",
                "state",
                "error",
                "boxContainer",
                "background"
              ]
            }
          }
        }
      },
      "variant": {
        "default": {
          "label": {
            "padding": {
              "value": 0,
              "filePath": "src/components/radiobutton.json",
              "isSource": true,
              "original": {
                "value": 0
              },
              "name": "ComponentsRadioButtonVariantDefaultLabelPadding",
              "attributes": {
                "category": "components",
                "type": "radio-button",
                "item": "variant",
                "subitem": "default",
                "state": "label"
              },
              "path": [
                "components",
                "radio-button",
                "variant",
                "default",
                "label",
                "padding"
              ]
            }
          },
          "disabled": {
            "label": {
              "outlineColor": {
                "value": "transparent",
                "filePath": "src/components/radiobutton.json",
                "isSource": true,
                "original": {
                  "value": "transparent"
                },
                "name": "ComponentsRadioButtonVariantDefaultDisabledLabelOutlineColor",
                "attributes": {
                  "category": "components",
                  "type": "radio-button",
                  "item": "variant",
                  "subitem": "default",
                  "state": "disabled"
                },
                "path": [
                  "components",
                  "radio-button",
                  "variant",
                  "default",
                  "disabled",
                  "label",
                  "outlineColor"
                ]
              }
            },
            "state": {
              "unselected": {
                "label": {
                  "color": {
                    "value": "#9ca1b0",
                    "filePath": "src/components/radiobutton.json",
                    "isSource": true,
                    "original": {
                      "value": "{color.text.disabled}"
                    },
                    "name": "ComponentsRadioButtonVariantDefaultDisabledStateUnselectedLabelColor",
                    "attributes": {
                      "category": "components",
                      "type": "radio-button",
                      "item": "variant",
                      "subitem": "default",
                      "state": "disabled"
                    },
                    "path": [
                      "components",
                      "radio-button",
                      "variant",
                      "default",
                      "disabled",
                      "state",
                      "unselected",
                      "label",
                      "color"
                    ]
                  }
                },
                "subtext": {
                  "color": {
                    "value": "#9ca1b0",
                    "filePath": "src/components/radiobutton.json",
                    "isSource": true,
                    "original": {
                      "value": "{color.text.disabled}"
                    },
                    "name": "ComponentsRadioButtonVariantDefaultDisabledStateUnselectedSubtextColor",
                    "attributes": {
                      "category": "components",
                      "type": "radio-button",
                      "item": "variant",
                      "subitem": "default",
                      "state": "disabled"
                    },
                    "path": [
                      "components",
                      "radio-button",
                      "variant",
                      "default",
                      "disabled",
                      "state",
                      "unselected",
                      "subtext",
                      "color"
                    ]
                  }
                }
              },
              "selected": {
                "label": {
                  "color": {
                    "value": "#9ca1b0",
                    "filePath": "src/components/radiobutton.json",
                    "isSource": true,
                    "original": {
                      "value": "{color.text.disabled}"
                    },
                    "name": "ComponentsRadioButtonVariantDefaultDisabledStateSelectedLabelColor",
                    "attributes": {
                      "category": "components",
                      "type": "radio-button",
                      "item": "variant",
                      "subitem": "default",
                      "state": "disabled"
                    },
                    "path": [
                      "components",
                      "radio-button",
                      "variant",
                      "default",
                      "disabled",
                      "state",
                      "selected",
                      "label",
                      "color"
                    ]
                  }
                },
                "subtext": {
                  "color": {
                    "value": "#9ca1b0",
                    "filePath": "src/components/radiobutton.json",
                    "isSource": true,
                    "original": {
                      "value": "{color.text.disabled}"
                    },
                    "name": "ComponentsRadioButtonVariantDefaultDisabledStateSelectedSubtextColor",
                    "attributes": {
                      "category": "components",
                      "type": "radio-button",
                      "item": "variant",
                      "subitem": "default",
                      "state": "disabled"
                    },
                    "path": [
                      "components",
                      "radio-button",
                      "variant",
                      "default",
                      "disabled",
                      "state",
                      "selected",
                      "subtext",
                      "color"
                    ]
                  }
                }
              },
              "error": {
                "label": {
                  "color": {
                    "value": "#9ca1b0",
                    "filePath": "src/components/radiobutton.json",
                    "isSource": true,
                    "original": {
                      "value": "{color.text.disabled}"
                    },
                    "name": "ComponentsRadioButtonVariantDefaultDisabledStateErrorLabelColor",
                    "attributes": {
                      "category": "components",
                      "type": "radio-button",
                      "item": "variant",
                      "subitem": "default",
                      "state": "disabled"
                    },
                    "path": [
                      "components",
                      "radio-button",
                      "variant",
                      "default",
                      "disabled",
                      "state",
                      "error",
                      "label",
                      "color"
                    ]
                  }
                },
                "subtext": {
                  "color": {
                    "value": "#9ca1b0",
                    "filePath": "src/components/radiobutton.json",
                    "isSource": true,
                    "original": {
                      "value": "{color.text.disabled}"
                    },
                    "name": "ComponentsRadioButtonVariantDefaultDisabledStateErrorSubtextColor",
                    "attributes": {
                      "category": "components",
                      "type": "radio-button",
                      "item": "variant",
                      "subitem": "default",
                      "state": "disabled"
                    },
                    "path": [
                      "components",
                      "radio-button",
                      "variant",
                      "default",
                      "disabled",
                      "state",
                      "error",
                      "subtext",
                      "color"
                    ]
                  }
                }
              }
            }
          },
          "labelState": {
            "unselected": {
              "background": {
                "value": "transparent",
                "filePath": "src/components/radiobutton.json",
                "isSource": true,
                "original": {
                  "value": "transparent"
                },
                "name": "ComponentsRadioButtonVariantDefaultLabelStateUnselectedBackground",
                "attributes": {
                  "category": "components",
                  "type": "radio-button",
                  "item": "variant",
                  "subitem": "default",
                  "state": "labelState"
                },
                "path": [
                  "components",
                  "radio-button",
                  "variant",
                  "default",
                  "labelState",
                  "unselected",
                  "background"
                ]
              }
            },
            "selected": {
              "outline": {
                "value": "transparent",
                "filePath": "src/components/radiobutton.json",
                "isSource": true,
                "original": {
                  "value": "transparent"
                },
                "name": "ComponentsRadioButtonVariantDefaultLabelStateSelectedOutline",
                "attributes": {
                  "category": "components",
                  "type": "radio-button",
                  "item": "variant",
                  "subitem": "default",
                  "state": "labelState"
                },
                "path": [
                  "components",
                  "radio-button",
                  "variant",
                  "default",
                  "labelState",
                  "selected",
                  "outline"
                ]
              }
            },
            "error": {
              "outlineColor": {
                "value": "#ef4343",
                "filePath": "src/components/radiobutton.json",
                "isSource": true,
                "original": {
                  "value": "{color.border.danger}"
                },
                "name": "ComponentsRadioButtonVariantDefaultLabelStateErrorOutlineColor",
                "attributes": {
                  "category": "components",
                  "type": "radio-button",
                  "item": "variant",
                  "subitem": "default",
                  "state": "labelState"
                },
                "path": [
                  "components",
                  "radio-button",
                  "variant",
                  "default",
                  "labelState",
                  "error",
                  "outlineColor"
                ]
              }
            }
          }
        },
        "boxed": {
          "label": {
            "outline": {
              "_": {
                "value": "1px solid #e2e4e9",
                "filePath": "src/components/radiobutton.json",
                "isSource": true,
                "original": {
                  "value": "{shape.border.width.sm.value} solid {color.border.neutral.default}"
                },
                "name": "ComponentsRadioButtonVariantBoxedLabelOutline",
                "attributes": {
                  "category": "components",
                  "type": "radio-button",
                  "item": "variant",
                  "subitem": "boxed",
                  "state": "label"
                },
                "path": [
                  "components",
                  "radio-button",
                  "variant",
                  "boxed",
                  "label",
                  "outline",
                  "_"
                ]
              },
              "hover": {
                "value": "1px solid #e2e4e9",
                "filePath": "src/components/radiobutton.json",
                "isSource": true,
                "original": {
                  "value": "{shape.border.width.sm.value} solid {color.border.neutral.default}"
                },
                "name": "ComponentsRadioButtonVariantBoxedLabelOutlineHover",
                "attributes": {
                  "category": "components",
                  "type": "radio-button",
                  "item": "variant",
                  "subitem": "boxed",
                  "state": "label"
                },
                "path": [
                  "components",
                  "radio-button",
                  "variant",
                  "boxed",
                  "label",
                  "outline",
                  "hover"
                ]
              }
            },
            "borderRadius": {
              "value": "4px",
              "filePath": "src/components/radiobutton.json",
              "isSource": true,
              "original": {
                "value": "{shape.border.radius.small}"
              },
              "name": "ComponentsRadioButtonVariantBoxedLabelBorderRadius",
              "attributes": {
                "category": "components",
                "type": "radio-button",
                "item": "variant",
                "subitem": "boxed",
                "state": "label"
              },
              "path": [
                "components",
                "radio-button",
                "variant",
                "boxed",
                "label",
                "borderRadius"
              ]
            },
            "padding": {
              "value": "8px 12px",
              "filePath": "src/components/radiobutton.json",
              "isSource": true,
              "original": {
                "value": "{spacing.inner.base02} {spacing.inner.base03}"
              },
              "name": "ComponentsRadioButtonVariantBoxedLabelPadding",
              "attributes": {
                "category": "components",
                "type": "radio-button",
                "item": "variant",
                "subitem": "boxed",
                "state": "label"
              },
              "path": [
                "components",
                "radio-button",
                "variant",
                "boxed",
                "label",
                "padding"
              ]
            }
          },
          "disabled": {
            "label": {
              "outlineColor": {
                "_": {
                  "value": "#ebecef",
                  "filePath": "src/components/radiobutton.json",
                  "isSource": true,
                  "original": {
                    "value": "{color.border.disabled}"
                  },
                  "name": "ComponentsRadioButtonVariantBoxedDisabledLabelOutlineColor",
                  "attributes": {
                    "category": "components",
                    "type": "radio-button",
                    "item": "variant",
                    "subitem": "boxed",
                    "state": "disabled"
                  },
                  "path": [
                    "components",
                    "radio-button",
                    "variant",
                    "boxed",
                    "disabled",
                    "label",
                    "outlineColor",
                    "_"
                  ]
                },
                "hover": {
                  "value": "#ebecef",
                  "filePath": "src/components/radiobutton.json",
                  "isSource": true,
                  "original": {
                    "value": "{color.border.disabled}"
                  },
                  "name": "ComponentsRadioButtonVariantBoxedDisabledLabelOutlineColorHover",
                  "attributes": {
                    "category": "components",
                    "type": "radio-button",
                    "item": "variant",
                    "subitem": "boxed",
                    "state": "disabled"
                  },
                  "path": [
                    "components",
                    "radio-button",
                    "variant",
                    "boxed",
                    "disabled",
                    "label",
                    "outlineColor",
                    "hover"
                  ]
                }
              }
            },
            "state": {
              "unselected": {
                "label": {
                  "color": {
                    "value": "#9ca1b0",
                    "filePath": "src/components/radiobutton.json",
                    "isSource": true,
                    "original": {
                      "value": "{color.text.disabled}"
                    },
                    "name": "ComponentsRadioButtonVariantBoxedDisabledStateUnselectedLabelColor",
                    "attributes": {
                      "category": "components",
                      "type": "radio-button",
                      "item": "variant",
                      "subitem": "boxed",
                      "state": "disabled"
                    },
                    "path": [
                      "components",
                      "radio-button",
                      "variant",
                      "boxed",
                      "disabled",
                      "state",
                      "unselected",
                      "label",
                      "color"
                    ]
                  }
                },
                "subtext": {
                  "color": {
                    "value": "#9ca1b0",
                    "filePath": "src/components/radiobutton.json",
                    "isSource": true,
                    "original": {
                      "value": "{color.text.disabled}"
                    },
                    "name": "ComponentsRadioButtonVariantBoxedDisabledStateUnselectedSubtextColor",
                    "attributes": {
                      "category": "components",
                      "type": "radio-button",
                      "item": "variant",
                      "subitem": "boxed",
                      "state": "disabled"
                    },
                    "path": [
                      "components",
                      "radio-button",
                      "variant",
                      "boxed",
                      "disabled",
                      "state",
                      "unselected",
                      "subtext",
                      "color"
                    ]
                  }
                }
              },
              "selected": {
                "label": {
                  "color": {
                    "value": "#1e2129",
                    "filePath": "src/components/radiobutton.json",
                    "isSource": true,
                    "original": {
                      "value": "{color.text.strong._}"
                    },
                    "name": "ComponentsRadioButtonVariantBoxedDisabledStateSelectedLabelColor",
                    "attributes": {
                      "category": "components",
                      "type": "radio-button",
                      "item": "variant",
                      "subitem": "boxed",
                      "state": "disabled"
                    },
                    "path": [
                      "components",
                      "radio-button",
                      "variant",
                      "boxed",
                      "disabled",
                      "state",
                      "selected",
                      "label",
                      "color"
                    ]
                  }
                },
                "subtext": {
                  "color": {
                    "value": "#666b7a",
                    "filePath": "src/components/radiobutton.json",
                    "isSource": true,
                    "original": {
                      "value": "{components.radio-button.subtext.color.value}"
                    },
                    "name": "ComponentsRadioButtonVariantBoxedDisabledStateSelectedSubtextColor",
                    "attributes": {
                      "category": "components",
                      "type": "radio-button",
                      "item": "variant",
                      "subitem": "boxed",
                      "state": "disabled"
                    },
                    "path": [
                      "components",
                      "radio-button",
                      "variant",
                      "boxed",
                      "disabled",
                      "state",
                      "selected",
                      "subtext",
                      "color"
                    ]
                  }
                }
              },
              "error": {
                "label": {
                  "color": {
                    "value": "#9ca1b0",
                    "filePath": "src/components/radiobutton.json",
                    "isSource": true,
                    "original": {
                      "value": "{color.text.disabled}"
                    },
                    "name": "ComponentsRadioButtonVariantBoxedDisabledStateErrorLabelColor",
                    "attributes": {
                      "category": "components",
                      "type": "radio-button",
                      "item": "variant",
                      "subitem": "boxed",
                      "state": "disabled"
                    },
                    "path": [
                      "components",
                      "radio-button",
                      "variant",
                      "boxed",
                      "disabled",
                      "state",
                      "error",
                      "label",
                      "color"
                    ]
                  }
                },
                "subtext": {
                  "color": {
                    "value": "#9ca1b0",
                    "filePath": "src/components/radiobutton.json",
                    "isSource": true,
                    "original": {
                      "value": "{color.text.assistive}"
                    },
                    "name": "ComponentsRadioButtonVariantBoxedDisabledStateErrorSubtextColor",
                    "attributes": {
                      "category": "components",
                      "type": "radio-button",
                      "item": "variant",
                      "subitem": "boxed",
                      "state": "disabled"
                    },
                    "path": [
                      "components",
                      "radio-button",
                      "variant",
                      "boxed",
                      "disabled",
                      "state",
                      "error",
                      "subtext",
                      "color"
                    ]
                  }
                }
              }
            }
          },
          "labelState": {
            "unselected": {
              "background": {
                "_": {
                  "value": "transparent",
                  "filePath": "src/components/radiobutton.json",
                  "isSource": true,
                  "original": {
                    "value": "transparent"
                  },
                  "name": "ComponentsRadioButtonVariantBoxedLabelStateUnselectedBackground",
                  "attributes": {
                    "category": "components",
                    "type": "radio-button",
                    "item": "variant",
                    "subitem": "boxed",
                    "state": "labelState"
                  },
                  "path": [
                    "components",
                    "radio-button",
                    "variant",
                    "boxed",
                    "labelState",
                    "unselected",
                    "background",
                    "_"
                  ]
                },
                "hover": {
                  "value": "#f6f7f9",
                  "filePath": "src/components/radiobutton.json",
                  "isSource": true,
                  "original": {
                    "value": "{color.background.action.outline.hover}"
                  },
                  "name": "ComponentsRadioButtonVariantBoxedLabelStateUnselectedBackgroundHover",
                  "attributes": {
                    "category": "components",
                    "type": "radio-button",
                    "item": "variant",
                    "subitem": "boxed",
                    "state": "labelState"
                  },
                  "path": [
                    "components",
                    "radio-button",
                    "variant",
                    "boxed",
                    "labelState",
                    "unselected",
                    "background",
                    "hover"
                  ]
                },
                "disabled": {
                  "value": "transparent",
                  "filePath": "src/components/radiobutton.json",
                  "isSource": true,
                  "original": {
                    "value": "transparent"
                  },
                  "name": "ComponentsRadioButtonVariantBoxedLabelStateUnselectedBackgroundDisabled",
                  "attributes": {
                    "category": "components",
                    "type": "radio-button",
                    "item": "variant",
                    "subitem": "boxed",
                    "state": "labelState"
                  },
                  "path": [
                    "components",
                    "radio-button",
                    "variant",
                    "boxed",
                    "labelState",
                    "unselected",
                    "background",
                    "disabled"
                  ]
                }
              }
            },
            "selected": {
              "outline": {
                "value": "1px solid #3c83f6",
                "filePath": "src/components/radiobutton.json",
                "isSource": true,
                "original": {
                  "value": "{shape.border.width.sm.value} solid {color.border.selected}"
                },
                "name": "ComponentsRadioButtonVariantBoxedLabelStateSelectedOutline",
                "attributes": {
                  "category": "components",
                  "type": "radio-button",
                  "item": "variant",
                  "subitem": "boxed",
                  "state": "labelState"
                },
                "path": [
                  "components",
                  "radio-button",
                  "variant",
                  "boxed",
                  "labelState",
                  "selected",
                  "outline"
                ]
              }
            },
            "error": {
              "outline": {
                "value": "1px solid #ef4343",
                "filePath": "src/components/radiobutton.json",
                "isSource": true,
                "original": {
                  "value": "{shape.border.width.sm.value} solid {color.border.danger}"
                },
                "name": "ComponentsRadioButtonVariantBoxedLabelStateErrorOutline",
                "attributes": {
                  "category": "components",
                  "type": "radio-button",
                  "item": "variant",
                  "subitem": "boxed",
                  "state": "labelState"
                },
                "path": [
                  "components",
                  "radio-button",
                  "variant",
                  "boxed",
                  "labelState",
                  "error",
                  "outline"
                ]
              }
            }
          }
        }
      }
    },
    "rounded-button": {
      "base": {
        "border-radius": {
          "value": "9999px",
          "filePath": "src/components/rounded-button.json",
          "isSource": true,
          "original": {
            "value": "{shape.border.radius.xlarge}"
          },
          "name": "ComponentsRoundedButtonBaseBorderRadius",
          "attributes": {
            "category": "components",
            "type": "rounded-button",
            "item": "base",
            "subitem": "border-radius"
          },
          "path": [
            "components",
            "rounded-button",
            "base",
            "border-radius"
          ]
        },
        "padding": {
          "value": "4px 12px",
          "filePath": "src/components/rounded-button.json",
          "isSource": true,
          "original": {
            "value": "{spacing.inner.base.value} {spacing.inner.base03.value}"
          },
          "name": "ComponentsRoundedButtonBasePadding",
          "attributes": {
            "category": "components",
            "type": "rounded-button",
            "item": "base",
            "subitem": "padding"
          },
          "path": [
            "components",
            "rounded-button",
            "base",
            "padding"
          ]
        },
        "background": {
          "_": {
            "value": "#ffffff",
            "filePath": "src/components/rounded-button.json",
            "isSource": true,
            "original": {
              "value": "{color.background.action.outline.default}"
            },
            "name": "ComponentsRoundedButtonBaseBackground",
            "attributes": {
              "category": "components",
              "type": "rounded-button",
              "item": "base",
              "subitem": "background",
              "state": "_"
            },
            "path": [
              "components",
              "rounded-button",
              "base",
              "background",
              "_"
            ]
          },
          "hover": {
            "value": "#f6f7f9",
            "filePath": "src/components/rounded-button.json",
            "isSource": true,
            "original": {
              "value": "{color.background.action.outline.hover}"
            },
            "name": "ComponentsRoundedButtonBaseBackgroundHover",
            "attributes": {
              "category": "components",
              "type": "rounded-button",
              "item": "base",
              "subitem": "background",
              "state": "hover"
            },
            "path": [
              "components",
              "rounded-button",
              "base",
              "background",
              "hover"
            ]
          }
        },
        "display": {
          "value": "flex",
          "filePath": "src/components/rounded-button.json",
          "isSource": true,
          "original": {
            "value": "flex"
          },
          "name": "ComponentsRoundedButtonBaseDisplay",
          "attributes": {
            "category": "components",
            "type": "rounded-button",
            "item": "base",
            "subitem": "display"
          },
          "path": [
            "components",
            "rounded-button",
            "base",
            "display"
          ]
        },
        "align-items": {
          "value": "center",
          "filePath": "src/components/rounded-button.json",
          "isSource": true,
          "original": {
            "value": "center"
          },
          "name": "ComponentsRoundedButtonBaseAlignItems",
          "attributes": {
            "category": "components",
            "type": "rounded-button",
            "item": "base",
            "subitem": "align-items"
          },
          "path": [
            "components",
            "rounded-button",
            "base",
            "align-items"
          ]
        },
        "gap": {
          "value": "8px",
          "filePath": "src/components/rounded-button.json",
          "isSource": true,
          "original": {
            "value": "{spacing.inner.base02.value}"
          },
          "name": "ComponentsRoundedButtonBaseGap",
          "attributes": {
            "category": "components",
            "type": "rounded-button",
            "item": "base",
            "subitem": "gap"
          },
          "path": [
            "components",
            "rounded-button",
            "base",
            "gap"
          ]
        },
        "color": {
          "value": "#1e2129",
          "filePath": "src/components/rounded-button.json",
          "isSource": true,
          "original": {
            "value": "{color.text.strong._}"
          },
          "name": "ComponentsRoundedButtonBaseColor",
          "attributes": {
            "category": "components",
            "type": "rounded-button",
            "item": "base",
            "subitem": "color"
          },
          "path": [
            "components",
            "rounded-button",
            "base",
            "color"
          ]
        },
        "border": {
          "value": "1px solid #e2e4e9",
          "filePath": "src/components/rounded-button.json",
          "isSource": true,
          "original": {
            "value": "{shape.border.width.sm.value} solid {color.border.neutral.default}"
          },
          "name": "ComponentsRoundedButtonBaseBorder",
          "attributes": {
            "category": "components",
            "type": "rounded-button",
            "item": "base",
            "subitem": "border"
          },
          "path": [
            "components",
            "rounded-button",
            "base",
            "border"
          ]
        }
      },
      "variant": {
        "avatar": {
          "padding": {
            "value": "4px",
            "filePath": "src/components/rounded-button.json",
            "isSource": true,
            "original": {
              "value": "{spacing.inner.base.value}"
            },
            "name": "ComponentsRoundedButtonVariantAvatarPadding",
            "attributes": {
              "category": "components",
              "type": "rounded-button",
              "item": "variant",
              "subitem": "avatar",
              "state": "padding"
            },
            "path": [
              "components",
              "rounded-button",
              "variant",
              "avatar",
              "padding"
            ]
          },
          "paddingRight": {
            "value": "12px",
            "filePath": "src/components/rounded-button.json",
            "isSource": true,
            "original": {
              "value": "{spacing.inner.base03.value}"
            },
            "name": "ComponentsRoundedButtonVariantAvatarPaddingRight",
            "attributes": {
              "category": "components",
              "type": "rounded-button",
              "item": "variant",
              "subitem": "avatar",
              "state": "paddingRight"
            },
            "path": [
              "components",
              "rounded-button",
              "variant",
              "avatar",
              "paddingRight"
            ]
          },
          "color": {
            "value": "#666b7a",
            "filePath": "src/components/rounded-button.json",
            "isSource": true,
            "original": {
              "value": "{color.text.secondary}"
            },
            "name": "ComponentsRoundedButtonVariantAvatarColor",
            "attributes": {
              "category": "components",
              "type": "rounded-button",
              "item": "variant",
              "subitem": "avatar",
              "state": "color"
            },
            "path": [
              "components",
              "rounded-button",
              "variant",
              "avatar",
              "color"
            ]
          },
          "h": {
            "value": "32px",
            "filePath": "src/components/rounded-button.json",
            "isSource": true,
            "original": {
              "value": "32px"
            },
            "name": "ComponentsRoundedButtonVariantAvatarH",
            "attributes": {
              "category": "components",
              "type": "rounded-button",
              "item": "variant",
              "subitem": "avatar",
              "state": "h"
            },
            "path": [
              "components",
              "rounded-button",
              "variant",
              "avatar",
              "h"
            ]
          }
        },
        "filter": {
          "h": {
            "value": "32px",
            "filePath": "src/components/rounded-button.json",
            "isSource": true,
            "original": {
              "value": "32px"
            },
            "name": "ComponentsRoundedButtonVariantFilterH",
            "attributes": {
              "category": "components",
              "type": "rounded-button",
              "item": "variant",
              "subitem": "filter",
              "state": "h"
            },
            "path": [
              "components",
              "rounded-button",
              "variant",
              "filter",
              "h"
            ]
          }
        }
      },
      "disabled": {
        "cursor": {
          "value": "default",
          "filePath": "src/components/rounded-button.json",
          "isSource": true,
          "original": {
            "value": "default"
          },
          "name": "ComponentsRoundedButtonDisabledCursor",
          "attributes": {
            "category": "components",
            "type": "rounded-button",
            "item": "disabled",
            "subitem": "cursor"
          },
          "path": [
            "components",
            "rounded-button",
            "disabled",
            "cursor"
          ]
        }
      }
    },
    "select": {
      "box": {
        "background": {
          "value": "#ffffff",
          "filePath": "src/components/select.json",
          "isSource": true,
          "original": {
            "value": "{color.base.white.value}"
          },
          "name": "ComponentsSelectBoxBackground",
          "attributes": {
            "category": "components",
            "type": "select",
            "item": "box",
            "subitem": "background"
          },
          "path": [
            "components",
            "select",
            "box",
            "background"
          ]
        },
        "border": {
          "value": "1px solid #e2e4e9",
          "filePath": "src/components/select.json",
          "isSource": true,
          "original": {
            "value": "{shape.border.width.sm.value} solid {color.border.neutral.default.value}"
          },
          "name": "ComponentsSelectBoxBorder",
          "attributes": {
            "category": "components",
            "type": "select",
            "item": "box",
            "subitem": "border"
          },
          "path": [
            "components",
            "select",
            "box",
            "border"
          ]
        },
        "border-radius": {
          "value": "4px",
          "filePath": "src/components/select.json",
          "isSource": true,
          "original": {
            "value": "{shape.border.radius.small.value}"
          },
          "name": "ComponentsSelectBoxBorderRadius",
          "attributes": {
            "category": "components",
            "type": "select",
            "item": "box",
            "subitem": "border-radius"
          },
          "path": [
            "components",
            "select",
            "box",
            "border-radius"
          ]
        },
        "display": {
          "value": "flex",
          "filePath": "src/components/select.json",
          "isSource": true,
          "original": {
            "value": "flex"
          },
          "name": "ComponentsSelectBoxDisplay",
          "attributes": {
            "category": "components",
            "type": "select",
            "item": "box",
            "subitem": "display"
          },
          "path": [
            "components",
            "select",
            "box",
            "display"
          ]
        },
        "justify-content": {
          "value": "space-between",
          "filePath": "src/components/select.json",
          "isSource": true,
          "original": {
            "value": "space-between"
          },
          "name": "ComponentsSelectBoxJustifyContent",
          "attributes": {
            "category": "components",
            "type": "select",
            "item": "box",
            "subitem": "justify-content"
          },
          "path": [
            "components",
            "select",
            "box",
            "justify-content"
          ]
        },
        "align-items": {
          "value": "center",
          "filePath": "src/components/select.json",
          "isSource": true,
          "original": {
            "value": "center"
          },
          "name": "ComponentsSelectBoxAlignItems",
          "attributes": {
            "category": "components",
            "type": "select",
            "item": "box",
            "subitem": "align-items"
          },
          "path": [
            "components",
            "select",
            "box",
            "align-items"
          ]
        },
        "cursor": {
          "value": "pointer",
          "filePath": "src/components/select.json",
          "isSource": true,
          "original": {
            "value": "pointer"
          },
          "name": "ComponentsSelectBoxCursor",
          "attributes": {
            "category": "components",
            "type": "select",
            "item": "box",
            "subitem": "cursor"
          },
          "path": [
            "components",
            "select",
            "box",
            "cursor"
          ]
        },
        "size": {
          "small": {
            "padding": {
              "value": "8px 16px",
              "filePath": "src/components/select.json",
              "isSource": true,
              "original": {
                "value": "{spacing.inner.base02.value} {spacing.inner.base04.value}"
              },
              "name": "ComponentsSelectBoxSizeSmallPadding",
              "attributes": {
                "category": "components",
                "type": "select",
                "item": "box",
                "subitem": "size",
                "state": "small"
              },
              "path": [
                "components",
                "select",
                "box",
                "size",
                "small",
                "padding"
              ]
            }
          },
          "medium": {
            "padding": {
              "value": "12px 16px",
              "filePath": "src/components/select.json",
              "isSource": true,
              "original": {
                "value": "{spacing.inner.base03.value} {spacing.inner.base04.value}"
              },
              "name": "ComponentsSelectBoxSizeMediumPadding",
              "attributes": {
                "category": "components",
                "type": "select",
                "item": "box",
                "subitem": "size",
                "state": "medium"
              },
              "path": [
                "components",
                "select",
                "box",
                "size",
                "medium",
                "padding"
              ]
            }
          },
          "large": {
            "padding": {
              "value": "12px 16px",
              "filePath": "src/components/select.json",
              "isSource": true,
              "original": {
                "value": "{spacing.inner.base03.value} {spacing.inner.base04.value}"
              },
              "name": "ComponentsSelectBoxSizeLargePadding",
              "attributes": {
                "category": "components",
                "type": "select",
                "item": "box",
                "subitem": "size",
                "state": "large"
              },
              "path": [
                "components",
                "select",
                "box",
                "size",
                "large",
                "padding"
              ]
            }
          }
        }
      },
      "disabled-box": {
        "background": {
          "value": "#ebecef",
          "filePath": "src/components/select.json",
          "isSource": true,
          "original": {
            "value": "{color.background.disabled}"
          },
          "name": "ComponentsSelectDisabledBoxBackground",
          "attributes": {
            "category": "components",
            "type": "select",
            "item": "disabled-box",
            "subitem": "background"
          },
          "path": [
            "components",
            "select",
            "disabled-box",
            "background"
          ]
        },
        "borderColor": {
          "value": "#ebecef",
          "filePath": "src/components/select.json",
          "isSource": true,
          "original": {
            "value": "{color.border.disabled}"
          },
          "name": "ComponentsSelectDisabledBoxBorderColor",
          "attributes": {
            "category": "components",
            "type": "select",
            "item": "disabled-box",
            "subitem": "borderColor"
          },
          "path": [
            "components",
            "select",
            "disabled-box",
            "borderColor"
          ]
        },
        "cursor": {
          "value": "not-allowed",
          "filePath": "src/components/select.json",
          "isSource": true,
          "original": {
            "value": "not-allowed"
          },
          "name": "ComponentsSelectDisabledBoxCursor",
          "attributes": {
            "category": "components",
            "type": "select",
            "item": "disabled-box",
            "subitem": "cursor"
          },
          "path": [
            "components",
            "select",
            "disabled-box",
            "cursor"
          ]
        }
      },
      "opened-box-top": {
        "border-color": {
          "value": "#ffffff #3c83f6 #3c83f6  #3c83f6",
          "filePath": "src/components/select.json",
          "isSource": true,
          "original": {
            "value": "{color.base.white.value} {color.base.blue.500.value} {color.base.blue.500.value}  {color.base.blue.500.value}"
          },
          "name": "ComponentsSelectOpenedBoxTopBorderColor",
          "attributes": {
            "category": "components",
            "type": "select",
            "item": "opened-box-top",
            "subitem": "border-color"
          },
          "path": [
            "components",
            "select",
            "opened-box-top",
            "border-color"
          ]
        },
        "border-radius": {
          "value": "0 0 4px 4px",
          "filePath": "src/components/select.json",
          "isSource": true,
          "original": {
            "value": "0 0 {shape.border.radius.small.value} {shape.border.radius.small.value}"
          },
          "name": "ComponentsSelectOpenedBoxTopBorderRadius",
          "attributes": {
            "category": "components",
            "type": "select",
            "item": "opened-box-top",
            "subitem": "border-radius"
          },
          "path": [
            "components",
            "select",
            "opened-box-top",
            "border-radius"
          ]
        }
      },
      "opened-box-bottom": {
        "border-color": {
          "value": "#3c83f6 #3c83f6 #ffffff #3c83f6",
          "filePath": "src/components/select.json",
          "isSource": true,
          "original": {
            "value": "{color.base.blue.500.value} {color.base.blue.500.value} {color.base.white.value} {color.base.blue.500.value}"
          },
          "name": "ComponentsSelectOpenedBoxBottomBorderColor",
          "attributes": {
            "category": "components",
            "type": "select",
            "item": "opened-box-bottom",
            "subitem": "border-color"
          },
          "path": [
            "components",
            "select",
            "opened-box-bottom",
            "border-color"
          ]
        },
        "border-radius": {
          "value": "4px 4px 0 0",
          "filePath": "src/components/select.json",
          "isSource": true,
          "original": {
            "value": "{shape.border.radius.small.value} {shape.border.radius.small.value} 0 0"
          },
          "name": "ComponentsSelectOpenedBoxBottomBorderRadius",
          "attributes": {
            "category": "components",
            "type": "select",
            "item": "opened-box-bottom",
            "subitem": "border-radius"
          },
          "path": [
            "components",
            "select",
            "opened-box-bottom",
            "border-radius"
          ]
        }
      },
      "error-box": {
        "border-color": {
          "value": "#ef4343",
          "filePath": "src/components/select.json",
          "isSource": true,
          "original": {
            "value": "{color.border.danger}"
          },
          "name": "ComponentsSelectErrorBoxBorderColor",
          "attributes": {
            "category": "components",
            "type": "select",
            "item": "error-box",
            "subitem": "border-color"
          },
          "path": [
            "components",
            "select",
            "error-box",
            "border-color"
          ]
        }
      },
      "opened-error-box-top": {
        "border-color": {
          "value": "#ffffff #ef4343 #ef4343 #ef4343",
          "filePath": "src/components/select.json",
          "isSource": true,
          "original": {
            "value": "{color.base.white.value} {components.select.error-box.border-color.value} {components.select.error-box.border-color.value} {components.select.error-box.border-color.value}"
          },
          "name": "ComponentsSelectOpenedErrorBoxTopBorderColor",
          "attributes": {
            "category": "components",
            "type": "select",
            "item": "opened-error-box-top",
            "subitem": "border-color"
          },
          "path": [
            "components",
            "select",
            "opened-error-box-top",
            "border-color"
          ]
        }
      },
      "opened-error-box-bottom": {
        "border-color": {
          "value": "#ef4343 #ef4343 #ffffff #ef4343",
          "filePath": "src/components/select.json",
          "isSource": true,
          "original": {
            "value": "{components.select.error-box.border-color.value} {components.select.error-box.border-color.value} {color.base.white.value} {components.select.error-box.border-color.value}"
          },
          "name": "ComponentsSelectOpenedErrorBoxBottomBorderColor",
          "attributes": {
            "category": "components",
            "type": "select",
            "item": "opened-error-box-bottom",
            "subitem": "border-color"
          },
          "path": [
            "components",
            "select",
            "opened-error-box-bottom",
            "border-color"
          ]
        }
      },
      "arrow": {
        "background": {
          "value": "transparent",
          "filePath": "src/components/select.json",
          "isSource": true,
          "original": {
            "value": "transparent"
          },
          "name": "ComponentsSelectArrowBackground",
          "attributes": {
            "category": "components",
            "type": "select",
            "item": "arrow",
            "subitem": "background"
          },
          "path": [
            "components",
            "select",
            "arrow",
            "background"
          ]
        },
        "color": {
          "value": "#666b7a",
          "filePath": "src/components/select.json",
          "isSource": true,
          "original": {
            "value": "{color.icon.secondary}"
          },
          "name": "ComponentsSelectArrowColor",
          "attributes": {
            "category": "components",
            "type": "select",
            "item": "arrow",
            "subitem": "color"
          },
          "path": [
            "components",
            "select",
            "arrow",
            "color"
          ]
        },
        "transition": {
          "value": "transform 0.2s ease-in-out",
          "filePath": "src/components/select.json",
          "isSource": true,
          "original": {
            "value": "transform 0.2s ease-in-out"
          },
          "name": "ComponentsSelectArrowTransition",
          "attributes": {
            "category": "components",
            "type": "select",
            "item": "arrow",
            "subitem": "transition"
          },
          "path": [
            "components",
            "select",
            "arrow",
            "transition"
          ]
        }
      },
      "openedArrow": {
        "transform": {
          "value": "rotate(180deg)",
          "filePath": "src/components/select.json",
          "isSource": true,
          "original": {
            "value": "rotate(180deg)"
          },
          "name": "ComponentsSelectOpenedArrowTransform",
          "attributes": {
            "category": "components",
            "type": "select",
            "item": "openedArrow",
            "subitem": "transform"
          },
          "path": [
            "components",
            "select",
            "openedArrow",
            "transform"
          ]
        }
      },
      "disabled-arrow": {
        "color": {
          "value": "#9ca1b0",
          "filePath": "src/components/select.json",
          "isSource": true,
          "original": {
            "value": "{color.icon.disabled}"
          },
          "name": "ComponentsSelectDisabledArrowColor",
          "attributes": {
            "category": "components",
            "type": "select",
            "item": "disabled-arrow",
            "subitem": "color"
          },
          "path": [
            "components",
            "select",
            "disabled-arrow",
            "color"
          ]
        }
      },
      "list": {
        "border": {
          "value": "1px solid",
          "filePath": "src/components/select.json",
          "isSource": true,
          "original": {
            "value": "{shape.border.width.sm.value} solid"
          },
          "name": "ComponentsSelectListBorder",
          "attributes": {
            "category": "components",
            "type": "select",
            "item": "list",
            "subitem": "border"
          },
          "path": [
            "components",
            "select",
            "list",
            "border"
          ]
        },
        "position": {
          "value": "absolute",
          "filePath": "src/components/select.json",
          "isSource": true,
          "original": {
            "value": "absolute"
          },
          "name": "ComponentsSelectListPosition",
          "attributes": {
            "category": "components",
            "type": "select",
            "item": "list",
            "subitem": "position"
          },
          "path": [
            "components",
            "select",
            "list",
            "position"
          ]
        },
        "background": {
          "value": "#ffffff",
          "filePath": "src/components/select.json",
          "isSource": true,
          "original": {
            "value": "{color.base.white.value}"
          },
          "name": "ComponentsSelectListBackground",
          "attributes": {
            "category": "components",
            "type": "select",
            "item": "list",
            "subitem": "background"
          },
          "path": [
            "components",
            "select",
            "list",
            "background"
          ]
        },
        "z-index": {
          "value": 1,
          "filePath": "src/components/select.json",
          "isSource": true,
          "original": {
            "value": 1
          },
          "name": "ComponentsSelectListZIndex",
          "attributes": {
            "category": "components",
            "type": "select",
            "item": "list",
            "subitem": "z-index"
          },
          "path": [
            "components",
            "select",
            "list",
            "z-index"
          ]
        },
        "border-color": {
          "value": "#e2e4e9 #3c83f6 #3c83f6 #3c83f6",
          "filePath": "src/components/select.json",
          "isSource": true,
          "original": {
            "value": "{color.border.divider} {color.border.selected} {color.border.selected} {color.border.selected}"
          },
          "name": "ComponentsSelectListBorderColor",
          "attributes": {
            "category": "components",
            "type": "select",
            "item": "list",
            "subitem": "border-color"
          },
          "path": [
            "components",
            "select",
            "list",
            "border-color"
          ]
        },
        "border-radius": {
          "value": "0 0 4px 4px",
          "filePath": "src/components/select.json",
          "isSource": true,
          "original": {
            "value": "0 0 {shape.border.radius.small.value} {shape.border.radius.small.value}"
          },
          "name": "ComponentsSelectListBorderRadius",
          "attributes": {
            "category": "components",
            "type": "select",
            "item": "list",
            "subitem": "border-radius"
          },
          "path": [
            "components",
            "select",
            "list",
            "border-radius"
          ]
        },
        "max-height": {
          "value": "300px",
          "filePath": "src/components/select.json",
          "isSource": true,
          "original": {
            "value": "300px"
          },
          "name": "ComponentsSelectListMaxHeight",
          "attributes": {
            "category": "components",
            "type": "select",
            "item": "list",
            "subitem": "max-height"
          },
          "path": [
            "components",
            "select",
            "list",
            "max-height"
          ]
        },
        "overflow-y": {
          "value": "scroll",
          "filePath": "src/components/select.json",
          "isSource": true,
          "original": {
            "value": "scroll"
          },
          "name": "ComponentsSelectListOverflowY",
          "attributes": {
            "category": "components",
            "type": "select",
            "item": "list",
            "subitem": "overflow-y"
          },
          "path": [
            "components",
            "select",
            "list",
            "overflow-y"
          ]
        },
        "overflow-x": {
          "value": "hidden",
          "filePath": "src/components/select.json",
          "isSource": true,
          "original": {
            "value": "hidden"
          },
          "name": "ComponentsSelectListOverflowX",
          "attributes": {
            "category": "components",
            "type": "select",
            "item": "list",
            "subitem": "overflow-x"
          },
          "path": [
            "components",
            "select",
            "list",
            "overflow-x"
          ]
        },
        "left": {
          "value": "0px",
          "filePath": "src/components/select.json",
          "isSource": true,
          "original": {
            "value": "0px"
          },
          "name": "ComponentsSelectListLeft",
          "attributes": {
            "category": "components",
            "type": "select",
            "item": "list",
            "subitem": "left"
          },
          "path": [
            "components",
            "select",
            "list",
            "left"
          ]
        }
      },
      "list-error": {
        "borderColor": {
          "value": "#f3f4f6 #ef4343 #ef4343 #ef4343",
          "filePath": "src/components/select.json",
          "isSource": true,
          "original": {
            "value": "{color.base.grey.100.value} {color.border.danger.value} {color.border.danger.value} {color.border.danger.value}"
          },
          "name": "ComponentsSelectListErrorBorderColor",
          "attributes": {
            "category": "components",
            "type": "select",
            "item": "list-error",
            "subitem": "borderColor"
          },
          "path": [
            "components",
            "select",
            "list-error",
            "borderColor"
          ]
        }
      },
      "selected-box-single-selection": {
        "display": {
          "value": "flex",
          "filePath": "src/components/select.json",
          "isSource": true,
          "original": {
            "value": "flex"
          },
          "name": "ComponentsSelectSelectedBoxSingleSelectionDisplay",
          "attributes": {
            "category": "components",
            "type": "select",
            "item": "selected-box-single-selection",
            "subitem": "display"
          },
          "path": [
            "components",
            "select",
            "selected-box-single-selection",
            "display"
          ]
        },
        "overflow": {
          "value": "hidden",
          "filePath": "src/components/select.json",
          "isSource": true,
          "original": {
            "value": "hidden"
          },
          "name": "ComponentsSelectSelectedBoxSingleSelectionOverflow",
          "attributes": {
            "category": "components",
            "type": "select",
            "item": "selected-box-single-selection",
            "subitem": "overflow"
          },
          "path": [
            "components",
            "select",
            "selected-box-single-selection",
            "overflow"
          ]
        },
        "text-overflow": {
          "value": "ellipsis",
          "filePath": "src/components/select.json",
          "isSource": true,
          "original": {
            "value": "ellipsis"
          },
          "name": "ComponentsSelectSelectedBoxSingleSelectionTextOverflow",
          "attributes": {
            "category": "components",
            "type": "select",
            "item": "selected-box-single-selection",
            "subitem": "text-overflow"
          },
          "path": [
            "components",
            "select",
            "selected-box-single-selection",
            "text-overflow"
          ]
        },
        "w": {
          "value": "100%",
          "filePath": "src/components/select.json",
          "isSource": true,
          "original": {
            "value": "100%"
          },
          "name": "ComponentsSelectSelectedBoxSingleSelectionW",
          "attributes": {
            "category": "components",
            "type": "select",
            "item": "selected-box-single-selection",
            "subitem": "w"
          },
          "path": [
            "components",
            "select",
            "selected-box-single-selection",
            "w"
          ]
        },
        "white-space": {
          "value": "nowrap",
          "filePath": "src/components/select.json",
          "isSource": true,
          "original": {
            "value": "nowrap"
          },
          "name": "ComponentsSelectSelectedBoxSingleSelectionWhiteSpace",
          "attributes": {
            "category": "components",
            "type": "select",
            "item": "selected-box-single-selection",
            "subitem": "white-space"
          },
          "path": [
            "components",
            "select",
            "selected-box-single-selection",
            "white-space"
          ]
        }
      },
      "selected-box-multiple-selection": {
        "display": {
          "value": "flex",
          "filePath": "src/components/select.json",
          "isSource": true,
          "original": {
            "value": "flex"
          },
          "name": "ComponentsSelectSelectedBoxMultipleSelectionDisplay",
          "attributes": {
            "category": "components",
            "type": "select",
            "item": "selected-box-multiple-selection",
            "subitem": "display"
          },
          "path": [
            "components",
            "select",
            "selected-box-multiple-selection",
            "display"
          ]
        },
        "align-items": {
          "value": "center",
          "filePath": "src/components/select.json",
          "isSource": true,
          "original": {
            "value": "center"
          },
          "name": "ComponentsSelectSelectedBoxMultipleSelectionAlignItems",
          "attributes": {
            "category": "components",
            "type": "select",
            "item": "selected-box-multiple-selection",
            "subitem": "align-items"
          },
          "path": [
            "components",
            "select",
            "selected-box-multiple-selection",
            "align-items"
          ]
        },
        "overflow": {
          "value": "auto",
          "filePath": "src/components/select.json",
          "isSource": true,
          "original": {
            "value": "auto"
          },
          "name": "ComponentsSelectSelectedBoxMultipleSelectionOverflow",
          "attributes": {
            "category": "components",
            "type": "select",
            "item": "selected-box-multiple-selection",
            "subitem": "overflow"
          },
          "path": [
            "components",
            "select",
            "selected-box-multiple-selection",
            "overflow"
          ]
        }
      },
      "multiple-selection-wrap": {
        "flex-wrap": {
          "value": "wrap",
          "filePath": "src/components/select.json",
          "isSource": true,
          "original": {
            "value": "wrap"
          },
          "name": "ComponentsSelectMultipleSelectionWrapFlexWrap",
          "attributes": {
            "category": "components",
            "type": "select",
            "item": "multiple-selection-wrap",
            "subitem": "flex-wrap"
          },
          "path": [
            "components",
            "select",
            "multiple-selection-wrap",
            "flex-wrap"
          ]
        }
      },
      "multiple-selection-text-no-wrap": {
        "white-space": {
          "value": "nowrap",
          "filePath": "src/components/select.json",
          "isSource": true,
          "original": {
            "value": "nowrap"
          },
          "name": "ComponentsSelectMultipleSelectionTextNoWrapWhiteSpace",
          "attributes": {
            "category": "components",
            "type": "select",
            "item": "multiple-selection-text-no-wrap",
            "subitem": "white-space"
          },
          "path": [
            "components",
            "select",
            "multiple-selection-text-no-wrap",
            "white-space"
          ]
        },
        "-webkit-mask-image": {
          "value": "linear-gradient(to right, rgba(0,0,0,1) 85%, rgba(0,0,0,0))",
          "filePath": "src/components/select.json",
          "isSource": true,
          "original": {
            "value": "linear-gradient(to right, rgba(0,0,0,1) 85%, rgba(0,0,0,0))"
          },
          "name": "ComponentsSelectMultipleSelectionTextNoWrapWebkitMaskImage",
          "attributes": {
            "category": "components",
            "type": "select",
            "item": "multiple-selection-text-no-wrap",
            "subitem": "-webkit-mask-image"
          },
          "path": [
            "components",
            "select",
            "multiple-selection-text-no-wrap",
            "-webkit-mask-image"
          ]
        }
      },
      "full-width": {
        "w": {
          "value": "100%",
          "filePath": "src/components/select.json",
          "isSource": true,
          "original": {
            "value": "100%"
          },
          "name": "ComponentsSelectFullWidthW",
          "attributes": {
            "category": "components",
            "type": "select",
            "item": "full-width",
            "subitem": "w"
          },
          "path": [
            "components",
            "select",
            "full-width",
            "w"
          ]
        }
      },
      "placeholder": {
        "color": {
          "value": "#9ca1b0",
          "filePath": "src/components/select.json",
          "isSource": true,
          "original": {
            "value": "{color.text.assistive}"
          },
          "name": "ComponentsSelectPlaceholderColor",
          "attributes": {
            "category": "components",
            "type": "select",
            "item": "placeholder",
            "subitem": "color"
          },
          "path": [
            "components",
            "select",
            "placeholder",
            "color"
          ]
        }
      },
      "text": {
        "color": {
          "value": "#1e2129",
          "filePath": "src/components/select.json",
          "isSource": true,
          "original": {
            "value": "{color.text.strong._}"
          },
          "name": "ComponentsSelectTextColor",
          "attributes": {
            "category": "components",
            "type": "select",
            "item": "text",
            "subitem": "color"
          },
          "path": [
            "components",
            "select",
            "text",
            "color"
          ]
        },
        "size": {
          "small": {
            "font-size": {
              "value": "0.8125rem",
              "filePath": "src/components/select.json",
              "isSource": true,
              "original": {
                "value": "{typography.body.small.font-size.value}"
              },
              "name": "ComponentsSelectTextSizeSmallFontSize",
              "attributes": {
                "category": "components",
                "type": "select",
                "item": "text",
                "subitem": "size",
                "state": "small"
              },
              "path": [
                "components",
                "select",
                "text",
                "size",
                "small",
                "font-size"
              ]
            },
            "line-height": {
              "value": "1rem",
              "filePath": "src/components/select.json",
              "isSource": true,
              "original": {
                "value": "{typography.body.small.line-height.value}"
              },
              "name": "ComponentsSelectTextSizeSmallLineHeight",
              "attributes": {
                "category": "components",
                "type": "select",
                "item": "text",
                "subitem": "size",
                "state": "small"
              },
              "path": [
                "components",
                "select",
                "text",
                "size",
                "small",
                "line-height"
              ]
            }
          },
          "medium": {
            "font-size": {
              "value": "0.875rem",
              "filePath": "src/components/select.json",
              "isSource": true,
              "original": {
                "value": "{typography.body.regular.font-size.value}"
              },
              "name": "ComponentsSelectTextSizeMediumFontSize",
              "attributes": {
                "category": "components",
                "type": "select",
                "item": "text",
                "subitem": "size",
                "state": "medium"
              },
              "path": [
                "components",
                "select",
                "text",
                "size",
                "medium",
                "font-size"
              ]
            },
            "line-height": {
              "value": "1.25rem",
              "filePath": "src/components/select.json",
              "isSource": true,
              "original": {
                "value": "{typography.body.regular.line-height.value}"
              },
              "name": "ComponentsSelectTextSizeMediumLineHeight",
              "attributes": {
                "category": "components",
                "type": "select",
                "item": "text",
                "subitem": "size",
                "state": "medium"
              },
              "path": [
                "components",
                "select",
                "text",
                "size",
                "medium",
                "line-height"
              ]
            }
          },
          "large": {
            "font-size": {
              "value": "1rem",
              "filePath": "src/components/select.json",
              "isSource": true,
              "original": {
                "value": "{typography.body.large.font-size.value}"
              },
              "name": "ComponentsSelectTextSizeLargeFontSize",
              "attributes": {
                "category": "components",
                "type": "select",
                "item": "text",
                "subitem": "size",
                "state": "large"
              },
              "path": [
                "components",
                "select",
                "text",
                "size",
                "large",
                "font-size"
              ]
            },
            "line-height": {
              "value": "1.5rem",
              "filePath": "src/components/select.json",
              "isSource": true,
              "original": {
                "value": "{typography.body.large.line-height.value}"
              },
              "name": "ComponentsSelectTextSizeLargeLineHeight",
              "attributes": {
                "category": "components",
                "type": "select",
                "item": "text",
                "subitem": "size",
                "state": "large"
              },
              "path": [
                "components",
                "select",
                "text",
                "size",
                "large",
                "line-height"
              ]
            }
          }
        }
      },
      "helper-text": {
        "color": {
          "value": "#9ca1b0",
          "filePath": "src/components/select.json",
          "isSource": true,
          "original": {
            "value": "{color.text.assistive}"
          },
          "name": "ComponentsSelectHelperTextColor",
          "attributes": {
            "category": "components",
            "type": "select",
            "item": "helper-text",
            "subitem": "color"
          },
          "path": [
            "components",
            "select",
            "helper-text",
            "color"
          ]
        }
      },
      "disabled-text": {
        "color": {
          "value": "#9ca1b0",
          "filePath": "src/components/select.json",
          "isSource": true,
          "original": {
            "value": "{color.text.disabled}"
          },
          "name": "ComponentsSelectDisabledTextColor",
          "attributes": {
            "category": "components",
            "type": "select",
            "item": "disabled-text",
            "subitem": "color"
          },
          "path": [
            "components",
            "select",
            "disabled-text",
            "color"
          ]
        }
      },
      "input": {
        "flexGrow": {
          "value": 1,
          "filePath": "src/components/select.json",
          "isSource": true,
          "original": {
            "value": 1
          },
          "name": "ComponentsSelectInputFlexGrow",
          "attributes": {
            "category": "components",
            "type": "select",
            "item": "input",
            "subitem": "flexGrow"
          },
          "path": [
            "components",
            "select",
            "input",
            "flexGrow"
          ]
        },
        "outline": {
          "value": "none",
          "filePath": "src/components/select.json",
          "isSource": true,
          "original": {
            "value": "none"
          },
          "name": "ComponentsSelectInputOutline",
          "attributes": {
            "category": "components",
            "type": "select",
            "item": "input",
            "subitem": "outline"
          },
          "path": [
            "components",
            "select",
            "input",
            "outline"
          ]
        },
        "backgroundColor": {
          "value": "transparent",
          "filePath": "src/components/select.json",
          "isSource": true,
          "original": {
            "value": "transparent"
          },
          "name": "ComponentsSelectInputBackgroundColor",
          "attributes": {
            "category": "components",
            "type": "select",
            "item": "input",
            "subitem": "backgroundColor"
          },
          "path": [
            "components",
            "select",
            "input",
            "backgroundColor"
          ]
        }
      },
      "container": {
        "position": {
          "value": "relative",
          "filePath": "src/components/select.json",
          "isSource": true,
          "original": {
            "value": "relative"
          },
          "name": "ComponentsSelectContainerPosition",
          "attributes": {
            "category": "components",
            "type": "select",
            "item": "container",
            "subitem": "position"
          },
          "path": [
            "components",
            "select",
            "container",
            "position"
          ]
        }
      }
    },
    "select-option": {
      "base": {
        "transition": {
          "value": "all 0.2s ease-in-out",
          "filePath": "src/components/select.json",
          "isSource": true,
          "original": {
            "value": "all 0.2s ease-in-out"
          },
          "name": "ComponentsSelectOptionBaseTransition",
          "attributes": {
            "category": "components",
            "type": "select-option",
            "item": "base",
            "subitem": "transition"
          },
          "path": [
            "components",
            "select-option",
            "base",
            "transition"
          ]
        },
        "background-color": {
          "hover": {
            "value": "#f3f4f6",
            "filePath": "src/components/select.json",
            "isSource": true,
            "original": {
              "value": "{color.base.grey.100.value}"
            },
            "name": "ComponentsSelectOptionBaseBackgroundColorHover",
            "attributes": {
              "category": "components",
              "type": "select-option",
              "item": "base",
              "subitem": "background-color",
              "state": "hover"
            },
            "path": [
              "components",
              "select-option",
              "base",
              "background-color",
              "hover"
            ]
          }
        },
        "padding": {
          "value": "8px",
          "filePath": "src/components/select.json",
          "isSource": true,
          "original": {
            "value": "{spacing.inner.base02.value}"
          },
          "name": "ComponentsSelectOptionBasePadding",
          "attributes": {
            "category": "components",
            "type": "select-option",
            "item": "base",
            "subitem": "padding"
          },
          "path": [
            "components",
            "select-option",
            "base",
            "padding"
          ]
        },
        "cursor": {
          "value": "pointer",
          "filePath": "src/components/select.json",
          "isSource": true,
          "original": {
            "value": "pointer"
          },
          "name": "ComponentsSelectOptionBaseCursor",
          "attributes": {
            "category": "components",
            "type": "select-option",
            "item": "base",
            "subitem": "cursor"
          },
          "path": [
            "components",
            "select-option",
            "base",
            "cursor"
          ]
        },
        "listStyle": {
          "value": "none",
          "filePath": "src/components/select.json",
          "isSource": true,
          "original": {
            "value": "none"
          },
          "name": "ComponentsSelectOptionBaseListStyle",
          "attributes": {
            "category": "components",
            "type": "select-option",
            "item": "base",
            "subitem": "listStyle"
          },
          "path": [
            "components",
            "select-option",
            "base",
            "listStyle"
          ]
        },
        "display": {
          "value": "flex",
          "filePath": "src/components/select.json",
          "isSource": true,
          "original": {
            "value": "flex"
          },
          "name": "ComponentsSelectOptionBaseDisplay",
          "attributes": {
            "category": "components",
            "type": "select-option",
            "item": "base",
            "subitem": "display"
          },
          "path": [
            "components",
            "select-option",
            "base",
            "display"
          ]
        },
        "alignItems": {
          "value": "center",
          "filePath": "src/components/select.json",
          "isSource": true,
          "original": {
            "value": "center"
          },
          "name": "ComponentsSelectOptionBaseAlignItems",
          "attributes": {
            "category": "components",
            "type": "select-option",
            "item": "base",
            "subitem": "alignItems"
          },
          "path": [
            "components",
            "select-option",
            "base",
            "alignItems"
          ]
        },
        "m": {
          "value": 0,
          "filePath": "src/components/select.json",
          "isSource": true,
          "original": {
            "value": 0
          },
          "name": "ComponentsSelectOptionBaseM",
          "attributes": {
            "category": "components",
            "type": "select-option",
            "item": "base",
            "subitem": "m"
          },
          "path": [
            "components",
            "select-option",
            "base",
            "m"
          ]
        },
        "color": {
          "value": "#000000",
          "filePath": "src/components/select.json",
          "isSource": true,
          "original": {
            "value": "{color.base.black.value}"
          },
          "name": "ComponentsSelectOptionBaseColor",
          "attributes": {
            "category": "components",
            "type": "select-option",
            "item": "base",
            "subitem": "color"
          },
          "path": [
            "components",
            "select-option",
            "base",
            "color"
          ]
        }
      },
      "selected": {
        "background-color": {
          "value": "#f3f4f6",
          "filePath": "src/components/select.json",
          "isSource": true,
          "original": {
            "value": "{color.base.grey.100.value}"
          },
          "name": "ComponentsSelectOptionSelectedBackgroundColor",
          "attributes": {
            "category": "components",
            "type": "select-option",
            "item": "selected",
            "subitem": "background-color"
          },
          "path": [
            "components",
            "select-option",
            "selected",
            "background-color"
          ]
        }
      },
      "focused": {
        "background-color": {
          "value": "#e2e4e9",
          "filePath": "src/components/select.json",
          "isSource": true,
          "original": {
            "value": "{color.base.grey.300.value}"
          },
          "name": "ComponentsSelectOptionFocusedBackgroundColor",
          "attributes": {
            "category": "components",
            "type": "select-option",
            "item": "focused",
            "subitem": "background-color"
          },
          "path": [
            "components",
            "select-option",
            "focused",
            "background-color"
          ]
        }
      }
    },
    "tag": {
      "base": {
        "borderRadius": {
          "value": "4px",
          "filePath": "src/components/tag.json",
          "isSource": true,
          "original": {
            "value": "{shape.border.radius.small.value}"
          },
          "name": "ComponentsTagBaseBorderRadius",
          "attributes": {
            "category": "components",
            "type": "tag",
            "item": "base",
            "subitem": "borderRadius"
          },
          "path": [
            "components",
            "tag",
            "base",
            "borderRadius"
          ]
        },
        "padding": {
          "value": "4px 8px",
          "filePath": "src/components/tag.json",
          "isSource": true,
          "original": {
            "value": "{spacing.inner.base.value} {spacing.inner.base02.value}"
          },
          "name": "ComponentsTagBasePadding",
          "attributes": {
            "category": "components",
            "type": "tag",
            "item": "base",
            "subitem": "padding"
          },
          "path": [
            "components",
            "tag",
            "base",
            "padding"
          ]
        },
        "background": {
          "value": "#ffffff",
          "filePath": "src/components/tag.json",
          "isSource": true,
          "original": {
            "value": "{color.base.white.value}"
          },
          "name": "ComponentsTagBaseBackground",
          "attributes": {
            "category": "components",
            "type": "tag",
            "item": "base",
            "subitem": "background"
          },
          "path": [
            "components",
            "tag",
            "base",
            "background"
          ]
        },
        "display": {
          "value": "flex",
          "filePath": "src/components/tag.json",
          "isSource": true,
          "original": {
            "value": "flex"
          },
          "name": "ComponentsTagBaseDisplay",
          "attributes": {
            "category": "components",
            "type": "tag",
            "item": "base",
            "subitem": "display"
          },
          "path": [
            "components",
            "tag",
            "base",
            "display"
          ]
        },
        "alignItems": {
          "value": "center",
          "filePath": "src/components/tag.json",
          "isSource": true,
          "original": {
            "value": "center"
          },
          "name": "ComponentsTagBaseAlignItems",
          "attributes": {
            "category": "components",
            "type": "tag",
            "item": "base",
            "subitem": "alignItems"
          },
          "path": [
            "components",
            "tag",
            "base",
            "alignItems"
          ]
        },
        "gap": {
          "value": "8px",
          "filePath": "src/components/tag.json",
          "isSource": true,
          "original": {
            "value": "{spacing.inner.base02.value}"
          },
          "name": "ComponentsTagBaseGap",
          "attributes": {
            "category": "components",
            "type": "tag",
            "item": "base",
            "subitem": "gap"
          },
          "path": [
            "components",
            "tag",
            "base",
            "gap"
          ]
        },
        "color": {
          "value": "#666b7a",
          "filePath": "src/components/tag.json",
          "isSource": true,
          "original": {
            "value": "{color.text.secondary.value}"
          },
          "name": "ComponentsTagBaseColor",
          "attributes": {
            "category": "components",
            "type": "tag",
            "item": "base",
            "subitem": "color"
          },
          "path": [
            "components",
            "tag",
            "base",
            "color"
          ]
        },
        "border": {
          "value": "2px solid #e2e4e9",
          "filePath": "src/components/tag.json",
          "isSource": true,
          "original": {
            "value": "{shape.border.width.lg.value} solid {color.base.grey.300.value}"
          },
          "name": "ComponentsTagBaseBorder",
          "attributes": {
            "category": "components",
            "type": "tag",
            "item": "base",
            "subitem": "border"
          },
          "path": [
            "components",
            "tag",
            "base",
            "border"
          ]
        }
      },
      "colors": {
        "blue": {
          "wrapper": {
            "borderColor": {
              "value": "#91c3fd",
              "filePath": "src/components/tag.json",
              "isSource": true,
              "original": {
                "value": "{color.base.blue.300.value}"
              },
              "name": "ComponentsTagColorsBlueWrapperBorderColor",
              "attributes": {
                "category": "components",
                "type": "tag",
                "item": "colors",
                "subitem": "blue",
                "state": "wrapper"
              },
              "path": [
                "components",
                "tag",
                "colors",
                "blue",
                "wrapper",
                "borderColor"
              ]
            },
            "color": {
              "value": "#3c83f6",
              "filePath": "src/components/tag.json",
              "isSource": true,
              "original": {
                "value": "{color.base.blue.500.value}"
              },
              "name": "ComponentsTagColorsBlueWrapperColor",
              "attributes": {
                "category": "components",
                "type": "tag",
                "item": "colors",
                "subitem": "blue",
                "state": "wrapper"
              },
              "path": [
                "components",
                "tag",
                "colors",
                "blue",
                "wrapper",
                "color"
              ]
            }
          }
        },
        "orange": {
          "wrapper": {
            "borderColor": {
              "value": "#fff1e0",
              "filePath": "src/components/tag.json",
              "isSource": true,
              "original": {
                "value": "{color.background.decorative.base03.value}"
              },
              "name": "ComponentsTagColorsOrangeWrapperBorderColor",
              "attributes": {
                "category": "components",
                "type": "tag",
                "item": "colors",
                "subitem": "orange",
                "state": "wrapper"
              },
              "path": [
                "components",
                "tag",
                "colors",
                "orange",
                "wrapper",
                "borderColor"
              ]
            },
            "color": {
              "value": "#dc2828",
              "filePath": "src/components/tag.json",
              "isSource": true,
              "original": {
                "value": "{color.text.danger.value}"
              },
              "name": "ComponentsTagColorsOrangeWrapperColor",
              "attributes": {
                "category": "components",
                "type": "tag",
                "item": "colors",
                "subitem": "orange",
                "state": "wrapper"
              },
              "path": [
                "components",
                "tag",
                "colors",
                "orange",
                "wrapper",
                "color"
              ]
            }
          }
        },
        "green": {
          "wrapper": {
            "borderColor": {
              "value": "#CFF6EE",
              "filePath": "src/components/tag.json",
              "isSource": true,
              "original": {
                "value": "#CFF6EE"
              },
              "name": "ComponentsTagColorsGreenWrapperBorderColor",
              "attributes": {
                "category": "components",
                "type": "tag",
                "item": "colors",
                "subitem": "green",
                "state": "wrapper"
              },
              "path": [
                "components",
                "tag",
                "colors",
                "green",
                "wrapper",
                "borderColor"
              ]
            },
            "color": {
              "value": "#10b77f",
              "filePath": "src/components/tag.json",
              "isSource": true,
              "original": {
                "value": "{color.base.green.500.value}"
              },
              "name": "ComponentsTagColorsGreenWrapperColor",
              "attributes": {
                "category": "components",
                "type": "tag",
                "item": "colors",
                "subitem": "green",
                "state": "wrapper"
              },
              "path": [
                "components",
                "tag",
                "colors",
                "green",
                "wrapper",
                "color"
              ]
            }
          }
        },
        "grey": {
          "wrapper": {
            "borderColor": {
              "value": "#e2e4e9",
              "filePath": "src/components/tag.json",
              "isSource": true,
              "original": {
                "value": "{color.border.neutral.default}"
              },
              "name": "ComponentsTagColorsGreyWrapperBorderColor",
              "attributes": {
                "category": "components",
                "type": "tag",
                "item": "colors",
                "subitem": "grey",
                "state": "wrapper"
              },
              "path": [
                "components",
                "tag",
                "colors",
                "grey",
                "wrapper",
                "borderColor"
              ]
            },
            "color": {
              "value": "#666b7a",
              "filePath": "src/components/tag.json",
              "isSource": true,
              "original": {
                "value": "{color.text.secondary.value}"
              },
              "name": "ComponentsTagColorsGreyWrapperColor",
              "attributes": {
                "category": "components",
                "type": "tag",
                "item": "colors",
                "subitem": "grey",
                "state": "wrapper"
              },
              "path": [
                "components",
                "tag",
                "colors",
                "grey",
                "wrapper",
                "color"
              ]
            }
          }
        }
      }
    },
    "tooltip": {
      "body": {
        "border-radius": {
          "value": "4px",
          "filePath": "src/components/tooltip.json",
          "isSource": true,
          "original": {
            "value": "{shape.border.radius.small.value}"
          },
          "name": "ComponentsTooltipBodyBorderRadius",
          "attributes": {
            "category": "components",
            "type": "tooltip",
            "item": "body",
            "subitem": "border-radius"
          },
          "path": [
            "components",
            "tooltip",
            "body",
            "border-radius"
          ]
        },
        "padding": {
          "value": "12px 16px",
          "filePath": "src/components/tooltip.json",
          "isSource": true,
          "original": {
            "value": "{spacing.inner.base03.value} {spacing.inner.base04.value}"
          },
          "name": "ComponentsTooltipBodyPadding",
          "attributes": {
            "category": "components",
            "type": "tooltip",
            "item": "body",
            "subitem": "padding"
          },
          "path": [
            "components",
            "tooltip",
            "body",
            "padding"
          ]
        },
        "box-shadow": {
          "value": "0px 8px 24px rgba(0, 29, 58, 0.12)",
          "filePath": "src/components/tooltip.json",
          "isSource": true,
          "original": {
            "value": "{shape.shadow.secondary.value}"
          },
          "name": "ComponentsTooltipBodyBoxShadow",
          "attributes": {
            "category": "components",
            "type": "tooltip",
            "item": "body",
            "subitem": "box-shadow"
          },
          "path": [
            "components",
            "tooltip",
            "body",
            "box-shadow"
          ]
        },
        "color": {
          "value": "#1e2129",
          "filePath": "src/components/tooltip.json",
          "isSource": true,
          "original": {
            "value": "{color.base.grey.900.value}"
          },
          "name": "ComponentsTooltipBodyColor",
          "attributes": {
            "category": "components",
            "type": "tooltip",
            "item": "body",
            "subitem": "color"
          },
          "path": [
            "components",
            "tooltip",
            "body",
            "color"
          ]
        },
        "border-top": {
          "value": "none",
          "filePath": "src/components/tooltip.json",
          "isSource": true,
          "original": {
            "value": "none"
          },
          "name": "ComponentsTooltipBodyBorderTop",
          "attributes": {
            "category": "components",
            "type": "tooltip",
            "item": "body",
            "subitem": "border-top"
          },
          "path": [
            "components",
            "tooltip",
            "body",
            "border-top"
          ]
        },
        "max-w": {
          "value": "280",
          "filePath": "src/components/tooltip.json",
          "isSource": true,
          "original": {
            "value": "280"
          },
          "name": "ComponentsTooltipBodyMaxW",
          "attributes": {
            "category": "components",
            "type": "tooltip",
            "item": "body",
            "subitem": "max-w"
          },
          "path": [
            "components",
            "tooltip",
            "body",
            "max-w"
          ]
        },
        "overflow": {
          "value": "hidden",
          "filePath": "src/components/tooltip.json",
          "isSource": true,
          "original": {
            "value": "hidden"
          },
          "name": "ComponentsTooltipBodyOverflow",
          "attributes": {
            "category": "components",
            "type": "tooltip",
            "item": "body",
            "subitem": "overflow"
          },
          "path": [
            "components",
            "tooltip",
            "body",
            "overflow"
          ]
        }
      },
      "content": {
        "display": {
          "value": "flex",
          "filePath": "src/components/tooltip.json",
          "isSource": true,
          "original": {
            "value": "flex"
          },
          "name": "ComponentsTooltipContentDisplay",
          "attributes": {
            "category": "components",
            "type": "tooltip",
            "item": "content",
            "subitem": "display"
          },
          "path": [
            "components",
            "tooltip",
            "content",
            "display"
          ]
        },
        "gap": {
          "value": "8px",
          "filePath": "src/components/tooltip.json",
          "isSource": true,
          "original": {
            "value": "{spacing.layout.base.value}"
          },
          "name": "ComponentsTooltipContentGap",
          "attributes": {
            "category": "components",
            "type": "tooltip",
            "item": "content",
            "subitem": "gap"
          },
          "path": [
            "components",
            "tooltip",
            "content",
            "gap"
          ]
        },
        "flex-direction": {
          "value": "row",
          "filePath": "src/components/tooltip.json",
          "isSource": true,
          "original": {
            "value": "row"
          },
          "name": "ComponentsTooltipContentFlexDirection",
          "attributes": {
            "category": "components",
            "type": "tooltip",
            "item": "content",
            "subitem": "flex-direction"
          },
          "path": [
            "components",
            "tooltip",
            "content",
            "flex-direction"
          ]
        },
        "align-items": {
          "value": "flex-start",
          "filePath": "src/components/tooltip.json",
          "isSource": true,
          "original": {
            "value": "flex-start"
          },
          "name": "ComponentsTooltipContentAlignItems",
          "attributes": {
            "category": "components",
            "type": "tooltip",
            "item": "content",
            "subitem": "align-items"
          },
          "path": [
            "components",
            "tooltip",
            "content",
            "align-items"
          ]
        }
      },
      "variant": {
        "default": {
          "body": {
            "background": {
              "value": "#ffffff",
              "filePath": "src/components/tooltip.json",
              "isSource": true,
              "original": {
                "value": "{color.base.white.value}"
              },
              "name": "ComponentsTooltipVariantDefaultBodyBackground",
              "attributes": {
                "category": "components",
                "type": "tooltip",
                "item": "variant",
                "subitem": "default",
                "state": "body"
              },
              "path": [
                "components",
                "tooltip",
                "variant",
                "default",
                "body",
                "background"
              ]
            },
            "box-shadow": {
              "value": "0px 8px 24px rgba(0, 29, 58, 0.12)",
              "filePath": "src/components/tooltip.json",
              "isSource": true,
              "original": {
                "value": "{components.tooltip.body.box-shadow.value}"
              },
              "name": "ComponentsTooltipVariantDefaultBodyBoxShadow",
              "attributes": {
                "category": "components",
                "type": "tooltip",
                "item": "variant",
                "subitem": "default",
                "state": "body"
              },
              "path": [
                "components",
                "tooltip",
                "variant",
                "default",
                "body",
                "box-shadow"
              ]
            }
          },
          "icon": {
            "color": {
              "value": "none",
              "filePath": "src/components/tooltip.json",
              "isSource": true,
              "original": {
                "value": "none"
              },
              "name": "ComponentsTooltipVariantDefaultIconColor",
              "attributes": {
                "category": "components",
                "type": "tooltip",
                "item": "variant",
                "subitem": "default",
                "state": "icon"
              },
              "path": [
                "components",
                "tooltip",
                "variant",
                "default",
                "icon",
                "color"
              ]
            }
          }
        },
        "alert": {
          "body": {
            "border-radius": {
              "value": "0",
              "filePath": "src/components/tooltip.json",
              "isSource": true,
              "original": {
                "value": "0"
              },
              "name": "ComponentsTooltipVariantAlertBodyBorderRadius",
              "attributes": {
                "category": "components",
                "type": "tooltip",
                "item": "variant",
                "subitem": "alert",
                "state": "body"
              },
              "path": [
                "components",
                "tooltip",
                "variant",
                "alert",
                "body",
                "border-radius"
              ]
            },
            "border-top": {
              "value": "none",
              "filePath": "src/components/tooltip.json",
              "isSource": true,
              "original": {
                "value": "none"
              },
              "name": "ComponentsTooltipVariantAlertBodyBorderTop",
              "attributes": {
                "category": "components",
                "type": "tooltip",
                "item": "variant",
                "subitem": "alert",
                "state": "body"
              },
              "path": [
                "components",
                "tooltip",
                "variant",
                "alert",
                "body",
                "border-top"
              ]
            },
            "background": {
              "value": "#ffffff",
              "filePath": "src/components/tooltip.json",
              "isSource": true,
              "original": {
                "value": "{color.base.white.value}"
              },
              "name": "ComponentsTooltipVariantAlertBodyBackground",
              "attributes": {
                "category": "components",
                "type": "tooltip",
                "item": "variant",
                "subitem": "alert",
                "state": "body"
              },
              "path": [
                "components",
                "tooltip",
                "variant",
                "alert",
                "body",
                "background"
              ]
            }
          },
          "icon": {
            "color": {
              "value": "none",
              "filePath": "src/components/tooltip.json",
              "isSource": true,
              "original": {
                "value": "none"
              },
              "name": "ComponentsTooltipVariantAlertIconColor",
              "attributes": {
                "category": "components",
                "type": "tooltip",
                "item": "variant",
                "subitem": "alert",
                "state": "icon"
              },
              "path": [
                "components",
                "tooltip",
                "variant",
                "alert",
                "icon",
                "color"
              ]
            }
          }
        },
        "dark": {
          "body": {
            "background": {
              "value": "#1e2129",
              "filePath": "src/components/tooltip.json",
              "isSource": true,
              "original": {
                "value": "{color.background.dark}"
              },
              "name": "ComponentsTooltipVariantDarkBodyBackground",
              "attributes": {
                "category": "components",
                "type": "tooltip",
                "item": "variant",
                "subitem": "dark",
                "state": "body"
              },
              "path": [
                "components",
                "tooltip",
                "variant",
                "dark",
                "body",
                "background"
              ]
            },
            "border-radius": {
              "value": "4px",
              "filePath": "src/components/tooltip.json",
              "isSource": true,
              "original": {
                "value": "{shape.border.radius.small.value}"
              },
              "name": "ComponentsTooltipVariantDarkBodyBorderRadius",
              "attributes": {
                "category": "components",
                "type": "tooltip",
                "item": "variant",
                "subitem": "dark",
                "state": "body"
              },
              "path": [
                "components",
                "tooltip",
                "variant",
                "dark",
                "body",
                "border-radius"
              ]
            },
            "color": {
              "value": "#ffffff",
              "filePath": "src/components/tooltip.json",
              "isSource": true,
              "original": {
                "value": "{color.text.inverse}"
              },
              "name": "ComponentsTooltipVariantDarkBodyColor",
              "attributes": {
                "category": "components",
                "type": "tooltip",
                "item": "variant",
                "subitem": "dark",
                "state": "body"
              },
              "path": [
                "components",
                "tooltip",
                "variant",
                "dark",
                "body",
                "color"
              ]
            },
            "padding": {
              "value": "4px 8px",
              "filePath": "src/components/tooltip.json",
              "isSource": true,
              "original": {
                "value": "{spacing.inner.base.value} {spacing.inner.base02.value}"
              },
              "name": "ComponentsTooltipVariantDarkBodyPadding",
              "attributes": {
                "category": "components",
                "type": "tooltip",
                "item": "variant",
                "subitem": "dark",
                "state": "body"
              },
              "path": [
                "components",
                "tooltip",
                "variant",
                "dark",
                "body",
                "padding"
              ]
            }
          },
          "icon": {
            "color": {
              "value": "#ffffff",
              "filePath": "src/components/tooltip.json",
              "isSource": true,
              "original": {
                "value": "{color.base.white.value}"
              },
              "name": "ComponentsTooltipVariantDarkIconColor",
              "attributes": {
                "category": "components",
                "type": "tooltip",
                "item": "variant",
                "subitem": "dark",
                "state": "icon"
              },
              "path": [
                "components",
                "tooltip",
                "variant",
                "dark",
                "icon",
                "color"
              ]
            }
          }
        }
      },
      "gap": {
        "value": 8,
        "filePath": "src/components/tooltip.json",
        "isSource": true,
        "original": {
          "value": 8
        },
        "name": "ComponentsTooltipGap",
        "attributes": {
          "category": "components",
          "type": "tooltip",
          "item": "gap"
        },
        "path": [
          "components",
          "tooltip",
          "gap"
        ]
      }
    }
  }
};