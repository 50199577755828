import React from "react";
import PropTypes from "prop-types";
import { IconOnlyButton, TextBodyThree } from "../../";

const Pagination = ({
  dataRange,
  dataTotal,
  prevClick,
  nextClick,
  isFirst,
  isLast
}) => {
  const buttonTextColor = disabled =>
    disabled ? "aui-text-grey-2" : "aui-text-grey-5";

  return (
    <div className="aui-inline-block">
      <div className="aui-flex-1 aui-flex aui-items-center aui-justify-between">
        <div>
          <TextBodyThree className="aui-mt-1 aui-text-grey-4">
            {dataRange}
            {dataTotal && ` / ${dataTotal}`}
          </TextBodyThree>
        </div>
        <div>
          <nav className="aui-relative aui-z-0 aui-inline-flex aui-ml-x-small aui-border aui-border-solid aui-border-grey-2">
            <IconOnlyButton
              icon="caret-left"
              className={`${buttonTextColor(
                isFirst
              )} aui-border-0 aui-border-transparent aui-shadow-none`}
              onClick={prevClick}
              disabled={isFirst}
            />
            <IconOnlyButton
              icon="caret-right"
              className={`${buttonTextColor(
                isLast
              )} aui-border-0 aui-border-transparent aui-shadow-none`}
              onClick={nextClick}
              disabled={isLast}
            />
          </nav>
        </div>
      </div>
    </div>
  );
};

Pagination.propTypes = {
  dataRange: PropTypes.string,
  dataTotal: PropTypes.string,
  prevClick: PropTypes.func,
  nextClick: PropTypes.func,
  isFirst: PropTypes.bool,
  isLast: PropTypes.bool
};

export { Pagination };
