import * as React from "react";

function SvgSc(props) {
  return (
    <svg viewBox="0 0 640 480" {...props}>
      <defs>
        <clipPath id="sc_svg__a">
          <path fillOpacity={0.67} d="M0 0h682.67v512H0z" />
        </clipPath>
      </defs>
      <g
        fillRule="evenodd"
        strokeWidth="1pt"
        clipPath="url(#sc_svg__a)"
        transform="scale(.9375)"
      >
        <path fill="red" d="M0 0h992.13v512H0z" />
        <path fill="#090" d="M0 512l992.12-170.67V512H0z" />
        <path fill="#fff" d="M0 512l992.12-341.33v170.67L0 512z" />
        <path fill="#009" d="M0 512V0h330.71L0 512z" />
        <path fill="#ff0" d="M0 512L330.71 0h330.71L0 512z" />
      </g>
    </svg>
  );
}

export default SvgSc;
