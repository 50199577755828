import React from "react";
import PropTypes from "prop-types";

const Table = props => {
  return (
    <table className="aui-table-auto aui-border-collapse">
      <thead className="aui-border aui-bg-grey-1 aui-border-grey-2 aui-border-solid">
        <tr>
          {props.headers.map((header, index) => (
            <th
              key={index}
              className="aui-border aui-border-grey-2 aui-border-solid aui-p-4 aui-uppercase aui-text-grey-4 aui-text-xs"
            >
              {header}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {props.data.length ? (
          props.data.map((row, index) => {
            return (
              <tr
                key={index}
                className={index % 2 !== 0 ? "aui-bg-grey-1" : ""}
              >
                {row.map((item, index) => {
                  return (
                    <td
                      key={index}
                      className="aui-border aui-border-grey-2 aui-border-solid aui-p-4"
                    >
                      {item}
                    </td>
                  );
                })}
              </tr>
            );
          })
        ) : (
          <tr className="aui-text-center aui-border aui-bg-grey-1 aui-border-grey-2 aui-border-solid">
            <td
              colSpan={props.headers.length}
              className="aui-text-grey-4 aui-text-sm aui-p-3"
            >
              No data exists.
            </td>
          </tr>
        )}
      </tbody>
    </table>
  );
};
export { Table };

Table.propTypes = {
  headers: PropTypes.any,
  data: PropTypes.any
};
