import React, { useRef, useState } from "react";
import PropTypes from "prop-types";
import Editor from "draft-js-plugins-editor";
import { stateFromMarkdown } from "draft-js-import-markdown";
import "draft-js/dist/Draft.css";
import { EditorState, ContentState } from "draft-js";
import "./index.pcss";

const RichTextDisplay = props => {
  const ref = useRef();
  const [editorState, setEditorState] = useState(
    props.value ? safeCreateContent(props.value) : EditorState.createEmpty()
  );

  function safeCreateContent(value) {
    try {
      return EditorState.createWithContent(stateFromMarkdown(value));
    } catch (_) {
      return EditorState.createWithContent(ContentState.createFromText(value));
    }
  }

  return (
    <div className="as-rich-text-display">
      <div className="aui-markdown-content">
        <Editor ref={ref} editorState={editorState} readOnly={true} />
      </div>
    </div>
  );
};

RichTextDisplay.propTypes = {
  value: PropTypes.string
};

export { RichTextDisplay };
