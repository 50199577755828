import React from "react";

function Shadow(shadowClass) {
  // eslint-disable-next-line react/display-name
  return props => {
    // eslint-disable-next-line react/prop-types
    const { children, className } = props;
    return <div className={`${shadowClass} ${className}`}>{children}</div>;
  };
}

const ModuleShadow = Shadow("aui-module-shadow");
const DownwardsShadow = Shadow("aui-downwards-shadow");
const BottomShadow = Shadow("aui-bottom-shadow");
const TopShadow = Shadow("aui-top-shadow");
const LeftShadow = Shadow("aui-left-shadow");
const RightShadow = Shadow("aui-right-shadow");

export {
  ModuleShadow,
  DownwardsShadow,
  BottomShadow,
  TopShadow,
  LeftShadow,
  RightShadow
};
