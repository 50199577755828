import React, { forwardRef, useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";

import { FieldError } from "../FieldError";

const TextArea = forwardRef((props, ref) => {
  const {
    rows,
    cols,
    id,
    error,
    warning,
    borderColor,
    bgColor,
    className,
    ariaLabelledby
  } = props;

  const [value, setValue] = useState([props.value]);
  const textareaRef = useRef(null);
  useEffect(() => {
    setValue(props.value || "");
  }, [props.value]);

  function onChange(e) {
    if (props.onChange) props.onChange(e.target.value);
    setValue(e.target.value);
  }

  let borderStyles = [
    "aui-border",
    borderColor ||
      (error ? "aui-border-error-2" : "aui-border-grey-2") ||
      (warning ? "aui-border-warning-2" : ""),
    "aui-border-solid"
  ];

  const classes = [
    "aui-rounded",
    "aui-p-2",
    "focus:aui-outline-none",
    "aui-shadow-inner",
    "aui-appearance-none",
    "aui-text-black",
    bgColor || "aui-bg-grey-1",
    "focus:aui-bg-white",
    ...borderStyles,
    className || ""
  ].join(" ");

  return (
    <React.Fragment>
      <textarea
        ref={ref || textareaRef}
        rows={rows || 10}
        cols={cols || 50}
        placeholder={props.placeholder || ""}
        value={value}
        onChange={onChange}
        onKeyPress={props.onKeyPress}
        autoFocus={props.autoFocus}
        className={classes}
        id={id}
        aria-labelledby={ariaLabelledby}
      ></textarea>
      {error && <FieldError>{error}</FieldError>}
      {warning && <FieldError warning={true}>{warning}</FieldError>}
    </React.Fragment>
  );
});

TextArea.propTypes = {
  className: PropTypes.string,
  placeholder: PropTypes.string,
  rows: PropTypes.any,
  cols: PropTypes.any,
  value: PropTypes.any,
  onChange: PropTypes.func,
  borderColor: PropTypes.string,
  bgColor: PropTypes.string,
  error: PropTypes.string,
  autoFocus: PropTypes.bool,
  warning: PropTypes.string,
  onKeyPress: PropTypes.func,
  id: PropTypes.string,
  ariaLabelledby: PropTypes.string
};

TextArea.displayName = "TextArea";

export { TextArea };
