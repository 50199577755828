import React from "react";
import PropTypes from "prop-types";
import { SimpleSpinner } from "../../";

function InlineLoadingMessage(props) {
  const spinnerColor = `aui-text-${props.spinnerColor}` || null;
  return (
    <span className="aui-text-grey-4">
      <SimpleSpinner className={`aui-inline aui-text-2xl ${spinnerColor}`} />
      <span className="aui-ml-8">{props.children}</span>
    </span>
  );
}

function RandomInlineLoadingMessage() {
  const messages = [
    "Have a good day...please wait.",
    "Fetching your data...Hum something loud while others stare.",
    "Searching...It's still faster than you could draw it.",
    "My other loading screen is much faster.",
    "Are we there yet?",
    "Unicorns are at the end of this road, I promise.",
    "Searching...Time flies when you're having fun.",
    "Please wait...Convincing AI not to turn evil.",
    "Winter is coming...",
    "Searching...Everything in this universe is either a potato or not a potato.",
    "Discovering new ways of making you wait...",
    "Still faster than a Windows update...",
    "Sometimes I'll start a sentence and I don't even know where it's going. I just hope I find it along the way.",
    '"The worst thing about prison was the dementors." - Michael "Prison Mike" Scott',
    "Just pretend like we're talking until the cops leave."
  ];

  function getRandomMessage() {
    return messages[Math.floor(Math.random() * messages.length)];
  }

  return <InlineLoadingMessage>{getRandomMessage()}</InlineLoadingMessage>;
}

InlineLoadingMessage.propTypes = {
  children: PropTypes.any,
  spinnerColor: PropTypes.string
};

export { InlineLoadingMessage, RandomInlineLoadingMessage };
