import React, { useRef, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { LinkableDropdownRowItem } from "../Forms/";
import { Icon } from "../../";
import { useOutsideClick } from "../../../hooks";
import { values as ObjectValues } from "lodash";

const DropdownMenu = props => {
  const { items, handle, mouseEvent = DropdownMenu.EVENT.HOVER } = props;
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const ref = useRef();

  useOutsideClick(ref, () => setDropdownVisible(false));

  const classes = [
    "aui-dropdown-toggle",
    "focus:aui-outline-none",
    "focus:aui-bg-white",
    "aui-text-left",
    "aui-relative",
    "aui-text-dark-1",
    "aui-cursor-pointer",
    "aui-font-semibold",
    "aui-text-body",
    props.className || ""
  ].join(" ");

  const dropdownPositionClassName =
    props.dropdownPosition === DropdownMenu.POSITION.LEFT
      ? "aui-left-0"
      : "aui-right-0";

  let additionalDropdownClasses = "";

  if (props.variant === "light") {
    additionalDropdownClasses =
      "aui-border aui-border-black aui-border-l-0 aui-border-b-0 aui-border-r-0 aui-border-solid aui-px-5 aui-pt-2 aui-pb-1";
  }

  if (props.variant === "rounded") {
    additionalDropdownClasses = "aui-rounded-lg";
  }

  function handleToggleClick(item, event, handle = false) {
    if (props.onClick) {
      // eslint-disable-next-line no-console
      console.warn(
        "DropdownMenu component: onClick method is deprecated. Please use onHandleClick for the handler and onItemClick for the items."
      );
      props.onClick(item, event);
    }
    if (handle && props.onHandleClick) {
      props.onHandleClick(event);
    }
    if (!handle && props.onItemClick) {
      props.onItemClick(item, event);
    }

    if (mouseEvent === DropdownMenu.EVENT.CLICK) {
      setDropdownVisible(!dropdownVisible);
    } else {
      setDropdownVisible(false);
    }
  }

  function handleHoverOn(e) {
    if (mouseEvent === DropdownMenu.EVENT.HOVER) {
      setDropdownVisible(true);
    }
  }

  function handleHoverOff(e) {
    if (mouseEvent === DropdownMenu.EVENT.HOVER) {
      setDropdownVisible(false);
    }
  }

  function getValue(item) {
    return props.getValue ? props.getValue(item) : item;
  }

  return (
    <div
      onMouseEnter={handleHoverOn}
      onMouseLeave={handleHoverOff}
      className="aui-dropdown-main"
    >
      <div className={classes} ref={ref}>
        <div
          data-testid="aui-dropdown-menu"
          className={"hover:aui-text-primary-2 aui-dropdown-default"}
          onClick={event => handleToggleClick("default", event, true)}
        >
          {handle}
          {!props.hideDropdownToggle && (
            <Icon
              icon="caret-down"
              className={
                "aui-ml-2 aui-text-tiny aui-inline-block " +
                (dropdownVisible ? "aui-rotate-180" : "aui-rotate-0")
              }
            />
          )}
        </div>

        {dropdownVisible && (
          <div
            className={`aui-absolute ${dropdownPositionClassName} ${
              props.dropdownWidth
                ? `aui-w-${props.dropdownWidth}`
                : props.dropdownWidthClassName
                ? props.dropdownWidthClassName
                : "aui-w-64"
            }`}
          >
            <div
              className={`aui-dropdown-content aui-bg-white aui-z-50 aui-shadow aui-absolute aui-w-full ${additionalDropdownClasses}`}
            >
              {items.map((item, idx) => {
                return (
                  <LinkableDropdownRowItem
                    key={idx}
                    idx={idx}
                    href={item.href}
                    variant={props.variant}
                    selected={props.selected === item}
                    onClick={event => handleToggleClick(item, event)}
                    className={props.rowItemClassName || ""}
                  >
                    {item.icon && (
                      <Icon
                        icon={item.icon}
                        className={`aui-mr-3${
                          item.iconClassNames ? " " + item.iconClassNames : ""
                        }`}
                      />
                    )}
                    {getValue(item)}
                  </LinkableDropdownRowItem>
                );
              })}

              {props.children}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

DropdownMenu.POSITION = {
  LEFT: 1,
  RIGHT: 2
};
DropdownMenu.EVENT = {
  HOVER: 0,
  CLICK: 1
};

DropdownMenu.propTypes = {
  items: PropTypes.array,
  className: PropTypes.string,
  dropdownWidthClassName: PropTypes.string,
  rowItemClassName: PropTypes.string,
  handle: PropTypes.any,
  hideDropdownToggle: PropTypes.bool,
  dropdownWidth: PropTypes.string,
  variant: PropTypes.string,
  selected: PropTypes.string,
  onClick: PropTypes.func,
  onHandleClick: PropTypes.func,
  onItemClick: PropTypes.func,
  getValue: PropTypes.func,
  children: PropTypes.any,
  dropdownPosition: PropTypes.oneOf(ObjectValues(DropdownMenu.POSITION)),
  mouseEvent: PropTypes.oneOf(ObjectValues(DropdownMenu.EVENT))
};

export { DropdownMenu };
