import React, { useEffect, useState, useRef } from "react";
import { AgGridReact } from "@ag-grid-community/react";
import { ClientSideRowModelModule } from "@ag-grid-community/client-side-row-model";
import "@ag-grid-community/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-community/all-modules/dist/styles/ag-theme-balham/sass/legacy/_ag-theme-balham-v22-compat.scss";
import PropTypes from "prop-types";
import { values as ObjectValues } from "lodash";

import { ElementResize } from "../../../services";
import { Icon } from "../../";
import { CheckboxHeader } from "./Components/CheckboxHeader";

import "./index.pcss";

const Grid = props => {
  const [gridAPIs, setGridAPIs] = useState({});
  const [disabled, setDisabled] = useState();

  const onGridReady = params => {
    setGridAPIs(params);
    if (props.onGridReady) props.onGridReady(params);
  };
  const onColumnResized = params => {
    if (params.finished) params.api.refreshHeader();
    if (props.agGridOptions && props.agGridOptions.onColumnResized) {
      props.agGridOptions.onColumnResized(params);
    }
  };
  const frameworkComponents = props.frameworkComponents || {};

  let lastSelectedRowIndex = null;

  const gridWrapperElement = useRef();

  useEffect(
    () =>
      setDisabled(
        props.rowData.length === 0 && !props.suppressDisabledWhenEmpty
      ),
    [props.rowData.length]
  );

  useEffect(() => {
    const autoSizeColumns = () => {
      if (props.autoSizeOnUpdate && gridAPIs.columnApi) {
        const getTotalColumnsWidth = () =>
          gridAPIs.columnApi
            .getAllColumns()
            .map(c => c.getActualWidth())
            .reduce((acc, width) => acc + width, 0);

        gridAPIs.columnApi.autoSizeAllColumns();
        const minimumWidth = getTotalColumnsWidth();
        gridAPIs.api.sizeColumnsToFit();
        const availableWidth = getTotalColumnsWidth();

        if (minimumWidth > availableWidth) {
          gridAPIs.columnApi.autoSizeAllColumns();
        }
      }
    };
    autoSizeColumns();

    const removeListener = ElementResize.onResize(
      gridWrapperElement.current,
      autoSizeColumns
    );

    setDisabled(
      (!props.rowData || props.rowData.length === 0) &&
        !props.suppressDisabledWhenEmpty
    );

    return removeListener;
  }, [
    gridAPIs,
    props.rowData,
    gridWrapperElement.current,
    props.onSelectionChanged,
    props.onSortChanged
  ]);

  useEffect(() => {
    if (gridAPIs.api) {
      const promise = props.loadingPromise || Promise.resolve();
      gridAPIs.api.showLoadingOverlay();
      promise
        .then(() => {
          if (!props.rowData.length) {
            gridAPIs.api.showNoRowsOverlay();
          } else {
            gridAPIs.api.hideOverlay();
          }
        })
        .catch(() => {});

      if (props.sortModel) gridAPIs.api.setSortModel(props.sortModel);
    }
  }, [gridAPIs, props.rowData, props.loadingPromise]);

  useEffect(() => {
    if (gridAPIs.api && props.selection) {
      const currentSelectionKey = JSON.stringify(
        gridAPIs.api
          .getSelectedRows()
          .map(row => row.id)
          .sort()
      );
      const propSelectionKey = JSON.stringify(
        props.selection.map(row => row.id).sort()
      );
      if (currentSelectionKey !== propSelectionKey) {
        gridAPIs.api.deselectAll();
        props.selection.forEach(row => {
          gridAPIs.api.getRowNode(row.id).setSelected(true);
        });
      }
    }
  }, [gridAPIs, props.selection]);

  const onSelectionChange = params => {
    if (props.onSelectionChanged) {
      props.onSelectionChanged(params.api.getSelectedRows());
    }
    params.api.refreshHeader();
  };

  const onSortChange = params => {
    if (props.onSortChanged) props.onSortChanged(params.api.getSortModel());
  };

  const defaultColDef = {
    sortable: false,
    cellClassRules: props.cellClassRules
  };

  const customCodeSelectionColumn = {
    colId: "auiSelectionCol",
    pinned: true,
    maxWidth: 42,
    width: 42,
    suppressSizeToFit: true,
    cellClass: "selection-indicator-cell",
    onCellClicked: ({ node, event, api, rowIndex }) => {
      if (event.shiftKey) {
        // Select all nodes between the current node and last selected node
        if (rowIndex > lastSelectedRowIndex) {
          // ...downwards selection
          api.forEachNodeAfterFilter(n => {
            if (n.rowIndex > lastSelectedRowIndex && n.rowIndex < rowIndex) {
              n.setSelected(true);
            }
          });
        } else {
          // ...upwards selection
          api.forEachNodeAfterFilter(n => {
            if (n.rowIndex < lastSelectedRowIndex && n.rowIndex > rowIndex) {
              n.setSelected(true);
            }
          });
        }
      }
      node.setSelected(!node.selected);
      lastSelectedRowIndex = rowIndex;
    },
    headerComponentFramework: props.canSelectAll && CheckboxHeader,
    cellRendererFramework: function selectionRenderer() {
      return (
        <div>
          <Icon
            className="aui-text-2xl aui-pt-4 aui-inline-block icon-selected"
            icon="checkbox-filled"
          />
          <Icon
            className="aui-text-2xl aui-pt-4 aui-inline-block icon-deselected"
            icon="checkbox"
          />
        </div>
      );
    }
  };

  const agGridSelectionColumn = {
    colId: "auiSelectionCol",
    pinned: true,
    maxWidth: 42,
    width: 42,
    suppressSizeToFit: true,
    checkboxSelection: true,
    headerCheckboxSelection: props.canSelectAll
  };

  const columnDefs = props.onSelectionChanged
    ? [
        props.useAgGridSelection
          ? agGridSelectionColumn
          : customCodeSelectionColumn,
        ...props.columnDefs
      ]
    : props.columnDefs;

  return (
    <div
      className={`ag-theme-balham aui-ag-grid ${
        disabled ? "aui-pointer-events-none" : ""
      }`}
      ref={gridWrapperElement}
    >
      <AgGridReact
        immutableData
        domLayout={props.autoHeight ? "autoHeight" : null}
        getRowNodeId={data => data.id || ObjectValues(data)[0]}
        onGridReady={onGridReady}
        modules={[ClientSideRowModelModule]}
        defaultColDef={defaultColDef}
        frameworkComponents={frameworkComponents}
        columnDefs={columnDefs}
        rowData={props.rowData}
        rowClassRules={props.rowClassRules}
        onSelectionChanged={onSelectionChange}
        onSortChanged={onSortChange}
        rowSelection={props.onSelectionChanged ? "multiple" : false}
        suppressRowClickSelection={!props.useAgGridSelection}
        headerHeight={36}
        rowHeight={47}
        {...props.agGridOptions}
        onColumnResized={onColumnResized}
      />
    </div>
  );
};

export { Grid };

Grid.propTypes = {
  agGridOptions: PropTypes.object,
  autoSizeOnUpdate: PropTypes.bool,
  autoHeight: PropTypes.bool,
  rowData: PropTypes.array,
  selection: PropTypes.array,
  columnDefs: PropTypes.array,
  sortModel: PropTypes.array,
  loadingPromise: PropTypes.instanceOf(Promise),
  onSelectionChanged: PropTypes.func,
  onSortChanged: PropTypes.func,
  cellClassRules: PropTypes.object,
  rowClassRules: PropTypes.object,
  frameworkComponents: PropTypes.object,
  suppressDisabledWhenEmpty: PropTypes.bool,
  suppressRowClickSelection: PropTypes.bool,
  canSelectAll: PropTypes.bool,
  onGridReady: PropTypes.func,
  useAgGridSelection: PropTypes.bool
};

Grid.defaultProps = {
  rowData: [],
  useAgGridSelection: false
};
