import * as React from "react";

function SvgEu(props) {
  return (
    <svg viewBox="0 0 640 480" {...props}>
      <defs>
        <g id="eu_svg__d">
          <g id="eu_svg__b">
            <path id="eu_svg__a" d="M0-1l-.31.95.477.156z" />
            <use transform="scale(-1 1)" xlinkHref="#eu_svg__a" />
          </g>
          <g id="eu_svg__c">
            <use transform="rotate(72)" xlinkHref="#eu_svg__b" />
            <use transform="rotate(144)" xlinkHref="#eu_svg__b" />
          </g>
          <use transform="scale(-1 1)" xlinkHref="#eu_svg__c" />
        </g>
      </defs>
      <path fill="#039" d="M0 0h640v480H0z" />
      <g fill="#fc0" transform="translate(320 242.263) scale(23.7037)">
        <use width="100%" height="100%" y={-6} xlinkHref="#eu_svg__d" />
        <use width="100%" height="100%" y={6} xlinkHref="#eu_svg__d" />
        <g id="eu_svg__e">
          <use width="100%" height="100%" x={-6} xlinkHref="#eu_svg__d" />
          <use
            width="100%"
            height="100%"
            transform="rotate(-144 -2.344 -2.11)"
            xlinkHref="#eu_svg__d"
          />
          <use
            width="100%"
            height="100%"
            transform="rotate(144 -2.11 -2.344)"
            xlinkHref="#eu_svg__d"
          />
          <use
            width="100%"
            height="100%"
            transform="rotate(72 -4.663 -2.076)"
            xlinkHref="#eu_svg__d"
          />
          <use
            width="100%"
            height="100%"
            transform="rotate(72 -5.076 .534)"
            xlinkHref="#eu_svg__d"
          />
        </g>
        <use
          width="100%"
          height="100%"
          transform="scale(-1 1)"
          xlinkHref="#eu_svg__e"
        />
      </g>
    </svg>
  );
}

export default SvgEu;
