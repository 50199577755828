import * as React from "react";

function SvgAo(props) {
  return (
    <svg viewBox="0 0 640 480" {...props}>
      <g fillRule="evenodd" strokeWidth="1pt">
        <path fill="red" d="M0 0h640v243.574H0z" />
        <path d="M0 236.428h640v243.574H0z" />
      </g>
      <path
        fill="#ffec00"
        fillRule="evenodd"
        d="M228.67 148.173c165.22 43.304 58.99 255.64-71.216 167.26l-8.817 13.545c76.628 54.658 152.57 10.66 173.9-46.358 22.297-58.788-7.52-141.48-92.51-150.03l-1.356 15.576z"
      />
      <path
        fill="#ffec00"
        fillRule="evenodd"
        d="M169.955 330.827l21.73 10.125-10.142 21.696-21.73-10.125zm148.985-99.48h23.98v23.942h-23.98zm-11.684-38.892l22.342-8.694 8.707 22.31-22.342 8.693zm-25.894-29.188l17.035-16.85 16.877 17.01-17.035 16.85zm-26.284-39.787l22.434 8.455-8.468 22.4-22.434-8.455zM316.1 270.01l22.265 8.888-8.902 22.23-22.265-8.887zm-69.876 70.05l22.06-9.388 9.402 22.025-22.058 9.39zm-39.504 2.77h23.98v23.94h-23.98zm41.29-115.937l-20.35-15.006-20.245 14.47 8.034-22.92-20.348-14.956 24.447-.17 8.567-22.55 7.782 22.702 24.7-.242-19.586 15.232 6.996 23.44z"
      />
      <path
        fill="#fe0"
        fillRule="evenodd"
        d="M336.03 346.376c-1.21.418-6.23 12.39-9.675 18.248 1.797.51 2.56.596 3.625 1.025 13.655 4.8 20.384 9.18 26.186 17.504 2.888 2.79 7.032 2.93 10.198.697 0 0 2.795-1.114 6.43-5.02 2.968-4.52 2.194-8.11-1.384-11.16-10.944-7.952-22.9-13.902-35.38-21.295z"
      />
      <path
        fillRule="evenodd"
        d="M365.247 372.842c0 2.388-1.94 4.324-4.33 4.324s-4.333-1.936-4.333-4.324 1.94-4.325 4.332-4.325 4.33 1.936 4.33 4.325zM343.87 359.17c0 2.388-1.94 4.324-4.33 4.324s-4.333-1.936-4.333-4.324 1.94-4.325 4.332-4.325 4.33 1.936 4.33 4.325zm10.898 6.975c0 2.39-1.94 4.325-4.33 4.325s-4.333-1.936-4.333-4.325 1.94-4.324 4.332-4.324 4.33 1.937 4.33 4.325z"
      />
      <path
        fill="#fe0"
        fillRule="evenodd"
        d="M324.47 363.667c-42.57-24.273-87.31-50.52-129.88-74.796-18.75-11.635-19.683-33.384-7.17-49.875 1.302-2.337 2.836-1.758 3.514-.524 1.463 8.03 5.97 16.325 11.37 21.496 44.693 28.383 87.732 55.804 131.71 85.613-3.448 5.767-6.104 12.32-9.55 18.086z"
      />
      <path
        fill="#ffec00"
        fillRule="evenodd"
        d="M297.174 305.457l17.85 15.986-16.01 17.824-17.85-15.986z"
      />
      <path
        fill="none"
        stroke="#000"
        strokeWidth={3.05}
        d="M331.54 348.82L206.58 273.3m109.53 58.093l-42.24-27.28m18.21 42.687l-42.75-24.755"
      />
    </svg>
  );
}

export default SvgAo;
