import * as React from "react";

function SvgCm(props) {
  return (
    <svg viewBox="0 0 640 480" {...props}>
      <path fill="#007a5e" d="M0 0h213.333v480H0z" />
      <path fill="#ce1126" d="M213.333 0h213.333v480H213.333z" />
      <path fill="#fcd116" d="M426.667 0H640v480H426.667z" />
      <g fill="#fcd116" transform="translate(320 240) scale(7.1111)">
        <g id="cm_svg__b">
          <path id="cm_svg__a" d="M0-8L-2.472-.392 1.332.845z" />
          <use
            width="100%"
            height="100%"
            transform="scale(-1 1)"
            xlinkHref="#cm_svg__a"
          />
        </g>
        <use
          width="100%"
          height="100%"
          transform="rotate(72)"
          xlinkHref="#cm_svg__b"
        />
        <use
          width="100%"
          height="100%"
          transform="rotate(144)"
          xlinkHref="#cm_svg__b"
        />
        <use
          width="100%"
          height="100%"
          transform="rotate(-144)"
          xlinkHref="#cm_svg__b"
        />
        <use
          width="100%"
          height="100%"
          transform="rotate(-72)"
          xlinkHref="#cm_svg__b"
        />
      </g>
    </svg>
  );
}

export default SvgCm;
