import React from "react";
import PropTypes from "prop-types";

const FieldError = props => {
  const backgroundColor = props.warning ? "aui-bg-warning-2" : "aui-bg-error-2";
  const classes = [
    "aui-inline-block",
    "aui-text-white",
    "aui-text-xs",
    "aui-pl-4",
    "aui-pt-1",
    "aui-pb-1",
    "aui-pr-4",
    "aui-rounded",
    "aui-mt-1",
    "aui-shadow",
    backgroundColor
  ].join(" ");

  return (
    <div className="aui-field-error">
      <div
        className={`aui-h-2 aui-w-2 aui-ml-3 aui-rotate-45 aui--mb-2 ${backgroundColor}`}
      ></div>
      <div className={classes}>{props.children}</div>
    </div>
  );
};
FieldError.propTypes = {
  warning: PropTypes.bool,
  children: PropTypes.any
};

export { FieldError };
