import * as React from "react";

function SvgSr(props) {
  return (
    <svg viewBox="0 0 640 480" {...props}>
      <path fill="#377e3f" d="M.1 0h640v480H.1z" />
      <path fill="#fff" d="M.1 96h640v288H.1z" />
      <path fill="#b40a2d" d="M.1 144h640v192H.1z" />
      <path
        fill="#ecc81d"
        d="M320 153.167l56.427 173.666-147.73-107.33h182.605l-147.73 107.33z"
      />
    </svg>
  );
}

export default SvgSr;
