import React from "react";
import PropTypes from "prop-types";

import { TextHeaderFour, TextBodyTwo } from "../Styles/Text";
import { Tooltip } from "../Tooltip";

const Compare = props => {
  const className = [
    "aui-inline-block",
    "aui-max-w-sm",
    "aui-overflow-hidden",
    "aui-min-w-compare",
    "aui-box-border",
    "aui-bg-white",
    "aui-text-grey-5",
    "aui-border",
    "aui-border-solid",
    "aui-border-grey-2",
    "aui-rounded",
    props.tooltip ? "aui-overflow-visible" : "",
    props.className || ""
  ]
    .join(" ")
    .trim();

  const isString = value => typeof value === "string";
  const isFunction = value => value instanceof Function;

  const titleClick = props.onTitleClick;
  const titleClass =
    "aui-text-black hover:aui-text-primary-1 aui-no-underline" +
    (titleClick ? " aui-cursor-pointer" : "");

  return (
    <div className={className}>
      <div>
        {titleClick && (
          <a
            className={titleClass}
            onClick={isFunction(titleClick) ? titleClick : null}
            href={isString(titleClick) ? titleClick : null}
            // eslint-disable-next-line react/jsx-no-target-blank
            target={isString(titleClick) ? "_blank" : null}
          >
            {props.title}
          </a>
        )}
        {!titleClick && props.title}
        {props.tooltip && (
          <span className={"aui-float-right aui-text-grey-3"}>
            <Tooltip
              icon={"more-info"}
              position={"bottom"}
              iconSize={"xs"}
              wide={true}
              narrow={false}
              noMargin={false}
              message={props.tooltip}
            />
          </span>
        )}
      </div>
      {!props.variant && (
        <TextHeaderFour className="aui-text-grey-5">
          {props.children}
        </TextHeaderFour>
      )}
      {props.variant === "positive" && (
        <TextHeaderFour className="aui-text-success-2">
          {props.children}
        </TextHeaderFour>
      )}
      {props.variant === "neutral" && (
        <TextHeaderFour className="aui-text-grey-5">
          {props.children}
        </TextHeaderFour>
      )}
      {props.variant === "negative" && (
        <TextHeaderFour className="aui-text-error-2">
          {props.children}
        </TextHeaderFour>
      )}
      {props.variant && (
        <TextBodyTwo className="aui-text-grey-4">{props.subtitle}</TextBodyTwo>
      )}
    </div>
  );
};

export { Compare };

Compare.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  onTitleClick: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  className: PropTypes.string,
  children: PropTypes.any,
  variant: PropTypes.string,
  tooltip: PropTypes.any,
  tooltipVariant: PropTypes.string
};
