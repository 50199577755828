import React from "react";
import RcSlider, { createSliderWithTooltip } from "rc-slider";
import PropTypes from "prop-types";
import "rc-slider/assets/index.css";
import "./index.pcss";

const RcRange = RcSlider.Range;
const TipRange = createSliderWithTooltip(RcRange);
const TipSlider = createSliderWithTooltip(RcSlider);

export const Slider = ({ range = false, tooltip = false, ...props }) => {
  const RangeComponent = tooltip ? TipRange : RcRange;
  const SliderComponent = tooltip ? TipSlider : RcSlider;

  return range ? <RangeComponent {...props} /> : <SliderComponent {...props} />;
};

Slider.propTypes = {
  range: PropTypes.bool,
  tooltip: PropTypes.bool
};
