import ApolloClient from "apollo-boost";

export default config => {
  const apollo = new ApolloClient({
    uri: config.apiURL
  });
  return {
    query(...args) {
      return apollo.query(...args);
    },
    watchQuery(...args) {
      return apollo.watchQuery(...args);
    },
    mutate(...args) {
      return apollo.mutate(...args);
    }
  };
};
