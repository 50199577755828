import * as React from "react";

function SvgTz(props) {
  return (
    <svg viewBox="0 0 640 480" {...props}>
      <defs>
        <clipPath id="tz_svg__a">
          <path fillOpacity={0.67} d="M10 0h160v120H10z" />
        </clipPath>
      </defs>
      <g
        fillRule="evenodd"
        strokeWidth="1pt"
        clipPath="url(#tz_svg__a)"
        transform="matrix(4 0 0 4 -40 0)"
      >
        <path fill="#09f" d="M0 0h180v120H0z" />
        <path fill="#090" d="M0 0h180L0 120V0z" />
        <path d="M0 120h40l140-95V0h-40L0 95v25z" />
        <path
          fill="#ff0"
          d="M0 91.456L137.18 0h13.52L0 100.47v-9.014zM29.295 120l150.7-100.47v9.014L42.815 120h-13.52z"
        />
      </g>
    </svg>
  );
}

export default SvgTz;
