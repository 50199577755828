import React from "react";
import PropTypes from "prop-types";

export default function TextBody(props) {
  const className = props.className || "";
  return (
    <div
      className={
        props.textBodyClassName
          ? props.textBodyClassName
          : `aui-text-body aui-text-dark-1 aui-mb-2 ${className}`
      }
    >
      {props.children}
    </div>
  );
}

TextBody.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string,
  textBodyClassName: PropTypes.string
};
