import * as React from "react";

function SvgOm(props) {
  return (
    <svg viewBox="0 0 640 480" {...props}>
      <defs>
        <clipPath id="om_svg__a">
          <path fillOpacity={0.67} d="M0 0h640v480H0z" />
        </clipPath>
      </defs>
      <g clipPath="url(#om_svg__a)">
        <path
          fill="#ef2d29"
          fillRule="evenodd"
          d="M-3.299-21.609h702.27v553.02H-3.299z"
        />
        <path
          fill="#009025"
          fillRule="evenodd"
          d="M174.57 317.33h535.76v207.56H174.57z"
        />
        <path
          fill="#fff"
          fillRule="evenodd"
          d="M174.57-35.415h564.88v190.08H174.57z"
        />
        <g stroke="#ef2d28" strokeWidth={0.983}>
          <g
            fill="#fff"
            fillRule="evenodd"
            transform="matrix(.19848 0 0 .17744 111.26 -13.444)"
          >
            <rect
              width={138.19}
              height={85.039}
              x={17.717}
              y={467.72}
              strokeWidth={1.406}
              rx={11.298}
              ry={11.851}
            />
            <rect
              width={131.1}
              height={77.953}
              x={21.26}
              y={471.26}
              strokeWidth={1.311}
              rx={10.719}
              ry={10.864}
            />
            <path
              strokeWidth={1.311}
              d="M64.966 396.07l9.723.443.442 5.745 7.955 5.303 6.187-6.629 7.513 5.303-7.07 5.746 1.767 7.955 8.838-.442v10.607l-7.07-.442-3.536 6.629 7.955 7.513-6.187 6.187-6.63-6.629-9.722 2.652.442 9.723-10.607.884-1.326-9.281-8.839-4.862-4.861 6.629-7.514-4.861 4.42-7.513-5.304-4.862H34.03l-.442-13.7 7.513.884 5.304-7.955-6.187-6.187 7.955-7.071 5.745 5.745 9.723-1.768 1.326-5.746z"
              transform="matrix(.68108 0 0 .5852 37.972 260.74)"
            />
            <ellipse
              cx={68.944}
              cy={426.78}
              strokeWidth={1.311}
              rx={11.049}
              ry={9.944}
              transform="matrix(.65819 0 0 .70224 38.845 209.62)"
            />
            <path
              strokeWidth={1.311}
              d="M38.976 474.8l-10.63 10.63m17.717-10.63l-10.63 10.63m17.716-10.63l-10.63 10.63m17.717-10.63l-10.63 10.63m17.716-10.63l-10.63 10.63m17.717-10.63l-10.63 10.63m17.717-10.63l-10.63 10.63m17.716-10.63l-10.63 10.63m17.717-10.63l-10.63 10.63m17.716-10.63l-10.63 10.63m17.717-10.63l-10.63 10.63m17.717-10.63l-10.63 10.63M88.582 474.8l-10.63 10.63m46.063-10.63l-10.63 10.63m17.717-10.63l-10.63 10.63m17.717-10.63l-10.63 10.63m17.717-10.63l-10.63 10.63m-.002-10.63l10.63 10.63m-17.71-10.63l10.63 10.63m-17.72-10.63l10.63 10.63m-17.72-10.63l10.63 10.63m-17.71-10.63l10.63 10.63m-17.72-10.63l10.63 10.63m-17.72-10.63l10.63 10.63m-17.71-10.63l10.63 10.63m-17.72-10.63l10.63 10.63m-17.72-10.63l10.63 10.63m-17.71-10.63l10.63 10.63M56.693 474.8l10.63 10.63m17.721-10.63l10.63 10.63M49.607 474.8l10.63 10.63M42.52 474.8l10.63 10.63M35.434 474.8l10.63 10.63M28.347 474.8l10.63 10.63m-.001 49.606l-10.63 10.63m17.717-10.63l-10.63 10.63m17.716-10.63l-10.63 10.63m17.717-10.63l-10.63 10.63m17.716-10.63l-10.63 10.63m17.717-10.63l-10.63 10.63m17.717-10.63l-10.63 10.63m17.716-10.63l-10.63 10.63m17.717-10.63l-10.63 10.63m17.716-10.63l-10.63 10.63m17.717-10.63l-10.63 10.63m17.717-10.63l-10.63 10.63m-17.717-10.63l-10.63 10.63m46.063-10.63l-10.63 10.63m17.717-10.63l-10.63 10.63m17.717-10.63l-10.63 10.63m17.717-10.63l-10.63 10.63m-.002-10.63l10.63 10.63m-17.71-10.63l10.63 10.63m-17.72-10.63l10.63 10.63m-17.72-10.63l10.63 10.63m-17.71-10.63l10.63 10.63m-17.72-10.63l10.63 10.63m-17.72-10.63l10.63 10.63m-17.71-10.63l10.63 10.63m-17.72-10.63l10.63 10.63m-17.72-10.63l10.63 10.63m-17.71-10.63l10.63 10.63m-17.721-10.63l10.63 10.63m17.721-10.63l10.63 10.63m-46.067-10.63l10.63 10.63m-17.717-10.63l10.63 10.63m-17.716-10.63l10.63 10.63m-17.717-10.63l10.63 10.63"
            />
          </g>
          <g
            fill="#fff"
            fillRule="evenodd"
            transform="matrix(.19848 0 0 .17744 19.131 -14.073)"
          >
            <rect
              width={138.19}
              height={85.039}
              x={17.717}
              y={467.72}
              strokeWidth={1.406}
              rx={11.298}
              ry={11.851}
            />
            <rect
              width={131.1}
              height={77.953}
              x={21.26}
              y={471.26}
              strokeWidth={1.311}
              rx={10.719}
              ry={10.864}
            />
            <path
              strokeWidth={1.311}
              d="M64.966 396.07l9.723.443.442 5.745 7.955 5.303 6.187-6.629 7.513 5.303-7.07 5.746 1.767 7.955 8.838-.442v10.607l-7.07-.442-3.536 6.629 7.955 7.513-6.187 6.187-6.63-6.629-9.722 2.652.442 9.723-10.607.884-1.326-9.281-8.839-4.862-4.861 6.629-7.514-4.861 4.42-7.513-5.304-4.862H34.03l-.442-13.7 7.513.884 5.304-7.955-6.187-6.187 7.955-7.071 5.745 5.745 9.723-1.768 1.326-5.746z"
              transform="matrix(.68108 0 0 .5852 37.972 260.74)"
            />
            <ellipse
              cx={68.944}
              cy={426.78}
              strokeWidth={1.311}
              rx={11.049}
              ry={9.944}
              transform="matrix(.65819 0 0 .70224 38.845 209.62)"
            />
            <path
              strokeWidth={1.311}
              d="M38.976 474.8l-10.63 10.63m17.717-10.63l-10.63 10.63m17.716-10.63l-10.63 10.63m17.717-10.63l-10.63 10.63m17.716-10.63l-10.63 10.63m17.717-10.63l-10.63 10.63m17.717-10.63l-10.63 10.63m17.716-10.63l-10.63 10.63m17.717-10.63l-10.63 10.63m17.716-10.63l-10.63 10.63m17.717-10.63l-10.63 10.63m17.717-10.63l-10.63 10.63M88.582 474.8l-10.63 10.63m46.063-10.63l-10.63 10.63m17.717-10.63l-10.63 10.63m17.717-10.63l-10.63 10.63m17.717-10.63l-10.63 10.63m-.002-10.63l10.63 10.63m-17.71-10.63l10.63 10.63m-17.72-10.63l10.63 10.63m-17.72-10.63l10.63 10.63m-17.71-10.63l10.63 10.63m-17.72-10.63l10.63 10.63m-17.72-10.63l10.63 10.63m-17.71-10.63l10.63 10.63m-17.72-10.63l10.63 10.63m-17.72-10.63l10.63 10.63m-17.71-10.63l10.63 10.63M56.693 474.8l10.63 10.63m17.721-10.63l10.63 10.63M49.607 474.8l10.63 10.63M42.52 474.8l10.63 10.63M35.434 474.8l10.63 10.63M28.347 474.8l10.63 10.63m-.001 49.606l-10.63 10.63m17.717-10.63l-10.63 10.63m17.716-10.63l-10.63 10.63m17.717-10.63l-10.63 10.63m17.716-10.63l-10.63 10.63m17.717-10.63l-10.63 10.63m17.717-10.63l-10.63 10.63m17.716-10.63l-10.63 10.63m17.717-10.63l-10.63 10.63m17.716-10.63l-10.63 10.63m17.717-10.63l-10.63 10.63m17.717-10.63l-10.63 10.63m-17.717-10.63l-10.63 10.63m46.063-10.63l-10.63 10.63m17.717-10.63l-10.63 10.63m17.717-10.63l-10.63 10.63m17.717-10.63l-10.63 10.63m-.002-10.63l10.63 10.63m-17.71-10.63l10.63 10.63m-17.72-10.63l10.63 10.63m-17.72-10.63l10.63 10.63m-17.71-10.63l10.63 10.63m-17.72-10.63l10.63 10.63m-17.72-10.63l10.63 10.63m-17.71-10.63l10.63 10.63m-17.72-10.63l10.63 10.63m-17.72-10.63l10.63 10.63m-17.71-10.63l10.63 10.63m-17.721-10.63l10.63 10.63m17.721-10.63l10.63 10.63m-46.067-10.63l10.63 10.63m-17.717-10.63l10.63 10.63m-17.716-10.63l10.63 10.63m-17.717-10.63l10.63 10.63"
            />
          </g>
          <path
            fill="#fff"
            fillRule="evenodd"
            strokeWidth={1.311}
            d="M538.58 531.5c1.717 166.56 24.803 201.97 3.543 201.97s-31.89-92.07-31.89-205.51 14.173-205.51 35.433-205.51-8.913 31.909-7.086 209.06z"
            transform="matrix(-.32136 -.12684 -.20158 .20221 345.89 61.391)"
          />
          <path
            fill="#fff"
            fillRule="evenodd"
            strokeWidth={1.229}
            d="M545.67 779.53l-60.236 17.716c56.693 60.236 120.47 85.039 138.19 74.409 17.717-10.629-31.89-35.433-77.953-92.125z"
            transform="matrix(-.19848 0 0 .17744 145.247 -13.446)"
          />
          <path
            fill="#fff"
            fillRule="evenodd"
            strokeWidth={1.27}
            d="M547.27 786.9l-50.926 14.74c56.693 60.236 112.77 77.385 127.28 70.015 14.591-7.37-30.287-28.063-76.35-84.755z"
            transform="matrix(-.19334 0 0 .17062 142.75 -8.126)"
          />
          <path
            fill="none"
            strokeWidth={1.751}
            d="M353.13 634.23c.187 1.273.748 7.068 1.25 9.375 0 3.186.288 5.632.625 8.125.796 2.177.661 4.198 3.125 5.001 1.694 2.631 2.76 3.313 5 4.375 1.527 1.323 3.84 2.642 6.25 3.75 2.499 1.071 4.771 1.218 7.5.625 2.19-1.526 3.804-3.1 5.625-4.375.429-1.973.822-4.903 1.25-6.875-.674 2.024-1.147 5.265-1.25 8.125.24 3.12 1.265 4.481 2.5 6.875"
            transform="matrix(-.13978 0 0 .12414 123.027 20.362)"
          />
          <path
            fill="none"
            strokeWidth={1.751}
            d="M389.38 681.73l.625-.625c-1.484 1.484-.878.776 2.5-1.25 2.426-1.246 5.015-1.908 8.125-2.5h8.75c3.405 0 5.696.511 8.125 1.25 1.762 1.762 4.393 2.763 6.25 4.375 1.905 1.295 2.722 2.829 3.75 5 1.701 1.701 2.803 4.326 4.375 5.625.704 2.871 2.141 2.773 3.125 5-2.948.227-5.311.631-6.875 2.5-2.597 1.344 2.179-1.317 3.125-2.5 2.046-.618 2.576-1.25 5.625-1.25 2.85-.904 4.579.737 7.5 1.25 1.705.987 2.178 1.25 4.376 1.25"
            transform="matrix(-.13978 0 0 .12414 124.863 19.663)"
          />
          <path
            fill="none"
            strokeWidth={1.751}
            d="M438.13 724.86c1.273.075 7.083 1.146 9.376 0 2.552-.696 4.068-2.04 5.625-4.375.767-1.689 0 2.998 0 5 .283 3.68 1.366 3.714 3.125 6.25 1.814 1.163 3.725 2.8 5.625 3.75 1.576 1.366 3.513 2.28 5.625 3.125 1.896.898 4.111 1.813 5.625 3.125 2.09 1.493 1.888 3.278 3.125 5.625-.235 3.057-.733 4.79-2.5 6.25-.738 2.113-2.395 4.205-3.75 5.625-1.545 2.87-3.256 4.224.625 5 2.132.914 3.327.197 5.625 0"
            transform="matrix(-.13978 0 0 .12414 126.96 18.19)"
          />
          <path
            fill="none"
            strokeWidth={1.751}
            d="M480.63 771.73c1.536-.391 6.983-2.213 9.375-3.125h8.75c3.391.261 4.037 1.256 6.876 2.5 1.832 1.871 3.089 3.162 5.625 4.376 1.341 1.733 3.953 4.677 5 6.875.745 2.133 1.25 5.018 1.25 8.125-.044 3.648-1.002 4.271-1.25 7.5-.701 2.684-1.839 5.143-3.75 7.5-.265.793-.457.986-1.25 1.25"
            transform="matrix(-.13978 0 0 .12414 127.396 17.802)"
          />
          <path
            fill="none"
            strokeWidth={2.339}
            d="M538.13 817.98c.374 0 1.201 2.018 2.5 3.75 2.828 2.906 3.192 3.125 7.5 3.125 3.884-.143 3.522-1.532 6.25-2.5 1.289-2.008 2.896-3.6 4.375-6.25 1.006-1.634 1.89-4.129 3.125-5.625 1.141-1.854 2.563-3.254 3.75-5 1.172-.502 1.618-.984 3.126-1.25-3.6.578-3.915 1.867-6.251 3.75-1.094 1.779-2.181 3.633-3.125 5.625-.361 2.673-1.21 4.779-1.25 8.125 0 3.457.017 5.872 1.25 8.75 1.473 1.545 2.563 3.143 4.375 4.376 1.439 1.405 3.244 3.336 4.376 5 1.96 1.206 3.385 2.189 5.625 3.75 1.884 1.102 3.915 2.234 6.875 2.5 3.67-.262 4.755-1.297 7.5-2.5 2.329-1.713 4.163-2.838 6.25-4.375 1.629-1.141 3.288-3.403 4.375-5 3.382-.846 5.529-.508 7.5 1.25a16.536 16.536 0 014.375 4.375c1.02.51 2.73 1.99 3.75 2.5"
            transform="matrix(-.09924 0 0 .09799 109.457 38.807)"
          />
          <path
            fill="none"
            strokeWidth={1.878}
            d="M503.76 836.11c-.802.334-3.717 2.367-5 3.125-.8 2.631-2.077 4.069-2.5 6.875-.677 2.709-.625 5.692-.625 8.75.685 3.194 2.015 5.557 3.125 8.125 1.974 1.342 2.967 2.33 5.625 3.75 2.494.337 4.94.625 8.126.625 1.904.635 5.615.942 7.5 0 2.783-.376 4.854-1.507 6.875-2.5 2.306-1.307 3.728-2.542 6.25-3.75 1.225-1.801 3.13-3.46 5-5.625 1.556-2.105 2.7-3.286 3.75-5.625-.924 2.669-2.327 4.999-3.125 7.5-1.422 2.661-2.248 3.604-2.5 6.875-.871 2.613-.625 5.76-.625 8.75.274 2.031.418 5.837 1.25 7.5v1.875"
            transform="matrix(-.12338 0 0 .12229 111.375 19.248)"
          />
          <path
            fill="none"
            strokeWidth={1.229}
            d="M541.26 799.23v.625c0-1.687.041-.83-.625 2.5-1.179 1.462-3.944 1.868-6.25 1.25-2.022-1.67-1.96-2.935-5.625-3.75-3.542.253-4.892 1.279-6.875 2.5-2.354 1.177-3.438 1.771-5 4.375-.776 2.12-1.209 3.555 1.25 4.375 1.954 1.386 4.277 2.149 6.875 2.5 2.887 0 4.968-.339 6.25 1.25 1.983 2.055 1.875 3.277 1.875 6.875.667 1.112.799 4.394 1.25 5.625 1.736.823 2.755 1.25 5.625 1.25.674-1.931 1.423-5.419 1.875-7.5.489-2.248.64-5.379 1.875-6.875a16.58 16.58 0 014.375-4.375c.766-1.073 2.223-1.965 3.75-2.5-2.452 1.251-2.801 2.603-3.125 5.625 2.155 1.078 2.731 1.833 6.25 1.875 3.459-.291 4.295-1.169 5.625-3.125.242 3.136 1.243 4.186 1.875 6.875 1.286 1.891 1.832 3.828 3.125 6.25-.551 3.012-1.737 3.163-1.875 6.875-.811 2.435-.723 4.783-2.5 6.251-.792.968-1.384 1.158-3.125 1.25 3.618 0 5.727-.391 8.125-1.875 1.854-1.141 3.255-2.563 5.001-3.75 2.212-1.603 4.947-3.085 7.5-3.751 2.887 0 4.968-.339 6.25 1.25 1.77 1.265 2.959 3.447 3.75 6.251.579 3.244.8 5.404-.625 7.5-1.057 2.408-1.297 3.346 0 6.25 1.452 2.033 3.617 2.272 6.875 3.125 2.379-.128 4.668-.671 6.25-1.25"
            transform="matrix(-.19848 0 0 .17744 145.247 -13.446)"
          />
          <g
            fillRule="evenodd"
            strokeWidth={1.311}
            transform="matrix(-.19848 0 0 .17744 145.247 -13.446)"
          >
            <path
              fill="#fff"
              d="M531.5 359.64c0-165.27 7.937-299.41 17.717-299.41 9.779 0 17.716 134.13 17.716 299.41H531.5z"
              transform="matrix(1.4216 -.73423 .46161 .89375 -716.84 541.07)"
            />
            <path
              fill="#fff"
              d="M531.5 359.64c0-165.27 7.937-299.41 17.717-299.41 9.779 0 17.716 134.13 17.716 299.41"
              transform="matrix(1.1373 -.58739 .44532 .86221 -554.83 471.77)"
            />
            <path
              fill="#fff"
              d="M563.39 301.18c.189 18.908 0 40.188 0 60.236h-28.347c0-20.048-.189-41.328 0-60.236h28.347z"
              transform="matrix(1.4216 -.73423 .45889 .88849 -716.67 541.39)"
            />
            <path
              fill="#fff"
              d="M559.84 304.72c.189 18.908 0 33.102 0 53.15h-21.26c0-20.048-.189-34.242 0-53.15h21.26z"
              transform="matrix(1.4216 -.73423 .45889 .88849 -716.67 541.39)"
            />
            <path
              fill="#fff"
              d="M542.13 311.81h14.173v38.976H542.13zm0 0l14.173 38.976m-14.173.004l14.173-38.976M542.13 113.39h14.173"
              transform="matrix(1.4216 -.73423 .45889 .88849 -716.67 541.39)"
            />
            <circle
              cx={545.67}
              cy={92.126}
              fill="#ef0000"
              transform="matrix(1.6046 .45375 -.36215 1.5787 -734.9 -170.85)"
              r={3.543}
            />
          </g>
          <path
            fill="#fff"
            fillRule="evenodd"
            strokeWidth={1.311}
            d="M538.58 531.5c1.717 166.56 24.803 201.97 3.543 201.97s-31.89-92.07-31.89-205.51 14.173-205.51 35.433-205.51-8.913 31.909-7.086 209.06z"
            transform="matrix(.32136 -.12684 .20158 .20221 -181.512 60.764)"
          />
          <path
            fill="#fff"
            fillRule="evenodd"
            strokeWidth={1.229}
            d="M545.67 779.53l-60.236 17.716c56.693 60.236 120.47 85.039 138.19 74.409 17.717-10.629-31.89-35.433-77.953-92.125z"
            transform="matrix(.19848 0 0 .17744 19.131 -14.073)"
          />
          <path
            fill="#fff"
            fillRule="evenodd"
            strokeWidth={1.27}
            d="M547.27 786.9l-50.926 14.74c56.693 60.236 112.77 77.385 127.28 70.015 14.591-7.37-30.287-28.063-76.35-84.755z"
            transform="matrix(.19334 0 0 .17062 21.629 -8.754)"
          />
          <path
            fill="none"
            strokeWidth={1.751}
            d="M353.13 634.23c.187 1.273.748 7.068 1.25 9.375 0 3.186.288 5.632.625 8.125.796 2.177.661 4.198 3.125 5.001 1.694 2.631 2.76 3.313 5 4.375 1.527 1.323 3.84 2.642 6.25 3.75 2.499 1.071 4.771 1.218 7.5.625 2.19-1.526 3.804-3.1 5.625-4.375.429-1.973.822-4.903 1.25-6.875-.674 2.024-1.147 5.265-1.25 8.125.24 3.12 1.265 4.481 2.5 6.875"
            transform="matrix(.13978 0 0 .12414 41.35 19.735)"
          />
          <path
            fill="none"
            strokeWidth={1.751}
            d="M389.38 681.73l.625-.625c-1.484 1.484-.878.776 2.5-1.25 2.426-1.246 5.015-1.908 8.125-2.5h8.75c3.405 0 5.696.511 8.125 1.25 1.762 1.762 4.393 2.763 6.25 4.375 1.905 1.295 2.722 2.829 3.75 5 1.701 1.701 2.803 4.326 4.375 5.625.704 2.871 2.141 2.773 3.125 5-2.948.227-5.311.631-6.875 2.5-2.597 1.344 2.179-1.317 3.125-2.5 2.046-.618 2.576-1.25 5.625-1.25 2.85-.904 4.579.737 7.5 1.25 1.705.987 2.178 1.25 4.376 1.25"
            transform="matrix(.13978 0 0 .12414 39.515 19.036)"
          />
          <path
            fill="none"
            strokeWidth={1.751}
            d="M438.13 724.86c1.273.075 7.083 1.146 9.376 0 2.552-.696 4.068-2.04 5.625-4.375.767-1.689 0 2.998 0 5 .283 3.68 1.366 3.714 3.125 6.25 1.814 1.163 3.725 2.8 5.625 3.75 1.576 1.366 3.513 2.28 5.625 3.125 1.896.898 4.111 1.813 5.625 3.125 2.09 1.493 1.888 3.278 3.125 5.625-.235 3.057-.733 4.79-2.5 6.25-.738 2.113-2.395 4.205-3.75 5.625-1.545 2.87-3.256 4.224.625 5 2.132.914 3.327.197 5.625 0"
            transform="matrix(.13978 0 0 .12414 37.419 17.563)"
          />
          <path
            fill="none"
            strokeWidth={1.751}
            d="M480.63 771.73c1.536-.391 6.983-2.213 9.375-3.125h8.75c3.391.261 4.037 1.256 6.876 2.5 1.832 1.871 3.089 3.162 5.625 4.376 1.341 1.733 3.953 4.677 5 6.875.745 2.133 1.25 5.018 1.25 8.125-.044 3.648-1.002 4.271-1.25 7.5-.701 2.684-1.839 5.143-3.75 7.5-.265.793-.457.986-1.25 1.25"
            transform="matrix(.13978 0 0 .12414 36.982 17.174)"
          />
          <path
            fill="none"
            strokeWidth={2.339}
            d="M538.13 817.98c.374 0 1.201 2.018 2.5 3.75 2.828 2.906 3.192 3.125 7.5 3.125 3.884-.143 3.522-1.532 6.25-2.5 1.289-2.008 2.896-3.6 4.375-6.25 1.006-1.634 1.89-4.129 3.125-5.625 1.141-1.854 2.563-3.254 3.75-5 1.172-.502 1.618-.984 3.126-1.25-3.6.578-3.915 1.867-6.251 3.75-1.094 1.779-2.181 3.633-3.125 5.625-.361 2.673-1.21 4.779-1.25 8.125 0 3.457.017 5.872 1.25 8.75 1.473 1.545 2.563 3.143 4.375 4.376 1.439 1.405 3.244 3.336 4.376 5 1.96 1.206 3.385 2.189 5.625 3.75 1.884 1.102 3.915 2.234 6.875 2.5 3.67-.262 4.755-1.297 7.5-2.5 2.329-1.713 4.163-2.838 6.25-4.375 1.629-1.141 3.288-3.403 4.375-5 3.382-.846 5.529-.508 7.5 1.25a16.536 16.536 0 014.375 4.375c1.02.51 2.73 1.99 3.75 2.5"
            transform="matrix(.09924 0 0 .09799 54.92 38.18)"
          />
          <path
            fill="none"
            strokeWidth={1.878}
            d="M503.76 836.11c-.802.334-3.717 2.367-5 3.125-.8 2.631-2.077 4.069-2.5 6.875-.677 2.709-.625 5.692-.625 8.75.685 3.194 2.015 5.557 3.125 8.125 1.974 1.342 2.967 2.33 5.625 3.75 2.494.337 4.94.625 8.126.625 1.904.635 5.615.942 7.5 0 2.783-.376 4.854-1.507 6.875-2.5 2.306-1.307 3.728-2.542 6.25-3.75 1.225-1.801 3.13-3.46 5-5.625 1.556-2.105 2.7-3.286 3.75-5.625-.924 2.669-2.327 4.999-3.125 7.5-1.422 2.661-2.248 3.604-2.5 6.875-.871 2.613-.625 5.76-.625 8.75.274 2.031.418 5.837 1.25 7.5v1.875"
            transform="matrix(.12338 0 0 .12229 53.004 18.62)"
          />
          <path
            fill="none"
            strokeWidth={1.229}
            d="M541.26 799.23v.625c0-1.687.041-.83-.625 2.5-1.179 1.462-3.944 1.868-6.25 1.25-2.022-1.67-1.96-2.935-5.625-3.75-3.542.253-4.892 1.279-6.875 2.5-2.354 1.177-3.438 1.771-5 4.375-.776 2.12-1.209 3.555 1.25 4.375 1.954 1.386 4.277 2.149 6.875 2.5 2.887 0 4.968-.339 6.25 1.25 1.983 2.055 1.875 3.277 1.875 6.875.667 1.112.799 4.394 1.25 5.625 1.736.823 2.755 1.25 5.625 1.25.674-1.931 1.423-5.419 1.875-7.5.489-2.248.64-5.379 1.875-6.875a16.58 16.58 0 014.375-4.375c.766-1.073 2.223-1.965 3.75-2.5-2.452 1.251-2.801 2.603-3.125 5.625 2.155 1.078 2.731 1.833 6.25 1.875 3.459-.291 4.295-1.169 5.625-3.125.242 3.136 1.243 4.186 1.875 6.875 1.286 1.891 1.832 3.828 3.125 6.25-.551 3.012-1.737 3.163-1.875 6.875-.811 2.435-.723 4.783-2.5 6.251-.792.968-1.384 1.158-3.125 1.25 3.618 0 5.727-.391 8.125-1.875 1.854-1.141 3.255-2.563 5.001-3.75 2.212-1.603 4.947-3.085 7.5-3.751 2.887 0 4.968-.339 6.25 1.25 1.77 1.265 2.959 3.447 3.75 6.251.579 3.244.8 5.404-.625 7.5-1.057 2.408-1.297 3.346 0 6.25 1.452 2.033 3.617 2.272 6.875 3.125 2.379-.128 4.668-.671 6.25-1.25"
            transform="matrix(.19848 0 0 .17744 19.131 -14.073)"
          />
          <g
            fillRule="evenodd"
            strokeWidth={1.311}
            transform="matrix(.19848 0 0 .17744 19.131 -14.073)"
          >
            <path
              fill="#fff"
              d="M531.5 359.64c0-165.27 7.937-299.41 17.717-299.41 9.779 0 17.716 134.13 17.716 299.41H531.5z"
              transform="matrix(1.4216 -.73423 .46161 .89375 -716.84 541.07)"
            />
            <path
              fill="#fff"
              d="M531.5 359.64c0-165.27 7.937-299.41 17.717-299.41 9.779 0 17.716 134.13 17.716 299.41"
              transform="matrix(1.1373 -.58739 .44532 .86221 -554.83 471.77)"
            />
            <path
              fill="#fff"
              d="M563.39 301.18c.189 18.908 0 40.188 0 60.236h-28.347c0-20.048-.189-41.328 0-60.236h28.347z"
              transform="matrix(1.4216 -.73423 .45889 .88849 -716.67 541.39)"
            />
            <path
              fill="#fff"
              d="M559.84 304.72c.189 18.908 0 33.102 0 53.15h-21.26c0-20.048-.189-34.242 0-53.15h21.26z"
              transform="matrix(1.4216 -.73423 .45889 .88849 -716.67 541.39)"
            />
            <path
              fill="#fff"
              d="M542.13 311.81h14.173v38.976H542.13zm0 0l14.173 38.976m-14.173.004l14.173-38.976M542.13 113.39h14.173"
              transform="matrix(1.4216 -.73423 .45889 .88849 -716.67 541.39)"
            />
            <circle
              cx={545.67}
              cy={92.126}
              fill="#ef0000"
              transform="matrix(1.6046 .45375 -.36215 1.5787 -734.9 -170.85)"
              r={3.543}
            />
          </g>
          <g
            fill="#fff"
            fillRule="evenodd"
            transform="matrix(.19848 0 0 .17744 19.131 -14.073)"
          >
            <path
              strokeWidth={1.311}
              d="M305.61 396.85c0 124.02.491 170.76-5.62 177.17-5.79 6.857-167.12 0-167.12 35.433s132.87 70.867 172.74 70.866c53.149 0 79.723-35.433 79.723-106.3v-177.17H305.61z"
              transform="matrix(1.3333 0 0 1 -141.74 0)"
            />
            <path
              strokeWidth={1.311}
              d="M265.75 396.85v17.717c35.433 5.906 70.866 5.906 106.3 0V396.85c-35.433 5.906-70.866 5.906-106.3 0z"
              transform="matrix(1 0 0 .99999 0 35.437)"
            />
            <path
              strokeWidth={1.311}
              d="M265.75 396.85v17.717c35.433 5.906 70.866 5.906 106.3 0V396.85c-35.433 5.906-70.866 5.906-106.3 0z"
              transform="matrix(1 0 0 .99999 0 .004)"
            />
            <path
              strokeWidth={1.311}
              d="M265.75 396.85v17.717c35.433 5.906 70.866 5.906 106.3 0V396.85c-35.433 5.906-70.866 5.906-106.3 0z"
              transform="matrix(1 0 0 .99999 0 17.721)"
            />
            <path
              strokeWidth={1.311}
              d="M265.75 396.85v17.717c35.433 5.906 70.866 5.906 106.3 0V396.85c-35.433 5.906-70.866 5.906-106.3 0z"
              transform="matrix(1 0 0 .99999 0 53.154)"
            />
            <ellipse
              cx={256.89}
              cy={210.83}
              strokeWidth={4.388}
              rx={8.858}
              ry={26.575}
              transform="matrix(.54545 0 0 .14383 130.46 394.32)"
            />
            <ellipse
              cx={292.32}
              cy={246.26}
              strokeWidth={4.388}
              rx={8.858}
              ry={26.575}
              transform="matrix(.54545 0 0 .14383 130.46 391.13)"
            />
            <ellipse
              cx={327.76}
              cy={263.98}
              strokeWidth={4.388}
              rx={8.858}
              ry={26.575}
              transform="matrix(.54545 0 0 .14383 130.46 390.49)"
            />
            <ellipse
              cx={363.19}
              cy={263.98}
              strokeWidth={4.388}
              rx={8.858}
              ry={26.575}
              transform="matrix(.54545 0 0 .14383 130.46 390.49)"
            />
            <ellipse
              cx={398.62}
              cy={246.26}
              strokeWidth={4.388}
              rx={8.858}
              ry={26.575}
              transform="matrix(.54545 0 0 .14383 130.46 391.13)"
            />
            <ellipse
              cx={434.06}
              cy={210.83}
              strokeWidth={4.388}
              rx={8.858}
              ry={26.575}
              transform="matrix(.54545 0 0 .14383 130.46 394.32)"
            />
            <path
              strokeWidth={1.311}
              d="M265.75 485.43l106.3 95.669m-95.67-95.669l95.669 85.039M290.55 488.98l81.496 70.866M301.18 488.98l70.866 60.237M311.81 488.98l60.236 49.607M322.44 488.98l49.606 38.977M333.07 488.98l38.976 28.347M343.7 488.98l28.346 21.26m-17.716-21.26l17.716 14.174m-7.086-14.174l7.086 7.087m-106.296-.007l106.3 95.669m0-106.299l-116.93 106.3m106.3-106.3l-102.76 92.126m88.59-88.576l-81.496 70.866m70.866-70.866l-70.866 60.237m60.236-60.237l-60.236 49.607m49.606-49.607l-49.606 38.977m38.976-38.977l-38.976 28.347M294.1 488.98l-28.346 21.26m17.716-21.26l-17.716 14.174m7.086-14.174l-7.086 7.087m106.296-.007l-116.93 106.3m116.93-95.67l-116.93 106.3m116.93-95.67l-116.93 106.3m116.93-95.67l-116.93 106.3m116.93-95.67l-116.93 106.3m116.93-95.67l-116.93 106.3m116.93-95.67l-116.93 106.3m116.93-95.67l-116.93 106.3m116.93-95.67l-109.84 99.21m109.84-88.58l-99.212 88.583M368.5 605.9l-77.953 70.866m74.413-56.686l-63.779 56.693m56.689-38.983l-31.89 28.346M265.75 506.69l102.76 92.126m-102.763-81.489l102.76 92.126m-102.76-81.496l99.213 88.583m-99.213-77.953l99.213 88.583m-99.207-77.957l95.67 85.039m-99.219-77.949l95.669 85.04m-95.669-74.41l88.583 77.953m-92.124-70.873l88.583 77.953m-92.132-70.863l85.04 74.409m-85.041-63.782l81.496 70.865m-81.496-60.235l70.866 63.779"
            />
            <path
              strokeWidth={1.311}
              d="M265.75 396.85v17.717c35.433 5.906 70.866 5.906 106.3 0V396.85c-35.433 5.906-70.866 5.906-106.3 0z"
              transform="matrix(1 0 0 .99999 0 70.87)"
            />
            <path
              strokeWidth={1.311}
              d="M255.114 612.99l63.78 56.692m-63.78-46.062l56.693 49.606m-56.694-38.976l49.606 42.519m-49.605-31.889l35.434 31.889m-35.434-21.259l28.347 24.804m-28.348-14.174l17.716 14.173"
            />
            <ellipse
              cx={256.89}
              cy={210.83}
              strokeWidth={4.388}
              rx={8.858}
              ry={26.575}
              transform="matrix(.54545 0 0 .14383 130.46 447.47)"
            />
            <ellipse
              cx={292.32}
              cy={246.26}
              strokeWidth={4.388}
              rx={8.858}
              ry={26.575}
              transform="matrix(.54545 0 0 .14383 130.46 444.28)"
            />
            <ellipse
              cx={327.76}
              cy={263.98}
              strokeWidth={4.388}
              rx={8.858}
              ry={26.575}
              transform="matrix(.54545 0 0 .14383 130.46 443.64)"
            />
            <ellipse
              cx={363.19}
              cy={263.98}
              strokeWidth={4.388}
              rx={8.858}
              ry={26.575}
              transform="matrix(.54545 0 0 .14383 130.46 443.64)"
            />
            <ellipse
              cx={398.62}
              cy={246.26}
              strokeWidth={4.388}
              rx={8.858}
              ry={26.575}
              transform="matrix(.54545 0 0 .14383 130.46 444.28)"
            />
            <ellipse
              cx={434.06}
              cy={210.83}
              strokeWidth={4.388}
              rx={8.858}
              ry={26.575}
              transform="matrix(.54545 0 0 .14383 130.46 447.47)"
            />
            <path
              strokeWidth={1.311}
              d="M113.39 651.97l127.55-74.41m-134.637 70.867l120.47-70.87M102.76 641.341l109.84-63.78M99.217 634.254l95.669-56.693M92.127 627.16l88.583-49.606m-85.043 38.983l60.236-35.433m-63.776 28.35l46.063-28.346m-46.066 17.709l31.89-17.717m-.001 74.407l120.47-70.86m-109.836 74.407l113.38-67.32M145.28 662.6l106.3-63.78m-95.67 67.324l88.583-53.15m-74.41 53.142l77.952-46.063m-67.322 49.607l67.323-38.976m-56.7 42.523l53.15-31.89m-38.976 31.896l42.52-24.803m-28.35 28.347l28.347-17.717m-14.164 17.713l17.717-10.63"
            />
            <path
              strokeWidth={1.311}
              d="M265.75 396.85v17.717c35.433 5.906 70.866 5.906 106.3 0V396.85c-35.433 5.906-70.866 5.906-106.3 0z"
              transform="matrix(0 1 -.99999 0 655.51 308.27)"
            />
            <path
              strokeWidth={1.311}
              d="M49.61 623.624l42.52-35.433M49.61 598.8l42.52 42.52"
            />
            <path
              strokeWidth={1.311}
              d="M260.15 387.99l5.595 26.575c35.433 5.906 65.271 5.906 100.7 0l11.19-26.575c-35.433 5.906-82.056 5.906-117.49 0z"
              transform="matrix(0 .63333 -.8 0 423.78 416.34)"
            />
            <path
              strokeWidth={1.311}
              d="M258.66 350.79v-17.865c-10.63.149-17.716-10.481-17.717-21.26l-35.432.149c0 10.63-7.087 21.26-17.717 21.26v17.716h70.866z"
              transform="matrix(1.4983 0 0 1 -15.633 53.149)"
            />
            <path
              strokeWidth={1.356}
              d="M296.09 343.7h45.506v21.26H296.09zm3.79-21.26h37.922v21.26H299.88zm0-21.26h37.922v21.26H299.88zm3.79-28.35h30.337v28.346H303.67z"
            />
            <path
              strokeWidth={1.413}
              d="M302.36 248.03h32.953v24.803H302.36z"
            />
            <ellipse
              cx={237.4}
              cy={161.22}
              strokeWidth={1.311}
              rx={42.52}
              ry={33.661}
              transform="matrix(1.0333 0 0 1 75.354 63.779)"
            />
            <path
              strokeWidth={1.311}
              d="M258.66 159.45c0 9.291 10.63 24.803 10.63 24.803-7.696 6.093-20.154 10.63-31.889 10.63s-26.349-2.528-31.89-10.63c0 0 10.63-15.512 10.63-24.803s-10.63-21.26-10.63-21.26c7.696-6.093 20.154-10.63 31.89-10.63 11.735 0 24.193 4.537 31.889 10.63 0 0-10.63 11.969-10.63 21.26z"
              transform="matrix(1.0333 0 0 1 75.354 63.779)"
            />
            <path
              strokeWidth={1.311}
              d="M251.58 159.45c0 9.291 10.63 28.346 10.63 28.346-7.696 6.093-13.068 7.087-24.803 7.087s-19.262 1.015-24.803-7.087c0 0 10.63-19.055 10.63-28.346s-10.63-24.803-10.63-24.803c7.696-6.093 13.067-7.087 24.803-7.087 11.735 0 17.107.994 24.803 7.087 0 0-10.63 15.512-10.63 24.803z"
              transform="matrix(1.0333 0 0 1 75.354 63.779)"
            />
            <circle
              cx={194.88}
              cy={166.54}
              strokeWidth={1.311}
              transform="matrix(1.0333 0 0 1 75.354 60.236)"
              r={10.63}
            />
            <circle
              cx={194.88}
              cy={166.54}
              strokeWidth={1.311}
              transform="matrix(1.0333 0 0 1 163.23 60.236)"
              r={10.63}
            />
            <circle
              cx={194.88}
              cy={166.54}
              strokeWidth={1.311}
              transform="matrix(1.0333 0 0 1 119.29 60.236)"
              r={10.63}
            />
            <circle
              cx={194.88}
              cy={166.54}
              strokeWidth={1.311}
              transform="matrix(1.0333 0 0 1 119.29 24.803)"
              r={10.63}
            />
            <circle
              cx={194.88}
              cy={166.54}
              strokeWidth={1.311}
              transform="matrix(1.0702 0 0 1 79.931 226.77)"
              r={10.63}
            />
            <circle
              cx={194.88}
              cy={166.54}
              strokeWidth={1.311}
              transform="matrix(1.0702 0 0 1 140.61 226.77)"
              r={10.63}
            />
            <path
              strokeWidth={1.311}
              d="M212.6 311.81h49.607l-24.803 31.89-24.804-31.89z"
              transform="matrix(1.0702 0 0 1 64.762 53.149)"
            />
            <circle
              cx={194.88}
              cy={166.54}
              strokeWidth={1.311}
              transform="matrix(1.427 0 0 1.3333 40.745 167.72)"
              r={10.63}
            />
          </g>
          <g
            fill="#fff"
            fillRule="evenodd"
            transform="matrix(.19848 0 0 .17744 18.78 -19.095)"
          >
            <rect
              width={81.496}
              height={21.26}
              x={262.2}
              y={524.41}
              strokeWidth={1.229}
              rx={4.349}
              ry={3.687}
            />
            <path
              strokeWidth={1.229}
              d="M368.51 506.69c-9.814 0-17.716 8.259-17.716 18.519v16.111c0 10.26 7.902 18.52 17.716 18.52 9.815 0 17.717-8.26 17.717-18.52v-16.111c0-10.26-7.902-18.519-17.717-18.519zm0 7.087c-5.888 0-10.63 6.608-10.63 14.816v9.345c0 8.208 4.742 14.816 10.63 14.816 5.889 0 10.63-6.608 10.63-14.816v-9.345c0-8.208-4.741-14.816-10.63-14.816zm-92.13-3.537c-5.888 0-10.63 6.608-10.63 14.816v16.432c0 8.208 4.742 14.816 10.63 14.816 5.889 0 10.63-6.608 10.63-14.816v-16.432c0-8.208-4.741-14.816-10.63-14.816zm0-7.087c-9.814 0-17.716 8.259-17.716 18.519v23.198c0 10.26 7.902 18.52 17.716 18.52 9.815 0 17.717-8.26 17.717-18.52v-23.198c0-10.26-7.902-18.519-17.717-18.519z"
            />
            <path
              strokeWidth={1.229}
              d="M248.03 517.32c-5.888 0-10.63 6.608-10.63 14.816l-.001 3.864c0 8.208 4.742 14.816 10.63 14.816 5.889 0 10.63-6.608 10.63-14.816l.001-3.864c0-8.208-4.741-14.816-10.63-14.816zm0-7.087c-9.814 0-17.716 8.259-17.716 18.519l-.001 10.63c0 10.26 7.902 18.52 17.716 18.52 9.815 0 17.717-8.26 17.717-18.52l.001-10.63c0-10.26-7.902-18.519-17.717-18.519z"
            />
            <path
              strokeWidth={0.867}
              d="M478.35 237.4c-5.888 0-10.63 6.608-10.63 14.816v16.432c0 8.208 4.742 14.816 10.63 14.816 5.889 0 10.63-6.608 10.63-14.816v-16.432c0-8.208-4.741-14.816-10.63-14.816zm0-7.087c-9.814 0-17.716 8.259-17.716 18.519v23.198c0 10.26 7.902 18.52 17.716 18.52 9.815 0 17.717-8.26 17.717-18.52v-23.198c0-10.26-7.902-18.519-17.717-18.519z"
              transform="matrix(1.8 0 0 1.1176 -655.51 242.2)"
            />
            <path
              strokeWidth={0.867}
              d="M478.35 237.4c-5.888 0-10.63 6.608-10.63 14.816v16.432c0 8.208 4.742 14.816 10.63 14.816 5.889 0 10.63-6.608 10.63-14.816v-16.432c0-8.208-4.741-14.816-10.63-14.816zm0-7.087c-9.814 0-17.716 8.259-17.716 18.519v23.198c0 10.26 7.902 18.52 17.716 18.52 9.815 0 17.717-8.26 17.717-18.52v-23.198c0-10.26-7.902-18.519-17.717-18.519z"
              transform="matrix(1.8 0 0 1.1176 -425.2 245.74)"
            />
            <rect
              width={42.52}
              height={21.26}
              x={375.59}
              y={524.41}
              strokeWidth={1.229}
              rx={2.269}
              ry={3.687}
            />
            <rect
              width={24.803}
              height={28.346}
              x={336.61}
              y={520.87}
              strokeWidth={1.229}
              rx={1.324}
              ry={4.916}
            />
            <rect
              width={24.803}
              height={28.346}
              x={219.69}
              y={520.87}
              strokeWidth={1.229}
              rx={1.324}
              ry={4.916}
            />
            <rect
              width={49.606}
              height={35.433}
              x={141.73}
              y={517.32}
              strokeWidth={1.229}
              rx={2.647}
              ry={6.145}
            />
            <rect
              width={46.063}
              height={35.433}
              x={450}
              y={520.87}
              strokeWidth={1.229}
              rx={2.458}
              ry={6.145}
            />
          </g>
        </g>
      </g>
    </svg>
  );
}

export default SvgOm;
