import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Icon } from "../../index";

export function Expandable(props) {
  const [expanded, setExpanded] = useState(props.expanded);

  const variants = {
    container: {
      className:
        "aui-expandable-fold aui-border-solid aui-border aui-border-grey-2 aui-grid-card-shadow"
    },
    line: {
      className:
        "aui-expandable-fold aui-border-solid aui-border-l-0 aui-border-r-0 aui-border-b aui-border-t-0 aui-border-grey-2"
    }
  };

  const headerClasses = [
    "aui-expandable-header",
    "aui-p-3",
    "aui-cursor-pointer hover:aui-text-primary-1",
    "aui-flex",
    "aui-flex-row",
    "aui-items-center"
  ].join(" ");

  const iconClosed = props.customIconClosed || "angle-right";
  const iconExpanded = props.customIconExpanded || "angle-down";

  return (
    <div className={variants[props.variant].className}>
      <div className={headerClasses} onClick={() => setExpanded(!expanded)}>
        {props.header}
        <Icon
          className="aui-pl-3"
          icon={expanded ? iconExpanded : iconClosed}
        />
      </div>
      {expanded ? (
        <div className="aui-expandable-content aui-p-3 aui-fade-in">
          {props.children}
        </div>
      ) : null}
    </div>
  );
}
Expandable.propTypes = {
  header: PropTypes.any,
  children: PropTypes.any,
  variant: PropTypes.oneOf(["container", "line"]),
  expanded: PropTypes.bool,
  customIconClosed: PropTypes.string,
  customIconExpanded: PropTypes.string
};

Expandable.defaultProps = {
  variant: "container",
  customIconClosed: "",
  customIconExpanded: "",
  expanded: false
};
