import React from "react";
import PropTypes from "prop-types";
import { Spinner, SimpleSpinner } from "../../";

import "./index.css";

function SpinnerOverlay(simple = false, type) {
  const overlay = ({ className, size, type }) => {
    const props = {
      wrapperClassName: "aui-spinner-overlay aui-absolute aui-inset-0 aui-flex",
      size: size,
      className,
      transparent: true,
      type
    };

    return simple ? <SimpleSpinner {...props} /> : <Spinner {...props} />;
  };

  overlay.propTypes = {
    className: PropTypes.string,
    size: PropTypes.oneOf(["small", "medium", "large"]),
    type: PropTypes.string
  };

  return overlay;
}

const AlphaSightsSpinnerOverlay = SpinnerOverlay();
const SimpleSpinnerOverlay = SpinnerOverlay(true);

export { AlphaSightsSpinnerOverlay as SpinnerOverlay, SimpleSpinnerOverlay };
