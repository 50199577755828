import * as React from "react";

function SvgCw(props) {
  return (
    <svg viewBox="0 0 640 480" {...props}>
      <defs>
        <clipPath id="cw_svg__a">
          <path fillOpacity={0.67} d="M0 0h682.67v512H0z" />
        </clipPath>
        <path
          id="cw_svg__b"
          d="M0-1l.225.69H.95L.364.12l.225.69L0 .383-.588.81l.225-.692L-.95-.31h.725z"
        />
      </defs>
      <g clipPath="url(#cw_svg__a)" transform="scale(.94)">
        <path fill="#002b7f" d="M0 0h768v512H0z" />
        <path fill="#f9e814" d="M0 320h768v64H0z" />
        <use
          width={13500}
          height={9000}
          x={2}
          y={2}
          fill="#fff"
          transform="scale(42.67)"
          xlinkHref="#cw_svg__b"
        />
        <use
          width={13500}
          height={9000}
          x={3}
          y={3}
          fill="#fff"
          transform="scale(56.9)"
          xlinkHref="#cw_svg__b"
        />
      </g>
    </svg>
  );
}

export default SvgCw;
