import React, { useState } from "react";
import PropTypes from "prop-types";
import { InlineLoadingMessage } from "../../../";

const ListRowItem = ({ onSelect, className, disabled, tabIndex, children }) => {
  const [animation, setAnimation] = useState("as-animate-list-item");
  const [isLoading, setIsLoading] = useState(false);
  const rowClasses = [
    "aui-bg-white",
    "aui-border-0",
    "aui-border-b",
    "aui-border-b-2",
    "aui-border-solid",
    "aui-border-grey-2",
    "aui-p-4",
    "aui-text-dark-1",
    "hover:aui-bg-light-blue-1",
    "focus:aui-bg-light-blue-1",
    "aui-outline-none",
    className || "",
    disabled ? "aui-pointer-events-none" : "aui-cursor-pointer"
  ].join(" ");

  function handleSelect() {
    const ret = onSelect();
    if (ret && ret.then) {
      setIsLoading(true);
      ret.then(() => setAnimation("aui-fade-out"));
      ret.finally(() => setIsLoading(false));
    } else {
      setAnimation("aui-fade-out");
    }
  }

  function handleKeyDown(evt) {
    if (evt.key === "Enter") {
      handleSelect();
    }
  }

  return (
    <div className="aui-relative">
      <div
        onClick={handleSelect}
        onKeyDown={handleKeyDown}
        className={`${rowClasses} ${animation}`}
        tabIndex={tabIndex}
      >
        {isLoading && <InlineLoadingMessage />} {children}
      </div>
    </div>
  );
};

ListRowItem.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string,
  onSelect: PropTypes.func,
  disabled: PropTypes.bool,
  tabIndex: PropTypes.number
};

export { ListRowItem };
