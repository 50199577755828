import React from "react";
import {
  format,
  startOfMonth,
  subMonths,
  addMonths,
  endOfMonth
} from "date-fns";
import PropTypes from "prop-types";

import { Icon } from "../../..";

const NavButton = ({
  currentMonth,
  handleSetMonth,
  icons,
  minDate,
  maxDate,
  months,
  label
}) => {
  const newMonth = addMonths(currentMonth, months);
  /* Option is disabled if navigating by one month in that direction will set the `currentMonth` to before `minDate` or after `maxDate`.
   * `handleSetMonth` allows navigation to the furthest month it can before exceeding min/max dates. */
  const disabled =
    months < 1
      ? minDate
        ? endOfMonth(subMonths(currentMonth, 1)) < minDate
        : false
      : maxDate
      ? startOfMonth(addMonths(currentMonth, 1)) > maxDate
      : false;
  return (
    <div
      className={`aui-flex aui-items-center aui-py-2 aui-px-2 aui-rounded hover:aui-bg-grey-1 ${
        disabled ? "aui-text-grey-3" : "aui-cursor-pointer"
      }`}
      tabIndex="0"
      onClick={disabled ? () => {} : () => handleSetMonth(newMonth)}
      role="button"
      aria-label={label}
      style={{ fontSize: "0.625rem" }}
    >
      {icons.map((icon, i) => (
        <Icon icon={icon} key={`${label || icon}-${i}`} />
      ))}
    </div>
  );
};

NavButton.propTypes = {
  currentMonth: PropTypes.instanceOf(Date).isRequired,
  handleSetMonth: PropTypes.func.isRequired,
  icons: PropTypes.PropTypes.arrayOf(PropTypes.string).isRequired,
  minDate: PropTypes.instanceOf(Date),
  maxDate: PropTypes.instanceOf(Date),
  months: PropTypes.number.isRequired,
  label: PropTypes.string
};

const CalendarNavigation = ({
  currentMonth,
  handleSetMonth,
  minDate,
  maxDate,
  dateFormatter
}) => {
  const formatter = dateFormatter
    ? dateFormatter
    : (date, dateFormat) => format(date, dateFormat);

  return (
    <div className="aui-flex aui-items-center aui-justify-between aui-p-1">
      <div className="aui-flex aui-items-center">
        <NavButton
          currentMonth={currentMonth}
          handleSetMonth={handleSetMonth}
          icons={["caret-left", "caret-left"]}
          minDate={minDate}
          maxDate={maxDate}
          months={-12}
          label="Previous year"
        />
        <NavButton
          currentMonth={currentMonth}
          handleSetMonth={handleSetMonth}
          icons={["caret-left"]}
          minDate={minDate}
          maxDate={maxDate}
          months={-1}
          label="Previous month"
        />
      </div>
      <div className="aui-px-1 aui-text-base" role="heading">
        {formatter(currentMonth, "MMMM yyyy")}
      </div>
      <div className="aui-flex aui-items-center">
        <NavButton
          currentMonth={currentMonth}
          handleSetMonth={handleSetMonth}
          icons={["caret-right"]}
          minDate={minDate}
          maxDate={maxDate}
          months={1}
          label="Next month"
        />
        <NavButton
          currentMonth={currentMonth}
          handleSetMonth={handleSetMonth}
          icons={["caret-right", "caret-right"]}
          minDate={minDate}
          maxDate={maxDate}
          months={12}
          label="Next year"
        />
      </div>
    </div>
  );
};

CalendarNavigation.propTypes = {
  currentMonth: PropTypes.instanceOf(Date).isRequired,
  handleSetMonth: PropTypes.func.isRequired,
  minDate: PropTypes.instanceOf(Date),
  maxDate: PropTypes.instanceOf(Date),
  dateFormatter: PropTypes.func
};

export default CalendarNavigation;
