import React from "react";
import PropTypes from "prop-types";

import { TextHeaderSix, TextBodyThree, Tooltip } from "../../";

import "./index.pcss";

const ProgressBar = props => {
  const barVisibility =
    props.disabled && "aui-opacity-30 aui-pointer-events-none";
  const className = [
    "aui-block",
    "aui-relative",
    "aui-w-full",
    "aui-cursor-default",
    barVisibility,
    props.className || ""
  ]
    .join(" ")
    .trim();

  const getPercentage = (fullBar = 100, value = 0) => {
    return (100 / fullBar) * value;
  };

  const getTooltip = (message, progress = 0, position = "bottom") =>
    message && (
      <div
        className="aui-absolute aui-left-0 aui-bottom-0 aui--mb-1"
        style={{
          marginLeft: `${progress}%`
        }}
      >
        <Tooltip
          className={`aui-flex aui-w-3 aui-h-4 aui--ml-3 aui-z-10 ${position ===
            "bottom" && "aui-transform aui--translate-x-2"}`}
          position={position}
          message={message}
          narrow
        >
          &nbsp;
        </Tooltip>
      </div>
    );

  const fullBar = getPercentage(props.fullBar, props.fullBar);
  const expected = getPercentage(props.fullBar, props.expected);
  const current = getPercentage(props.fullBar, props.current);
  const marker = getPercentage(props.fullBar, props.marker);
  const separator = getPercentage(props.fullBar, props.separator);

  const currentProgressClassName =
    props.currentProgressClassName || "aui-progress-blue";

  return (
    <div className={className}>
      <div className="aui-progress-text">
        <TextHeaderSix className="aui-inline-block aui-text-grey-5">
          {props.title}
        </TextHeaderSix>
        <TextBodyThree className="aui-inline-block aui-pb-1 aui-text-grey-4">
          {props.subtitle}
        </TextBodyThree>
      </div>
      <div className="aui-progress">
        <div className="aui-progress-bar">
          <div
            className="aui-progress-grey"
            style={{ width: `${expected}%` }}
          ></div>
          <div
            className={currentProgressClassName}
            style={{ width: `${current}%` }}
          ></div>
          <div
            className="aui-progress-marker"
            style={{
              marginLeft: `${marker}%`
            }}
          ></div>
          <div
            className="aui-progress-splitter"
            style={{
              marginLeft: `${separator}%`
            }}
          >
            <i></i>
            <i></i>
          </div>
        </div>

        {props.fullBarTooltip &&
          getTooltip(props.fullBarTooltip, fullBar, props.fullBarTooltipPos)}
        {props.expectedTooltip &&
          getTooltip(props.expectedTooltip, expected, props.expectedTooltipPos)}
        {props.currentTooltip &&
          getTooltip(props.currentTooltip, current, props.currentTooltipPos)}
        {props.markerTooltip &&
          getTooltip(props.markerTooltip, marker, props.markerTooltipPos)}
        {props.separatorTooltip &&
          getTooltip(
            props.separatorTooltip,
            separator,
            props.separatorTooltipPos
          )}
      </div>
    </div>
  );
};

export { ProgressBar };

ProgressBar.propTypes = {
  title: PropTypes.any,
  subtitle: PropTypes.any,
  className: PropTypes.string,
  fullBar: PropTypes.number,
  fullBarTooltip: PropTypes.string,
  fullBarTooltipPos: PropTypes.string,
  expected: PropTypes.number,
  expectedTooltip: PropTypes.string,
  expectedTooltipPos: PropTypes.string,
  current: PropTypes.number,
  currentTooltip: PropTypes.string,
  currentTooltipPos: PropTypes.string,
  marker: PropTypes.number,
  markerTooltip: PropTypes.string,
  markerTooltipPos: PropTypes.string,
  separator: PropTypes.number,
  separatorTooltip: PropTypes.string,
  separatorTooltipPos: PropTypes.string,
  disabled: PropTypes.bool,
  currentProgressClassName: PropTypes.string
};
