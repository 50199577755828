import * as React from "react";

function SvgGl(props) {
  return (
    <svg viewBox="0 0 640 480" {...props}>
      <path fill="#fff" d="M0 0h640v480H0z" />
      <path
        fill="#d00c33"
        d="M0 240h640v240H0zm80 0c.014 88.356 71.644 159.975 160 159.975S399.986 328.355 400 240c-.014-88.356-71.644-159.975-160-159.975S80.014 151.645 80 240"
      />
    </svg>
  );
}

export default SvgGl;
