import React from "react";
import PropTypes from "prop-types";

const LinkableDropdownRowItem = props => {
  const noop = () => {};
  const onClick = props.onClick || noop;

  const linkClasses = [
    "aui-dropdown-item",
    "aui-no-underline",
    "aui-text-grey-5",
    "aui-font-semibold",
    "aui-text-body",
    props.className
  ].join(" ");

  let itemClasses = "hover:aui-bg-light-blue-1";

  if (props.variant === "light") {
    itemClasses = `aui-font-normal aui-border aui-border-grey-2 aui-border-l-0 aui-border-b-0 aui-border-r-0 aui-border-solid hover:aui-text-primary-2 ${props.idx ===
      0 && "aui-border-t-0"}`;
  }

  if (props.variant === "rounded") {
    itemClasses = "hover:aui-bg-grey-1";
  }

  const selectedClass = props.selected ? "aui-text-primary-2" : "";

  return (
    <a className={linkClasses} onClick={onClick} href={props.href}>
      <React.Fragment>
        <div
          className={`aui-p-3 aui-py-4 aui-select-none ${itemClasses} ${selectedClass}`}
        >
          {props.children}
        </div>
      </React.Fragment>
    </a>
  );
};

LinkableDropdownRowItem.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string,
  idx: PropTypes.number,
  variant: PropTypes.string,
  selected: PropTypes.bool,
  onClick: PropTypes.func,
  href: PropTypes.string
};

export { LinkableDropdownRowItem };
