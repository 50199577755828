import React, { useEffect, useState, useRef } from "react";
import {
  Icon,
  ModalTextHeader,
  PrimaryButton,
  TernaryButton,
  ButtonGroupLayout,
  WarningAlert,
  ErrorAlert
} from "../../";
import PropTypes from "prop-types";
import { useShortcut, useOutsideClick } from "../../../hooks";
import "./index.pcss";

const Modal = ({
  onClose,
  onConfirm,
  showIcon,
  closeOnOutsideClick,
  title,
  subtitle,
  children,
  confirmLabel,
  cancelLabel,
  className,
  hideHeader,
  alert,
  modalHeaderClassName
}) => {
  const [animation, setAnimation] = useState("aui-zoom-in");
  const [zoomOut, setZoomOut] = useState(false);
  const ref = useRef();

  if (closeOnOutsideClick === undefined) {
    closeOnOutsideClick = !onConfirm;
  }

  if (closeOnOutsideClick) useOutsideClick(ref, handleClose);

  // Prevent window scroll whilst the modal is open
  document.body.style.overflow = "hidden";

  useEffect(() => {
    return () => (document.body.style.overflow = "");
  }, []);

  useEffect(() => {
    if (zoomOut) {
      setAnimation("aui-zoom-out");
    } else {
      setAnimation("aui-zoom-in");
    }
  }, [zoomOut]);

  useShortcut(
    {
      27: handleEscapePress
    },
    true
  );

  function handleClose() {
    setZoomOut(true);
    setTimeout(onClose, 300);
  }

  function handleEscapePress(evt) {
    evt.stopPropagation();
    handleClose();
  }

  const handleConfirm = () => {
    if (onConfirm) {
      const ret = onConfirm();
      if (ret && ret.then) {
        ret.then(handleClose).catch(() => {});
      } else {
        handleClose();
      }
      return ret;
    }
  };

  const modalContainerClasses = [
    "aui-modal-container",
    "aui-z-50",
    "aui-min-h-full",
    "aui-py-8",
    "aui-border-box",
    "aui-flex",
    "aui-flex-col",
    "aui-justify-around",
    animation
  ].join(" ");

  const modalContentClasses = [
    "aui-modal-content",
    "aui-shadow-huge",
    "aui-bg-white",
    "aui-rounded",
    "aui-mx-auto",
    alert ? "as-modal-alert" : "",
    className
  ].join(" ");

  const Wrapper =
    alert === "warning"
      ? WarningAlert
      : alert === "error"
      ? ErrorAlert
      : React.Fragment;

  const content = alert ? (
    <Wrapper containerClassName="aui-w-full">
      <div className="aui-w-full aui-flex aui-items-start aui-flex-col">
        <div className="aui-mt-0 aui-text-left">{children}</div>
        {(onClose || onConfirm) && (
          <div className="aui-mt-6">
            {onConfirm && (
              <PrimaryButton onClick={onConfirm}>
                {confirmLabel ? confirmLabel : "Confirm"}
              </PrimaryButton>
            )}
            {onClose && (
              <TernaryButton onClick={onClose}>
                {cancelLabel ? cancelLabel : "Cancel"}
              </TernaryButton>
            )}
          </div>
        )}
      </div>
    </Wrapper>
  ) : (
    children
  );

  return (
    <div className="aui-modal aui-fixed aui-w-full aui-h-full aui-top-0 aui-left-0 aui-items-center aui-justify-center aui-z-max">
      <div className="aui-modal-overlay aui-fixed aui-w-full aui-h-full aui-top-0 aui-left-0 aui-bg-grey-4 aui-opacity-50" />
      <div className="aui-h-full aui-overflow-auto aui-relative">
        <div className={modalContainerClasses}>
          <div className={modalContentClasses} ref={ref}>
            {!hideHeader && !alert && (
              <ModalHeader
                showIcon={showIcon}
                title={title}
                subtitle={subtitle}
                confirmLabel={confirmLabel}
                cancelLabel={cancelLabel}
                onClose={onClose}
                onConfirm={onConfirm ? handleConfirm : null}
                hideHeader={hideHeader}
                modalTextClassName={modalHeaderClassName}
              />
            )}
            {content}
          </div>
        </div>
      </div>
    </div>
  );
};

const ModalHeader = ({
  onClose,
  onConfirm,
  showIcon,
  title,
  subtitle,
  cancelLabel = "Cancel",
  confirmLabel = "Confirm",
  className,
  modalTextClassName,
  hideControls,
  noBackgroundColor
}) => {
  const headerBackgroundClassNames = [
    "aui-bg-light-blue-1",
    "aui-border-b",
    "aui-border-b-2",
    "aui-border-solid",
    "aui-border-grey-2"
  ];
  let classNames = [
    "aui-flex",
    "aui-items-center",
    subtitle ? "" : "aui-py-4",
    "aui-p-2",
    "aui-text-left",
    "aui-px-6",
    "aui-border-0",
    "aui-m-h-1",
    "aui-clearfix",
    className || ""
  ];

  if (!noBackgroundColor) {
    classNames = classNames.concat(headerBackgroundClassNames);
  }
  classNames = classNames.join(" ").trim();

  const modalTextHeaderClassNames = [
    "aui-inline-block",
    "aui-my-2",
    "aui-grey-5",
    "aui-uppercase",
    "aui-text-grey-5",
    modalTextClassName || ""
  ]
    .join(" ")
    .trim();

  return (
    <div className={classNames}>
      <ModalTextHeader className={modalTextHeaderClassNames}>
        {title}
        {subtitle && (
          <div className="aui-text-grey-3 aui-normal-case aui-semibold">
            {subtitle}
          </div>
        )}
      </ModalTextHeader>
      {!hideControls && (
        <div className="aui-inline-block aui-ml-auto">
          {(!showIcon || onConfirm) && (
            <ButtonGroupLayout>
              {!showIcon && (
                <TernaryButton onClick={onClose}>{cancelLabel}</TernaryButton>
              )}
              {onConfirm && (
                <PrimaryButton onClick={onConfirm}>
                  {confirmLabel}
                </PrimaryButton>
              )}
            </ButtonGroupLayout>
          )}
          {showIcon && (
            <Icon
              icon="close"
              onClick={onClose}
              className={`aui-my-4 aui-ml-4 aui-float-right aui-w-5 aui-text-grey-5 aui-text-sm aui-cursor-pointer hover:aui-text-primary-2`}
            />
          )}
        </div>
      )}
    </div>
  );
};

Modal.propTypes = {
  onClose: PropTypes.func,
  onConfirm: PropTypes.func,
  showIcon: PropTypes.bool,
  closeOnOutsideClick: PropTypes.bool,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  children: PropTypes.any,
  confirmLabel: PropTypes.string,
  cancelLabel: PropTypes.string,
  className: PropTypes.string,
  hideHeader: PropTypes.bool,
  alert: PropTypes.string,
  modalHeaderClassName: PropTypes.string
};

ModalHeader.propTypes = {
  onClose: PropTypes.func,
  onConfirm: PropTypes.func,
  confirmLabel: PropTypes.string,
  cancelLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  showIcon: PropTypes.bool,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  subtitle: PropTypes.string,
  className: PropTypes.string,
  modalTextClassName: PropTypes.string,
  hideControls: PropTypes.bool,
  noBackgroundColor: PropTypes.bool
};

export { Modal, ModalHeader };
