import * as React from "react";

function SvgLv(props) {
  return (
    <svg viewBox="0 0 640 480" {...props}>
      <g fillRule="evenodd">
        <path fill="#fff" d="M0 0h640v480.003H0z" />
        <path fill="#981e32" d="M0 0h640v192.001H0zm0 288.002h640v192.001H0z" />
      </g>
    </svg>
  );
}

export default SvgLv;
