import React from "react";
import PropTypes from "prop-types";
import * as flags from "./flags";

const toKebabCase = string =>
  string.replace(/([A-Z0-9]|(?=[a-z]))([A-Z])/g, "$1-$2").toLowerCase();

const flagsDictionary = Object.entries(flags).reduce(
  (acc, [flag, klass]) => ({
    ...acc,
    [toKebabCase(flag)]: klass
  }),
  {}
);

export default function Flag({ flag, ...props }) {
  const klass = flagsDictionary[flag];
  if (klass) {
    return React.createElement(klass, props);
  } else {
    return <span>Flag Not Found</span>;
  }
}

Flag.propTypes = {
  flag: PropTypes.any,
  className: PropTypes.string
};

export const getFlags = () => Object.keys(flagsDictionary);
