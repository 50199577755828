import * as React from "react";

function SvgPh(props) {
  return (
    <svg viewBox="0 0 640 480" {...props}>
      <path fill="#0038a8" d="M0 0h640v240H0z" />
      <path fill="#ce1126" d="M0 240h640v240H0z" />
      <path fill="#fff" d="M415.692 240L0 480V0" />
      <g fill="#fcd116" transform="translate(149.333 240) scale(5.33333)">
        <circle r={9} />
        <g id="ph_svg__d">
          <g id="ph_svg__c">
            <g id="ph_svg__b">
              <path d="M-1 0l.062.062L0 0l-.938-.062z" transform="scale(19)" />
              <path
                id="ph_svg__a"
                d="M-.884.116l.05.05L0 0z"
                transform="scale(19.2381)"
              />
              <use
                width="100%"
                height="100%"
                transform="scale(1 -1)"
                xlinkHref="#ph_svg__a"
              />
            </g>
            <use
              width="100%"
              height="100%"
              transform="rotate(45)"
              xlinkHref="#ph_svg__b"
            />
          </g>
          <use
            width="100%"
            height="100%"
            transform="rotate(90)"
            xlinkHref="#ph_svg__c"
          />
        </g>
        <use
          width="100%"
          height="100%"
          transform="scale(-1)"
          xlinkHref="#ph_svg__d"
        />
        <g transform="translate(-2.02)">
          <g id="ph_svg__f" transform="translate(37.962)">
            <path
              id="ph_svg__e"
              d="M5 0L1.618 1.176l-.073 3.58-2.163-2.854-3.427 1.037L-2 0z"
            />
            <use
              width="100%"
              height="100%"
              transform="scale(1 -1)"
              xlinkHref="#ph_svg__e"
            />
          </g>
          <use
            width="100%"
            height="100%"
            transform="rotate(120)"
            xlinkHref="#ph_svg__f"
          />
          <use
            width="100%"
            height="100%"
            transform="rotate(-120)"
            xlinkHref="#ph_svg__f"
          />
        </g>
      </g>
    </svg>
  );
}

export default SvgPh;
