// Based on: https://stackoverflow.com/a/44750757

let resizeSensorNode;
const initResizeNode = function() {
  const fillParent =
    "display: block; position: absolute; left: 0; top: 0; right: 0; bottom: 0; overflow: hidden; z-index: -1; visibility: hidden;";
  const triggerStyle = "position: absolute; left: 0; top: 0; transition: 0s;";

  const resizeSensor = (resizeSensorNode = document.createElement(
    "resizeSensor"
  ));
  resizeSensor.style = fillParent;

  const expandSensor = document.createElement("div");
  expandSensor.style = fillParent;
  resizeSensor.appendChild(expandSensor);

  const trigger = document.createElement("div");
  trigger.style = triggerStyle;
  expandSensor.appendChild(trigger);

  const shrinkSensor = expandSensor.cloneNode(true);
  shrinkSensor.firstChild.style = triggerStyle + " width: 200%; height: 200%";
  resizeSensor.appendChild(shrinkSensor);
};

const removeOnSizeChange = function(domNode, callback) {
  if (domNode.resizeListeners) {
    domNode.resizeListeners = domNode.resizeListeners.filter(
      fn => fn !== callback
    );
  }
};

const onSizeChange = function(domNode, fn) {
  if (!domNode) return;
  if (domNode.resizeListeners) {
    domNode.resizeListeners.push(fn);
    return;
  }

  const position = window.getComputedStyle(domNode).position;
  if (position === "static") {
    domNode.style.position = "relative";
  }

  domNode.resizeListeners = [];
  domNode.resizeListeners.push(fn);

  if (!resizeSensorNode) initResizeNode();

  domNode.resizeSensor = resizeSensorNode.cloneNode(true);
  domNode.appendChild(domNode.resizeSensor);

  const expand = domNode.resizeSensor.firstChild;
  const expandTrigger = expand.firstChild;
  const shrink = domNode.resizeSensor.childNodes[1];

  const reset = function() {
    expandTrigger.style.width = "100000px";
    expandTrigger.style.height = "100000px";

    expand.scrollLeft = 100000;
    expand.scrollTop = 100000;

    shrink.scrollLeft = 100000;
    shrink.scrollTop = 100000;
  };

  reset();

  let hasChanged, frameRequest, newWidth, newHeight;
  let lastWidth = domNode.offsetWidth;
  let lastHeight = domNode.offsetHeight;

  const onResized = function() {
    frameRequest = undefined;

    if (!hasChanged) return;

    lastWidth = newWidth;
    lastHeight = newHeight;

    domNode.resizeListeners.forEach(listener => listener());
  };

  const onScroll = function() {
    newWidth = domNode.offsetWidth;
    newHeight = domNode.offsetHeight;
    hasChanged = newWidth !== lastWidth || newHeight !== lastHeight;

    if (hasChanged && !frameRequest) {
      frameRequest = requestAnimationFrame(onResized);
    }

    reset();
  };

  expand.addEventListener("scroll", onScroll);
  shrink.addEventListener("scroll", onScroll);
};

export default {
  onResize(element, callback) {
    onSizeChange(element, callback);
    return () => removeOnSizeChange(element, callback);
  }
};
