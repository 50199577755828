import * as React from "react";

function SvgCz(props) {
  return (
    <svg viewBox="0 0 640 480" {...props}>
      <defs>
        <clipPath id="cz_svg__a">
          <path fillOpacity={0.67} d="M-74 0h682.67v512H-74z" />
        </clipPath>
      </defs>
      <g
        fillRule="evenodd"
        strokeWidth="1pt"
        clipPath="url(#cz_svg__a)"
        transform="translate(69.38) scale(.94)"
      >
        <path fill="#e80000" d="M-74 0h768v512H-74z" />
        <path fill="#fff" d="M-74 0h768v256H-74z" />
        <path fill="#00006f" d="M-74 0l382.73 255.67L-74 511.01V0z" />
      </g>
    </svg>
  );
}

export default SvgCz;
