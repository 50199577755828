import { formatDistanceStrict, format } from "date-fns";

/* eslint-disable */
function standardizeDateFormat(dateFormat) {
  const INCORRECT_DAY_PATTERN = "D";
  const INCORRECT_YEAR_PATTERN = "Y";
  const AM_PM_PATTERN = "a";

  let standardizedFormat = dateFormat;

  // Removing date field patterns to comply with Unicode Data Markup Language
  // for Dates

  // ref https://www.unicode.org/reports/tr35/tr35-dates.html#Date_Field_Symbol_Table
  if (dateFormat.includes(INCORRECT_DAY_PATTERN)) {
    // D incorrect format for day of month
    standardizedFormat = standardizedFormat.replace(
      new RegExp(INCORRECT_DAY_PATTERN, "g"),
      "d"
    );
    console.warn(
      `Invalid date pattern provided: Replacing ${INCORRECT_DAY_PATTERN} with 'd'`
    );
  }
  if (dateFormat.includes(INCORRECT_YEAR_PATTERN)) {
    // Y Incorrect format for calendar year
    standardizedFormat = standardizedFormat.replace(
      new RegExp(INCORRECT_YEAR_PATTERN, "g"),
      "y"
    );
    console.warn(
      `Invalid date pattern provided: Replacing ${INCORRECT_YEAR_PATTERN} with 'y'`
    );
  }
  if (dateFormat.includes(AM_PM_PATTERN)) {
    // no am , pm support in date-fns, using a,p pattern and addign the m after
    standardizedFormat = standardizedFormat.replace(AM_PM_PATTERN, "aaaaa'm'");
  }

  return standardizedFormat;
}
/* eslint-enable */

function pad(n, width) {
  const c = "0";
  n = n + "";
  return n.length >= width ? n : new Array(width - n.length + 1).join(c) + n;
}

function formatCurrency(value, minimumFractionDigits = 2, currency = "USD") {
  return new Intl.NumberFormat(navigator.language || "en-US", {
    style: "currency",
    currency,
    minimumFractionDigits
  }).format(value);
}

function formatNumber(value, minimumFractionDigits = 2) {
  return new Intl.NumberFormat(navigator.language || "en-US", {
    minimumFractionDigits
  }).format(value);
}

function formatDate(
  date,
  dateFormat = "MMM dd, yyyy",
  timeZone = "Etc/Universal"
) {
  if (!date) return "-";
  const dateObj = new Date(date);
  return format(
    new Date(dateObj.toLocaleString("en-US", { timeZone })),
    standardizeDateFormat(dateFormat)
  );
}

function formatDateRelative(date, invalidPlaceholder = "-") {
  const dateObj = new Date(date);
  return date
    ? formatDistanceStrict(dateObj, new Date(), { addSuffix: true })
    : invalidPlaceholder;
}

function formattedStrToNumber(formattedStr) {
  if (typeof formattedStr === "string" || formattedStr instanceof String) {
    return Number(
      formattedStr
        .replace(/,/g, "")
        .replace(/\$/g, "")
        .trim()
    );
  }
  return formattedStr;
}

function capitalise(string) {
  return string ? string[0].toUpperCase() + string.substr(1).toLowerCase() : "";
}

function capitaliseCountryName(string) {
  const isAbbreviation = string.length <= 3;
  return isAbbreviation
    ? string
    : string
        .split(" ")
        .map(capitalise)
        .join(" ");
}

export {
  pad,
  formattedStrToNumber,
  formatNumber,
  formatCurrency,
  formatDate,
  formatDateRelative,
  capitalise,
  capitaliseCountryName
};
