import React, { useRef, useState, useEffect } from "react";
import PropTypes from "prop-types";

import { DropdownRowItem } from "../";
import { Icon, InlineLoadingMessage } from "../../../";
import { FieldError } from "../FieldError";
import { useDebounce, useOutsideClick } from "../../../../hooks";

const SelectFilter = props => {
  const getValue =
    props.getValue ||
    function(item) {
      return item;
    };

  function renderListItem(item, key) {
    return (
      <DropdownRowItem
        key={key}
        onClick={() => handleSelect(item)}
        disabled={isItemDisabled(item)}
      >
        {renderItem(item)}
      </DropdownRowItem>
    );
  }

  function renderItem(item) {
    return props.renderListItem ? props.renderListItem(item) : getValue(item);
  }

  const [isDropdownVisible, setDropdownVisible] = useState(false);
  const [selected, setSelected] = useState(props.selected);
  const [currentValue, setCurrentValue] = useState("");
  const [dataLoadingPromise, setDataLoadingPromise] = useState(
    getValue(props.selected)
  );
  const [items, setItems] = useState([]);
  const ref = useRef();
  const inputRef = useRef();
  const debouncedCurrentValue = useDebounce(currentValue, 500);
  const [isLoading, setIsLoading] = useState(null);
  useOutsideClick(ref, () => setDropdownVisible(false));

  useEffect(() => setSelected(props.selected), [props.selected]);

  useEffect(() => {
    if (isDropdownVisible && inputRef.current) {
      inputRef.current.focus();
    }
  }, [isDropdownVisible]);

  useEffect(() => {
    if (debouncedCurrentValue) {
      setIsLoading(true);
      const promise =
        props.loadOptions && props.loadOptions(debouncedCurrentValue);
      setDataLoadingPromise(promise);
    } else {
      setItems([]);
    }
  }, [debouncedCurrentValue]);

  useEffect(() => {
    if (dataLoadingPromise && dataLoadingPromise.then) {
      dataLoadingPromise.then(setItems);
      dataLoadingPromise.finally(() => setIsLoading(false));
    }
  }, [dataLoadingPromise]);

  let borderStyles = [
    "aui-border",
    props.error && !isDropdownVisible
      ? "aui-border-error-2"
      : "aui-border-grey-2",
    "aui-border-solid"
  ];

  const disabledClasses = [
    "aui-pointer-events-none",
    "aui-border-grey-2",
    "aui-bg-grey-1"
  ];
  const activeClasses = ["aui-cursor-pointer"];

  const stateStyles = props.disabled ? disabledClasses : activeClasses;

  const classes = [
    "aui-rounded",
    "focus:aui-outline-none",
    "aui-appearance-none",
    "aui-bg-white",
    "focus:aui-bg-white",
    "aui-text-left",
    "aui-text-dark-1",
    "aui-p-3",
    "aui-align-middle",
    "aui-w-full",
    "aui-border-box",
    "as-search-input",
    props.className || "",
    ...borderStyles,
    ...stateStyles
  ].join(" ");

  function handleInputClick(e) {
    if (props.onClick) props.onClick(e);
    setDropdownVisible(!isDropdownVisible);
  }

  function handleSelect(item) {
    if (props.onSelect) props.onSelect(item);
    setSelected(item);
    setDropdownVisible(false);
  }

  function handleCreate() {
    const newItem = props.onCreate(currentValue);
    handleSelect(newItem);
  }

  function handleEnterPress(evt) {
    if (evt.key === "Enter" && props.onCreate) {
      handleCreate();
    }
  }

  function isItemDisabled(item) {
    if (props.isItemDisabled) {
      return props.isItemDisabled(item);
    }
    return false;
  }

  return (
    <React.Fragment>
      <div ref={ref}>
        {!isDropdownVisible && (
          <div
            onClick={() => setDropdownVisible(!isDropdownVisible)}
            className={classes}
          >
            {selected
              ? renderItem(selected)
              : props.placeholder || "Type to search..."}
          </div>
        )}
        {isDropdownVisible && (
          <React.Fragment>
            <input
              placeholder={props.placeholder || "Type to search..."}
              type="text"
              value={isDropdownVisible ? currentValue : getValue(selected)}
              onClick={handleInputClick}
              className={classes}
              ref={inputRef}
              onKeyPress={handleEnterPress}
              onChange={e => setCurrentValue(e.target.value)}
            />
            <div className="aui-relative">
              <div className="aui-dropdown-content aui-bg-white aui-z-50 aui-shadow aui-absolute aui-w-full aui-overflow-y-scroll aui-max-h-64">
                {isLoading && (
                  <DropdownRowItem disabled={true}>
                    <InlineLoadingMessage>Searching...</InlineLoadingMessage>
                  </DropdownRowItem>
                )}
                {!isLoading &&
                  items.map((item, idx) => {
                    return item !== selected && renderListItem(item, idx);
                  })}
                {isLoading === false &&
                  (!items || items.length === 0) &&
                  !!currentValue && (
                    <DropdownRowItem disabled={true}>
                      Sorry, nothing found.
                    </DropdownRowItem>
                  )}
                {isLoading === false &&
                  props.onCreate &&
                  currentValue &&
                  !!currentValue.trim() && (
                    <DropdownRowItem onClick={handleCreate}>
                      <a className="aui-text-primary-1">
                        &nbsp; Create new {currentValue}?
                      </a>
                    </DropdownRowItem>
                  )}
              </div>
            </div>
          </React.Fragment>
        )}
      </div>
      {props.error && !isDropdownVisible && (
        <FieldError>{props.error}</FieldError>
      )}
    </React.Fragment>
  );
};

SelectFilter.propTypes = {
  selected: PropTypes.any,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  getValue: PropTypes.func,
  className: PropTypes.string,
  loadOptions: PropTypes.func,
  onSelect: PropTypes.func,
  onClick: PropTypes.func,
  onCreate: PropTypes.func,
  error: PropTypes.string,
  isItemDisabled: PropTypes.func,
  renderListItem: PropTypes.func
};

export { SelectFilter };
