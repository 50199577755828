import * as React from "react";

function SvgUg(props) {
  return (
    <svg viewBox="0 0 640 480" {...props}>
      <defs>
        <clipPath id="ug_svg__a">
          <path fillOpacity={0.67} d="M-85.333 0h682.67v512h-682.67z" />
        </clipPath>
      </defs>
      <g clipPath="url(#ug_svg__a)" transform="translate(80) scale(.9375)">
        <path
          fill="#ffe700"
          fillRule="evenodd"
          d="M-128 341.36h768v85.321h-768z"
        />
        <path fillRule="evenodd" d="M-128 256h768v85.321h-768z" />
        <path
          fill="#de3908"
          fillRule="evenodd"
          d="M-128 170.68h768v85.321h-768z"
        />
        <path
          fill="#ffe700"
          fillRule="evenodd"
          d="M-128 85.358h768v85.321h-768z"
        />
        <path fillRule="evenodd" d="M-128 0h768v85.321h-768z" />
        <path
          fill="#fffdff"
          fillRule="evenodd"
          stroke="#000"
          strokeWidth={0.986}
          d="M335.71 255.997c0 44.023-35.688 79.71-79.71 79.71s-79.71-35.687-79.71-79.71 35.687-79.71 79.71-79.71 79.71 35.687 79.71 79.71z"
        />
        <path
          fill="#de3108"
          fillRule="evenodd"
          stroke="#000"
          strokeWidth={0.986}
          d="M241.936 194.89l-5.175-9.531c1.997-1.997 5.356-3.54 10.712-3.54 0 .363-.545 10.44-.545 10.44l-4.992 2.632z"
        />
        <path
          fill="#ffe700"
          fillRule="evenodd"
          stroke="#000"
          strokeWidth={0.986}
          d="M246.926 192.354l.727-10.53s10.712-.636 16.522 6.354c.09-.09-5.72 8.17-5.72 8.17l-11.529-3.994z"
        />
        <path
          fill="#de3108"
          fillRule="evenodd"
          stroke="#000"
          strokeWidth={0.986}
          d="M258.64 196.256l5.265-8.17c3.54 3.723 4.993 6.355 5.538 10.35.09.09-8.352 2.087-8.352 1.996s-2.36-4.085-2.45-4.176z"
        />
        <path
          fillRule="evenodd"
          stroke="#000"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={0.924}
          d="M244.57 331.153s9.895-11.348 29.14-8.896c-2.905-4.72-12.255-4.176-12.255-4.176s-2.905-22.06-.636-23.15 11.892.092 11.892.092c1.27 0 3.45-3.45 1.726-5.629-1.726-2.179-6.809-10.53-4.721-12.165 2.088-1.634 13.435.908 13.435.908l-32.045-41.032s-3.268-15.433 3.268-22.877c7.898-6.536 7.081-13.617 6.809-13.527-1.09-7.171-11.983-12.346-19.337-5.719-4.357 5.265-1.452 9.26-1.452 9.26s-11.439 3.086-11.893 5.083c-.454 1.998 12.891-.362 12.891-.362l-1.27 9.169s-25.964 23.602-6.083 44.028c.182-.091.636-.908.636-.908s6.99 8.624 14.342 10.53c6.9 7.082 6.265 5.992 6.265 5.992s1.361 11.166.09 13.345c-1.724-.545-19.335-1.18-21.969-.182-2.36.727-11.438.273-9.168 15.07 1.724-3.994 3.268-7.535 3.268-7.535s-.273 5.356 1.906 7.263c-.363-5.63 2.088-9.441 2.088-9.441s.454 6.173 1.816 7.08c1.362.908 1.362-9.986 8.897-9.078 7.534.908 12.981.636 12.981.636s2.542 21.333 1.725 23.33c-5.448-1.27-18.429.545-19.246 3.813 7.625-.454 11.167.454 11.167.454s-6.173 5.447-4.267 8.624z"
        />
        <path
          fill="#9ca69c"
          fillRule="evenodd"
          stroke="#9ca69c"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={0.924}
          d="M247.626 214.749s-18.892 20.823-10.741 36.757c.434-2.222.245-3.618.517-3.527-.454-.272 2.343 1.917 2.13 1.491.06-1.152-.852-3.62-.852-3.62l2.556.638-1.491-2.769 3.621.426s-1.278-3.408-.851-3.408c.425 0 2.981.212 2.981.212-5.372-9.641-.304-17.648 2.13-26.2z"
        />
        <path
          fill="#9ca69c"
          fillRule="evenodd"
          stroke="#9ca69c"
          strokeWidth={0.986}
          d="M254.19 196.887s1 7.172-2.905 9.26c-.635.454-3.086 1.27-2.723 2.724.454 1.997 1.543 1.633 3.087 1.27 4.084-.726 8.805-9.441 2.541-13.254z"
        />
        <path
          fill="#fff"
          fillRule="evenodd"
          d="M247.204 203.063a1.543 1.543 0 11-3.087 0 1.543 1.543 0 013.087 0z"
        />
        <path
          fill="#de3108"
          fillRule="evenodd"
          stroke="#000"
          strokeWidth={0.986}
          d="M241.118 209.052c-.999.817-6.264 6.264-1.09 8.26 5.357-1.452 3.904-2.45 5.084-3.63.03-2.451-2.663-3.087-3.994-4.63z"
        />
        <path
          fill="#9ca69c"
          fillRule="evenodd"
          stroke="#9ca69c"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={0.924}
          d="M252.554 260.53c-.272 1.18-1.452 5.538.182 8.897 4.54-1.907 6.627-1.362 8.17-.364-3.72-2.995-5.174-4.267-8.352-8.533z"
        />
        <path
          fill="#fff"
          fillRule="evenodd"
          stroke="#fff"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={0.924}
          d="M260.366 281.137l.272 10.168s3.541.635 5.175 0c1.634-.636-.091-7.081-5.447-10.168z"
        />
        <path
          fill="#9ca69c"
          fillRule="evenodd"
          stroke="#000"
          strokeWidth={0.986}
          d="M286.053 282.405s-6.536-15.795-23.24-19.79-14.525-21.787-13.163-22.877c.727-1.543 1.271-3.903 6.082-1.633 4.812 2.27 26.963 13.435 30.14 13.98s.454 30.684.181 30.32z"
        />
        <path
          fill="#de3108"
          fillRule="evenodd"
          stroke="#000"
          strokeLinejoin="round"
          strokeWidth={0.924}
          d="M270.167 262.526c-.272.182 22.332 13.345 15.523 24.693 6.446-4.267 4.358-11.71 4.358-11.71s5.265 13.707-7.535 20.425c1.362 1.18 2.27.907 2.27.907l-2.18 2.18s-.998 1.633 7.627-2.543c-2.361 1.907-2.542 3.268-2.542 3.268s.635 1.816 6.264-3.086c-4.54 4.902-5.538 7.444-5.538 7.353 12.255-1.09 38.944-40.942-8.443-52.744 2.543 2.634 2.179 2.27 2.179 2.27l-11.983 8.987z"
        />
        <path
          fill="#fff"
          fillRule="evenodd"
          stroke="#000"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={0.924}
          d="M271.165 258.624c3.087 2.179 4.175 2.905 4.539 3.995-2.814-.635-5.356-.454-5.356-.454s-6.082-5.81-7.172-6.264c-.817 0-5.537-2.996-5.537-2.996-2.36-1.18-4.539-9.35 4.176-6.99 8.986 4.266 10.257 4.63 10.257 4.63l10.713 3.359 6.173 6.9s-10.984-5.448-12.346-5.539c2.995 2.451 4.72 5.81 4.72 5.81-3.48-.999-6.505-1.906-10.167-2.45z"
        />
        <path
          fill="none"
          stroke="#fff"
          strokeLinecap="round"
          strokeWidth={0.924}
          d="M228.413 209.87s10.53-2.542 11.801-2.18"
        />
        <path
          fill="#de3908"
          fillRule="evenodd"
          d="M-128 426.68h768v85.321h-768z"
        />
      </g>
    </svg>
  );
}

export default SvgUg;
