import * as React from "react";

function SvgTt(props) {
  return (
    <svg viewBox="0 0 640 480" {...props}>
      <path fill="#fff" d="M0 0h640v480H0z" />
      <path
        fill="#e00000"
        fillRule="evenodd"
        d="M463.663 480L.05 1.003 0 479.747l463.663.253zM176.337 0L639.95 478.997 640 .253 176.337 0z"
      />
      <path
        fillRule="evenodd"
        d="M27.74.23h118.597l468.176 479.315h-122.35L27.738.229z"
      />
    </svg>
  );
}

export default SvgTt;
