import React from "react";
import PropTypes from "prop-types";

import "./icons.css";
export { default as iconography } from "./icons.json";

export default function Icon({
  icon,
  className = "",
  onClick,
  onMouseUp,
  onMouseDown
}) {
  return (
    <i
      className={`aui-icon aui-icon-${icon} ${className}`}
      onClick={onClick}
      onMouseDown={onMouseDown}
      onMouseUp={onMouseUp}
    />
  );
}

Icon.propTypes = {
  icon: PropTypes.string,
  className: PropTypes.string,
  onClick: PropTypes.func,
  onMouseDown: PropTypes.func,
  onMouseUp: PropTypes.func
};

export const getIcons = () => iconography.map(icon => icon.name);
