import React from "react";
import PropTypes from "prop-types";

import { Icon } from "../../";

function AlertFactory(colorPrimary, colorSecondary, textColor) {
  const component = props => {
    const containerClassNames = [
      `aui-overflow-hidden aui-mb-8 aui-mt-4 aui-text-${textColor}`,
      props.containerClassName ? props.containerClassName : ""
    ]
      .join(" ")
      .trim();

    return (
      <div
        className={`aui-max-w-lg aui-mx-auto aui-rounded aui-overflow-hidden aui-shadow-md aui-bg-white aui-border aui-border-${colorSecondary} aui-border-solid aui-fade-in`}
      >
        <div
          className={`aui-px-6 aui-py-6 aui-bg-${colorPrimary} aui-border-0 aui-border-b aui-border-b-2 aui-border-solid aui-border-${colorSecondary}`}
        />

        <div className="aui-overflow-hidden aui-flex aui-justify-around aui--mt-8">
          <div
            className={`aui-rounded-full aui-h-16 aui-w-16 aui-flex aui-items-center aui-justify-center aui-bg-${colorSecondary}`}
          >
            <Icon
              icon="alert-triangle"
              className="aui-text-4xl aui-text-white"
            />
          </div>
        </div>
        <div className="aui-flex aui-justify-around aui-pt-2 aui-pl-10 aui-pr-10 aui-text-center">
          <div className={containerClassNames}>{props.children}</div>
        </div>
      </div>
    );
  };
  component.propTypes = {
    children: PropTypes.any,
    containerClassName: PropTypes.string
  };
  return component;
}

const WarningAlert = AlertFactory("warning-1", "warning-2", "warning-3");
const ErrorAlert = AlertFactory("error-1", "error-2", "warning-3");

export { WarningAlert, ErrorAlert };
