import * as React from "react";

function SvgTc(props) {
  return (
    <svg viewBox="0 0 640 480" {...props}>
      <path fill="#006" d="M640 480V0H0v480h640z" />
      <path fill="#006" fillRule="evenodd" d="M0 0h373.68v232.168H0z" />
      <g strokeWidth="1pt">
        <path
          fill="#fff"
          d="M0 0v25.957l331.901 206.21h41.778v-25.956L41.777.001H0zm373.679 0v25.956L41.777 232.166H0V206.21L331.901 0h41.778z"
        />
        <path
          fill="#fff"
          d="M155.7 0v232.167h62.28V0H155.7zM0 77.389v77.389h373.679v-77.39H0z"
        />
        <path
          fill="#c00"
          d="M0 92.867V139.3h373.679V92.867H0zM168.155 0v232.167h37.368V0h-37.368zM0 232.167l124.56-77.39h27.851l-124.56 77.39H0zM0 0l124.56 77.389H96.708L0 17.305V0zm221.267 77.389L345.827 0h27.852l-124.56 77.389h-27.852zM373.68 232.167l-124.56-77.39h27.852l96.708 60.085v17.305z"
        />
      </g>
      <path
        fill="#fff"
        fillOpacity={0.996}
        d="M612.54 212.442v79.91c0 65.607-25.77 125.663-102.217 162.169-76.416-36.506-102.189-96.56-102.189-162.168v-79.911h204.444z"
      />
      <path
        fill="#fdc300"
        fillOpacity={0.996}
        d="M605.691 220.585v74.535c0 61.192-24.036 117.209-95.34 151.256-71.307-34.048-95.34-90.063-95.34-151.256v-74.535h190.69z"
      />
      <g stroke="#000" strokeWidth={0.091}>
        <path
          fill="#ff9e3d"
          d="M205.96 52.847a2.085 2.085 0 00-1.265-.441c.117-1.295-.824-1.854-1.501-2.09-.676-.235-.912-.5-1.029-1.03-.118-.529-.471-.852-1.089-1.029-.618-.176-1.148-.794-1.383-1.088-.235-.295-.324-.236-.5.264-.177.5-.883 1.442-.236 1.472-.47.382-.735.823-.441 1.412-.324.265-.559.647-.118.794.442.147.559.265.589.383-.397 0-.5.794.441.97-.206.03-.294.677.089.677-.53 0-.972.795-1.648.795-.677 0-1.03.147-1.059.441-.03.294-.383.647-.559.794.5.06.823 0 1.088-.206s.706-.264 1.001-.029c.294.235.912.353 1.206.294-.647.442-1.118 1.03-1.177 1.589-.059.56-.441 1.206-.883 1.383-.441.176-1.029.647-1.235 1.206.559 1.383 1.352 1.725 2.147 2.09 1.413.646 2.295 1.117 2.884 1.764.588.648.824.177 1.706 1.295.883 1.118 1.707 3.236 2.589 3.59.883.353 2.001.117 2.648-1 .648-1.119 1.06-12.417-2.265-14.3z"
          transform="matrix(3.909 0 0 3.9094 -339.132 46.653)"
        />
        <path
          fill="#ff927f"
          d="M207.46 48.139c-.824.883-1.884 1.765-1.707 3.354s.353 4.178-1.118 5.06c-1.471.884-2.177 3.12-.412 4.414 1.765 1.295 1.824 2.884 2.118 4.355.295 1.47 1.06 2.648 2.237 1.647 1.176.177 1.824-.294 1.824-1.235s.059-1.471.941-1.766c.883-.294 1.118-.588 1.53-1.383.412-.794.647-1.147.412-1.618-.235-.47-.324-1.441-.147-2.148.177-.706-.235-1.088-.471-1.53-.235-.44-.323-.823-.206-1.412.118-.588-.176-.97-.588-1.265-.412-.294-.589-.588-.5-1.06.088-.47-.295-.764-.736-1.117s-.765-.824-.765-1.53c0-.706-1.353-2.354-2.412-2.766z"
          transform="matrix(3.909 0 0 3.9094 -339.132 46.653)"
        />
        <path
          fill="#ff9ee1"
          d="M207.79 67.352c1.971-.5 1.795-3.737 1.501-5.09-.295-1.353-1.051-3.292-.471-4.825.824-2.177-1.001-4.237-.53-5.06.471-.825.412-1.266.353-1.53-.058-.266-.5-.472-.529.087-.03.56-.177.765-.53 1.118s-.265 1.118 0 1.972c.265.853.824 2.148-.059 3.854-.882 1.706-.519 2.467-.176 3.266.529 1.236 1.795 5.531.441 6.208z"
          transform="matrix(3.909 0 0 3.9094 -339.132 46.653)"
        />
        <path
          fill="none"
          d="M198.96 48.904c.221 0 .648-.088.648.206m-1.088 1.206c.265-.191.736.03 1.206-.25m-.736 1.427c.353 0 .824-.015 1.265-.368m-.825 1.339c.324 0 .927-.206 1.074-.632m-.984 1.309c.632-.03 1.471.206 1.588-.015m-1.558 2.104c.441-.412 1.486-.691 1.721-1.118m-.601-5.443c.735.235.431.69.794.971.648.5.059 1.06.589 1.206.529.147.52.237.382.824-.162.692.5.72.265 1.177m2-.441c-.294 0-1.162.03-1.53.81m-4.75 7.678c.339.059.508-.134.603-.324.103-.206.147-.353.456-.367.309-.015.78-.103.883-.339.103-.235.471-.015.78-.632.309-.618.515-1.354 1.338-1.619"
          transform="matrix(3.909 0 0 3.9094 -339.132 46.653)"
        />
      </g>
      <g stroke="#000" strokeWidth={0.091}>
        <path
          fill="#00a728"
          d="M221.76 73.75c.333.484-.012 1.007.553 1.216.662.245.084 1.116.865 1.422.692.273.005 1.105.753 1.479.669.334-.112 1.115.781 1.562.892.446-.168 1.45.502 1.84.669.391-.223 1.395.558 1.786.781.39-.335 1.171.39 1.729.725.558-.167 1.394.558 1.896s-.445 1.173.279 1.897c.558.558-.201 1.104.223 1.953.279.557-.279 2.789-1.841 2.566-.558.948-2.733 1.785-3.514 1.506-.781.558-3.291 1.339-4.575.111-1.282 1.228-3.793.447-4.574-.111-.781.279-2.956-.558-3.514-1.506-1.562.223-2.12-2.009-1.841-2.566.424-.849-.335-1.395.223-1.953.724-.724-.446-1.394.279-1.897s-.167-1.338.558-1.896c.725-.558-.391-1.339.39-1.73.781-.39-.111-1.394.558-1.785.67-.39-.39-1.394.502-1.84.893-.447.112-1.228.781-1.562.748-.374.061-1.206.753-1.479.781-.306.203-1.177.865-1.422.574-.212.208-.749.57-1.24 1.661.654 6.803 1.123 8.918.024z"
          transform="matrix(3.909 0 0 3.9094 -339.132 46.653)"
        />
        <path
          fill="#fdc300"
          fillOpacity={0.996}
          d="M213.51 76.668c.001.25-.005.5-.237.809-.502.67.223 1.004-.335 1.73-.557.724.112 1.17-.502 1.784-.613.614.112 1.172-.502 1.841-.613.67.112 1.116-.502 1.73-.613.613.112 1.283-.502 1.896-.613.614.112 1.395-.558 2.12-.669.725.112 1.283-.446 1.84-.558.559-.056 1.116-.446 1.507.669-.502.669-1.283 1.171-1.674.502-.39-.167-.948.112-1.227s.056-1.45.558-2.008.056-1.674.502-2.064.111-1.562.669-1.952 0-1.34.446-1.841c.447-.502 0-1.283.447-1.897.446-.614.02-1.17.334-1.673.14-.224.14-1.841-.209-.921zm1.61.697c-.39.725.279.892-.223 1.618s.279 1.283-.279 2.008.279 1.115-.223 1.84c-.502.726.223 1.116-.223 1.674-.447.558.334 1.116-.223 1.785-.558.67.223 1.172-.224 1.785-.446.614.168 1.283-.223 1.785-.39.502.279 1.228-.279 1.897s.112 1.116-.223 1.562c.67-.223.419-1.144.67-1.339.251-.195-.084-1.478.306-1.813.391-.334-.251-1.367.112-1.813.614-.753.084-1.255.446-1.84.363-.586-.138-1.338.14-1.73.474-.67-.21-1.273.139-1.757.502-.697-.097-1.272.251-1.73.53-.697-.056-1.394.168-1.757.223-.362.585-1.924-.112-2.175zm2.17.609c-.39.972.279 1.36.056 2.138-.223.778.279 1.361-.056 2.203-.334.843.447 1.49 0 2.592-.446 1.102.288 1.376 0 2.463-.334 1.263.558 1.933 0 2.825-.557.893.639 1.188.056 2.12-.279.446.028 1.59.474.223.447-1.366-.334-1.534.056-2.092.391-.558-.139-2.036.028-2.817.168-.78-.39-1.84-.028-2.677.363-.837-.558-1.73-.195-2.455.363-.725-.167-1.618.167-2.203.335-.586-.251-1.367-.111-1.702.139-.334-.14-1.57-.447-.618z"
          transform="matrix(3.909 0 0 3.9094 -339.132 46.653)"
        />
        <path
          fill="#ef072d"
          d="M221.47 74.408c0-6.303-.892-8.479-4.184-8.479-3.291 0-4.183 2.175-4.183 8.479h8.367z"
          transform="matrix(3.909 0 0 3.9094 -339.132 46.653)"
        />
        <path
          fill="none"
          d="M214.33 73.906c-.614.614.112 1.06-.502 1.785-.614.725-.056 1.116-.558 1.785s.223 1.004-.335 1.73c-.557.725.112 1.17-.502 1.784-.613.614.112 1.172-.502 1.841-.613.67.112 1.116-.502 1.73-.613.613.112 1.283-.502 1.896-.613.614.112 1.395-.558 2.12-.669.725.112 1.283-.446 1.84-.558.559-.056 1.117-.446 1.507-.391.39-.446.935-.279 1.172m6.302-18.967c-.557.725.279 1.06-.223 1.673-.502.614.223.837-.167 1.563s.279.892-.223 1.617.279 1.283-.279 2.008.279 1.116-.223 1.841c-.502.725.223 1.116-.223 1.674-.447.558.334 1.115-.223 1.785-.558.67.223 1.171-.224 1.785-.446.614.168 1.283-.223 1.785-.39.502.279 1.227-.279 1.897s.112 1.115-.223 1.562c-.335.446-.599 1.101-.279 1.283m4.579-19.803c-.278 1.166.391 2.203 0 3.175-.39.972.279 1.36.056 2.138-.223.778.279 1.361-.056 2.203-.334.843.447 1.49 0 2.592-.446 1.102.288 1.376 0 2.463-.334 1.263.558 1.933 0 2.825-.557.893.503 1.116.056 2.12-.446 1.004.279 1.395-.056 2.399m2.96-20.808c.614.614-.112 1.06.502 1.785.614.725.056 1.116.558 1.785s-.223 1.004.335 1.73c.557.725-.112 1.17.502 1.784.613.614-.112 1.172.502 1.841.613.67-.112 1.116.502 1.73.613.613-.112 1.283.502 1.896.613.614-.112 1.395.558 2.12.669.725-.112 1.283.446 1.84.558.559.056 1.117.446 1.507.391.39.447.935.279 1.172m-6.302-18.967c.557.725-.279 1.06.223 1.673.502.614-.223.837.167 1.563.391.725-.279.892.223 1.617s-.279 1.283.279 2.008-.279 1.116.223 1.841c.502.725-.223 1.116.223 1.674.447.558-.334 1.115.224 1.785.557.67-.224 1.171.223 1.785.446.614-.168 1.283.223 1.785.39.502-.279 1.227.279 1.897s-.112 1.115.223 1.562c.335.446.6 1.101.279 1.283"
          transform="matrix(3.909 0 0 3.9094 -339.132 46.653)"
        />
        <path
          d="M213.11 74.408c0-6.303.892-8.479 4.183-8.479-1.506 0-2.426 1.171-2.677 1.952s-.112.725.223.419.167.362-.111.725c-.279.362-.558 1.394-.168 1.06.391-.335.697.056.279.641s-.781 1.953-.363 1.479c.419-.475.502.306.252.697-.252.39-.224.725 0 .558.223-.168.195.641-.028.948h-1.59zm7.72-.362c0-4.937-.613-6.415-1.84-6.917-.695-.285-.715-.223-.383.223s.281 1.088-.026.641c-.306-.446-.408-.53-.536-.334-.128.195.357 1.115.715 1.952.288.673.102 1.004-.332.335s-.434-.063-.053.502c.489.725.474 2.929.474 3.849l1.981-.251z"
          transform="matrix(3.909 0 0 3.9094 -339.132 46.653)"
        />
        <path
          fill="#ef072d"
          d="M217.29 75.329c1.311 0 2.746-.234 3.71-.608 1.144-.444 1.205-.834.809-1.332-.279-.351-.893-.14-1.478.117-.586.257-2.204.42-3.041.42-.836 0-2.454-.163-3.04-.42-.585-.258-1.199-.468-1.478-.117-.396.498-.335.888.809 1.332.963.374 2.399.608 3.709.608z"
          transform="matrix(3.909 0 0 3.9094 -339.132 46.653)"
        />
      </g>
      <g fill="#b95a1e" fillRule="evenodd">
        <path
          stroke="#000"
          strokeWidth={6.377}
          d="M779.41 912.6s-.12 103.6 0 179.24c.12 75.63 19.822 104.91 54.262 103.59 34.44-1.33 41.108-58.83 34.531-92.08-6.577-33.26-53.24-198.97-53.24-198.97l-35.553 8.222z"
          transform="matrix(.053 -.03423 .03246 .0559 489.023 284.185)"
        />
        <path
          stroke="#000"
          strokeWidth={6.377}
          d="M779.41 912.6s-.12 103.6 0 179.24c.12 75.63 19.822 104.91 54.262 103.59 34.44-1.33 41.108-58.83 34.531-92.08-6.577-33.26-53.24-198.97-53.24-198.97l-35.553 8.222z"
          transform="matrix(.0608 -.01361 .0129 .06412 499.988 261.06)"
        />
        <path
          stroke="#000"
          strokeWidth={6.377}
          d="M779.41 912.6s-.12 103.6 0 179.24c.12 75.63 19.822 104.91 54.262 103.59 34.44-1.33 41.108-58.83 34.531-92.08-6.577-33.26-53.24-198.97-53.24-198.97l-35.553 8.222z"
          transform="matrix(.06216 0 0 .06555 509.298 249.353)"
        />
        <path
          stroke="#000"
          strokeWidth={8.095}
          d="M779.53 255.12c159.57-1.394 177.16-35.432 177.16-35.432s35.43-70.866 17.716-88.584c-17.717-17.716-194.88-17.716-194.88-17.716v141.73z"
          transform="matrix(.03857 0 0 .06555 527.683 292.32)"
        />
        <path
          stroke="#000"
          strokeWidth={6.377}
          d="M779.53 255.12c106.3 0 124.02-35.432 124.02-35.432s35.43-70.866 17.716-88.584c-17.717-17.716-141.73-17.716-141.73-17.716v141.73z"
          transform="matrix(.06216 0 0 .06555 509.298 287.673)"
        />
        <path
          stroke="#000"
          strokeWidth={6.377}
          d="M779.53 255.12c88.583 0 141.73-35.433 141.73-35.433s35.43-70.866 17.716-88.584c-17.72-17.72-159.45-17.71-159.45-17.71v141.73z"
          transform="matrix(.06216 0 0 .06555 509.298 283.026)"
        />
        <path
          stroke="#000"
          strokeWidth={5.724}
          d="M832.68 113.39s70.866-88.583 194.88-177.17c124.01-88.582 213.73-156.72 230.31-141.73 0 17.717-106.3 106.3-194.88 177.17C974.4 42.52 868.1 148.82 868.1 148.82l-35.433-35.433z"
          transform="matrix(.07714 0 0 .06555 497.232 249.488)"
        />
        <path
          stroke="#000"
          strokeWidth={5.724}
          d="M832.68 113.39s70.05-52.025 204.38-124.02c132.23-63.918 318.89-53.15 318.89-35.433-17.71 17.716-177.16 17.716-283.46 70.866-101.48 50.729-204.39 124.01-204.39 124.01l-35.433-35.433z"
          transform="matrix(.07714 0 0 .06555 497.87 254.135)"
        />
        <path
          stroke="#000"
          strokeWidth={5.724}
          d="M832.68 113.39s61.675-35.068 204.38-88.583c141.72-53.15 329.87-17.717 329.87 0-17.7 17.713-170.4 6.944-276.7 35.429-109.99 27.758-222.09 88.584-222.09 88.584l-35.433-35.433z"
          transform="matrix(.07714 0 0 .06555 497.93 262.135)"
        />
        <path
          stroke="#000"
          strokeWidth={5.724}
          d="M832.68 113.39s71.079-70.976 222.47-88.583c152.3-17.72 276.3 88.583 276.3 106.29-17.7 17.72-159.5-77.972-265.7-64.286-112.67 13.654-197.69 82.006-197.69 82.006l-35.433-35.433z"
          transform="matrix(.07714 0 0 .06555 498.45 270.253)"
        />
        <path
          stroke="#000"
          strokeWidth={6.377}
          d="M779.53 255.12c159.57-1.394 159.45-45.508 159.45-45.508s35.43-70.866 17.716-88.584c-17.717-17.716-177.16-7.64-177.16-7.64v141.73z"
          transform="matrix(.06216 0 0 .06555 509.298 277.227)"
        />
        <path
          stroke="#000"
          strokeWidth={6.377}
          d="M779.53 255.12c159.57-1.394 177.16-35.432 177.16-35.432s35.43-70.866 17.716-88.584c-17.717-17.716-194.88-17.716-194.88-17.716v141.73z"
          transform="matrix(.06216 0 0 .06555 509.298 272.58)"
        />
        <path
          stroke="#000"
          strokeWidth={6.377}
          d="M779.53 255.12c159.57-1.394 194.88-35.433 194.88-35.433s35.43-70.866 17.716-88.583c-17.717-17.716-212.6-17.716-212.6-17.716v141.73z"
          transform="matrix(.06216 0 0 .06555 509.298 266.774)"
        />
        <path
          stroke="#000"
          strokeWidth={6.377}
          d="M779.53 255.12c159.57-1.394 212.6-35.433 212.6-35.433s17.714-70.866 0-88.583c-17.717-17.716-212.6-17.716-212.6-17.716v141.73z"
          transform="matrix(.06216 0 0 .06555 509.298 260.967)"
        />
        <path
          stroke="#000"
          strokeWidth={5.724}
          d="M832.68 113.39S946.89 24.807 946.89-46.06c0-70.866-50.349-141.73-64.625-177.17 28.552 0 93.176 106.3 93.176 177.17 0 77.444-107.32 194.88-107.32 194.88l-35.433-35.433z"
          transform="matrix(.07714 0 0 .06555 495.728 244.706)"
        />
        <path
          stroke="#000"
          strokeWidth={5.724}
          d="M832.68 113.39s-14.276-53.15-14.276-159.45c0-124.02 63.001-202.89 85.654-230.32 5.898 27.422-57.102 124.02-57.102 230.32 0 77.444 21.157 194.88 21.157 194.88l-35.433-35.433z"
          transform="matrix(.07714 0 0 .06555 495.728 244.706)"
        />
        <path
          stroke="#000"
          strokeWidth={6.377}
          d="M779.53 255.12c159.57-1.394 212.6-35.433 212.6-35.433s0-70.866-17.716-88.583c-17.717-17.716-194.88-17.716-194.88-17.716v141.73z"
          transform="matrix(.06216 0 0 .06555 509.298 255.16)"
        />
        <path
          stroke="#000"
          strokeWidth={6.377}
          d="M779.53 255.12c159.57-1.394 194.88-35.433 194.88-35.433s0-70.866-17.716-88.583c-17.717-17.716-177.16-17.716-177.16-17.716v141.73z"
          transform="matrix(.06216 0 0 .06555 509.298 249.353)"
        />
        <path
          stroke="#fdc301"
          strokeWidth={16.071}
          d="M318.9 1318.1c55.065-122.32 70.866-336.61 70.866-372.05 0-99.782-32.948-585.11-35.433-779.53s124.02-301.18 248.03-301.18c106.3 0 177.17 70.866 177.17 177.17h-53.15c0-70.866-53.15-124.02-124.02-124.02-106.3 0-187.99 83.739-194.88 248.03-7.605 189.89 21.316 682.94 17.717 814.96s-51.234 249.72-106.3 336.61z"
          transform="matrix(-.06216 0 0 .06555 606.204 249.353)"
        />
        <path
          stroke="#000"
          strokeWidth={6.377}
          d="M779.41 912.6s-.12 103.6 0 179.24c.12 75.63 19.822 104.91 54.262 103.59 34.44-1.33 41.108-58.83 34.531-92.08-6.577-33.26-53.24-198.97-53.24-198.97l-35.553 8.222z"
          transform="matrix(-.053 -.03423 -.03246 .0559 626.48 284.185)"
        />
        <path
          stroke="#000"
          strokeWidth={6.377}
          d="M779.53 32.884c124.13.141 177.77 102.39 177.46 100.31l-35.734 33.347c-18.985 17.717-141.73 17.717-140.21 17.626l-1.52-151.29z"
          transform="matrix(.06216 0 0 .06555 509.298 249.353)"
        />
        <path
          stroke="#000"
          strokeWidth={6.377}
          d="M779.41 912.6s-.12 103.6 0 179.24c.12 75.63 19.822 104.91 54.262 103.59 34.44-1.33 41.108-58.83 34.531-92.08-6.577-33.26-53.24-198.97-53.24-198.97l-35.553 8.222z"
          transform="matrix(-.0608 -.01361 -.0129 .06412 615.506 261.06)"
        />
        <path
          stroke="#000"
          strokeWidth={6.377}
          d="M779.41 912.6s-.12 103.6 0 179.24c.12 75.63 19.822 104.91 54.262 103.59 34.44-1.33 41.108-58.83 34.531-92.08-6.577-33.26-53.24-198.97-53.24-198.97l-35.553 8.222z"
          transform="matrix(-.06216 0 0 .06555 606.196 249.353)"
        />
        <path
          stroke="#000"
          strokeWidth={8.095}
          d="M779.53 255.12c159.57-1.394 177.16-35.432 177.16-35.432s35.43-70.866 17.716-88.584c-17.717-17.716-194.88-17.716-194.88-17.716v141.73z"
          transform="matrix(-.03857 0 0 .06555 587.811 292.32)"
        />
        <path
          stroke="#000"
          strokeWidth={6.377}
          d="M779.53 255.12c106.3 0 124.02-35.432 124.02-35.432s35.43-70.866 17.716-88.584c-17.717-17.716-141.73-17.716-141.73-17.716v141.73z"
          transform="matrix(-.06216 0 0 .06555 606.196 287.673)"
        />
        <path
          stroke="#000"
          strokeWidth={6.377}
          d="M779.53 255.12c88.583 0 141.73-35.433 141.73-35.433s35.43-70.866 17.716-88.584c-17.72-17.72-159.45-17.71-159.45-17.71v141.73z"
          transform="matrix(-.06216 0 0 .06555 606.196 283.026)"
        />
        <path
          stroke="#000"
          strokeWidth={5.724}
          d="M832.68 113.39s70.866-88.583 194.88-177.17c124.01-88.582 213.73-156.72 230.31-141.73 0 17.717-106.3 106.3-194.88 177.17C974.4 42.52 868.1 148.82 868.1 148.82l-35.433-35.433z"
          transform="matrix(-.07714 0 0 .06555 618.262 249.488)"
        />
        <path
          stroke="#000"
          strokeWidth={5.724}
          d="M832.68 113.39s70.05-52.025 204.38-124.02c132.23-63.918 318.89-53.15 318.89-35.433-17.71 17.716-177.16 17.716-283.46 70.866-101.48 50.729-204.39 124.01-204.39 124.01l-35.433-35.433z"
          transform="matrix(-.07714 0 0 .06555 617.632 254.135)"
        />
        <path
          stroke="#000"
          strokeWidth={5.724}
          d="M832.68 113.39s61.675-35.068 204.38-88.583c141.72-53.15 329.87-17.717 329.87 0-17.7 17.713-170.4 6.944-276.7 35.429-109.99 27.758-222.09 88.584-222.09 88.584l-35.433-35.433z"
          transform="matrix(-.07714 0 0 .06555 617.573 262.135)"
        />
        <path
          stroke="#000"
          strokeWidth={5.724}
          d="M832.68 113.39s71.079-70.976 222.47-88.583c152.3-17.72 276.3 88.583 276.3 106.29-17.7 17.72-159.5-77.972-265.7-64.286-112.67 13.654-197.69 82.006-197.69 82.006l-35.433-35.433z"
          transform="matrix(-.07714 0 0 .06555 617.052 270.253)"
        />
        <path
          stroke="#000"
          strokeWidth={6.377}
          d="M779.53 255.12c159.57-1.394 159.45-45.508 159.45-45.508s35.43-70.866 17.716-88.584c-17.717-17.716-177.16-7.64-177.16-7.64v141.73z"
          transform="matrix(-.06216 0 0 .06555 606.196 277.227)"
        />
        <path
          stroke="#000"
          strokeWidth={6.377}
          d="M779.53 255.12c159.57-1.394 177.16-35.432 177.16-35.432s35.43-70.866 17.716-88.584c-17.717-17.716-194.88-17.716-194.88-17.716v141.73z"
          transform="matrix(-.06216 0 0 .06555 606.196 272.58)"
        />
        <path
          stroke="#000"
          strokeWidth={6.377}
          d="M779.53 255.12c159.57-1.394 194.88-35.433 194.88-35.433s35.43-70.866 17.716-88.583c-17.717-17.716-212.6-17.716-212.6-17.716v141.73z"
          transform="matrix(-.06216 0 0 .06555 606.196 266.774)"
        />
        <path
          stroke="#000"
          strokeWidth={6.377}
          d="M779.53 255.12c159.57-1.394 212.6-35.433 212.6-35.433s17.714-70.866 0-88.583c-17.717-17.716-212.6-17.716-212.6-17.716v141.73z"
          transform="matrix(-.06216 0 0 .06555 606.196 260.967)"
        />
        <path
          stroke="#000"
          strokeWidth={5.724}
          d="M832.68 113.39S946.89 24.807 946.89-46.06c0-70.866-50.349-141.73-64.625-177.17 28.552 0 93.176 106.3 93.176 177.17 0 77.444-107.32 194.88-107.32 194.88l-35.433-35.433z"
          transform="matrix(-.07714 0 0 .06555 619.775 244.706)"
        />
        <path
          stroke="#000"
          strokeWidth={5.724}
          d="M832.68 113.39s-14.276-53.15-14.276-159.45c0-124.02 63.001-202.89 85.654-230.32 5.898 27.422-57.102 124.02-57.102 230.32 0 77.444 21.157 194.88 21.157 194.88l-35.433-35.433z"
          transform="matrix(-.07714 0 0 .06555 619.775 244.706)"
        />
        <path
          stroke="#000"
          strokeWidth={6.377}
          d="M779.53 255.12c159.57-1.394 212.6-35.433 212.6-35.433s0-70.866-17.716-88.583c-17.717-17.716-194.88-17.716-194.88-17.716v141.73z"
          transform="matrix(-.06216 0 0 .06555 606.196 255.16)"
        />
        <path
          stroke="#000"
          strokeWidth={6.377}
          d="M779.53 255.12c159.57-1.394 194.88-35.433 194.88-35.433s0-70.866-17.716-88.583c-17.717-17.716-177.16-17.716-177.16-17.716v141.73z"
          transform="matrix(-.06216 0 0 .06555 606.196 249.353)"
        />
        <path
          stroke="#fdc301"
          strokeWidth={16.071}
          d="M318.9 1318.1c55.065-122.32 70.866-336.61 70.866-372.05 0-99.782-32.948-585.11-35.433-779.53s124.02-301.18 248.03-301.18c106.3 0 177.17 70.866 177.17 177.17h-53.15c0-70.866-53.15-124.02-124.02-124.02-106.3 0-187.99 83.739-194.88 248.03-7.605 189.89 21.316 682.94 17.717 814.96s-51.234 249.72-106.3 336.61z"
          transform="matrix(.06216 0 0 .06555 509.29 249.353)"
        />
        <path
          stroke="#000"
          strokeWidth={6.377}
          d="M779.53 32.884c124.13.141 177.77 102.39 177.46 100.31l-35.734 33.347c-18.985 17.717-141.73 17.717-140.21 17.626l-1.52-151.29z"
          transform="matrix(-.06216 0 0 .06555 606.196 249.353)"
        />
      </g>
    </svg>
  );
}

export default SvgTc;
