function scrollToTarget(
  selector,
  options = { behavior: "smooth", block: "nearest", inline: "center" }
) {
  const section = document.querySelector(selector);

  section.scrollIntoView(options);
}

export { scrollToTarget };
