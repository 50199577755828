import React, { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import Editor from "draft-js-plugins-editor";
import createMarkdownShortcutsPlugin from "draft-js-markdown-shortcuts-plugin";

import "draft-js/dist/Draft.css";

import {
  EditorState,
  RichUtils,
  SelectionState,
  ContentState,
  convertToRaw,
  convertFromRaw
} from "draft-js";

import { draftToMarkdown, markdownToDraft } from "markdown-draft-js";
import { stateFromMarkdown } from "draft-js-import-markdown";

import { TextEditorButton } from "./Components/TextEditorButton";
import { InfoPopup } from "../InfoPopup";

import "./index.pcss";

const INLINE_STYLES = ["BOLD", "ITALIC", "STRIKETHROUGH", "UNDERLINE"];
const BLOCK_TYPES = ["ordered-list-item", "unordered-list-item"];

const RichTextEditor = ({
  title,
  subtitle,
  hideShortcutsTooltip,
  value,
  onBlur,
  onChange,
  refreshEditorState,
  useMarkdownDraftJS,
  disableAutofocus
}) => {
  const [plugins] = useState([createMarkdownShortcutsPlugin()]);
  const [editorState, setEditorState] = useState(
    value ? safeCreateContent(value) : EditorState.createEmpty()
  );
  const [toggledStyles, setToggledStyles] = useState({});
  const [refresh, setRefresh] = refreshEditorState;
  const ref = useRef();

  useEffect(() => {
    if (!disableAutofocus) focus();
  }, []);

  useEffect(() => {
    if (!refresh) return;

    setEditorState(
      value ? safeCreateContent(value) : EditorState.createEmpty()
    );

    setRefresh(false);
  }, [refresh]);

  function updateEditorState(newState) {
    setEditorState(newState);
    if (onChange) {
      const content = newState.getCurrentContent();
      const rawObject = convertToRaw(content);
      // Markdown treats text separated by singular newlines as a paragraph.  This is problematic in when we actually want a singular line seperation (instead of a paragraph) or when newlines are copy and pasted into the editor.
      // There are 3 ways to instruct to Markdown to treat newlines literally:
      // 1. Precede newline with double spaces
      // 2. Precede newline with an html <br> tag
      // 3. Precede newline with a backslash (\)
      // Option 3 is not universally supported by Markdown renderers.  Option 2 creates a <br> tag artifact which looks unusual when viewing the raw content.  Option 1 also creates an artifact but trailing spaces seem more innocuous and is universally accepted.
      // References:
      // https://daringfireball.net/projects/markdown/syntax#p
      // https://www.markdownguide.org/basic-syntax/#line-break-best-practices
      const markdownString = draftToMarkdown(rawObject).replace(/\n/g, "  \n");
      onChange({
        markdown: markdownString,
        rawObject: rawObject,
        plaintext: content.getPlainText()
      });
    }

    const newContentState = newState.getCurrentContent();
    const inlineStyle = newState.getCurrentInlineStyle();

    INLINE_STYLES.forEach(style => {
      toggledStyles[style.toLowerCase()] = inlineStyle.has(style);
    });

    const selectionState = newState.getSelection();
    const blocks = getSelectedBlocks(
      newContentState,
      selectionState.getAnchorKey(),
      selectionState.getFocusKey()
    );

    const appliedBlockStyles = blocks.map(block => block.getType());
    BLOCK_TYPES.forEach(blockStyle => {
      toggledStyles[blockStyle.toLowerCase()] = appliedBlockStyles.includes(
        blockStyle
      );
    });
    setToggledStyles({ ...toggledStyles });
  }

  const handleBlur = () => {
    if (!onBlur) {
      return;
    }
    const content = editorState.getCurrentContent();
    const rawObject = convertToRaw(content);
    // See updateEditorState for note on newlines in Markdown
    const markdownString = draftToMarkdown(rawObject).replace(/\n/g, "  \n");
    onBlur({
      markdown: markdownString,
      rawObject: rawObject,
      plaintext: content.getPlainText()
    });
  };

  function getSelectedBlocks(contentState, anchorKey, focusKey) {
    const isSameBlock = anchorKey === focusKey;
    const startingBlock = contentState.getBlockForKey(anchorKey);
    const selectedBlocks = [startingBlock];

    if (!isSameBlock) {
      let blockKey = anchorKey;

      while (blockKey !== focusKey) {
        const nextBlock = contentState.getBlockAfter(blockKey);
        if (!nextBlock) break;
        selectedBlocks.push(nextBlock);
        blockKey = nextBlock.getKey();
      }
    }
    return selectedBlocks;
  }

  function moveToEnd(editorState) {
    const blocks = editorState.getCurrentContent().getBlockMap();
    const selection = new SelectionState({
      anchorOffset: blocks.last().getLength(),
      anchorKey: blocks.last().getKey(),
      focusOffset: blocks.last().getLength(),
      focusKey: blocks.last().getKey()
    });
    return EditorState.forceSelection(editorState, selection);
  }

  function handleKeyCommand(command, editorState) {
    editorState = forceFocus();
    toggleToolbarButtonState(command);
    const newState = RichUtils.handleKeyCommand(editorState, command);

    if (newState) {
      updateEditorState(newState);
      return "handled";
    }
    return "not-handled";
  }

  function toggleStyle(e, style) {
    e.preventDefault();
    toggleToolbarButtonState(style);
    updateEditorState(RichUtils.toggleInlineStyle(forceFocus(), style));
  }

  function toggleToolbarButtonState(style) {
    toggledStyles[style.toLowerCase()] = toggledStyles[style.toLowerCase()]
      ? false
      : true;
    setToggledStyles({ ...toggledStyles });
  }

  function toggleBlockType(e, blockType) {
    e.preventDefault();
    setEditorState(RichUtils.toggleBlockType(forceFocus(), blockType));
    toggledStyles[blockType] = toggledStyles[blockType] ? false : true;
    setToggledStyles({ ...toggledStyles });
  }

  function focus() {
    ref.current.focus();
  }

  function forceFocus() {
    focus();
    if (!editorState.getSelection().hasFocus) {
      return EditorState.moveFocusToEnd(editorState);
    }
    return editorState;
  }

  function handleTab(e) {
    setEditorState(RichUtils.onTab(e, editorState, 12));
  }

  // A bug in the DarftJS draft-js-import-markdown sometimes causes the
  // stateFromMarkdown() call to fail for large, rich text documents. Until
  // the bug is resolved, we will rely on this fallback.
  function safeCreateContent(value) {
    try {
      if (useMarkdownDraftJS) {
        const rawData = markdownToDraft(value);
        const contentState = convertFromRaw(rawData);
        return EditorState.createWithContent(contentState);
      }
      return EditorState.createWithContent(stateFromMarkdown(value));
    } catch (_) {
      return EditorState.createWithContent(ContentState.createFromText(value));
    }
    return EditorState.createEmpty();
  }

  return (
    <div
      onClick={focus}
      className="aui-font-sans aui-mt-2 aui-flex aui-flex-wrap aui-justify-between"
    >
      <div
        title={title}
        className="aui-text-dark-1 aui-mb-2 aui-mr-4 aui-ml-2xs aui-inline-block aui-font-semibold aui-text-body"
      >
        {title}
      </div>
      <div className="aui-flex aui-space-x-4 aui-float-right aui-align-middle aui-items-center aui-mb-2 aui-mx-2xs">
        {INLINE_STYLES.map((style, idx) => (
          <TextEditorButton
            isToggled={toggledStyles[style.toLowerCase()]}
            key={idx}
            icon={`text-${style.toLowerCase()}`}
            onClick={e => toggleStyle(e, style)}
          />
        ))}
        <TextEditorButton
          icon="text-numbered-bullets"
          isToggled={toggledStyles["ordered-list-item"]}
          onClick={e => toggleBlockType(e, "ordered-list-item")}
        />
        <TextEditorButton
          icon="text-bullets"
          isToggled={toggledStyles["unordered-list-item"]}
          onClick={e => toggleBlockType(e, "unordered-list-item")}
        />
        {!hideShortcutsTooltip && (
          <div className="aui-text-body aui-text-grey-4 aui-user-select-none aui-cursor-pointer">
            <InfoPopup
              infoBody={
                <div className="aui-text-dark-1 aui-space-y-1">
                  <div>
                    <strong>CMD + B</strong> for bold text.
                  </div>
                  <div>
                    <strong>CMD + I</strong> for italic text.
                  </div>
                  <div>
                    <strong>CMD + U</strong> to underline.
                  </div>
                </div>
              }
            >
              Shortcuts
            </InfoPopup>
          </div>
        )}
      </div>
      {subtitle && (
        <div className="aui-text-grey-4 aui-mb-2 aui-ml-2xs aui-text-body">
          {subtitle}
        </div>
      )}
      <div className="aui-markdown-content aui-w-full">
        <Editor
          ref={ref}
          onTab={handleTab}
          editorState={editorState}
          onBlur={handleBlur}
          onChange={updateEditorState}
          handleKeyCommand={handleKeyCommand}
          plugins={plugins}
          spellCheck={true}
        />
      </div>
    </div>
  );
};

RichTextEditor.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  hideShortcutsTooltip: PropTypes.bool,
  value: PropTypes.string,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  refreshEditorState: PropTypes.array,
  useMarkdownDraftJS: PropTypes.bool,
  disableAutofocus: PropTypes.bool
};

RichTextEditor.defaultProps = {
  refreshEditorState: [false, () => {}]
};

export { RichTextEditor };
