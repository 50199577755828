import * as React from "react";

function SvgBy(props) {
  return (
    <svg viewBox="0 0 640 480" {...props}>
      <defs>
        <clipPath id="by_svg__a">
          <path fillOpacity={0.67} d="M0 0h682.6v512H0z" />
        </clipPath>
      </defs>
      <g
        fillRule="evenodd"
        clipPath="url(#by_svg__a)"
        transform="scale(.9376 .9375)"
      >
        <path fill="#b20000" d="M0 0h1024v340.11H0z" />
        <path fill="#429f00" d="M0 340.11h1024V512H0z" />
        <path fill="#fff" d="M0 0h113.37v512H0z" />
        <g fill="#b20000" strokeWidth="1pt">
          <path d="M5.394 8.682h5.398v8.68H5.394zm16.196 0h16.196v8.68H21.59zM26.983 0h5.398v8.68h-5.397zM53.98 0h5.4v8.68h-5.4zm21.59 8.682h16.197v8.68H75.57zM80.98 0h5.398v8.68H80.98z" />
          <path d="M16.196 17.36h10.798v8.68H16.196zm16.196 0H43.19v8.68H32.392zm37.785 0h10.798v8.68H70.177zm16.196 0H97.17v8.68H86.374zm-75.586 8.68h10.798v8.678H10.787zm26.999 0h10.797v8.678H37.786zm26.998 0H75.58v8.678H64.785zm26.983 0h10.797v8.678H91.767zM102.57 8.682h5.397v8.68h-5.398zM5.394 34.718h10.798v8.678H5.394zm37.786 0h10.797v8.678H43.18zm53.995 0h10.798v8.678H97.175zM10.787 60.75h10.798v8.68H10.787zm5.409 8.68h10.798v8.677H16.196zm21.59-8.68h10.797v8.68H37.786zm-5.394 8.68H43.19v8.677H32.392zM21.59 78.107h16.196v8.678H21.59z" />
          <path d="M26.983 86.784h5.398v8.68h-5.397zm37.801-26.032H75.58v8.678H64.785zm5.393 8.678h10.798v8.677H70.177zm21.59-8.68h10.797v8.68H91.767zm-5.394 8.68H97.17v8.677H86.374zM75.57 78.107h16.197v8.678H75.57z" />
          <path d="M80.98 86.784h5.398v8.68H80.98zm-27-8.677h5.4v8.678h-5.4zm-53.98 0h5.398v8.678H0zm107.963 0h5.398v8.678h-5.397zm-80.98 43.39h5.398v8.678h-5.397z" />
          <path d="M21.59 130.174h16.196v8.678H21.59zm-5.394 8.678H43.19v8.678H16.196zm64.784-17.355h5.398v8.678H80.98z" />
          <path d="M75.57 130.174h16.197v8.678H75.57zm-5.393 8.678H97.17v8.678H70.178zm5.393 43.39h26.994v8.678H75.57zM64.784 147.53h37.79v8.68h-37.79zm-53.997 0h37.79v8.68h-37.79zm5.409 43.39h16.196v8.678H16.196zm-5.409-8.678H37.78v8.678H10.788zm75.586-17.356h26.994v8.678H86.373zM80.98 190.92h16.195v8.678H80.98z" />
          <path d="M21.59 199.597h5.398v8.68H21.59zm64.783 0h5.398v8.68h-5.397zM0 164.886h26.994v8.678H0zm48.588 17.356h16.196v8.678H48.588zm5.392 8.678h5.4v8.678h-5.4zm-16.194-26.034h37.79v8.678h-37.79zM59.375 34.718h10.798v8.678H59.375z" />
          <path d="M48.588 43.395h16.196v8.678H48.588z" />
          <path d="M43.18 52.073h10.797v8.68H43.18zm16.195 0h10.798v8.68H59.375zM0 43.395h10.798v8.678H0z" />
          <path d="M5.394 52.073h10.798v8.68H5.394zm97.176-8.678h10.797v8.678H102.57zm-5.395 8.678h10.798v8.68H97.175zM0 130.174h5.398v8.678H0zm107.963 0h5.398v8.678h-5.397zm-59.375 69.423h16.196v8.68H48.588z" />
          <path d="M43.18 208.276h10.797v8.678H43.18zm16.195 0h10.798v8.678H59.375zm-21.589 8.678h10.797v8.678H37.786zm26.998 0H75.58v8.678H64.785z" />
          <path d="M32.392 225.63H43.19v8.68H32.392zm37.785 0h10.798v8.68H70.177zm-43.194 8.68H37.78v8.678H26.984zm48.587 0h10.8v8.678h-10.8z" />
          <path d="M16.196 242.987h16.196v8.678H16.196zm64.784 0h16.195v8.678H80.98z" />
          <path d="M91.767 234.31h10.797v8.678H91.767zm5.408-8.68h10.798v8.68H97.175z" />
          <path d="M102.57 216.954h10.797v8.678H102.57zM10.787 234.31h10.798v8.678H10.787zm-5.393-8.68h10.798v8.68H5.394z" />
          <path d="M0 216.954h10.798v8.678H0zm21.59 34.711h5.398v8.678H21.59zm64.783 0h5.398v8.678h-5.397zM53.98 225.63h5.4v8.68h-5.4zm-16.194 26.035h5.398v8.678h-5.398zm32.391 0h5.398v8.678h-5.398zm-16.197 0h5.4v8.678h-5.4zm-53.98 0h5.398v8.678H0zm107.963 0h5.398v8.678h-5.397zM53.98 130.174h5.4v8.678h-5.4zM26.983 43.395h5.398v8.678h-5.397zm53.997 0h5.398v8.678H80.98zM48.588 104.14h16.196v8.68H48.588z" />
          <path d="M37.786 112.818H53.98v8.68H37.787z" />
          <path d="M43.18 121.497h5.397v8.678H43.18zM32.392 104.14H43.19v8.68H32.392z" />
          <path d="M37.786 95.463H53.98v8.678H37.787z" />
          <path d="M43.18 86.784h5.397v8.68H43.18zm16.195 8.679H75.57v8.678H59.376z" />
          <path d="M64.784 86.784h5.398v8.68h-5.398zm5.393 17.356h10.798v8.68H70.177z" />
          <path d="M59.375 112.818H75.57v8.68H59.376z" />
          <path d="M64.784 121.497h5.398v8.678h-5.398zm21.589-17.357H97.17v8.68H86.374z" />
          <path d="M91.767 95.463h16.196v8.678H91.767z" />
          <path d="M102.57 104.14h10.797v8.68H102.57z" />
          <path d="M91.767 112.818h16.196v8.68H91.767z" />
          <path d="M97.175 121.497h5.4v8.678h-5.4zm0-34.713h5.4v8.68h-5.4zM0 104.14h10.798v8.68H0z" />
          <path d="M5.394 95.463H21.59v8.678H5.394z" />
          <path d="M16.196 104.14h10.798v8.68H16.196z" />
          <path d="M5.394 112.818H21.59v8.68H5.394z" />
          <path d="M10.787 121.497h5.398v8.678h-5.398zm0-34.713h5.398v8.68h-5.398zm-5.393 69.424h21.594v8.678H5.394zm26.998 0h21.594v8.678H32.392zm26.983 0H80.97v8.678H59.374zm26.998 0h21.594v8.678H86.373zM43.18 173.563h26.993v8.68H43.18zm-37.786 0h26.993v8.68H5.394zm75.586 0h26.993v8.68H80.98zm26.983 34.713h5.398v8.678h-5.397zM0 208.276h5.398v8.678H0zm5.394 295.051h5.398v-8.678H5.394zm16.196 0h16.196v-8.678H21.59z" />
          <path d="M26.983 511.997h5.398v-8.678h-5.397zm26.997 0h5.4v-8.678h-5.4zm21.59-8.67h16.197v-8.678H75.57z" />
          <path d="M80.98 511.997h5.398v-8.678H80.98zm-64.784-17.352h10.798v-8.678H16.196zm16.196 0H43.19v-8.678H32.392zm37.785 0h10.798v-8.678H70.177zm16.196 0H97.17v-8.678H86.374zm-48.587-8.682h10.797v-8.678H37.786zm26.998 0H75.58v-8.678H64.785zm26.983 0h10.797v-8.678H91.767zm10.803 17.364h5.397v-8.678h-5.398zM5.394 477.293h10.798v-8.678H5.394z" />
          <path d="M43.18 477.293h10.797v-8.678H43.18zm53.995 0h10.798v-8.678H97.175zM10.787 451.26h10.798v-8.68H10.787zm5.409-8.683h10.798V433.9H16.196zm21.59 8.683h10.797v-8.68H37.786zm-5.394-8.683H43.19V433.9H32.392zm-10.802-8.682h16.196v-8.678H21.59z" />
          <path d="M26.983 425.225h5.398v-8.678h-5.397zm37.801 26.035H75.58v-8.68H64.785zm5.393-8.683h10.798V433.9H70.177zm21.59 8.683h10.797v-8.68H91.767zm-5.394-8.683H97.17V433.9H86.374zm-10.803-8.682h16.197v-8.678H75.57z" />
          <path d="M80.98 425.225h5.398v-8.678H80.98zm-27 8.67h5.4v-8.678h-5.4zm-53.98 0h5.398v-8.678H0zm107.963 0h5.398v-8.678h-5.397zm-80.98-43.385h5.398v-8.68h-5.397z" />
          <path d="M21.59 381.84h16.196v-8.68H21.59zm-5.394-8.682H43.19v-8.68H16.196zM80.98 390.51h5.398v-8.68H80.98z" />
          <path d="M75.57 381.84h16.197v-8.68H75.57zm-5.393-8.682H97.17v-8.68H70.178zm5.393-43.386h26.994v-8.68H75.57zm-10.786 34.703h37.79v-8.678h-37.79zm-53.997 0h37.79v-8.678h-37.79zm5.409-43.385h16.196v-8.682H16.196zm-5.409 8.682H37.78v-8.68H10.788zm75.586 17.352h26.994v-8.68H86.373zM80.98 321.09h16.195v-8.68H80.98zM0 347.124h26.994v-8.68H0zm48.588-17.352h16.196v-8.68H48.588zm5.392-8.682h5.4v-8.68h-5.4zm-16.194 26.034h37.79v-8.68h-37.79zm21.589 130.169h10.798v-8.678H59.375zm-10.787-8.683h16.196v-8.677H48.588zm-5.408-8.68h10.797v-8.68H43.18zm16.195 0h10.798v-8.68H59.375zM0 468.61h10.798v-8.677H0zm5.394-8.68h10.798v-8.68H5.394zm97.176 8.68h10.797v-8.677H102.57zm-5.395-8.68h10.798v-8.68H97.175zM0 381.84h5.398v-8.68H0zm107.963 0h5.398v-8.68h-5.397zM43.18 303.738h10.797v-8.68H43.18zm16.195 0h10.798v-8.68H59.375zm-21.589-8.682h10.797v-8.68H37.786zm26.998 0H75.58v-8.68H64.785zm-32.392-8.682H43.19v-8.68H32.392zm37.785 0h10.798v-8.68H70.177z" />
          <path d="M26.983 277.704H37.78v-8.68H26.984zm48.587 0h10.8v-8.68h-10.8zm-59.374-8.682h16.196v-8.68H16.196zm64.784 0h16.195v-8.68H80.98zm10.787 8.682h10.797v-8.68H91.767z" />
          <path d="M97.175 286.374h10.798v-8.68H97.175zm5.395 8.682h10.797v-8.68H102.57zm-91.783-17.352h10.798v-8.68H10.787z" />
          <path d="M5.394 286.374h10.798v-8.68H5.394zM0 295.056h10.798v-8.68H0zm21.59-34.713h5.398v-8.678H21.59zm32.39 26.031h5.4v-8.68h-5.4zm0 95.466h5.4v-8.68h-5.4zm-26.997 86.77h5.398v-8.677h-5.397zm53.997 0h5.398v-8.677H80.98zm-32.392-60.737h16.196v-8.678H48.588zm-10.802-8.683H53.98v-8.677H37.787zm5.394-8.68h5.397v-8.68H43.18zm-10.788 17.363H43.19v-8.678H32.392z" />
          <path d="M37.786 416.543H53.98v-8.678H37.787zm5.394 8.682h5.397v-8.678H43.18zm16.195-8.682H75.57v-8.678H59.376zm5.409 8.682h5.398v-8.678h-5.398z" />
          <path d="M70.177 407.873h10.798v-8.678H70.177zm-10.802-8.683H75.57v-8.677H59.376zm5.409-8.68h5.398v-8.68h-5.398zm21.589 17.363H97.17v-8.678H86.374z" />
          <path d="M91.767 416.543h16.196v-8.678H91.767z" />
          <path d="M102.57 407.873h10.797v-8.678H102.57zm-10.803-8.683h16.196v-8.677H91.767zm5.408-8.68h5.4v-8.68h-5.4zm0 34.715h5.4v-8.678h-5.4zM0 407.873h10.798v-8.678H0z" />
          <path d="M5.394 416.543H21.59v-8.678H5.394z" />
          <path d="M16.196 407.873h10.798v-8.678H16.196zM5.394 399.19H21.59v-8.677H5.394zm5.393-8.68h5.398v-8.68h-5.398zm0 34.715h5.398v-8.678h-5.398zm-5.393-69.419h21.594v-8.68H5.394zm26.998 0h21.594v-8.68H32.392zm26.983 0H80.97v-8.68H59.374zm26.998 0h21.594v-8.68H86.373zM43.18 338.442h26.993v-8.68H43.18zm-37.786 0h26.993v-8.68H5.394zm75.586 0h26.993v-8.68H80.98zm26.983-34.704h5.398v-8.68h-5.397zM0 303.738h5.398v-8.68H0zm48.588-43.395h5.398v8.68h-5.398zm10.787 0h5.398v8.68h-5.398zm0-17.356h5.398v8.678h-5.398zm-10.787 0h5.398v8.678h-5.398zM10.787 477.293h10.798v8.68H10.787zm75.586-164.885h5.398v-8.68h-5.397zm-64.783 0h5.398v-8.68H21.59zm26.995-8.674H64.78v8.677H48.585z" />
        </g>
      </g>
    </svg>
  );
}

export default SvgBy;
