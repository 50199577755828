import * as React from "react";

function SvgPg(props) {
  return (
    <svg viewBox="0 0 640 480" {...props}>
      <g fillRule="evenodd">
        <path d="M1.644 0l-.5 479.985H641.13L1.64 0z" />
        <path fill="red" d="M640.63 479.984l.5-479.985H1.145l639.49 479.985z" />
      </g>
      <path
        fill="#fc0"
        stroke="#fc0"
        strokeWidth={1.065}
        d="M178 54l-3.84-.2c-1.18-2.78-4.513-3.823-6.563-2.653-1.86.16-11.6-.147-11.6-.147l7.12 3.12c1.76 4.76 7.88 5.88 7.88 5.88-.543 8.774-8.881-1.133-15.881 3.867-5 3-5.059 6.583-7.761 12.371-.84 1.61-4.36 5.761-4.36 5.761l5.88-.5-1.88 2.5 7-1s-.94.785-1.5 1.38c1.037.203 8-1.63 8-1.63l-.5 2.25c3.57-1.43 8-2.88 8-2.88s1.57 1.31 3 1.88l1-4 4 1 1-4c6 8 8 16 19 18l-1-4c3.06 1.31 8.74 4.09 8.74 4.09l.86-1.83c4.77 3.42 8.73 3.36 11.4 3.74 0 0-1.99-4.98-2-5 .13.05 1.99 1.01 2 1l-3-8 3 1-4-6 1.5-1-.5-3c6 2 14 5 15 12 1 11-11 14-19 13 6 5 17 3 22-2 2-2 3-5 4-8 1 3 3 7 3 11-1 9-13 12-21 13 9 5 25-1 26-14 0-11-7-16-10-21-.45-1.82-1-5.38-1-5.38.388.115 3 1.39 3 1.39s-1.77-3.31-2-4.01c0 0-3.12-8.5-4.25-10.38.186-.105 2.25.35 2.25.35l-8.19-10.27s1.976-.18 2.32-.24c0 0-9.52-7.51-12.13-8.46l3-1c-6-3-13-1-19 3l1-3-1.83.17v-3.43l1.83-2.74-3-1 2-5-3 1 1-5s-2.24 1.04-3.6.89c.05.11 1.59-3.42 1.59-3.42-.95-1.49.01-4.47.01-4.47-7 1-8 2-12 8-6 11-4 16-3 27z"
        transform="matrix(2.21989 0 0 2.21194 1.145 0)"
      />
      <path
        fill="red"
        fillRule="evenodd"
        stroke="red"
        strokeWidth={1.42}
        d="M215.78 70.438c.521.86 6.197 3.594 10.468 6.016-1.146-4.61-9.479-5.625-10.468-6.016z"
        transform="matrix(2.21989 0 0 2.21194 1.145 0)"
      />
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M174.95 399.034l-14.12-8.98-18.95 9.046 4.202-16.147-14.487-15.174 16.718-1.017 9.996-18.404 6.127 15.528 20.667 3.783-12.929 10.617zm36.234-78.944l-6.55-3.119-6.296 3.605.959-7.188-5.388-4.844 7.142-1.306 2.965-6.613 3.457 6.37 7.221.774-5.006 5.243zm31.929-45.279l-14.363-6.99-13.923 7.831 2.222-15.771-11.785-10.772 15.746-2.765 6.63-14.488 7.526 14.067 15.872 1.814-11.1 11.458zm-65.707-63.04l-16.92-8.447-16.573 9.097 2.835-18.642-13.808-12.895 18.671-3.075 8.043-17.07 8.704 16.745 18.776 2.347-13.29 13.422zm-60.801 65.473l-17.062-10.087-17.016 10.308 4.362-19.288-15.11-12.918 19.759-1.836 7.676-18.315 7.85 18.16 19.854 1.615-14.906 13.05z"
      />
    </svg>
  );
}

export default SvgPg;
