import React from "react";
import PropTypes from "prop-types";
import { Icon } from "../../";

const Disclaimer = ({ backgroundColor, className, icon, mainText, title }) => {
  const iconBackgroundClass = `aui-bg-${backgroundColor} aui-px-3 aui-py-3 aui-flex aui-items-center`;
  const wrapperClass = `${className} aui-flex aui-justify-start aui-items-stretch`;
  let textWrapperClass = "aui-inline-block aui-align-middle aui-ml-3";

  if (!title || !mainText) {
    textWrapperClass += " aui-flex aui-items-center";
  }

  return (
    <div className={wrapperClass}>
      <div className={iconBackgroundClass}>
        <Icon icon={icon} className="aui-text-white aui-text-2xl" />
      </div>
      <div className={textWrapperClass}>
        {title && <div className="aui-text-dark-1 aui-font-bold">{title}</div>}
        {mainText && <div className="aui-text-grey-4">{mainText}</div>}
      </div>
    </div>
  );
};

Disclaimer.propTypes = {
  backgroundColor: PropTypes.string,
  className: PropTypes.string,
  icon: PropTypes.string,
  mainText: PropTypes.string,
  title: PropTypes.string
};

Disclaimer.defaultProps = {
  backgroundColor: "warning-2",
  className: "",
  icon: "tutorials"
};

export { Disclaimer };
