import React, { useState } from "react";
import PropTypes from "prop-types";

import { Icon } from "../../";
import "./index.pcss";

function Banner(
  colorPrimary,
  colorSecondary,
  textColor,
  iconColor,
  iconHoverColor,
  fullWidth
) {
  return ({
    onClose,
    timeout,
    icon,
    slim,
    hideCloseIcon,
    children,
    customIconColor,
    overflow = false,
    disableAnimation = false
  }) => {
    const [isVisible, setVisible] = useState(true);

    const close = () => {
      if (onClose) {
        onClose();
      }
      setVisible(false);
    };

    const resultantIconColor = customIconColor ? customIconColor : iconColor;

    if (timeout) {
      setTimeout(close, timeout);
    }

    const bannerIcon = () => {
      if (icon === false) return null;
      return (
        <Icon
          icon={icon || "alert-triangle"}
          className={`aui-mt-1 aui-mb-2 aui-mx-2 aui-float-left aui-w-5 aui-text-${resultantIconColor} aui-text-lg`}
        />
      );
    };
    bannerIcon.propTypes = {
      icon: PropTypes.oneOfType([PropTypes.string, PropTypes.bool])
    };

    const bannerGridClass =
      icon === false
        ? hideCloseIcon
          ? ""
          : "aui-banner-grid-cols-hide-icon"
        : hideCloseIcon
        ? "aui-banner-grid-cols-hide-close"
        : "aui-banner-grid-cols-3";

    return (
      isVisible && (
        <div
          className={`aui-banner ${
            disableAnimation ? "" : "aui-fade-in"
          } aui-mx-auto ${
            slim ? "aui-p-2" : "aui-p-4"
          } aui-bg-${colorPrimary} aui-text-${textColor} aui-bold aui-rounded ${
            overflow ? "" : "aui-overflow-hidden"
          } aui-shadow-sm aui-border aui-border-${colorSecondary} aui-border-solid ${
            !fullWidth ? "aui-max-w-3xl" : ""
          }`}
        >
          <div className={`aui-grid aui-items-center ${bannerGridClass}`}>
            {bannerIcon()}
            <div className="aui-inline-block aui-my-1 aui-text-base aui-leading-snug">
              {children}
            </div>

            {!hideCloseIcon && (
              <div>
                <Icon
                  icon="close-pill"
                  onClick={() => close()}
                  className={`aui-m-2 aui-float-right aui-text-${textColor} aui-text-tiny aui-cursor-pointer hover:aui-text-${iconHoverColor}`}
                />
              </div>
            )}
          </div>
        </div>
      )
    );
  };
}

const SuccessBanner = Banner(
  "success-1",
  "success-2",
  "success-2",
  "success-2",
  "black",
  false
);

const InfoBanner = Banner(
  "light-blue-2",
  "light-blue-2",
  "warning-3",
  "blue-1",
  "black",
  false
);

const FullWidthInfoBanner = Banner(
  "light-blue-2",
  "light-blue-2",
  "warning-3",
  "blue-1",
  "black",
  true
);

const WarningBanner = Banner(
  "warning-1",
  "warning-2",
  "warning-3",
  "warning-2",
  "black",
  false
);

const FullWidthWarningBanner = Banner(
  "warning-1",
  "warning-2",
  "warning-3",
  "warning-2",
  "black",
  true
);

const ErrorBanner = Banner(
  "error-2",
  "error-2",
  "white",
  "white",
  "grey-2",
  false
);

const BorderlessWarningBanner = Banner(
  "warning-1",
  "warning-1",
  "warning-3",
  "dark-1",
  "black",
  true
);

const BorderlessErrorBanner = Banner(
  "error-2",
  "error-2",
  "white",
  "white",
  "grey-2",
  true
);

export {
  SuccessBanner,
  InfoBanner,
  FullWidthInfoBanner,
  WarningBanner,
  ErrorBanner,
  BorderlessWarningBanner,
  BorderlessErrorBanner,
  FullWidthWarningBanner
};
