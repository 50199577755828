import React from "react";
import PropTypes from "prop-types";
import TextBody from "./TextBody";

export function TextEmphasis(props) {
  return (
    <div className="aui-text-emphasis aui-text-dark-1 aui-font-semibold">
      {props.children}
    </div>
  );
}
TextEmphasis.propTypes = {
  children: PropTypes.any
};

export function TextHeader(props) {
  const className = props.className || "";
  return (
    <div
      className={`aui-text-header aui-text-dark-1 aui-font-semibold aui-mb-6 ${className}`}
    >
      {props.children}
    </div>
  );
}
TextHeader.propTypes = {
  className: PropTypes.string,
  children: PropTypes.any
};

export function SectionTextHeader(props) {
  const className = props.className || "";
  return (
    <div
      className={`aui-text-section aui-text-dark-1 aui-font-semibold aui-mb-6 ${className}`}
    >
      {props.children}
    </div>
  );
}
SectionTextHeader.propTypes = {
  className: PropTypes.string,
  children: PropTypes.any
};

export function ModalTextHeader(props) {
  const className = props.className || "";
  return (
    <div
      className={`aui-text-modal-header aui-text-dark-1 aui-font-semibold ${className}`}
    >
      {props.children}
    </div>
  );
}
ModalTextHeader.propTypes = {
  className: PropTypes.string,
  children: PropTypes.any
};

export function TextSubHeader(props) {
  const className = props.className || "";
  return (
    <div
      className={`aui-text-sub-heading aui-text-black aui-font-bold aui-uppercase aui-mb-3 ${className}`}
    >
      {props.children}
    </div>
  );
}
TextSubHeader.propTypes = {
  className: PropTypes.string,
  children: PropTypes.any
};

export function TextTableCell(props) {
  return (
    <span className="aui-text-table-cell aui-text-grey-4">
      {props.children}
    </span>
  );
}
TextTableCell.propTypes = {
  children: PropTypes.any
};

export function TextHeaderFour(props) {
  const className = props.className || "";
  return (
    <div
      className={`aui-text-emphasis aui-text-dark-1 aui-font-semibold aui-leading-10 ${className}`}
    >
      {props.children}
    </div>
  );
}
TextHeaderFour.propTypes = {
  className: PropTypes.string,
  children: PropTypes.any
};

export function TextHeaderFourRegular(props) {
  const className = props.className || "";
  return (
    <div
      className={`aui-text-emphasis aui-text-dark-1 aui-font-regular aui-leading-10 ${className}`}
    >
      {props.children}
    </div>
  );
}
TextHeaderFourRegular.propTypes = {
  className: PropTypes.string,
  children: PropTypes.any
};

export function TextHeaderFive(props) {
  const className = props.className || "";
  return (
    <div
      className={`aui-text-xl aui-text-dark-1 aui-font-semibold aui-leading-7 aui-tracking-snug ${className}`}
    >
      {props.children}
    </div>
  );
}
TextHeaderFive.propTypes = {
  className: PropTypes.string,
  children: PropTypes.any
};

export function TextHeaderSix(props) {
  const className = props.className || "";
  return (
    <div
      className={`aui-text-sm aui-text-dark-1 aui-font-semibold aui-leading-7 aui-tracking-snug ${className}`}
    >
      {props.children}
    </div>
  );
}
TextHeaderSix.propTypes = {
  className: PropTypes.string,
  children: PropTypes.any
};

export function TextBodyTwo(props) {
  const className = props.className || "";
  return (
    <div
      className={`aui-text-sub-heading aui-text-dark-1 aui-font-regular aui-leading-tight aui-tracking-narrower ${className}`}
    >
      {props.children}
    </div>
  );
}
TextBodyTwo.propTypes = {
  className: PropTypes.string,
  children: PropTypes.any
};

export function TextBodyThree(props) {
  const className = props.className || "";
  return (
    <div
      className={`aui-text-sub-text aui-text-dark-1 aui-font-regular aui-leading-subtext aui-tracking-narrowest ${className}`}
    >
      {props.children}
    </div>
  );
}
TextBodyThree.propTypes = {
  className: PropTypes.string,
  children: PropTypes.any
};

export function TextTableTitle(props) {
  const className = props.className || "";
  return (
    <div
      className={`aui-text-sub-text aui-text-dark-1 aui-font-semibold aui-leading-subtext aui-tracking-narrow ${className}`}
    >
      {props.children}
    </div>
  );
}
TextTableTitle.propTypes = {
  className: PropTypes.string,
  children: PropTypes.any
};

export { TextBody };
