import { useEffect } from "react";

export function useShortcut(
  keycodeToActionMap,
  useCapture = false,
  watchlist = [],
  requireMeta = false
) {
  useEffect(() => {
    document.addEventListener("keydown", handleKeyDown, useCapture);
    return () => {
      document.removeEventListener("keydown", handleKeyDown, useCapture);
    };
  }, watchlist);

  function handleKeyDown(evt) {
    const action = keycodeToActionMap[evt.keyCode];
    const comboSatisfied = (requireMeta && evt.metaKey) || !requireMeta;
    if (action && comboSatisfied) {
      action(evt);
    }
  }
}
