import React from "react";
import PropTypes from "prop-types";

const Avatar = props => {
  const classNames = [
    "aui-w-6",
    "aui-h-6",
    "aui-rounded-full",
    "aui-mr-2",
    props.className || ""
  ].join(" ");
  return (
    <div className="aui-inline-block aui-w-1/2">
      <img className={classNames} src={props.src} /> {props.children}
    </div>
  );
};

Avatar.propTypes = {
  src: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.any
};

export { Avatar };
