import { isEqual, pick } from "lodash";

function get(obj, path, defaultValue) {
  var properties = path.split(".");
  return (
    properties.reduce((prev, curr) => prev && prev[curr], obj) || defaultValue
  );
}

function isEmpty(value) {
  if (value === null || value === undefined || value === "") return true;

  if (Array.isArray(value)) {
    return value.length === 0;
  }

  if (typeof value === "object") {
    return Object.keys(value).length === 0 && value.constructor === Object;
  }
  return false;
}

function compare(obj1, obj2, keys) {
  if (!keys) return isEqual(obj1, obj2);
  return isEqual(pick(obj1, keys), pick(obj2, keys));
}

export { get, isEmpty, compare };
