import PropTypes from "prop-types";
import React from "react";
import ReactMarkdown from "react-markdown";

const Markdown = props => {
  return (
    <ReactMarkdown
      className={`aui-markdown-content ${props.className || ""}`}
      {...props}
    />
  );
};
Markdown.propTypes = {
  source: PropTypes.string,
  className: PropTypes.string
};

export { Markdown };
