import * as React from "react";

function SvgCx(props) {
  return (
    <svg viewBox="0 0 640 480" {...props}>
      <path fill="#0021ad" d="M0 0h640v480H0z" />
      <path fill="#1c8a42" d="M0 0h640v480z" />
      <circle cx={320} cy={240} r={57.805} fill="#ffc639" />
      <path
        fill="#1c8a42"
        d="M284.698 214.035c3.954 5.425 9.946 14.52 14.766 12.147 3.76.013 5.702.277 6.212 2.78 8.87 1.51 23.778-2.232 33.054-14.314 0 0 .78.103.51-4.49.05-2 2.735-1.6 2.8-.948.373.993.31 1.735.812 1.76 1.142-.38 2.703-2.94 4.012-4.566.355-.716.162-1.47.245-2.374.69-1.697 2.412-1.33 2.773-.44.33.575.336 1.014.658 1.588 1.75 1.168 4.904.077 5.15.077.31-1.425 1.225-1.29 1.225-1.29 1.167-.258.71-.155 1.477.245-.677 7.684 1.51 8.025 1.33 11.966.076 4.4-1.33 5.65-1.33 7.328.445 2.052 6.934 2.123 4.65 3.858-2.005 1.07.007 3.077-3.012 3.858-8.786 4.457-10.47 8.283-10.47 8.283s-2.206 4.167-2.45 4.167c-1.472 2.78-3.336 1.264-4.374 2.62-.516 1.702-1.097 5.476-.065 7.437.517 2.696-.063 4.167-.708 6.863-.58 5.625-2.813 6.477-3.07 8.45-1.033 2.11.218 12.02-.762 12.02-6.54.128-11.554-1.253-14.14-1.77 2.522-10.87 1.54-20.415 1.54-21.396-.644-7.773-11.642-5.883-13.326-6.915-1.393-.298-2.27-1.433-2.76-1.923-1.556-.168-2.136-.523-3.69-.684-.775.387-.31.787-2.04 1.322-4.386.53-6.32-3.825-6.32-3.825.212-1.464-9.864.297-15.34-.974-2.246 1.25-3.227 4.954-5.065 5.393-.053 1.147-3.027-.943-3.626-2.072-.123-3.283 2.864-4.735 2.864-4.735 2.374-1.67 3.78-1.942 4.922-3.096.574-2.858.283-4.974 1.503-7.122 1.032-1.64 2.554-.87 3.586-1.606 1.11-.737 1.58-5.55.554-6.865 0 0-4.438-4.006-4.683-4.25-1.444-4.142 1.69-6.813 2.588-6.51z"
      />
      <path
        fill="#ffc639"
        d="M561.875 142.435c-2.62-10.364-26.06-32.685-43.678-46.89-4.206-2.865-6.947-1.158-6.4 2.927 2.194 3.66 3.84 7.68 6.032 11.34.612 2.437 1.767 4.144 2.38 6.582 0 0 .18 4.206.548 4.572 5.483 6.037 6.22 11.156 6.22 11.156 3.166 6.097 5.972 10.546 11.52 15.546 6.22 3.903 1.645 16.03 1.826 22.494 0 4.083-2.923 3.6-5.484 3.11-20.178-18.534-40.175-18.592-57.792-23.96-6.89-.728-7.012 2.562-4.754 4.387 12.314 13.172 23.893 22.132 39.136 29.628l7.683 4.76c2.923 2.44 5.85 4.877 8.773 7.316 6.767 4.386 7.322 8.41 7.322 8.773.18 8.23-4.212 14.63-5.49 17.19-2.31 8.728-6.947 10.245-6.947 10.245-37.678 25.422-57.43 32.002-118.514 24.138-.916-.483-6.767.49 0 2.93 15.546 5.18 53.735 13.488 90.682-4.007 8.87-6.218 14.804-4.18 21.23-7.902 10.553-6.508 25.622-14.61 28.363-15.52 8.232-4.393 31.275-9.328 36.584-13.72 6.09-.49 12.41-1.29 12.837-6.53 2-1.295 4.93-.347 7.103-4.617 4.832-.84 4.02-2.568 4.02-2.568-1.22-3.412-5.736-4.82-8.96-7.315-4.762-1.587-8.052-2.077-11.53-.368-1.096.49-2.192.974-3.29 1.465 0 0-5.12-.736-5.12-1.097-11.38-.626-10.27-38.337-14.296-54.066z"
      />
      <path
        fill="#1c8a42"
        d="M588.563 204.166a2.818 1.76 15.947 11-5.373-1.687 2.818 1.76 15.947 015.373 1.686z"
      />
      <g fill="#fff" transform="matrix(.64 0 0 .64 0 80)">
        <path
          id="cx_svg__a"
          d="M188.16 190.94l-12.75-11.936-12.905 11.77 1.383-17.405-17.25-2.754 14.476-9.774-8.606-15.197 16.668 5.22 6.518-16.205 6.31 16.287 16.734-5.007-8.8 15.086 14.348 9.96-17.283 2.53 1.158 17.424z"
        />
        <path d="M233.39 335.53l-13.752-9.167-13.39 9.664 4.71-15.568-13.58-9.416 16.666-.446 4.988-15.496 5.595 15.3 16.667-.156-13.21 9.902 5.307 15.382z" />
        <use
          width="100%"
          height="100%"
          x={2.522}
          y={269.061}
          xlinkHref="#cx_svg__a"
        />
        <use
          width="100%"
          height="100%"
          x={-112.066}
          y={123.223}
          xlinkHref="#cx_svg__a"
        />
        <use
          width="100%"
          height="100%"
          x={108.427}
          y={85.027}
          xlinkHref="#cx_svg__a"
        />
      </g>
    </svg>
  );
}

export default SvgCx;
