import * as React from "react";

function SvgSy(props) {
  return (
    <svg viewBox="0 0 640 480" {...props}>
      <rect
        width={640}
        height={160}
        y={160}
        fill="#fff"
        fillRule="evenodd"
        rx={0}
        ry={0}
      />
      <rect width={640} height={160} y={320} fillRule="evenodd" rx={0} ry={0} />
      <path fill="red" fillRule="evenodd" d="M0 0h640v160H0z" />
      <path
        fill="#090"
        fillRule="evenodd"
        d="M201.9 281l-28.822-20.867-28.68 21.072 10.667-34.242-28.628-21.145 35.418-.295 10.985-34.138 11.221 34.06 35.418.045-28.481 21.344L201.9 281zm307.64 0l-28.822-20.867-28.68 21.072 10.667-34.242-28.628-21.145 35.418-.295 10.985-34.138 11.221 34.06 35.418.045-28.481 21.344L509.54 281z"
      />
    </svg>
  );
}

export default SvgSy;
