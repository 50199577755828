import * as React from "react";
export const HighPotential = props => {
  const instance = React.useMemo(() => "erase-" + "ads-icons-high-potential-" + Math.random(), []);
  return <svg viewBox="0 0 20 20" fill="currentColor" xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" {...props}><defs><mask id={instance}><rect x={0} y={0} height={20} width={20} fill="white" /><circle cx={15} cy={4} r={4} className="notification" fill="black" style={{
          display: "none"
        }} /></mask></defs><mask id="a" style={{
      maskType: "alpha"
    }} maskUnits="userSpaceOnUse" x={0} y={0} width={20} height={20}><path d="M0 0h20v20H0z" mask={"url(#" + instance + ")"} /></mask><g mask="url(#a)"><path d="M2.104 9.332c.167-2.041 1.007-3.76 2.521-5.156S7.931 2.082 10 2.082c1.097 0 2.125.208 3.083.625a8.053 8.053 0 0 1 2.511 1.698 8.054 8.054 0 0 1 1.698 2.511c.417.958.625 1.986.625 3.083a7.67 7.67 0 0 1-.625 3.073 8.022 8.022 0 0 1-1.698 2.521 8.05 8.05 0 0 1-2.511 1.698 7.645 7.645 0 0 1-3.083.625 7.789 7.789 0 0 1-4.042-1.125c-.194-.111-.298-.264-.312-.459-.014-.194.055-.374.208-.541a.683.683 0 0 1 .406-.198c.16-.021.31.003.448.073a6.88 6.88 0 0 0 1.625.687 6.27 6.27 0 0 0 1.667.229c1.833 0 3.389-.639 4.667-1.916 1.277-1.278 1.916-2.834 1.916-4.667 0-1.82-.639-3.371-1.916-4.656C13.389 4.058 11.833 3.416 10 3.416c-1.722 0-3.205.57-4.448 1.708-1.243 1.139-1.94 2.548-2.094 4.229a.646.646 0 0 1-.229.469.718.718 0 0 1-.479.177.641.641 0 0 1-.469-.198.573.573 0 0 1-.177-.469Zm.729 4.959a.65.65 0 0 1-.208-.469c0-.174.07-.33.208-.469l3.209-3.208a.848.848 0 0 1 .52-.229.708.708 0 0 1 .542.208l1.917 1.583 3.208-3.229h-1.708a.618.618 0 0 1-.448-.198.618.618 0 0 1 0-.906.635.635 0 0 1 .448-.187h3.146c.222 0 .41.076.562.229.153.153.229.34.229.562v3.146a.635.635 0 0 1-.187.448.615.615 0 0 1-.906 0 .618.618 0 0 1-.198-.448V9.457l-3.521 3.521a.778.778 0 0 1-.521.229.672.672 0 0 1-.542-.208l-1.937-1.604-2.875 2.896a.65.65 0 0 1-.469.208.65.65 0 0 1-.469-.208Z" mask={"url(#" + instance + ")"} /></g><circle cx={15} cy={4} r={2.5} fill="#FD3001" className="notification" style={{
      display: "none"
    }} /></svg>;
};