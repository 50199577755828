import * as React from "react";

function SvgCf(props) {
  return (
    <svg viewBox="0 0 640 480" {...props}>
      <defs>
        <clipPath id="cf_svg__a">
          <path fillOpacity={0.67} d="M-12.355 32h640v480h-640z" />
        </clipPath>
      </defs>
      <g
        fillRule="evenodd"
        clipPath="url(#cf_svg__a)"
        transform="translate(12.355 -32)"
      >
        <path fill="#00f" d="M-52 32h719.29v118.94H-52z" />
        <path fill="#ff0" d="M-52 391.65h719.29V512H-52z" />
        <path fill="#009a00" d="M-52 271.3h719.29v120.35H-52z" />
        <path fill="#fff" d="M-52 150.94h719.29v120.35H-52z" />
        <path fill="red" d="M247.7 32.474h119.88v479.53H247.7z" />
        <path
          fill="#ff0"
          d="M99.253 137.653L67.837 115.93l-31.314 21.937 10.87-36.717-30.457-23.118 38.14-.968 12.49-36.22 12.702 36.113 38.173.732-30.284 23.288"
        />
      </g>
    </svg>
  );
}

export default SvgCf;
