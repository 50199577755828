import React from "react";
import PropTypes from "prop-types";

const InfoPopup = ({
  marginLeftClassName = "aui--ml-26",
  className = "",
  wrapperClassName = "",
  children,
  infoBody,
  displayTop
}) => {
  const classNames = [
    "aui-hidden",
    "aui-text-left",
    "aui-module-shadow",
    "aui-w-48",
    "aui-bg-white",
    "aui-text-grey-5",
    "aui-zoom-in",
    "aui-border-0",
    "aui-border-dark-1",
    "aui-border-solid",
    `${displayTop ? "aui-border-b" : "aui-border-t"}`,
    "aui-absolute",
    "aui-z-10",
    `${displayTop ? "aui-bottom-3/2" : "aui-top-3/2"}`,
    "aui-left-1/2",
    marginLeftClassName,
    className
  ].join(" ");

  return (
    <span
      className={`aui-group aui-relative hover:aui-text-primary-2 ${wrapperClassName}`}
    >
      {children}
      <span className={`group-hover:aui-block ${classNames}`}>
        <div className="aui-p-4 ">{infoBody}</div>
      </span>
    </span>
  );
};

InfoPopup.propTypes = {
  marginLeftClassName: PropTypes.string,
  wrapperClassName: PropTypes.string,
  className: PropTypes.string,
  infoBody: PropTypes.any,
  children: PropTypes.any,
  displayTop: PropTypes.bool
};

export { InfoPopup };
