export const appendTooltipToBody = function(
  evt,
  customLeft = 2,
  customTop = 5,
  visible = true
) {
  const icon = evt.currentTarget;
  const tooltip = icon.getElementsByClassName("aui-tooltip-text")[0];

  if (tooltip) {
    const revertTooltipState = () => {
      tooltip.classList.add("aui-invisible");
      tooltip.classList.add("aui-hidden");
      icon.appendChild(tooltip);
    };

    const { bottom, left } = icon.getBoundingClientRect();

    tooltip.style.top = `${window.scrollY + bottom + customTop}px`;
    tooltip.style.left = `${window.scrollX + left + customLeft}px`;

    document.getElementsByTagName("body")[0].appendChild(tooltip);

    if (!visible) {
      tooltip.classList.add("aui-invisible");
      return;
    }

    icon.addEventListener("mouseenter", evt => {
      tooltip.classList.remove("aui-invisible");
      tooltip.classList.remove("aui-hidden");
    });

    icon.addEventListener("mouseleave", evt => {
      revertTooltipState();
    });

    icon.addEventListener("click", evt => {
      revertTooltipState();
    });
  }
};
