import * as React from "react";

function SvgLt(props) {
  return (
    <svg viewBox="0 0 640 480" {...props}>
      <g fillRule="evenodd" strokeWidth="1pt" transform="scale(.64143 .96773)">
        <rect
          width={1063}
          height={708.66}
          fill="#007308"
          rx={0}
          ry={0}
          transform="scale(.93865 .69686)"
        />
        <rect
          width={1063}
          height={236.22}
          y={475.56}
          fill="#bf0000"
          rx={0}
          ry={0}
          transform="scale(.93865 .69686)"
        />
        <path fill="#ffb300" d="M0 0h997.77v164.61H0z" />
      </g>
    </svg>
  );
}

export default SvgLt;
