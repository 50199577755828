import * as React from "react";

function SvgSz(props) {
  return (
    <svg viewBox="0 0 640 480" {...props}>
      <defs>
        <clipPath id="sz_svg__a">
          <path fillOpacity={0.67} d="M-85.333 0h682.67v512h-682.67z" />
        </clipPath>
      </defs>
      <g clipPath="url(#sz_svg__a)" transform="translate(80) scale(.9375)">
        <path fill="#3e5eb9" fillRule="evenodd" d="M-128 0h768v512h-768z" />
        <path
          fill="#ffd900"
          fillRule="evenodd"
          d="M-128 91.429h768v329.14h-768z"
        />
        <path fill="#b10c0c" fillRule="evenodd" d="M-128 128h768v256h-768z" />
        <rect
          width={621.71}
          height={10.971}
          x={-51.439}
          y={250.51}
          fill="#ffd900"
          fillRule="evenodd"
          stroke="#000"
          strokeWidth={1.474}
          rx={5.767}
          ry={5.851}
        />
        <g stroke="#000" transform="translate(-757.03 -25.6) scale(1.0321)">
          <path
            fill="#fff"
            fillRule="evenodd"
            strokeWidth={4.175}
            d="M-106.3 265.75l-88.583 35.433 88.583 35.433 88.582-35.433-88.582-35.433z"
            transform="matrix(.34 0 0 .3 1256.8 136.42)"
          />
          <rect
            width={442.91}
            height={7.087}
            x={761.81}
            y={223.23}
            fill="#ffd900"
            fillRule="evenodd"
            strokeWidth={1.333}
            rx={4.108}
            ry={3.78}
          />
          <path
            fill="none"
            strokeWidth={2.667}
            d="M1224.4 279.92c-3.54 0-7.09-3.544-7.09-7.087s3.55-7.087 7.09-7.087"
            transform="matrix(-.50001 0 0 .5 1806.3 90.354)"
          />
          <path
            fill="none"
            strokeWidth={2.667}
            d="M1224.4 279.92c-3.54 0-7.09-3.544-7.09-7.087s3.55-7.087 7.09-7.087"
            transform="matrix(-.50001 0 0 .5 1802.8 90.354)"
          />
          <path
            fill="none"
            strokeWidth={2.667}
            d="M1224.4 279.92c-3.54 0-7.09-3.544-7.09-7.087s3.55-7.087 7.09-7.087"
            transform="matrix(-.50001 0 0 .5 1799.2 90.355)"
          />
        </g>
        <g stroke="#000" transform="translate(-786.29 -3.657) scale(1.0321)">
          <path
            fill="#fff"
            fillRule="evenodd"
            strokeWidth={4.175}
            d="M-106.3 265.75l-88.583 35.433 88.583 35.433 88.582-35.433-88.582-35.433z"
            transform="matrix(.34 0 0 .3 1256.8 136.42)"
          />
          <rect
            width={442.91}
            height={7.087}
            x={761.81}
            y={223.23}
            fill="#ffd900"
            fillRule="evenodd"
            strokeWidth={1.333}
            rx={4.108}
            ry={3.78}
          />
          <path
            fill="none"
            strokeWidth={2.667}
            d="M1224.4 279.92c-3.54 0-7.09-3.544-7.09-7.087s3.55-7.087 7.09-7.087"
            transform="matrix(-.50001 0 0 .5 1806.3 90.354)"
          />
          <path
            fill="none"
            strokeWidth={2.667}
            d="M1224.4 279.92c-3.54 0-7.09-3.544-7.09-7.087s3.55-7.087 7.09-7.087"
            transform="matrix(-.50001 0 0 .5 1802.8 90.354)"
          />
          <path
            fill="none"
            strokeWidth={2.667}
            d="M1224.4 279.92c-3.54 0-7.09-3.544-7.09-7.087s3.55-7.087 7.09-7.087"
            transform="matrix(-.50001 0 0 .5 1799.2 90.355)"
          />
        </g>
        <g fillRule="evenodd">
          <path
            fill="#3d5da7"
            stroke="#000"
            strokeWidth={1.422}
            d="M338.07-.416c-5.571 12.442 4.275-4.138 28.299 16.69 4.452 3.87 8.342 14.09 8.342 21.178-1.13-.975-1.969-3.145-3.214-4.553-1.743 2.253 1.664 12.577 2.162 17.457-3.756-2.71-3.394-3.993-4.642-7.324.249 4.026-.645 15.116.849 19.386-2.96-1.09-2.764-4.163-4.31-4.78 1.052 4.834-.916 10.094-.394 15.528-1.73-2.208-3.573-3.937-4.376-4.829-.135 2.588-3.327 9.388-3.4 11.835-1.468-1.143-1.866-2.926-2.111-4.126-1.824 2.955-8.308 13.872-8.724 17.202-4.996-5.69-17.793-19.545-19.459-26.9-1.473 4.176-3.604 5.584-7.817 8.632-1.665-11.656-7.891-24.756-4.561-34.747-2.359 1.804-4.302 3.608-6.66 5.828 2.234-16.88 13.628-36.674 30.016-46.477z"
            transform="matrix(.9944 0 0 .77118 190.368 251.963)"
          />
          <path
            fill="#a70000"
            d="M505.878 299.164c2.3-4.597 4.419-6.056 5.905-9.016 2.626-5.203 3-9.343 5.288-8.736 2.288.607 2.285 2.737-.678 7.854-2.964 5.116-4.372 6.209-10.515 9.898zm15.56 10.951c-.295-3.5.72-4.963.534-7.217-.316-3.967-1.938-6.69.171-6.883 2.11-.194 3.096 1.159 3.102 5.156.006 3.998-.612 5.048-3.807 8.944zm11.805 6.864c-.922-4.887-.233-7.055-.822-10.203-1.027-5.54-3.058-9.187-1.103-9.693 1.955-.507 3.134 1.284 3.874 6.902.74 5.618.35 7.162-1.95 12.994zm12.272-34.245c-2.882-2.188-4.565-2.334-6.424-3.74-3.274-2.468-4.931-5.108-5.984-3.797-1.053 1.311-.306 2.745 3.125 5.02 3.431 2.276 4.593 2.456 9.283 2.517zm-2.031 16.228c-1.52-3.155-3.119-3.955-4.101-5.986-1.738-3.567-1.74-6.653-3.625-5.862-1.884.791-2.036 2.407-.02 5.86 2.015 3.452 3.07 4.077 7.746 5.988z"
          />
        </g>
        <g fillRule="evenodd">
          <path
            fill="#3d5da7"
            stroke="#000"
            strokeWidth={1.422}
            d="M329.6 20.703c-.272-2.662.253-2.98-1.258-4.989 2.2.997 2.284 3.649 4.959 1.767.983-.551 1.411-.569.217-3.526 2.79.14 11.927 3.535 13.39 3.614 3.842.191 10.855-4.373 15.723 1.24 4.672 5.117 3.112 10.428 3.112 17.515-1.877-.975-.973-1.455-2.965-3.989 1.494 6.195-.08 17.364-.08 23.934-.767-1.584-.654-.896-1.404-2.537-1.992 5.997-4.38 7.231-4.38 14.318-.719-2.78-.025-2.191-.825-3.653-1.936 4.552-14.925 8.443-9.92 13.033-4.518-2.87-6.499-2.57-8.548-5.15-.882.617-1.584 1.785-2.404 3.386-7.943-3.96-5.103-12.5-11.326-18.206-1.077 2.393-.586 2.045-1.75 5.939-1.26-5.408-1.604-8.844-3.021-12.82-1.223 2.204-1.113 1.36-3.333 4.69-.918-6.587-2.413-8.142-1.822-12.501-2.359 1.804-.815 1.073-3.173 3.293 2.234-16.88 11.884-29.352 18.808-25.358z"
            transform="matrix(1.1372 0 0 1.0495 -399.418 239.16)"
          />
          <path
            fill="#a70000"
            d="M-35.707 289.802c2.327-5.704 4.425-7.495 5.929-11.167 2.655-6.454 3.088-11.613 5.32-10.826 2.23.787 2.194 3.444-.79 9.786-2.985 6.342-4.383 7.686-10.459 12.207zm8.814 14.27c.932-5.114 2.405-6.976 3.01-10.27 1.075-5.79.476-10.102 2.549-9.875 2.073.227 2.543 2.412 1.163 8.173-1.38 5.76-2.332 7.125-6.722 11.972zm10.539 9.921c.263-4.957 1.424-6.893 1.598-10.086.315-5.616-.767-9.637 1.211-9.66 1.978-.023 2.682 1.997 2.076 7.62-.606 5.622-1.338 7.025-4.885 12.126zM6.28 281.574c-4.328-4.312-6.945-5.116-9.736-7.89-4.917-4.87-7.294-9.44-9.044-7.82-1.75 1.62-.698 4.05 4.48 8.684 5.177 4.634 6.978 5.309 14.3 7.026zM3.647 298.24c-2.819-4.33-4.887-5.451-6.707-8.237-3.21-4.895-4.312-9.1-6.132-8.06-1.82 1.042-1.413 3.24 2.065 7.985 3.478 4.744 4.878 5.617 10.774 8.311z"
          />
        </g>
        <g fillRule="evenodd">
          <path
            fill="#fff"
            stroke="#000"
            strokeWidth={2.108}
            d="M637.8 230.32c-53.15 59.05-124.02 177.16-265.75 177.16-124.02 0-212.6-118.11-265.75-177.16 53.15-59.06 141.73-177.17 265.75-177.17 141.73 0 212.6 118.11 265.75 177.17z"
            transform="matrix(.68807 0 0 .61926 .001 113.366)"
          />
          <path d="M243.234 184.445c9.73 10.943 1.605 15.354 11.903 16.073 10.86.797 4.705 11.562 13.84 11.936 6.387.28-.638 25.794 5.511 34.213 6.263 8.777 11.508 2.571 11.618 8.913.109 6.558-17.045 5.896-17.346 26.099-.503 11.642-14.476 12.388-15.143 19.879-.83 7.046 27.528 11.002 27.15 17.31-.388 6.288-30.62 5.303-31.936 12.475-.675 6.442 41.531 11.721 44.925 30.352-6.298 2.06-24.216 3.998-37.76 4.009-85.327.068-146.283-73.14-182.854-109.708 36.571-36.574 97.52-109.714 182.855-109.714 0 0-25.33 23.144-12.763 38.163z" />
          <path
            fill="#fff"
            d="M141.408 216.989h8.866v29.256h-8.866zm0 48.761h8.866v29.256h-8.866zm17.73-48.761h8.866v29.256h-8.866zm0 48.761h8.866v29.256h-8.866zm17.73-48.761h8.866v29.256h-8.866zm0 48.761h8.866v29.256h-8.866zm17.734-48.761h8.866v29.256h-8.866zm0 48.761h8.866v29.256h-8.866zm17.73-48.761h8.866v29.256h-8.866zm0 48.761h8.866v29.256h-8.866zm17.73-48.761h8.865v29.256h-8.865zm0 48.761h8.865v29.256h-8.865z"
          />
          <path d="M275.499 216.989h8.866v29.256h-8.866zm0 48.761h8.866v29.256h-8.866zm17.729-48.761h8.866v29.256h-8.866zm0 48.761h8.866v29.256h-8.866zm17.73-48.761h8.866v29.256h-8.866zm0 48.761h8.866v29.256h-8.866zm17.735-48.761h8.866v29.256h-8.866zm0 48.761h8.866v29.256h-8.866zm17.729-48.761h8.866v29.256h-8.866zm0 48.761h8.866v29.256h-8.866zm17.73-48.761h8.866v29.256h-8.866zm0 48.761h8.866v29.256h-8.866z" />
        </g>
        <g fillRule="evenodd">
          <path
            fill="#3d5da7"
            stroke="#000"
            strokeWidth={1.422}
            d="M338.07-.416c-5.571 12.442 9.754-4.138 33.778 16.69 4.452 3.87 10.833 19.16 10.833 26.246-5.115-1.257-14.173-7.087-14.173-7.087s10.63 12.295 10.63 26.468c-3.756-2.71-5.635-2.304-6.883-5.634 0 4.588 3.34 7.512 3.34 14.599-2.711-2.498-5.006-4.163-7.3-5.625 3.543 7.086-6.457 20.834-1.452 25.423-8.752-1.462-17.707-7.92-21.25-15.006-1.878 1.462-2.082 3.756-2.155 6.203.276.264-13.322-11.656-12.073-16.235-1.824 2.955-2.081 4.579-2.497 7.909-4.996-5.69-9.574-11.378-11.24-18.733-2.22 3.33-2.359 3.33-4.579 6.66-1.665-11.655-1.665-11.24 1.665-21.23-2.359 1.804-4.302 3.608-6.66 5.828 2.234-16.88 13.628-36.674 30.016-46.477z"
            transform="matrix(.9094 0 0 .78749 -110.58 166.096)"
          />
          <path
            fill="#a70000"
            d="M184.375 213.644c.81-6.752 2.576-9.295 3.103-13.643.94-7.648-.175-13.237 2.534-13.095 2.71.141 3.49 2.962 2.151 10.593-1.34 7.63-2.468 9.484-7.788 16.145zm14.145 12.363c-.55-5.697.503-8.122.154-11.791-.602-6.457-2.596-10.83-.234-11.233 2.362-.402 3.556 1.764 3.81 8.283.256 6.519-.375 8.256-3.73 14.74zm22.082 10.085c-2.401-4.929-4.464-6.4-6.015-9.572-2.739-5.576-3.31-10.129-5.422-9.304-2.112.825-1.996 3.18 1.054 8.639 3.05 5.458 4.429 6.57 10.383 10.237zm7.633-44.146c-6.116-3.22-9.257-3.26-13.199-5.329-6.94-3.627-10.98-7.725-12.414-5.47-1.434 2.256.48 4.499 7.635 7.796 7.155 3.298 9.354 3.454 17.978 3.003zm2.245 18.436c-4.455-3.705-7.088-4.25-9.96-6.633-5.06-4.182-7.578-8.288-9.268-6.624-1.69 1.665-.56 3.864 4.75 7.804 5.31 3.94 7.127 4.43 14.477 5.453z"
          />
        </g>
      </g>
    </svg>
  );
}

export default SvgSz;
