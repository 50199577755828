function saveToLocalStorage(key, value) {
  if (!key) throw Error(`Autosave key cannot evaluate to ${key}`);

  localStorage.setItem(key, JSON.stringify(value));
}

function loadFromLocalStorage(key) {
  return JSON.parse(localStorage.getItem(key));
}

function removeFromLocalStorage(key) {
  return localStorage.removeItem(key);
}

export { saveToLocalStorage, loadFromLocalStorage, removeFromLocalStorage };
