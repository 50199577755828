import * as React from "react";

function SvgSs(props) {
  return (
    <svg viewBox="0 0 640 480" {...props}>
      <path fill="#078930" d="M0 336h640v144H0z" />
      <path fill="#fff" d="M0 144h640v192H0z" />
      <path d="M0 0h640v144H0z" />
      <path fill="#da121a" d="M0 168h640v144H0z" />
      <path fill="#0f47af" d="M0 0l415.7 240L0 480z" />
      <path
        fill="#fcdd09"
        d="M200.7 194.85L61.75 240l138.95 45.15-85.85-118.2v146.1z"
      />
    </svg>
  );
}

export default SvgSs;
