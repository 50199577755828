import React, { PropsWithChildren } from "react";
import { sendMessage, messages, Events } from "@alphasights/realtime-import";
import { useListenToWindowMessageEvent, useTalkToMainApp } from "hooks/index";

export type RTASModuleProps<T extends object = object> = React.PropsWithChildren &
    React.HTMLAttributes<HTMLDivElement> & {
        onContextUpdate?: (context: T | null) => void;
    };

export type RTASModuleContextType<T extends object = object> = {
    context: T | null;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const RTASModuleContext = React.createContext<RTASModuleContextType<any> | undefined>(undefined);
export type RTASModuleProviderProps<T extends object = object> = PropsWithChildren<{
    context: T | null;
}>;
export const RTASModuleProvider = <T extends object = object>({ children, context }: RTASModuleProviderProps<T>) => {
    return <RTASModuleContext.Provider value={{ context }}>{children}</RTASModuleContext.Provider>;
};

export const useRTASModuleContext = <T extends object = object>() => {
    const context = React.useContext(RTASModuleContext);
    if (!context) {
        throw new Error("useRTASModuleContext must be used within a RTASModuleContextProvider");
    }
    return (context as RTASModuleContextType<T>).context;
};

export const RTASModule = <T extends object = object>({ children, onContextUpdate, ...props }: RTASModuleProps<T>) => {
    const isFirstRender = React.useRef(true);
    const documentRef = React.createRef<HTMLDivElement>();
    const [context, setContext] = React.useState<T | null>({} as T);
    const { talkToMainApp } = useTalkToMainApp();

    useListenToWindowMessageEvent(Events.SetContext, (message) => {
        if (!message || !message.payload || typeof message.payload !== "string") {
            setContext(null);
            return;
        }
        const context = JSON.parse(message.payload);
        setContext(context);
    });

    React.useEffect(() => {
        onContextUpdate?.(context);
    }, [context, onContextUpdate]);

    React.useEffect(() => {
        const documentCurrent = documentRef.current;
        const resizeObserver = new ResizeObserver((entries) => {
            const height = entries[0].contentRect.height;
            sendMessage(messages.resize({ height }));
        });

        if (documentCurrent) {
            resizeObserver.observe(documentCurrent);
        }
        return () => {
            if (documentCurrent) {
                resizeObserver.unobserve(documentCurrent);
            }
        };
    }, [documentRef]);

    React.useEffect(() => {
        if (isFirstRender.current) {
            isFirstRender.current = false;
            talkToMainApp(messages.moduleLoaded());
            talkToMainApp(messages.requestContext());
        }
    }, [isFirstRender, talkToMainApp]);
    return (
        <RTASModuleProvider<T> context={context}>
            <div role="region" ref={documentRef} {...props}>
                {children}
            </div>
        </RTASModuleProvider>
    );
};
