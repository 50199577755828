import * as React from "react";

function SvgPf(props) {
  return (
    <svg viewBox="0 0 640 480" {...props}>
      <defs>
        <clipPath id="pf_svg__a">
          <path fillOpacity={0.67} d="M0 0h640v480H0z" />
        </clipPath>
      </defs>
      <g clipPath="url(#pf_svg__a)">
        <path fill="#fff" d="M0 0h640v480H0z" />
        <path fill="#fff" d="M80 0h480v480H80z" />
        <path
          fill="#083d9c"
          fillRule="evenodd"
          stroke="#083d9c"
          strokeWidth="2pt"
          d="M277.28 340.75s10.839-8.788 21.386-8.788 13.477 7.323 20.801 7.909c7.324.586 13.475-7.324 22.558-7.617 9.082-.292 20.508 6.445 20.508 6.445l-39.843 12.013-45.41-9.962zm-22.85-12.89l135.35.586s-11.718-12.597-25.488-12.89c-13.769-.295-9.96 5.86-20.507 6.737-10.546.88-13.185-6.444-22.852-6.152-9.666.292-15.234 6.152-22.557 6.446-7.324.292-16.699-7.326-22.266-7.03-5.567.292-25.488 8.787-25.488 8.787l3.809 3.516zm-17.28-16.11l166.99.587c2.636-3.808-8.204-12.89-18.163-13.77-8.205.293-14.062 8.496-20.801 8.79-6.739.292-14.355-8.497-21.973-8.202-7.616.292-15.526 8.202-23.144 8.202-7.616 0-13.183-8.497-22.85-8.497-9.668 0-14.062 9.375-21.386 8.79-7.326-.587-13.77-9.375-20.801-9.375-7.03 0-18.75 10.546-21.093 9.96-2.344-.586 2.928 4.395 3.223 3.515z"
        />
        <path
          fill="red"
          fillRule="evenodd"
          stroke="#000"
          strokeLinejoin="round"
          strokeWidth={2.5}
          d="M301.3 218.88l38.38 10.255v-54.786c-17.579.88-32.226-33.397-1.172-35.741-30.468-4.395-33.985 3.515-37.5 12.011l.292 68.261z"
        />
        <path
          fill="#083d9c"
          fillRule="evenodd"
          stroke="#083d9c"
          strokeLinecap="round"
          strokeWidth={5}
          d="M276.99 258.72l86.718.292"
        />
        <path
          fill="none"
          stroke="#000"
          strokeLinecap="round"
          strokeWidth={3.999}
          d="M281.094 237.919l10.254 13.77m-10.254.011l11.134-13.476m-5.266-1.187l-.292 8.496m10.83-7.614l10.254 13.77m-10.254.011l11.134-13.476m-5.266-1.187l-.292 8.496m11.122-7.614l10.255 13.77m-10.255.011l11.134-13.476m-5.265-1.187l-.292 8.496m11.709-7.614l10.254 13.77m-10.254.011l11.134-13.476m-5.266-1.187l-.292 8.496m11.123-7.614l10.254 13.77m-10.254.011l11.134-13.476m-5.266-1.187l-.292 8.496"
        />
        <path
          fill="#ef7d08"
          fillRule="evenodd"
          d="M218.69 259.6l36.913.293V236.75l-42.187-2.05 5.274 24.902zm-1.76-31.93l39.258 3.809-.292-16.406-38.38-15.234-.587 27.832zm7.91-32.81l30.176 14.648 4.309-4.498s-2.775-1.912-2.638-3.672c.052-1.78 2.792-2.05 2.844-3.98.05-1.78-3.103-1.994-3.137-3.774-.206-1.93 2.43-3.998 2.43-3.998l-27.245-23.73-6.739 29.004zm198.04 65.03h-38.964l-.292-22.558 42.772-3.223-3.516 25.78zm-38.67-27.83l46.29-5.565-9.962-26.66-36.62 15.526.292 16.698zm33.69-39.55l-33.398 17.578c-.488-1.905-.902-3.737-3.221-5.274 0 0 2.05-1.172 2.05-3.224 0-2.049-2.638-2.343-2.638-3.515s2.417-2.195 2.564-4.833c-.293-1.831-2.564-4.393-2.124-4.907l25.928-19.85L417.9 192.51zm-72.36 39.11l16.698-.732.292-6.74-16.99 7.472zm-50.97-.51l-17.578-.512v-7.032l17.578 7.544zm-.08-2.05l-17.505-9.01v-11.718s-2.051.293-1.758-2.05c.097-4.884 12.865 8.91 19.409 13.402l-.146 9.375zm51.05-1.1l-.072-7.616s15.818-14.281 19.187-16.92c0 2.93-1.83 5.2-1.83 5.2v11.133l-17.285 8.202zM243 163.8c.292.293 17.75 19.696 17.75 19.696.49-1.839 4.515-2.118 8.616-1.825 4.102.292 7.372-.275 7.372 2.655 0 2.93-2.081 2.513-2.081 4.565s3.118 1.879 3.118 4.513c0 2.636-2.261 2.098-2.273 4.14-.007 1.688 2.36 1.778 2.36 1.778l16.625 16.114.073-17.213-34.276-53.758L243 163.8zm27.43-20.35c.26.782 23.216 47.477 23.216 47.477s.26-43.825 4.173-46.173l-6.52-12-20.87 10.696zm100.95 1.82l-25.923 46.245.034-19.491s2.15-3.277-1.242-3.016c-3.39.26-7.564-.261-7.564-.261l10.434-36.521 24.26 13.044zm26.61 19.83c-.261.522-17.488 18.04-17.488 18.04-.782-2.086-5.99-1.085-10.947-1.085-4.957 0-5.647 1.59-5.385 2.893.522 3.392 2.196.893 2.196 4.024 0 3.13-2.412 1.987-2.627 4.279.24 2.632 3.786 1.981 1.708 3.976L345.5 216.376l.057-18.146 37.042-57.652 15.392 24.52z"
        />
        <path
          fill="red"
          fillRule="evenodd"
          stroke="#000"
          strokeLinejoin="round"
          strokeWidth={2.5}
          d="M309.82 268.4c-8.348 13.826-30.664 9.726-35.882.073-1.564-.443-.639-59.55-.639-59.55s-2.493-1.136-2.608-2.954c-.115-1.835 3.39-2.002 3.39-4.351 0-2.348-3.579-1.427-3.65-3.79.015-2.26 3.82-1.948 3.65-4.036-.201-2.353-4.262-2.005-4.434-4.175-.132-1.723 2.904-3.225 3.748-4-.546.027-2.82-.034-2.835-.042l-6.392.13c-4.541.005.078.99.012 3.61-.042 1.712-2.303 2.851-2.506 4.34-.071 1.527 3.236 2.6 3.278 4.434.037 1.636-3.25 1.748-3.132 3.268.205 2.573 2.912 3.139 2.871 4.696-.041 1.556-3.643 2.15-3.652 3.391.125 2.402.521 60.781.521 60.781 5.74 29.739 38.868 37.304 48.26-1.825zm21.84 0c8.348 13.826 30.663 9.726 35.881.073 1.564-.443.64-59.55.64-59.55s2.493-1.136 2.607-2.954c.114-1.835-3.17-2.002-3.17-4.351 0-2.348 3.36-1.427 3.43-3.79-.014-2.26-3.527-2.094-3.357-4.183.2-2.353 2.959-2.078 3.116-4.247.131-1.869-1.732-3.007-2.576-3.782.546.027 2.673-.034 2.689-.041l6.391.13c4.542.005-.078.99-.01 3.61.04 1.712 2.301 2.851 2.505 4.34.07 1.527-3.237 2.6-3.279 4.434-.036 1.636 3.25 1.747 3.131 3.268-.204 2.572-2.911 3.139-2.87 4.696.041 1.556 3.644 2.15 3.652 3.39-.125 2.403-.52 60.782-.52 60.782-5.74 29.739-38.868 37.304-48.26-1.825z"
        />
        <path
          fill="#083d9c"
          fillRule="evenodd"
          stroke="#083d9c"
          strokeWidth="2pt"
          d="M301.71 295.59l37.277-.022c.29-.293-8.346-12.874-18.632-11.987-11.46.3-19.244 12.009-18.644 12.009zm118.86-.91h-51.008s6.602-3.901 8.402-7.502c3.3 1.8 2.4 3.6 9.002 3.9 6.6.3 12.9-7.5 19.203-7.2 6.3.3 14.4 11.102 14.4 10.802zm-201 0h51.008s-6.602-3.901-8.402-7.502c-3.3 1.8-2.4 3.6-9.002 3.9-6.599.3-12.9-7.5-19.203-7.2-6.3.3-14.4 11.102-14.4 10.802zm3.81-16.04l36.327.292s-2.344-4.98-2.636-11.13c-9.377-3.222-16.993 7.03-23.732 7.323-6.736.292-13.767-7.324-13.767-7.324l3.808 10.84zm193.94 0l-36.328.292s2.344-4.98 2.637-11.13c9.376-3.222 16.992 7.03 23.73 7.323 6.738.292 13.769-7.324 13.769-7.324l-3.808 10.84zm-106.35.3l18.455-.584s.294-5.567-9.374-5.567c-9.668 0-8.788 6.445-9.081 6.151zm-11.13-7.91c3.223-1.76 6.152-3.515 8.204-7.618l-12.598.292s-5.858 3.517-8.788 7.326h13.183zm40.72 0c-3.223-1.76-6.151-3.515-8.203-7.618l12.597.292s5.858 3.517 8.788 7.326H340.56z"
        />
        <path
          fill="#de2010"
          fillRule="evenodd"
          d="M-40 360h720v120H-40zm0-360h720v120H-40z"
        />
      </g>
    </svg>
  );
}

export default SvgPf;
