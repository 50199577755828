import * as React from "react";

function SvgGi(props) {
  return (
    <svg viewBox="0 0 640 480" {...props}>
      <path fill="#da000c" d="M0 0h640v480H0z" />
      <path fill="#fff" d="M0 0h640v321.563H0z" />
      <g stroke="#000" transform="translate(-160) scale(1.875)">
        <g id="gi_svg__a" fill="#da000c" strokeLinecap="square">
          <path fill="#000" stroke="none" d="M196.57 116.303h64v43.993h-64z" />
          <path d="M229.82 153.865h-39.07l-8.66 5.7v6.04h47.73m-16.35-37.011c5.624 0 10.218 4.69 10.218 10.47v14.78h7.343v-56h-40.28v56h12.5v-14.78c0-5.555 4.515-10.47 10.22-10.47z" />
          <path
            fill="#000"
            stroke="none"
            d="M204.528 59.962h18.523v33.96H204.53z"
          />
          <path d="M223 88.656h-16.21v-5.75h-11.884v5.75h-8.062v-5.75h-4.813v10.438H223m-36.156-.038h34.97v4.538h-34.97zm13.876-45.681v35.28h6.07V73.22c0-3.63 2.563-6.6 6.148-6.782.115-.006.226 0 .343 0a6.78 6.78 0 016.782 6.78v9.688h5.782v-35.28zm-2.19-4.781v4.78h29.25v-4.78zm-3.75-9.156v9.156h35.095v-9.157h-5.406v4.657h-6.532v-4.657h-9.97v4.657h-6.53v-4.657zm-12.69 125.876H230m31-2.751h-32.438l-9.78 4.718v7.032H261" />
          <path strokeLinecap="butt" d="M218.77 161.52H262" />
        </g>
        <use
          width="100%"
          height="100%"
          transform="matrix(-1 0 0 1 512 0)"
          xlinkHref="#gi_svg__a"
        />
        <g fill="#f8d80e">
          <g strokeLinecap="round">
            <path
              strokeWidth={0.768}
              d="M273.297 150.03c-2.654.884-5.053 2.37-7.47 3.754-3.1 1.83-6.106 3.827-8.903 6.1-.985.648-1.93 1.36-2.69 2.272-1.025.878-2.064 2.055-1.868 3.513.05.642.873-.756 1.414-.824a4.39 4.39 0 013.142-.47c1.27-1.34 2.91-2.237 4.325-3.407 3.004-2.244 6.236-4.17 9.525-5.962 1.148-.59 2.3-1.186 3.525-1.602l-1-3.375z"
            />
            <path d="M260.4 157.39v3.913m2.417-5.618v3.883m2.416-5.388v3.858m2.417-5.344v3.98" />
            <path
              strokeWidth={0.768}
              d="M238.89 150.156l-1.186 3.313c5.536 2.1 10.808 4.877 15.797 8.06 1.692 1.15 3.468 2.265 4.797 3.845.293.81-.514 1.49-1.314 1.244-.732-.25-1.453-.533-2.226-.005-1.107.503-2.106 2.14-.426 2.69 2.316 1.556 6.083.88 7.196-1.844.58-1.4.618-3.195-.508-4.344-2.063-2.364-4.797-4.008-7.42-5.672-4.69-2.82-9.574-5.374-14.707-7.288z"
            />
            <path d="M254.05 158.035l-.313 3.34m3.095-1.635l-.862 3.27m3.834-.998l-1.808 2.537m2.719 3.631l-2.61-1.4m3.423-1.35l-2.995.28m-.868 4.055l-.205-2.633m-.892-.376l-2.473 1.822m-9.418-15.641v3.115m6.286.192v3.534m-3.143-5.185v3.32" />
          </g>
          <path d="M235.78 227.563v8.03h5.032v-4.03h6.844v4.03h5.406v-8.03zm0 11.062v8.063h17.282v-8.063h-5.406v4.03h-6.844v-4.03z" />
          <path d="M253.052 193.65h4.99v58.116h-4.99z" />
          <path d="M253.052 198.68h4.99v50.06h-4.99z" />
          <path
            strokeWidth={1.024}
            d="M255.547 179.406l10.625 6.188-10.625 6.156-10.625-6.156zm-14.22 3.97h-2.78v4.436h2.78l14.22 8.25 14.22-8.25h2.78v-4.437h-2.78l-14.22-8.25z"
          />
          <path
            strokeWidth={1.024}
            d="M255.344 164.28c-2.42.34-4.226 2.73-4.094 5.135.01 3.738-.018 7.476.014 11.214.13 2.407 2.233 4.565 4.675 4.587 1.984.188 3.978-.972 4.81-2.78l-1.718-1.03c-.444 1.183-1.724 1.917-2.97 1.78-1.634.046-2.958-1.544-2.812-3.134.01-3.713-.02-7.428.015-11.14.09-1.516 1.535-2.755 3.048-2.6.626-.18 1.487.676 1.875-.063.583-.83-.415-1.477-.97-1.937-.613-.085-1.253-.008-1.874-.032zm5.78 3.907c-.395.746-1.215 1.19-1.874 1.58v7.514l2 1.157c-.008-3.204.016-6.408-.01-9.61-.033-.198-.01-.5-.115-.64z"
          />
        </g>
        <g fill="#da000c">
          <path
            fill="#000"
            d="M240.803 38.35h29.33v53.256h-29.33z"
            stroke="none"
          />
          <path d="M238.75 38.375v44.563h9.313v-13.22c0-3.048 1.952-7.28 7.937-7.28s7.938 4.232 7.938 7.28v13.22h9.312V38.374zm15.813 5h2.875v15.25h-2.875zm-8.25 3h2.906V57.53h-2.907zm16.468 0h2.908V57.53h-2.907zM235.594 31.97v6.343h40.812v-6.344zm-3.844-7.376v7.375h48.5v-7.376h-6.094v4.062h-6.875v-4.062h-7.842v4.062h-6.875v-4.062h-7.844v4.062h-6.876v-4.062zm-9 73.25v4.594h66.5v-4.594z" />
          <path d="M220.03 82.906v14.938h71.94V82.906h-6.814v5.75h-9.062v-5.75h-12.156v5.75h-15.875v-5.75h-12.157v5.75h-9.062v-5.75z" />
          <path
            strokeLinejoin="round"
            d="M228.688 102.438v54.343h12.843v-20.343c0-9.582 6.398-14.093 14.47-14.093 7.76 0 14.47 4.51 14.47 14.094v20.343h12.842v-54.343z"
          />
        </g>
      </g>
    </svg>
  );
}

export default SvgGi;
